(function (t) {
// et
t.add("An authentication exception occurred.", "Autentimisel juhtus ootamatu viga.", "security", "et");
t.add("Authentication credentials could not be found.", "Autentimisandmeid ei leitud.", "security", "et");
t.add("Authentication request could not be processed due to a system problem.", "Autentimisp\u00e4ring ei \u00f5nnestunud s\u00fcsteemi probleemi t\u00f5ttu.", "security", "et");
t.add("Invalid credentials.", "Vigased autentimisandmed.", "security", "et");
t.add("Cookie has already been used by someone else.", "K\u00fcpsis on juba kellegi teise poolt kasutuses.", "security", "et");
t.add("Not privileged to request the resource.", "Ressursi p\u00e4rimiseks pole piisavalt \u00f5iguseid.", "security", "et");
t.add("Invalid CSRF token.", "Vigane CSRF m\u00e4rgis.", "security", "et");
t.add("No authentication provider found to support the authentication token.", "Ei leitud sobivat autentimismeetodit, mis toetaks autentimism\u00e4rgist.", "security", "et");
t.add("No session available, it either timed out or cookies are not enabled.", "Seanss puudub, see on kas aegunud v\u00f5i pole k\u00fcpsised lubatud.", "security", "et");
t.add("No token could be found.", "Identsust\u00f5endit ei leitud.", "security", "et");
t.add("Username could not be found.", "Kasutajanime ei leitud.", "security", "et");
t.add("Account has expired.", "Kasutajakonto on aegunud.", "security", "et");
t.add("Credentials have expired.", "Autentimistunnused on aegunud.", "security", "et");
t.add("Account is disabled.", "Kasutajakonto on keelatud.", "security", "et");
t.add("Account is locked.", "Kasutajakonto on lukustatud.", "security", "et");
t.add("Too many failed login attempts, please try again later.", "Liiga palju eba\u00f5nnestunud autentimise katseid, palun proovi hiljem uuesti.", "security", "et");
t.add("Invalid or expired login link.", "Vigane v\u00f5i aegunud sisselogimise link.", "security", "et");
t.add("Too many failed login attempts, please try again in %minutes% minute.", "Liiga palju eba\u00f5nnestunud autentimise katseid, palun proovi uuesti %minutes% minuti p\u00e4rast.", "security", "et");
t.add("Too many failed login attempts, please try again in %minutes% minutes.", "Liiga palju eba\u00f5nnestunud autentimise katseid, palun proovi uuesti %minutes% minuti p\u00e4rast.", "security", "et");
})(Translator);
