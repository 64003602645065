(function (t) {
// hr
t.add("This value should be false.", "Ova vrijednost treba biti neto\u010dna (false).", "validators", "hr");
t.add("This value should be true.", "Ova vrijednost treba biti to\u010dna (true).", "validators", "hr");
t.add("This value should be of type {{ type }}.", "Ova vrijednost treba biti tipa {{ type }}.", "validators", "hr");
t.add("This value should be blank.", "Ova vrijednost treba biti prazna.", "validators", "hr");
t.add("The value you selected is not a valid choice.", "Ova vrijednost nije valjan izbor.", "validators", "hr");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Izaberite barem {{ limit }} mogu\u0107nosti.", "validators", "hr");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Izaberite najvi\u0161e {{ limit }} mogu\u0107nosti.", "validators", "hr");
t.add("One or more of the given values is invalid.", "Jedna ili vi\u0161e danih vrijednosti nije ispravna.", "validators", "hr");
t.add("This field was not expected.", "Ovo polje nije o\u010dekivano.", "validators", "hr");
t.add("This field is missing.", "Ovo polje nedostaje.", "validators", "hr");
t.add("This value is not a valid date.", "Ova vrijednost nije ispravan datum.", "validators", "hr");
t.add("This value is not a valid datetime.", "Ova vrijednost nije ispravnog datum-vrijeme formata.", "validators", "hr");
t.add("This value is not a valid email address.", "Ova vrijednost nije ispravna e-mail adresa.", "validators", "hr");
t.add("The file could not be found.", "Datoteka ne mo\u017ee biti prona\u0111ena.", "validators", "hr");
t.add("The file is not readable.", "Datoteka nije \u010ditljiva.", "validators", "hr");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Datoteka je prevelika ({{ size }} {{ suffix }}). Najve\u0107a dozvoljena veli\u010dina je {{ limit }} {{ suffix }}.", "validators", "hr");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Mime tip datoteke nije ispravan ({{ type }}). Dozvoljeni mime tipovi su {{ types }}.", "validators", "hr");
t.add("This value should be {{ limit }} or less.", "Ova vrijednost treba biti {{ limit }} ili manje.", "validators", "hr");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Ova vrijednost je preduga\u010dka. Treba imati {{ limit }} znakova ili manje.", "validators", "hr");
t.add("This value should be {{ limit }} or more.", "Ova vrijednost treba biti {{ limit }} ili vi\u0161e.", "validators", "hr");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Ova vrijednost je prekratka. Treba imati {{ limit }} znakova ili vi\u0161e.", "validators", "hr");
t.add("This value should not be blank.", "Ova vrijednost ne bi trebala biti prazna.", "validators", "hr");
t.add("This value should not be null.", "Ova vrijednost ne bi trebala biti null.", "validators", "hr");
t.add("This value should be null.", "Ova vrijednost treba biti null.", "validators", "hr");
t.add("This value is not valid.", "Ova vrijednost nije ispravna.", "validators", "hr");
t.add("This value is not a valid time.", "Ova vrijednost nije ispravno vrijeme.", "validators", "hr");
t.add("This value is not a valid URL.", "Ova vrijednost nije ispravan URL.", "validators", "hr");
t.add("The two values should be equal.", "Obje vrijednosti trebaju biti jednake.", "validators", "hr");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Ova datoteka je prevelika. Najve\u0107a dozvoljena veli\u010dina je {{ limit }} {{ suffix }}.", "validators", "hr");
t.add("The file is too large.", "Ova datoteka je prevelika.", "validators", "hr");
t.add("The file could not be uploaded.", "Ova datoteka ne mo\u017ee biti prenesena.", "validators", "hr");
t.add("This value should be a valid number.", "Ova vrijednost treba biti ispravan broj.", "validators", "hr");
t.add("This file is not a valid image.", "Ova datoteka nije ispravna slika.", "validators", "hr");
t.add("This is not a valid IP address.", "Ovo nije ispravna IP adresa.", "validators", "hr");
t.add("This value is not a valid language.", "Ova vrijednost nije ispravan jezik.", "validators", "hr");
t.add("This value is not a valid locale.", "Ova vrijednost nije ispravana regionalna oznaka.", "validators", "hr");
t.add("This value is not a valid country.", "Ova vrijednost nije ispravna dr\u017eava.", "validators", "hr");
t.add("This value is already used.", "Ova vrijednost je ve\u0107 iskori\u0161tena.", "validators", "hr");
t.add("The size of the image could not be detected.", "Veli\u010dina slike se ne mo\u017ee odrediti.", "validators", "hr");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u0160irina slike je prevelika ({{ width }}px). Najve\u0107a dozvoljena \u0161irina je {{ max_width }}px.", "validators", "hr");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u0160irina slike je premala ({{ width }}px). Najmanja dozvoljena \u0161irina je {{ min_width }}px.", "validators", "hr");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Visina slike je prevelika ({{ height }}px). Najve\u0107a dozvoljena visina je {{ max_height }}px.", "validators", "hr");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Visina slike je premala ({{ height }}px). Najmanja dozvoljena visina je {{ min_height }}px.", "validators", "hr");
t.add("This value should be the user's current password.", "Ova vrijednost treba biti trenutna korisni\u010dka lozinka.", "validators", "hr");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Ova vrijednost treba imati to\u010dno {{ limit }} znakova.", "validators", "hr");
t.add("The file was only partially uploaded.", "Datoteka je samo djelomi\u010dno prenesena.", "validators", "hr");
t.add("No file was uploaded.", "Niti jedna datoteka nije prenesena.", "validators", "hr");
t.add("No temporary folder was configured in php.ini.", "U php.ini datoteci nije konfiguriran privremeni direktorij.", "validators", "hr");
t.add("Cannot write temporary file to disk.", "Ne mogu zapisati privremenu datoteku na disk.", "validators", "hr");
t.add("A PHP extension caused the upload to fail.", "Prijenos datoteke nije uspio zbog PHP ekstenzije.", "validators", "hr");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Ova kolekcija treba sadr\u017eavati {{ limit }} ili vi\u0161e elemenata.|Ova kolekcija treba sadr\u017eavati {{ limit }} ili vi\u0161e elemenata.|Ova kolekcija treba sadr\u017eavati {{ limit }} ili vi\u0161e elemenata.", "validators", "hr");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Ova kolekcija treba sadr\u017eavati {{ limit }} ili manje elemenata.|Ova kolekcija treba sadr\u017eavati {{ limit }} ili manje elemenata.|Ova kolekcija treba sadr\u017eavati {{ limit }} ili manje elemenata.", "validators", "hr");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Ova kolekcija treba sadr\u017eavati to\u010dno {{ limit }} element.|Ova kolekcija treba sadr\u017eavati to\u010dno {{ limit }} elementa.|Ova kolekcija treba sadr\u017eavati to\u010dno {{ limit }} elemenata.", "validators", "hr");
t.add("Invalid card number.", "Neispravan broj kartice.", "validators", "hr");
t.add("Unsupported card type or invalid card number.", "Tip kartice nije podr\u017ean ili je broj kartice neispravan.", "validators", "hr");
t.add("This is not a valid International Bank Account Number (IBAN).", "Ova vrijednost nije ispravan me\u0111unarodni broj bankovnog ra\u010duna (IBAN).", "validators", "hr");
t.add("This value is not a valid ISBN-10.", "Ova vrijednost nije ispravan ISBN-10.", "validators", "hr");
t.add("This value is not a valid ISBN-13.", "Ova vrijednost nije ispravan ISBN-13.", "validators", "hr");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Ova vrijednost nije ispravan ISBN-10 niti ISBN-13.", "validators", "hr");
t.add("This value is not a valid ISSN.", "Ova vrijednost nije ispravan ISSN.", "validators", "hr");
t.add("This value is not a valid currency.", "Ova vrijednost nije ispravna valuta.", "validators", "hr");
t.add("This value should be equal to {{ compared_value }}.", "Ova vrijednost treba biti jednaka {{ compared_value }}.", "validators", "hr");
t.add("This value should be greater than {{ compared_value }}.", "Ova vrijednost treba biti ve\u0107a od {{ compared_value }}.", "validators", "hr");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Ova vrijednost treba biti ve\u0107a od ili jednaka {{ compared_value }}.", "validators", "hr");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Ova vrijednost treba biti {{ compared_value_type }} {{ compared_value }}.", "validators", "hr");
t.add("This value should be less than {{ compared_value }}.", "Ova vrijednost treba biti manja od {{ compared_value }}.", "validators", "hr");
t.add("This value should be less than or equal to {{ compared_value }}.", "Ova vrijednost treba biti manja od ili jednaka {{ compared_value }}.", "validators", "hr");
t.add("This value should not be equal to {{ compared_value }}.", "Ova vrijednost treba biti razli\u010dita od {{ compared_value }}.", "validators", "hr");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Ova vrijednost treba biti razli\u010dita od {{ compared_value_type }} {{ compared_value }}.", "validators", "hr");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Omjer slike je prevelik ({{ ratio }}). Dozvoljeni maksimalni omjer je {{ max_ratio }}.", "validators", "hr");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Omjer slike je premali ({{ ratio }}). Minimalni o\u010dekivani omjer je {{ min_ratio }}.", "validators", "hr");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Slika je kvadratnog oblika ({{ width }}x{{ height }}px). Kvadratne slike nisu dozvoljene.", "validators", "hr");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Slika je orijentirana horizontalno ({{ width }}x{{ height }}px). Horizontalno orijentirane slike nisu dozvoljene.", "validators", "hr");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Slika je orijentirana vertikalno ({{ width }}x{{ height }}px). Vertikalno orijentirane slike nisu dozvoljene.", "validators", "hr");
t.add("An empty file is not allowed.", "Prazna datoteka nije dozvoljena.", "validators", "hr");
t.add("The host could not be resolved.", "Poslu\u017eitelj ne mo\u017ee biti prona\u0111en.", "validators", "hr");
t.add("This value does not match the expected {{ charset }} charset.", "Ova vrijednost ne odgovara o\u010dekivanom {{ charset }} znakovnom skupu.", "validators", "hr");
t.add("This is not a valid Business Identifier Code (BIC).", "Ovo nije validan poslovni identifikacijski broj (BIC).", "validators", "hr");
t.add("Error", "Gre\u0161ka", "validators", "hr");
t.add("This is not a valid UUID.", "Ovo nije validan UUID.", "validators", "hr");
t.add("This value should be a multiple of {{ compared_value }}.", "Ova vrijednost treba biti vi\u0161ekratnik od {{ compared_value }}.", "validators", "hr");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Poslovni identifikacijski broj (BIC) nije povezan sa IBAN brojem {{ iban }}.", "validators", "hr");
t.add("This value should be valid JSON.", "Ova vrijednost treba biti validan JSON.", "validators", "hr");
t.add("This collection should contain only unique elements.", "Ova kolekcija treba sadr\u017eavati samo unikatne elemente.", "validators", "hr");
t.add("This value should be positive.", "Ova vrijednost treba biti pozitivna.", "validators", "hr");
t.add("This value should be either positive or zero.", "Ova vrijednost treba biti pozitivna ili jednaka nuli.", "validators", "hr");
t.add("This value should be negative.", "Ova vrijednost treba biti negativna.", "validators", "hr");
t.add("This value should be either negative or zero.", "Ova vrijednost treba biti negativna ili jednaka nuli.", "validators", "hr");
t.add("This value is not a valid timezone.", "Ova vrijednost nije validna vremenska zona.", "validators", "hr");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Ova lozinka je procurila u nekom od sigurnosnih propusta, te je potrebno koristiti drugu lozinku.", "validators", "hr");
t.add("This value should be between {{ min }} and {{ max }}.", "Ova vrijednost treba biti izme\u0111u {{ min }} i {{ max }}.", "validators", "hr");
t.add("This value is not a valid hostname.", "Ova vrijednost nije ispravno ime poslu\u017eitelja (engl. hostname).", "validators", "hr");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Broj elemenata u kolekciji treba biti djeljiv s {{ compared_value }}.", "validators", "hr");
t.add("This value should satisfy at least one of the following constraints:", "Ova vrijednost mora zadovoljiti jedan od sljede\u0107ih ograni\u010denja:", "validators", "hr");
t.add("Each element of this collection should satisfy its own set of constraints.", "Svaki element ove kolekcije mora zadovoljiti vlastiti skup ograni\u010denja.", "validators", "hr");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Ova vrijednost nije ispravan me\u0111unarodni identifikacijski broj vrijednosnih papira (ISIN).", "validators", "hr");
t.add("This value should be a valid expression.", "Ova vrijednost mora biti valjani izraz.", "validators", "hr");
t.add("This value is not a valid CSS color.", "Ova vrijednost nije va\u017ee\u0107a CSS boja.", "validators", "hr");
t.add("This value is not a valid CIDR notation.", "Ova vrijednost nije valjana CIDR notacija.", "validators", "hr");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "Vrijednost mre\u017ene maske trebala bi biti izme\u0111u {{ min }} i {{ max }}.", "validators", "hr");
t.add("This form should not contain extra fields.", "Ovaj obrazac ne smije sadr\u017eavati dodatna polja.", "validators", "hr");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Prenesena datoteka je prevelika. Molim poku\u0161ajte prenijeti manju datoteku.", "validators", "hr");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF vrijednost nije ispravna. Poku\u0161ajte ponovo poslati obrazac.", "validators", "hr");
t.add("This value is not a valid HTML5 color.", "Ova vrijednost nije va\u017ee\u0107a HTML5 boja.", "validators", "hr");
t.add("Please enter a valid birthdate.", "Molim upi\u0161ite ispravan datum ro\u0111enja.", "validators", "hr");
t.add("The selected choice is invalid.", "Odabrani izbor nije ispravan.", "validators", "hr");
t.add("The collection is invalid.", "Kolekcija nije ispravna.", "validators", "hr");
t.add("Please select a valid color.", "Molim odaberite ispravnu boju.", "validators", "hr");
t.add("Please select a valid country.", "Molim odaberite ispravnu dr\u017eavu.", "validators", "hr");
t.add("Please select a valid currency.", "Molim odaberite ispravnu valutu.", "validators", "hr");
t.add("Please choose a valid date interval.", "Molim odaberite ispravni vremenski interval.", "validators", "hr");
t.add("Please enter a valid date and time.", "Molim unesite ispravni datum i vrijeme.", "validators", "hr");
t.add("Please enter a valid date.", "Molim odaberite ispravan datum.", "validators", "hr");
t.add("Please select a valid file.", "Molim odaberite ispravnu datoteku.", "validators", "hr");
t.add("The hidden field is invalid.", "Skriveno polje nije ispravno.", "validators", "hr");
t.add("Please enter an integer.", "Molim unesite cijeli broj.", "validators", "hr");
t.add("Please select a valid language.", "Molim odaberite ispravan jezik.", "validators", "hr");
t.add("Please select a valid locale.", "Molim odaberite ispravnu lokalizaciju.", "validators", "hr");
t.add("Please enter a valid money amount.", "Molim unesite ispravan iznos novca.", "validators", "hr");
t.add("Please enter a number.", "Molim unesite broj.", "validators", "hr");
t.add("The password is invalid.", "Ova lozinka nije ispravna.", "validators", "hr");
t.add("Please enter a percentage value.", "Molim unesite vrijednost postotka.", "validators", "hr");
t.add("The values do not match.", "Ove vrijednosti se ne poklapaju.", "validators", "hr");
t.add("Please enter a valid time.", "Molim unesite ispravno vrijeme.", "validators", "hr");
t.add("Please select a valid timezone.", "Molim odaberite ispravnu vremensku zonu.", "validators", "hr");
t.add("Please enter a valid URL.", "Molim unesite ispravan URL.", "validators", "hr");
t.add("Please enter a valid search term.", "Molim unesite ispravan pojam za pretra\u017eivanje.", "validators", "hr");
t.add("Please provide a valid phone number.", "Molim navedite ispravan telefonski broj.", "validators", "hr");
t.add("The checkbox has an invalid value.", "Polje za potvrdu sadr\u017ei neispravnu vrijednost.", "validators", "hr");
t.add("Please enter a valid email address.", "Molim unesite valjanu adresu elektronske po\u0161te.", "validators", "hr");
t.add("Please select a valid option.", "Molim odaberite ispravnu opciju.", "validators", "hr");
t.add("Please select a valid range.", "Molim odaberite ispravan raspon.", "validators", "hr");
t.add("Please enter a valid week.", "Molim unesite ispravni tjedan.", "validators", "hr");
})(Translator);
