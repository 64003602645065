(function (t) {
// th
t.add("An authentication exception occurred.", "\u0e1e\u0e1a\u0e04\u0e27\u0e32\u0e21\u0e1c\u0e34\u0e14\u0e1e\u0e25\u0e32\u0e14\u0e43\u0e19\u0e01\u0e32\u0e23\u0e23\u0e31\u0e1a\u0e23\u0e2d\u0e07\u0e15\u0e31\u0e27\u0e15\u0e19", "security", "th");
t.add("Authentication credentials could not be found.", "\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e43\u0e19\u0e01\u0e32\u0e23\u0e23\u0e31\u0e1a\u0e23\u0e2d\u0e07\u0e15\u0e31\u0e27\u0e15\u0e19 (credentials) ", "security", "th");
t.add("Authentication request could not be processed due to a system problem.", "\u0e04\u0e33\u0e23\u0e49\u0e2d\u0e07\u0e43\u0e19\u0e01\u0e32\u0e23\u0e23\u0e31\u0e1a\u0e23\u0e2d\u0e07\u0e15\u0e31\u0e27\u0e15\u0e19\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e14\u0e33\u0e40\u0e19\u0e34\u0e19\u0e01\u0e32\u0e23\u0e44\u0e14\u0e49 \u0e40\u0e19\u0e37\u0e48\u0e2d\u0e07\u0e21\u0e32\u0e08\u0e32\u0e01\u0e1b\u0e31\u0e0d\u0e2b\u0e32\u0e02\u0e2d\u0e07\u0e23\u0e30\u0e1a\u0e1a", "security", "th");
t.add("Invalid credentials.", "\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e01\u0e32\u0e23\u0e23\u0e31\u0e1a\u0e23\u0e2d\u0e07\u0e15\u0e31\u0e27\u0e15\u0e19\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "security", "th");
t.add("Cookie has already been used by someone else.", "Cookie \u0e16\u0e39\u0e01\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19\u0e44\u0e1b\u0e41\u0e25\u0e49\u0e27\u0e14\u0e49\u0e27\u0e22\u0e1c\u0e39\u0e49\u0e2d\u0e37\u0e48\u0e19", "security", "th");
t.add("Not privileged to request the resource.", "\u0e44\u0e21\u0e48\u0e44\u0e14\u0e49\u0e23\u0e31\u0e1a\u0e2a\u0e34\u0e17\u0e18\u0e34\u0e4c\u0e43\u0e2b\u0e49\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19\u0e2a\u0e48\u0e27\u0e19\u0e19\u0e35\u0e49\u0e44\u0e14\u0e49", "security", "th");
t.add("Invalid CSRF token.", "CSRF token \u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "security", "th");
t.add("No authentication provider found to support the authentication token.", "\u0e44\u0e21\u0e48\u0e1e\u0e1a authentication provider \u0e17\u0e35\u0e48\u0e23\u0e2d\u0e07\u0e23\u0e31\u0e1a\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a authentication token", "security", "th");
t.add("No session available, it either timed out or cookies are not enabled.", "\u0e44\u0e21\u0e48\u0e21\u0e35 session \u0e17\u0e35\u0e48\u0e1e\u0e23\u0e49\u0e2d\u0e21\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19, Session \u0e2b\u0e21\u0e14\u0e2d\u0e32\u0e22\u0e38\u0e44\u0e1b\u0e41\u0e25\u0e49\u0e27\u0e2b\u0e23\u0e37\u0e2d cookies \u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e40\u0e1b\u0e34\u0e14\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19", "security", "th");
t.add("No token could be found.", "\u0e44\u0e21\u0e48\u0e1e\u0e1a token", "security", "th");
t.add("Username could not be found.", "\u0e44\u0e21\u0e48\u0e1e\u0e1a Username", "security", "th");
t.add("Account has expired.", "\u0e1a\u0e31\u0e0d\u0e0a\u0e35\u0e2b\u0e21\u0e14\u0e2d\u0e32\u0e22\u0e38\u0e44\u0e1b\u0e41\u0e25\u0e49\u0e27", "security", "th");
t.add("Credentials have expired.", "\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25\u0e01\u0e32\u0e23\u0e23\u0e30\u0e1a\u0e38\u0e15\u0e31\u0e27\u0e15\u0e19\u0e2b\u0e21\u0e14\u0e2d\u0e32\u0e22\u0e38\u0e41\u0e25\u0e49\u0e27", "security", "th");
t.add("Account is disabled.", "\u0e1a\u0e31\u0e0d\u0e0a\u0e35\u0e16\u0e39\u0e01\u0e23\u0e30\u0e07\u0e31\u0e1a\u0e41\u0e25\u0e49\u0e27", "security", "th");
t.add("Account is locked.", "\u0e1a\u0e31\u0e0d\u0e0a\u0e35\u0e16\u0e39\u0e01\u0e25\u0e47\u0e2d\u0e01\u0e41\u0e25\u0e49\u0e27", "security", "th");
t.add("Too many failed login attempts, please try again later.", "\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e1e\u0e22\u0e32\u0e22\u0e32\u0e21\u0e40\u0e02\u0e49\u0e32\u0e2a\u0e39\u0e48\u0e23\u0e30\u0e1a\u0e1a\u0e25\u0e49\u0e21\u0e40\u0e2b\u0e25\u0e27\u0e21\u0e32\u0e01\u0e40\u0e01\u0e34\u0e19\u0e44\u0e1b \u0e01\u0e23\u0e38\u0e13\u0e32\u0e25\u0e2d\u0e07\u0e43\u0e2b\u0e21\u0e48\u0e20\u0e32\u0e22\u0e2b\u0e25\u0e31\u0e07", "security", "th");
t.add("Invalid or expired login link.", "\u0e25\u0e34\u0e07\u0e04\u0e4c\u0e40\u0e02\u0e49\u0e32\u0e2a\u0e39\u0e48\u0e23\u0e30\u0e1a\u0e1a\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e2b\u0e23\u0e37\u0e2d\u0e2b\u0e21\u0e14\u0e2d\u0e32\u0e22\u0e38\u0e44\u0e1b\u0e41\u0e25\u0e49\u0e27", "security", "th");
t.add("Too many failed login attempts, please try again in %minutes% minute.", "\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e1e\u0e22\u0e32\u0e22\u0e32\u0e21\u0e40\u0e02\u0e49\u0e32\u0e2a\u0e39\u0e48\u0e23\u0e30\u0e1a\u0e1a\u0e25\u0e49\u0e21\u0e40\u0e2b\u0e25\u0e27\u0e21\u0e32\u0e01\u0e40\u0e01\u0e34\u0e19\u0e44\u0e1b \u0e42\u0e1b\u0e23\u0e14\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07\u0e43\u0e19 %minutes% \u0e19\u0e32\u0e17\u0e35", "security", "th");
t.add("Too many failed login attempts, please try again in %minutes% minutes.", "\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e1e\u0e22\u0e32\u0e22\u0e32\u0e21\u0e40\u0e02\u0e49\u0e32\u0e2a\u0e39\u0e48\u0e23\u0e30\u0e1a\u0e1a\u0e25\u0e49\u0e21\u0e40\u0e2b\u0e25\u0e27\u0e21\u0e32\u0e01\u0e40\u0e01\u0e34\u0e19\u0e44\u0e1b \u0e42\u0e1b\u0e23\u0e14\u0e25\u0e2d\u0e07\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07\u0e43\u0e19 %minutes% \u0e19\u0e32\u0e17\u0e35", "security", "th");
})(Translator);
