(function (t) {
// en
t.add("menu.customers", "Customers", "messages", "en");
t.add("menu.customerGroups", "Customer groups", "messages", "en");
t.add("customers.export", "Export", "messages", "en");
t.add("menu.exercise", "Exercise", "messages", "en");
t.add("menu.exercise_list", "Exercise list", "messages", "en");
t.add("menu.category", "Category list", "messages", "en");
t.add("menu.planning", "Planning", "messages", "en");
t.add("menu.posts", "Posts", "messages", "en");
t.add("menu.courses", "Courses", "messages", "en");
t.add("menu.courses.course_categories", "Formation's categories", "messages", "en");
t.add("menu.profile", "Profile", "messages", "en");
t.add("menu.users", "Users", "messages", "en");
t.add("menu.exportActiveUsers", "Active users", "messages", "en");
t.add("menu.contact", "Contact", "messages", "en");
t.add("menu.logout", "Logout", "messages", "en");
t.add("common.datatable_actions", "Actions", "messages", "en");
t.add("planning.show_details", "Details", "messages", "en");
t.add("planning.duplicate_modal_title", "Duplicate the planning?", "messages", "en");
t.add("planning.duplicate", "Duplicate", "messages", "en");
t.add("planning.edit_modal_title", "Edit planning", "messages", "en");
t.add("planning.edit", "Edit", "messages", "en");
t.add("planning.remove_modal_title", "Delete planning", "messages", "en");
t.add("planning.remove", "Delete", "messages", "en");
t.add("planning.title", "Name", "messages", "en");
t.add("planning.session_count", "Session count", "messages", "en");
t.add("planning.category", "Category", "messages", "en");
t.add("planning.customer_name", "Customer name", "messages", "en");
t.add("category.title", "Title", "messages", "en");
t.add("category.details", "Details", "messages", "en");
t.add("category.edit", "Edit", "messages", "en");
t.add("category.delete", "Delete", "messages", "en");
t.add("course_category.name", "Name", "messages", "en");
t.add("course_category.details", "Details", "messages", "en");
t.add("course_category.edit", "Edit", "messages", "en");
t.add("course_category.delete", "Delete", "messages", "en");
t.add("courses.title", "Name", "messages", "en");
t.add("courses.therapists_count", "Therapists", "messages", "en");
t.add("courses.details", "Details", "messages", "en");
t.add("courses.remove_modal_title", "Delete course", "messages", "en");
t.add("courses.remove", "Delete", "messages", "en");
t.add("courses.edit", "Edit", "messages", "en");
t.add("menu.french", "French", "messages", "en");
t.add("menu.english", "English", "messages", "en");
t.add("menu.locale", "Language", "messages", "en");
t.add("menu.office", "Cabinet", "messages", "en");
t.add("office.remove", "Delete", "messages", "en");
t.add("office.edit", "Edit", "messages", "en");
t.add("error_page.title", "An Error Occurred", "messages", "en");
t.add("error_page.message", "The server returned a", "messages", "en");
t.add("post.view_original", "View original article", "messages", "en");
t.add("users.first_name", "First Name", "messages", "en");
t.add("users.email", "email adress", "messages", "en");
t.add("users.last_name", "Last name", "messages", "en");
t.add("users.customers_count", "Clients", "messages", "en");
t.add("users.k_start_count", "K-STARTS", "messages", "en");
t.add("users.dpr_count", "DPR", "messages", "en");
t.add("users.tar_count", "TAR", "messages", "en");
t.add("users.s_start_count", "S-STARTS", "messages", "en");
t.add("users.knee_planning", "PLANIF.KNEE", "messages", "en");
t.add("users.shoulder_planning", "PLANIF.SHOULDER", "messages", "en");
t.add("customer.pdf.cause", "Cause", "messages", "en");
t.add("customer_group.customers", "Customers in the group", "messages", "en");
t.add("exercise.chain.circuit_nr", "Circuit number", "messages", "en");
t.add("exercise.chain.isolated", "Isolated", "messages", "en");
t.add("exercise.validate_button", "Validate", "messages", "en");
t.add("planning_exercise.delete_modal_title", "Delete exercise", "messages", "en");
t.add("planning_exercise.delete", "Delete", "messages", "en");
t.add("planning.pdf.title", "Planning", "messages", "en");
t.add("of", "of", "messages", "en");
t.add("contact_form.sender", "Sender", "messages", "en");
t.add("contact_form.message", "Message", "messages", "en");
t.add("stat.users.list", "User connection statistics", "messages", "en");
t.add("stat.users", "users", "messages", "en");
t.add("stat.30.users.list", "Connected in the last 30 days", "messages", "en");
t.add("titles.90.users.list", "Connected between the last 31 and 90 days", "messages", "en");
t.add("titles.old.users.list", "Online for more than 91 days", "messages", "en");
t.add("stripe.payment.error", "An error occurred during payment. Please try again later", "messages", "en");
t.add("protocole.error.process", "An error occurred while creating the protocol. You have been received %amount% credit", "messages", "en");
t.add("protocole.credit.deficiency", "You do not have enough credits.", "messages", "en");
t.add("protocole.credit.error.process", "An error occurred during the creation of the protocol. You will not be charged for your credits.", "messages", "en");
t.add("menu.invoices", "Invoices", "messages", "en");
t.add("menu.buyCredit", "Buy Credits", "messages", "en");
t.add("menu.credit.admin", "Credit management", "messages", "en");
t.add("invoice.name", "Name", "messages", "en");
t.add("invoicing.pay", "Pay", "messages", "en");
t.add("invoice.created_at", "Created at", "messages", "en");
t.add("invoice.total", "Total", "messages", "en");
t.add("invoicing.validate", "Validate", "messages", "en");
t.add("protocole.paiement.buy", "Buy", "messages", "en");
t.add("protocole.paiement.buy_credits", "Buy credits", "messages", "en");
t.add("protocole.paiement.use_credits", "Use credits", "messages", "en");
t.add("protocole.paiement.use_credit", "Use credit", "messages", "en");
})(Translator);
