(function (t) {
// bg
t.add("An authentication exception occurred.", "\u0413\u0440\u0435\u0448\u043a\u0430 \u043f\u0440\u0438 \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u043a\u0430\u0446\u0438\u044f.", "security", "bg");
t.add("Authentication credentials could not be found.", "\u0423\u0434\u043e\u0441\u0442\u043e\u0432\u0435\u0440\u0435\u043d\u0438\u0435\u0442\u043e \u0437\u0430 \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u043a\u0430\u0446\u0438\u044f \u043d\u0435 \u0435 \u043e\u0442\u043a\u0440\u0438\u0442\u043e.", "security", "bg");
t.add("Authentication request could not be processed due to a system problem.", "\u0417\u0430\u044f\u0432\u043a\u0430\u0442\u0430 \u0437\u0430 \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u043a\u0430\u0446\u0438\u044f \u043d\u0435 \u043c\u043e\u0436\u0435 \u0434\u0430 \u0431\u044a\u0434\u0435 \u043e\u0431\u0440\u0430\u0431\u043e\u0442\u0435\u043d\u0430\u0442\u0430 \u043f\u043e\u0440\u0430\u0434\u0438 \u0441\u0438\u0441\u0442\u0435\u043c\u043d\u0430 \u0433\u0440\u0435\u0448\u043a\u0430.", "security", "bg");
t.add("Invalid credentials.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u043d\u043e \u0443\u0434\u043e\u0441\u0442\u043e\u0432\u0435\u0440\u0435\u043d\u0438\u0435 \u0437\u0430 \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u043a\u0430\u0446\u0438\u044f.", "security", "bg");
t.add("Cookie has already been used by someone else.", "\u0422\u0430\u0437\u0438 \u0431\u0438\u0441\u043a\u0432\u0438\u0442\u043a\u0430 \u0432\u0435\u0447\u0435 \u0441\u0435 \u043f\u043e\u043b\u0437\u0432\u0430 \u043e\u0442 \u043d\u044f\u043a\u043e\u0439 \u0434\u0440\u0443\u0433.", "security", "bg");
t.add("Not privileged to request the resource.", "\u041d\u044f\u043c\u0430\u0442\u0435 \u043f\u0440\u0430\u0432\u0430 \u0437\u0430 \u0434\u043e\u0441\u0442\u044a\u043f \u0434\u043e \u0442\u043e\u0437\u0438 \u0440\u0435\u0441\u0443\u0440\u0441.", "security", "bg");
t.add("Invalid CSRF token.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u0435\u043d CSRF \u0442\u043e\u043a\u0435\u043d.", "security", "bg");
t.add("No authentication provider found to support the authentication token.", "\u041d\u0435 \u0435 \u043e\u0442\u043a\u0440\u0438\u0442 \u043f\u0440\u043e\u0432\u0430\u0439\u0434\u044a\u0440, \u043a\u043e\u0439\u0442\u043e \u0434\u0430 \u043f\u043e\u0434\u0434\u044a\u0440\u0436\u0430 \u0442\u043e\u0437\u0438 \u0442\u043e\u043a\u0435\u043d \u0437\u0430 \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u043a\u0430\u0446\u0438\u044f.", "security", "bg");
t.add("No session available, it either timed out or cookies are not enabled.", "\u0421\u0435\u0441\u0438\u044f\u0442\u0430 \u043d\u0435 \u0435 \u0434\u043e\u0441\u0442\u044a\u043f\u043d\u0430, \u0438\u043b\u0438 \u0432\u0440\u0435\u043c\u0435\u0442\u043e \u0437\u0430 \u0434\u043e\u0441\u0442\u044a\u043f \u0435 \u0438\u0437\u0442\u0435\u043a\u043b\u043e, \u0438\u043b\u0438 \u0431\u0438\u0441\u043a\u0432\u0438\u0442\u043a\u0438\u0442\u0435 \u043d\u0435 \u0441\u0430 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u0438.", "security", "bg");
t.add("No token could be found.", "\u0422\u043e\u043a\u0435\u043d\u044a\u0442 \u043d\u0435 \u0435 \u043e\u0442\u043a\u0440\u0438\u0442.", "security", "bg");
t.add("Username could not be found.", "\u041f\u043e\u0442\u0440\u0435\u0431\u0438\u0442\u0435\u043b\u0441\u043a\u043e\u0442\u043e \u0438\u043c\u0435 \u043d\u0435 \u0435 \u043e\u0442\u043a\u0440\u0438\u0442\u043e.", "security", "bg");
t.add("Account has expired.", "\u0410\u043a\u0430\u0443\u043d\u0442\u044a\u0442 \u0435 \u0438\u0437\u0442\u0435\u043a\u044a\u043b.", "security", "bg");
t.add("Credentials have expired.", "\u0423\u0434\u043e\u0441\u0442\u043e\u0432\u0435\u0440\u0435\u043d\u0438\u0435\u0442\u043e \u0437\u0430 \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u043a\u0430\u0446\u0438\u044f \u0435 \u0438\u0437\u0442\u0435\u043a\u043b\u043e.", "security", "bg");
t.add("Account is disabled.", "\u0410\u043a\u0430\u0443\u043d\u0442\u044a\u0442 \u0435 \u0434\u0435\u0430\u043a\u0442\u0438\u0432\u0438\u0440\u0430\u043d.", "security", "bg");
t.add("Account is locked.", "\u0410\u043a\u0430\u0443\u043d\u0442\u044a\u0442 \u0435 \u0437\u0430\u043a\u043b\u044e\u0447\u0435\u043d.", "security", "bg");
t.add("Too many failed login attempts, please try again later.", "\u0422\u0432\u044a\u0440\u0434\u0435 \u043c\u043d\u043e\u0433\u043e \u043d\u0435\u0443\u0441\u043f\u0435\u0448\u043d\u0438 \u043e\u043f\u0438\u0442\u0438 \u0437\u0430 \u0432\u0445\u043e\u0434, \u043c\u043e\u043b\u044f \u043e\u043f\u0438\u0442\u0430\u0439\u0442\u0435 \u043f\u043e-\u043a\u044a\u0441\u043d\u043e.", "security", "bg");
t.add("Invalid or expired login link.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u0435\u043d \u0438\u043b\u0438 \u0438\u0437\u0442\u0435\u043a\u044a\u043b \u043b\u0438\u043d\u043a \u0437\u0430 \u0432\u0445\u043e\u0434.", "security", "bg");
t.add("Too many failed login attempts, please try again in %minutes% minute.", "\u0422\u0432\u044a\u0440\u0434\u0435 \u043c\u043d\u043e\u0433\u043e \u043d\u0435\u0443\u0441\u043f\u0435\u0448\u043d\u0438 \u043e\u043f\u0438\u0442\u0438 \u0437\u0430 \u0432\u0445\u043e\u0434, \u043c\u043e\u043b\u044f \u043e\u043f\u0438\u0442\u0430\u0439\u0442\u0435 \u043e\u0442\u043d\u043e\u0432\u043e \u0441\u043b\u0435\u0434 %minutes% \u043c\u0438\u043d\u0443\u0442\u0430.", "security", "bg");
t.add("Too many failed login attempts, please try again in %minutes% minutes.", "\u0422\u0432\u044a\u0440\u0434\u0435 \u043c\u043d\u043e\u0433\u043e \u043d\u0435\u0443\u0441\u043f\u0435\u0448\u043d\u0438 \u043e\u043f\u0438\u0442\u0438 \u0437\u0430 \u0432\u0445\u043e\u0434, \u043c\u043e\u043b\u044f \u043e\u043f\u0438\u0442\u0430\u0439\u0442\u0435 \u043e\u0442\u043d\u043e\u0432\u043e \u0441\u043b\u0435\u0434 %minutes% \u043c\u0438\u043d\u0443\u0442\u0438.", "security", "bg");
})(Translator);
