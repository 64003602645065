(function (t) {
// fr
t.add("exercise.pdf.name", "Nom", "pdf", "fr");
t.add("exercise.description", "Description", "pdf", "fr");
t.add("exercise.tools", "Accessoires", "pdf", "fr");
t.add("exercise.movement", "Mouvement", "pdf", "fr");
t.add("exercise.intensity", "Charge ou Intensit\u00e9", "pdf", "fr");
t.add("exercise.pdf.execution_speed", "Vitesse", "pdf", "fr");
t.add("exercise.repetitions", "R\u00e9p\u00e9titions ou temps", "pdf", "fr");
t.add("exercise.number_of_series", "Nombre de s\u00e9ries", "pdf", "fr");
t.add("exercise.intermediate_rest", "Repos", "pdf", "fr");
t.add("exercise.chain", "Ordre", "pdf", "fr");
t.add("exercise.chain.circuit_nr", "Circuit N\u00b0", "pdf", "fr");
t.add("exercise.chain.isolated", "Isol\u00e9", "pdf", "fr");
})(Translator);
