(function (t) {
// pt
t.add("This value should be false.", "Este valor deveria ser falso.", "validators", "pt");
t.add("This value should be true.", "Este valor deveria ser verdadeiro.", "validators", "pt");
t.add("This value should be of type {{ type }}.", "Este valor deveria ser do tipo {{ type }}.", "validators", "pt");
t.add("This value should be blank.", "Este valor deveria ser vazio.", "validators", "pt");
t.add("The value you selected is not a valid choice.", "O valor selecionado n\u00e3o \u00e9 uma op\u00e7\u00e3o v\u00e1lida.", "validators", "pt");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Voc\u00ea deveria selecionar {{ limit }} op\u00e7\u00e3o no m\u00ednimo.|Voc\u00ea deveria selecionar {{ limit }} op\u00e7\u00f5es no m\u00ednimo.", "validators", "pt");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Voc\u00ea deve selecionar, no m\u00e1ximo {{ limit }} op\u00e7\u00e3o.|Voc\u00ea deve selecionar, no m\u00e1ximo {{ limit }} op\u00e7\u00f5es.", "validators", "pt");
t.add("One or more of the given values is invalid.", "Um ou mais dos valores introduzidos n\u00e3o s\u00e3o v\u00e1lidos.", "validators", "pt");
t.add("This field was not expected.", "Este campo n\u00e3o era esperado.", "validators", "pt");
t.add("This field is missing.", "Este campo est\u00e1 faltando.", "validators", "pt");
t.add("This value is not a valid date.", "Este valor n\u00e3o \u00e9 uma data v\u00e1lida.", "validators", "pt");
t.add("This value is not a valid datetime.", "Este valor n\u00e3o \u00e9 uma data-hora v\u00e1lida.", "validators", "pt");
t.add("This value is not a valid email address.", "Este valor n\u00e3o \u00e9 um endere\u00e7o de e-mail v\u00e1lido.", "validators", "pt");
t.add("The file could not be found.", "O arquivo n\u00e3o p\u00f4de ser encontrado.", "validators", "pt");
t.add("The file is not readable.", "O arquivo n\u00e3o p\u00f4de ser lido.", "validators", "pt");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "O arquivo \u00e9 muito grande ({{ size }} {{ suffix }}). O tamanho m\u00e1ximo permitido \u00e9 de {{ limit }} {{ suffix }}.", "validators", "pt");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "O tipo mime do arquivo \u00e9 inv\u00e1lido ({{ type }}). Os tipos mime permitidos s\u00e3o {{ types }}.", "validators", "pt");
t.add("This value should be {{ limit }} or less.", "Este valor deveria ser {{ limit }} ou menor.", "validators", "pt");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "O valor \u00e9 muito longo. Deveria ter {{ limit }} caracteres ou menos.", "validators", "pt");
t.add("This value should be {{ limit }} or more.", "Este valor deveria ser {{ limit }} ou mais.", "validators", "pt");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "O valor \u00e9 muito curto. Deveria de ter {{ limit }} caractere ou mais.|O valor \u00e9 muito curto. Deveria de ter {{ limit }} caracteres ou mais.", "validators", "pt");
t.add("This value should not be blank.", "Este valor n\u00e3o deveria ser branco\/vazio.", "validators", "pt");
t.add("This value should not be null.", "Este valor n\u00e3o deveria ser nulo.", "validators", "pt");
t.add("This value should be null.", "Este valor deveria ser nulo.", "validators", "pt");
t.add("This value is not valid.", "Este valor n\u00e3o \u00e9 v\u00e1lido.", "validators", "pt");
t.add("This value is not a valid time.", "Este valor n\u00e3o \u00e9 uma hora v\u00e1lida.", "validators", "pt");
t.add("This value is not a valid URL.", "Este valor n\u00e3o \u00e9 um URL v\u00e1lido.", "validators", "pt");
t.add("The two values should be equal.", "Os dois valores deveriam ser iguais.", "validators", "pt");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "O arquivo \u00e9 muito grande. O tamanho m\u00e1ximo permitido \u00e9 de {{ limit }} {{ suffix }}.", "validators", "pt");
t.add("The file is too large.", "O ficheiro \u00e9 muito grande.", "validators", "pt");
t.add("The file could not be uploaded.", "N\u00e3o foi poss\u00edvel carregar o ficheiro.", "validators", "pt");
t.add("This value should be a valid number.", "Este valor deveria ser um n\u00famero v\u00e1lido.", "validators", "pt");
t.add("This file is not a valid image.", "Este ficheiro n\u00e3o \u00e9 uma imagem.", "validators", "pt");
t.add("This is not a valid IP address.", "Este endere\u00e7o de IP n\u00e3o \u00e9 v\u00e1lido.", "validators", "pt");
t.add("This value is not a valid language.", "Este valor n\u00e3o \u00e9 uma linguagem v\u00e1lida.", "validators", "pt");
t.add("This value is not a valid locale.", "Este valor n\u00e3o \u00e9 um 'locale' v\u00e1lido.", "validators", "pt");
t.add("This value is not a valid country.", "Este valor n\u00e3o \u00e9 um Pa\u00eds v\u00e1lido.", "validators", "pt");
t.add("This value is already used.", "Este valor j\u00e1 est\u00e1 a ser usado.", "validators", "pt");
t.add("The size of the image could not be detected.", "O tamanho da imagem n\u00e3o foi detetado.", "validators", "pt");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "A largura da imagem ({{ width }}px) \u00e9 muito grande. A largura m\u00e1xima da imagem \u00e9: {{ max_width }}px.", "validators", "pt");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "A largura da imagem ({{ width }}px) \u00e9 muito pequena. A largura min\u00edma da imagem \u00e9 de: {{ min_width }}px.", "validators", "pt");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "A altura da imagem ({{ height }}px) \u00e9 muito grande. A altura m\u00e1xima da imagem \u00e9 de: {{ max_height }}px.", "validators", "pt");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "A altura da imagem ({{ height }}px) \u00e9 muito pequena. A altura min\u00edma da imagem \u00e9 de: {{ min_height }}px.", "validators", "pt");
t.add("This value should be the user's current password.", "Este valor deveria ser a senha atual do usu\u00e1rio.", "validators", "pt");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Este valor deve possuir exatamente {{ limit }} caracteres.", "validators", "pt");
t.add("The file was only partially uploaded.", "S\u00f3 foi enviada uma parte do arquivo.", "validators", "pt");
t.add("No file was uploaded.", "Nenhum arquivo foi enviado.", "validators", "pt");
t.add("No temporary folder was configured in php.ini.", "N\u00e3o existe uma pasta tempor\u00e1ria configurada no arquivo php.ini.", "validators", "pt");
t.add("Cannot write temporary file to disk.", "N\u00e3o foi poss\u00edvel escrever os arquivos tempor\u00e1rios no disco.", "validators", "pt");
t.add("A PHP extension caused the upload to fail.", "Uma extens\u00e3o PHP causou a falha no envio.", "validators", "pt");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Esta cole\u00e7\u00e3o deve conter {{ limit }} elemento ou mais.|Esta cole\u00e7\u00e3o deve conter {{ limit }} elementos ou mais.", "validators", "pt");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Esta cole\u00e7\u00e3o deve conter {{ limit }} elemento ou menos.|Esta cole\u00e7\u00e3o deve conter {{ limit }} elementos ou menos.", "validators", "pt");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Esta cole\u00e7\u00e3o deve conter exatamente {{ limit }} elemento.|Esta cole\u00e7\u00e3o deve conter exatamente {{ limit }} elementos.", "validators", "pt");
t.add("Invalid card number.", "N\u00famero de cart\u00e3o inv\u00e1lido.", "validators", "pt");
t.add("Unsupported card type or invalid card number.", "Tipo de cart\u00e3o n\u00e3o suportado ou n\u00famero de cart\u00e3o inv\u00e1lido.", "validators", "pt");
t.add("This is not a valid International Bank Account Number (IBAN).", "Este n\u00e3o \u00e9 um N\u00famero Internacional de Conta Banc\u00e1ria (IBAN) v\u00e1lido.", "validators", "pt");
t.add("This value is not a valid ISBN-10.", "Este valor n\u00e3o \u00e9 um ISBN-10 v\u00e1lido.", "validators", "pt");
t.add("This value is not a valid ISBN-13.", "Este valor n\u00e3o \u00e9 um ISBN-13 v\u00e1lido.", "validators", "pt");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Este valor n\u00e3o \u00e9 um ISBN-10 ou ISBN-13 v\u00e1lido.", "validators", "pt");
t.add("This value is not a valid ISSN.", "Este valor n\u00e3o \u00e9 um ISSN v\u00e1lido.", "validators", "pt");
t.add("This value is not a valid currency.", "Este n\u00e3o \u00e9 um valor monet\u00e1rio v\u00e1lido.", "validators", "pt");
t.add("This value should be equal to {{ compared_value }}.", "Este valor deve ser igual a {{ compared_value }}.", "validators", "pt");
t.add("This value should be greater than {{ compared_value }}.", "Este valor deve ser superior a {{ compared_value }}.", "validators", "pt");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Este valor deve ser igual ou superior a {{ compared_value }}.", "validators", "pt");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Este valor deve ser id\u00eantico a {{ compared_value_type }} {{ compared_value }}.", "validators", "pt");
t.add("This value should be less than {{ compared_value }}.", "Este valor deve ser inferior a {{ compared_value }}.", "validators", "pt");
t.add("This value should be less than or equal to {{ compared_value }}.", "Este valor deve ser igual ou inferior a {{ compared_value }}.", "validators", "pt");
t.add("This value should not be equal to {{ compared_value }}.", "Este valor n\u00e3o deve ser igual a {{ compared_value }}.", "validators", "pt");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Este valor n\u00e3o deve ser id\u00eantico a {{ compared_value_type }} {{ compared_value }}.", "validators", "pt");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "O formato da imagem \u00e9 muito grande ({{ ratio }}). O formato m\u00e1ximo \u00e9 {{ max_ratio }}.", "validators", "pt");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "O formato da imagem \u00e9 muito pequeno ({{ ratio }}). O formato m\u00ednimo esperado \u00e9 {{ min_ratio }}.", "validators", "pt");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "A imagem \u00e9 um quadrado ({{ width }}x{{ height }}px). Imagens quadradas n\u00e3o s\u00e3o permitidas.", "validators", "pt");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "A imagem est\u00e1 em orienta\u00e7\u00e3o de paisagem ({{ width }}x{{ height }}px). Imagens orientadas em paisagem n\u00e3o s\u00e3o permitidas.", "validators", "pt");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "A imagem est\u00e1 em orienta\u00e7\u00e3o de retrato ({{ width }}x{{ height }}px). Imagens orientadas em retrato n\u00e3o s\u00e3o permitidas.", "validators", "pt");
t.add("An empty file is not allowed.", "Um arquivo vazio n\u00e3o \u00e9 permitido.", "validators", "pt");
t.add("The host could not be resolved.", "O host n\u00e3o pode ser resolvido.", "validators", "pt");
t.add("This value does not match the expected {{ charset }} charset.", "O valor n\u00e3o corresponde ao conjunto de caracteres {{ charset }} esperado.", "validators", "pt");
t.add("This is not a valid Business Identifier Code (BIC).", "O C\u00f3digo de Identifica\u00e7\u00e3o de Empresa (BIC) n\u00e3o \u00e9 v\u00e1lido.", "validators", "pt");
t.add("Error", "Erro", "validators", "pt");
t.add("This is not a valid UUID.", "Este valor n\u00e3o \u00e9 um UUID v\u00e1lido.", "validators", "pt");
t.add("This value should be a multiple of {{ compared_value }}.", "Este valor deve ser um m\u00faltiplo de {{ compared_value }}.", "validators", "pt");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "O C\u00f3digo de Identifica\u00e7\u00e3o de Empresa (BIC) n\u00e3o est\u00e1 associado ao IBAN {{ iban }}.", "validators", "pt");
t.add("This value should be valid JSON.", "Este valor deve ser um JSON v\u00e1lido.", "validators", "pt");
t.add("This collection should contain only unique elements.", "Esta cole\u00e7\u00e3o deve conter s\u00f3 elementos \u00fanicos.", "validators", "pt");
t.add("This value should be positive.", "Este valor deve ser estritamente positivo.", "validators", "pt");
t.add("This value should be either positive or zero.", "Este valor deve ser superior ou igual a zero.", "validators", "pt");
t.add("This value should be negative.", "Este valor deve ser estritamente negativo.", "validators", "pt");
t.add("This value should be either negative or zero.", "Este valor deve ser inferior ou igual a zero.", "validators", "pt");
t.add("This value is not a valid timezone.", "Este valor n\u00e3o \u00e9 um fuso hor\u00e1rio v\u00e1lido.", "validators", "pt");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Esta senha foi divulgada durante uma fuga de dados, n\u00e3o deve ser usada de novamente. Por favor usar uma senha outra.", "validators", "pt");
t.add("This value should be between {{ min }} and {{ max }}.", "Este valor deve situar-se entre {{ min }} e {{ max }}.", "validators", "pt");
t.add("This value is not a valid hostname.", "Este valor n\u00e3o \u00e9 um nome de host v\u00e1lido.", "validators", "pt");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "O n\u00famero de elementos desta cole\u00e7\u00e3o deve ser um m\u00faltiplo de {{ compared_value }}.", "validators", "pt");
t.add("This value should satisfy at least one of the following constraints:", "Este valor deve satisfazer pelo menos uma das seguintes restri\u00e7\u00f5es :", "validators", "pt");
t.add("Each element of this collection should satisfy its own set of constraints.", "Cada elemento desta cole\u00e7\u00e3o deve satisfazer o seu pr\u00f3prio conjunto de restri\u00e7\u00f5es.", "validators", "pt");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Este valor n\u00e3o \u00e9 um N\u00famero Internacional de Identifica\u00e7\u00e3o de Seguran\u00e7a (ISIN) v\u00e1lido.", "validators", "pt");
t.add("This value should be a valid expression.", "Este valor deve ser uma express\u00e3o v\u00e1lida.", "validators", "pt");
t.add("This value is not a valid CSS color.", "Este valor n\u00e3o \u00e9 uma cor de CSS v\u00e1lida.", "validators", "pt");
t.add("This value is not a valid CIDR notation.", "Este valor n\u00e3o \u00e9 uma nota\u00e7\u00e3o CIDR v\u00e1lida.", "validators", "pt");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "O valor da m\u00e1scara de rede deve estar entre {{ min }} e {{ max }}.", "validators", "pt");
t.add("This form should not contain extra fields.", "Este formul\u00e1rio n\u00e3o deveria possuir mais campos.", "validators", "pt");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "O ficheiro enviado \u00e9 muito grande. Por favor, tente enviar um ficheiro menor.", "validators", "pt");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "O token CSRF est\u00e1 inv\u00e1lido. Por favor, tente enviar o formul\u00e1rio novamente.", "validators", "pt");
t.add("This value is not a valid HTML5 color.", "Este valor n\u00e3o \u00e9 uma cor HTML5 v\u00e1lida.", "validators", "pt");
t.add("Please enter a valid birthdate.", "Por favor, informe uma data de nascimento v\u00e1lida.", "validators", "pt");
t.add("The selected choice is invalid.", "A escolha seleccionada \u00e9 inv\u00e1lida.", "validators", "pt");
t.add("The collection is invalid.", "A cole\u00e7\u00e3o \u00e9 inv\u00e1lida.", "validators", "pt");
t.add("Please select a valid color.", "Por favor, selecione uma cor v\u00e1lida.", "validators", "pt");
t.add("Please select a valid country.", "Por favor, selecione um pa\u00eds v\u00e1lido.", "validators", "pt");
t.add("Please select a valid currency.", "Por favor, selecione uma moeda v\u00e1lida.", "validators", "pt");
t.add("Please choose a valid date interval.", "Por favor, escolha um intervalo de datas v\u00e1lido.", "validators", "pt");
t.add("Please enter a valid date and time.", "Por favor, informe uma data e hor\u00e1rio v\u00e1lidos.", "validators", "pt");
t.add("Please enter a valid date.", "Por favor, informe uma data v\u00e1lida.", "validators", "pt");
t.add("Please select a valid file.", "Por favor, selecione um ficheiro v\u00e1lido.", "validators", "pt");
t.add("The hidden field is invalid.", "O campo oculto \u00e9 inv\u00e1lido.", "validators", "pt");
t.add("Please enter an integer.", "Por favor, informe um inteiro.", "validators", "pt");
t.add("Please select a valid language.", "Por favor selecione um idioma v\u00e1lido.", "validators", "pt");
t.add("Please select a valid locale.", "Por favor, selecione um locale v\u00e1lido.", "validators", "pt");
t.add("Please enter a valid money amount.", "Por favor, informe um valor monet\u00e1rio v\u00e1lido.", "validators", "pt");
t.add("Please enter a number.", "Por favor, informe um n\u00famero.", "validators", "pt");
t.add("The password is invalid.", "A palavra-passe \u00e9 inv\u00e1lida.", "validators", "pt");
t.add("Please enter a percentage value.", "Por favor, informe um valor percentual.", "validators", "pt");
t.add("The values do not match.", "Os valores n\u00e3o correspondem.", "validators", "pt");
t.add("Please enter a valid time.", "Por favor, informe uma hora v\u00e1lida.", "validators", "pt");
t.add("Please select a valid timezone.", "Por favor, selecione um fuso hor\u00e1rio v\u00e1lido.", "validators", "pt");
t.add("Please enter a valid URL.", "Por favor, informe uma URL v\u00e1lida.", "validators", "pt");
t.add("Please enter a valid search term.", "Por favor, informe um termo de busca v\u00e1lido.", "validators", "pt");
t.add("Please provide a valid phone number.", "Por favor, infome um n\u00famero de telefone v\u00e1lido.", "validators", "pt");
t.add("The checkbox has an invalid value.", "O checkbox possui um valor inv\u00e1lido.", "validators", "pt");
t.add("Please enter a valid email address.", "Por favor, informe um endere\u00e7o de email v\u00e1lido.", "validators", "pt");
t.add("Please select a valid option.", "Por favor, selecione uma op\u00e7\u00e3o v\u00e1lida.", "validators", "pt");
t.add("Please select a valid range.", "Por favor, selecione um intervalo v\u00e1lido.", "validators", "pt");
t.add("Please enter a valid week.", "Por favor, selecione uma semana v\u00e1lida.", "validators", "pt");
})(Translator);
