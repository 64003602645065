(function (t) {
// en
t.add("user.password_requested", "Password reset", "users", "en");
t.add("user.password_requested_message", "It seems that password reset request\u2026", "users", "en");
t.add("user.password_reset_link", "To change your password, please\u2026", "users", "en");
t.add("user.reset_password", "Reset", "users", "en");
t.add("user.password_reset_title", "Password reset", "users", "en");
t.add("user.enter_a_new_password", "Please enter the new password", "users", "en");
t.add("user.password_reset_email_sent", "Veuillez v\u00e9rifier votre email et suivez les instructions pour r\u00e9initialiser votre mot de passe.", "users", "en");
})(Translator);
