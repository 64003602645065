(function (t) {
// en
t.add("titles.customer.details", "Customer's details", "labels", "en");
t.add("titles.planning.templates_list", "Planning template list", "labels", "en");
t.add("titles.customers.general_information", "General information", "labels", "en");
t.add("titles.customers.knees", "Knees", "labels", "en");
t.add("titles.customers.shoulders", "Shoulders", "labels", "en");
t.add("titles.customers.hips", "Hips", "labels", "en");
t.add("titles.customers.ankle", "Ankles", "labels", "en");
t.add("titles.customers.other_lower_limbs", "Other lower limbs", "labels", "en");
t.add("titles.customers.back", "Back", "labels", "en");
t.add("buttons.edit", "Edit", "labels", "en");
t.add("modal.confirmation", "Are you sure you want to do this action?", "labels", "en");
t.add("action.cancel", "Cancel", "labels", "en");
t.add("action.confirm", "Confirm", "labels", "en");
t.add("titles.planning.list", "Customer planning list", "labels", "en");
t.add("planning.modal.add", "Add", "labels", "en");
t.add("planning.buttons.add", "Add", "labels", "en");
t.add("titles.customers.list", "Customers list", "labels", "en");
t.add("buttons.add", "Add", "labels", "en");
t.add("course.title", "Name", "labels", "en");
t.add("titles.courses.list", "Course list", "labels", "en");
t.add("titles.courses.edit", "Edit course", "labels", "en");
t.add("titles.planning_exercises.edit", "Edit exercise", "labels", "en");
t.add("planning_exercise.form.delete", "Delete", "labels", "en");
t.add("exercise_session.form.next", "Ok", "labels", "en");
t.add("exercise_session.form.cancel", "Cancel", "labels", "en");
t.add("exercise_session.button.add_planning", "Add planning", "labels", "en");
t.add("exercise_session.button.add_session", "Add session", "labels", "en");
t.add("planning.filter.title", "Planning name", "labels", "en");
t.add("planning.filter.customer", "Customer name", "labels", "en");
t.add("titles.posts.list", "Posts", "labels", "en");
t.add("titles.posts.top", "Posts", "labels", "en");
t.add("post.draft_post", "Draft", "labels", "en");
t.add("post.edit_draft", "Edit draft", "labels", "en");
t.add("post.view_post", "View post", "labels", "en");
t.add("post.search", "Search", "labels", "en");
t.add("post.btn.create", "Add post", "labels", "en");
t.add("common.search", "Search", "labels", "en");
t.add("titles.posts.add", "Add post", "labels", "en");
t.add("titles.posts.back_to_list", "Back to post list", "labels", "en");
t.add("post.image.dropzone", "Drag file or click to add an image.", "labels", "en");
t.add("post.image.label", "Image label", "labels", "en");
t.add("category.select.all", "All", "labels", "en");
t.add("category.select.label", "Category", "labels", "en");
t.add("post.modal.delete", "Delete post", "labels", "en");
t.add("post.edit_post", "Edit", "labels", "en");
t.add("buttons.adjust", "Adjust", "labels", "en");
t.add("buttons.cancel", "Cancel", "labels", "en");
t.add("buttons.save", "Save", "labels", "en");
t.add("planning.send_pdf_via_email", "Send by email", "labels", "en");
t.add("planning.form.pdf_send", "Send", "labels", "en");
t.add("pdf.download", "Download", "labels", "en");
t.add("test.send_pdf_via_email", "Send by email", "labels", "en");
t.add("dashboard.news_label", "Training news", "labels", "en");
t.add("dashboard.news_more_info", "Click here for more information", "labels", "en");
t.add("dashboard.join_us_button", "Who are we?", "labels", "en");
t.add("dashboard.view_offers", "View offers", "labels", "en");
t.add("dashboard.last_articles", "Last posts", "labels", "en");
t.add("dashboard.clients_list", "Customer list", "labels", "en");
t.add("dashboard.more_customers", "See more", "labels", "en");
t.add("dashboard.site_internet_title", "Do you want to discover the latest ATHLETIC news ?", "labels", "en");
t.add("dashboard.site_internet_label", "Website", "labels", "en");
t.add("dashboard.site_internet_content", "Pour tous connaitre de l\u2019activit\u00e9 ATHLETIC ainsi que d\u00e9couvrir le r\u00e9seau de professionnels du sport et de la sant\u00e9 labellis\u00e9s, cliquez ci-dessous", "labels", "en");
t.add("dashboard.site_internet_button", "Click here for more information", "labels", "en");
t.add("dashboard.testimony_title", "The ATHLETIC trainig, you're the one who talks about it the best ", "labels", "en");
t.add("dashboard.testimony_label", "Testimony", "labels", "en");
t.add("dashboard.testimony_content", "\"During my years of practice, I realized that we, physiotherapist, were performing only until Day 90 after an ACL surgery, making difficult the return to sport at 100% for patients\"\n        ", "labels", "en");
t.add("dashboard.testimony_button", "Cliquez ici pour livre vos t\u00e9moignages", "labels", "en");
t.add("dashboard.title", "Primary and secondary prevention specialist", "labels", "en");
t.add("titles.dashboard", "Home", "labels", "en");
t.add("contact_page.title", "Contact", "labels", "en");
t.add("dashboard.news_title", "Do you want to consolidate your expertise in knee reathletization phase ?", "labels", "en");
t.add("dashboard.news_content", "Venez d\u00e9couvrir nos prochaines sessions qui vous permettront d\u2019acqu\u00e9rir une expertise compl\u00e8te et d\u2019appartenir \u00e0 notre r\u00e9seau de professionnels labellis\u00e9s.", "labels", "en");
t.add("titles.office.list", "Cabinets list", "labels", "en");
t.add("office.btn.add", "Add cabinet", "labels", "en");
t.add("office.name", "Cabinet name", "labels", "en");
t.add("office.form.save", "Save", "labels", "en");
t.add("office.modal.add", "Create new cabinet", "labels", "en");
t.add("user.offices", "Cabinets", "labels", "en");
t.add("office.therapists", "Therapists", "labels", "en");
t.add("customer.offices", "Cabinets", "labels", "en");
t.add("contact_page.form_title", "Contact form", "labels", "en");
t.add("contact_page.message_submitted", "Your message has been sent", "labels", "en");
t.add("buttons.send", "Send", "labels", "en");
t.add("titles.posts.details", "Article", "labels", "en");
t.add("titles.posts.edit", "Edit", "labels", "en");
t.add("post.form.delete", "Delete", "labels", "en");
t.add("post.button.add", "Validate", "labels", "en");
t.add("post.button.save_as_draft", "Save a draft", "labels", "en");
t.add("titles.customers.add", "Add customer", "labels", "en");
t.add("titles.customers.edit", "Edit customer", "labels", "en");
t.add("buttons.validate", "Validate", "labels", "en");
t.add("titles.users.details", "User details", "labels", "en");
t.add("titles.users.credits", "User credits", "labels", "en");
t.add("user.firstName", "First Name", "labels", "en");
t.add("user.lastName", "Last Name", "labels", "en");
t.add("user.email", "Email Address", "labels", "en");
t.add("user.role", "Type", "labels", "en");
t.add("user.credit.admin", "Credit management", "labels", "en");
t.add("titles.users.add", "Add User", "labels", "en");
t.add("titles.users.edit", "Edit user", "labels", "en");
t.add("titles.users.list", "Users list", "labels", "en");
t.add("titles.users.profile", "Profile", "labels", "en");
t.add("user.profile.informations", "Profile Informations", "labels", "en");
t.add("titles.planning_exercises.add", "Add exercise", "labels", "en");
t.add("titles.categories.details", "Detail category", "labels", "en");
t.add("category.name", "Category Name", "labels", "en");
t.add("titles.categories.add", "Add category", "labels", "en");
t.add("titles.categories.edit", "Edit category", "labels", "en");
t.add("titles.categories.list", "Categories list", "labels", "en");
t.add("titles.course_categories.list", "Formation's categories list", "labels", "en");
t.add("titles.course_categories.add", "Add formation's category", "labels", "en");
t.add("titles.course_categories.details", "D\u00e9tails formation's cat\u00e9gory", "labels", "en");
t.add("titles.course_categories.edit", "Edit formation's category", "labels", "en");
t.add("course_category.name", "Formation's category name", "labels", "en");
t.add("test.form.pdf_send", "Send", "labels", "en");
t.add("titles.customer_group.details", "Group details", "labels", "en");
t.add("titles.customer_group.add", "Add client group", "labels", "en");
t.add("titles.customers_groups.top", "Client group", "labels", "en");
t.add("titles.customer_group.edit", "Edit group", "labels", "en");
t.add("titles.customer_groups.list", "Group list", "labels", "en");
t.add("exercise.btn.add-to-session", "Session", "labels", "en");
t.add("titles.exercises.add", "Add exercise", "labels", "en");
t.add("titles.exercises.edit", "Edit exercise", "labels", "en");
t.add("titles.exercises.list", "Exercises list", "labels", "en");
t.add("exercise.btn.add", "Add exercise", "labels", "en");
t.add("exercise.name", "Exercise name", "labels", "en");
t.add("type.select.label", "Type", "labels", "en");
t.add("type.select.all", "All", "labels", "en");
t.add("planning.form.save", "Save", "labels", "en");
t.add("course.buttons.add_image", "Add image", "labels", "en");
t.add("course.buttons.add_video", "Add video", "labels", "en");
t.add("exercise.button.save_as_draft", "Save a draft", "labels", "en");
t.add("titles.planning.details", "Planning details", "labels", "en");
t.add("planning_session.modal.edit", "Add session", "labels", "en");
t.add("planning_session.duplicate_modal_title", "Duplicate session", "labels", "en");
t.add("planning_session.modal.delete", "Delete Session", "labels", "en");
t.add("planning.buttons.add_exercise", "Exercise", "labels", "en");
t.add("planning.modal.add_session", "Add session", "labels", "en");
t.add("planning.buttons.add_session", "Add session", "labels", "en");
t.add("planning.available_actions", "Available actions list", "labels", "en");
t.add("planning.quick_actions", "Quick actions", "labels", "en");
t.add("planning.modal.edit", "Edit planning", "labels", "en");
t.add("planning.edit", "Edit planning", "labels", "en");
t.add("planning.download_pdf", "Download PDF planning", "labels", "en");
t.add("planning.delete_planning", "Delete planning", "labels", "en");
t.add("planning.duplicate_planning", "Duplicate planning", "labels", "en");
t.add("planning.session", "Session", "labels", "en");
t.add("planning_session.form.delete", "Delete", "labels", "en");
t.add("planning.form.delete", "Delete", "labels", "en");
t.add("planning.form.duplicate", "Validate", "labels", "en");
t.add("planning.form.cancel", "Cancel", "labels", "en");
t.add("planning_session.form.save", "Save", "labels", "en");
t.add("titles.courses.add", "Add training", "labels", "en");
t.add("course.form.add_therapist", "Add", "labels", "en");
t.add("course.form.cancel", "Cancel", "labels", "en");
t.add("course.form.delete_therapist", "Delete therapist", "labels", "en");
t.add("course.form.delete", "Delete", "labels", "en");
t.add("course.buttons.add_document", "Add File", "labels", "en");
t.add("buttons.stat_global", "Global statistics", "labels", "en");
t.add("buttons.add.image", "__buttons.add.image", "labels", "en");
t.add("test.generate_planification", "Generate protocole", "labels", "en");
t.add("stat.users.list", "__stat.users.list", "labels", "en");
t.add("stat.users", "__stat.users", "labels", "en");
t.add("stat.30.users.list", "__stat.30.users.list", "labels", "en");
t.add("titles.90.users.list", "__titles.90.users.list", "labels", "en");
t.add("titles.old.users.list", "__titles.old.users.list", "labels", "en");
t.add("user.startAt", "__user.startAt", "labels", "en");
t.add("user.endAt", "__user.endAt", "labels", "en");
t.add("user.submit", "__user.submit", "labels", "en");
t.add("titles.course_category.details", "__titles.course_category.details", "labels", "en");
})(Translator);
