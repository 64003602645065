(function (t) {
// fr
t.add("user.startAt", "Date de d\u00e9but", "labels", "fr");
t.add("user.endAt", "Date de fin", "labels", "fr");
t.add("user.firstName", "Pr\u00e9nom", "labels", "fr");
t.add("user.lastName", "Nom", "labels", "fr");
t.add("user.role", "Type", "labels", "fr");
t.add("modal.confirmation", "\u00cates-vous s\u00fbr de vouloir r\u00e9aliser cette action ?", "labels", "fr");
t.add("buttons.add", "Ajouter", "labels", "fr");
t.add("titles.users.add", "Ajouter un utilisateur", "labels", "fr");
t.add("titles.users.list", "Liste des utilisateurs", "labels", "fr");
t.add("stat.users", "utilisateurs", "labels", "fr");
t.add("stat.users.list", "Statistiques de connexions des utilisateurs", "labels", "fr");
t.add("stat.30.users.list", "Connect\u00e9s dans les 30 derniers jours", "labels", "fr");
t.add("titles.90.users.list", "Connect\u00e9s entre les 31 et 90 derniers jours", "labels", "fr");
t.add("titles.old.users.list", "Connect\u00e9s depuis plus de 90 jours", "labels", "fr");
t.add("titles.dashboard", "Accueil", "labels", "fr");
t.add("titles.users.details", "Details de l'utilisateur", "labels", "fr");
t.add("titles.users.credits", "Cr\u00e9dits de l'utilisateur", "labels", "fr");
t.add("titles.users.customers", "Clients de l'utilisateur", "labels", "fr");
t.add("titles.users.tests", "Tests de l'utilisateur", "labels", "fr");
t.add("titles.users.plannings", "Planifications de l'utilisateur", "labels", "fr");
t.add("buttons.edit", "Modifier", "labels", "fr");
t.add("user.email", "Email", "labels", "fr");
t.add("user.credit", "Cr\u00e9dits", "labels", "fr");
t.add("user.credit.admin", "Gestion des Cr\u00e9dits", "labels", "fr");
t.add("titles.users.edit", "Modifier l'utilisateur", "labels", "fr");
t.add("buttons.save", "Enregistrer", "labels", "fr");
t.add("titles.categories.list", "Liste des cat\u00e9gories", "labels", "fr");
t.add("titles.course_categories.list", "Liste des cat\u00e9gories de formation", "labels", "fr");
t.add("titles.course_categories.add", "Ajouter une cat\u00e9gorie de formation", "labels", "fr");
t.add("titles.course_categories.details", "D\u00e9tails de la cat\u00e9gorie", "labels", "fr");
t.add("titles.course_categories.edit", "Modifier la cat\u00e9gorie", "labels", "fr");
t.add("course_category.name", "Nom de la cat\u00e9gorie de formation", "labels", "fr");
t.add("category.name", "Nom de la cat\u00e9gorie", "labels", "fr");
t.add("titles.categories.add", "Ajouter une cat\u00e9gorie", "labels", "fr");
t.add("titles.categories.details", "D\u00e9tails de la cat\u00e9gorie", "labels", "fr");
t.add("titles.categories.edit", "Modifier la cat\u00e9gorie", "labels", "fr");
t.add("titles.exercises.add", "Ajouter un exercice", "labels", "fr");
t.add("titles.exercises.edit", "Modifier l'exercice", "labels", "fr");
t.add("titles.exercises.list", "Liste des exercices", "labels", "fr");
t.add("exercise.btn.add", "Ajouter un exercice", "labels", "fr");
t.add("exercise.name", "Nom de l\u2019exercice\u2026", "labels", "fr");
t.add("category.select.all", "Toutes", "labels", "fr");
t.add("category.select.label", "Cat\u00e9gorie", "labels", "fr");
t.add("type.select.label", "Type", "labels", "fr");
t.add("type.select.all", "Toutes", "labels", "fr");
t.add("titles.customers.add", "Ajouter un client", "labels", "fr");
t.add("titles.customers.edit", "Modifier le client", "labels", "fr");
t.add("titles.customer.details", "D\u00e9tails du client", "labels", "fr");
t.add("titles.customers.list", "Liste des clients", "labels", "fr");
t.add("buttons.add.image", "Nouvelle image", "labels", "fr");
t.add("exercise.btn.add-to-session", "S\u00e9ance", "labels", "fr");
t.add("planning.session", "S\u00e9ance", "labels", "fr");
t.add("titles.exercises.top", "Catalogue", "labels", "fr");
t.add("titles.categories.top", "Cat\u00e9gorie", "labels", "fr");
t.add("titles.categories.back_to_list", "Retour \u00e0 la liste", "labels", "fr");
t.add("titles.users.top", "Utilisateurs", "labels", "fr");
t.add("titles.users.back_to_list", "Retour \u00e0 la liste", "labels", "fr");
t.add("titles.customers.top", "Clients", "labels", "fr");
t.add("titles.customers.back_to_list", "Retour \u00e0 la liste des clients", "labels", "fr");
t.add("titles.planning.list", "Liste des planifications clients", "labels", "fr");
t.add("titles.planning.top", "Planifications", "labels", "fr");
t.add("planning.title", "Nom", "labels", "fr");
t.add("planning.buttons.add", "Nouvelle planification", "labels", "fr");
t.add("planning.modal.add", "Cr\u00e9er une nouvelle planification", "labels", "fr");
t.add("planning.form.save", "Sauvegarder", "labels", "fr");
t.add("titles.planning.details", "D\u00e9tail de la planification", "labels", "fr");
t.add("titles.planning.back_to_list", "Retour \u00e0 la liste des planifications", "labels", "fr");
t.add("planning.modal.edit", "Modifier la planification", "labels", "fr");
t.add("planning.modal.add_session", "Ajouter une s\u00e9ance", "labels", "fr");
t.add("planning.buttons.add_session", "Ajouter une s\u00e9ance", "labels", "fr");
t.add("planning_session.form.save", "Sauvegarder", "labels", "fr");
t.add("planning.buttons.add_exercise", "Exercice", "labels", "fr");
t.add("planning.download_pdf", "T\u00e9l\u00e9charger la planification en PDF", "labels", "fr");
t.add("planning.delete_planning", "Supprimer la planification ?", "labels", "fr");
t.add("planning.duplicate_planning", "Dupliquer la planification", "labels", "fr");
t.add("planning_session.modal.edit", "Modifier la s\u00e9ance", "labels", "fr");
t.add("planning_session.modal.delete", "Supprimer la s\u00e9ance", "labels", "fr");
t.add("planning_session.form.delete", "Supprimer", "labels", "fr");
t.add("planning.form.delete", "Supprimer", "labels", "fr");
t.add("planning.form.duplicate", "Valider", "labels", "fr");
t.add("planning.form.cancel", "Annuler", "labels", "fr");
t.add("titles.customer_group.details", "D\u00e9tails du groupe", "labels", "fr");
t.add("titles.customer_group.add", "Nouveau groupe de clients", "labels", "fr");
t.add("titles.customers_groups.top", "Groupe de clients", "labels", "fr");
t.add("titles.back_to_list", "Retour \u00e0 la liste", "labels", "fr");
t.add("titles.customer_group.edit", "Modifier le groupe", "labels", "fr");
t.add("titles.customer_groups.list", "Liste des groupes", "labels", "fr");
t.add("planning.quick_actions", "Actions rapides", "labels", "fr");
t.add("titles.planning_exercises.add", "Ajouter un exercice", "labels", "fr");
t.add("titles.planning_exercises.top", "Planifier un exercice", "labels", "fr");
t.add("titles.planning_exercises.edit", "Modifier l'exercice", "labels", "fr");
t.add("planning_exercise.form.delete", "Supprimer", "labels", "fr");
t.add("exercise_session.form.next", "Valider", "labels", "fr");
t.add("exercise_session.form.cancel", "Annuler", "labels", "fr");
t.add("exercise_session.button.add_planning", "Nouvelle planification", "labels", "fr");
t.add("exercise_session.button.add_session", "Nouvelle s\u00e9ance", "labels", "fr");
t.add("planning.filter.title", "Nom de la planification", "labels", "fr");
t.add("planning.filter.customer", "Nom du client", "labels", "fr");
t.add("titles.planning.templates_list", "Liste des planifications types", "labels", "fr");
t.add("titles.posts.list", "Articles", "labels", "fr");
t.add("titles.posts.top", "Articles", "labels", "fr");
t.add("post.draft_post", "Brouillon", "labels", "fr");
t.add("post.edit_draft", "Modifier le brouillon", "labels", "fr");
t.add("post.view_post", "Voir l\u2019article", "labels", "fr");
t.add("post.search", "Rechercher", "labels", "fr");
t.add("post.btn.create", "Cr\u00e9er un article", "labels", "fr");
t.add("common.search", "Recherche", "labels", "fr");
t.add("titles.posts.add", "Cr\u00e9ation d\u2019un article", "labels", "fr");
t.add("titles.posts.back_to_list", "Retour \u00e0 la liste des articles", "labels", "fr");
t.add("post.image.dropzone", "Glissez un fichier ou cliquez pour ajouter une image.", "labels", "fr");
t.add("post.image.label", "Image d\u2019en-t\u00eate", "labels", "fr");
t.add("post.button.add", "Valider", "labels", "fr");
t.add("titles.posts.details", "Article", "labels", "fr");
t.add("post.button.save_as_draft", "Sauvegarder en brouillon", "labels", "fr");
t.add("titles.posts.edit", "Modifier l'article", "labels", "fr");
t.add("post.form.delete", "Supprimer", "labels", "fr");
t.add("post.modal.delete", "Supprimer l'article", "labels", "fr");
t.add("post.edit_post", "Modifier", "labels", "fr");
t.add("exercise.button.save_as_draft", "Sauvegarder en brouillon", "labels", "fr");
t.add("titles.planning_exercises.add_top", "Modification de la session", "labels", "fr");
t.add("titles.courses.list", "Liste des formations", "labels", "fr");
t.add("titles.courses.top", "Formations", "labels", "fr");
t.add("course.title", "Nom", "labels", "fr");
t.add("titles.courses.add", "Cr\u00e9ation d\u2019une formation", "labels", "fr");
t.add("course.buttons.add_document", "Ajouter un document", "labels", "fr");
t.add("titles.courses.edit", "Modifier la formation", "labels", "fr");
t.add("titles.customers.general_information", "Informations g\u00e9n\u00e9rales", "labels", "fr");
t.add("buttons.validate", "Valider", "labels", "fr");
t.add("titles.customers.knees", "Genou", "labels", "fr");
t.add("titles.customers.shoulders", "\u00c9paule", "labels", "fr");
t.add("titles.customers.hips", "Hanche", "labels", "fr");
t.add("titles.customers.ankle", "Cheville", "labels", "fr");
t.add("titles.customers.other_lower_limbs", "Autres membres inf\u00e9rieurs", "labels", "fr");
t.add("titles.customers.back", "Dos", "labels", "fr");
t.add("action.cancel", "Annuler", "labels", "fr");
t.add("action.confirm", "Confirmer", "labels", "fr");
t.add("course.form.delete", "Supprimer", "labels", "fr");
t.add("course.form.delete_therapist", "Supprimer le th\u00e9rapeute ?", "labels", "fr");
t.add("course.form.add_therapist", "Ajouter", "labels", "fr");
t.add("course.form.cancel", "Annuler", "labels", "fr");
t.add("titles.users.profile", "Mon compte", "labels", "fr");
t.add("users.customers_count", "Clients", "labels", "fr");
t.add("users.k_start_count", "K-STARTS", "labels", "fr");
t.add("users.dpr_count", "DPR", "labels", "fr");
t.add("users.tar_count", "TAR", "labels", "fr");
t.add("users.s_start_count", "S-STARTS", "labels", "fr");
t.add("users.knee_planning", "PLANIF.GENOU", "labels", "fr");
t.add("users.shoulder_planning", "PLANIF.EPAULE", "labels", "fr");
t.add("dashboard.news_title", "Vous souhaitez consolider votre expertise dans la r\u00e9athl\u00e9tisation genou ?", "labels", "fr");
t.add("dashboard.news_content", " Venez d\u00e9couvrir nos prochaines sessions qui vous permettront d\u2019acqu\u00e9rir une expertise compl\u00e8te et d\u2019appartenir \u00e0 notre r\u00e9seau de professionnels labellis\u00e9s.", "labels", "fr");
t.add("dashboard.news_label", "Actualit\u00e9s formations", "labels", "fr");
t.add("dashboard.news_more_info", "Cliquez ici pour en savoir +", "labels", "fr");
t.add("dashboard.join_us_button", "Qui sommes-nous ?", "labels", "fr");
t.add("dashboard.view_offers", "Voir les offres", "labels", "fr");
t.add("dashboard.last_articles", "Les derniers articles", "labels", "fr");
t.add("dashboard.clients_list", "Liste des clients", "labels", "fr");
t.add("dashboard.more_customers", "Voir plus", "labels", "fr");
t.add("contact_page.title", "Contact", "labels", "fr");
t.add("contact_page.form_title", "Formulaire de contact", "labels", "fr");
t.add("buttons.send", "Envoyer", "labels", "fr");
t.add("contact_page.message_submitted", "Votre message a bien \u00e9t\u00e9 envoy\u00e9", "labels", "fr");
t.add("contact_page.back_to_form", "Retour au formulaire de contact", "labels", "fr");
t.add("user.profile.informations", "Informations", "labels", "fr");
t.add("planning_session.duplicate_modal_title", "Dupliquer cette s\u00e9ance ?", "labels", "fr");
t.add("planning.send_pdf_via_email", "Envoyer par email", "labels", "fr");
t.add("planning.form.pdf_send", "Envoyer", "labels", "fr");
t.add("pdf.download", "T\u00e9l\u00e9charger", "labels", "fr");
t.add("planning.edit", "Modifier la planification", "labels", "fr");
t.add("test.send_pdf_via_email", "Envoyer par email", "labels", "fr");
t.add("test.form.pdf_send", "Envoyer", "labels", "fr");
t.add("titles.customers.plannings", "Planifications", "labels", "fr");
t.add("titles.test", "Test", "labels", "fr");
t.add("planning.available_actions", "Liste des actions disponibles", "labels", "fr");
t.add("buttons.adjust", "Ajuster", "labels", "fr");
t.add("buttons.cancel", "Annuler", "labels", "fr");
t.add("dashboard.site_internet_title", "Vous souhaitez d\u00e9couvrir les derni\u00e8res actualit\u00e9s ATHLETIC ?", "labels", "fr");
t.add("dashboard.site_internet_label", "Site internet", "labels", "fr");
t.add("dashboard.site_internet_content", "Pour tous connaitre de l\u2019activit\u00e9 ATHLETIC ainsi que d\u00e9couvrir le r\u00e9seau de\n          professionnels du sport et de la sant\u00e9 labellis\u00e9s, cliquez ci-dessous\n        ", "labels", "fr");
t.add("dashboard.site_internet_button", "Cliquez ici pour en savoir +", "labels", "fr");
t.add("dashboard.testimony_title", "La formation ATHLETIC, c\u2019est vous qui en parlez le mieux !", "labels", "fr");
t.add("dashboard.testimony_label", "T\u00e9moignage", "labels", "fr");
t.add("dashboard.testimony_content", "\"Au fil de mes ann\u00e9es de pratique, je me suis rendu compte que nous, kin\u00e9sith\u00e9rapeutes, n\u2019\u00e9tions performants que jusqu\u2019\u00e0 J+90 apr\u00e8s une op\u00e9ration du LCA rendant difficile une reprise du sport \u00e0 100% pour les patients\"\n        ", "labels", "fr");
t.add("dashboard.testimony_button", "Cliquez ici pour lire vos t\u00e9moignages", "labels", "fr");
t.add("dashboard.title", "Specialiste de la pr\u00e9vention primaire et secondaire", "labels", "fr");
t.add("course.buttons.add_video", "Ajouter une vid\u00e9o", "labels", "fr");
t.add("course.buttons.add_image", "Ajouter une image", "labels", "fr");
t.add("user.active", "Activ\u00e9", "labels", "fr");
t.add("user.last_login", "Derni\u00e8re connexion", "labels", "fr");
t.add("titles.office.list", "Liste des cabinets", "labels", "fr");
t.add("office.btn.add", "Ajouter un cabinet", "labels", "fr");
t.add("office.name", "Nom du cabinet", "labels", "fr");
t.add("office.form.save", "Sauvegarder", "labels", "fr");
t.add("office.modal.add", "Cr\u00e9er un nouveau cabinet", "labels", "fr");
t.add("user.offices", "Cabinets", "labels", "fr");
t.add("office.therapists", "Th\u00e9rapeutes", "labels", "fr");
t.add("customer.offices", "Cabinets", "labels", "fr");
t.add("buttons.stat_global", "Statistiques globales", "labels", "fr");
t.add("global_statistics.open.title", "Ouvertures d'acc\u00e8s", "labels", "fr");
t.add("global_statistics.open.subtitle", "utilisateurs cr\u00e9\u00e9s au total et par type de formation", "labels", "fr");
t.add("global_statistics.userEnableOrNot.title", "Nombre utilisateurs activ\u00e9s", "labels", "fr");
t.add("global_statistics.userEnableOrNot.subtitle", "personnes se sont connect\u00e9es au moins une fois sur la p\u00e9riode pour changer leur mot de passe", "labels", "fr");
t.add("global_statistics.usersLastConnected.title", "Nombre de connexions", "labels", "fr");
t.add("global_statistics.usersLastConnected.subtitle", "utilisateurs qui se sont connect\u00e9s sur la p\u00e9riode r\u00e9partis par type", "labels", "fr");
t.add("global_statistics.userEnableOrNot.details", "soit %count% utilisateurs en attente.", "labels", "fr");
t.add("global_statistics.customers.title", "Nombre de clients", "labels", "fr");
t.add("global_statistics.customers.subtitle", "cr\u00e9\u00e9s sur la p\u00e9riode\n         et r\u00e9partis en interne \/ externe", "labels", "fr");
t.add("global_statistics.tests.title", "Nombre de tests", "labels", "fr");
t.add("global_statistics.tests.subtitle", "Par type", "labels", "fr");
t.add("global_statistics.planning.title", "Nombre de planifications", "labels", "fr");
t.add("global_statistics.planning.subtitle", "Par cat\u00e9gories", "labels", "fr");
t.add("global_statistics.protocole.title", "Protocoles automatis\u00e9s", "labels", "fr");
t.add("global_statistics.protocole.subtitle", "protocoles cr\u00e9\u00e9s au total et par cat\u00e9gorie", "labels", "fr");
t.add("titles.course_category.details", "__titles.course_category.details", "labels", "fr");
t.add("user.submit", "Envoyer", "labels", "fr");
t.add("test.generate_planification", "G\u00e9n\u00e9rer le protocole", "labels", "fr");
})(Translator);
