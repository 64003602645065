(function (t) {
// my
t.add("An authentication exception occurred.", "\u1021\u101e\u102f\u1036\u1038\u1015\u103c\u102f\u1001\u103d\u1004\u103a\u1037 \u1001\u103c\u103d\u1004\u103a\u1038\u1001\u103b\u1000\u103a\u1010\u1005\u103a\u1001\u102f\u1016\u103c\u1005\u103a\u101e\u103d\u102c\u1038\u101e\u100a\u103a\u104b", "security", "my");
t.add("Authentication credentials could not be found.", "\u1021\u101e\u102f\u1036\u1038\u1015\u103c\u102f\u1001\u103d\u1004\u103a\u1037 \u1021\u1011\u1031\u102c\u1000\u103a\u1021\u1011\u102c\u1038\u1019\u103b\u102c\u1038 \u101b\u103e\u102c\u1019\u1010\u103d\u1031\u1037\u1015\u102b\u104b", "security", "my");
t.add("Authentication request could not be processed due to a system problem.", "System \u1015\u103c\u103f\u1014\u102c\u1021\u1001\u1000\u103a\u1021\u1001\u1032\u101b\u103e\u102d \u1014\u1031\u1015\u102b\u101e\u1016\u103c\u1004\u103a\u1037 \u1021\u101e\u102f\u1036\u1038\u1015\u103c\u102f\u1001\u103d\u1004\u103a\u1037\u1010\u1031\u102c\u1004\u103a\u1038\u1006\u102d\u102f\u1001\u103b\u1000\u103a\u1000\u102d\u102f \u1006\u1031\u102c\u1004\u103a\u101b\u103d\u1000\u103a\u104d\u1019\u101b \u1014\u102d\u102f\u1004\u103a\u1015\u102b\u104b", "security", "my");
t.add("Invalid credentials.", "\u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a \u1021\u1011\u1031\u102c\u1000\u103a\u1021\u1011\u102c\u1038\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "security", "my");
t.add("Cookie has already been used by someone else.", "Cookie \u1000\u102d\u102f \u1010\u1005\u103a\u1005\u102f\u1036\u1010\u1005\u103a\u101a\u1031\u102c\u1000\u103a\u1019\u103e \u1021\u101e\u102f\u1036\u1038\u1015\u103c\u102f\u1015\u103c\u102e\u1038\u1016\u103c\u1005\u103a\u101e\u100a\u103a\u104b", "security", "my");
t.add("Not privileged to request the resource.", "\u1021\u101b\u1004\u103a\u1038\u1021\u1019\u103c\u1005\u103a\u1000\u102d\u102f\u1010\u1031\u102c\u1004\u103a\u1038\u1006\u102d\u102f\u101b\u1014\u103a\u1021\u1001\u103d\u1004\u1037\u103a\u1011\u1030\u1038\u1019\u101b\u1015\u102b\u104b", "security", "my");
t.add("Invalid CSRF token.", "\u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a CSRF token \u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "security", "my");
t.add("No authentication provider found to support the authentication token.", "\u1021\u1011\u1031\u102c\u1000\u103a\u1021\u1011\u102c\u1038\u1005\u102d\u1005\u1005\u103a\u1001\u103c\u1004\u103a\u1038\u101e\u1004\u103a\u1039\u1000\u1031\u1010\u1000\u102d\u102f\u1015\u1036\u1037\u1015\u102d\u102f\u1038\u101b\u1014\u103a \u1019\u100a\u103a\u101e\u100a\u1037\u103a\u1021\u1011\u1031\u102c\u1000\u103a\u1021\u1011\u102c\u1038\u1005\u102d\u1005\u1005\u103a\u101b\u1031\u1038 \u1040\u1014\u103a\u1006\u1031\u102c\u1004\u103a\u1019\u103e\u102f\u1019\u103e\u1019\u1010\u103d\u1031\u1037\u1015\u102b\u104b", "security", "my");
t.add("No session available, it either timed out or cookies are not enabled.", "Session \u1019\u1021\u102c\u1038\u101c\u1015\u103a\u1015\u102b\u104b Session \u1021\u1001\u103b\u102d\u1014\u103a\u1000\u102f\u1014\u103a\u101e\u103d\u102c\u1038\u1001\u103c\u1004\u103a\u1038 (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) cookies \u1019\u103b\u102c\u1038\u1000\u102d\u102f\u1016\u103d\u1004\u1037\u103a\u1011\u102c\u1038\u1001\u103c\u1004\u103a\u1038\u1019\u101b\u103e\u102d\u1015\u102b\u104b", "security", "my");
t.add("No token could be found.", "Toke  \u101b\u103e\u102c\u1019\u1010\u103d\u1031\u1037\u1015\u102b\u104b", "security", "my");
t.add("Username could not be found.", "\u1021\u101e\u102f\u1036\u1038\u1015\u103c\u102f\u101e\u1030\u1021\u1019\u100a\u103a \u101b\u103e\u102c\u1016\u103d\u1031\u1010\u103d\u1031\u1037\u101b\u103e\u102d\u1001\u103b\u1004\u103a\u1038\u1019\u101b\u103e\u102d\u1015\u102b\u104b", "security", "my");
t.add("Account has expired.", "\u1021\u1000\u1031\u102c\u1004\u103a\u1037 \u101e\u1000\u103a\u1010\u1019\u103a\u1038\u1000\u102f\u1014\u103a\u101c\u103d\u1014\u103a\u101e\u103d\u102c\u1038\u1015\u102b\u1015\u103c\u102e\u104b", "security", "my");
t.add("Credentials have expired.", "\u1021\u1011\u1031\u102c\u1000\u103a\u1021\u1011\u102c\u1038\u101e\u1000\u103a\u1010\u1014\u103a\u1038 \u1000\u102f\u1014\u103a\u101c\u103d\u1014\u103a\u101e\u103d\u102c\u1038\u1015\u102b\u1015\u103c\u102e\u104b", "security", "my");
t.add("Account is disabled.", "\u1021\u1000\u1031\u102c\u1004\u103a\u1037\u1015\u102d\u1010\u103a\u1011\u102c\u1038\u1015\u102b\u101e\u100a\u103a\u104b", "security", "my");
t.add("Account is locked.", "\u1021\u1000\u1031\u102c\u1004\u103a\u1037 \u101c\u1031\u102c\u1001\u103a\u1000\u103b\u101e\u103d\u102c\u1038\u1015\u102b\u1015\u103c\u102e\u104b", "security", "my");
t.add("Too many failed login attempts, please try again later.", "Login \u1040\u1004\u103a\u101b\u1014\u103a\u1000\u103c\u102d\u102f\u1038\u1005\u102c\u1038\u1019\u103e\u102f\u1019\u103b\u102c\u1038\u101c\u103d\u1014\u103a\u1038\u1015\u102b\u101e\u100a\u103a\u104a \u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1014\u1031\u102c\u1000\u103a\u1019\u103e\u1011\u1015\u103a\u1000\u103c\u102d\u102f\u1038\u1005\u102c\u1038\u1015\u102b\u104b", "security", "my");
t.add("Invalid or expired login link.", "\u1019\u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u101e\u1000\u103a\u1010\u1014\u103a\u1038\u1000\u102f\u1014\u103a\u101e\u1031\u102c login link \u1016\u103c\u1005\u103a\u1015\u102b\u101e\u100a\u103a\u104b", "security", "my");
t.add("Too many failed login attempts, please try again in %minutes% minute.", "Too many failed login attempts, please try again in %minutes% minute.", "security", "my");
t.add("Too many failed login attempts, please try again in %minutes% minutes.", "Login \u1040\u1004\u103a\u101b\u1014\u103a\u1000\u103c\u102d\u102f\u1038\u1005\u102c\u1038\u1019\u103e\u102f\u1019\u103b\u102c\u1038\u101c\u103d\u1014\u103a\u1038\u1015\u102b\u101e\u100a\u103a\u104a \u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1014\u1031\u102c\u1000\u103a %minutes% \u1019\u103e\u1011\u1015\u103a\u1019\u1036\u1000\u103c\u102d\u102f\u1038\u1005\u102c\u1038\u1015\u102b\u104b", "security", "my");
})(Translator);
