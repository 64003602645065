(function (t) {
// fr
t.add("An authentication exception occurred.", "Une exception d'authentification s'est produite.", "security", "fr");
t.add("Authentication credentials could not be found.", "Les identifiants d'authentification n'ont pas pu \u00eatre trouv\u00e9s.", "security", "fr");
t.add("Authentication request could not be processed due to a system problem.", "La requ\u00eate d'authentification n'a pas pu \u00eatre execut\u00e9e \u00e0 cause d'un probl\u00e8me syst\u00e8me.", "security", "fr");
t.add("Invalid credentials.", "Le mot de passe que vous avez saisi est incorrect. Veuillez r\u00e9essayer.", "security", "fr");
t.add("Cookie has already been used by someone else.", "Le cookie a d\u00e9j\u00e0 \u00e9t\u00e9 utilis\u00e9 par quelqu'un d'autre.", "security", "fr");
t.add("Not privileged to request the resource.", "Privil\u00e8ges insuffisants pour acc\u00e9der \u00e0 la ressource.", "security", "fr");
t.add("Invalid CSRF token.", "Merci de recharger la page et de vous reconnecter.", "security", "fr");
t.add("No authentication provider found to support the authentication token.", "Aucun fournisseur d'authentification n'a \u00e9t\u00e9 trouv\u00e9 pour supporter le jeton d'authentification.", "security", "fr");
t.add("No session available, it either timed out or cookies are not enabled.", "Aucune session disponible, celle-ci a expir\u00e9 ou les cookies ne sont pas activ\u00e9s.", "security", "fr");
t.add("No token could be found.", "Aucun jeton n'a pu \u00eatre trouv\u00e9.", "security", "fr");
t.add("Username could not be found.", "L'adresse email n'a pas pu \u00eatre trouv\u00e9e", "security", "fr");
t.add("Account has expired.", "Le compte a expir\u00e9.", "security", "fr");
t.add("Credentials have expired.", "Les identifiants ont expir\u00e9.", "security", "fr");
t.add("Account is disabled.", "Le compte est d\u00e9sactiv\u00e9.", "security", "fr");
t.add("Account is locked.", "Le compte est bloqu\u00e9.", "security", "fr");
t.add("Too many failed login attempts, please try again later.", "Plusieurs tentatives de connexion ont \u00e9chou\u00e9, veuillez r\u00e9essayer plus tard.", "security", "fr");
t.add("Invalid or expired login link.", "Lien de connexion invalide ou expir\u00e9.", "security", "fr");
t.add("Too many failed login attempts, please try again in %minutes% minute.", "Plusieurs tentatives de connexion ont \u00e9chou\u00e9, veuillez r\u00e9essayer dans %minutes% minute.", "security", "fr");
t.add("Too many failed login attempts, please try again in %minutes% minutes.", "Plusieurs tentatives de connexion ont \u00e9chou\u00e9, veuillez r\u00e9essayer dans %minutes% minutes.", "security", "fr");
t.add("Login", "Connexion", "security", "fr");
t.add("Email", "Email", "security", "fr");
t.add("Password", "Mot de passe", "security", "fr");
t.add("Remember me", "Se souvenir de moi", "security", "fr");
t.add("Forgotten Password ?", "Mot de passe oubli\u00e9 ?", "security", "fr");
t.add("Bad credentials.", "Le login ou le mot de passe que vous avez saisi est incorrect. Veuillez r\u00e9essayer.", "security", "fr");
t.add("Enter your email to reset your password:", "Veuillez saisir votre email pour r\u00e9initialiser votre mot de passe :", "security", "fr");
t.add("Request", "Demander", "security", "fr");
t.add("Cancel", "Annuler", "security", "fr");
t.add("Logout", "D\u00e9connexion", "security", "fr");
t.add("Phone", "T\u00e9l\u00e9phone", "security", "fr");
t.add("Enter your phone to reset your password:", "Entrez votre t\u00e9l\u00e9phone pour r\u00e9initialiser votre mot de passe :", "security", "fr");
t.add("Change Password", "Changer le mot de passe", "security", "fr");
t.add("Enter the secret code you received on your phone to change your password:", "Entrez le code secret que vous avez re\u00e7u sur votre t\u00e9l\u00e9phone pour changer votre mot de passe :", "security", "fr");
t.add("Secret Code", "Code secret", "security", "fr");
t.add("New Password", "Nouveau mot de passe", "security", "fr");
t.add("reset_failure", "Impossible de r\u00e9initialiser le mot de passe", "security", "fr");
t.add("reset_success", "Le mot de passe est r\u00e9initialis\u00e9, veuillez vous connecter avec le nouveau mot de passe", "security", "fr");
t.add("Change", "Modifier", "security", "fr");
})(Translator);
