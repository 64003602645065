(function (t) {
// cs
t.add("This value should be false.", "Tato hodnota mus\u00ed b\u00fdt nepravdiv\u00e1 (false).", "validators", "cs");
t.add("This value should be true.", "Tato hodnota mus\u00ed b\u00fdt pravdiv\u00e1 (true).", "validators", "cs");
t.add("This value should be of type {{ type }}.", "Tato hodnota mus\u00ed b\u00fdt typu {{ type }}.", "validators", "cs");
t.add("This value should be blank.", "Tato hodnota mus\u00ed b\u00fdt pr\u00e1zdn\u00e1.", "validators", "cs");
t.add("The value you selected is not a valid choice.", "Vybran\u00e1 hodnota nen\u00ed platnou mo\u017enost\u00ed.", "validators", "cs");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Mus\u00ed b\u00fdt vybr\u00e1na nejm\u00e9n\u011b {{ limit }} mo\u017enost.|Mus\u00ed b\u00fdt vybr\u00e1ny nejm\u00e9n\u011b {{ limit }} mo\u017enosti.|Mus\u00ed b\u00fdt vybr\u00e1no nejm\u00e9n\u011b {{ limit }} mo\u017enost\u00ed.", "validators", "cs");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Mus\u00ed b\u00fdt vybr\u00e1na maxim\u00e1ln\u011b {{ limit }} mo\u017enost.|Mus\u00ed b\u00fdt vybr\u00e1ny maxim\u00e1ln\u011b {{ limit }} mo\u017enosti.|Mus\u00ed b\u00fdt vybr\u00e1no maxim\u00e1ln\u011b {{ limit }} mo\u017enost\u00ed.", "validators", "cs");
t.add("One or more of the given values is invalid.", "N\u011bkter\u00e9 z uveden\u00fdch hodnot jsou neplatn\u00e9.", "validators", "cs");
t.add("This field was not expected.", "Toto pole nebylo o\u010dek\u00e1v\u00e1no.", "validators", "cs");
t.add("This field is missing.", "Toto pole chyb\u00ed.", "validators", "cs");
t.add("This value is not a valid date.", "Tato hodnota nen\u00ed platn\u00e9 datum.", "validators", "cs");
t.add("This value is not a valid datetime.", "Tato hodnota nen\u00ed platn\u00e9 datum s \u010dasov\u00fdm \u00fadajem.", "validators", "cs");
t.add("This value is not a valid email address.", "Tato hodnota nen\u00ed platn\u00e1 e-mailov\u00e1 adresa.", "validators", "cs");
t.add("The file could not be found.", "Soubor nebyl nalezen.", "validators", "cs");
t.add("The file is not readable.", "Soubor je ne\u010diteln\u00fd.", "validators", "cs");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Soubor je p\u0159\u00edli\u0161 velk\u00fd ({{ size }} {{ suffix }}). Maxim\u00e1ln\u00ed povolen\u00e1 velikost souboru je {{ limit }} {{ suffix }}.", "validators", "cs");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Neplatn\u00fd mime typ souboru ({{ type }}). Povolen\u00e9 mime typy soubor\u016f jsou {{ types }}.", "validators", "cs");
t.add("This value should be {{ limit }} or less.", "Tato hodnota mus\u00ed b\u00fdt {{ limit }} nebo m\u00e9n\u011b.", "validators", "cs");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Tato hodnota je p\u0159\u00edli\u0161 dlouh\u00e1. Mus\u00ed obsahovat maxim\u00e1ln\u011b {{ limit }} znak.|Tato hodnota je p\u0159\u00edli\u0161 dlouh\u00e1. Mus\u00ed obsahovat maxim\u00e1ln\u011b {{ limit }} znaky.|Tato hodnota je p\u0159\u00edli\u0161 dlouh\u00e1. Mus\u00ed obsahovat maxim\u00e1ln\u011b {{ limit }} znak\u016f.", "validators", "cs");
t.add("This value should be {{ limit }} or more.", "Tato hodnota mus\u00ed b\u00fdt {{ limit }} nebo v\u00edce.", "validators", "cs");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Tato hodnota je p\u0159\u00edli\u0161 kr\u00e1tk\u00e1. Mus\u00ed obsahovat minim\u00e1ln\u011b {{ limit }} znak.|Tato hodnota je p\u0159\u00edli\u0161 kr\u00e1tk\u00e1. Mus\u00ed obsahovat minim\u00e1ln\u011b {{ limit }} znaky.|Tato hodnota je p\u0159\u00edli\u0161 kr\u00e1tk\u00e1. Mus\u00ed obsahovat minim\u00e1ln\u011b {{ limit }} znak\u016f.", "validators", "cs");
t.add("This value should not be blank.", "Tato hodnota nesm\u00ed b\u00fdt pr\u00e1zdn\u00e1.", "validators", "cs");
t.add("This value should not be null.", "Tato hodnota nesm\u00ed b\u00fdt null.", "validators", "cs");
t.add("This value should be null.", "Tato hodnota mus\u00ed b\u00fdt null.", "validators", "cs");
t.add("This value is not valid.", "Tato hodnota nen\u00ed platn\u00e1.", "validators", "cs");
t.add("This value is not a valid time.", "Tato hodnota nen\u00ed platn\u00fd \u010dasov\u00fd \u00fadaj.", "validators", "cs");
t.add("This value is not a valid URL.", "Tato hodnota nen\u00ed platn\u00e1 URL adresa.", "validators", "cs");
t.add("The two values should be equal.", "Tyto dv\u011b hodnoty mus\u00ed b\u00fdt stejn\u00e9.", "validators", "cs");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Soubor je p\u0159\u00edli\u0161 velk\u00fd. Maxim\u00e1ln\u00ed povolen\u00e1 velikost souboru je {{ limit }} {{ suffix }}.", "validators", "cs");
t.add("The file is too large.", "Soubor je p\u0159\u00edli\u0161 velk\u00fd.", "validators", "cs");
t.add("The file could not be uploaded.", "Soubor se nepoda\u0159ilo nahr\u00e1t.", "validators", "cs");
t.add("This value should be a valid number.", "Tato hodnota mus\u00ed b\u00fdt \u010d\u00edslo.", "validators", "cs");
t.add("This file is not a valid image.", "Tento soubor nen\u00ed obr\u00e1zek.", "validators", "cs");
t.add("This is not a valid IP address.", "Toto nen\u00ed platn\u00e1 IP adresa.", "validators", "cs");
t.add("This value is not a valid language.", "Tento jazyk neexistuje.", "validators", "cs");
t.add("This value is not a valid locale.", "Tato lokalizace neexistuje.", "validators", "cs");
t.add("This value is not a valid country.", "Tato zem\u011b neexistuje.", "validators", "cs");
t.add("This value is already used.", "Tato hodnota je ji\u017e pou\u017e\u00edv\u00e1na.", "validators", "cs");
t.add("The size of the image could not be detected.", "Nepoda\u0159ily se zjistit rozm\u011bry obr\u00e1zku.", "validators", "cs");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Obr\u00e1zek je p\u0159\u00edli\u0161 \u0161irok\u00fd ({{ width }}px). Maxim\u00e1ln\u00ed povolen\u00e1 \u0161\u00ed\u0159ka obr\u00e1zku je {{ max_width }}px.", "validators", "cs");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Obr\u00e1zek je p\u0159\u00edli\u0161 \u00fazk\u00fd ({{ width }}px). Minim\u00e1ln\u00ed \u0161\u00ed\u0159ka mus\u00ed b\u00fdt {{ min_width }}px.", "validators", "cs");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Obr\u00e1zek je p\u0159\u00edli\u0161 vysok\u00fd ({{ height }}px). Maxim\u00e1ln\u00ed povolen\u00e1 v\u00fd\u0161ka obr\u00e1zku je {{ max_height }}px.", "validators", "cs");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Obr\u00e1zek je p\u0159\u00edli\u0161 n\u00edzk\u00fd ({{ height }}px). Minim\u00e1ln\u00ed v\u00fd\u0161ka obr\u00e1zku mus\u00ed b\u00fdt {{ min_height }}px.", "validators", "cs");
t.add("This value should be the user's current password.", "Tato hodnota mus\u00ed b\u00fdt aktu\u00e1ln\u00ed heslo u\u017eivatele.", "validators", "cs");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Tato hodnota mus\u00ed m\u00edt p\u0159esn\u011b {{ limit }} znak.|Tato hodnota mus\u00ed m\u00edt p\u0159esn\u011b {{ limit }} znaky.|Tato hodnota mus\u00ed m\u00edt p\u0159esn\u011b {{ limit }} znak\u016f.", "validators", "cs");
t.add("The file was only partially uploaded.", "Byla nahr\u00e1na jen \u010d\u00e1st souboru.", "validators", "cs");
t.add("No file was uploaded.", "\u017d\u00e1dn\u00fd soubor nebyl nahr\u00e1n.", "validators", "cs");
t.add("No temporary folder was configured in php.ini.", "V php.ini nen\u00ed nastavena cesta k adres\u00e1\u0159i pro do\u010dasn\u00e9 soubory.", "validators", "cs");
t.add("Cannot write temporary file to disk.", "Do\u010dasn\u00fd soubor se nepoda\u0159ilo zapsat na disk.", "validators", "cs");
t.add("A PHP extension caused the upload to fail.", "Roz\u0161\u00ed\u0159en\u00ed PHP zabr\u00e1nilo nahr\u00e1n\u00ed souboru.", "validators", "cs");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Tato kolekce mus\u00ed obsahovat minim\u00e1ln\u011b {{ limit }} prvek.|Tato kolekce mus\u00ed obsahovat minim\u00e1ln\u011b {{ limit }} prvky.|Tato kolekce mus\u00ed obsahovat minim\u00e1ln\u011b {{ limit }} prvk\u016f.", "validators", "cs");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Tato kolekce mus\u00ed obsahovat maxim\u00e1ln\u011b {{ limit }} prvek.|Tato kolekce mus\u00ed obsahovat maxim\u00e1ln\u011b {{ limit }} prvky.|Tato kolekce mus\u00ed obsahovat maxim\u00e1ln\u011b {{ limit }} prvk\u016f.", "validators", "cs");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Tato kolekce mus\u00ed obsahovat p\u0159esn\u011b {{ limit }} prvek.|Tato kolekce mus\u00ed obsahovat p\u0159esn\u011b {{ limit }} prvky.|Tato kolekce mus\u00ed obsahovat p\u0159esn\u011b {{ limit }} prvk\u016f.", "validators", "cs");
t.add("Invalid card number.", "Neplatn\u00e9 \u010d\u00edslo karty.", "validators", "cs");
t.add("Unsupported card type or invalid card number.", "Nepodporovan\u00fd typ karty nebo neplatn\u00e9 \u010d\u00edslo karty.", "validators", "cs");
t.add("This is not a valid International Bank Account Number (IBAN).", "Toto je neplatn\u00fd IBAN.", "validators", "cs");
t.add("This value is not a valid ISBN-10.", "Tato hodnota nen\u00ed platn\u00e9 ISBN-10.", "validators", "cs");
t.add("This value is not a valid ISBN-13.", "Tato hodnota nen\u00ed platn\u00e9 ISBN-13.", "validators", "cs");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Tato hodnota nen\u00ed platn\u00e9 ISBN-10 ani ISBN-13.", "validators", "cs");
t.add("This value is not a valid ISSN.", "Tato hodnota nen\u00ed platn\u00e9 ISSN.", "validators", "cs");
t.add("This value is not a valid currency.", "Tato m\u011bna neexistuje.", "validators", "cs");
t.add("This value should be equal to {{ compared_value }}.", "Tato hodnota mus\u00ed b\u00fdt rovna {{ compared_value }}.", "validators", "cs");
t.add("This value should be greater than {{ compared_value }}.", "Tato hodnota mus\u00ed b\u00fdt v\u011bt\u0161\u00ed ne\u017e {{ compared_value }}.", "validators", "cs");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Tato hodnota mus\u00ed b\u00fdt v\u011bt\u0161\u00ed nebo rovna {{ compared_value }}.", "validators", "cs");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Tato hodnota mus\u00ed b\u00fdt typu {{ compared_value_type }} a z\u00e1rove\u0148 mus\u00ed b\u00fdt rovna {{ compared_value }}.", "validators", "cs");
t.add("This value should be less than {{ compared_value }}.", "Tato hodnota mus\u00ed b\u00fdt men\u0161\u00ed ne\u017e {{ compared_value }}.", "validators", "cs");
t.add("This value should be less than or equal to {{ compared_value }}.", "Tato hodnota mus\u00ed b\u00fdt men\u0161\u00ed nebo rovna {{ compared_value }}.", "validators", "cs");
t.add("This value should not be equal to {{ compared_value }}.", "Tato hodnota nesm\u00ed b\u00fdt rovna {{ compared_value }}.", "validators", "cs");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Tato hodnota nesm\u00ed b\u00fdt typu {{ compared_value_type }} a z\u00e1rove\u0148 nesm\u00ed b\u00fdt rovna {{ compared_value }}.", "validators", "cs");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Pom\u011br stran obr\u00e1zku je p\u0159\u00edli\u0161 velk\u00fd ({{ ratio }}). Maxim\u00e1ln\u00ed povolen\u00fd pom\u011br stran obr\u00e1zku je {{ max_ratio }}.", "validators", "cs");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Pom\u011br stran obr\u00e1zku je p\u0159\u00edli\u0161 mal\u00fd ({{ ratio }}). Minim\u00e1ln\u00ed povolen\u00fd pom\u011br stran obr\u00e1zku je {{ min_ratio }}.", "validators", "cs");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Strany obr\u00e1zku jsou \u010dtvercov\u00e9 ({{ width }}x{{ height }}px). \u010ctvercov\u00e9 obr\u00e1zky nejsou povolen\u00e9.", "validators", "cs");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Obr\u00e1zek je orientovan\u00fd na \u0161\u00ed\u0159ku ({{ width }}x{{ height }}px). Obr\u00e1zky orientovan\u00e9 na \u0161\u00ed\u0159ku nejsou povolen\u00e9.", "validators", "cs");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Obr\u00e1zek je orientovan\u00fd na v\u00fd\u0161ku ({{ width }}x{{ height }}px). Obr\u00e1zky orientovan\u00e9 na v\u00fd\u0161ku nejsou povolen\u00e9.", "validators", "cs");
t.add("An empty file is not allowed.", "Soubor nesm\u00ed b\u00fdt pr\u00e1zdn\u00fd.", "validators", "cs");
t.add("The host could not be resolved.", "Hostitele nebylo mo\u017en\u00e9 rozpoznat.", "validators", "cs");
t.add("This value does not match the expected {{ charset }} charset.", "Tato hodnota neodpov\u00edd\u00e1 o\u010dek\u00e1van\u00e9 znakov\u00e9 sad\u011b {{ charset }}.", "validators", "cs");
t.add("This is not a valid Business Identifier Code (BIC).", "Tato hodnota nen\u00ed platn\u00fd identifika\u010dn\u00ed k\u00f3d podniku (BIC).", "validators", "cs");
t.add("Error", "Chyba", "validators", "cs");
t.add("This is not a valid UUID.", "Tato hodnota nen\u00ed platn\u00e9 UUID.", "validators", "cs");
t.add("This value should be a multiple of {{ compared_value }}.", "Tato hodnota mus\u00ed b\u00fdt n\u00e1sobek hodnoty {{ compared_value }}.", "validators", "cs");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Bankovn\u00ed identifika\u010dn\u00ed k\u00f3d (BIC) neodpov\u00edd\u00e1 mezin\u00e1rodn\u00edmu \u010d\u00edslu \u00fa\u010dtu (IBAN) {{ iban }}.", "validators", "cs");
t.add("This value should be valid JSON.", "Tato hodnota mus\u00ed b\u00fdt validn\u00ed JSON.", "validators", "cs");
t.add("This collection should contain only unique elements.", "Tato kolekce mus\u00ed obsahovat pouze unik\u00e1tn\u00ed prvky.", "validators", "cs");
t.add("This value should be positive.", "Tato hodnota mus\u00ed b\u00fdt kladn\u00e1.", "validators", "cs");
t.add("This value should be either positive or zero.", "Tato hodnota mus\u00ed b\u00fdt bu\u010f kladn\u00e1 nebo nula.", "validators", "cs");
t.add("This value should be negative.", "Tato hodnota mus\u00ed b\u00fdt z\u00e1porn\u00e1.", "validators", "cs");
t.add("This value should be either negative or zero.", "Tato hodnota mus\u00ed b\u00fdt bu\u010f z\u00e1porn\u00e1 nebo nula.", "validators", "cs");
t.add("This value is not a valid timezone.", "Tato \u010dasov\u00e1 z\u00f3na neexistuje.", "validators", "cs");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Zadan\u00e9 heslo bylo sou\u010d\u00e1st\u00ed \u00faniku dat, tak\u017ee ho nen\u00ed mo\u017en\u00e9 pou\u017e\u00edt. Pou\u017eijte pros\u00edm jin\u00e9 heslo.", "validators", "cs");
t.add("This value should be between {{ min }} and {{ max }}.", "Hodnota mus\u00ed b\u00fdt mezi {{ min }} a {{ max }}.", "validators", "cs");
t.add("This value is not a valid hostname.", "Tato hodnota nen\u00ed platn\u00fd hostname.", "validators", "cs");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Po\u010det prvk\u016f v t\u00e9to kolekci mus\u00ed b\u00fdt n\u00e1sobek {{ compared_value }}.", "validators", "cs");
t.add("This value should satisfy at least one of the following constraints:", "Tato hodnota mus\u00ed spl\u0148ovat alespo\u0148 jedno z n\u00e1sleduj\u00edc\u00edch omezen\u00ed:", "validators", "cs");
t.add("Each element of this collection should satisfy its own set of constraints.", "Ka\u017ed\u00fd prvek v t\u00e9to kolekci mus\u00ed spl\u0148ovat sv\u00e1 vlastn\u00ed omezen\u00ed.", "validators", "cs");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Tato hodnota nen\u00ed platn\u00e9 mezin\u00e1rodn\u00ed identifika\u010dn\u00ed \u010d\u00edslo cenn\u00e9ho pap\u00edru (ISIN).", "validators", "cs");
t.add("This value should be a valid expression.", "Tato hodnota mus\u00ed b\u00fdt platn\u00fd v\u00fdraz.", "validators", "cs");
t.add("This value is not a valid CSS color.", "Tato hodnota nen\u00ed platn\u00e1 barva CSS.", "validators", "cs");
t.add("This value is not a valid CIDR notation.", "Tato hodnota nen\u00ed platn\u00e1 notace CIDR.", "validators", "cs");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "Hodnota masky s\u00edt\u011b mus\u00ed b\u00fdt mezi {{ min }} a {{ max }}.", "validators", "cs");
t.add("This form should not contain extra fields.", "Tato skupina pol\u00ed nesm\u00ed obsahovat dal\u0161\u00ed pole.", "validators", "cs");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Nahran\u00fd soubor je p\u0159\u00edli\u0161 velk\u00fd. Nahrajte pros\u00edm men\u0161\u00ed soubor.", "validators", "cs");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF token je neplatn\u00fd. Zkuste pros\u00edm znovu odeslat formul\u00e1\u0159.", "validators", "cs");
t.add("This value is not a valid HTML5 color.", "Tato hodnota nen\u00ed platn\u00e1 HTML5 barva.", "validators", "cs");
t.add("Please enter a valid birthdate.", "Pros\u00edm zadejte platn\u00fd datum narozen\u00ed.", "validators", "cs");
t.add("The selected choice is invalid.", "Vybran\u00e1 mo\u017enost nen\u00ed platn\u00e1.", "validators", "cs");
t.add("The collection is invalid.", "Kolekce nen\u00ed platn\u00e1.", "validators", "cs");
t.add("Please select a valid color.", "Pros\u00edm vyberte platnou barvu.", "validators", "cs");
t.add("Please select a valid country.", "Pros\u00edm vyberte platnou zemi.", "validators", "cs");
t.add("Please select a valid currency.", "Pros\u00edm vyberte platnou m\u011bnu.", "validators", "cs");
t.add("Please choose a valid date interval.", "Pros\u00edm vyberte platn\u00e9 rozp\u011bt\u00ed dat.", "validators", "cs");
t.add("Please enter a valid date and time.", "Pros\u00edm zadejte platn\u00fd datum a \u010das.", "validators", "cs");
t.add("Please enter a valid date.", "Pros\u00edm zadejte platn\u00fd datum.", "validators", "cs");
t.add("Please select a valid file.", "Pros\u00edm vyberte platn\u00fd soubor.", "validators", "cs");
t.add("The hidden field is invalid.", "Skryt\u00e9 pole nen\u00ed platn\u00e9.", "validators", "cs");
t.add("Please enter an integer.", "Pros\u00edm zadejte \u010d\u00edslo.", "validators", "cs");
t.add("Please select a valid language.", "Pros\u00edm zadejte platn\u00fd jazyk.", "validators", "cs");
t.add("Please select a valid locale.", "Pros\u00edm zadejte platn\u00fd jazyk.", "validators", "cs");
t.add("Please enter a valid money amount.", "Pros\u00edm zadejte platnou \u010d\u00e1stku.", "validators", "cs");
t.add("Please enter a number.", "Pros\u00edm zadejte \u010d\u00edslo.", "validators", "cs");
t.add("The password is invalid.", "Heslo nen\u00ed platn\u00e9.", "validators", "cs");
t.add("Please enter a percentage value.", "Pros\u00edm zadejte procentu\u00e1ln\u00ed hodnotu.", "validators", "cs");
t.add("The values do not match.", "Hodnoty se neshoduj\u00ed.", "validators", "cs");
t.add("Please enter a valid time.", "Pros\u00edm zadejte platn\u00fd \u010das.", "validators", "cs");
t.add("Please select a valid timezone.", "Pros\u00edm vyberte platn\u00e9 \u010dasov\u00e9 p\u00e1smo.", "validators", "cs");
t.add("Please enter a valid URL.", "Pros\u00edm zadejte platnou URL.", "validators", "cs");
t.add("Please enter a valid search term.", "Pros\u00edm zadejte platn\u00fd v\u00fdraz k vyhled\u00e1n\u00ed.", "validators", "cs");
t.add("Please provide a valid phone number.", "Pros\u00edm zadejte platn\u00e9 telefonn\u00ed \u010d\u00edslo.", "validators", "cs");
t.add("The checkbox has an invalid value.", "Za\u0161krt\u00e1vac\u00ed pol\u00ed\u010dko m\u00e1 neplatnou hodnotu.", "validators", "cs");
t.add("Please enter a valid email address.", "Pros\u00edm zadejte platnou emailovou adresu.", "validators", "cs");
t.add("Please select a valid option.", "Pros\u00edm vyberte platnou mo\u017enost.", "validators", "cs");
t.add("Please select a valid range.", "Pros\u00edm vyberte platn\u00fd rozsah.", "validators", "cs");
t.add("Please enter a valid week.", "Pros\u00edm zadejte platn\u00fd t\u00fdden.", "validators", "cs");
})(Translator);
