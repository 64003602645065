(function (t) {
// en
t.add("exercise.pdf.name", "Name", "pdf", "en");
t.add("exercise.description", "Description", "pdf", "en");
t.add("exercise.tools", "Tools", "pdf", "en");
t.add("exercise.movement", "Movement", "pdf", "en");
t.add("exercise.intensity", "Intensity", "pdf", "en");
t.add("exercise.pdf.execution_speed", "Speed", "pdf", "en");
t.add("exercise.repetitions", "Repetitions", "pdf", "en");
t.add("exercise.number_of_series", "Number of series", "pdf", "en");
t.add("exercise.intermediate_rest", "Intermediate rest", "pdf", "en");
t.add("exercise.chain", "Order", "pdf", "en");
t.add("exercise.chain.circuit_nr", "Circuit number", "pdf", "en");
t.add("exercise.chain.isolated", "Isolated", "pdf", "en");
})(Translator);
