(function (t) {
// et
t.add("This value should be false.", "V\u00e4\u00e4rtus peaks olema v\u00e4\u00e4r.", "validators", "et");
t.add("This value should be true.", "V\u00e4\u00e4rtus peaks oleme t\u00f5ene.", "validators", "et");
t.add("This value should be of type {{ type }}.", "V\u00e4\u00e4rtus peaks olema {{ type }}-t\u00fc\u00fcpi.", "validators", "et");
t.add("This value should be blank.", "V\u00e4\u00e4rtus peaks olema t\u00fchi.", "validators", "et");
t.add("The value you selected is not a valid choice.", "V\u00e4\u00e4rtus peaks olema \u00fcks etteantud valikutest.", "validators", "et");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Valima peaks v\u00e4hemalt {{ limit }} valikut.", "validators", "et");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Valima peaks mitte rohkem kui  {{ limit }} valikut.", "validators", "et");
t.add("One or more of the given values is invalid.", "\u00dcks v\u00f5i rohkem v\u00e4\u00e4rtustest on vigane.", "validators", "et");
t.add("This field was not expected.", "See v\u00e4li ei olnud oodatud.", "validators", "et");
t.add("This field is missing.", "See v\u00e4li on puudu.", "validators", "et");
t.add("This value is not a valid date.", "V\u00e4\u00e4rtus pole korrektne kuup\u00e4ev.", "validators", "et");
t.add("This value is not a valid datetime.", "V\u00e4\u00e4rtus pole korrektne kuup\u00e4ev ja kellaeg.", "validators", "et");
t.add("This value is not a valid email address.", "V\u00e4\u00e4rtus pole korrektne e-maili aadress.", "validators", "et");
t.add("The file could not be found.", "Faili ei leita.", "validators", "et");
t.add("The file is not readable.", "Fail ei ole loetav.", "validators", "et");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Fail on liiga suur ({{ size }} {{ suffix }}). Suurim lubatud suurus on {{ limit }} {{ suffix }}.", "validators", "et");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Faili sisut\u00fc\u00fcp on vigane ({{ type }}). Lubatud sisut\u00fc\u00fcbid on {{ types }}.", "validators", "et");
t.add("This value should be {{ limit }} or less.", "V\u00e4\u00e4rtus peaks olema {{ limit }} v\u00f5i v\u00e4hem.", "validators", "et");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "V\u00e4\u00e4rtus on liiga pikk. Pikkus peaks olema {{ limit }} t\u00e4hem\u00e4rki v\u00f5i v\u00e4hem.", "validators", "et");
t.add("This value should be {{ limit }} or more.", "V\u00e4\u00e4rtus peaks olema {{ limit }} v\u00f5i rohkem.", "validators", "et");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "V\u00e4\u00e4rtus on liiga l\u00fchike. Pikkus peaks  olema {{ limit }} t\u00e4hem\u00e4rki v\u00f5i rohkem.", "validators", "et");
t.add("This value should not be blank.", "V\u00e4\u00e4rtus ei tohiks olla t\u00fchi.", "validators", "et");
t.add("This value should not be null.", "V\u00e4\u00e4rtus ei tohiks olla 'null'.", "validators", "et");
t.add("This value should be null.", "V\u00e4\u00e4rtus peaks olema 'null'.", "validators", "et");
t.add("This value is not valid.", "V\u00e4\u00e4rtus on vigane.", "validators", "et");
t.add("This value is not a valid time.", "V\u00e4\u00e4rtus pole korrektne aeg.", "validators", "et");
t.add("This value is not a valid URL.", "V\u00e4\u00e4rtus pole korrektne URL.", "validators", "et");
t.add("The two values should be equal.", "V\u00e4\u00e4rtused peaksid olema v\u00f5rdsed.", "validators", "et");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Fail on liiga suur. Maksimaalne lubatud suurus on {{ limit }} {{ suffix }}.", "validators", "et");
t.add("The file is too large.", "Fail on liiga suur.", "validators", "et");
t.add("The file could not be uploaded.", "Faili ei saa \u00fcles laadida.", "validators", "et");
t.add("This value should be a valid number.", "V\u00e4\u00e4rtus peaks olema korrektne number.", "validators", "et");
t.add("This file is not a valid image.", "Fail ei ole korrektne pilt.", "validators", "et");
t.add("This is not a valid IP address.", "IP aadress pole korrektne.", "validators", "et");
t.add("This value is not a valid language.", "V\u00e4\u00e4rtus pole korrektne keel.", "validators", "et");
t.add("This value is not a valid locale.", "V\u00e4\u00e4rtus pole korrektne asukohakeel.", "validators", "et");
t.add("This value is not a valid country.", "V\u00e4\u00e4rtus pole olemasolev riik.", "validators", "et");
t.add("This value is already used.", "V\u00e4\u00e4rtust on juba kasutatud.", "validators", "et");
t.add("The size of the image could not be detected.", "Pildi suurust polnud v\u00f5imalik tuvastada.", "validators", "et");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Pilt on liiga lai ({{ width }}px). Suurim lubatud laius on {{ max_width }}px.", "validators", "et");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Pilt on liiga kitsas ({{ width }}px). V\u00e4him lubatud laius on {{ min_width }}px.", "validators", "et");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Pilt on liiga pikk ({{ height }}px). Lubatud suurim pikkus on {{ max_height }}px.", "validators", "et");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Pilt pole piisavalt pikk ({{ height }}px). Lubatud v\u00e4him pikkus on {{ min_height }}px.", "validators", "et");
t.add("This value should be the user's current password.", "V\u00e4\u00e4rtus peaks olema kasutaja kehtiv salas\u00f5na.", "validators", "et");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "V\u00e4\u00e4rtus peaks olema t\u00e4pselt {{ limit }} t\u00e4hem\u00e4rk pikk.|V\u00e4\u00e4rtus peaks olema t\u00e4pselt {{ limit }} t\u00e4hem\u00e4rki pikk.", "validators", "et");
t.add("The file was only partially uploaded.", "Fail ei laetud t\u00e4ielikult \u00fcles.", "validators", "et");
t.add("No file was uploaded.", "\u00dchtegi faili ei laetud \u00fcles.", "validators", "et");
t.add("No temporary folder was configured in php.ini.", "\u00dchtegi ajutist kausta polnud php.ini-s seadistatud.", "validators", "et");
t.add("Cannot write temporary file to disk.", "Ajutist faili ei saa kettale kirjutada.", "validators", "et");
t.add("A PHP extension caused the upload to fail.", "PHP laiendi t\u00f5ttu eba\u00f5nnestus faili \u00fcleslaadimine.", "validators", "et");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Kogumikus peaks olema v\u00e4hemalt {{ limit }} element.|Kogumikus peaks olema v\u00e4hemalt {{ limit }} elementi.", "validators", "et");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Kogumikus peaks olema \u00fclimalt {{ limit }} element.|Kogumikus peaks olema \u00fclimalt {{ limit }} elementi.", "validators", "et");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Kogumikus peaks olema t\u00e4pselt {{ limit }} element.|Kogumikus peaks olema t\u00e4pselt {{ limit }}|elementi.", "validators", "et");
t.add("Invalid card number.", "Vigane kaardi number.", "validators", "et");
t.add("Unsupported card type or invalid card number.", "Kaardi t\u00fc\u00fcpi ei toetata v\u00f5i kaardi number on vigane.", "validators", "et");
t.add("This is not a valid International Bank Account Number (IBAN).", "V\u00e4\u00e4rtus pole korrektne IBAN-number.", "validators", "et");
t.add("This value is not a valid ISBN-10.", "V\u00e4\u00e4rtus pole korrektne ISBN-10.", "validators", "et");
t.add("This value is not a valid ISBN-13.", "V\u00e4\u00e4rtus pole korrektne ISBN-13.", "validators", "et");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "V\u00e4\u00e4rtus pole korrektne ISBN-10 ega ISBN-13.", "validators", "et");
t.add("This value is not a valid ISSN.", "V\u00e4\u00e4rtus pole korrektne ISSN.", "validators", "et");
t.add("This value is not a valid currency.", "V\u00e4\u00e4rtus pole korrektne valuuta.", "validators", "et");
t.add("This value should be equal to {{ compared_value }}.", "V\u00e4\u00e4rtus peaks olema v\u00f5rdne {{ compared_value }}-ga.", "validators", "et");
t.add("This value should be greater than {{ compared_value }}.", "V\u00e4\u00e4rtus peaks olema suurem kui {{ compared_value }}.", "validators", "et");
t.add("This value should be greater than or equal to {{ compared_value }}.", "V\u00e4\u00e4rtus peaks olema suurem kui v\u00f5i v\u00f5rduma {{ compared_value }}-ga.", "validators", "et");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "V\u00e4\u00e4rtus peaks olema identne v\u00e4\u00e4rtusega {{ compared_value_type }} {{ compared_value }}.", "validators", "et");
t.add("This value should be less than {{ compared_value }}.", "V\u00e4\u00e4rtus peaks olema v\u00e4iksem kui {{ compared_value }}.", "validators", "et");
t.add("This value should be less than or equal to {{ compared_value }}.", "V\u00e4\u00e4rtus peaks olema v\u00e4iksem kui v\u00f5i v\u00f5rduma {{ compared_value }}-ga.", "validators", "et");
t.add("This value should not be equal to {{ compared_value }}.", "V\u00e4\u00e4rtus ei tohiks v\u00f5rduda {{ compared_value }}-ga.", "validators", "et");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "V\u00e4\u00e4rtus ei tohiks olla identne v\u00e4\u00e4rtusega {{ compared_value_type }} {{ compared_value }}.", "validators", "et");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Kuvasuhe on liiga suur ({{ ratio }}). Lubatud maksimaalne suhe on {{ max_ratio }}.", "validators", "et");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Kuvasuhe on liiga v\u00e4ike ({{ ratio }}). Oodatav minimaalne suhe on {{ min_ratio }}.", "validators", "et");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Pilt on ruudukujuline ({{ width }}x{{ height }}px). Ruudukujulised pildid pole lubatud.", "validators", "et");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Pilt on horisontaalselt orienteeritud ({{ width }}x{{ height }}px). Maastikulised pildid pole lubatud.", "validators", "et");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Pilt on vertikaalselt orienteeritud ({{ width }}x{{ height }}px). Portreepildid pole lubatud.", "validators", "et");
t.add("An empty file is not allowed.", "T\u00fchi fail pole lubatud.", "validators", "et");
t.add("The host could not be resolved.", "Sellist domeeni ei \u00f5nnestunud leida.", "validators", "et");
t.add("This value does not match the expected {{ charset }} charset.", "See v\u00e4\u00e4rtus ei \u00fchti eeldatava t\u00e4hem\u00e4rgiga {{ charset }}.", "validators", "et");
t.add("This is not a valid Business Identifier Code (BIC).", "See ei ole kehtiv ettev\u00f5tte identifitseerimiskood (BIC).", "validators", "et");
t.add("Error", "Viga", "validators", "et");
t.add("This is not a valid UUID.", "See pole kehtiv UUID.", "validators", "et");
t.add("This value should be a multiple of {{ compared_value }}.", "See v\u00e4\u00e4rtus peaks olema v\u00e4\u00e4rtuse {{ compared_value }} kordne.", "validators", "et");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "See ettev\u00f5tte identifitseerimiskood (BIC) ei ole seotud IBAN-iga {{ iban }}.", "validators", "et");
t.add("This value should be valid JSON.", "See v\u00e4\u00e4rtus peaks olema kehtiv JSON.", "validators", "et");
t.add("This collection should contain only unique elements.", "See kogu peaks sisaldama ainult unikaalseid elemente.", "validators", "et");
t.add("This value should be positive.", "See v\u00e4\u00e4rtus peaks olema positiivne.", "validators", "et");
t.add("This value should be either positive or zero.", "See v\u00e4\u00e4rtus peaks olema kas positiivne v\u00f5i null.", "validators", "et");
t.add("This value should be negative.", "See v\u00e4\u00e4rtus peaks olema negatiivne.", "validators", "et");
t.add("This value should be either negative or zero.", "See v\u00e4\u00e4rtus peaks olema kas negatiivne v\u00f5i null.", "validators", "et");
t.add("This value is not a valid timezone.", "See v\u00e4\u00e4rtus pole kehtiv ajav\u00f6\u00f6nd.", "validators", "et");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "See parool on lekkinud andmerikkumise korral, seda ei tohi kasutada. Palun kasutage muud parooli.", "validators", "et");
t.add("This value should be between {{ min }} and {{ max }}.", "See v\u00e4\u00e4rtus peaks olema vahemikus {{ min }} kuni {{ max }}.", "validators", "et");
t.add("This value is not a valid hostname.", "See v\u00e4\u00e4rtus pole korrektne domeeninimi.", "validators", "et");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Selles kogus olevate elementide arv peab olema arvu {{ compared_value }} kordne.", "validators", "et");
t.add("This value should satisfy at least one of the following constraints:", "See v\u00e4\u00e4rtus peab vastama v\u00e4hemalt \u00fchele j\u00e4rgmistest tingimustest:", "validators", "et");
t.add("Each element of this collection should satisfy its own set of constraints.", "K\u00f5ik v\u00e4\u00e4rtused selles kogus peavad vastama oma tingimustele.", "validators", "et");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "See v\u00e4\u00e4rtus pole korrektne ISIN-kood.", "validators", "et");
t.add("This value should be a valid expression.", "See v\u00e4\u00e4rtus pole korrektne avaldis.", "validators", "et");
t.add("This value is not a valid CSS color.", "See v\u00e4\u00e4rtus pole korrektne CSS-i v\u00e4rv.", "validators", "et");
t.add("This value is not a valid CIDR notation.", "See v\u00e4\u00e4rtus pole korrektne CIDR v\u00f5rguaadress.", "validators", "et");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "V\u00f5rgumaski v\u00e4\u00e4rtus peaks olema vahemikus {{ min }} kuni {{ max }}.", "validators", "et");
t.add("This form should not contain extra fields.", "V\u00e4ljade grupp ei tohiks sisalda lisav\u00e4ljasid.", "validators", "et");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "\u00dcleslaaditud fail oli liiga suur. Palun proovi uuesti v\u00e4iksema failiga.", "validators", "et");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF-m\u00e4rgis on vigane. Palun proovi vormi uuesti esitada.", "validators", "et");
t.add("This value is not a valid HTML5 color.", "See v\u00e4\u00e4rtus ei ole korrektne HTML5 v\u00e4rv.", "validators", "et");
t.add("Please enter a valid birthdate.", "Palun sisesta korrektne s\u00fcnnikuup\u00e4ev.", "validators", "et");
t.add("The selected choice is invalid.", "Tehtud valik on vigane.", "validators", "et");
t.add("The collection is invalid.", "Kogum on vigane.", "validators", "et");
t.add("Please select a valid color.", "Palun vali korrektne v\u00e4rv.", "validators", "et");
t.add("Please select a valid country.", "Palun vali korrektne riik.", "validators", "et");
t.add("Please select a valid currency.", "Palun vali korrektne valuuta.", "validators", "et");
t.add("Please choose a valid date interval.", "Palun vali korrektne kuup\u00e4evade vahemik.", "validators", "et");
t.add("Please enter a valid date and time.", "Palun sisesta korrektne kuup\u00e4ev ja kellaaeg.", "validators", "et");
t.add("Please enter a valid date.", "Palun sisesta korrektne kuup\u00e4ev.", "validators", "et");
t.add("Please select a valid file.", "Palun vali korrektne fail.", "validators", "et");
t.add("The hidden field is invalid.", "Peidetud v\u00e4li on vigane.", "validators", "et");
t.add("Please enter an integer.", "Palun sisesta t\u00e4isarv.", "validators", "et");
t.add("Please select a valid language.", "Palun vali korrektne keel.", "validators", "et");
t.add("Please select a valid locale.", "Palun vali korrektne keelekood.", "validators", "et");
t.add("Please enter a valid money amount.", "Palun sisesta korrektne rahaline v\u00e4\u00e4rtus.", "validators", "et");
t.add("Please enter a number.", "Palun sisesta number.", "validators", "et");
t.add("The password is invalid.", "Vigane parool.", "validators", "et");
t.add("Please enter a percentage value.", "Palun sisesta protsendiline v\u00e4\u00e4rtus.", "validators", "et");
t.add("The values do not match.", "V\u00e4\u00e4rtused ei klapi.", "validators", "et");
t.add("Please enter a valid time.", "Palun sisesta korrektne aeg.", "validators", "et");
t.add("Please select a valid timezone.", "Palun vali korrektne ajav\u00f6\u00f6nd.", "validators", "et");
t.add("Please enter a valid URL.", "Palun sisesta korrektne URL.", "validators", "et");
t.add("Please enter a valid search term.", "Palun sisesta korrektne otsingutermin.", "validators", "et");
t.add("Please provide a valid phone number.", "Palun sisesta korrektne telefoninumber.", "validators", "et");
t.add("The checkbox has an invalid value.", "M\u00e4rkeruudu v\u00e4\u00e4rtus on vigane.", "validators", "et");
t.add("Please enter a valid email address.", "Palun sisesta korrektne e-posti aadress.", "validators", "et");
t.add("Please select a valid option.", "Palun tee korrektne valik.", "validators", "et");
t.add("Please select a valid range.", "Palun vali korrektne vahemik.", "validators", "et");
t.add("Please enter a valid week.", "Palun sisesta korrektne n\u00e4dal.", "validators", "et");
})(Translator);
