(function (t) {
// en
t.add("test.scaleACLRSI", "ACL - RSI scale", "tests", "en");
t.add("test.singleLegLanding", "Single leg landing", "tests", "en");
t.add("test.genuValgum", "Genu Valgum", "tests", "en");
t.add("test.healthyKnee", "Healthy knee", "tests", "en");
t.add("test.launch", "Launch test", "tests", "en");
t.add("test.title", "Tests", "tests", "en");
t.add("test.name", "Test name", "tests", "en");
t.add("test.customerName", "Client name", "tests", "en");
t.add("test.date", "Test date", "tests", "en");
t.add("test.validate", "Validate", "tests", "en");
t.add("test.side.healthy", "Healthy", "tests", "en");
t.add("test.side.healthy_newton", "Healthy (in Newton)", "tests", "en");
t.add("test.side.healthy_cm", "Healthy (in cm)", "tests", "en");
t.add("test.side.injured", "Injured", "tests", "en");
t.add("test.side.injured_newton", "Injured (in Newton)", "tests", "en");
t.add("test.side.injured_cm", "Injured  (in cm)", "tests", "en");
t.add("test.side.left_cm", "Left (in cm)", "tests", "en");
t.add("test.side.left_newton", "Left (in Newton)", "tests", "en");
t.add("test.side.right_cm", "Right (in cm)", "tests", "en");
t.add("test.side.right_newton", "Right (in Newton)", "tests", "en");
t.add("test.side.left", "Left", "tests", "en");
t.add("test.side.right", "Right", "tests", "en");
t.add("test.change_customer", "Edit", "tests", "en");
t.add("test.list", "Test list", "tests", "en");
t.add("test.totals", "Results", "tests", "en");
t.add("test.psychologicalComponent", "Psychological element", "tests", "en");
t.add("test.physicalComponent", "Athletic element", "tests", "en");
t.add("test.exercise.name", "Exercise name", "tests", "en");
t.add("test.difference", "Difference", "tests", "en");
t.add("test.illinois_modifie", "Modified illinois (change of direction speed test)", "tests", "en");
t.add("test.average", "Average", "tests", "en");
t.add("test.global_result", "Global results", "tests", "en");
t.add("test.globalScore.insufficient", "To work", "tests", "en");
t.add("test.globalScore.correct", "To perfect", "tests", "en");
t.add("test.globalScore.good", "To maintain", "tests", "en");
t.add("test.globalResult.100", "Global results (100)", "tests", "en");
t.add("test.globalResult.message", "The results are", "tests", "en");
t.add("test.edit", "Edit", "tests", "en");
t.add("test.show_details", "View details", "tests", "en");
t.add("test.delete", "Delete", "tests", "en");
t.add("test.results", "Test results", "tests", "en");
t.add("test.result.externalRotationForce", "External rotational force (RE)", "tests", "en");
t.add("test.result.internalRotationForce", "Internal rotational force (RI)", "tests", "en");
t.add("test.result.equilibriumRotationForce", "Ratio RE\/RI", "tests", "en");
t.add("test.result.stabilisationWork", "Work of stabilisation", "tests", "en");
t.add("test.result.explosivityWork", "Work of explosivity", "tests", "en");
t.add("test.result.powerWork", "Work of power", "tests", "en");
t.add("test.result.musclarWork", "Abdominal sheathing work \/ Muscular endurance work", "tests", "en");
t.add("test.specific_analysis_to_be_done", "Specific analysis of work to do", "tests", "en");
t.add("test.result.overallApprehensionOfTheTest", "Global apprehension", "tests", "en");
t.add("test.result.genuValgumDynamic", "Genu Valgum dynamic", "tests", "en");
t.add("test.result.dynamicProprioception", "Dynamic stabilization", "tests", "en");
t.add("test.result.axialPlyometricWork", "Pliometric work on-axis", "tests", "en");
t.add("test.result.speedWork", "Speed work", "tests", "en");
t.add("test.result.eccentricControlOfTheQuadriceps", "Eccentric quadriceps control", "tests", "en");
t.add("test.result.concentricStrengtheningTheQuadriceps", "Concentric quadriceps", "tests", "en");
t.add("test.result.plyometricWorkOffAxis", "Pliometric work off-axis", "tests", "en");
t.add("test.result.muscularEnduranceWork", "Muscular endurance work", "tests", "en");
t.add("test.result.strengtheningHipStabilizers", "Strength hips stabilizer", "tests", "en");
t.add("test.result.explosiveWork", "Explosivity work", "tests", "en");
t.add("test.result.numberOfMinimumRehalationSessionsRecommended", "Minimal number of specific sessions training", "tests", "en");
t.add("test.result.comment", "Comments", "tests", "en");
t.add("test.result.generalComment", "Comments", "tests", "en");
t.add("test.tegnerScale", "Tegner scale", "tests", "en");
t.add("%", "%", "tests", "en");
t.add("test.speed_work.improvement_needed", "A perfectionner", "tests", "en");
t.add("test.speed_work.needed", "A travailler", "tests", "en");
t.add("test.speed_work.ok", "Acquired", "tests", "en");
t.add("test.apprehension.no", "No apprehension", "tests", "en");
t.add("test.apprehension.at_the_resumption_of_competition", "A la reprise de comp\u00e9tition", "tests", "en");
t.add("test.apprehension.at_the_resumption_of_activity", "A la reprise de l'activit\u00e9", "tests", "en");
t.add("test.work_needed.left", "Left", "tests", "en");
t.add("test.work_needed.right", "Right", "tests", "en");
t.add("test.work_needed.both", "Bilateral", "tests", "en");
t.add("test.work_needed.none", "Acquired", "tests", "en");
t.add("choice.choose", "Choose", "tests", "en");
t.add("test.result.apprehensionDuringTheTest", "Apprehension during the test", "tests", "en");
t.add("test.qasls_test_results", "QASLS test", "tests", "en");
t.add("test.aclrsi_test_results", "ACL-RSI scale", "tests", "en");
t.add("test.aclrsi_questionaire_description", "Instructions: Place a mark on the line, which best describes you in relation to the descriptors.", "tests", "en");
t.add("test.aclrsi_edge.not_at_all_sure", "Not at all confident", "tests", "en");
t.add("test.aclrsi_edge.totally_sure", "Fully confident", "tests", "en");
t.add("test.aclrsi_edge.extremely_likely", "Extremely likely", "tests", "en");
t.add("test.aclrsi_edge.not_at_all_likely", "Not likely at all", "tests", "en");
t.add("test.aclrsi_edge.extremely_worried", "Extremely nervous", "tests", "en");
t.add("test.aclrsi_edge.not_at_all_worried", "Not nervousat all", "tests", "en");
t.add("test.aclrsi_edge.extremely_frustrated", "Extremely frustrating", "tests", "en");
t.add("test.aclrsi_edge.not_at_all_frustrated", "Not at all frustrating", "tests", "en");
t.add("test.aclrsi_edge.extreme_fear", "Extremely fearful", "tests", "en");
t.add("test.aclrsi_edge.no_fear", "No fear at all", "tests", "en");
t.add("test.aclrsi_edge.very_scared", "Extremely afraid", "tests", "en");
t.add("test.aclrsi_edge.not_at_all_scared", "Not at all afraid", "tests", "en");
t.add("test.aclrsi_edge.all_the_time", "All of the time", "tests", "en");
t.add("test.aclrsi_edge.at_no_time", "None of the time", "tests", "en");
t.add("test.aclrsi_edge.not_at_all_confident", "Not at all confident", "tests", "en");
t.add("test.aclrsi_edge.totally_confident", "Fully confident", "tests", "en");
t.add("test.aclrsi_edge.not_at_all_relaxed", "Not at all relaxed", "tests", "en");
t.add("test.aclrsi_edge.totally_relaxed", "Fully relaxed", "tests", "en");
t.add("test.aclrsi_test_link", "Answer", "tests", "en");
t.add("test.respond_to_the_questionnaire", "Complete the questionnaire", "tests", "en");
t.add("test.tar.description", "Observation form of reathletization aptitude test", "tests", "en");
t.add("result.tar.not_validated", "Not validated", "tests", "en");
t.add("result.tar.validated", "Validated", "tests", "en");
t.add("test.result.placeholder", "Comments", "tests", "en");
t.add("test.result.validated", "Validated", "tests", "en");
t.add("test.result.not_validated", "Non valid\u00e9", "tests", "en");
t.add("test.tar.globalComment", "Global comment", "tests", "en");
t.add("test.result.side_left", "Left", "tests", "en");
t.add("test.result.side_right", "Right", "tests", "en");
t.add("test.dpr.results", "DPR test results", "tests", "en");
t.add("test.dpr.description", "DPR test", "tests", "en");
t.add("test.email.customerNoEmails", "The email can't be sent immediatly", "tests", "en");
t.add("test.graph_title.quadriceps_force", "Quadriceps", "tests", "en");
t.add("test.graph_title.ij_force", "Hamstring", "tests", "en");
t.add("test.graph_title.adductors_force", "Adductors", "tests", "en");
t.add("test.graph_title.abductors", "Abductors", "tests", "en");
t.add("test.graph_title.dc", "Bench press", "tests", "en");
t.add("test.graph_title.tp", "TP", "tests", "en");
t.add("test.graph_title.ri", "Internal rotation", "tests", "en");
t.add("test.graph_title.re", "External rotation", "tests", "en");
t.add("test.graph_title.ratio_ij_quadriceps", "Hamstring\/quadriceps ratio", "tests", "en");
t.add("test.graph_title.ratio_adductors_abductors", "Adductor\/Abductor ratio", "tests", "en");
t.add("test.graph_title.ratio_re_ri", "External rotation\/internal rotation ratio", "tests", "en");
t.add("test.graph_title.upper_body_strength", "Upper body strength", "tests", "en");
t.add("test.graph_title.trunk_endurance", "Trunk endurance", "tests", "en");
t.add("test.results.tar", "Observation form of reathletization aptitude test", "tests", "en");
t.add("test.dpr.long_name", "Test de D\u00e9tection de Profils \u00e0 Risque", "tests", "en");
t.add("test.force", "Strength test", "tests", "en");
t.add("test.force.inferior_members", "Lower limb", "tests", "en");
t.add("test.force.tronk", "Trunk", "tests", "en");
t.add("test.force.epaule", "Shoulder", "tests", "en");
t.add("test.dpr.comment.dpr_closed_eyes_unipodal_stability.0", "Bilateral stabilization work of lower limb.", "tests", "en");
t.add("test.dpr.comment.dpr_closed_eyes_unipodal_stability.1", "Lower limb proprioception work", "tests", "en");
t.add("test.dpr.comment.dpr_closed_eyes_unipodal_stability.2", "Lower limb proprioception work", "tests", "en");
t.add("test.dpr.comment.dpr_unipodal_knee_flexion.0", "Bilateral eccentric control work of quadriceps.~|~Bilateral strengthening stabilizer work of hips.", "tests", "en");
t.add("test.dpr.comment.dpr_unipodal_knee_flexion.1", "Eccentric control work of left quadriceps.~|~Strengthening stabilizer work of left hip.", "tests", "en");
t.add("test.dpr.comment.dpr_unipodal_knee_flexion.2", "Eccentric control work of right quadriceps.~|~Strengthening stabilizer work of right hip.", "tests", "en");
t.add("test.dpr.comment.dpr_squat.0", "Lower limb mobility and flexibility global work.", "tests", "en");
t.add("test.dpr.comment.dpr_proprioception_basin.0", "Pelvis stabilization work.", "tests", "en");
t.add("test.dpr.comment.dpr_posterior_muscle_chain.0", "Lower back strength and\/or flexibility.", "tests", "en");
t.add("test.dpr.comment.dpr_dip_shoulders.0", "Scapula strength and\/or flexibility.", "tests", "en");
t.add("test.dpr.comment.dpr_quadriceps_flexibility.0", "Bilateral flexibility work of quadriceps.", "tests", "en");
t.add("test.dpr.comment.dpr_quadriceps_flexibility.1", "Flexibility work of left quadriceps.", "tests", "en");
t.add("test.dpr.comment.dpr_quadriceps_flexibility.2", "Flexibility work of right quadriceps.", "tests", "en");
t.add("test.dpr.comment.dpr_ij_flexibility.0", "Bilateral flexibility work of hamstring.", "tests", "en");
t.add("test.dpr.comment.dpr_ij_flexibility.1", "Flexibility work of left hamstring.", "tests", "en");
t.add("test.dpr.comment.dpr_ij_flexibility.2", "Flexibility work of right hamstring.", "tests", "en");
t.add("test.dpr.comment.dpr_adductors_flexibility.0", "Bilateral flexibility work of adductor.", "tests", "en");
t.add("test.dpr.comment.dpr_adductors_flexibility.1", "Flexibility work of left adductor.", "tests", "en");
t.add("test.dpr.comment.dpr_adductors_flexibility.2", "Flexibility work of right adductor.", "tests", "en");
t.add("test.dpr.comment.dpr_calves.0", "Bilateral flexibility work of calves.", "tests", "en");
t.add("test.dpr.comment.dpr_calves.1", "Flexibility work of left calf.", "tests", "en");
t.add("test.dpr.comment.dpr_calves.2", "Flexibility work of right calf.", "tests", "en");
t.add("test.dpr.comment.dpr_fingers_ground.0", "Lower back flexibility in lumber and gluteal.", "tests", "en");
t.add("test.dpr.comment.dpr_psoas.0", "Bilateral flexibility work of psoas.", "tests", "en");
t.add("test.dpr.comment.dpr_psoas.1", "Flexibility work of left psoas.", "tests", "en");
t.add("test.dpr.comment.dpr_psoas.2", "Flexibility work of right psoas.", "tests", "en");
t.add("test.dpr.comment.dpr_internal_rotation.0", "Bilateral flexibility work of internal rotation of shoulders.", "tests", "en");
t.add("test.dpr.comment.dpr_internal_rotation.1", "Flexibility work of internal rotation of left shoulder.", "tests", "en");
t.add("test.dpr.comment.dpr_internal_rotation.2", "Flexibility work of internal rotation of right shoulder.", "tests", "en");
t.add("test.dpr.comment.dpr_candlestick.0", "Bilateral flexibility work of external rotation of shoulders.", "tests", "en");
t.add("test.dpr.comment.dpr_candlestick.1", "Flexibility work of external rotation of left shoulder.", "tests", "en");
t.add("test.dpr.comment.dpr_candlestick.2", "Flexibility work of external rotation of right shoulder.", "tests", "en");
t.add("test.dpr.comment.dpr_retropulsion.0", "Bilateral flexibility work of shoulder retraction.", "tests", "en");
t.add("test.dpr.comment.dpr_antepulsion.0", "Bilateral flexibility work of shoulder protraction.", "tests", "en");
t.add("test.dpr.comment.dpr_antepulsion.1", "Flexibility work of left shoulder protraction.", "tests", "en");
t.add("test.dpr.comment.dpr_antepulsion.2", "Flexibility work of right shoulder protraction.", "tests", "en");
t.add("test.dpr.comment.dpr_quadriceps_force.0", "Strengthening bilateral work of quadriceps.", "tests", "en");
t.add("test.dpr.comment.dpr_quadriceps_force.1", "Strengthening work of left quadriceps.", "tests", "en");
t.add("test.dpr.comment.dpr_quadriceps_force.2", "Strengthening work of right quadriceps.", "tests", "en");
t.add("test.dpr.comment.dpr_quadriceps_force.3", "Strengthening bilateral work of quadriceps (left predominance).", "tests", "en");
t.add("test.dpr.comment.dpr_quadriceps_force.4", "Strengthening bilateral work of quadriceps (right predominance).", "tests", "en");
t.add("test.dpr.comment.dpr_ij_force.0", "Strengthening bilateral work of hamstring.", "tests", "en");
t.add("test.dpr.comment.dpr_ij_force.1", "Strengthening work of left hamstring.", "tests", "en");
t.add("test.dpr.comment.dpr_ij_force.2", "Strengthening work of right hamstring.", "tests", "en");
t.add("test.dpr.comment.dpr_ij_force.3", "Strengthening bilateral work of hamstring (left predominance).", "tests", "en");
t.add("test.dpr.comment.dpr_ij_force.4", "Strengthening bilateral work of hamstring (right predominance).", "tests", "en");
t.add("test.dpr.comment.dpr_adductors_force.0", "Bilateral strengthening work of adductors.", "tests", "en");
t.add("test.dpr.comment.dpr_adductors_force.1", "Strengthening work of left adductor.", "tests", "en");
t.add("test.dpr.comment.dpr_adductors_force.2", "Strengthening work of right adductor.", "tests", "en");
t.add("test.dpr.comment.dpr_adductors_force.3", "Bilateral strengthening work of adductor (left predominance).", "tests", "en");
t.add("test.dpr.comment.dpr_adductors_force.4", "Bilateral strengthening work of adductor (right predominance).", "tests", "en");
t.add("test.dpr.comment.dpr_abductors.0", "Bilateral strengthening work of abductors.", "tests", "en");
t.add("test.dpr.comment.dpr_abductors.1", "Strengthening work of left adductor.", "tests", "en");
t.add("test.dpr.comment.dpr_abductors.2", "Strengthening work of right adductor.", "tests", "en");
t.add("test.dpr.comment.dpr_abductors.3", "Bilateral strengthening work of adductor (left predominance).", "tests", "en");
t.add("test.dpr.comment.dpr_abductors.4", "Bilateral strengthening work of adductor (right predominance).", "tests", "en");
t.add("test.dpr.comment.dpr_shirado.0", "Abdominal work static to dynamic.", "tests", "en");
t.add("test.dpr.comment.dpr_sorensen.0", "Lumba work static to dynamic.", "tests", "en");
t.add("test.dpr.comment.dpr_dc.0", "Strengthening work of anteriors muscles.", "tests", "en");
t.add("test.dpr.comment.dpr_tirage.0", "Strengthening work of posteriors muscles.", "tests", "en");
t.add("test.dpr.comment.dpr_ri1.0", "Strengthening bilateral work of internal rotation shoulders", "tests", "en");
t.add("test.dpr.comment.dpr_ri1.1", "Strengthening work of internal rotation left shoulder.", "tests", "en");
t.add("test.dpr.comment.dpr_ri1.2", "Strengthening work of internal rotation right shoulder.", "tests", "en");
t.add("test.dpr.comment.dpr_ri1.3", "Strengthening bilateral work of internal rotation shoulders (left predominance).", "tests", "en");
t.add("test.dpr.comment.dpr_ri1.4", "Strengthening bilateral work of internal rotation shoulders (right predominance).", "tests", "en");
t.add("test.dpr.comment.dpr_re1.0", "Strengthening bilateral work of external rotation shoulders.", "tests", "en");
t.add("test.dpr.comment.dpr_re1.1", "Strengthening work of external rotation left shoulder.", "tests", "en");
t.add("test.dpr.comment.dpr_re1.2", "Strengthening work of external rotation right shoulder.", "tests", "en");
t.add("test.dpr.comment.dpr_re1.3", "Strengthening bilateral work of external rotation shoulders (left predominance)", "tests", "en");
t.add("test.dpr.comment.dpr_re1.4", "Strengthening bilateral work of external rotation shoulders (right predominance)", "tests", "en");
t.add("test.dpr.comment.muscular_maintenance_work", "Muscular maintenance work", "tests", "en");
t.add("test.result.ratio_tirage_dc", "Lying rowing \/ bench press", "tests", "en");
t.add("test.result.ratio_abdo_spinaux", "Abductor \/ spinal", "tests", "en");
t.add("test.graph_title.ratio_tirage_dc", "Lying rowing \/ bench press ratio", "tests", "en");
t.add("test.dpr.comment.ratio_ij_quadriceps.both_between", "Balance strengthening work of quadriceps and harmstrings.", "tests", "en");
t.add("test.dpr.comment.ratio_ij_quadriceps.both_below", "You should make strengthening bilateral work of hamstring compared to quadriceps.", "tests", "en");
t.add("test.dpr.comment.ratio_ij_quadriceps.both_above", "You should make strengthening bilateral work of quadriceps compared to hamstring.", "tests", "en");
t.add("test.dpr.comment.ratio_ij_quadriceps.left_below_right_between", "Privil\u00e9gier le travail de renforcement des ischio-jambiers par rapport \u00e0 celui du quadriceps \u00e0 gauche et travail \u00e9quilibr\u00e9 de renforcement du quadriceps et des ischio-jambiers \u00e0 droite.", "tests", "en");
t.add("test.dpr.comment.ratio_ij_quadriceps.left_between_right_below", "Privil\u00e9gier le travail de renforcement des ischio-jambiers par rapport \u00e0 celui du quadriceps \u00e0 droite et travail \u00e9quilibr\u00e9 de renforcement du quadriceps et des ischio-jambiers \u00e0 gauche.", "tests", "en");
t.add("test.dpr.comment.ratio_ij_quadriceps.left_above_right_between", "Privil\u00e9gier le travail de renforcement du quadriceps par rapport \u00e0 celui des ischio-jambiers \u00e0 gauche  et travail \u00e9quilibrer de renforcement du quadriceps et des ischio-jambiers \u00e0 droite.", "tests", "en");
t.add("test.dpr.comment.ratio_ij_quadriceps.left_between_right_above", "Privil\u00e9gier le travail de renforcement du quadriceps par rapport \u00e0 celui des ischio-jambiers \u00e0 droite et travail \u00e9quilibrer de renforcement du quadriceps et des ischio-jambiers \u00e0 gauche.", "tests", "en");
t.add("test.dpr.comment.ratio_adductors_abductors.both_between", "Balance strengthening work of adductors and abductors.", "tests", "en");
t.add("test.dpr.comment.ratio_adductors_abductors.both_below", "You should make strengthening bilateral work of adductors compared to abductors.", "tests", "en");
t.add("test.dpr.comment.ratio_adductors_abductors.both_above", "You should make strengthening bilateral work of abductors compared to adductors.", "tests", "en");
t.add("test.dpr.comment.ratio_adductors_abductors.left_below_right_between", "Privil\u00e9gier le travail de renforcement des adducteurs par rapport \u00e0 celui des abducteurs \u00e0 gauche et travail \u00e9quilibr\u00e9 de renforcement des adducteurs et des abducteurs \u00e0 droite.", "tests", "en");
t.add("test.dpr.comment.ratio_adductors_abductors.left_between_right_below", "Privil\u00e9gier le travail de renforcement des adducteurs par rapport \u00e0 celui des abducteurs \u00e0 droite et travail \u00e9quilibr\u00e9 de renforcement des adducteurs et des abducteurs \u00e0 gauche.", "tests", "en");
t.add("test.dpr.comment.ratio_adductors_abductors.left_above_right_between", "Privil\u00e9gier le travail de renforcement des abducteurs par rapport \u00e0 celui des adducteurs \u00e0 gauche et travail \u00e9quilibr\u00e9 de renforcement des adducteurs et des abducteurs \u00e0 droite.", "tests", "en");
t.add("test.dpr.comment.ratio_adductors_abductors.left_between_right_above", "Privil\u00e9gier le travail de renforcement des abducteurs par rapport \u00e0 celui des adducteurs \u00e0 droite et travail \u00e9quilibr\u00e9 de renforcement des adducteurs et des abducteurs \u00e0 gauche.", "tests", "en");
t.add("test.dpr.comment.ratio_re_ri.both_between", "Balance strengthening work of internal and external rotator.", "tests", "en");
t.add("test.dpr.comment.ratio_re_ri.both_below", "You should make bilateral strengthening work of external rotator compared to internal rotator.", "tests", "en");
t.add("test.dpr.comment.ratio_re_ri.both_above", "You should make bilateral strengthening work of internal rotator compared to external rotator.", "tests", "en");
t.add("test.dpr.comment.ratio_re_ri.left_below_right_between", "Privil\u00e9gier le travail de renforcement des rotateurs externes par rapport \u00e0 celui des rotateurs internes \u00e0 gauche et travail \u00e9quilibr\u00e9 de renforcement des rotateurs internes et externes \u00e0 droite.", "tests", "en");
t.add("test.dpr.comment.ratio_re_ri.left_between_right_below", "Privil\u00e9gier le travail de renforcement des rotateurs externes par rapport \u00e0 celui des rotateurs internes \u00e0 droite et travail \u00e9quilibr\u00e9 de renforcement des rotateurs internes et externes \u00e0 gauche.", "tests", "en");
t.add("test.dpr.comment.ratio_re_ri.left_above_right_between", "Privil\u00e9gier le travail de renforcement des rotateurs internes par rapport \u00e0 celui des rotateurs externes \u00e0 gauche et travail \u00e9quilibr\u00e9 de renforcement des rotateurs internes et externes \u00e0 droite.", "tests", "en");
t.add("test.dpr.comment.ratio_re_ri.left_between_right_above", "Privil\u00e9gier le travail de renforcement des rotateurs internes par rapport \u00e0 celui des rotateurs externes \u00e0 droite et travail \u00e9quilibr\u00e9 de renforcement des rotateurs internes et externes \u00e0 gauche.", "tests", "en");
t.add("test.dpr.comment.ratio_tirage_dc.below", "You should make bilateral strengthening work of posterior muscles compared to anterior muscles.", "tests", "en");
t.add("test.dpr.comment.ratio_tirage_dc.between", "Balance strengthening work of anterior and posterior muscles.", "tests", "en");
t.add("test.dpr.comment.ratio_tirage_dc.above", "You should make bilateral strengthening work of anterior muscles compared to posterior muscles.", "tests", "en");
t.add("test.dpr.comment.ratio_abdo_spinaux.between", "You should make strengthening work of abdominal compared to lumba.", "tests", "en");
t.add("test.dpr.comment.ratio_abdo_spinaux.below", "Balance strengthening work of abdominal and lumba.", "tests", "en");
t.add("test.dpr.comment.ratio_abdo_spinaux.above", "You should make strengthening work of lumba compared to abdominal.", "tests", "en");
t.add("test.comments", "Comments", "tests", "en");
t.add("test.exercise.name.illinois_modifie_average", "Average time", "tests", "en");
t.add("test.exercise.exercise_name.sirsi", "SI-RSI", "tests", "en");
t.add("test.exercise.exercise_name.uqybt", "UQ YBT", "tests", "en");
t.add("test.exercise.exercise_name.ckuest", "CKCUEST", "tests", "en");
t.add("test.exercise.exercise_name.endurance_indice", "Endurance index", "tests", "en");
t.add("test.exercise.exercise_name.re_ri", "Ratio RE\/RI", "tests", "en");
t.add("test.score_total", "Total score", "tests", "en");
t.add("test.score", "Score", "tests", "en");
t.add("test.score_title", "Tests scores", "tests", "en");
t.add("test.total_score", "Total score", "tests", "en");
t.add("test.dpr.comment.dpr_abductors.muscular_maintenance_work", "Travail d'entretien musculaire des abducteurs.", "tests", "en");
t.add("test.dpr.comment.dpr_quadriceps_force.muscular_maintenance_work", "Travail d'entretien musculaire des quadriceps", "tests", "en");
t.add("test.dpr.comment.dpr_ij_force.muscular_maintenance_work", "Travail d'entretien musculaire des ischio-jambiers", "tests", "en");
t.add("test.dpr.comment.dpr_adductors_force.muscular_maintenance_work", "Travail d'entretien musculaire des adducteurs.", "tests", "en");
t.add("test.dpr.comment.dpr_shirado.muscular_maintenance_work", "Travail d'entretien musculaire des abdominaux.", "tests", "en");
t.add("test.dpr.comment.dpr_sorensen.muscular_maintenance_work", "Travail d'entretien musculaire des lombaires.", "tests", "en");
t.add("test.dpr.comment.dpr_dc.muscular_maintenance_work", "Travail d'entretien musculaires des muscles ant\u00e9rieurs.", "tests", "en");
t.add("test.dpr.comment.dpr_tirage.muscular_maintenance_work", "Travail d\u2019entretien musculaire des muscles post\u00e9rieurs du tronc.", "tests", "en");
t.add("test.dpr.comment.dpr_ri1.muscular_maintenance_work", "Travail d'entretien musculaire des rotateurs internes.", "tests", "en");
t.add("test.dpr.comment.dpr_re1.muscular_maintenance_work", "Travail d'entretien musculaire des rotateurs externes.", "tests", "en");
t.add("exercise.custom_name.dpr_shirado", "Abdominal", "tests", "en");
t.add("exercise.custom_name.dpr_sorensen", "Spinal", "tests", "en");
t.add("test.exercise.exercise_name.single_leg_landing", "Single leg landing", "tests", "en");
t.add("test.exercise.exercise_name.single_hop", "Single hop", "tests", "en");
t.add("test.exercise.exercise_name.triple_hop", "Triple hop", "tests", "en");
t.add("test.exercise.exercise_name.side_hop_15_secondes", "Side hop 15 secondes", "tests", "en");
t.add("test.exercise.exercise_name.side_hop_30_secondes", "Side hop 30 secondes", "tests", "en");
t.add("test.exercise.exercise_name.crossover_hop", "Crossover hop", "tests", "en");
t.add("test.exercise.exercise_name.illinois_modifie_1s", "Time 1 (s)", "tests", "en");
t.add("test.exercise.exercise_name.illinois_modifie_2s", "Time 2 (s)", "tests", "en");
t.add("test.exercise.exercise_name.excessive_movement_of_bars_to_balance", "Excessive arm movement to balance", "tests", "en");
t.add("test.exercise.exercise_name.inclined_in_different_positions", "Leaning in any directiin", "tests", "en");
t.add("test.exercise.exercise_name.loss_of_the_horizontal_plane", "Loss of horizontal plane", "tests", "en");
t.add("test.exercise.exercise_name.flip_or_excessive_rotation", "Excessive tilt or rotation", "tests", "en");
t.add("test.exercise.exercise_name.hip_adduction", "WB thigh moves into hip adduction", "tests", "en");
t.add("test.exercise.exercise_name.not_maintaining_the_neutral_position_of_the_contralateral_thigh", "NWB  thigh not held in neutral", "tests", "en");
t.add("test.exercise.exercise_name.ball_head_oriented_towards_the_2nd_toe", "Patella pointing towards 2nd toe (noticeable valgus)", "tests", "en");
t.add("test.exercise.exercise_name.ball_joint_oriented_towards_the_inside_of_the_foot", "Patella pointing past inside of foot (significant valgus)", "tests", "en");
t.add("test.exercise.exercise_name.rebalance_with_the_other", "Touches down with NWB foot", "tests", "en");
t.add("test.exercise.exercise_name.the_receiving_leg_wobbles_noticeably", "Stance leg wobbles noticeably", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_1", "Are you confident that you can perform at your previous level of sport participation?", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_2", "Do you think you are likely to re-injury your knee by participating in your sport?", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_3", "Are you nervous about playing your sport?", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_4", "Are you confident that your knee will not give way by playing your sport?", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_5", "Are you confident that you could play your sport without concern for your knee?", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_6", "Do you find it frustrating to have to consider your knee with respect to your sport?", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_7", "Are you fearful of re-injuring your knee by playing your sport?", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_8", "Are you confident about your knee holding up under pressure?", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_9", "Are you afraid of accidentally injuring your knee by playing your sport?", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_10", "Do thoughts of having to go through surgery and rehabilitation prevent you from playing your sport?", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_11", "Are you confident about your ability to perform well at your sport?", "tests", "en");
t.add("test.exercise.exercise_name.aclrsi_question_12", "Do you feel relaxed about playing your sport?", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_1", "One leg stabilization capacity", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_2", "Iliopsoas capacity work", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_3", "Hip stabilizer capacity", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_4", "Capacit\u00e9 de travail des ischio-jambiers (course interne)", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_5", "Capacit\u00e9 de travail des abducteurs de hanche", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_6", "Capacit\u00e9 de stabilisation unipodal en statique avec r\u00e9partition des charges", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_7", "Capacit\u00e9 du triceps sural \u00e0 maintenir la position", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_8", "Capacit\u00e9 du contr\u00f4le excentrique du quadriceps", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_9", "Capacit\u00e9 des stabilisateurs de hanche en dynamique et en charge", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_10", "Capacit\u00e9 de stimulation des fibres rapides", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_11", "Technique d'appui en dynamique dans l'axe", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_12", "Capacit\u00e9 de stabilisation unipodal en dynamique", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_13", "Capacit\u00e9 des stabilisateurs de hanche en dynamique et en charge", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_14", "Capacit\u00e9 concentrique et excentrique des ischio-jambiers", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_15", "Capacit\u00e9 de stimulation des fibres rapides", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_16", "Technique d'appui en dynamique en avant\/arri\u00e8re", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_17", "Aper\u00e7u de l'appr\u00e9hension", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_18", "Capacit\u00e9 de force du quadriceps en concentrique sur un angle fonctionnel (90\u00b0)", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_19", "Capacit\u00e9 de force du quadriceps en concentrique sur un angle sportif (120\u00b0)", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_20", "Capacit\u00e9 excentrique du quadriceps", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_21", "Capacit\u00e9 de stimulation des fibres rapides", "tests", "en");
t.add("test.exercise.exercise_name.tar_question_22", "Technique d\u2019appuis en dynamique en lat\u00e9ral", "tests", "en");
t.add("test.exercise.exercise_name.dpr_closed_eyes_unipodal_stability", "Stabilit\u00e9 unipodale yeux ferm\u00e9s", "tests", "en");
t.add("test.exercise.exercise_name.dpr_unipodal_knee_flexion", "Flexion de genou unipodale", "tests", "en");
t.add("test.exercise.exercise_name.dpr_squat", "Accroupissement", "tests", "en");
t.add("test.exercise.exercise_name.dpr_proprioception_basin", "Proprioception bassin", "tests", "en");
t.add("test.exercise.exercise_name.dpr_posterior_muscle_chain", "Cha\u00eene musculaire post\u00e9rieure", "tests", "en");
t.add("test.exercise.exercise_name.dpr_dip_shoulders", "Epaules dips", "tests", "en");
t.add("test.exercise.exercise_name.dpr_quadriceps_force", "Quadriceps", "tests", "en");
t.add("test.exercise.exercise_name.dpr_ij_force", "IJ", "tests", "en");
t.add("test.exercise.exercise_name.dpr_adductors_force", "Adducteurs", "tests", "en");
t.add("test.exercise.exercise_name.dpr_abductors", "Abducteurs", "tests", "en");
t.add("test.exercise.exercise_name.dpr_shirado", "Shirado", "tests", "en");
t.add("test.exercise.exercise_name.dpr_sorensen", "Sorensen", "tests", "en");
t.add("test.exercise.exercise_name.dpr_dc", "DC", "tests", "en");
t.add("test.exercise.exercise_name.dpr_tirage", "Tirage", "tests", "en");
t.add("test.exercise.exercise_name.dpr_ri1", "RI1", "tests", "en");
t.add("test.exercise.exercise_name.dpr_re1", "RE1", "tests", "en");
t.add("test.exercise.exercise_name.dpr_quadriceps_flexibility", "Quadriceps", "tests", "en");
t.add("test.exercise.exercise_name.dpr_ij_flexibility", "IJ", "tests", "en");
t.add("test.exercise.exercise_name.dpr_adductors_flexibility", "Adducteurs", "tests", "en");
t.add("test.exercise.exercise_name.dpr_calves", "Mollets", "tests", "en");
t.add("test.exercise.exercise_name.dpr_fingers_ground", "Doigts \/ Sol", "tests", "en");
t.add("test.exercise.exercise_name.dpr_psoas", "PSOAS", "tests", "en");
t.add("test.exercise.exercise_name.dpr_internal_rotation", "Rotation interne", "tests", "en");
t.add("test.exercise.exercise_name.dpr_candlestick", "Chandelier", "tests", "en");
t.add("test.exercise.exercise_name.dpr_retropulsion", "R\u00e9tropulsion", "tests", "en");
t.add("test.exercise.exercise_name.dpr_antepulsion", "Ant\u00e9pulsion", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_1_name_1", "D\u00e9faut", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_2_name_1", "Arm strategy", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_2_name_2", "Trunk alignment", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_2_name_3", "Pelvic plane", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_2_name_4", "Thigh motion", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_2_name_5", "Knee position", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_2_name_6", "Steady stance", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_3_name_1", "D\u00e9faut", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_4_name_1", "Circuits de mise en route", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_4_name_2", "", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_4_name_3", "Circuits de renforcement", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_4_name_4", "Quadriceps 1", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_4_name_5", "Ischio-jambiers", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_4_name_6", "Quadriceps 2", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_1", "Tests fonctionnels", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_2", "Membres inf\u00e9rieurs", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_3", "Tronc", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_4", "Membres sup\u00e9rieurs", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_5", "Tests de force", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_6", "Membres inf\u00e9rieurs", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_7", "Tronc", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_8", "Membres sup\u00e9rieurs", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_9", "Tests de souplesse", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_10", "Membres inf\u00e9rieurs", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_11", "Tronc", "tests", "en");
t.add("test.exercise_group.exercise_group_name.test_5_name_12", "Membres sup\u00e9rieurs", "tests", "en");
t.add("test.exercise.exercise_name.rotateur_externe", "External rotator", "tests", "en");
t.add("test.exercise.exercise_name.rotateur_interne", "Internal rotator", "tests", "en");
t.add("test.exercise.exercise_name.y_balance_test_medial", "YBT M", "tests", "en");
t.add("test.exercise.exercise_name.y_balance_test_infero_lateral", "YBT IL", "tests", "en");
t.add("test.exercise.exercise_name.y_balance_test_supero_lateral", "YBT SL", "tests", "en");
t.add("test.exercise.exercise_name.usspt", "USSPT", "tests", "en");
t.add("test.exercise.exercise_name.ckcuest", "CKCUEST", "tests", "en");
t.add("test.scaleSIRSI", "SI - RSI scale", "tests", "en");
t.add("test.sirsi_test_results", "SI - RSI scale", "tests", "en");
t.add("test.sstarts.globalComment", "Global comment", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_1", "Do you think you can practice your sport at the same level as before?", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_2", "Do you think you might injure your shoulder again if you return to sport?", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_3", "Are you worried about returning to your sport?", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_4", "Do you think that your shoulder will be stable during your sports practice?", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_5", "Do you think you can practice your sport without worrying about your shoulder?", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_6", "Are you frustrated with having to take your shoulder into account when practicing sports?", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_7", "Craignez-vous de vous blesser de nouveau l\u2019\u00e9paule lors de votre pratique sportive ?", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_8", "Are you worried about injuring your shoulder again when practicing sports?", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_9", "Are you afraid of accidentally injuring your shoulder again while exercising?", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_10", "Does the thought of eventually having to undergo operation again or re-education prevent you from playing your sport?", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_11", "Are you confident in your ability to play your sport well?", "tests", "en");
t.add("test.exercise.exercise_name.sirsi_question_12", "Do you feel relaxed about playing your sport?", "tests", "en");
t.add("test.sirsi_questionaire_description", "Please answer the following questions about the main sport you played before the accident. For each question, check the box between the two extremes depending on what you think best corresponds to your current shoulder situation.", "tests", "en");
t.add("test.sirsi_edge.not_at_all_sure", "Not sure at all", "tests", "en");
t.add("test.sirsi_edge.totally_sure", "Totally sure", "tests", "en");
t.add("test.sirsi_edge.extremely_likely", "Extremely likely", "tests", "en");
t.add("test.sirsi_edge.not_at_all_likely", "Completely unlikely", "tests", "en");
t.add("test.sirsi_edge.extremely_worried", "Extremely worried", "tests", "en");
t.add("test.sirsi_edge.not_at_all_worried", "Not worried at all", "tests", "en");
t.add("test.sirsi_edge.extremely_frustrated", "Extremely frustrated", "tests", "en");
t.add("test.sirsi_edge.not_at_all_frustrated", "Not frustrated at all", "tests", "en");
t.add("test.sirsi_edge.extreme_fear", "Extreme fear", "tests", "en");
t.add("test.sirsi_edge.no_fear", "No fear", "tests", "en");
t.add("test.sirsi_edge.very_scared", "Very scared", "tests", "en");
t.add("test.sirsi_edge.not_at_all_scared", "Not scared at all", "tests", "en");
t.add("test.sirsi_edge.all_the_time", "All the time", "tests", "en");
t.add("test.sirsi_edge.at_no_time", "At no time", "tests", "en");
t.add("test.sirsi_edge.not_at_all_confident", "Not confident at all", "tests", "en");
t.add("test.sirsi_edge.totally_confident", "Totally confident", "tests", "en");
t.add("test.sirsi_edge.not_at_all_relaxed", "Not relaxed at all", "tests", "en");
t.add("test.sirsi_edge.totally_relaxed", "Totally relaxed", "tests", "en");
t.add("test.k_starts_launch_warning", "__test.k_starts_launch_warning", "tests", "en");
t.add("test.normal", "__test.normal", "tests", "en");
t.add("test.exercise.maintenance", "__test.exercise.maintenance", "tests", "en");
t.add("test.insufficient", "__test.insufficient", "tests", "en");
t.add("test.exercise.correction", "__test.exercise.correction", "tests", "en");
t.add("test.risky", "__test.risky", "tests", "en");
t.add("test.exercise.specific", "__test.exercise.specific", "tests", "en");
})(Translator);
