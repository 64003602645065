(function (t) {
// mn
t.add("This value should be false.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be true.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be of type {{ type }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430  {{ type }} -\u043d \u0442\u04e9\u0440\u04e9\u043b \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be blank.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0445\u043e\u043e\u0441\u043e\u043d \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("The value you selected is not a valid choice.", "\u0421\u043e\u043d\u0433\u043e\u0441\u043e\u043d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u0425\u0430\u043c\u0433\u0438\u0439\u043d \u0431\u0430\u0433\u0430\u0434\u0430\u0430 {{ limit }} \u0443\u0442\u0433\u0430 \u0441\u043e\u043d\u0433\u043e\u0433\u0434\u0441\u043e\u043d \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u0425\u0430\u043c\u0433\u0438\u0439\u043d \u0438\u0445\u0434\u044d\u044d {{ limit }} \u0443\u0442\u0433\u0430 \u0441\u043e\u043d\u0433\u043e\u0433\u0434\u043e\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0442\u043e\u0439.", "validators", "mn");
t.add("One or more of the given values is invalid.", "\u04e8\u0433\u04e9\u0433\u0434\u0441\u04e9\u043d \u043d\u044d\u0433 \u044d\u0441\u0432\u044d\u043b \u043d\u044d\u0433\u044d\u044d\u0441 \u043e\u043b\u043e\u043d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This field was not expected.", "\u042d\u043d\u044d \u0442\u0430\u043b\u0431\u0430\u0440 \u043d\u044c \u0445\u04af\u043b\u044d\u044d\u0433\u0434\u044d\u0436 \u0431\u0430\u0439\u0441\u0430\u043d \u044e\u043c.", "validators", "mn");
t.add("This field is missing.", "\u042d\u043d\u044d \u0442\u0430\u043b\u0431\u0430\u0440 \u043d\u044c \u0430\u043b\u0433\u0430 \u0431\u043e\u043b\u0441\u043e\u043d \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid date.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 date \u0442\u04e9\u0440\u04e9\u043b \u0431\u0430\u0439\u043d\u0430 .", "validators", "mn");
t.add("This value is not a valid datetime.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0446\u0430\u0433 \u0442\u04e9\u0440\u04e9\u043b \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid email address.", "\u0418-\u043c\u0430\u0439\u043b \u0445\u0430\u044f\u0433 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The file could not be found.", "\u0424\u0430\u0439\u043b \u043e\u043b\u0434\u0441\u043e\u043d\u0433\u04af\u0439.", "validators", "mn");
t.add("The file is not readable.", "\u0424\u0430\u0439\u043b \u0443\u043d\u0448\u0438\u0433\u0434\u0430\u0445\u0443\u0439\u0446 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0424\u0430\u0439\u043b \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0442\u043e\u043c \u0431\u0430\u0439\u043d\u0430 ({{ size }} {{ suffix }}). \u0417\u04e9\u0432\u0448\u04e9\u04e9\u0440\u04e9\u0433\u0434\u04e9\u0445 \u0434\u044d\u044d\u0434 \u0445\u044d\u043c\u0436\u044d\u044d  {{ limit }} {{ suffix }} \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "\u0424\u0430\u0439\u043b\u044b\u043d MIME-\u0442\u04e9\u0440\u04e9\u043b \u043d\u044c \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430 ({{ type }}). \u0417\u04e9\u0432\u0448\u04e9\u04e9\u0440\u04e9\u0433\u0434\u04e9\u0445 MIME-\u0442\u04e9\u0440\u043b\u04af\u04af\u0434 {{ types }}.", "validators", "mn");
t.add("This value should be {{ limit }} or less.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430  {{ limit }} \u044e\u043c\u0443\u0443 \u044d\u0441\u0432\u044d\u043b \u0431\u0430\u0433\u0430 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0443\u0440\u0442 \u0431\u0430\u0439\u043d\u0430. {{ limit }} \u0442\u044d\u043c\u0434\u044d\u0433\u0442\u0438\u0439\u043d \u0443\u0440\u0442\u0442\u0430\u0439 \u044e\u043c\u0443\u0443 \u044d\u0441\u0432\u044d\u043b \u0431\u0430\u0433\u0430 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value should be {{ limit }} or more.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ limit }} \u044e\u043c\u0443\u0443 \u044d\u0441\u0432\u044d\u043b \u0438\u0445 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0431\u043e\u0433\u0438\u043d\u043e \u0431\u0430\u0439\u043d\u0430. {{ limit }} \u0442\u044d\u043c\u0434\u044d\u0433\u0442 \u044d\u0441\u0432\u044d\u043b \u0438\u0445 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value should not be blank.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0445\u043e\u043e\u0441\u043e\u043d \u0431\u0430\u0439\u0436 \u0431\u043e\u043b\u043e\u0445\u0433\u04af\u0439.", "validators", "mn");
t.add("This value should not be null.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 null \u0431\u0430\u0439\u0436 \u0431\u043e\u043b\u043e\u0445\u0433\u04af\u0439.", "validators", "mn");
t.add("This value should be null.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 null \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not valid.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid time.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0446\u0430\u0433 \u0442\u04e9\u0440\u04e9\u043b \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid URL.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 URL \u0431\u0430\u0439\u043d\u0430 .", "validators", "mn");
t.add("The two values should be equal.", "\u0425\u043e\u0451\u0440 \u0443\u0442\u0433\u0443\u0443\u0434 \u0438\u0436\u0438\u043b \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0424\u0430\u0439\u043b \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0442\u043e\u043c \u0431\u0430\u0439\u043d\u0430. \u0417\u04e9\u0432\u0448\u04e9\u04e9\u0440\u04e9\u0433\u0434\u04e9\u0445 \u0434\u044d\u044d\u0434 \u0445\u044d\u043c\u0436\u044d\u044d \u043d\u044c {{ limit }} {{ suffix }} \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The file is too large.", "\u0424\u0430\u0439\u043b \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0442\u043e\u043c \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The file could not be uploaded.", "\u0424\u0430\u0439\u043b upload \u0445\u0438\u0439\u0433\u0434\u0441\u044d\u043d\u0433\u04af\u0439.", "validators", "mn");
t.add("This value should be a valid number.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0437\u04e9\u0432\u0445\u04e9\u043d \u0442\u043e\u043e \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This file is not a valid image.", "\u0424\u0430\u0439\u043b \u0437\u0443\u0440\u0430\u0433 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This is not a valid IP address.", "IP \u0445\u0430\u044f\u0433 \u0437\u04e9\u0432 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid language.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0437\u04e9\u0432 \u0445\u044d\u043b \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid locale.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0437\u04e9\u0432 \u0431\u0430\u0439\u0440\u0448\u0438\u043b \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid country.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0431\u043e\u0434\u0438\u0442 \u0443\u043b\u0441 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is already used.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0430\u043b\u044c \u0445\u044d\u0434\u0438\u0439\u043d\u044d\u044d \u0445\u044d\u0440\u044d\u0433\u043b\u044d\u0433\u0434\u0441\u044d\u043d \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The size of the image could not be detected.", "\u0417\u0443\u0440\u0433\u0438\u0439\u043d \u0445\u044d\u043c\u0436\u044d\u044d \u0442\u043e\u0433\u0442\u043e\u043e\u0433\u0434\u043e\u0436 \u0447\u0430\u0434\u0441\u0430\u043d\u0433\u04af\u0439.", "validators", "mn");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u0417\u0443\u0440\u0433\u0438\u0439\u043d \u04e9\u0440\u0433\u04e9\u043d \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0442\u043e\u043c \u0431\u0430\u0439\u043d\u0430 ({{ width }}px). \u04e8\u0440\u0433\u04e9\u043d \u043d\u044c \u0445\u0430\u043c\u0433\u0438\u0439\u043d \u0438\u0445\u0434\u044d\u044d {{ max_width }}px \u0431\u0430\u0439\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0442\u043e\u0439.", "validators", "mn");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u0417\u0443\u0440\u0433\u0438\u0439\u043d \u04e9\u0440\u0433\u04e9\u043d \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0436\u0438\u0436\u0438\u0433 \u0431\u0430\u0439\u043d\u0430 ({{ width }}px). \u04e8\u0440\u0433\u04e9\u043d \u043d\u044c \u0445\u0430\u043c\u0433\u0438\u0439\u043d \u0431\u0430\u0433\u0430\u0434\u0430\u0430 {{ min_width }}px \u0431\u0430\u0439\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0442\u043e\u0439.", "validators", "mn");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u0417\u0443\u0440\u0433\u0438\u0439\u043d \u04e9\u043d\u0434\u04e9\u0440 \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0442\u043e\u043c \u0431\u0430\u0439\u043d\u0430 ({{ height }}px). \u04e8\u043d\u0434\u04e9\u0440 \u043d\u044c \u0445\u0430\u043c\u0433\u0438\u0439\u043d \u0438\u0445\u0434\u044d\u044d {{ max_height }}px \u0431\u0430\u0439\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0442\u043e\u0439.", "validators", "mn");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u0417\u0443\u0440\u0433\u0438\u0439\u043d \u04e9\u043d\u0434\u04e9\u0440 \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0436\u0438\u0436\u0438\u0433 \u0431\u0430\u0439\u043d\u0430 ({{ height }}px). \u04e8\u043d\u0434\u04e9\u0440 \u043d\u044c \u0445\u0430\u043c\u0433\u0438\u0439\u043d \u0431\u0430\u0433\u0430\u0434\u0430\u0430 {{ min_height }}px \u0431\u0430\u0439\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be the user's current password.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0445\u044d\u0440\u044d\u0433\u043b\u044d\u0433\u0447\u0438\u0439\u043d \u043e\u0434\u043e\u043e\u0433\u0438\u0439\u043d \u043d\u0443\u0443\u0446 \u04af\u0433 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u044f\u0433 {{ limit }} \u0442\u044d\u043c\u0434\u044d\u0433\u0442 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.|\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u044f\u0433 {{ limit }} \u0442\u044d\u043c\u0434\u044d\u0433\u0442 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("The file was only partially uploaded.", "\u0424\u0430\u0439\u043b\u044b\u043d \u0437\u04e9\u0432\u0445\u04e9\u043d \u0445\u0430\u0433\u0430\u0441 \u043d\u044c upload \u0445\u0438\u0439\u0433\u0434\u0441\u044d\u043d.", "validators", "mn");
t.add("No file was uploaded.", "\u042f\u043c\u0430\u0440 \u0447 \u0444\u0430\u0439\u043b upload \u0445\u0438\u0439\u0433\u0434\u0441\u044d\u043d\u0433\u04af\u0439.", "validators", "mn");
t.add("No temporary folder was configured in php.ini.", "php.ini \u0434\u044d\u044d\u0440 \u0442\u04af\u0440 \u0437\u0443\u0443\u0440\u044b\u043d \u0445\u0430\u0432\u0442\u0441\u044b\u0433 \u0442\u043e\u0445\u0438\u0440\u0443\u0443\u043b\u0430\u0430\u0433\u04af\u0439 \u0431\u0430\u0439\u043d\u0430, \u044d\u0441\u0432\u044d\u043b \u0442\u043e\u0445\u0438\u0440\u0443\u0443\u043b\u0441\u0430\u043d \u0445\u0430\u0432\u0442\u0430\u0441 \u0431\u0430\u0439\u0445\u0433\u04af\u0439 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("Cannot write temporary file to disk.", "\u0422\u04af\u0440 \u0437\u0443\u0443\u0440\u044b\u043d \u0444\u0430\u0439\u043b\u044b\u0433 \u0434\u0438\u0441\u043a \u0440\u0443\u0443 \u0431\u0438\u0447\u0438\u0436 \u0431\u043e\u043b\u043e\u0445\u0433\u04af\u0439 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("A PHP extension caused the upload to fail.", "PHP extension \u043d\u044c upload -\u0433 \u0430\u043c\u0436\u0438\u043b\u0442\u0433\u04af\u0439 \u0431\u043e\u043b\u0433\u043e\u043e\u0434 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u042d\u043d\u044d \u043a\u043e\u043b\u043b\u0435\u043a\u0446 {{ limit }} \u0431\u0430 \u0442\u04af\u04af\u043d\u044d\u044d\u0441 \u0434\u044d\u044d\u0448 \u0442\u043e\u043e\u043d\u044b \u044d\u043b\u0435\u043c\u0435\u043d\u0442 \u0430\u0433\u0443\u0443\u043b\u0430\u0445 \u0451\u0441\u0442\u043e\u0439.|\u042d\u043d\u044d \u043a\u043e\u043b\u043b\u0435\u043a\u0446 {{ limit }} \u0431\u0430 \u0442\u04af\u04af\u043d\u044d\u044d\u0441 \u0434\u044d\u044d\u0448 \u0442\u043e\u043e\u043d\u044b \u044d\u043b\u0435\u043c\u0435\u043d\u0442 \u0430\u0433\u0443\u0443\u043b\u0430\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u042d\u043d\u044d \u043a\u043e\u043b\u043b\u0435\u043a\u0446 {{ limit }} \u0431\u0430 \u0442\u04af\u04af\u043d\u044d\u044d\u0441 \u0434\u043e\u043e\u0448 \u0442\u043e\u043e\u043d\u044b \u044d\u043b\u0435\u043c\u0435\u043d\u0442 \u0430\u0433\u0443\u0443\u043b\u0430\u0445 \u0451\u0441\u0442\u043e\u0439.|\u042d\u043d\u044d \u043a\u043e\u043b\u043b\u0435\u043a\u0446 {{ limit }} \u0431\u0430 \u0442\u04af\u04af\u043d\u044d\u044d\u0441 \u0434\u043e\u043e\u0448 \u0442\u043e\u043e\u043d\u044b \u044d\u043b\u0435\u043c\u0435\u043d\u0442 \u0430\u0433\u0443\u0443\u043b\u0430\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u042d\u043d\u044d \u043a\u043e\u043b\u043b\u0435\u043a\u0446 \u044f\u0433 {{ limit }} \u044d\u043b\u0435\u043c\u0435\u043d\u0442 \u0430\u0433\u0443\u0443\u043b\u0430\u0445 \u0451\u0441\u0442\u043e\u0439.|\u042d\u043d\u044d \u043a\u043e\u043b\u043b\u0435\u043a\u0446 \u044f\u0433 {{ limit }} \u044d\u043b\u0435\u043c\u0435\u043d\u0442 \u0430\u0433\u0443\u0443\u043b\u0430\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("Invalid card number.", "\u041a\u0430\u0440\u0442\u044b\u043d \u0434\u0443\u0433\u0430\u0430\u0440 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("Unsupported card type or invalid card number.", "\u0414\u044d\u043c\u0436\u0438\u0433\u0434\u044d\u044d\u0433\u04af\u0439 \u043a\u0430\u0440\u0442\u044b\u043d \u0442\u04e9\u0440\u04e9\u043b \u044d\u0441\u0432\u044d\u043b \u043a\u0430\u0440\u0442\u044b\u043d \u0434\u0443\u0433\u0430\u0430\u0440 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0437\u04e9\u0432 \u041e\u043b\u043e\u043d \u0423\u043b\u0441\u044b\u043d \u0411\u0430\u043d\u043a\u043d\u044b \u0414\u0430\u043d\u0441\u043d\u044b \u0414\u0443\u0433\u0430\u0430\u0440 (IBAN) \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid ISBN-10.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0437\u04e9\u0432 ISBN-10 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid ISBN-13.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0437\u04e9\u0432 ISBN-13 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0437\u04e9\u0432 ISBN-10 \u044e\u043c\u0443\u0443 ISBN-13 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid ISSN.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0437\u04e9\u0432 ISSN \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value is not a valid currency.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0431\u043e\u0434\u0438\u0442 \u0432\u0430\u043b\u044e\u0442 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value should be equal to {{ compared_value }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ compared_value }} -\u0442a\u0439 \u0442\u044d\u043d\u0446\u04af\u04af \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be greater than {{ compared_value }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ compared_value }} -\u0441 \u0438\u0445 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ compared_value }} -\u0442\u0430\u0439 \u0442\u044d\u043d\u0446\u04af\u04af \u044e\u043c\u0443\u0443 \u044d\u0441\u0432\u044d\u043b \u0438\u0445 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ compared_value_type }} {{ compared_value }} -\u0442\u0430\u0439 \u044f\u0433 \u0438\u0436\u0438\u043b \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be less than {{ compared_value }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ compared_value }} -\u0441 \u0431\u0430\u0433\u0430 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be less than or equal to {{ compared_value }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ compared_value }} -\u0442\u0430\u0439 \u0438\u0436\u0438\u043b \u044e\u043c\u0443\u0443 \u044d\u0441\u0432\u044d\u043b \u0431\u0430\u0433\u0430 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should not be equal to {{ compared_value }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ compared_value }} -\u0442\u0430\u0439 \u0442\u044d\u043d\u0446\u04af\u04af \u0431\u0430\u0439\u0445 \u0451\u0441\u0433\u04af\u0439.", "validators", "mn");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ compared_value_type }} {{ compared_value }} -\u0442\u0430\u0439 \u044f\u0433 \u0438\u0436\u0438\u043b \u0431\u0430\u0439\u0445 \u0451\u0441\u0433\u04af\u0439.", "validators", "mn");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u0417\u0443\u0440\u0433\u0438\u0439\u043d \u0445\u0430\u0440\u044c\u0446\u0430\u0430 \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0442\u043e\u043c \u0431\u0430\u0439\u043d\u0430 ({{ ratio }}). \u0425\u0430\u0440\u044c\u0446\u0430\u0430 \u043d\u044c \u0445\u0430\u043c\u0433\u0438\u0439\u043d \u0438\u0445\u0434\u044d\u044d {{ max_ratio }} \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u0417\u0443\u0440\u0433\u0438\u0439\u043d \u0445\u0430\u0440\u044c\u0446\u0430\u0430 \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0436\u0438\u0436\u0438\u0433 \u0431\u0430\u0439\u043d\u0430 ({{ ratio }}). \u0425\u0430\u0440\u044c\u0446\u0430\u0430 \u043d\u044c \u0445\u0430\u043c\u0433\u0438\u0439\u043d \u0431\u0430\u0433\u0430\u0434\u0430\u0430 {{ min_ratio }} \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u0417\u0443\u0440\u0430\u0433 \u0434\u04e9\u0440\u0432\u04e9\u043b\u0436\u0438\u043d \u0445\u044d\u043b\u0431\u044d\u0440\u0442\u044d\u0439 \u0431\u0430\u0439\u043d\u0430 ({{ width }}x{{ height }}px). \u0414\u04e9\u0440\u0432\u04e9\u043b\u0436\u0438\u043d \u0437\u0443\u0440\u0433\u0443\u0443\u0434 \u043e\u0440\u0443\u0443\u043b\u0430\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0433\u04af\u0439.", "validators", "mn");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u0417\u0443\u0440\u0430\u0433 \u0445\u044d\u0432\u0442\u044d\u044d \u0431\u0430\u0439\u0440\u043b\u0430\u043b\u0442\u0430\u0439 \u0431\u0430\u0439\u043d\u0430 ({{ width }}x{{ height }}px). \u0425\u044d\u0432\u0442\u044d\u044d \u0431\u0430\u0439\u0440\u043b\u0430\u043b\u0442\u0430\u0439 \u0437\u0443\u0440\u0433\u0443\u0443\u0434 \u043e\u0440\u0443\u0443\u043b\u0430\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0433\u04af\u0439.", "validators", "mn");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u0417\u0443\u0440\u0433\u0443\u0443\u0434 \u0431\u043e\u0441\u043e\u043e \u0431\u0430\u0439\u0440\u043b\u0430\u043b\u0442\u0430\u0439 \u0431\u0430\u0439\u043d\u0430 ({{ width }}x{{ height }}px). \u0411\u043e\u0441\u043e\u043e \u0431\u0430\u0439\u0440\u043b\u0430\u043b\u0442\u0430\u0439 \u0437\u0443\u0440\u0433\u0443\u0443\u0434 \u043e\u0440\u0443\u0443\u043b\u0430\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0433\u04af\u0439.", "validators", "mn");
t.add("An empty file is not allowed.", "\u0425\u043e\u043e\u0441\u043e\u043d \u0444\u0430\u0439\u043b \u043e\u0440\u0443\u0443\u043b\u0430\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0433\u04af\u0439.", "validators", "mn");
t.add("The host could not be resolved.", "\u0425\u043e\u0441\u0442 \u0437\u04e9\u0432 \u0442\u043e\u0445\u0438\u0440\u043e\u0433\u0434\u043e\u043e\u0433\u04af\u0439 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value does not match the expected {{ charset }} charset.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0442\u043e\u043e\u0446\u043e\u043e\u043b\u0441\u043e\u043d {{ charset }} \u0442\u044d\u043c\u0434\u044d\u0433\u0442\u0442\u044d\u0439 \u0442\u0430\u0430\u0440\u0430\u0445\u0433\u04af\u0439 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This is not a valid Business Identifier Code (BIC).", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0437\u04e9\u0432 Business Identifier Code (BIC) \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("Error", "\u0410\u043b\u0434\u0430\u0430", "validators", "mn");
t.add("This is not a valid UUID.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0437\u04e9\u0432 UUID \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value should be a multiple of {{ compared_value }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ compared_value }} -\u043d \u04af\u0440\u0436\u0432\u044d\u0440 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "\u042d\u043d\u044d Business Identifier Code (BIC) \u043a\u043e\u0434 \u043d\u044c IBAN {{ iban }} -\u0442\u0430\u0439 \u0445\u043e\u043b\u0431\u043e\u043e\u0433\u04af\u0439 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This value should be valid JSON.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 JSON \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This collection should contain only unique elements.", "\u042d\u043d\u044d \u043a\u043e\u043b\u043b\u0435\u043a\u0446 \u0437\u04e9\u0432\u0445\u04e9\u043d \u0434\u0430\u0432\u0442\u0430\u0433\u0434\u0430\u0445\u0433\u04af\u0439 \u044d\u043b\u0435\u043c\u0435\u043d\u0442\u04af\u04af\u0434 \u0430\u0433\u0443\u0443\u043b\u0430\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be positive.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u044d\u0435\u0440\u044d\u0433 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be either positive or zero.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0442\u044d\u0433 \u044d\u0441\u0432\u044d\u043b \u044d\u0435\u0440\u044d\u0433 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be negative.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0441\u04e9\u0440\u04e9\u0433 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should be either negative or zero.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0441\u04e9\u0440\u04e9\u0433 \u044d\u0441\u0432\u044d\u043b \u0442\u044d\u0433 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value is not a valid timezone.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u04af\u043d\u044d\u043d \u0437\u04e9\u0432 \u0446\u0430\u0433\u0438\u0439\u043d \u0431\u04af\u0441 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "\u042d\u043d\u044d \u043d\u0443\u0443\u0446 \u04af\u0433\u0438\u0439\u043d \u043c\u044d\u0434\u044d\u044d\u043b\u044d\u043b \u0430\u043b\u0434\u0430\u0433\u0434\u0441\u0430\u043d \u0431\u0430\u0439\u0445 \u043c\u0430\u0433\u0430\u0434\u043b\u0430\u043b\u0442\u0430\u0439 \u0443\u0447\u0440\u0430\u0430\u0441 \u0434\u0430\u0445\u0438\u043d \u0430\u0448\u0438\u0433\u043b\u0430\u0433\u0434\u0430\u0445 \u0451\u0441\u0433\u04af\u0439. \u04e8\u04e9\u0440 \u043d\u0443\u0443\u0446 \u04af\u0433 \u0430\u0448\u0438\u0433\u043b\u0430\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("This value should be between {{ min }} and {{ max }}.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 {{ min }} -\u0441 {{ max }} \u0445\u043e\u043e\u0440\u043e\u043d\u0434 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value is not a valid hostname.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 hostname \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "\u042d\u043d\u044d\u0445\u04af\u04af \u0446\u0443\u0433\u043b\u0443\u0443\u043b\u0433\u0430\u043d \u0434\u0430\u0445\u044c \u044d\u043b\u0435\u043c\u044d\u043d\u0442\u0438\u0439\u043d \u0442\u043e\u043e, {{ compared_value }}-\u043d \u04af\u0440\u0436\u0432\u044d\u0440 \u0431\u0430\u0439\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value should satisfy at least one of the following constraints:", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0434\u043e\u043e\u0440\u0445 \u0431\u043e\u043b\u0437\u043e\u043b\u0443\u0443\u0434\u044b\u043d \u044f\u0434\u0430\u0436 \u043d\u044d\u0433\u0438\u0439\u0433 \u0445\u0430\u043d\u0433\u0430\u0445 \u0451\u0441\u0442\u043e\u0439:", "validators", "mn");
t.add("Each element of this collection should satisfy its own set of constraints.", "\u042d\u043d\u044d\u0445\u04af\u04af \u0446\u0443\u0433\u043b\u0443\u0443\u043b\u0433\u0430\u043d \u0434\u0430\u0445\u044c \u044d\u043b\u0435\u043c\u044d\u043d\u0442\u04af\u04af\u0434 \u04e9\u04e9\u0440 \u04e9\u04e9\u0440\u0441\u0434\u0438\u0439\u043d \u0431\u043e\u043b\u0437\u043e\u043b\u0443\u0443\u0434\u0430\u0430 \u0445\u0430\u043d\u0433\u0430\u0445 \u0451\u0441\u0442\u043e\u0439.", "validators", "mn");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0437\u04e9\u0432 International Securities Identification Number (ISIN) \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("This form should not contain extra fields.", "\u0424\u043e\u0440\u043c \u043d\u044d\u043c\u044d\u043b\u0442 \u0442\u0430\u043b\u0431\u0430\u0440 \u0431\u0430\u0433\u0442\u0430\u0430\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0433\u04af\u0439.", "validators", "mn");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Upload \u0445\u0438\u0439\u0441\u044d\u043d \u0444\u0430\u0439\u043b \u0445\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u0442\u043e\u043c \u0431\u0430\u0439\u043d\u0430. \u0411\u0430\u0433\u0430 \u0445\u044d\u043c\u0436\u044d\u044d\u0442\u044d\u0439 \u0444\u0430\u0439\u043b \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF token \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430. \u0424\u043e\u0440\u043c\u043e\u043e \u0434\u0430\u0445\u0438\u043d \u0438\u043b\u0433\u044d\u044d\u043d\u044d \u04af\u04af.", "validators", "mn");
t.add("This value is not a valid HTML5 color.", "\u042d\u043d\u044d \u0443\u0442\u0433\u0430 \u0437\u04e9\u0432 HTML5 \u04e9\u043d\u0433\u04e9 \u0431\u0438\u0448 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("Please enter a valid birthdate.", "\u0417\u04e9\u0432 \u0442\u04e9\u0440\u0441\u04e9\u043d \u043e\u043d \u0441\u0430\u0440 \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("The selected choice is invalid.", "\u0421\u043e\u043d\u0433\u043e\u0441\u043e\u043d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("The collection is invalid.", "\u0426\u0443\u0433\u043b\u0443\u0443\u043b\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("Please select a valid color.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u04e9\u043d\u0433\u04e9 \u0441\u043e\u043d\u0433\u043e\u043d\u043e \u0443\u0443.", "validators", "mn");
t.add("Please select a valid country.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0443\u043b\u0441 \u0441\u043e\u043d\u0433\u043e\u043d\u043e \u0443\u0443.", "validators", "mn");
t.add("Please select a valid currency.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u043c\u04e9\u043d\u0433\u04e9\u043d \u0442\u044d\u043c\u0434\u044d\u0433\u0442 \u0441\u043e\u043d\u0433\u043e\u043d\u043e \u0443\u0443.", "validators", "mn");
t.add("Please choose a valid date interval.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0446\u0430\u0433\u044b\u043d \u0437\u0430\u0439 \u0441\u043e\u043d\u0433\u043e\u043d\u043e \u0443\u0443.", "validators", "mn");
t.add("Please enter a valid date and time.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u043e\u043d \u0446\u0430\u0433 \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("Please enter a valid date.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u043e\u043d \u0446\u0430\u0433 \u04e9\u0434\u04e9\u0440 \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("Please select a valid file.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0444\u0430\u0439\u043b \u0441\u043e\u043d\u0433\u043e\u043d\u043e \u0443\u0443.", "validators", "mn");
t.add("The hidden field is invalid.", "\u041d\u0443\u0443\u0446 \u0442\u0430\u043b\u0431\u0430\u0440\u044b\u043d \u0443\u0442\u0433\u0430 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("Please enter an integer.", "\u0411\u04af\u0445\u044d\u043b \u0442\u043e\u043e \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("Please select a valid language.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0445\u044d\u043b \u0441\u043e\u043d\u0433\u043e\u043d\u043e \u0443\u0443.", "validators", "mn");
t.add("Please select a valid locale.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0431\u04af\u0441 \u0441\u043e\u043d\u0433\u043e\u043d\u043e \u0443\u0443.", "validators", "mn");
t.add("Please enter a valid money amount.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u043c\u04e9\u043d\u0433\u04e9\u043d\u0438\u0439 \u0445\u044d\u043c\u0436\u044d\u044d \u0441\u043e\u043d\u0433\u043e\u043d\u043e \u0443\u0443.", "validators", "mn");
t.add("Please enter a number.", "\u0422\u043e\u043e \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("The password is invalid.", "\u041d\u0443\u0443\u0446 \u04af\u0433 \u0431\u0443\u0440\u0443\u0443 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("Please enter a percentage value.", "\u0425\u0443\u0432\u044c \u0443\u0442\u0433\u0430 \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("The values do not match.", "\u0423\u0442\u0433\u0430 \u0445\u043e\u043e\u0440\u043e\u043d\u0434\u043e\u043e \u0442\u0430\u0430\u0440\u0430\u0445\u0433\u04af\u0439 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("Please enter a valid time.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0446\u0430\u0433 \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("Please select a valid timezone.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0446\u0430\u0433\u044b\u043d \u0431\u04af\u0441 \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("Please enter a valid URL.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 URL \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("Please enter a valid search term.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0445\u0430\u0439\u0445 \u0443\u0442\u0433\u0430 \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("Please provide a valid phone number.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0443\u0442\u0430\u0441\u043d\u044b \u0434\u0443\u0433\u0430\u0430\u0440 \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("The checkbox has an invalid value.", "\u0421\u043e\u043d\u0433\u043e\u0445 \u0445\u0430\u0439\u0440\u0446\u0430\u0433 \u0431\u0443\u0440\u0443\u0443 \u0443\u0442\u0433\u0430\u0442\u0430\u0439 \u0431\u0430\u0439\u043d\u0430.", "validators", "mn");
t.add("Please enter a valid email address.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0438-\u043c\u044d\u0439\u043b \u0445\u0430\u044f\u0433 \u043e\u0440\u0443\u0443\u043b\u043d\u0430 \u0443\u0443.", "validators", "mn");
t.add("Please select a valid option.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0441\u043e\u043d\u0433\u043e\u043b\u0442 \u0441\u043e\u043d\u0433\u043e\u043d\u043e \u0443\u0443.", "validators", "mn");
t.add("Please select a valid range.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0445\u044f\u0437\u0433\u0430\u0430\u0440\u044b\u043d \u0443\u0442\u0433\u0430 \u0441\u043e\u043d\u0433\u043e\u043d\u043e \u0443\u0443.", "validators", "mn");
t.add("Please enter a valid week.", "\u04ae\u043d\u044d\u043d \u0437\u04e9\u0432 \u0434\u043e\u043b\u043e\u043e \u0445\u043e\u043d\u043e\u0433 \u0441\u043e\u043d\u0433\u043e\u043d\u043e \u0443\u0443.", "validators", "mn");
})(Translator);
