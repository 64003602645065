(function (t) {
// fr
t.add("user_added", "L'utilisateur a bien \u00e9t\u00e9 cr\u00e9\u00e9", "flash_messages", "fr");
t.add("user_edited", "L'utilisateur a bien \u00e9t\u00e9 modifi\u00e9", "flash_messages", "fr");
t.add("category_added", "La cat\u00e9gorie a bien \u00e9t\u00e9 ajout\u00e9e", "flash_messages", "fr");
t.add("category_edited", "La cat\u00e9gorie a bien \u00e9t\u00e9 modifi\u00e9e", "flash_messages", "fr");
t.add("course_category_added", "La cat\u00e9gorie de formation a bien \u00e9t\u00e9 ajout\u00e9e", "flash_messages", "fr");
t.add("course_category_edited", "La cat\u00e9gorie de formation a bien \u00e9t\u00e9 modifi\u00e9e", "flash_messages", "fr");
t.add("exercise_edited", "L'exercice a bien \u00e9t\u00e9 modifi\u00e9", "flash_messages", "fr");
t.add("planning_added", "La planification a bien \u00e9t\u00e9 cr\u00e9\u00e9e", "flash_messages", "fr");
t.add("planning_edited", "La planification a bien \u00e9t\u00e9 modifi\u00e9e", "flash_messages", "fr");
t.add("planning_session_added", "La session a bien \u00e9t\u00e9 ajout\u00e9e", "flash_messages", "fr");
t.add("planning_session_edited", "La session a bien \u00e9t\u00e9 modifi\u00e9e", "flash_messages", "fr");
t.add("planning_session_removed", "La session a bien \u00e9t\u00e9 supprim\u00e9e", "flash_messages", "fr");
t.add("planning_removed", "La planification a bien \u00e9t\u00e9 supprim\u00e9e", "flash_messages", "fr");
t.add("customer_group_edited", "Le groupe a bien \u00e9t\u00e9 modifi\u00e9", "flash_messages", "fr");
t.add("customer_group_added", "Le groupe a bien \u00e9t\u00e9 ajout\u00e9", "flash_messages", "fr");
t.add("planning_exercise_edited", "L'exercice a bien \u00e9t\u00e9 modifi\u00e9 pour cette session", "flash_messages", "fr");
t.add("planning_exercise_removed", "L'exercice a bien \u00e9t\u00e9 supprim\u00e9 de cette session", "flash_messages", "fr");
t.add("planning_exercise_added", "L'exercice a bien \u00e9t\u00e9 ajout\u00e9 de cette session", "flash_messages", "fr");
t.add("post_added", "L'article a bien \u00e9t\u00e9 ajout\u00e9", "flash_messages", "fr");
t.add("post_edited", "L'article a bien \u00e9t\u00e9 modifi\u00e9", "flash_messages", "fr");
t.add("post_removed", "L'article a bien \u00e9t\u00e9 supprim\u00e9", "flash_messages", "fr");
t.add("course_added", "La formation a bien \u00e9t\u00e9 ajout\u00e9e", "flash_messages", "fr");
t.add("course_edited", "La formation a bien \u00e9t\u00e9 modifi\u00e9e", "flash_messages", "fr");
t.add("course_removed", "La formation a bien \u00e9t\u00e9 supprim\u00e9e", "flash_messages", "fr");
t.add("therapist_removed", "Le th\u00e9rapeute a bien \u00e9t\u00e9 supprim\u00e9", "flash_messages", "fr");
t.add("therapist_added", "Le th\u00e9rapeute a bien \u00e9t\u00e9 ajout\u00e9", "flash_messages", "fr");
t.add("user.password_successfully_changed", "Votre mot de passe a bien \u00e9t\u00e9 chang\u00e9 avec succ\u00e8s", "flash_messages", "fr");
t.add("planning_session_duplicated", "La session a bien \u00e9t\u00e9 ajout\u00e9e", "flash_messages", "fr");
t.add("planning_pdf_sent", "Email envoy\u00e9", "flash_messages", "fr");
t.add("test_pdf_sent", "Email envoy\u00e9", "flash_messages", "fr");
t.add("office_added", "Le cabinet a bien \u00e9t\u00e9 ajout\u00e9", "flash_messages", "fr");
t.add("office_therapist_added", "Le th\u00e9rapeute a bien \u00e9t\u00e9 ajout\u00e9", "flash_messages", "fr");
t.add("customer_edited", "Le client a \u00e9t\u00e9 modifi\u00e9 avec succ\u00e8s", "flash_messages", "fr");
t.add("facturation.success", "La vente de cr\u00e9dit a bien \u00e9t\u00e9 effectu\u00e9", "flash_messages", "fr");
t.add("paiement.success", "L'achat de cr\u00e9dit a bien \u00e9t\u00e9 effectu\u00e9", "flash_messages", "fr");
t.add("credit.add.success", "L'ajout de cr\u00e9dits a bien \u00e9t\u00e9 effectu\u00e9", "flash_messages", "fr");
t.add("credit.substract.success", "La suppression de cr\u00e9dits a bien \u00e9t\u00e9 effectu\u00e9", "flash_messages", "fr");
t.add("credit.substract.failed", "Le nombre de cr\u00e9dits \u00e0 supprimer d\u00e9passe le nombre de cr\u00e9dit que poss\u00e8de l'utilisateur", "flash_messages", "fr");
})(Translator);
