(function (t) {
// he
t.add("An authentication exception occurred.", "\u05e9\u05d2\u05d9\u05d0\u05d4 \u05d1\u05d0\u05d9\u05de\u05d5\u05ea", "security", "he");
t.add("Authentication credentials could not be found.", "\u05e4\u05e8\u05d8\u05d9 \u05d6\u05d9\u05d4\u05d5\u05d9 \u05dc\u05d0 \u05e0\u05de\u05e6\u05d0\u05d5.", "security", "he");
t.add("Authentication request could not be processed due to a system problem.", "\u05dc\u05d0 \u05e0\u05d9\u05ea\u05df \u05d4\u05d9\u05d4 \u05dc\u05e2\u05d1\u05d3 \u05d0\u05ea \u05d1\u05e7\u05e9\u05ea \u05d0\u05d9\u05de\u05d5\u05ea \u05d1\u05d2\u05dc\u05dc \u05d1\u05e2\u05d9\u05d9\u05ea \u05de\u05e2\u05e8\u05db\u05ea.", "security", "he");
t.add("Invalid credentials.", "\u05e9\u05dd \u05de\u05e9\u05ea\u05de\u05e9 \u05d0\u05d5 \u05e1\u05d9\u05e1\u05de\u05d0 \u05e9\u05d2\u05d5\u05d9\u05d9\u05dd.", "security", "he");
t.add("Cookie has already been used by someone else.", "\u05e2\u05d5\u05d2\u05d9\u05d4 \u05db\u05d1\u05e8 \u05e9\u05d5\u05de\u05e9\u05d4.", "security", "he");
t.add("Not privileged to request the resource.", "\u05d0\u05d9\u05df \u05d4\u05e8\u05e9\u05d0\u05d4 \u05de\u05ea\u05d0\u05d9\u05de\u05d4.", "security", "he");
t.add("Invalid CSRF token.", "\u05d0\u05e1\u05d9\u05de\u05d5\u05df CSRF \u05dc\u05d0 \u05d7\u05d5\u05e7\u05d9.", "security", "he");
t.add("No authentication provider found to support the authentication token.", "\u05dc\u05d0 \u05e0\u05de\u05e6\u05d0 \u05e1\u05e4\u05e7 \u05d0\u05d9\u05de\u05d5\u05ea \u05d4\u05de\u05ea\u05d0\u05d9\u05de\u05d4 \u05dc\u05d1\u05e7\u05e9\u05d4.", "security", "he");
t.add("No session available, it either timed out or cookies are not enabled.", "\u05d0\u05d9\u05df \u05e1\u05d9\u05d9\u05e9\u05df \u05d6\u05de\u05d9\u05df, \u05d0\u05d5 \u05e9\u05ea\u05dd \u05d4\u05d6\u05de\u05df \u05d4\u05e7\u05e6\u05d5\u05d1 \u05d0\u05d5 \u05d4\u05e2\u05d5\u05d2\u05d9\u05d5\u05ea \u05d0\u05d9\u05e0\u05df \u05de\u05d5\u05e4\u05e2\u05dc\u05d5\u05ea.", "security", "he");
t.add("No token could be found.", "\u05d4\u05d8\u05d5\u05e7\u05df \u05dc\u05d0 \u05e0\u05de\u05e6\u05d0.", "security", "he");
t.add("Username could not be found.", "\u05e9\u05dd \u05de\u05e9\u05ea\u05de\u05e9 \u05dc\u05d0 \u05e0\u05de\u05e6\u05d0.", "security", "he");
t.add("Account has expired.", "\u05d4\u05d7\u05e9\u05d1\u05d5\u05df \u05e4\u05d2 \u05ea\u05d5\u05e7\u05e3.", "security", "he");
t.add("Credentials have expired.", "\u05e4\u05e8\u05d8\u05d9 \u05d4\u05ea\u05d7\u05d1\u05e8\u05d5\u05ea \u05e4\u05e7\u05e2\u05d5 \u05ea\u05d5\u05e7\u05e3.", "security", "he");
t.add("Account is disabled.", "\u05d4\u05d7\u05e9\u05d1\u05d5\u05df \u05de\u05d1\u05d5\u05d8\u05dc.", "security", "he");
t.add("Account is locked.", "\u05d4\u05d7\u05e9\u05d1\u05d5\u05df \u05e0\u05e2\u05d5\u05dc.", "security", "he");
t.add("Too many failed login attempts, please try again later.", "\u05d9\u05d5\u05ea\u05e8 \u05de\u05d3\u05d9 \u05e0\u05d9\u05e1\u05d9\u05d5\u05e0\u05d5\u05ea \u05db\u05e0\u05d9\u05e1\u05d4 \u05db\u05d5\u05e9\u05dc\u05d9\u05dd, \u05d0\u05e0\u05d0 \u05e0\u05e1\u05d4 \u05e9\u05d5\u05d1 \u05de\u05d0\u05d5\u05d7\u05e8 \u05d9\u05d5\u05ea\u05e8.", "security", "he");
t.add("Invalid or expired login link.", "\u05e7\u05d9\u05e9\u05d5\u05e8 \u05db\u05e0\u05d9\u05e1\u05d4 \u05dc\u05d0 \u05d7\u05d5\u05e7\u05d9 \u05d0\u05d5 \u05e9\u05e4\u05d2 \u05ea\u05d5\u05e7\u05e4\u05d5.", "security", "he");
t.add("Too many failed login attempts, please try again in %minutes% minute.", "\u05d9\u05d5\u05ea\u05e8 \u05de\u05d3\u05d9 \u05e0\u05d9\u05e1\u05d9\u05d5\u05e0\u05d5\u05ea \u05db\u05e0\u05d9\u05e1\u05d4 \u05db\u05d5\u05e9\u05dc\u05d9\u05dd, \u05d0\u05e0\u05d0 \u05e0\u05e1\u05d4 \u05e9\u05d5\u05d1 \u05d1\u05d5\u05d3 %minutes% \u05d3\u05e7\u05d4.", "security", "he");
t.add("Too many failed login attempts, please try again in %minutes% minutes.", "\u05d9\u05d5\u05ea\u05e8 \u05de\u05d3\u05d9 \u05e0\u05d9\u05e1\u05d9\u05d5\u05e0\u05d5\u05ea \u05db\u05e0\u05d9\u05e1\u05d4 \u05db\u05d5\u05e9\u05dc\u05d9\u05dd, \u05d0\u05e0\u05d0 \u05e0\u05e1\u05d4 \u05e9\u05d5\u05d1 \u05d1\u05d5\u05d3 %minutes% \u05d3\u05e7\u05d5\u05ea.", "security", "he");
})(Translator);
