(function (t) {
// th
t.add("This value should be false.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19 false", "validators", "th");
t.add("This value should be true.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19 true", "validators", "th");
t.add("This value should be of type {{ type }}.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19 {{ type }}", "validators", "th");
t.add("This value should be blank.", "\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e04\u0e48\u0e32\u0e27\u0e48\u0e32\u0e07", "validators", "th");
t.add("The value you selected is not a valid choice.", "\u0e04\u0e38\u0e13\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e04\u0e48\u0e32\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e1a\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01", "validators", "th");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u0e04\u0e38\u0e13\u0e15\u0e49\u0e2d\u0e07\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e19\u0e49\u0e2d\u0e22 {{ limit }} \u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01", "validators", "th");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u0e04\u0e38\u0e13\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e17\u0e35\u0e48\u0e2a\u0e38\u0e14 {{ limit }} \u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01", "validators", "th");
t.add("One or more of the given values is invalid.", "\u0e21\u0e35\u0e1a\u0e32\u0e07\u0e04\u0e48\u0e32\u0e17\u0e35\u0e48\u0e2a\u0e48\u0e07\u0e21\u0e32\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This field was not expected.", "\u0e44\u0e21\u0e48\u0e04\u0e27\u0e23\u0e21\u0e35\u0e1f\u0e34\u0e25\u0e14\u0e4c\u0e19\u0e35\u0e49", "validators", "th");
t.add("This field is missing.", "\u0e1f\u0e34\u0e25\u0e14\u0e4c\u0e19\u0e35\u0e49\u0e2b\u0e32\u0e22\u0e44\u0e1b", "validators", "th");
t.add("This value is not a valid date.", "\u0e04\u0e48\u0e32\u0e02\u0e2d\u0e07\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid datetime.", "\u0e04\u0e48\u0e32\u0e02\u0e2d\u0e07\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e41\u0e25\u0e30\u0e40\u0e27\u0e25\u0e32\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid email address.", "\u0e04\u0e48\u0e32\u0e02\u0e2d\u0e07\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e4c\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("The file could not be found.", "\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e44\u0e1f\u0e25\u0e4c", "validators", "th");
t.add("The file is not readable.", "\u0e44\u0e1f\u0e25\u0e4c\u0e44\u0e21\u0e48\u0e2d\u0e22\u0e39\u0e48\u0e43\u0e19\u0e2a\u0e16\u0e32\u0e19\u0e30\u0e17\u0e35\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2d\u0e48\u0e32\u0e19\u0e44\u0e14\u0e49", "validators", "th");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0e44\u0e1f\u0e25\u0e4c\u0e43\u0e2b\u0e0d\u0e48\u0e40\u0e01\u0e34\u0e19 ({{ size }} {{ suffix }}) \u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e43\u0e2b\u0e49\u0e43\u0e2b\u0e0d\u0e48\u0e17\u0e35\u0e48\u0e2a\u0e38\u0e14\u0e44\u0e14\u0e49\u0e44\u0e21\u0e48\u0e40\u0e01\u0e34\u0e19 {{ limit }} {{ suffix }}", "validators", "th");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Mime type \u0e02\u0e2d\u0e07\u0e44\u0e1f\u0e25\u0e4c\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07 ({{ type }}) Mime types \u0e17\u0e35\u0e48\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e04\u0e37\u0e2d {{ types }}", "validators", "th");
t.add("This value should be {{ limit }} or less.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e08\u0e30\u0e40\u0e1b\u0e47\u0e19 {{ limit }} \u0e2b\u0e23\u0e37\u0e2d\u0e19\u0e49\u0e2d\u0e22\u0e01\u0e27\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19", "validators", "th");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e22\u0e32\u0e27\u0e40\u0e01\u0e34\u0e19\u0e44\u0e1b \u0e04\u0e27\u0e23\u0e08\u0e30\u0e21\u0e35\u0e41\u0e04\u0e48 {{ limit }} \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23\u0e2b\u0e23\u0e37\u0e2d\u0e19\u0e49\u0e2d\u0e22\u0e01\u0e27\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19", "validators", "th");
t.add("This value should be {{ limit }} or more.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e08\u0e30\u0e21\u0e35 {{ limit }} \u0e2b\u0e23\u0e37\u0e2d\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19", "validators", "th");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e2a\u0e31\u0e49\u0e19\u0e40\u0e01\u0e34\u0e19\u0e44\u0e1b \u0e04\u0e27\u0e23\u0e08\u0e30\u0e21\u0e35 {{ limit }} \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23\u0e2b\u0e23\u0e37\u0e2d\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19", "validators", "th");
t.add("This value should not be blank.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e04\u0e48\u0e32\u0e27\u0e48\u0e32\u0e07", "validators", "th");
t.add("This value should not be null.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e04\u0e48\u0e32 null", "validators", "th");
t.add("This value should be null.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e04\u0e48\u0e32 null", "validators", "th");
t.add("This value is not valid.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid time.", "\u0e04\u0e48\u0e32\u0e02\u0e2d\u0e07\u0e40\u0e27\u0e25\u0e32\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid URL.", "\u0e04\u0e48\u0e32\u0e02\u0e2d\u0e07 URL \u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("The two values should be equal.", "\u0e04\u0e48\u0e32\u0e17\u0e31\u0e49\u0e07\u0e2a\u0e2d\u0e07\u0e04\u0e48\u0e32\u0e04\u0e27\u0e23\u0e08\u0e30\u0e40\u0e2b\u0e21\u0e37\u0e2d\u0e19\u0e01\u0e31\u0e19", "validators", "th");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0e02\u0e19\u0e32\u0e14\u0e44\u0e1f\u0e25\u0e4c\u0e43\u0e2b\u0e0d\u0e48\u0e40\u0e01\u0e34\u0e19\u0e44\u0e1b \u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e43\u0e2b\u0e49\u0e44\u0e1f\u0e25\u0e4c\u0e02\u0e19\u0e32\u0e14\u0e43\u0e2b\u0e0d\u0e48\u0e44\u0e14\u0e49\u0e44\u0e21\u0e48\u0e40\u0e01\u0e34\u0e19 {{ limit }} {{ suffix }}", "validators", "th");
t.add("The file is too large.", "\u0e02\u0e19\u0e32\u0e14\u0e44\u0e1f\u0e25\u0e4c\u0e43\u0e2b\u0e0d\u0e48\u0e40\u0e01\u0e34\u0e19\u0e44\u0e1b", "validators", "th");
t.add("The file could not be uploaded.", "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e44\u0e1f\u0e25\u0e4c\u0e44\u0e14\u0e49", "validators", "th");
t.add("This value should be a valid number.", "\u0e04\u0e48\u0e32\u0e02\u0e2d\u0e07\u0e15\u0e31\u0e27\u0e40\u0e25\u0e02\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This file is not a valid image.", "\u0e44\u0e1f\u0e25\u0e4c\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48\u0e44\u0e1f\u0e25\u0e4c\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e", "validators", "th");
t.add("This is not a valid IP address.", "\u0e04\u0e48\u0e32\u0e02\u0e2d\u0e07 IP \u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid language.", "\u0e04\u0e48\u0e32\u0e02\u0e2d\u0e07\u0e20\u0e32\u0e29\u0e32\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid locale.", "\u0e04\u0e48\u0e32\u0e02\u0e2d\u0e07\u0e20\u0e39\u0e21\u0e34\u0e20\u0e32\u0e04 (Locale) \u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid country.", "\u0e04\u0e48\u0e32\u0e02\u0e2d\u0e07\u0e1b\u0e23\u0e30\u0e40\u0e17\u0e28\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is already used.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e16\u0e39\u0e01\u0e43\u0e0a\u0e49\u0e07\u0e32\u0e19\u0e44\u0e1b\u0e41\u0e25\u0e49\u0e27", "validators", "th");
t.add("The size of the image could not be detected.", "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e15\u0e23\u0e27\u0e08\u0e2a\u0e2d\u0e1a\u0e02\u0e19\u0e32\u0e14\u0e44\u0e1f\u0e25\u0e4c\u0e02\u0e2d\u0e07\u0e20\u0e32\u0e1e\u0e44\u0e14\u0e49", "validators", "th");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u0e04\u0e27\u0e32\u0e21\u0e01\u0e27\u0e49\u0e32\u0e07\u0e02\u0e2d\u0e07\u0e20\u0e32\u0e1e\u0e40\u0e01\u0e34\u0e19\u0e02\u0e19\u0e32\u0e14 ({{ width }}px) \u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e43\u0e2b\u0e49\u0e01\u0e27\u0e49\u0e32\u0e07\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e17\u0e35\u0e48\u0e2a\u0e38\u0e14 {{ max_width }}px", "validators", "th");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u0e04\u0e27\u0e32\u0e21\u0e01\u0e27\u0e49\u0e32\u0e07\u0e02\u0e2d\u0e07\u0e20\u0e32\u0e1e\u0e15\u0e48\u0e33\u0e40\u0e01\u0e34\u0e19\u0e44\u0e1b ({{ width }}px) \u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e43\u0e2b\u0e49\u0e04\u0e27\u0e32\u0e21\u0e01\u0e27\u0e49\u0e32\u0e07\u0e44\u0e21\u0e48\u0e15\u0e48\u0e33\u0e01\u0e27\u0e48\u0e32 {{ min_width }}px", "validators", "th");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u0e04\u0e27\u0e32\u0e21\u0e2a\u0e39\u0e07\u0e02\u0e2d\u0e07\u0e20\u0e32\u0e1e\u0e40\u0e01\u0e34\u0e19\u0e02\u0e19\u0e32\u0e14 ({{ height }}px) \u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e43\u0e2b\u0e49\u0e2a\u0e39\u0e07\u0e44\u0e14\u0e49\u0e21\u0e32\u0e01\u0e17\u0e35\u0e48\u0e2a\u0e38\u0e14 {{ max_height }}px", "validators", "th");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u0e04\u0e27\u0e32\u0e21\u0e2a\u0e39\u0e07\u0e02\u0e2d\u0e07\u0e20\u0e32\u0e1e\u0e40\u0e25\u0e47\u0e01\u0e40\u0e01\u0e34\u0e19\u0e44\u0e1b ({{ height }}px) \u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e43\u0e2b\u0e49\u0e04\u0e27\u0e32\u0e21\u0e2a\u0e39\u0e07\u0e44\u0e21\u0e48\u0e04\u0e27\u0e23\u0e15\u0e48\u0e33\u0e01\u0e27\u0e48\u0e32 {{ min_height }}px", "validators", "th");
t.add("This value should be the user's current password.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e08\u0e30\u0e40\u0e1b\u0e47\u0e19\u0e23\u0e2b\u0e31\u0e2a\u0e1c\u0e48\u0e32\u0e19\u0e1b\u0e31\u0e08\u0e08\u0e38\u0e1a\u0e31\u0e19\u0e02\u0e2d\u0e07\u0e1c\u0e39\u0e49\u0e43\u0e0a\u0e49", "validators", "th");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e08\u0e30\u0e21\u0e35\u0e04\u0e27\u0e32\u0e21\u0e22\u0e32\u0e27 {{ limit }} \u0e15\u0e31\u0e27\u0e2d\u0e31\u0e01\u0e29\u0e23", "validators", "th");
t.add("The file was only partially uploaded.", "\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e44\u0e1f\u0e25\u0e4c\u0e44\u0e14\u0e49\u0e40\u0e1e\u0e35\u0e22\u0e07\u0e1a\u0e32\u0e07\u0e2a\u0e48\u0e27\u0e19\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19", "validators", "th");
t.add("No file was uploaded.", "\u0e44\u0e21\u0e48\u0e21\u0e35\u0e44\u0e1f\u0e25\u0e4c\u0e43\u0e14\u0e16\u0e39\u0e01\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14", "validators", "th");
t.add("No temporary folder was configured in php.ini.", "\u0e44\u0e21\u0e48\u0e1e\u0e1a\u0e01\u0e32\u0e23\u0e15\u0e31\u0e49\u0e07\u0e04\u0e48\u0e32\u0e42\u0e1f\u0e25\u0e40\u0e14\u0e2d\u0e23\u0e4c\u0e0a\u0e31\u0e48\u0e27\u0e04\u0e23\u0e32\u0e27 (temporary folder) \u0e43\u0e19 php.ini", "validators", "th");
t.add("Cannot write temporary file to disk.", "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e40\u0e02\u0e35\u0e22\u0e19\u0e44\u0e1f\u0e25\u0e4c\u0e0a\u0e31\u0e48\u0e27\u0e04\u0e23\u0e32\u0e27 (temporary file) \u0e25\u0e07\u0e14\u0e34\u0e2a\u0e01\u0e4c\u0e44\u0e14\u0e49", "validators", "th");
t.add("A PHP extension caused the upload to fail.", "PHP extension \u0e17\u0e33\u0e43\u0e2b\u0e49\u0e01\u0e32\u0e23\u0e2d\u0e31\u0e1b\u0e42\u0e2b\u0e25\u0e14\u0e21\u0e35\u0e1b\u0e31\u0e0d\u0e2b\u0e32", "validators", "th");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u0e04\u0e2d\u0e40\u0e25\u0e47\u0e01\u0e0a\u0e31\u0e48\u0e19\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e08\u0e30\u0e1b\u0e23\u0e30\u0e01\u0e2d\u0e1a\u0e44\u0e1b\u0e14\u0e49\u0e27\u0e22\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e19\u0e49\u0e2d\u0e22 {{ limit }} \u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01", "validators", "th");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u0e04\u0e2d\u0e40\u0e25\u0e47\u0e01\u0e0a\u0e31\u0e48\u0e19\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e04\u0e27\u0e23\u0e21\u0e35\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e40\u0e01\u0e34\u0e19 {{ limit }}", "validators", "th");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u0e04\u0e2d\u0e40\u0e25\u0e47\u0e01\u0e0a\u0e31\u0e48\u0e19\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e08\u0e30\u0e21\u0e35 {{ limit }} \u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19", "validators", "th");
t.add("Invalid card number.", "\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e25\u0e02\u0e1a\u0e31\u0e15\u0e23\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Unsupported card type or invalid card number.", "\u0e44\u0e21\u0e48\u0e23\u0e39\u0e49\u0e08\u0e31\u0e01\u0e1b\u0e23\u0e30\u0e40\u0e20\u0e17\u0e02\u0e2d\u0e07\u0e1a\u0e31\u0e15\u0e23 \u0e2b\u0e23\u0e37\u0e2d\u0e2b\u0e21\u0e32\u0e22\u0e40\u0e25\u0e02\u0e1a\u0e31\u0e15\u0e23\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48 International Bank Account Number (IBAN) \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid ISBN-10.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48 ISBN-10 \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid ISBN-13.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48 ISBN-13 \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48 ISBN-10 \u0e2b\u0e23\u0e37\u0e2d ISBN-13 \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid ISSN.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48 ISSN \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid currency.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value should be equal to {{ compared_value }}.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e15\u0e23\u0e07\u0e01\u0e31\u0e1a {{ compared_value }}", "validators", "th");
t.add("This value should be greater than {{ compared_value }}.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e08\u0e30\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32 {{ compared_value }}", "validators", "th");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e08\u0e30\u0e21\u0e32\u0e01\u0e01\u0e27\u0e48\u0e32\u0e2b\u0e23\u0e37\u0e2d\u0e15\u0e23\u0e07\u0e01\u0e31\u0e1a {{ compared_value }}", "validators", "th");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e08\u0e30\u0e40\u0e2b\u0e21\u0e37\u0e2d\u0e19\u0e01\u0e31\u0e19\u0e01\u0e31\u0e1a {{ compared_value_type }} {{ compared_value }}", "validators", "th");
t.add("This value should be less than {{ compared_value }}.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e08\u0e30\u0e19\u0e49\u0e2d\u0e22\u0e01\u0e27\u0e48\u0e32 {{ compared_value }}", "validators", "th");
t.add("This value should be less than or equal to {{ compared_value }}.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e08\u0e30\u0e19\u0e49\u0e2d\u0e22\u0e01\u0e27\u0e48\u0e32\u0e2b\u0e23\u0e37\u0e2d\u0e40\u0e17\u0e48\u0e32\u0e01\u0e31\u0e1a {{ compared_value }}", "validators", "th");
t.add("This value should not be equal to {{ compared_value }}.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e04\u0e27\u0e23\u0e40\u0e17\u0e48\u0e32\u0e01\u0e31\u0e19\u0e01\u0e31\u0e1a {{ compared_value }}", "validators", "th");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e04\u0e27\u0e23\u0e40\u0e2b\u0e21\u0e37\u0e2d\u0e19\u0e01\u0e31\u0e19\u0e01\u0e31\u0e1a {{ compared_value_type }} {{ compared_value }}", "validators", "th");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u0e2a\u0e31\u0e14\u0e2a\u0e48\u0e27\u0e19\u0e02\u0e2d\u0e07\u0e20\u0e32\u0e1e\u0e43\u0e2b\u0e0d\u0e48\u0e40\u0e01\u0e34\u0e19 ({{ ratio }}) \u0e2a\u0e31\u0e14\u0e2a\u0e48\u0e27\u0e19\u0e43\u0e2b\u0e0d\u0e48\u0e17\u0e35\u0e48\u0e2a\u0e38\u0e14\u0e17\u0e35\u0e48\u0e43\u0e0a\u0e49\u0e44\u0e14\u0e49\u0e04\u0e37\u0e2d {{ max_ratio }}", "validators", "th");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u0e2a\u0e31\u0e14\u0e2a\u0e48\u0e27\u0e19\u0e02\u0e2d\u0e07\u0e20\u0e32\u0e1e\u0e40\u0e25\u0e47\u0e01\u0e40\u0e01\u0e34\u0e19 ({{ ratio }}) \u0e2a\u0e31\u0e14\u0e2a\u0e48\u0e27\u0e19\u0e40\u0e25\u0e47\u0e01\u0e17\u0e35\u0e48\u0e2a\u0e38\u0e14\u0e17\u0e35\u0e48\u0e43\u0e0a\u0e49\u0e44\u0e14\u0e49\u0e04\u0e37\u0e2d {{ min_ratio }}", "validators", "th");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u0e23\u0e39\u0e1b\u0e20\u0e32\u0e1e\u0e40\u0e1b\u0e47\u0e19\u0e08\u0e38\u0e15\u0e23\u0e31\u0e2a ({{ width }}x{{ height }}px) \u0e44\u0e21\u0e48\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e20\u0e32\u0e1e\u0e17\u0e35\u0e48\u0e40\u0e1b\u0e47\u0e19\u0e2a\u0e35\u0e48\u0e40\u0e2b\u0e25\u0e35\u0e48\u0e22\u0e21\u0e08\u0e15\u0e38\u0e23\u0e31\u0e2a", "validators", "th");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u0e20\u0e32\u0e1e\u0e19\u0e35\u0e49\u0e40\u0e1b\u0e47\u0e19\u0e41\u0e19\u0e27\u0e19\u0e2d\u0e19 ({{ width }}x{{ height }}px) \u0e44\u0e21\u0e48\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e20\u0e32\u0e1e\u0e17\u0e35\u0e48\u0e40\u0e1b\u0e47\u0e19\u0e41\u0e19\u0e27\u0e19\u0e2d\u0e19", "validators", "th");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u0e20\u0e32\u0e1e\u0e19\u0e35\u0e49\u0e40\u0e1b\u0e47\u0e19\u0e41\u0e19\u0e27\u0e15\u0e31\u0e49\u0e07 ({{ width }}x{{ height }}px) \u0e44\u0e21\u0e48\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e20\u0e32\u0e1e\u0e17\u0e35\u0e48\u0e40\u0e1b\u0e47\u0e19\u0e41\u0e19\u0e27\u0e15\u0e31\u0e49\u0e07", "validators", "th");
t.add("An empty file is not allowed.", "\u0e44\u0e21\u0e48\u0e2d\u0e19\u0e38\u0e0d\u0e32\u0e15\u0e43\u0e2b\u0e49\u0e43\u0e0a\u0e49\u0e44\u0e1f\u0e25\u0e4c\u0e27\u0e48\u0e32\u0e07", "validators", "th");
t.add("The host could not be resolved.", "\u0e44\u0e21\u0e48\u0e2a\u0e32\u0e21\u0e32\u0e23\u0e16\u0e41\u0e01\u0e49\u0e44\u0e02\u0e0a\u0e37\u0e48\u0e2d\u0e42\u0e2e\u0e2a\u0e15\u0e4c", "validators", "th");
t.add("This value does not match the expected {{ charset }} charset.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e1a\u0e01\u0e32\u0e23\u0e40\u0e02\u0e49\u0e32\u0e23\u0e2b\u0e31\u0e2a {{ charset }}", "validators", "th");
t.add("This is not a valid Business Identifier Code (BIC).", "\u0e19\u0e35\u0e48\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e15\u0e32\u0e21\u0e23\u0e2b\u0e31\u0e2a\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e23\u0e30\u0e1a\u0e38\u0e18\u0e38\u0e23\u0e01\u0e34\u0e08\u0e19\u0e35\u0e49 (BIC)", "validators", "th");
t.add("Error", "\u0e40\u0e01\u0e34\u0e14\u0e02\u0e49\u0e2d\u0e1c\u0e34\u0e14\u0e1e\u0e25\u0e32\u0e14", "validators", "th");
t.add("This is not a valid UUID.", "\u0e19\u0e35\u0e48\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48 UUID \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value should be a multiple of {{ compared_value }}.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19 {{ compared_value }} \u0e2b\u0e25\u0e32\u0e22\u0e15\u0e31\u0e27", "validators", "th");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "\u0e23\u0e2b\u0e31\u0e2a\u0e2a\u0e33\u0e2b\u0e23\u0e31\u0e1a\u0e23\u0e30\u0e1a\u0e38\u0e18\u0e38\u0e23\u0e01\u0e34\u0e08\u0e19\u0e35\u0e49 (BIC) \u0e44\u0e21\u0e48\u0e40\u0e01\u0e35\u0e48\u0e22\u0e27\u0e02\u0e49\u0e2d\u0e07\u0e01\u0e31\u0e1a IBAN {{ iban }}", "validators", "th");
t.add("This value should be valid JSON.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e2d\u0e22\u0e39\u0e48\u0e43\u0e19\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a JSON \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This collection should contain only unique elements.", "\u0e04\u0e2d\u0e40\u0e25\u0e47\u0e01\u0e0a\u0e31\u0e48\u0e19\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e21\u0e35\u0e40\u0e09\u0e1e\u0e32\u0e30\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e0b\u0e49\u0e33\u0e01\u0e31\u0e19\u0e40\u0e17\u0e48\u0e32\u0e19\u0e31\u0e49\u0e19", "validators", "th");
t.add("This value should be positive.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e04\u0e48\u0e32\u0e1a\u0e27\u0e01", "validators", "th");
t.add("This value should be either positive or zero.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e04\u0e48\u0e32\u0e1a\u0e27\u0e01\u0e2b\u0e23\u0e37\u0e2d\u0e04\u0e48\u0e32\u0e28\u0e39\u0e19\u0e22\u0e4c", "validators", "th");
t.add("This value should be negative.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e04\u0e48\u0e32\u0e25\u0e1a", "validators", "th");
t.add("This value should be either negative or zero.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e04\u0e48\u0e32\u0e25\u0e1a\u0e2b\u0e23\u0e37\u0e2d\u0e04\u0e48\u0e32\u0e28\u0e39\u0e19\u0e22\u0e4c", "validators", "th");
t.add("This value is not a valid timezone.", "\u0e04\u0e48\u0e32\u0e40\u0e02\u0e15\u0e40\u0e27\u0e25\u0e32\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "\u0e23\u0e2b\u0e31\u0e2a\u0e1c\u0e48\u0e32\u0e19\u0e19\u0e35\u0e49\u0e44\u0e14\u0e49\u0e40\u0e04\u0e22\u0e23\u0e31\u0e48\u0e27\u0e44\u0e2b\u0e25\u0e2d\u0e2d\u0e01\u0e44\u0e1b\u0e42\u0e14\u0e22\u0e16\u0e39\u0e01\u0e01\u0e32\u0e23\u0e25\u0e30\u0e40\u0e21\u0e34\u0e14\u0e02\u0e49\u0e2d\u0e21\u0e39\u0e25 \u0e0b\u0e36\u0e48\u0e07\u0e44\u0e21\u0e48\u0e04\u0e27\u0e23\u0e19\u0e33\u0e01\u0e25\u0e31\u0e1a\u0e21\u0e32\u0e43\u0e0a\u0e49 \u0e01\u0e23\u0e38\u0e13\u0e32\u0e43\u0e0a\u0e49\u0e23\u0e2b\u0e31\u0e2a\u0e1c\u0e48\u0e32\u0e19\u0e2d\u0e37\u0e48\u0e19", "validators", "th");
t.add("This value should be between {{ min }} and {{ max }}.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e2d\u0e22\u0e39\u0e48\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07 {{ min }} \u0e16\u0e36\u0e07 {{ max }}", "validators", "th");
t.add("This value is not a valid hostname.", "\u0e04\u0e48\u0e32\u0e42\u0e2e\u0e2a\u0e15\u0e4c\u0e40\u0e19\u0e21\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "\u0e08\u0e33\u0e19\u0e27\u0e19\u0e02\u0e2d\u0e07\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e43\u0e19\u0e04\u0e2d\u0e40\u0e25\u0e47\u0e01\u0e0a\u0e31\u0e48\u0e19\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e1e\u0e2b\u0e38\u0e04\u0e39\u0e13\u0e02\u0e2d\u0e07 {{ compared_value }}", "validators", "th");
t.add("This value should satisfy at least one of the following constraints:", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e15\u0e32\u0e21\u0e02\u0e49\u0e2d\u0e08\u0e33\u0e01\u0e31\u0e14\u0e2d\u0e22\u0e48\u0e32\u0e07\u0e19\u0e49\u0e2d\u0e22\u0e2b\u0e19\u0e36\u0e48\u0e07\u0e02\u0e49\u0e2d\u0e08\u0e32\u0e01\u0e02\u0e49\u0e2d\u0e08\u0e33\u0e01\u0e31\u0e14\u0e40\u0e2b\u0e25\u0e48\u0e32\u0e19\u0e35\u0e49:", "validators", "th");
t.add("Each element of this collection should satisfy its own set of constraints.", "\u0e2a\u0e21\u0e32\u0e0a\u0e34\u0e01\u0e41\u0e15\u0e48\u0e25\u0e30\u0e15\u0e31\u0e27\u0e43\u0e19\u0e04\u0e2d\u0e40\u0e25\u0e47\u0e01\u0e0a\u0e31\u0e48\u0e19\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e44\u0e1b\u0e15\u0e32\u0e21\u0e02\u0e49\u0e2d\u0e08\u0e33\u0e01\u0e31\u0e14\u0e02\u0e2d\u0e07\u0e04\u0e2d\u0e40\u0e25\u0e47\u0e01\u0e0a\u0e31\u0e48\u0e19\u0e19\u0e31\u0e49\u0e19\u0e46", "validators", "th");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "\u0e04\u0e48\u0e32\u0e23\u0e2b\u0e31\u0e2a\u0e2b\u0e25\u0e31\u0e01\u0e17\u0e23\u0e31\u0e1e\u0e22\u0e4c\u0e2a\u0e32\u0e01\u0e25 (ISIN) \u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value should be a valid expression.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e04\u0e27\u0e23\u0e40\u0e1b\u0e47\u0e19\u0e19\u0e34\u0e1e\u0e08\u0e19\u0e4c\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid CSS color.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48\u0e2a\u0e35 CSS \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("This value is not a valid CIDR notation.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48\u0e23\u0e39\u0e1b\u0e41\u0e1a\u0e1a CIDR \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "\u0e04\u0e48\u0e32\u0e02\u0e2d\u0e07 netmask \u0e04\u0e27\u0e23\u0e21\u0e35\u0e04\u0e48\u0e32\u0e23\u0e30\u0e2b\u0e27\u0e48\u0e32\u0e07 {{ min }} \u0e16\u0e36\u0e07 {{ max }}", "validators", "th");
t.add("This form should not contain extra fields.", "\u0e1f\u0e2d\u0e23\u0e4c\u0e21\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e04\u0e27\u0e23\u0e21\u0e35 extra fields", "validators", "th");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "\u0e44\u0e1f\u0e25\u0e4c\u0e17\u0e35\u0e48\u0e2d\u0e31\u0e1e\u0e42\u0e2b\u0e25\u0e14\u0e21\u0e35\u0e02\u0e19\u0e32\u0e14\u0e43\u0e2b\u0e0d\u0e48\u0e40\u0e01\u0e34\u0e19\u0e44\u0e1b \u0e01\u0e23\u0e38\u0e13\u0e32\u0e25\u0e2d\u0e07\u0e2d\u0e31\u0e1e\u0e42\u0e2b\u0e25\u0e14\u0e43\u0e2b\u0e21\u0e48\u0e2d\u0e35\u0e01\u0e04\u0e23\u0e31\u0e49\u0e07\u0e14\u0e49\u0e27\u0e22\u0e44\u0e1f\u0e25\u0e4c\u0e17\u0e35\u0e48\u0e21\u0e35\u0e02\u0e19\u0e32\u0e14\u0e40\u0e25\u0e47\u0e01\u0e25\u0e07", "validators", "th");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF token \u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07 \u0e01\u0e23\u0e38\u0e13\u0e32\u0e25\u0e2d\u0e07\u0e2a\u0e48\u0e07\u0e41\u0e1a\u0e1a\u0e1f\u0e2d\u0e23\u0e4c\u0e21\u0e43\u0e2b\u0e21\u0e48", "validators", "th");
t.add("This value is not a valid HTML5 color.", "\u0e04\u0e48\u0e32\u0e19\u0e35\u0e49\u0e44\u0e21\u0e48\u0e43\u0e0a\u0e48\u0e04\u0e48\u0e32\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07\u0e02\u0e2d\u0e07\u0e04\u0e48\u0e32\u0e2a\u0e35 HTML5", "validators", "th");
t.add("Please enter a valid birthdate.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e27\u0e31\u0e19\u0e40\u0e14\u0e37\u0e2d\u0e19\u0e1b\u0e35\u0e40\u0e01\u0e34\u0e14\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("The selected choice is invalid.", "\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e40\u0e25\u0e34\u0e2d\u0e01\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("The collection is invalid.", "\u0e04\u0e2d\u0e40\u0e25\u0e47\u0e01\u0e0a\u0e31\u0e48\u0e19\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please select a valid color.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e04\u0e48\u0e32\u0e2a\u0e35\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please select a valid country.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e1b\u0e23\u0e30\u0e40\u0e17\u0e28\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please select a valid currency.", "\u0e01\u0e23\u0e38\u0e38\u0e13\u0e32\u0e40\u0e25\u0e34\u0e2d\u0e01\u0e04\u0e48\u0e32\u0e2a\u0e01\u0e38\u0e25\u0e40\u0e07\u0e34\u0e19\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please choose a valid date interval.", "\u0e01\u0e23\u0e38\u0e13\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e0a\u0e48\u0e27\u0e07\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please enter a valid date and time.", "\u0e01\u0e23\u0e38\u0e13\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e04\u0e48\u0e32\u0e40\u0e27\u0e25\u0e32\u0e41\u0e25\u0e30\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please enter a valid date.", "\u0e01\u0e23\u0e38\u0e13\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e04\u0e48\u0e32\u0e27\u0e31\u0e19\u0e17\u0e35\u0e48\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please select a valid file.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e44\u0e1f\u0e25\u0e4c\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("The hidden field is invalid.", "\u0e04\u0e48\u0e32 Hidden field \u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please enter an integer.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e15\u0e31\u0e27\u0e40\u0e25\u0e02\u0e08\u0e33\u0e19\u0e27\u0e19\u0e40\u0e15\u0e47\u0e21", "validators", "th");
t.add("Please select a valid language.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e20\u0e32\u0e29\u0e32\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please select a valid locale.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e17\u0e49\u0e2d\u0e07\u0e16\u0e34\u0e48\u0e19\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please enter a valid money amount.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e08\u0e33\u0e19\u0e27\u0e19\u0e40\u0e07\u0e34\u0e19\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please enter a number.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e15\u0e31\u0e27\u0e40\u0e25\u0e02", "validators", "th");
t.add("The password is invalid.", "\u0e23\u0e2b\u0e31\u0e2a\u0e1c\u0e48\u0e32\u0e19\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please enter a percentage value.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e04\u0e48\u0e32\u0e40\u0e1b\u0e2d\u0e23\u0e4c\u0e40\u0e0b\u0e47\u0e19\u0e15\u0e4c", "validators", "th");
t.add("The values do not match.", "\u0e04\u0e48\u0e32\u0e17\u0e31\u0e49\u0e07\u0e2a\u0e2d\u0e07\u0e44\u0e21\u0e48\u0e15\u0e23\u0e07\u0e01\u0e31\u0e19", "validators", "th");
t.add("Please enter a valid time.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e04\u0e48\u0e32\u0e40\u0e27\u0e25\u0e32\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please select a valid timezone.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e04\u0e48\u0e32\u0e40\u0e02\u0e15\u0e40\u0e27\u0e25\u0e32\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please enter a valid URL.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01 URL \u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please enter a valid search term.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e04\u0e33\u0e04\u0e49\u0e19\u0e2b\u0e32\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please provide a valid phone number.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e40\u0e1a\u0e2d\u0e23\u0e4c\u0e42\u0e17\u0e23\u0e28\u0e31\u0e1e\u0e17\u0e4c\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("The checkbox has an invalid value.", "Checkbox \u0e21\u0e35\u0e04\u0e48\u0e32\u0e17\u0e35\u0e48\u0e44\u0e21\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please enter a valid email address.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e2d\u0e22\u0e39\u0e48\u0e2d\u0e35\u0e40\u0e21\u0e25\u0e4c\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please select a valid option.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e15\u0e31\u0e27\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please select a valid range.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e40\u0e25\u0e37\u0e2d\u0e01\u0e04\u0e48\u0e32\u0e0a\u0e48\u0e27\u0e07\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
t.add("Please enter a valid week.", "\u0e01\u0e23\u0e38\u0e13\u0e32\u0e01\u0e23\u0e2d\u0e01\u0e04\u0e48\u0e32\u0e2a\u0e31\u0e1b\u0e14\u0e32\u0e2b\u0e4c\u0e17\u0e35\u0e48\u0e16\u0e39\u0e01\u0e15\u0e49\u0e2d\u0e07", "validators", "th");
})(Translator);
