(function (t) {
// sr_Cyrl
t.add("This value should be false.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u043d\u0435\u0442\u0430\u0447\u043d\u0430.", "validators", "sr_Cyrl");
t.add("This value should be true.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0442\u0430\u0447\u043d\u0430.", "validators", "sr_Cyrl");
t.add("This value should be of type {{ type }}.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0442\u0438\u043f\u0430 {{ type }}.", "validators", "sr_Cyrl");
t.add("This value should be blank.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u043f\u0440\u0430\u0437\u043d\u0430.", "validators", "sr_Cyrl");
t.add("The value you selected is not a valid choice.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0458\u0435\u0434\u043d\u0430 \u043e\u0434 \u043f\u043e\u043d\u0443\u0452\u0435\u043d\u0438\u0445.", "validators", "sr_Cyrl");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u0418\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0431\u0430\u0440 {{ limit }} \u043c\u043e\u0433\u0443\u045b\u043d\u043e\u0441\u0442.|\u0418\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0431\u0430\u0440 {{ limit }} \u043c\u043e\u0433\u0443\u045b\u043d\u043e\u0441\u0442\u0438.|\u0418\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0431\u0430\u0440 {{ limit }} \u043c\u043e\u0433\u0443\u045b\u043d\u043e\u0441\u0442\u0438.", "validators", "sr_Cyrl");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u0418\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u043d\u0430\u0458\u0432\u0438\u0448\u0435 {{ limit }} \u043c\u043e\u0433\u0443\u045b\u043d\u043e\u0441\u0442.|\u0418\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u043d\u0430\u0458\u0432\u0438\u0448\u0435 {{ limit }} \u043c\u043e\u0433\u0443\u045b\u043d\u043e\u0441\u0442\u0438.|\u0418\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u043d\u0430\u0458\u0432\u0438\u0448\u0435 {{ limit }} \u043c\u043e\u0433\u0443\u045b\u043d\u043e\u0441\u0442\u0438.", "validators", "sr_Cyrl");
t.add("One or more of the given values is invalid.", "\u0408\u0435\u0434\u043d\u0430 \u0438\u043b\u0438 \u0432\u0438\u0448\u0435 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442\u0438 \u0458\u0435 \u043d\u0435\u0432\u0430\u043b\u0438\u0434\u043d\u0430.", "validators", "sr_Cyrl");
t.add("This field was not expected.", "\u041e\u0432\u043e \u043f\u043e\u0459\u0435 \u043d\u0438\u0458\u0435 \u0431\u0438\u043b\u043e \u043e\u0447\u0435\u043a\u0438\u0432\u0430\u043d\u043e.", "validators", "sr_Cyrl");
t.add("This field is missing.", "\u041e\u0432\u043e \u043f\u043e\u0459\u0435 \u043d\u0435\u0434\u043e\u0441\u0442\u0430\u0458\u0435.", "validators", "sr_Cyrl");
t.add("This value is not a valid date.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d \u0434\u0430\u0442\u0443\u043c.", "validators", "sr_Cyrl");
t.add("This value is not a valid datetime.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d \u0434\u0430\u0442\u0443\u043c-\u0432\u0440\u0435\u043c\u0435.", "validators", "sr_Cyrl");
t.add("This value is not a valid email address.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0430\u0434\u0440\u0435\u0441\u0430 \u0435\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u0441\u043a\u0435 \u043f\u043e\u0448\u0442\u0435.", "validators", "sr_Cyrl");
t.add("The file could not be found.", "\u0414\u0430\u0442\u043e\u0442\u0435\u043a\u0430 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0431\u0438\u0442\u0438 \u043f\u0440\u043e\u043d\u0430\u0452\u0435\u043d\u0430.", "validators", "sr_Cyrl");
t.add("The file is not readable.", "\u0414\u0430\u0442\u043e\u0442\u0435\u043a\u0430 \u043d\u0438\u0458\u0435 \u0447\u0438\u0442\u0459\u0438\u0432\u0430.", "validators", "sr_Cyrl");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0414\u0430\u0442\u043e\u0442\u0435\u043a\u0430 \u0458\u0435 \u043f\u0440\u0435\u0432\u0435\u043b\u0438\u043a\u0430 ({{ size }} {{ suffix }}). \u041d\u0430\u0458\u0432\u0435\u045b\u0430 \u0434\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0430 \u0432\u0435\u043b\u0438\u0447\u0438\u043d\u0430 \u0458\u0435 {{ limit }} {{ suffix }}.", "validators", "sr_Cyrl");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "\u041c\u0438\u043c\u0435 \u0442\u0438\u043f \u0434\u0430\u0442\u043e\u0442\u0435\u043a\u0435 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d ({{ type }}). \u0414\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0438 \u043c\u0438\u043c\u0435 \u0442\u0438\u043f\u043e\u0432\u0438 \u0441\u0443 {{ types }}.", "validators", "sr_Cyrl");
t.add("This value should be {{ limit }} or less.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 {{ limit }} \u0438\u043b\u0438 \u043c\u0430\u045a\u0435.", "validators", "sr_Cyrl");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0458\u0435 \u043f\u0440\u0435\u0434\u0443\u0433\u0430\u0447\u043a\u0430. \u0422\u0440\u0435\u0431\u0430 \u0434\u0430 \u0438\u043c\u0430 {{ limit }} \u043a\u0430\u0440\u0430\u043a\u0442\u0435\u0440 \u0438\u043b\u0438 \u043c\u0430\u045a\u0435.|\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0458\u0435 \u043f\u0440\u0435\u0434\u0443\u0433\u0430\u0447\u043a\u0430. \u0422\u0440\u0435\u0431\u0430 \u0434\u0430 \u0438\u043c\u0430 {{ limit }} \u043a\u0430\u0440\u0430\u043a\u0442\u0435\u0440\u0430 \u0438\u043b\u0438 \u043c\u0430\u045a\u0435.|\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0458\u0435 \u043f\u0440\u0435\u0434\u0443\u0433\u0430\u0447\u043a\u0430. \u0422\u0440\u0435\u0431\u0430 \u0434\u0430 \u0438\u043c\u0430 {{ limit }} \u043a\u0430\u0440\u0430\u043a\u0442\u0435\u0440\u0430 \u0438\u043b\u0438 \u043c\u0430\u045a\u0435.", "validators", "sr_Cyrl");
t.add("This value should be {{ limit }} or more.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 {{ limit }} \u0438\u043b\u0438 \u0432\u0438\u0448\u0435.", "validators", "sr_Cyrl");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0458\u0435 \u043f\u0440\u0435\u043a\u0440\u0430\u0442\u043a\u0430. \u0422\u0440\u0435\u0431\u0430 \u0434\u0430 \u0438\u043c\u0430 {{ limit }} \u043a\u0430\u0440\u0430\u043a\u0442\u0435\u0440 \u0438\u043b\u0438 \u0432\u0438\u0448\u0435.|\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0458\u0435 \u043f\u0440\u0435\u043a\u0440\u0430\u0442\u043a\u0430. \u0422\u0440\u0435\u0431\u0430 \u0434\u0430 \u0438\u043c\u0430 {{ limit }} \u043a\u0430\u0440\u0430\u043a\u0442\u0435\u0440\u0430 \u0438\u043b\u0438 \u0432\u0438\u0448\u0435.|\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0458\u0435 \u043f\u0440\u0435\u043a\u0440\u0430\u0442\u043a\u0430. \u0422\u0440\u0435\u0431\u0430 \u0434\u0430 \u0438\u043c\u0430 {{ limit }} \u043a\u0430\u0440\u0430\u043a\u0442\u0435\u0440\u0430 \u0438\u043b\u0438 \u0432\u0438\u0448\u0435.", "validators", "sr_Cyrl");
t.add("This value should not be blank.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0435 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u043f\u0440\u0430\u0437\u043d\u0430.", "validators", "sr_Cyrl");
t.add("This value should not be null.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0435 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 null.", "validators", "sr_Cyrl");
t.add("This value should be null.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 null.", "validators", "sr_Cyrl");
t.add("This value is not valid.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430.", "validators", "sr_Cyrl");
t.add("This value is not a valid time.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u043e \u0432\u0440\u0435\u043c\u0435.", "validators", "sr_Cyrl");
t.add("This value is not a valid URL.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d URL.", "validators", "sr_Cyrl");
t.add("The two values should be equal.", "\u041e\u0431\u0435 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442\u0438 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0443 \u0458\u0435\u0434\u043d\u0430\u043a\u0435.", "validators", "sr_Cyrl");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0414\u0430\u0442\u043e\u0442\u0435\u043a\u0430 \u0458\u0435 \u043f\u0440\u0435\u0432\u0435\u043b\u0438\u043a\u0430. \u041d\u0430\u0458\u0432\u0435\u045b\u0430 \u0434\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0430 \u0432\u0435\u043b\u0438\u0447\u0438\u043d\u0430 \u0458\u0435 {{ limit }} {{ suffix }}.", "validators", "sr_Cyrl");
t.add("The file is too large.", "\u0414\u0430\u0442\u043e\u0442\u0435\u043a\u0430 \u0458\u0435 \u043f\u0440\u0435\u0432\u0435\u043b\u0438\u043a\u0430.", "validators", "sr_Cyrl");
t.add("The file could not be uploaded.", "\u0414\u0430\u0442\u043e\u0442\u0435\u043a\u0430 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0431\u0438\u0442\u0438 \u043e\u0442\u043f\u0440\u0435\u043c\u0459\u0435\u043d\u0430.", "validators", "sr_Cyrl");
t.add("This value should be a valid number.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d \u0431\u0440\u043e\u0458.", "validators", "sr_Cyrl");
t.add("This file is not a valid image.", "\u041e\u0432\u0430 \u0434\u0430\u0442\u043e\u0442\u0435\u043a\u0430 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0441\u043b\u0438\u043a\u0430.", "validators", "sr_Cyrl");
t.add("This is not a valid IP address.", "\u041e\u0432\u043e \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0418\u041f \u0430\u0434\u0440\u0435\u0441\u0430.", "validators", "sr_Cyrl");
t.add("This value is not a valid language.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d \u0458\u0435\u0437\u0438\u043a.", "validators", "sr_Cyrl");
t.add("This value is not a valid locale.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d \u043b\u043e\u043a\u0430\u043b.", "validators", "sr_Cyrl");
t.add("This value is not a valid country.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0437\u0435\u043c\u0459\u0430.", "validators", "sr_Cyrl");
t.add("This value is already used.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0458\u0435 \u0432\u0435\u045b \u0438\u0441\u043a\u043e\u0440\u0438\u0448\u045b\u0435\u043d\u0430.", "validators", "sr_Cyrl");
t.add("The size of the image could not be detected.", "\u0412\u0435\u043b\u0438\u0447\u0438\u043d\u0430 \u0441\u043b\u0438\u043a\u0435 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0431\u0438\u0442\u0438 \u043e\u0434\u0440\u0435\u0452\u0435\u043d\u0430.", "validators", "sr_Cyrl");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u0428\u0438\u0440\u0438\u043d\u0430 \u0441\u043b\u0438\u043a\u0435 \u0458\u0435 \u043f\u0440\u0435\u0432\u0435\u043b\u0438\u043a\u0430 ({{ width }}px). \u041d\u0430\u0458\u0435\u045b\u0430 \u0434\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0430 \u0448\u0438\u0440\u0438\u043d\u0430 \u0458\u0435 {{ max_width }}px.", "validators", "sr_Cyrl");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u0428\u0438\u0440\u0438\u043d\u0430 \u0441\u043b\u0438\u043a\u0435 \u0458\u0435 \u043f\u0440\u0435\u043c\u0430\u043b\u0430 ({{ width }}px). \u041d\u0430\u0458\u043c\u0430\u045a\u0430 \u0434\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0430 \u0448\u0438\u0440\u0438\u043d\u0430 \u0458\u0435 {{ min_width }}px.", "validators", "sr_Cyrl");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u0412\u0438\u0441\u0438\u043d\u0430 \u0441\u043b\u0438\u043a\u0435 \u0458\u0435 \u043f\u0440\u0435\u0432\u0435\u043b\u0438\u043a\u0430 ({{ height }}px). \u041d\u0430\u0458\u0435\u045b\u0430 \u0434\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0430 \u0432\u0438\u0441\u0438\u043d\u0430 \u0458\u0435 {{ max_height }}px.", "validators", "sr_Cyrl");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u0412\u0438\u0441\u0438\u043d\u0430 \u0441\u043b\u0438\u043a\u0435 \u0458\u0435 \u043f\u0440\u0435\u043c\u0430\u043b\u0430 ({{ height }}px). \u041d\u0430\u0458\u043c\u0430\u045a\u0430 \u0434\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0430 \u0432\u0438\u0441\u0438\u043d\u0430 \u0458\u0435 {{ min_height }}px.", "validators", "sr_Cyrl");
t.add("This value should be the user's current password.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0442\u0440\u0435\u043d\u0443\u0442\u043d\u0430 \u043a\u043e\u0440\u0438\u0441\u043d\u0438\u0447\u043a\u0430 \u043b\u043e\u0437\u0438\u043d\u043a\u0430.", "validators", "sr_Cyrl");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0438\u043c\u0430 \u0442\u0430\u0447\u043d\u043e {{ limit }} \u043a\u0430\u0440\u0430\u043a\u0442\u0435\u0440.|\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0438\u043c\u0430 \u0442\u0430\u0447\u043d\u043e {{ limit }} \u043a\u0430\u0440\u0430\u043a\u0442\u0435\u0440\u0430.|\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0438\u043c\u0430 \u0442\u0430\u0447\u043d\u043e {{ limit }} \u043a\u0430\u0440\u0430\u043a\u0442\u0435\u0440\u0430.", "validators", "sr_Cyrl");
t.add("The file was only partially uploaded.", "\u0414\u0430\u0442\u043e\u0442\u0435\u043a\u0430 \u0458\u0435 \u0441\u0430\u043c\u043e \u043f\u0430\u0440\u0446\u0438\u0458\u0430\u043b\u043d\u043e \u043e\u0442\u043f\u0440\u0435\u043c\u0459\u0435\u043d\u0430.", "validators", "sr_Cyrl");
t.add("No file was uploaded.", "\u0414\u0430\u0442\u043e\u0442\u0435\u043a\u0430 \u043d\u0438\u0458\u0435 \u043e\u0442\u043f\u0440\u0435\u043c\u0459\u0435\u043d\u0430.", "validators", "sr_Cyrl");
t.add("No temporary folder was configured in php.ini.", "\u041f\u0440\u0438\u0432\u0440\u0435\u043c\u0435\u043d\u0438 \u0434\u0438\u0440\u0435\u043a\u0442\u043e\u0440\u0438\u0458\u0443\u043c \u043d\u0438\u0458\u0435 \u043a\u043e\u043d\u0444\u0438\u0433\u0443\u0440\u0438\u0441\u0430\u043d \u0443 php.ini.", "validators", "sr_Cyrl");
t.add("Cannot write temporary file to disk.", "\u041d\u0435\u043c\u043e\u0433\u0443\u045b\u0435 \u043f\u0438\u0441\u0430\u045a\u0435 \u043f\u0440\u0438\u0432\u0440\u0435\u043c\u0435\u043d\u0435 \u0434\u0430\u0442\u043e\u0442\u0435\u043a\u0435 \u043d\u0430 \u0434\u0438\u0441\u043a.", "validators", "sr_Cyrl");
t.add("A PHP extension caused the upload to fail.", "PHP \u0435\u043a\u0441\u0442\u0435\u043d\u0437\u0438\u0458\u0430 \u0458\u0435 \u043f\u0440\u043e\u0443\u0437\u0440\u043e\u043a\u043e\u0432\u0430\u043b\u0430 \u043d\u0435\u0443\u0441\u043f\u0435\u0445 \u043e\u0442\u043f\u0440\u0435\u043c\u0430\u045a\u0430 \u0434\u0430\u0442\u043e\u0442\u0435\u043a\u0435.", "validators", "sr_Cyrl");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u041e\u0432\u0430 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0430 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0441\u0430\u0434\u0440\u0436\u0438 {{ limit }} \u0438\u043b\u0438 \u0432\u0438\u0448\u0435 \u0435\u043b\u0435\u043c\u0435\u043d\u0430\u0442\u0430.|\u041e\u0432\u0430 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0430 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0441\u0430\u0434\u0440\u0436\u0438 {{ limit }} \u0438\u043b\u0438 \u0432\u0438\u0448\u0435 \u0435\u043b\u0435\u043c\u0435\u043d\u0430\u0442\u0430.|\u041e\u0432\u0430 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0430 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0441\u0430\u0434\u0440\u0436\u0438 {{ limit }} \u0438\u043b\u0438 \u0432\u0438\u0448\u0435 \u0435\u043b\u0435\u043c\u0435\u043d\u0430\u0442\u0430.", "validators", "sr_Cyrl");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u041e\u0432\u0430 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0430 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0441\u0430\u0434\u0440\u0436\u0438 {{ limit }} \u0438\u043b\u0438 \u043c\u0430\u045a\u0435 \u0435\u043b\u0435\u043c\u0435\u043d\u0430\u0442\u0430.|\u041e\u0432\u0430 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0430 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0441\u0430\u0434\u0440\u0436\u0438 {{ limit }} \u0438\u043b\u0438 \u043c\u0430\u045a\u0435 \u0435\u043b\u0435\u043c\u0435\u043d\u0430\u0442\u0430.|\u041e\u0432\u0430 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0430 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0441\u0430\u0434\u0440\u0436\u0438 {{ limit }} \u0438\u043b\u0438 \u043c\u0430\u045a\u0435 \u0435\u043b\u0435\u043c\u0435\u043d\u0430\u0442\u0430.", "validators", "sr_Cyrl");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u041e\u0432\u0430 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0430 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0441\u0430\u0434\u0440\u0436\u0438 \u0442\u0430\u0447\u043d\u043e {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442.|\u041e\u0432\u0430 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0430 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0441\u0430\u0434\u0440\u0436\u0438 \u0442\u0430\u0447\u043d\u043e {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0442\u0430.|\u041e\u0432\u0430 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0430 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0441\u0430\u0434\u0440\u0436\u0438 \u0442\u0430\u0447\u043d\u043e {{ limit }} \u0435\u043b\u0435\u043c\u0435\u043d\u0430\u0442\u0430.", "validators", "sr_Cyrl");
t.add("Invalid card number.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u0430\u043d \u0431\u0440\u043e\u0458 \u043a\u0430\u0440\u0442\u0438\u0446\u0435.", "validators", "sr_Cyrl");
t.add("Unsupported card type or invalid card number.", "\u041d\u0435\u0432\u0430\u043b\u0438\u0434\u0430\u043d \u0431\u0440\u043e\u0458 \u043a\u0430\u0440\u0442\u0438\u0446\u0435 \u0438\u043b\u0438 \u0442\u0438\u043f \u043a\u0430\u0440\u0442\u0438\u0446\u0435 \u043d\u0438\u0458\u0435 \u043f\u043e\u0434\u0440\u0436\u0430\u043d.", "validators", "sr_Cyrl");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u041e\u0432\u043e \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d \u043c\u0435\u0452\u0443\u043d\u0430\u0440\u043e\u0434\u043d\u0438 \u0431\u0440\u043e\u0458 \u0431\u0430\u043d\u043a\u043e\u0432\u043d\u043e\u0433 \u0440\u0430\u0447\u0443\u043d\u0430 (IBAN).", "validators", "sr_Cyrl");
t.add("This value is not a valid ISBN-10.", "\u041e\u0432\u043e \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d ISBN-10.", "validators", "sr_Cyrl");
t.add("This value is not a valid ISBN-13.", "\u041e\u0432\u043e \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d ISBN-13.", "validators", "sr_Cyrl");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u041e\u0432\u043e \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d ISBN-10 \u0438\u043b\u0438 ISBN-13.", "validators", "sr_Cyrl");
t.add("This value is not a valid ISSN.", "\u041e\u0432\u043e \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d ISSN.", "validators", "sr_Cyrl");
t.add("This value is not a valid currency.", "\u041e\u0432\u043e \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0432\u0430\u043b\u0443\u0442\u0430.", "validators", "sr_Cyrl");
t.add("This value should be equal to {{ compared_value }}.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 {{ compared_value }}.", "validators", "sr_Cyrl");
t.add("This value should be greater than {{ compared_value }}.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0432\u0435\u045b\u0430 \u043e\u0434 {{ compared_value }}.", "validators", "sr_Cyrl");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0432\u0435\u045b\u0430 \u0438\u043b\u0438 \u0458\u0435\u0434\u043d\u0430\u043a\u0430 {{ compared_value }}.", "validators", "sr_Cyrl");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0438\u0434\u0435\u043d\u0442\u0438\u0447\u043d\u0430 \u0441\u0430 {{ compared_value_type }} {{ compared_value }}.", "validators", "sr_Cyrl");
t.add("This value should be less than {{ compared_value }}.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u043c\u0430\u045a\u0430 \u043e\u0434 {{ compared_value }}.", "validators", "sr_Cyrl");
t.add("This value should be less than or equal to {{ compared_value }}.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u043c\u0430\u045a\u0430 \u0438\u043b\u0438 \u0458\u0435\u0434\u043d\u0430\u043a\u0430 {{ compared_value }}.", "validators", "sr_Cyrl");
t.add("This value should not be equal to {{ compared_value }}.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0435 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0458\u0435\u0434\u043d\u0430\u043a\u0430 {{ compared_value }}.", "validators", "sr_Cyrl");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0435 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0438\u0434\u0435\u043d\u0442\u0438\u0447\u043d\u0430 \u0441\u0430 {{ compared_value_type }} {{ compared_value }}.", "validators", "sr_Cyrl");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u0420\u0430\u0437\u043c\u0435\u0440\u0430 \u043e\u0432\u0435 \u0441\u043b\u0438\u043a\u0435 \u0458\u0435 \u043f\u0440\u0435\u0432\u0435\u043b\u0438\u043a\u0430 ({{ ratio }}). \u041c\u0430\u043a\u0441\u0438\u043c\u0430\u043b\u043d\u0430 \u0434\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0430 \u0440\u0430\u0437\u043c\u0435\u0440\u0430 \u0458\u0435 {{ max_ratio }}.", "validators", "sr_Cyrl");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u0420\u0430\u0437\u043c\u0435\u0440\u0430 \u043e\u0432\u0435 \u0441\u043b\u0438\u043a\u0435 \u0458\u0435 \u043f\u0440\u0435\u043c\u0430\u043b\u0430 ({{ ratio }}). \u041c\u0438\u043d\u0438\u043c\u0430\u043b\u043d\u0430 \u043e\u0447\u0435\u043a\u0438\u0432\u0430\u043d\u0430 \u0440\u0430\u0437\u043c\u0435\u0440\u0430 \u0458\u0435 {{ min_ratio }}.", "validators", "sr_Cyrl");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u0421\u043b\u0438\u043a\u0430 \u0458\u0435 \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u043d\u0430 ({{ width }}x{{ height }}px). \u041a\u0432\u0430\u0434\u0440\u0430\u0442\u043d\u0435 \u0441\u043b\u0438\u043a\u0435 \u043d\u0438\u0441\u0443 \u0434\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0435.", "validators", "sr_Cyrl");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u0421\u043b\u0438\u043a\u0430 \u0458\u0435 \u043e\u0440\u0438\u0458\u0435\u043d\u0442\u0430\u0446\u0438\u0458\u0435 \u043f\u0435\u0458\u0437\u0430\u0436\u0430 ({{ width }}x{{ height }}px). \u041f\u0435\u0458\u0437\u0430\u0436\u043d\u0430 \u043e\u0440\u0438\u0458\u0435\u043d\u0442\u0430\u0446\u0438\u0458\u0430 \u0441\u043b\u0438\u043a\u0430 \u043d\u0438\u0458\u0435 \u0434\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0430.", "validators", "sr_Cyrl");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u0421\u043b\u0438\u043a\u0430 \u0458\u0435 \u043e\u0440\u0438\u0458\u0430\u043d\u0442\u0430\u0446\u0438\u0458\u0435 \u043f\u043e\u0440\u0442\u0440\u0435\u0442\u0430 ({{ width }}x{{ height }}px). \u041f\u043e\u0440\u0442\u0440\u0435\u0442\u043d\u0430 \u043e\u0440\u0438\u0458\u0435\u043d\u0442\u0430\u0446\u0438\u0458\u0430 \u0441\u043b\u0438\u043a\u0430 \u043d\u0438\u0458\u0435 \u0434\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0430.", "validators", "sr_Cyrl");
t.add("An empty file is not allowed.", "\u041f\u0440\u0430\u0437\u043d\u0430 \u0434\u0430\u0442\u043e\u0442\u0435\u043a\u0430 \u043d\u0438\u0458\u0435 \u0434\u043e\u0437\u0432\u043e\u0459\u0435\u043d\u0430.", "validators", "sr_Cyrl");
t.add("The host could not be resolved.", "\u0418\u043c\u0435 \u0445\u043e\u0441\u0442\u0430 \u043d\u0435 \u043c\u043e\u0436\u0435 \u0431\u0438\u0442\u0438 \u0440\u0430\u0437\u0440\u0435\u0448\u0435\u043d\u043e.", "validators", "sr_Cyrl");
t.add("This value does not match the expected {{ charset }} charset.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0441\u0435 \u043d\u0435 \u043f\u043e\u043a\u043b\u0430\u043f\u0430 \u0441\u0430 \u043e\u0447\u0435\u043a\u0438\u0432\u0430\u043d\u0438\u043c {{ charset }} \u0441\u0435\u0442\u043e\u043c \u043a\u0430\u0440\u0430\u043a\u0442\u0435\u0440\u0430.", "validators", "sr_Cyrl");
t.add("This is not a valid Business Identifier Code (BIC).", "\u041e\u0432\u043e \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d \u043c\u0435\u0452\u0443\u043d\u0430\u0440\u043e\u0434\u043d\u0438 \u0438\u0434\u0435\u043d\u0442\u0438\u0444\u0438\u043a\u0430\u0446\u0438\u0458\u0441\u043a\u0438 \u043a\u043e\u0434 \u0431\u0430\u043d\u043a\u0435 (BIC).", "validators", "sr_Cyrl");
t.add("Error", "\u0413\u0440\u0435\u0448\u043a\u0430", "validators", "sr_Cyrl");
t.add("This is not a valid UUID.", "\u041e\u0432\u043e \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d \u0443\u043d\u0438\u0432\u0435\u0440\u0437\u0430\u043b\u043d\u0438 \u0443\u043d\u0438\u043a\u0430\u0442\u043d\u0438 \u0438\u0434\u0435\u043d\u0442\u0438\u0444\u0438\u043a\u0430\u0442\u043e\u0440 (UUID).", "validators", "sr_Cyrl");
t.add("This value should be a multiple of {{ compared_value }}.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0431\u0438 \u0442\u0440\u0435\u0431\u0430\u043b\u043e \u0434\u0430 \u0431\u0443\u0434\u0435 \u0434\u0435\u0459\u0438\u0432\u0430 \u0441\u0430 {{ compared_value }}.", "validators", "sr_Cyrl");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "BIC \u043a\u043e\u0434 \u043d\u0438\u0458\u0435 \u043f\u043e\u0432\u0435\u0437\u0430\u043d \u0441\u0430 IBAN {{ iban }}.", "validators", "sr_Cyrl");
t.add("This value should be valid JSON.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0431\u0438 \u0442\u0440\u0435\u0431\u0430\u043b\u043e \u0434\u0430 \u0431\u0443\u0434\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d JSON.", "validators", "sr_Cyrl");
t.add("This collection should contain only unique elements.", "\u041e\u0432\u0430 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0430 \u0431\u0438 \u0442\u0440\u0435\u0431\u0430\u043b\u0430 \u0434\u0430 \u0441\u0430\u0434\u0440\u0436\u0438 \u0441\u0430\u043c\u043e \u0458\u0435\u0434\u0438\u043d\u0441\u0442\u0432\u0435\u043d\u0435 \u0435\u043b\u0435\u043c\u0435\u043d\u0442\u0435.", "validators", "sr_Cyrl");
t.add("This value should be positive.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0431\u0438 \u0442\u0440\u0435\u0431\u0430\u043b\u0430 \u0431\u0438\u0442\u0438 \u043f\u043e\u0437\u0438\u0442\u0438\u0432\u043d\u0430.", "validators", "sr_Cyrl");
t.add("This value should be either positive or zero.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0431\u0438 \u0442\u0440\u0435\u0431\u0430\u043b\u0430 \u0431\u0438\u0442\u0438 \u043f\u043e\u0437\u0438\u0442\u0438\u0432\u043d\u0430 \u0438\u043b\u0438 \u043d\u0443\u043b\u0430.", "validators", "sr_Cyrl");
t.add("This value should be negative.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0431\u0438 \u0442\u0440\u0435\u0431\u0430\u043b\u0430 \u0431\u0438\u0442\u0438 \u043d\u0435\u0433\u0430\u0442\u0438\u0432\u043d\u0430.", "validators", "sr_Cyrl");
t.add("This value should be either negative or zero.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0431\u0438 \u0442\u0440\u0435\u0431\u0430\u043b\u0430 \u0431\u0438\u0442\u0438 \u043f\u043e\u0437\u0438\u0442\u0438\u0432\u043d\u0430 \u0438\u043b\u0438 \u043d\u0443\u043b\u0430.", "validators", "sr_Cyrl");
t.add("This value is not a valid timezone.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0432\u0430\u043b\u0438\u0434\u043d\u0430 \u0432\u0440\u0435\u043c\u0435\u043d\u0441\u043a\u0430 \u0437\u043e\u043d\u0430.", "validators", "sr_Cyrl");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "\u041e\u0432\u0430 \u043b\u043e\u0437\u0438\u043d\u043a\u0430 \u0458\u0435 \u043a\u043e\u043c\u043f\u0440\u043e\u043c\u0438\u0442\u043e\u0432\u0430\u043d\u0430 \u043f\u0440\u0438\u043b\u0438\u043a\u043e\u043c \u043f\u0440\u0435\u0442\u0445\u043e\u0434\u043d\u0438\u0445 \u043d\u0430\u043f\u0430\u0434\u0430, \u043d\u0435\u043c\u043e\u0458\u0442\u0435 \u0458\u0435 \u043a\u043e\u0440\u0438\u0441\u0442\u0438\u0442\u0438. \u041a\u043e\u0440\u0438\u0441\u0442\u0438\u0442\u0435 \u0434\u0440\u0443\u0433\u0443 \u043b\u043e\u0437\u0438\u043d\u043a\u0443.", "validators", "sr_Cyrl");
t.add("This value should be between {{ min }} and {{ max }}.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0438\u0437\u043c\u0435\u0452\u0443 {{ min }} \u0438 {{ max }}.", "validators", "sr_Cyrl");
t.add("This value is not a valid hostname.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u043e \u0438\u043c\u0435 \u0445\u043e\u0441\u0442\u0430.", "validators", "sr_Cyrl");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "\u0411\u0440\u043e\u0458 \u0435\u043b\u0435\u043c\u0435\u043d\u0430\u0442\u0430 \u0443 \u043e\u0432\u043e\u0458 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0438 \u0431\u0438 \u0442\u0440\u0435\u0431\u0430\u043b\u043e \u0434\u0430 \u0431\u0443\u0434\u0435 \u0434\u0435\u0459\u0438\u0432 \u0441\u0430 {{ compared_value }}.", "validators", "sr_Cyrl");
t.add("This value should satisfy at least one of the following constraints:", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0431\u0438 \u0442\u0440\u0435\u0431\u0430\u043b\u043e \u0434\u0430 \u0437\u0430\u0434\u043e\u0432\u043e\u0459\u0430\u0432\u0430 \u043d\u0430\u0458\u043c\u0430\u045a\u0435 \u0458\u0435\u0434\u043d\u043e \u043e\u0434 \u043d\u0430\u0440\u0435\u0434\u043d\u0438\u0445 \u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u045a\u0430:", "validators", "sr_Cyrl");
t.add("Each element of this collection should satisfy its own set of constraints.", "\u0421\u0432\u0430\u043a\u0438 \u0435\u043b\u0435\u043c\u0435\u043d\u0442 \u043e\u0432\u0435 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0435 \u0431\u0438 \u0442\u0440\u0435\u0431\u0430\u043b\u043e \u0434\u0430 \u0437\u0430\u0434\u043e\u0432\u043e\u0459\u0438 \u0441\u043e\u043f\u0441\u0442\u0432\u0435\u043d\u0438 \u0441\u043a\u0443\u043f \u043e\u0433\u0440\u0430\u043d\u0438\u0447\u0435\u045a\u0430.", "validators", "sr_Cyrl");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0430 \u043c\u0435\u0452\u0443\u043d\u0430\u0440\u043e\u0434\u043d\u0430 \u0438\u0434\u0435\u043d\u0442\u0438\u0444\u0438\u043a\u0430\u0446\u0438\u043e\u043d\u0430 \u043e\u0437\u043d\u0430\u043a\u0430 \u0445\u0430\u0440\u0442\u0438\u0458\u0430 \u043e\u0434 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442\u0438 (ISIN).", "validators", "sr_Cyrl");
t.add("This value should be a valid expression.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0431\u0443\u0434\u0435 \u0432\u0430\u043b\u0438\u0434\u0430\u043d \u0438\u0437\u0440\u0430\u0437.", "validators", "sr_Cyrl");
t.add("This value is not a valid CSS color.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0430 CSS \u0431\u043e\u0458\u0430.", "validators", "sr_Cyrl");
t.add("This value is not a valid CIDR notation.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0430 CIDR \u043d\u043e\u0442\u0430\u0446\u0438\u0458\u0430.", "validators", "sr_Cyrl");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "\u0412\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043c\u0440\u0435\u0436\u043d\u0435 \u043c\u0430\u0441\u043a\u0435 \u0442\u0440\u0435\u0431\u0430 \u0431\u0438\u0442\u0438 \u0438\u0437\u043c\u0435\u0452\u0443 {{ min }} \u0438 {{ max }}.", "validators", "sr_Cyrl");
t.add("This form should not contain extra fields.", "\u041e\u0432\u0430\u0458 \u0444\u043e\u0440\u043c\u0443\u043b\u0430\u0440 \u043d\u0435 \u0442\u0440\u0435\u0431\u0430 \u0434\u0430 \u0441\u0430\u0434\u0440\u0436\u0438 \u0434\u043e\u0434\u0430\u0442\u043d\u0430 \u043f\u043e\u0459\u0430.", "validators", "sr_Cyrl");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "\u041e\u0442\u043f\u0440\u0435\u043c\u0459\u0435\u043d\u0430 \u0434\u0430\u0442\u043e\u0442\u0435\u043a\u0430 \u0458\u0435 \u0431\u0438\u043b\u0430 \u043f\u0440\u0435\u0432\u0435\u043b\u0438\u043a\u0430. \u041c\u043e\u043b\u0438\u043c \u043f\u043e\u043a\u0443\u0448\u0430\u0458\u0442\u0435 \u043e\u0442\u043f\u0440\u0435\u043c\u0430\u045a\u0435 \u043c\u0430\u045a\u0435 \u0434\u0430\u0442\u043e\u0442\u0435\u043a\u0435.", "validators", "sr_Cyrl");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0430. \u041f\u043e\u043a\u0443\u0448\u0430\u0458\u0442\u0435 \u043f\u043e\u043d\u043e\u0432\u043e.", "validators", "sr_Cyrl");
t.add("This value is not a valid HTML5 color.", "\u041e\u0432\u0430 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442 \u043d\u0438\u0458\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0430 HTML5 \u0431\u043e\u0458\u0430.", "validators", "sr_Cyrl");
t.add("Please enter a valid birthdate.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0430\u043d \u0434\u0430\u0442\u0443\u043c \u0440\u043e\u0452\u0435\u045a\u0430.", "validators", "sr_Cyrl");
t.add("The selected choice is invalid.", "\u041e\u0434\u0430\u0431\u0440\u0430\u043d\u0438 \u0438\u0437\u0431\u043e\u0440 \u043d\u0438\u0458\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0430\u043d.", "validators", "sr_Cyrl");
t.add("The collection is invalid.", "\u041e\u0432\u0430 \u043a\u043e\u043b\u0435\u043a\u0446\u0438\u0458\u0430 \u043d\u0438\u0458\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0430.", "validators", "sr_Cyrl");
t.add("Please select a valid color.", "\u041c\u043e\u043b\u0438\u043c \u0438\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0443 \u0431\u043e\u0458\u0443.", "validators", "sr_Cyrl");
t.add("Please select a valid country.", "\u041c\u043e\u043b\u0438\u043c \u0438\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0443 \u0434\u0440\u0436\u0430\u0432\u0443.", "validators", "sr_Cyrl");
t.add("Please select a valid currency.", "\u041c\u043e\u043b\u0438\u043c \u0438\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0443 \u0432\u0430\u043b\u0443\u0442\u0443.", "validators", "sr_Cyrl");
t.add("Please choose a valid date interval.", "\u041c\u043e\u043b\u0438\u043c \u0438\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0430\u043d \u0434\u0430\u0442\u0443\u043c\u0441\u043a\u0438 \u0438\u043d\u0442\u0435\u0440\u0432\u0430\u043b.", "validators", "sr_Cyrl");
t.add("Please enter a valid date and time.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0430\u043d \u0434\u0430\u0442\u0443\u043c \u0438 \u0432\u0440\u0435\u043c\u0435.", "validators", "sr_Cyrl");
t.add("Please enter a valid date.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0430\u043d \u0434\u0430\u0442\u0443\u043c.", "validators", "sr_Cyrl");
t.add("Please select a valid file.", "\u041c\u043e\u043b\u0438\u043c \u0438\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0443 \u0434\u0430\u0442\u043e\u0442\u0435\u043a\u0443.", "validators", "sr_Cyrl");
t.add("The hidden field is invalid.", "\u0421\u043a\u0440\u0438\u0432\u0435\u043d\u043e \u043f\u043e\u0459\u0435 \u043d\u0438\u0458\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u043e.", "validators", "sr_Cyrl");
t.add("Please enter an integer.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u0446\u0435\u043e \u0431\u0440\u043e\u0458 (integer).", "validators", "sr_Cyrl");
t.add("Please select a valid language.", "\u041c\u043e\u043b\u0438\u043c \u0438\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0430\u043d \u0458\u0435\u0437\u0438\u043a.", "validators", "sr_Cyrl");
t.add("Please select a valid locale.", "\u041c\u043e\u043b\u0438\u043c \u0438\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0443 \u043b\u043e\u043a\u0430\u043b\u0438\u0437\u0430\u0446\u0438\u0458\u0443.", "validators", "sr_Cyrl");
t.add("Please enter a valid money amount.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0443 \u043a\u043e\u043b\u0438\u0447\u0438\u043d\u0443 \u043d\u043e\u0432\u0446\u0430.", "validators", "sr_Cyrl");
t.add("Please enter a number.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u0431\u0440\u043e\u0458.", "validators", "sr_Cyrl");
t.add("The password is invalid.", "\u041e\u0432\u0430 \u043b\u043e\u0437\u0438\u043d\u043a\u0430 \u043d\u0438\u0458\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0430.", "validators", "sr_Cyrl");
t.add("Please enter a percentage value.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u043f\u0440\u043e\u0446\u0435\u043d\u0442\u0443\u0430\u043b\u043d\u0443 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442.", "validators", "sr_Cyrl");
t.add("The values do not match.", "\u0414\u0430\u0442\u0435 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442\u0438 \u0441\u0435 \u043d\u0435 \u043f\u043e\u043a\u043b\u0430\u043f\u0430\u0458\u0443.", "validators", "sr_Cyrl");
t.add("Please enter a valid time.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u043e \u0432\u0440\u0435\u043c\u0435.", "validators", "sr_Cyrl");
t.add("Please select a valid timezone.", "\u041c\u043e\u043b\u0438\u043c \u0438\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0443 \u0432\u0440\u0435\u043c\u0435\u043d\u0441\u043a\u0443 \u0437\u043e\u043d\u0443.", "validators", "sr_Cyrl");
t.add("Please enter a valid URL.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0430\u043d URL.", "validators", "sr_Cyrl");
t.add("Please enter a valid search term.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0430\u043d \u0442\u0435\u0440\u043c\u0438\u043d \u0437\u0430 \u043f\u0440\u0435\u0442\u0440\u0430\u0433\u0443.", "validators", "sr_Cyrl");
t.add("Please provide a valid phone number.", "\u041c\u043e\u043b\u0438\u043c \u043d\u0430\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0430\u043d \u0431\u0440\u043e\u0458 \u0442\u0435\u043b\u0435\u0444\u043e\u043d\u0430.", "validators", "sr_Cyrl");
t.add("The checkbox has an invalid value.", "\u041f\u043e\u0459\u0435 \u0437\u0430 \u043f\u043e\u0442\u0432\u0440\u0434\u0443 \u0441\u0430\u0434\u0440\u0436\u0438 \u043d\u0435\u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0443 \u0432\u0440\u0435\u0434\u043d\u043e\u0441\u0442.", "validators", "sr_Cyrl");
t.add("Please enter a valid email address.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0443 email \u0430\u0434\u0440\u0435\u0441\u0443.", "validators", "sr_Cyrl");
t.add("Please select a valid option.", "\u041c\u043e\u043b\u0438\u043c \u0438\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0443 \u043e\u043f\u0446\u0438\u0458\u0443.", "validators", "sr_Cyrl");
t.add("Please select a valid range.", "\u041c\u043e\u043b\u0438\u043c \u0438\u0437\u0430\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u0430\u043d \u043e\u043f\u0441\u0435\u0433.", "validators", "sr_Cyrl");
t.add("Please enter a valid week.", "\u041c\u043e\u043b\u0438\u043c \u0443\u043f\u0438\u0448\u0438\u0442\u0435 \u0438\u0441\u043f\u0440\u0430\u0432\u043d\u0443 \u0441\u0435\u0434\u043c\u0438\u0446\u0443.", "validators", "sr_Cyrl");
})(Translator);
