(function (t) {
// en
t.add("An authentication exception occurred.", "An authentication exception occurred.", "security", "en");
t.add("Authentication credentials could not be found.", "Authentication credentials could not be found.", "security", "en");
t.add("Authentication request could not be processed due to a system problem.", "Authentication request could not be processed due to a system problem.", "security", "en");
t.add("Invalid credentials.", "Invalid credentials.", "security", "en");
t.add("Cookie has already been used by someone else.", "Cookie has already been used by someone else.", "security", "en");
t.add("Not privileged to request the resource.", "Not privileged to request the resource.", "security", "en");
t.add("Invalid CSRF token.", "Invalid CSRF token.", "security", "en");
t.add("No authentication provider found to support the authentication token.", "No authentication provider found to support the authentication token.", "security", "en");
t.add("No session available, it either timed out or cookies are not enabled.", "No session available, it either timed out or cookies are not enabled.", "security", "en");
t.add("No token could be found.", "No token could be found.", "security", "en");
t.add("Username could not be found.", "Username could not be found.", "security", "en");
t.add("Account has expired.", "Account has expired.", "security", "en");
t.add("Credentials have expired.", "Credentials have expired.", "security", "en");
t.add("Account is disabled.", "Account is disabled.", "security", "en");
t.add("Account is locked.", "Account is locked.", "security", "en");
t.add("Too many failed login attempts, please try again later.", "Too many failed login attempts, please try again later.", "security", "en");
t.add("Invalid or expired login link.", "Invalid or expired login link.", "security", "en");
t.add("Too many failed login attempts, please try again in %minutes% minute.", "Too many failed login attempts, please try again in %minutes% minute.", "security", "en");
t.add("Too many failed login attempts, please try again in %minutes% minutes.", "Too many failed login attempts, please try again in %minutes% minutes.", "security", "en");
t.add("Login", "__Login", "security", "en");
t.add("Email", "__Email", "security", "en");
t.add("Password", "__Password", "security", "en");
t.add("Remember me", "__Remember me", "security", "en");
t.add("Forgotten Password ?", "__Forgotten Password ?", "security", "en");
t.add("Enter your email to reset your password:", "__Enter your email to reset your password:", "security", "en");
t.add("Request", "__Request", "security", "en");
t.add("Cancel", "__Cancel", "security", "en");
})(Translator);
