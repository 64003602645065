(function (t) {
// az
t.add("This value should be false.", "Bu d\u0259y\u0259r false olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be true.", "Bu d\u0259y\u0259r true olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be of type {{ type }}.", "Bu d\u0259y\u0259rin tipi {{ type }} olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be blank.", "Bu d\u0259y\u0259r bo\u015f olmal\u0131d\u0131r.", "validators", "az");
t.add("The value you selected is not a valid choice.", "Se\u00e7diyiniz d\u0259y\u0259r d\u00fczg\u00fcn bir se\u00e7im de\u011fil.", "validators", "az");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u018fn az {{ limit }} se\u00e7im qeyd edilm\u0259lidir.", "validators", "az");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u018fn \u00e7ox {{ limit }} se\u00e7im qeyd edilm\u0259lidir.", "validators", "az");
t.add("One or more of the given values is invalid.", "T\u0259qdim edil\u0259n d\u0259y\u0259rl\u0259rd\u0259n bir v\u0259 ya bir ne\u00e7\u0259si yanl\u0131\u015fd\u0131r.", "validators", "az");
t.add("This field was not expected.", "Bu sah\u0259 g\u00f6zl\u0259nilmirdi.", "validators", "az");
t.add("This field is missing.", "Bu sah\u0259 \u0259ksikdir.", "validators", "az");
t.add("This value is not a valid date.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir tarix deyil.", "validators", "az");
t.add("This value is not a valid datetime.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir tarixsaat deyil.", "validators", "az");
t.add("This value is not a valid email address.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir e-po\u00e7t adresi deyil.", "validators", "az");
t.add("The file could not be found.", "Fayl tap\u0131lmad\u0131.", "validators", "az");
t.add("The file is not readable.", "Fayl oxunabil\u0259n deyil.", "validators", "az");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Fayl \u00e7ox b\u00f6y\u00fckd\u00fcr ({{ size }} {{ suffix }}). \u0130caz\u0259 veril\u0259n maksimum fayl \u00f6l\u00e7\u00fcs\u00fc {{ limit }} {{ suffix }}.", "validators", "az");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Fayl\u0131n mime tipi yanl\u0131\u015fdr ({{ type }}). \u0130caz\u0259 veril\u0259n mime tipl\u0259ri {{ types }}.", "validators", "az");
t.add("This value should be {{ limit }} or less.", "Bu d\u0259y\u0259r {{ limit }} v\u0259 ya alt\u0131nda olmal\u0131d\u0131r.", "validators", "az");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Bu d\u0259y\u0259r \u00e7ox uzundur. {{ limit }} v\u0259 ya daha az simvol olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be {{ limit }} or more.", "Bu d\u0259y\u0259r {{ limit }} veya daha fazla olmal\u0131d\u0131r.", "validators", "az");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Bu d\u0259y\u0259r \u00e7ox q\u0131sad\u0131r. {{ limit }} v\u0259 ya daha \u00e7ox simvol olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should not be blank.", "Bu d\u0259y\u0259r bo\u015f olmamal\u0131d\u0131r.", "validators", "az");
t.add("This value should not be null.", "Bu d\u0259y\u0259r bo\u015f olmamal\u0131d\u0131r.", "validators", "az");
t.add("This value should be null.", "Bu d\u0259y\u0259r bo\u015f olmamal\u0131d\u0131r.", "validators", "az");
t.add("This value is not valid.", "Bu d\u0259y\u0259r do\u011fru deyil.", "validators", "az");
t.add("This value is not a valid time.", "Bu d\u0259y\u0259r do\u011fru bir saat deyil.", "validators", "az");
t.add("This value is not a valid URL.", "Bu d\u0259y\u0259r do\u011fru bir URL de\u011fil.", "validators", "az");
t.add("The two values should be equal.", "\u0130ki d\u0259y\u0259r eyni olmal\u0131d\u0131r.", "validators", "az");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Fayl \u00e7ox b\u00f6y\u00fckd\u00fcr. \u0130caz\u0259 veril\u0259n \u0259n b\u00f6y\u00fck fayl \u00f6l\u00e7\u00fcs\u00fc {{ limit }} {{ suffix }}.", "validators", "az");
t.add("The file is too large.", "Fayl \u00e7ox b\u00f6y\u00fckd\u00fcr.", "validators", "az");
t.add("The file could not be uploaded.", "Fayl y\u00fckl\u0259n\u0259bilmir.", "validators", "az");
t.add("This value should be a valid number.", "Bu d\u0259y\u0259r r\u0259q\u0259m olmal\u0131d\u0131r.", "validators", "az");
t.add("This file is not a valid image.", "Bu fayl d\u00fczg\u00fcn bir \u015f\u0259kil deyil.", "validators", "az");
t.add("This is not a valid IP address.", "Bu d\u00fczg\u00fcn bir IP adresi deyil.", "validators", "az");
t.add("This value is not a valid language.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir dil deyil.", "validators", "az");
t.add("This value is not a valid locale.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir dil deyil.", "validators", "az");
t.add("This value is not a valid country.", "Bu d\u0259y\u0259r d\u00fczg\u00fcn bir \u00f6lk\u0259 deyil.", "validators", "az");
t.add("This value is already used.", "Bu d\u0259y\u0259r hal-haz\u0131rda istifad\u0259d\u0259dir.", "validators", "az");
t.add("The size of the image could not be detected.", "\u015e\u0259klin \u00f6l\u00e7\u00fcs\u00fc hesablana bilmir.", "validators", "az");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u015e\u0259klin geni\u015fliyi \u00e7ox b\u00f6y\u00fckd\u00fcr ({{ width }}px). \u0130caz\u0259 veril\u0259n \u0259n b\u00f6y\u00fck geni\u015flik {{ max_width }}px.", "validators", "az");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u015e\u0259klin geni\u015fliyi \u00e7ox ki\u00e7ikdir ({{ width }}px). \u018fn az {{ min_width }}px olmal\u0131d\u0131r.", "validators", "az");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u015e\u0259klin y\u00fcks\u0259kliyi \u00e7ox b\u00f6y\u00fckd\u00fcr ({{ height }}px). \u0130caz\u0259 veril\u0259n \u0259n b\u00f6y\u00fck y\u00fcks\u0259klik {{ max_height }}px.", "validators", "az");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u015e\u0259klin y\u00fcks\u0259kliyi \u00e7ox ki\u00e7ikdir ({{ height }}px). \u018fn az {{ min_height }}px olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be the user's current password.", "Bu d\u0259y\u0259r istifad\u0259\u00e7inin haz\u0131rk\u0131 parolu olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Bu d\u0259y\u0259r tam olaraq {{ limit }} simvol olmald\u0131r.", "validators", "az");
t.add("The file was only partially uploaded.", "Fayl qism\u0259n y\u00fckl\u0259ndi.", "validators", "az");
t.add("No file was uploaded.", "Fayl y\u00fckl\u0259nm\u0259di.", "validators", "az");
t.add("No temporary folder was configured in php.ini.", "php.ini'd\u0259 m\u00fcv\u0259qq\u0259ti qovluq qura\u015fd\u0131r\u0131lmay\u0131b.", "validators", "az");
t.add("Cannot write temporary file to disk.", "M\u00fcv\u0259qq\u0259ti fayl disk\u0259 yaz\u0131la bilmir.", "validators", "az");
t.add("A PHP extension caused the upload to fail.", "Bir PHP \u0259lav\u0259si fayl\u0131n y\u00fckl\u0259nm\u0259sin\u0259 mane oldu.", "validators", "az");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Bu kolleksiyada {{ limit }} v\u0259 ya daha \u00e7ox element olmal\u0131d\u0131r.", "validators", "az");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Bu kolleksiyada {{ limit }} v\u0259 ya daha az element olmal\u0131d\u0131r.", "validators", "az");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Bu kolleksiyada tam olaraq {{ limit }} element olmal\u0131d\u0131r.", "validators", "az");
t.add("Invalid card number.", "Yanl\u0131\u015f kart n\u00f6mr\u0259si.", "validators", "az");
t.add("Unsupported card type or invalid card number.", "D\u0259st\u0259kl\u0259nm\u0259y\u0259n kart tipi v\u0259 ya yanl\u0131\u015f kart n\u00f6mr\u0259si.", "validators", "az");
t.add("This is not a valid International Bank Account Number (IBAN).", "Bu d\u0259y\u0259r do\u011fru bir Beyn\u0259lxalq Bank Hesap N\u00f6mr\u0259si (IBAN) deyil.", "validators", "az");
t.add("This value is not a valid ISBN-10.", "Bu d\u0259y\u0259r do\u011fru bir ISBN-10 deyil.", "validators", "az");
t.add("This value is not a valid ISBN-13.", "Bu d\u0259y\u0259r do\u011fru bir ISBN-13 deyil.", "validators", "az");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Bu d\u0259y\u0259r do\u011fru bir ISBN-10 v\u0259 ya ISBN-13 deyil.", "validators", "az");
t.add("This value is not a valid ISSN.", "Bu d\u0259y\u0259r do\u011fru bir ISSN deyil.", "validators", "az");
t.add("This value is not a valid currency.", "Bu d\u0259y\u0259r do\u011fru bir valyuta deyil.", "validators", "az");
t.add("This value should be equal to {{ compared_value }}.", "Bu d\u0259y\u0259r {{ compared_value }} il\u0259 b\u0259rab\u0259r olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be greater than {{ compared_value }}.", "Bu d\u0259y\u0259r {{ compared_value }} d\u0259y\u0259rind\u0259n b\u00fcy\u00fck olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Bu d\u0259y\u0259r {{ compared_value }} il\u0259 b\u0259rab\u0259r v\u0259 ya daha b\u00f6y\u00fck olmald\u0131r.", "validators", "az");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Bu d\u0259y\u0259r {{ compared_value_type }} {{ compared_value }} il\u0259 eyni olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be less than {{ compared_value }}.", "Bu d\u0259y\u0259r {{ compared_value }} d\u0259y\u0259rind\u0259n ki\u00e7ik olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be less than or equal to {{ compared_value }}.", "Bu d\u0259y\u0259r {{ compared_value }} d\u0259y\u0259rind\u0259n ki\u00e7ik v\u0259 ya b\u0259rab\u0259r olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should not be equal to {{ compared_value }}.", "Bu de\u011fer {{ compared_value }} ile e\u015fit olmamal\u0131d\u0131r.", "validators", "az");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Bu d\u0259y\u0259r {{ compared_value_type }} {{ compared_value }} il\u0259 eyni olmamal\u0131d\u0131r.", "validators", "az");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u015e\u0259kil nisb\u0259ti \u00e7ox b\u00fcy\u00fckd\u00fcr ({{ ratio }}). \u0130caz\u0259 veril\u0259n maksimum nisb\u0259t: {{ max_ratio }}.", "validators", "az");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u015e\u0259kil nisb\u0259ti \u00e7ox balacad\u0131r ({{ ratio }}). \u0130caz\u0259 veril\u0259n minimum nisb\u0259t: {{ min_ratio }}.", "validators", "az");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u015e\u0259kil kvadratd\u0131r ({{ width }}x{{ height }}px). Kvadrat \u015f\u0259kill\u0259r\u0259 icaz\u0259 verilmir.", "validators", "az");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u015e\u0259kil albom rejimind\u0259dir ({{ width }}x{{ height }}px). Albom rejimli \u015f\u0259kill\u0259r\u0259 icaz\u0259 verilmir.", "validators", "az");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u015e\u0259kil portret rejimind\u0259dir ({{ width }}x{{ height }}px). Portret rejimli \u015f\u0259kill\u0259r\u0259 icaz\u0259 verilmir.", "validators", "az");
t.add("An empty file is not allowed.", "Bo\u015f fayla icaz\u0259 verilmir.", "validators", "az");
t.add("The host could not be resolved.", "\u00dcnvan tap\u0131lmad\u0131.", "validators", "az");
t.add("This value does not match the expected {{ charset }} charset.", "Bu d\u0259y\u0259r g\u00f6zl\u0259nil\u0259n {{ charset }} simvol c\u0259dv\u0259li il\u0259 uy\u011fun g\u0259lmir.", "validators", "az");
t.add("This is not a valid Business Identifier Code (BIC).", "Bu d\u0259y\u0259r do\u011fru bir Biznes T\u0259yinedici Kodu (BIC) deyil.", "validators", "az");
t.add("Error", "X\u0259ta", "validators", "az");
t.add("This is not a valid UUID.", "Bu d\u0259y\u0259r do\u011fru bir UUID deyil.", "validators", "az");
t.add("This value should be a multiple of {{ compared_value }}.", "Bu d\u0259y\u0259r {{ compare_value }} d\u0259y\u0259rinin b\u00f6l\u0259nl\u0259rind\u0259n biri olmal\u0131d\u0131r.", "validators", "az");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Bu Biznes T\u0259yinedici Kodu (BIC) {{ iban }} IBAN kodu il\u0259 \u0259laq\u0259li deyil.", "validators", "az");
t.add("This value should be valid JSON.", "Bu d\u0259y\u0259r do\u011fru bir JSON olmal\u0131d\u0131r.", "validators", "az");
t.add("This collection should contain only unique elements.", "Bu kolleksiyada sad\u0259c\u0259 unikal elementl\u0259r olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be positive.", "Bu d\u0259y\u0259r m\u00fcsb\u0259t olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be either positive or zero.", "Bu d\u0259y\u0259r m\u00fcsb\u0259t v\u0259 ya s\u0131f\u0131r olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should be negative.", "Bu d\u0259y\u0259r m\u0259nfi olmald\u0131r.", "validators", "az");
t.add("This value should be either negative or zero.", "Bu d\u0259y\u0259r m\u0259nfi v\u0259 ya s\u0131f\u0131r olmald\u0131r.", "validators", "az");
t.add("This value is not a valid timezone.", "Bu d\u0259y\u0259r do\u011fru bir zaman zola\u011f\u0131 deyil.", "validators", "az");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Bu parol data o\u011furlu\u011funda tap\u0131ld\u0131\u011f\u0131 \u00fc\u00e7\u00fcn i\u015fl\u0259dilm\u0259m\u0259lidir. Z\u0259hm\u0259t olmasa, ba\u015fqa parol se\u00e7in.", "validators", "az");
t.add("This value should be between {{ min }} and {{ max }}.", "Bu d\u0259y\u0259r {{ min }} v\u0259 {{ max }} aras\u0131nda olmald\u0131r.", "validators", "az");
t.add("This value is not a valid hostname.", "Bu d\u0259y\u0259r do\u011fru bir host ad\u0131 deyil.", "validators", "az");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Bu kolleksiyadak\u0131 elementlerin say\u0131 {{ compared_value }} tam b\u00f6l\u00fcn\u0259ni olmal\u0131d\u0131r.", "validators", "az");
t.add("This value should satisfy at least one of the following constraints:", "Bu d\u0259y\u0259r a\u015fa\u011f\u0131dak\u0131 m\u0259cburiyy\u0259tl\u0259rd\u0259n birini qar\u015f\u0131lamal\u0131d\u0131r:", "validators", "az");
t.add("Each element of this collection should satisfy its own set of constraints.", "Bu kolleksiyadak\u0131 h\u0259r element \u00f6z m\u0259cburiyy\u0259tl\u0259rini qar\u015f\u0131lamal\u0131d\u0131r.", "validators", "az");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Bu d\u0259y\u0259r do\u011fru bir Qiym\u0259tli Ka\u011f\u0131z\u0131n Beyn\u0259lxalq \u0130dentifikasiya Kodu (ISIN) deyil.", "validators", "az");
t.add("This form should not contain extra fields.", "Bu formada \u0259lav\u0259 sah\u0259 olmamal\u0131d\u0131r.", "validators", "az");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Y\u00fckl\u0259n\u0259n fayl \u00e7ox b\u00f6y\u00fckd\u00fcr. L\u00fctf\u0259n daha ki\u00e7ik fayl y\u00fckl\u0259yin.", "validators", "az");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF ni\u015fan\u0131 yanl\u0131\u015fd\u0131r. L\u00fctfen forman\u0131 yenid\u0259n g\u00f6nd\u0259rin.", "validators", "az");
t.add("This value is not a valid HTML5 color.", "Bu d\u0259y\u0259r do\u011fru bir HTML5 r\u0259ngi deyil.", "validators", "az");
t.add("Please enter a valid birthdate.", "Z\u0259hm\u0259t olmasa do\u011fru bir do\u011fum g\u00fcn\u00fc daxil edin.", "validators", "az");
t.add("The selected choice is invalid.", "Se\u00e7ilmi\u015f se\u00e7im do\u011fru deyil.", "validators", "az");
t.add("The collection is invalid.", "Kolleksiya do\u011fru deyil.", "validators", "az");
t.add("Please select a valid color.", "Z\u0259hm\u0259t olmasa do\u011fru bir r\u0259ng se\u00e7in.", "validators", "az");
t.add("Please select a valid country.", "Z\u0259hm\u0259t olmasa do\u011fru bir \u00f6lk\u0259 se\u00e7in.", "validators", "az");
t.add("Please select a valid currency.", "Z\u0259hm\u0259t olmasa do\u011fru bir valyuta se\u00e7in.", "validators", "az");
t.add("Please choose a valid date interval.", "Z\u0259hm\u0259t olmasa do\u011fru bir tarix aral\u0131\u011f\u0131 se\u00e7in.", "validators", "az");
t.add("Please enter a valid date and time.", "Z\u0259hm\u0259t olmasa do\u011fru bir tarix ve saat daxil edin.", "validators", "az");
t.add("Please enter a valid date.", "Z\u0259hm\u0259t olmasa do\u011fru bir tarix daxil edin.", "validators", "az");
t.add("Please select a valid file.", "Z\u0259hm\u0259t olmasa do\u011fru bir fayl se\u00e7in.", "validators", "az");
t.add("The hidden field is invalid.", "Gizli sah\u0259 do\u011fru deyil.", "validators", "az");
t.add("Please enter an integer.", "Z\u0259hm\u0259t olmasa bir tam \u0259d\u0259d daxil edin.", "validators", "az");
t.add("Please select a valid language.", "Z\u0259hm\u0259t olmasa do\u011fru bir dil se\u00e7in.", "validators", "az");
t.add("Please select a valid locale.", "Z\u0259hm\u0259t olmasa do\u011fru bir yer se\u00e7in.", "validators", "az");
t.add("Please enter a valid money amount.", "Z\u0259hm\u0259t olmasa do\u011fru bir pul miqdar\u0131 daxil edin.", "validators", "az");
t.add("Please enter a number.", "Z\u0259hm\u0259t olmasa do\u011fru bir r\u0259q\u0259m daxil edin.", "validators", "az");
t.add("The password is invalid.", "Parol do\u011fru deyil.", "validators", "az");
t.add("Please enter a percentage value.", "Z\u0259hm\u0259t olmasa do\u011fru bir faiz d\u0259y\u0259ri daxil edin.", "validators", "az");
t.add("The values do not match.", "D\u0259y\u0259rl\u0259r \u00f6rt\u00fc\u015fm\u00fcr.", "validators", "az");
t.add("Please enter a valid time.", "Z\u0259hm\u0259t olmasa do\u011fru bir saat daxil edin.", "validators", "az");
t.add("Please select a valid timezone.", "Z\u0259hm\u0259t olmasa do\u011fru bir saat qur\u015fa\u011f\u0131 se\u00e7in.", "validators", "az");
t.add("Please enter a valid URL.", "Z\u0259hm\u0259t olmasa do\u011fru bir URL daxil edin.", "validators", "az");
t.add("Please enter a valid search term.", "Z\u0259hm\u0259t olmasa do\u011fru bir axtar\u0131\u015f termini daxil edin.", "validators", "az");
t.add("Please provide a valid phone number.", "Z\u0259hm\u0259t olmasa do\u011fru bir telefon n\u00f6mr\u0259si se\u00e7in.", "validators", "az");
t.add("The checkbox has an invalid value.", "Se\u00e7im qutusunda do\u011fru olmayan d\u0259y\u0259r var.", "validators", "az");
t.add("Please enter a valid email address.", "Z\u0259hm\u0259t olmasa do\u011fru bir e-po\u00e7t se\u00e7in.", "validators", "az");
t.add("Please select a valid option.", "Z\u0259hm\u0259t olmasa do\u011fru bir variant se\u00e7in.", "validators", "az");
t.add("Please select a valid range.", "Z\u0259hm\u0259t olmasa do\u011fru bir aral\u0131q se\u00e7in.", "validators", "az");
t.add("Please enter a valid week.", "Z\u0259hm\u0259t olmasa do\u011fru bir h\u0259ft\u0259 se\u00e7in.", "validators", "az");
})(Translator);
