(function (t) {
// nn
t.add("This value should be false.", "Verdien skulle ha vore tom\/nei.", "validators", "nn");
t.add("This value should be true.", "Verdien skulla ha vore satt\/ja.", "validators", "nn");
t.add("This value should be of type {{ type }}.", "Verdien m\u00e5 vere av typen {{ type }}.", "validators", "nn");
t.add("This value should be blank.", "Verdien skal vere blank.", "validators", "nn");
t.add("The value you selected is not a valid choice.", "Verdien du valde er ikkje gyldig.", "validators", "nn");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Du m\u00e5 gjere minst {{ limit }} val.", "validators", "nn");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Du kan maksimalt gjere {{ limit }} val.", "validators", "nn");
t.add("One or more of the given values is invalid.", "Ein eller fleire av dei opplyste verdiane er ugyldige.", "validators", "nn");
t.add("This field was not expected.", "Dette feltet var ikkje forventa.", "validators", "nn");
t.add("This field is missing.", "Dette feltet mangler.", "validators", "nn");
t.add("This value is not a valid date.", "Verdien er ikkje ein gyldig dato.", "validators", "nn");
t.add("This value is not a valid datetime.", "Verdien er ikkje ein gyldig dato og tid.", "validators", "nn");
t.add("This value is not a valid email address.", "Verdien er ikkje ei gyldig e-postadresse.", "validators", "nn");
t.add("The file could not be found.", "Fila er ikkje funnen.", "validators", "nn");
t.add("The file is not readable.", "Fila kan ikkje lesast.", "validators", "nn");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Fila er for stor ({{ size }} {{ suffix }}). Maksimal storleik er {{ limit }} {{ suffix }}.", "validators", "nn");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Mime-typen av fila er ugyldig ({{ type }}). Tillatne mime-typar er {{ types }}.", "validators", "nn");
t.add("This value should be {{ limit }} or less.", "Verdien m\u00e5 vere {{ limit }} eller mindre.", "validators", "nn");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Verdien er for lang. Den m\u00e5 vere {{ limit }} bokstavar eller mindre.", "validators", "nn");
t.add("This value should be {{ limit }} or more.", "Verdien m\u00e5 vere {{ limit }} eller meir.", "validators", "nn");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Verdien er for kort. Den m\u00e5 ha {{ limit }} teikn eller fleire.", "validators", "nn");
t.add("This value should not be blank.", "Verdien kan ikkje vere blank.", "validators", "nn");
t.add("This value should not be null.", "Verdien kan ikkje vere tom (null).", "validators", "nn");
t.add("This value should be null.", "Verdien m\u00e5 vere tom (null).", "validators", "nn");
t.add("This value is not valid.", "Verdien er ikkje gyldig.", "validators", "nn");
t.add("This value is not a valid time.", "Verdien er ikkje ei gyldig tidseining.", "validators", "nn");
t.add("This value is not a valid URL.", "Verdien er ikkje ein gyldig URL.", "validators", "nn");
t.add("The two values should be equal.", "Dei to verdiane m\u00e5 vere like.", "validators", "nn");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Fila er for stor. Den maksimale storleiken er {{ limit }} {{ suffix }}.", "validators", "nn");
t.add("The file is too large.", "Fila er for stor.", "validators", "nn");
t.add("The file could not be uploaded.", "Fila kunne ikkje bli lasta opp.", "validators", "nn");
t.add("This value should be a valid number.", "Verdien m\u00e5 vere eit gyldig tal.", "validators", "nn");
t.add("This file is not a valid image.", "Fila er ikkje eit gyldig bilete.", "validators", "nn");
t.add("This is not a valid IP address.", "Dette er ikkje ei gyldig IP-adresse.", "validators", "nn");
t.add("This value is not a valid language.", "Verdien er ikkje eit gyldig spr\u00e5k.", "validators", "nn");
t.add("This value is not a valid locale.", "Verdien er ikkje ein gyldig lokalitet (spr\u00e5k\/region).", "validators", "nn");
t.add("This value is not a valid country.", "Verdien er ikkje eit gyldig land.", "validators", "nn");
t.add("This value is already used.", "Verdien er allereie i bruk.", "validators", "nn");
t.add("The size of the image could not be detected.", "Storleiken p\u00e5 biletet kunne ikkje oppdagast.", "validators", "nn");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Biletbreidda er for stor, ({{ width }} pikslar). Tillaten maksimumsbreidde er {{ max_width }} pikslar.", "validators", "nn");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Biletbreidda er for liten, ({{ width }} pikslar). Forventa minimumsbreidde er {{ min_width }} pikslar.", "validators", "nn");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Bileth\u00f8gda er for stor, ({{ height }} pikslar). Tillaten maksimumsh\u00f8gde er {{ max_height }} pikslar.", "validators", "nn");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Billeth\u00f8gda er for l\u00e5g, ({{ height }} pikslar). Forventa minimumsh\u00f8gde er {{ min_height }} pikslar.", "validators", "nn");
t.add("This value should be the user's current password.", "Verdien m\u00e5 vere brukaren sitt noverande passord.", "validators", "nn");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Verdien m\u00e5 vere n\u00f8yaktig {{ limit }} teikn.", "validators", "nn");
t.add("The file was only partially uploaded.", "Fila vart berre delvis lasta opp.", "validators", "nn");
t.add("No file was uploaded.", "Inga fil vart lasta opp.", "validators", "nn");
t.add("No temporary folder was configured in php.ini.", "F\u00f8rebels mappe (tmp) er ikkje konfigurert i php.ini.", "validators", "nn");
t.add("Cannot write temporary file to disk.", "Kan ikkje skrive f\u00f8rebels fil til disk.", "validators", "nn");
t.add("A PHP extension caused the upload to fail.", "Ei PHP-udviding for\u00e5rsaka feil under opplasting.", "validators", "nn");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Denne samlinga m\u00e5 innehalde {{ limit }} element eller meir.|Denne samlinga m\u00e5 innehalde {{ limit }} element eller meir.", "validators", "nn");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Denne samlinga m\u00e5 innehalde {{ limit }} element eller f\u00e6rre.|Denne samlinga m\u00e5 innehalde {{ limit }} element eller f\u00e6rre.", "validators", "nn");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Denne samlinga m\u00e5 innehalde n\u00f8yaktig {{ limit }} element.|Denne samlinga m\u00e5 innehalde n\u00f8yaktig {{ limit }} element.", "validators", "nn");
t.add("Invalid card number.", "Ugyldig kortnummer.", "validators", "nn");
t.add("Unsupported card type or invalid card number.", "Korttypen er ikkje st\u00f8tta, eller kortnummeret er ugyldig.", "validators", "nn");
t.add("This is not a valid International Bank Account Number (IBAN).", "Dette er ikkje eit gyldig internasjonalt bankkontonummer (IBAN).", "validators", "nn");
t.add("This value is not a valid ISBN-10.", "Verdien er ikkje eit gyldig ISBN-10.", "validators", "nn");
t.add("This value is not a valid ISBN-13.", "Verdien er ikkje eit gyldig ISBN-13.", "validators", "nn");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Verdien er verken eit gyldig ISBN-10 eller eit gyldig ISBN-13.", "validators", "nn");
t.add("This value is not a valid ISSN.", "Verdien er ikkje eit gyldig ISSN.", "validators", "nn");
t.add("This value is not a valid currency.", "Verdien er ikkje ein gyldig valuta.", "validators", "nn");
t.add("This value should be equal to {{ compared_value }}.", "Verdien b\u00f8r vera eins med {{ compared_value }}.", "validators", "nn");
t.add("This value should be greater than {{ compared_value }}.", "Verdien b\u00f8r vera st\u00f8rre enn {{ compared_value }}.", "validators", "nn");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Verdien b\u00f8r vera st\u00f8rre enn eller eins med {{ compared_value }}.", "validators", "nn");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Verdien b\u00f8r vera eins med {{ compared_value_type }} {{ compared_value }}.", "validators", "nn");
t.add("This value should be less than {{ compared_value }}.", "Verdien b\u00f8r vera mindre enn {{ compared_value }}.", "validators", "nn");
t.add("This value should be less than or equal to {{ compared_value }}.", "Verdi b\u00f8r vera mindre enn eller eins med {{ compared_value }}.", "validators", "nn");
t.add("This value should not be equal to {{ compared_value }}.", "Verdi b\u00f8r ikkje vera eins med {{ compared_value }}.", "validators", "nn");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Denne verdien b\u00f8r ikkje vera eins med {{ compared_value_type }} {{ compared_value }}.", "validators", "nn");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Sideforholdet til biletet er for stort ({{ ratio }}). Sideforholdet kan ikkje vere st\u00f8rre enn {{ max_ratio }}.", "validators", "nn");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Sideforholdet til biletet er for lite ({{ ratio }}). Sideforholdet kan ikkje vere mindre enn {{ min_ratio }}.", "validators", "nn");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Biletet er kvadratisk ({{ width }}x{{ height }}px). Kvadratiske bilete er ikkje tillatne.", "validators", "nn");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Biletet er landskapsorientert ({{ width }}x{{ height }}px). Landskapsorienterte bilete er ikkje tillatne.", "validators", "nn");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Biletet er portrettorientert ({{ width }}x{{ height }}px). Portrettorienterte bilete er ikkje tillatne.", "validators", "nn");
t.add("An empty file is not allowed.", "Ei tom fil er ikkje tillate.", "validators", "nn");
t.add("The host could not be resolved.", "Verten kunne ikkje finnast.", "validators", "nn");
t.add("This value does not match the expected {{ charset }} charset.", "Verdien stemmer ikkje med forventa {{ charset }} charset.", "validators", "nn");
t.add("This is not a valid Business Identifier Code (BIC).", "Dette er ikkje ein gyldig Business Identifier Code (BIC).", "validators", "nn");
t.add("Error", "Feil", "validators", "nn");
t.add("This is not a valid UUID.", "Dette er ikkje ein gyldig UUID.", "validators", "nn");
t.add("This value should be a multiple of {{ compared_value }}.", "Verdien b\u00f8r vera eit multipel av {{ compared_value }}.", "validators", "nn");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Denne Business Identifier Code (BIC) er ikkje kopla til IBAN {{ iban }}.", "validators", "nn");
t.add("This value should be valid JSON.", "Verdien b\u00f8r vera gyldig JSON.", "validators", "nn");
t.add("This collection should contain only unique elements.", "Denne samlinga b\u00f8r berre innehalda unike element.", "validators", "nn");
t.add("This value should be positive.", "Verdien b\u00f8r vera positiv.", "validators", "nn");
t.add("This value should be either positive or zero.", "Verdien b\u00f8r vera anten positiv eller null.", "validators", "nn");
t.add("This value should be negative.", "Verdien b\u00f8r vera negativ.", "validators", "nn");
t.add("This value should be either negative or zero.", "Verdien b\u00f8r vera negativ eller null.", "validators", "nn");
t.add("This value is not a valid timezone.", "Verdien er ikkje ei gyldig tidssone.", "validators", "nn");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Dette passordet har lekt ut ved eit datainnbrot, det f\u00e5r ikkje nyttast. Gje opp eit anna passord.", "validators", "nn");
t.add("This value should be between {{ min }} and {{ max }}.", "Denne verdien b\u00f8r liggje mellom {{ min }} og {{ max }}.", "validators", "nn");
t.add("This value is not a valid hostname.", "Verdien er ikkje eit gyldig vertsnamn.", "validators", "nn");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Talet p\u00e5 element i denne samlinga b\u00f8r vera eit multippel av {{ compared_value }}.", "validators", "nn");
t.add("This value should satisfy at least one of the following constraints:", "Verdien burde oppfylla minst ein av f\u00f8lgjande avgrensingar:", "validators", "nn");
t.add("Each element of this collection should satisfy its own set of constraints.", "Kvart element i denne samlinga b\u00f8r oppfylla sine eigne avgrensingar.", "validators", "nn");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Verdien er ikkje eit gyldig International Securities Identification Number (ISIN).", "validators", "nn");
t.add("This form should not contain extra fields.", "Feltgruppa kan ikkje innehalde ekstra felt.", "validators", "nn");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Fila du lasta opp var for stor. Last opp ei mindre fil.", "validators", "nn");
t.add("The CSRF token is invalid.", "CSRF-n\u00f8kkelen er ikkje gyldig.", "validators", "nn");
t.add("This value is not a valid HTML5 color.", "Verdien er ikkje ein gyldig HTML5-farge.", "validators", "nn");
t.add("Please enter a valid birthdate.", "Gje opp ein gyldig f\u00f8dselsdato.", "validators", "nn");
t.add("The selected choice is invalid.", "Valget du gjorde er ikkje gyldig.", "validators", "nn");
t.add("The collection is invalid.", "Samlinga er ikkje gyldig.", "validators", "nn");
t.add("Please select a valid color.", "Gje opp ein gyldig farge.", "validators", "nn");
t.add("Please select a valid country.", "Gje opp eit gyldig land.", "validators", "nn");
t.add("Please select a valid currency.", "Gje opp ein gyldig valuta.", "validators", "nn");
t.add("Please choose a valid date interval.", "Gje opp eit gyldig datointervall.", "validators", "nn");
t.add("Please enter a valid date and time.", "Gje opp ein gyldig dato og tid.", "validators", "nn");
t.add("Please enter a valid date.", "Gje opp ein gyldig dato.", "validators", "nn");
t.add("Please select a valid file.", "Velg ei gyldig fil.", "validators", "nn");
t.add("The hidden field is invalid.", "Det skjulte feltet er ikkje gyldig.", "validators", "nn");
t.add("Please enter an integer.", "Gje opp eit heiltal.", "validators", "nn");
t.add("Please select a valid language.", "Gje opp eit gyldig spr\u00e5k.", "validators", "nn");
t.add("Please select a valid locale.", "Gje opp eit gyldig locale.", "validators", "nn");
t.add("Please enter a valid money amount.", "Gje opp ein gyldig sum pengar.", "validators", "nn");
t.add("Please enter a number.", "Gje opp eit nummer.", "validators", "nn");
t.add("The password is invalid.", "Passordet er ikkje gyldig.", "validators", "nn");
t.add("Please enter a percentage value.", "Gje opp ein prosentverdi.", "validators", "nn");
t.add("The values do not match.", "Verdiane er ikkje eins.", "validators", "nn");
t.add("Please enter a valid time.", "Gje opp ei gyldig tid.", "validators", "nn");
t.add("Please select a valid timezone.", "Gje opp ei gyldig tidssone.", "validators", "nn");
t.add("Please enter a valid URL.", "Gje opp ein gyldig URL.", "validators", "nn");
t.add("Please enter a valid search term.", "Gje opp gyldige s\u00f8kjeord.", "validators", "nn");
t.add("Please provide a valid phone number.", "Gje opp eit gyldig telefonnummer.", "validators", "nn");
t.add("The checkbox has an invalid value.", "Sjekkboksen har ein ugyldig verdi.", "validators", "nn");
t.add("Please enter a valid email address.", "Gje opp ei gyldig e-postadresse.", "validators", "nn");
t.add("Please select a valid option.", "Velg eit gyldig vilk\u00e5r.", "validators", "nn");
t.add("Please select a valid range.", "Velg eit gyldig spenn.", "validators", "nn");
t.add("Please enter a valid week.", "Gje opp ei gyldig veke.", "validators", "nn");
})(Translator);
