(function (t) {
// fr
t.add("This value should be false.", "Cette valeur doit \u00eatre fausse.", "validators", "fr");
t.add("This value should be true.", "Cette valeur doit \u00eatre vraie.", "validators", "fr");
t.add("This value should be of type {{ type }}.", "Cette valeur doit \u00eatre de type {{ type }}.", "validators", "fr");
t.add("This value should be blank.", "Cette valeur doit \u00eatre vide.", "validators", "fr");
t.add("The value you selected is not a valid choice.", "Cette valeur doit \u00eatre l'un des choix propos\u00e9s.", "validators", "fr");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Vous devez s\u00e9lectionner au moins {{ limit }} choix.|Vous devez s\u00e9lectionner au moins {{ limit }} choix.", "validators", "fr");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Vous devez s\u00e9lectionner au maximum {{ limit }} choix.|Vous devez s\u00e9lectionner au maximum {{ limit }} choix.", "validators", "fr");
t.add("One or more of the given values is invalid.", "Une ou plusieurs des valeurs soumises sont invalides.", "validators", "fr");
t.add("This field was not expected.", "Ce champ n'a pas \u00e9t\u00e9 pr\u00e9vu.", "validators", "fr");
t.add("This field is missing.", "Ce champ est manquant.", "validators", "fr");
t.add("This value is not a valid date.", "Cette valeur n'est pas une date valide.", "validators", "fr");
t.add("This value is not a valid datetime.", "Cette valeur n'est pas une date valide.", "validators", "fr");
t.add("This value is not a valid email address.", "Cette valeur n'est pas une adresse email valide.", "validators", "fr");
t.add("The file could not be found.", "Le fichier n'a pas \u00e9t\u00e9 trouv\u00e9.", "validators", "fr");
t.add("The file is not readable.", "Le fichier n'est pas lisible.", "validators", "fr");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Le fichier est trop volumineux ({{ size }} {{ suffix }}). Sa taille ne doit pas d\u00e9passer {{ limit }} {{ suffix }}.", "validators", "fr");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Le type du fichier est invalide ({{ type }}). Les types autoris\u00e9s sont {{ types }}.", "validators", "fr");
t.add("This value should be {{ limit }} or less.", "Cette valeur doit \u00eatre inf\u00e9rieure ou \u00e9gale \u00e0 {{ limit }}.", "validators", "fr");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Cette cha\u00eene est trop longue. Elle doit avoir au maximum {{ limit }} caract\u00e8re.|Cette cha\u00eene est trop longue. Elle doit avoir au maximum {{ limit }} caract\u00e8res.", "validators", "fr");
t.add("This value should be {{ limit }} or more.", "Cette valeur doit \u00eatre sup\u00e9rieure ou \u00e9gale \u00e0 {{ limit }}.", "validators", "fr");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Cette cha\u00eene est trop courte. Elle doit avoir au minimum {{ limit }} caract\u00e8re.|Cette cha\u00eene est trop courte. Elle doit avoir au minimum {{ limit }} caract\u00e8res.", "validators", "fr");
t.add("This value should not be blank.", "Cette valeur ne doit pas \u00eatre vide.", "validators", "fr");
t.add("This value should not be null.", "Cette valeur ne doit pas \u00eatre nulle.", "validators", "fr");
t.add("This value should be null.", "Cette valeur doit \u00eatre nulle.", "validators", "fr");
t.add("This value is not valid.", "Cette valeur n'est pas valide.", "validators", "fr");
t.add("This value is not a valid time.", "Cette valeur n'est pas une heure valide.", "validators", "fr");
t.add("This value is not a valid URL.", "Cette valeur n'est pas une URL valide.", "validators", "fr");
t.add("The two values should be equal.", "Les deux valeurs doivent \u00eatre identiques.", "validators", "fr");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Le fichier est trop volumineux. Sa taille ne doit pas d\u00e9passer {{ limit }} {{ suffix }}.", "validators", "fr");
t.add("The file is too large.", "Le fichier est trop volumineux.", "validators", "fr");
t.add("The file could not be uploaded.", "Le t\u00e9l\u00e9chargement de ce fichier est impossible.", "validators", "fr");
t.add("This value should be a valid number.", "Cette valeur doit \u00eatre un nombre.", "validators", "fr");
t.add("This file is not a valid image.", "Ce fichier n'est pas une image valide.", "validators", "fr");
t.add("This is not a valid IP address.", "Cette adresse IP n'est pas valide.", "validators", "fr");
t.add("This value is not a valid language.", "Cette langue n'est pas valide.", "validators", "fr");
t.add("This value is not a valid locale.", "Ce param\u00e8tre r\u00e9gional n'est pas valide.", "validators", "fr");
t.add("This value is not a valid country.", "Ce pays n'est pas valide.", "validators", "fr");
t.add("This value is already used.", "Cette valeur est d\u00e9j\u00e0 utilis\u00e9e.", "validators", "fr");
t.add("The size of the image could not be detected.", "La taille de l'image n'a pas pu \u00eatre d\u00e9tect\u00e9e.", "validators", "fr");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "La largeur de l'image est trop grande ({{ width }}px). La largeur maximale autoris\u00e9e est de {{ max_width }}px.", "validators", "fr");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "La largeur de l'image est trop petite ({{ width }}px). La largeur minimale attendue est de {{ min_width }}px.", "validators", "fr");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "La hauteur de l'image est trop grande ({{ height }}px). La hauteur maximale autoris\u00e9e est de {{ max_height }}px.", "validators", "fr");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "La hauteur de l'image est trop petite ({{ height }}px). La hauteur minimale attendue est de {{ min_height }}px.", "validators", "fr");
t.add("This value should be the user's current password.", "Cette valeur doit \u00eatre le mot de passe actuel de l'utilisateur.", "validators", "fr");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Cette cha\u00eene doit avoir exactement {{ limit }} caract\u00e8re.|Cette cha\u00eene doit avoir exactement {{ limit }} caract\u00e8res.", "validators", "fr");
t.add("The file was only partially uploaded.", "Le fichier a \u00e9t\u00e9 partiellement transf\u00e9r\u00e9.", "validators", "fr");
t.add("No file was uploaded.", "Aucun fichier n'a \u00e9t\u00e9 transf\u00e9r\u00e9.", "validators", "fr");
t.add("No temporary folder was configured in php.ini.", "Aucun r\u00e9pertoire temporaire n'a \u00e9t\u00e9 configur\u00e9 dans le php.ini, ou le r\u00e9pertoire configur\u00e9 n'existe pas.", "validators", "fr");
t.add("Cannot write temporary file to disk.", "Impossible d'\u00e9crire le fichier temporaire sur le disque.", "validators", "fr");
t.add("A PHP extension caused the upload to fail.", "Une extension PHP a emp\u00each\u00e9 le transfert du fichier.", "validators", "fr");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Cette collection doit contenir {{ limit }} \u00e9l\u00e9ment ou plus.|Cette collection doit contenir {{ limit }} \u00e9l\u00e9ments ou plus.", "validators", "fr");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Cette collection doit contenir {{ limit }} \u00e9l\u00e9ment ou moins.|Cette collection doit contenir {{ limit }} \u00e9l\u00e9ments ou moins.", "validators", "fr");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Cette collection doit contenir exactement {{ limit }} \u00e9l\u00e9ment.|Cette collection doit contenir exactement {{ limit }} \u00e9l\u00e9ments.", "validators", "fr");
t.add("Invalid card number.", "Num\u00e9ro de carte invalide.", "validators", "fr");
t.add("Unsupported card type or invalid card number.", "Type de carte non support\u00e9 ou num\u00e9ro invalide.", "validators", "fr");
t.add("This is not a valid International Bank Account Number (IBAN).", "Le num\u00e9ro IBAN (International Bank Account Number) saisi n'est pas valide.", "validators", "fr");
t.add("This value is not a valid ISBN-10.", "Cette valeur n'est pas un code ISBN-10 valide.", "validators", "fr");
t.add("This value is not a valid ISBN-13.", "Cette valeur n'est pas un code ISBN-13 valide.", "validators", "fr");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Cette valeur n'est ni un code ISBN-10, ni un code ISBN-13 valide.", "validators", "fr");
t.add("This value is not a valid ISSN.", "Cette valeur n'est pas un code ISSN valide.", "validators", "fr");
t.add("This value is not a valid currency.", "Cette valeur n'est pas une devise valide.", "validators", "fr");
t.add("This value should be equal to {{ compared_value }}.", "Cette valeur doit \u00eatre \u00e9gale \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should be greater than {{ compared_value }}.", "Cette valeur doit \u00eatre sup\u00e9rieure \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Cette valeur doit \u00eatre sup\u00e9rieure ou \u00e9gale \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Cette valeur doit \u00eatre identique \u00e0 {{ compared_value_type }} {{ compared_value }}.", "validators", "fr");
t.add("This value should be less than {{ compared_value }}.", "Cette valeur doit \u00eatre inf\u00e9rieure \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should be less than or equal to {{ compared_value }}.", "Cette valeur doit \u00eatre inf\u00e9rieure ou \u00e9gale \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should not be equal to {{ compared_value }}.", "Cette valeur ne doit pas \u00eatre \u00e9gale \u00e0 {{ compared_value }}.", "validators", "fr");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Cette valeur ne doit pas \u00eatre identique \u00e0 {{ compared_value_type }} {{ compared_value }}.", "validators", "fr");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Le rapport largeur\/hauteur de l'image est trop grand ({{ ratio }}). Le rapport maximal autoris\u00e9 est {{ max_ratio }}.", "validators", "fr");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Le rapport largeur\/hauteur de l'image est trop petit ({{ ratio }}). Le rapport minimal attendu est {{ min_ratio }}.", "validators", "fr");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "L'image est carr\u00e9e ({{ width }}x{{ height }}px). Les images carr\u00e9es ne sont pas autoris\u00e9es.", "validators", "fr");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "L'image est au format paysage ({{ width }}x{{ height }}px). Les images au format paysage ne sont pas autoris\u00e9es.", "validators", "fr");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "L'image est au format portrait ({{ width }}x{{ height }}px). Les images au format portrait ne sont pas autoris\u00e9es.", "validators", "fr");
t.add("An empty file is not allowed.", "Un fichier vide n'est pas autoris\u00e9.", "validators", "fr");
t.add("The host could not be resolved.", "Le nom de domaine n'a pas pu \u00eatre r\u00e9solu.", "validators", "fr");
t.add("This value does not match the expected {{ charset }} charset.", "Cette valeur ne correspond pas au jeu de caract\u00e8res {{ charset }} attendu.", "validators", "fr");
t.add("This is not a valid Business Identifier Code (BIC).", "Ce n'est pas un code universel d'identification des banques (BIC) valide.", "validators", "fr");
t.add("Error", "Erreur", "validators", "fr");
t.add("This is not a valid UUID.", "Ceci n'est pas un UUID valide.", "validators", "fr");
t.add("This value should be a multiple of {{ compared_value }}.", "Cette valeur doit \u00eatre un multiple de {{ compared_value }}.", "validators", "fr");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Ce code d'identification d'entreprise (BIC) n'est pas associ\u00e9 \u00e0 l'IBAN {{ iban }}.", "validators", "fr");
t.add("This value should be valid JSON.", "Cette valeur doit \u00eatre un JSON valide.", "validators", "fr");
t.add("This collection should contain only unique elements.", "Cette collection ne doit pas comporter de doublons.", "validators", "fr");
t.add("This value should be positive.", "Cette valeur doit \u00eatre strictement positive.", "validators", "fr");
t.add("This value should be either positive or zero.", "Cette valeur doit \u00eatre sup\u00e9rieure ou \u00e9gale \u00e0 z\u00e9ro.", "validators", "fr");
t.add("This value should be negative.", "Cette valeur doit \u00eatre strictement n\u00e9gative.", "validators", "fr");
t.add("This value should be either negative or zero.", "Cette valeur doit \u00eatre inf\u00e9rieure ou \u00e9gale \u00e0 z\u00e9ro.", "validators", "fr");
t.add("This value is not a valid timezone.", "Cette valeur n'est pas un fuseau horaire valide.", "validators", "fr");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Ce mot de passe a \u00e9t\u00e9 divulgu\u00e9 lors d'une fuite de donn\u00e9es, il ne doit plus \u00eatre utilis\u00e9. Veuillez utiliser un autre mot de passe.", "validators", "fr");
t.add("This value should be between {{ min }} and {{ max }}.", "Cette valeur doit \u00eatre comprise entre {{ min }} et {{ max }}.", "validators", "fr");
t.add("This value is not a valid hostname.", "Cette valeur n'est pas un nom d'h\u00f4te valide.", "validators", "fr");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Le nombre d'\u00e9l\u00e9ments de cette collection doit \u00eatre un multiple de {{ compared_value }}.", "validators", "fr");
t.add("This value should satisfy at least one of the following constraints:", "Cette valeur doit satisfaire \u00e0 au moins une des contraintes suivantes :", "validators", "fr");
t.add("Each element of this collection should satisfy its own set of constraints.", "Chaque \u00e9l\u00e9ment de cette collection doit satisfaire \u00e0 son propre jeu de contraintes.", "validators", "fr");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Cette valeur n'est pas un code international de s\u00e9curit\u00e9 valide (ISIN).", "validators", "fr");
t.add("This value should be a valid expression.", "Cette valeur doit \u00eatre une expression valide.", "validators", "fr");
t.add("This value is not a valid CSS color.", "Cette valeur n'est pas une couleur CSS valide.", "validators", "fr");
t.add("This value is not a valid CIDR notation.", "Cette valeur n'est pas une notation CIDR valide.", "validators", "fr");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "La valeur du masque de r\u00e9seau doit \u00eatre comprise entre {{ min }} et {{ max }}.", "validators", "fr");
t.add("This form should not contain extra fields.", "Ce formulaire ne doit pas contenir de champs suppl\u00e9mentaires.", "validators", "fr");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Le fichier t\u00e9l\u00e9charg\u00e9 est trop volumineux. Merci d'essayer d'envoyer un fichier plus petit.", "validators", "fr");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "Le jeton CSRF est invalide. Veuillez renvoyer le formulaire.", "validators", "fr");
t.add("This value is not a valid HTML5 color.", "Cette valeur n'est pas une couleur HTML5 valide.", "validators", "fr");
t.add("Please enter a valid birthdate.", "Veuillez entrer une date de naissance valide.", "validators", "fr");
t.add("The selected choice is invalid.", "Le choix s\u00e9lectionn\u00e9 est invalide.", "validators", "fr");
t.add("The collection is invalid.", "La collection est invalide.", "validators", "fr");
t.add("Please select a valid color.", "Veuillez s\u00e9lectionner une couleur valide.", "validators", "fr");
t.add("Please select a valid country.", "Veuillez s\u00e9lectionner un pays valide.", "validators", "fr");
t.add("Please select a valid currency.", "Veuillez s\u00e9lectionner une devise valide.", "validators", "fr");
t.add("Please choose a valid date interval.", "Veuillez choisir un intervalle de dates valide.", "validators", "fr");
t.add("Please enter a valid date and time.", "Veuillez saisir une date et une heure valides.", "validators", "fr");
t.add("Please enter a valid date.", "Veuillez entrer une date valide.", "validators", "fr");
t.add("Please select a valid file.", "Veuillez s\u00e9lectionner un fichier valide.", "validators", "fr");
t.add("The hidden field is invalid.", "Le champ masqu\u00e9 n'est pas valide.", "validators", "fr");
t.add("Please enter an integer.", "Veuillez saisir un entier.", "validators", "fr");
t.add("Please select a valid language.", "Veuillez s\u00e9lectionner une langue valide.", "validators", "fr");
t.add("Please select a valid locale.", "Veuillez s\u00e9lectionner une langue valide.", "validators", "fr");
t.add("Please enter a valid money amount.", "Veuillez saisir un montant valide.", "validators", "fr");
t.add("Please enter a number.", "Veuillez saisir un nombre.", "validators", "fr");
t.add("The password is invalid.", "Le mot de passe est invalide.", "validators", "fr");
t.add("Please enter a percentage value.", "Veuillez saisir un pourcentage valide.", "validators", "fr");
t.add("The values do not match.", "Les valeurs ne correspondent pas.", "validators", "fr");
t.add("Please enter a valid time.", "Veuillez saisir une heure valide.", "validators", "fr");
t.add("Please select a valid timezone.", "Veuillez s\u00e9lectionner un fuseau horaire valide.", "validators", "fr");
t.add("Please enter a valid URL.", "Veuillez saisir une URL valide.", "validators", "fr");
t.add("Please enter a valid search term.", "Veuillez saisir un terme de recherche valide.", "validators", "fr");
t.add("Please provide a valid phone number.", "Veuillez fournir un num\u00e9ro de t\u00e9l\u00e9phone valide.", "validators", "fr");
t.add("The checkbox has an invalid value.", "La case \u00e0 cocher a une valeur non valide.", "validators", "fr");
t.add("Please enter a valid email address.", "Veuillez saisir une adresse email valide.", "validators", "fr");
t.add("Please select a valid option.", "Veuillez s\u00e9lectionner une option valide.", "validators", "fr");
t.add("Please select a valid range.", "Veuillez s\u00e9lectionner une plage valide.", "validators", "fr");
t.add("Please enter a valid week.", "Veuillez entrer une semaine valide.", "validators", "fr");
t.add("course_document.invalid_pdf", "Merci de t\u00e9l\u00e9charger un fichier PDF valide", "validators", "fr");
t.add("validation.knee.injured_side.required", "Le champ \"C\u00f4t\u00e9 l\u00e9s\u00e9\" de l'onglet Genou doit \u00eatre renseign\u00e9 sur le profil de ce client avant d'aller plus loin", "validators", "fr");
t.add("validation.weight.required", "Le champ \"Poids\" de l'onglet g\u00e9n\u00e9ral doit \u00eatre renseign\u00e9 sur le profil de ce client avant d'aller plus loin", "validators", "fr");
t.add("validation.height.required", "Le champ \"Taille\" de l'onglet G\u00e9n\u00e9ral doit \u00eatre renseign\u00e9 sur le profil de ce client avant d'aller plus loin", "validators", "fr");
t.add("validation.gender.required", "Le champ \"Sexe\" de l'onglet G\u00e9n\u00e9ral doit \u00eatre renseign\u00e9 sur le profil de ce client avant d'aller plus loin", "validators", "fr");
t.add("validation.activity.required", "Le champ 'Fr\u00e9quence d'activit\u00e9' de l'onglet G\u00e9n\u00e9ral doit \u00eatre renseign\u00e9 sur le profil de ce client avant d'aller plus loin", "validators", "fr");
t.add("validation.shoulder.left_c7_major.required", "Le champ \"C7 Major gauche\" de l'onglet \u00c9paule doit \u00eatre renseign\u00e9 sur le profil de ce client avant d'aller plus loin", "validators", "fr");
t.add("validation.shoulder.right_c7_major.required", "Le champ \"C7 Major droite\" de l'onglet \u00c9paule doit \u00eatre renseign\u00e9 sur le profil de ce client avant d'aller plus loin", "validators", "fr");
t.add("validation.upper_handedness.required", "Le champ \"Membre sup\u00e9rieur dominant\" de l'onglet \u00c9paule doit \u00eatre renseign\u00e9 sur le profil de ce client avant d'aller plus loin", "validators", "fr");
t.add("validation.shoulder.injured_side.required", "Le champ \"C\u00f4t\u00e9 l\u00e9s\u00e9\" de l'onglet \u00c9paule doit \u00eatre renseign\u00e9 sur le profil de ce client avant d'aller plus loin", "validators", "fr");
t.add("link.invalid_youtube", "Merci de choisir un lien Youtube valide", "validators", "fr");
})(Translator);
