(function (t) {
// da
t.add("This value should be false.", "V\u00e6rdien skal v\u00e6re falsk.", "validators", "da");
t.add("This value should be true.", "V\u00e6rdien skal v\u00e6re sand.", "validators", "da");
t.add("This value should be of type {{ type }}.", "V\u00e6rdien skal v\u00e6re af typen {{ type }}.", "validators", "da");
t.add("This value should be blank.", "V\u00e6rdien skal v\u00e6re blank.", "validators", "da");
t.add("The value you selected is not a valid choice.", "Den valgte v\u00e6rdi er ikke gyldig.", "validators", "da");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Du skal v\u00e6lge mindst \u00e9n mulighed.|Du skal v\u00e6lge mindst {{ limit }} muligheder.", "validators", "da");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Du kan h\u00f8jst v\u00e6lge \u00e9n mulighed.|Du kan h\u00f8jst v\u00e6lge {{ limit }} muligheder.", "validators", "da");
t.add("One or more of the given values is invalid.", "En eller flere af de angivne v\u00e6rdier er ugyldige.", "validators", "da");
t.add("This field was not expected.", "Feltet blev ikke forventet.", "validators", "da");
t.add("This field is missing.", "Dette felt mangler.", "validators", "da");
t.add("This value is not a valid date.", "V\u00e6rdien er ikke en gyldig dato.", "validators", "da");
t.add("This value is not a valid datetime.", "V\u00e6rdien er ikke et gyldigt tidspunkt.", "validators", "da");
t.add("This value is not a valid email address.", "V\u00e6rdien er ikke en gyldig e-mailadresse.", "validators", "da");
t.add("The file could not be found.", "Filen kunne ikke findes.", "validators", "da");
t.add("The file is not readable.", "Filen kan ikke l\u00e6ses.", "validators", "da");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Filen er for stor ({{ size }} {{ suffix }}). Maksimale tilladte st\u00f8rrelse er {{ limit }} {{ suffix }}.", "validators", "da");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Filens MIME-type er ugyldig ({{ type }}). Tilladte MIME-typer er {{ types }}.", "validators", "da");
t.add("This value should be {{ limit }} or less.", "V\u00e6rdien skal v\u00e6re {{ limit }} eller mindre.", "validators", "da");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "V\u00e6rdien er for lang. Den m\u00e5 h\u00f8jst indeholde {{ limit }} tegn.", "validators", "da");
t.add("This value should be {{ limit }} or more.", "V\u00e6rdien skal v\u00e6re {{ limit }} eller mere.", "validators", "da");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "V\u00e6rdien er for kort. Den skal indeholde mindst {{ limit }} tegn.", "validators", "da");
t.add("This value should not be blank.", "V\u00e6rdien m\u00e5 ikke v\u00e6re blank.", "validators", "da");
t.add("This value should not be null.", "V\u00e6rdien m\u00e5 ikke v\u00e6re tom (null).", "validators", "da");
t.add("This value should be null.", "V\u00e6rdien skal v\u00e6re tom (null).", "validators", "da");
t.add("This value is not valid.", "V\u00e6rdien er ikke gyldig.", "validators", "da");
t.add("This value is not a valid time.", "V\u00e6rdien er ikke et gyldigt klokkesl\u00e6t.", "validators", "da");
t.add("This value is not a valid URL.", "V\u00e6rdien er ikke en gyldig URL.", "validators", "da");
t.add("The two values should be equal.", "De to v\u00e6rdier skal v\u00e6re ens.", "validators", "da");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Filen er for stor. Den maksimale st\u00f8rrelse er {{ limit }} {{ suffix }}.", "validators", "da");
t.add("The file is too large.", "Filen er for stor.", "validators", "da");
t.add("The file could not be uploaded.", "Filen kunne ikke uploades.", "validators", "da");
t.add("This value should be a valid number.", "V\u00e6rdien skal v\u00e6re et gyldigt tal.", "validators", "da");
t.add("This file is not a valid image.", "Filen er ikke gyldigt billede.", "validators", "da");
t.add("This is not a valid IP address.", "Dette er ikke en gyldig IP-adresse.", "validators", "da");
t.add("This value is not a valid language.", "V\u00e6rdien er ikke et gyldigt sprog.", "validators", "da");
t.add("This value is not a valid locale.", "V\u00e6rdien er ikke en gyldig lokalitet.", "validators", "da");
t.add("This value is not a valid country.", "V\u00e6rdien er ikke et gyldigt land.", "validators", "da");
t.add("This value is already used.", "V\u00e6rdien er allerede i brug.", "validators", "da");
t.add("The size of the image could not be detected.", "St\u00f8rrelsen p\u00e5 billedet kunne ikke detekteres.", "validators", "da");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Billedet er for bredt ({{ width }}px). St\u00f8rste tilladte bredde er {{ max_width }}px.", "validators", "da");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Billedet er for smalt ({{ width }}px). Mindste forventede bredde er {{ min_width }}px.", "validators", "da");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Billedet er for h\u00f8jt ({{ height }}px). St\u00f8rste tilladte h\u00f8jde er {{ max_height }}px.", "validators", "da");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Billedet er for lavt ({{ height }}px). Mindste forventede h\u00f8jde er {{ min_height }}px.", "validators", "da");
t.add("This value should be the user's current password.", "V\u00e6rdien skal v\u00e6re brugerens nuv\u00e6rende adgangskode.", "validators", "da");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "V\u00e6rdien skal v\u00e6re p\u00e5 pr\u00e6cis {{ limit }} tegn.", "validators", "da");
t.add("The file was only partially uploaded.", "Filen blev kun delvist uploadet.", "validators", "da");
t.add("No file was uploaded.", "Ingen fil blev uploadet.", "validators", "da");
t.add("No temporary folder was configured in php.ini.", "Ingen midlertidig mappe er konfigureret i php.ini.", "validators", "da");
t.add("Cannot write temporary file to disk.", "Kan ikke skrive midlertidig fil til disk.", "validators", "da");
t.add("A PHP extension caused the upload to fail.", "En PHP-udvidelse for\u00e5rsagede fejl i upload.", "validators", "da");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Denne samling skal indeholde mindst \u00e9t element.|Denne samling skal indeholde mindst {{ limit }} elementer.", "validators", "da");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Denne samling skal indeholde h\u00f8jst \u00e9t element.|Denne samling skal indeholde h\u00f8jst {{ limit }} elementer.", "validators", "da");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Denne samling skal indeholde pr\u00e6cis \u00e9t element.|Denne samling skal indeholde pr\u00e6cis {{ limit }} elementer.", "validators", "da");
t.add("Invalid card number.", "Ugyldigt kortnummer.", "validators", "da");
t.add("Unsupported card type or invalid card number.", "Ikke-underst\u00f8ttet korttype eller ugyldigt kortnummer.", "validators", "da");
t.add("This is not a valid International Bank Account Number (IBAN).", "Det er ikke et gyldigt International Bank Account Number (IBAN).", "validators", "da");
t.add("This value is not a valid ISBN-10.", "V\u00e6rdien er ikke en gyldig ISBN-10.", "validators", "da");
t.add("This value is not a valid ISBN-13.", "V\u00e6rdien er ikke en gyldig ISBN-13.", "validators", "da");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "V\u00e6rdien er hverken en gyldig ISBN-10 eller en gyldig ISBN-13.", "validators", "da");
t.add("This value is not a valid ISSN.", "V\u00e6rdien er ikke en gyldig ISSN.", "validators", "da");
t.add("This value is not a valid currency.", "Denne v\u00e6rdi er ikke en gyldig valuta.", "validators", "da");
t.add("This value should be equal to {{ compared_value }}.", "Denne v\u00e6rdi skal v\u00e6re lig med {{ compared_value }}.", "validators", "da");
t.add("This value should be greater than {{ compared_value }}.", "Denne v\u00e6rdi skal v\u00e6re st\u00f8rre end {{ compared_value }}.", "validators", "da");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Denne v\u00e6rdi skal v\u00e6re st\u00f8rre end eller lig med {{ compared_value }}.", "validators", "da");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Denne v\u00e6rdi skal v\u00e6re identisk med {{ compared_value_type }} {{ compared_value }}.", "validators", "da");
t.add("This value should be less than {{ compared_value }}.", "Denne v\u00e6rdi skal v\u00e6re mindre end {{ compared_value }}.", "validators", "da");
t.add("This value should be less than or equal to {{ compared_value }}.", "Denne v\u00e6rdi skal v\u00e6re mindre end eller lig med {{ compared_value }}.", "validators", "da");
t.add("This value should not be equal to {{ compared_value }}.", "Denne v\u00e6rdi b\u00f8r ikke v\u00e6re lig med {{ compared_value }}.", "validators", "da");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Denne v\u00e6rdi b\u00f8r ikke v\u00e6re identisk med {{ compared_value_type }} {{ compared_value }}.", "validators", "da");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Billedforholdet er for stort ({{ratio}}). Tilladt maksimumsforhold er {{ max_ratio }}.", "validators", "da");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Billedforholdet er for lille ({{ ratio }}). Minimumsforventet forventet er {{ min_ratio }}.", "validators", "da");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Billedet er firkantet ({{ width }} x {{ height }} px). Firkantede billeder er ikke tilladt.", "validators", "da");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Billedet er landskabsorienteret ({{width}} x {{height}} px). Landskabsorienterede billeder er ikke tilladt", "validators", "da");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Billedet er portr\u00e6torienteret ({{ width }}x{{ height }}px). Portr\u00e6torienterede billeder er ikke tilladt.", "validators", "da");
t.add("An empty file is not allowed.", "En tom fil er ikke tilladt.", "validators", "da");
t.add("The host could not be resolved.", "V\u00e6rten kunne ikke l\u00f8ses.", "validators", "da");
t.add("This value does not match the expected {{ charset }} charset.", "Denne v\u00e6rdi stemmer ikke overens med den forventede {{ charset }} charset.", "validators", "da");
t.add("This is not a valid Business Identifier Code (BIC).", "Dette er ikke en gyldig Business Identifier Code (BIC).a", "validators", "da");
t.add("Error", "Fejl", "validators", "da");
t.add("This is not a valid UUID.", "Dette er ikke en gyldig UUID.", "validators", "da");
t.add("This value should be a multiple of {{ compared_value }}.", "Denne v\u00e6rdi skal v\u00e6re et multiplikation af {{ compared_value }}.", "validators", "da");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Denne Business Identifier Code (BIC) er ikke forbundet med IBAN {{ iban }}.", "validators", "da");
t.add("This value should be valid JSON.", "Denne v\u00e6rdi skal v\u00e6re gyldig JSON.", "validators", "da");
t.add("This collection should contain only unique elements.", "Denne samling b\u00f8r kun indeholde unikke elementer.", "validators", "da");
t.add("This value should be positive.", "Denne v\u00e6rdi skal v\u00e6re positiv.", "validators", "da");
t.add("This value should be either positive or zero.", "Denne v\u00e6rdi skal v\u00e6re enten positiv eller nul.", "validators", "da");
t.add("This value should be negative.", "Denne v\u00e6rdi skal v\u00e6re negativ.", "validators", "da");
t.add("This value should be either negative or zero.", "Denne v\u00e6rdi skal v\u00e6re enten negativ eller nul.", "validators", "da");
t.add("This value is not a valid timezone.", "Denne v\u00e6rdi er ikke en gyldig tidszone.", "validators", "da");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Denne adgangskode er blevet l\u00e6kket i et databrud, det m\u00e5 ikke bruges. Brug venligst en anden adgangskode.", "validators", "da");
t.add("This value should be between {{ min }} and {{ max }}.", "V\u00e6rdien skal v\u00e6re mellem {{ min }} og {{ max }}.", "validators", "da");
t.add("This value is not a valid hostname.", "V\u00e6rdien er ikke et gyldigt v\u00e6rtsnavn.", "validators", "da");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Antallet af elementer i denne samling skal v\u00e6re en multiplikation af {{ compared_value }}.", "validators", "da");
t.add("This value should satisfy at least one of the following constraints:", "V\u00e6rdien skal overholde mindst \u00e9n af f\u00f8lgende krav:", "validators", "da");
t.add("Each element of this collection should satisfy its own set of constraints.", "Hvert element i denne samling skal overholde dens egne krav.", "validators", "da");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "V\u00e6rdien er ikke et gyldig International Securities Identification Number (ISIN).", "validators", "da");
t.add("This value should be a valid expression.", "V\u00e6rdien skal v\u00e6re et gyldigt udtryk.", "validators", "da");
t.add("This value is not a valid CSS color.", "V\u00e6rdien skal v\u00e6re en gyldig CSS farve.", "validators", "da");
t.add("This value is not a valid CIDR notation.", "V\u00e6rdien er ikke en gyldig CIDR notation.", "validators", "da");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "V\u00e6rdien af netmasken skal v\u00e6re mellem {{ min }} og {{ max }}.", "validators", "da");
t.add("This form should not contain extra fields.", "Feltgruppen m\u00e5 ikke indeholde ekstra felter.", "validators", "da");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Den uploadede fil var for stor. Upload venligst en mindre fil.", "validators", "da");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF-token er ugyldig. Pr\u00f8v venligst at genindsende.", "validators", "da");
t.add("This value is not a valid HTML5 color.", "V\u00e6rdien er ikke en gyldig HTML5 farve.", "validators", "da");
t.add("Please enter a valid birthdate.", "Indtast venligst en gyldig f\u00f8dselsdato.", "validators", "da");
t.add("The selected choice is invalid.", "Den valgte mulighed er ugyldig .", "validators", "da");
t.add("The collection is invalid.", "Samlingen er ugyldig.", "validators", "da");
t.add("Please select a valid color.", "V\u00e6lg venligst en gyldig farve.", "validators", "da");
t.add("Please select a valid country.", "V\u00e6lg venligst et gyldigt land.", "validators", "da");
t.add("Please select a valid currency.", "V\u00e6lg venligst en gyldig valuta.", "validators", "da");
t.add("Please choose a valid date interval.", "V\u00e6lg venligst et gyldigt datointerval.", "validators", "da");
t.add("Please enter a valid date and time.", "V\u00e6lg venligst en gyldig dato og tid.", "validators", "da");
t.add("Please enter a valid date.", "V\u00e6lg venligst en gyldig dato.", "validators", "da");
t.add("Please select a valid file.", "V\u00e6lg venligst en gyldig fil.", "validators", "da");
t.add("The hidden field is invalid.", "Det skjulte felt er ugyldigt.", "validators", "da");
t.add("Please enter an integer.", "Inds\u00e6t veligst et heltal.", "validators", "da");
t.add("Please select a valid language.", "V\u00e6lg venligst et gyldigt sprog.", "validators", "da");
t.add("Please select a valid locale.", "V\u00e6lg venligst en gyldigt sprogkode.", "validators", "da");
t.add("Please enter a valid money amount.", "V\u00e6lg venligst et gyldigt bel\u00f8b.", "validators", "da");
t.add("Please enter a number.", "Indtast venligst et nummer.", "validators", "da");
t.add("The password is invalid.", "Passwordet er ugyldigt.", "validators", "da");
t.add("Please enter a percentage value.", "Indtast venligst en procentv\u00e6rdi.", "validators", "da");
t.add("The values do not match.", "V\u00e6rdierne er ikke ens.", "validators", "da");
t.add("Please enter a valid time.", "Indtast venligst en gyldig tid.", "validators", "da");
t.add("Please select a valid timezone.", "V\u00e6lg venligst en gyldig tidszone.", "validators", "da");
t.add("Please enter a valid URL.", "Indtast venligst en gyldig URL.", "validators", "da");
t.add("Please enter a valid search term.", "Indtast venligst et gyldigt s\u00f8geord.", "validators", "da");
t.add("Please provide a valid phone number.", "Giv venligst et gyldigt telefonnummer.", "validators", "da");
t.add("The checkbox has an invalid value.", "Checkboxen har en ugyldigt v\u00e6rdi.", "validators", "da");
t.add("Please enter a valid email address.", "Indtast venligst en gyldig e-mailadresse.", "validators", "da");
t.add("Please select a valid option.", "V\u00e6lg venligst en gyldig mulighed.", "validators", "da");
t.add("Please select a valid range.", "V\u00e6lg venligst et gyldigt interval .", "validators", "da");
t.add("Please enter a valid week.", "Indtast venligst en gyldig uge.", "validators", "da");
})(Translator);
