(function (t) {
// eu
t.add("This value should be false.", "Balio hau faltsua izan beharko litzateke.", "validators", "eu");
t.add("This value should be true.", "Balio hau egia izan beharko litzateke.", "validators", "eu");
t.add("This value should be of type {{ type }}.", "Balio hau {{ type }} motakoa izan beharko litzateke.", "validators", "eu");
t.add("This value should be blank.", "Balio hau hutsik egon beharko litzateke.", "validators", "eu");
t.add("The value you selected is not a valid choice.", "Hautatu duzun balioa ez da aukera egoki bat.", "validators", "eu");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Gutxienez aukera {{ limit }} hautatu behar duzu.|Gutxienez {{ limit }} aukera hautatu behar dituzu.", "validators", "eu");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Gehienez aukera {{ limit }} hautatu behar duzu.|Gehienez {{ limit }} aukera hautatu behar dituzu.", "validators", "eu");
t.add("One or more of the given values is invalid.", "Emandako balioetatik gutxienez bat ez da egokia.", "validators", "eu");
t.add("This field was not expected.", "Eremu hau ez zen espero.", "validators", "eu");
t.add("This field is missing.", "Eremu hau falta da.", "validators", "eu");
t.add("This value is not a valid date.", "Balio hau ez da data egoki bat.", "validators", "eu");
t.add("This value is not a valid datetime.", "Balio hau ez da data-ordu egoki bat.", "validators", "eu");
t.add("This value is not a valid email address.", "Balio hau ez da posta elektroniko egoki bat.", "validators", "eu");
t.add("The file could not be found.", "Ezin izan da fitxategia aurkitu.", "validators", "eu");
t.add("The file is not readable.", "Fitxategia ez da irakurgarria.", "validators", "eu");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Fitxategia handiegia da ({{ size }} {{ suffix }}). Baimendutako tamaina handiena {{ limit }} {{ suffix }} da.", "validators", "eu");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Fitxategiaren mime mota ez da egokia ({{ type }}). Hauek dira baimendutako mime motak: {{ types }}.", "validators", "eu");
t.add("This value should be {{ limit }} or less.", "Balio hau gehienez {{ limit }} izan beharko litzateke.", "validators", "eu");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Balio hau luzeegia da. Gehienez karaktere {{ limit }} eduki beharko luke.|Balio hau luzeegia da. Gehienez {{ limit }} karaktere eduki beharko lituzke.", "validators", "eu");
t.add("This value should be {{ limit }} or more.", "Balio hau gutxienez {{ limit }} izan beharko litzateke.", "validators", "eu");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Balio hau motzegia da. Karaktere {{ limit }} gutxienez eduki beharko luke.|Balio hau motzegia da. Gutxienez {{ limit }} karaktere eduki beharko lituzke.", "validators", "eu");
t.add("This value should not be blank.", "Balio hau ez litzateke hutsik egon behar.", "validators", "eu");
t.add("This value should not be null.", "Balio hau ez litzateke nulua izan behar.", "validators", "eu");
t.add("This value should be null.", "Balio hau nulua izan beharko litzateke.", "validators", "eu");
t.add("This value is not valid.", "Balio hau ez da egokia.", "validators", "eu");
t.add("This value is not a valid time.", "Balio hau ez da ordu egoki bat.", "validators", "eu");
t.add("This value is not a valid URL.", "Balio hau ez da baliabideen kokatzaile uniforme (URL) egoki bat.", "validators", "eu");
t.add("The two values should be equal.", "Bi balioak berdinak izan beharko lirateke.", "validators", "eu");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Fitxategia handiegia da. Baimendutako tamaina handiena {{ limit }} {{ suffix }} da.", "validators", "eu");
t.add("The file is too large.", "Fitxategia handiegia da.", "validators", "eu");
t.add("The file could not be uploaded.", "Ezin izan da fitxategia igo.", "validators", "eu");
t.add("This value should be a valid number.", "Balio hau zenbaki egoki bat izan beharko litzateke.", "validators", "eu");
t.add("This file is not a valid image.", "Fitxategi hau ez da irudi egoki bat.", "validators", "eu");
t.add("This is not a valid IP address.", "Honako hau ez da IP helbide egoki bat.", "validators", "eu");
t.add("This value is not a valid language.", "Balio hau ez da hizkuntza egoki bat.", "validators", "eu");
t.add("This value is not a valid locale.", "Balio hau ez da kokapen egoki bat.", "validators", "eu");
t.add("This value is not a valid country.", "Balio hau ez da herrialde egoki bat.", "validators", "eu");
t.add("This value is already used.", "Balio hau jadanik erabilia izan da.", "validators", "eu");
t.add("The size of the image could not be detected.", "Ezin izan da irudiaren tamaina detektatu.", "validators", "eu");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Irudiaren zabalera handiegia da ({{ width }}px). Onartutako gehienezko zabalera {{ max_width }}px dira.", "validators", "eu");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Irudiaren zabalera txikiegia da ({{ width }}px). Onartutako gutxieneko zabalera {{ min_width }}px dira.", "validators", "eu");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Irudiaren altuera handiegia da ({{ height }}px). Onartutako gehienezko altuera {{ max_height }}px dira.", "validators", "eu");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Irudiaren altuera txikiegia da ({{ height }}px). Onartutako gutxieneko altuera {{ min_height }}px dira.", "validators", "eu");
t.add("This value should be the user's current password.", "Balio hau uneko erabiltzailearen pasahitza izan beharko litzateke.", "validators", "eu");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Balio honek zehazki karaktere {{ limit }} izan beharko luke.|Balio honek zehazki {{ limit }} karaktere izan beharko lituzke.", "validators", "eu");
t.add("The file was only partially uploaded.", "Fitxategiaren zati bat bakarrik igo da.", "validators", "eu");
t.add("No file was uploaded.", "Ez da fitxategirik igo.", "validators", "eu");
t.add("No temporary folder was configured in php.ini.", "Ez da aldi baterako karpetarik konfiguratu php.ini fitxategian.", "validators", "eu");
t.add("Cannot write temporary file to disk.", "Ezin izan da aldi baterako fitxategia diskoan idatzi.", "validators", "eu");
t.add("A PHP extension caused the upload to fail.", "PHP luzapen batek igoeraren hutsa eragin du.", "validators", "eu");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Bilduma honek gutxienez elementu {{ limit }} eduki beharko luke.|Bilduma honek gutxienez {{ limit }} elementu eduki beharko lituzke.", "validators", "eu");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Bilduma honek gehienez elementu {{ limit }} eduki beharko luke.|Bilduma honek gehienez {{ limit }} elementu eduki beharko lituzke.", "validators", "eu");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Bilduma honek zehazki elementu {{ limit }} eduki beharko luke.|Bilduma honek zehazki {{ limit }} elementu eduki beharko lituzke.", "validators", "eu");
t.add("Invalid card number.", "Txartel zenbaki baliogabea.", "validators", "eu");
t.add("Unsupported card type or invalid card number.", "Txartel mota onartezina edo txartel zenbaki baliogabea.", "validators", "eu");
t.add("This is not a valid International Bank Account Number (IBAN).", "Hau ez da baliozko banku internazionaleko kontu zenbaki (IBAN) bat.", "validators", "eu");
t.add("This value is not a valid ISBN-10.", "Balio hau ez da onartutako ISBN-10 bat.", "validators", "eu");
t.add("This value is not a valid ISBN-13.", "Balio hau ez da onartutako ISBN-13 bat.", "validators", "eu");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Balio hau ez da onartutako ISBN-10 edo ISBN-13 bat.", "validators", "eu");
t.add("This value is not a valid ISSN.", "Balio hau ez da onartutako ISSN bat.", "validators", "eu");
t.add("This value is not a valid currency.", "Balio hau ez da baliozko moneta bat.", "validators", "eu");
t.add("This value should be equal to {{ compared_value }}.", "Balio hau {{ compared_value }}-(r)en berbera izan beharko litzateke.", "validators", "eu");
t.add("This value should be greater than {{ compared_value }}.", "Balio hau {{ compared_value }} baino handiagoa izan beharko litzateke.", "validators", "eu");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Balio hau {{ compared_value }}-(r)en berdina edota handiagoa izan beharko litzateke.", "validators", "eu");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Balio hau {{ compared_value_type }} {{ compared_value }}-(r)en berbera izan beharko litzateke.", "validators", "eu");
t.add("This value should be less than {{ compared_value }}.", "Balio hau {{ compared_value }} baino txikiagoa izan beharko litzateke.", "validators", "eu");
t.add("This value should be less than or equal to {{ compared_value }}.", "Balio hau {{ compared_value }}-(r)en berdina edota txikiagoa izan beharko litzateke.", "validators", "eu");
t.add("This value should not be equal to {{ compared_value }}.", "Balio hau ez litzateke {{ compared_value }}-(r)en berdina izan behar.", "validators", "eu");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Balio hau ez litzateke {{ compared_value_type }} {{ compared_value }}-(r)en berbera izan behar.", "validators", "eu");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Irudiaren proportzioa oso handia da ({{ ratio }}). Onartutako proportzio handienda {{ max_ratio }} da.", "validators", "eu");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Irudiaren proportzioa oso txikia da ({{ ratio }}). Onartutako proportzio txikiena {{ min_ratio }} da.", "validators", "eu");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Irudia karratua da ({{ width }}x{{ height }}px). Karratuak diren irudiak ez dira onartzen.", "validators", "eu");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Irudia horizontalki bideratua dago ({{ width }}x{{ height }}px). Horizontalki bideratutako irudiak ez dira onartzen.", "validators", "eu");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Irudia bertikalki bideratua dago ({{ width }}x{{ height }}px). Bertikalki bideratutako irudiak ez dira onartzen.", "validators", "eu");
t.add("An empty file is not allowed.", "Hutsik dagoen fitxategia ez da onartzen.", "validators", "eu");
t.add("The host could not be resolved.", "Host-a ezin da ebatzi.", "validators", "eu");
t.add("This value does not match the expected {{ charset }} charset.", "Balio honen karaktere kodea ez da esperotakoa {{ charset }}.", "validators", "eu");
t.add("This is not a valid Business Identifier Code (BIC).", "Ez da balizko Banku Identifikazioko Kodea (BIC).", "validators", "eu");
t.add("Error", "Errore", "validators", "eu");
t.add("This is not a valid UUID.", "Balio hau ez da onartutako UUID bat.", "validators", "eu");
t.add("This value should be a multiple of {{ compared_value }}.", "Balio honek {{ compared_value }}-ren multiploa izan beharko luke.", "validators", "eu");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Banku Identifikazioko Kode hau ez dago lotuta {{ IBAN }} IBAN-rekin.", "validators", "eu");
t.add("This value should be valid JSON.", "Balio honek baliozko JSON bat izan behar luke.", "validators", "eu");
t.add("This collection should contain only unique elements.", "Bilduma honek elementu bakarrak soilik izan beharko lituzke.", "validators", "eu");
t.add("This value should be positive.", "Balio honek positiboa izan beharko luke.", "validators", "eu");
t.add("This value should be either positive or zero.", "Balio honek positiboa edo zero izan behar luke.", "validators", "eu");
t.add("This value should be negative.", "Balio honek negatiboa izan behar luke.", "validators", "eu");
t.add("This value should be either negative or zero.", "Balio honek negatiboa edo zero izan behar luke.", "validators", "eu");
t.add("This value is not a valid timezone.", "Balio hori ez da baliozko ordu-eremua.", "validators", "eu");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Pasahitz hori ezin da erabili, beste gune eta aplikazio batzuetako segurtasun-akatsei esker lortutako pasahitz publikoen zerrendan sartuta dagoelako. Mesedez, erabili beste pasahitz bat.", "validators", "eu");
t.add("This value should be between {{ min }} and {{ max }}.", "Balio honek {{ min }} eta {{ max }} artean egon behar luke.", "validators", "eu");
t.add("This value is not a valid hostname.", "Balio hori ez da ostalari-izen onargarria.", "validators", "eu");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Bilduma honetako elementu-kopuruak {{ compared_value }}-ren multiploa izan behar luke.", "validators", "eu");
t.add("This value should satisfy at least one of the following constraints:", "Balio honek, gutxienez, murrizketa hauetako bat bete behar du:", "validators", "eu");
t.add("Each element of this collection should satisfy its own set of constraints.", "Bilduma honetako elementu bakoitzak bere murriztapen-multzoa bete behar du.", "validators", "eu");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Balio hori ez da baliozko baloreen nazioarteko identifikazio-zenbaki bat (ISIN).", "validators", "eu");
t.add("This value should be a valid expression.", "Balio hori baliozko adierazpena izan beharko litzateke.", "validators", "eu");
t.add("This value is not a valid CSS color.", "Balio hori ez da baliozko CSS kolorea.", "validators", "eu");
t.add("This value is not a valid CIDR notation.", "Balio hori ez da baliozko CIDR notazioa.", "validators", "eu");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "Maskararen balioa {{ min }} eta {{ max }} artekoa izan beharko litzateke.", "validators", "eu");
t.add("This form should not contain extra fields.", "Formulario honek ez luke aparteko eremurik eduki behar.", "validators", "eu");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Igotako fitxategia handiegia da. Mesedez saiatu fitxategi txikiago bat igotzen.", "validators", "eu");
t.add("The CSRF token is invalid.", "CSRF tokena ez da egokia.", "validators", "eu");
t.add("This value is not a valid HTML5 color.", "Balio hori ez da HTML5 kolore onargarria.", "validators", "eu");
t.add("Please enter a valid birthdate.", "Mesedez, sartu baliozko urtebetetze-eguna.", "validators", "eu");
t.add("The selected choice is invalid.", "Hautatutako aukera ez da egokia.", "validators", "eu");
t.add("The collection is invalid.", "Bilduma ez da baliozkoa.", "validators", "eu");
t.add("Please select a valid color.", "Mesedez, hautatu baliozko kolore bat.", "validators", "eu");
t.add("Please select a valid country.", "Mesedez, hautatu baliozko herrialde bat.", "validators", "eu");
t.add("Please select a valid currency.", "Mesedez, hautatu baliozko moneta bat.", "validators", "eu");
t.add("Please choose a valid date interval.", "Mesedez, hautatu baliozko data-tarte bat.", "validators", "eu");
t.add("Please enter a valid date and time.", "Mesedez, sartu baliozko data eta ordua.", "validators", "eu");
t.add("Please enter a valid date.", "Mesedez, sartu baliozko data bat.", "validators", "eu");
t.add("Please select a valid file.", "Mesedez, hautatu baliozko fitxategi bat.", "validators", "eu");
t.add("The hidden field is invalid.", "Eremu ezkutua ez da baliozkoa.", "validators", "eu");
t.add("Please enter an integer.", "Mesedez, sartu zenbaki oso bat.", "validators", "eu");
t.add("Please select a valid language.", "Mesedez, hautatu baliozko hizkuntza bat.", "validators", "eu");
t.add("Please select a valid locale.", "Mesedez, hautatu baliozko eskualde-konfigurazio bat.", "validators", "eu");
t.add("Please enter a valid money amount.", "Mesedez, sartu baliozko diru-kopuru bat.", "validators", "eu");
t.add("Please enter a number.", "Mesedez, sartu zenbaki bat.", "validators", "eu");
t.add("The password is invalid.", "Pasahitza ez da zuzena.", "validators", "eu");
t.add("Please enter a percentage value.", "Mesedez, sartu portzentajezko balio bat.", "validators", "eu");
t.add("The values do not match.", "Balioak ez datoz bat.", "validators", "eu");
t.add("Please enter a valid time.", "Mesedez, sartu baliozko ordu bat.", "validators", "eu");
t.add("Please select a valid timezone.", "Mesedez, hautatu baliozko ordu-eremua.", "validators", "eu");
t.add("Please enter a valid URL.", "Mesedez, sartu baliozko URL bat.", "validators", "eu");
t.add("Please enter a valid search term.", "Mesedez, sartu bilaketa-termino onargarri bat.", "validators", "eu");
t.add("Please provide a valid phone number.", "Mesedez, eman baliozko telefono-zenbaki bat.", "validators", "eu");
t.add("The checkbox has an invalid value.", "Egiaztatze-laukiak balio baliogabea du.", "validators", "eu");
t.add("Please enter a valid email address.", "Mesedez, sartu baliozko helbide elektroniko bat.", "validators", "eu");
t.add("Please select a valid option.", "Mesedez, hautatu baliozko aukera bat.", "validators", "eu");
t.add("Please select a valid range.", "Mesedez, hautatu baliozko tarte bat.", "validators", "eu");
t.add("Please enter a valid week.", "Mesedez, sartu baliozko aste bat.", "validators", "eu");
})(Translator);
