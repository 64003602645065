(function (t) {
// sk
t.add("This value should be false.", "T\u00e1to hodnota by mala by\u0165 nastaven\u00e1 na false.", "validators", "sk");
t.add("This value should be true.", "T\u00e1to hodnota by mala by\u0165 nastaven\u00e1 na true.", "validators", "sk");
t.add("This value should be of type {{ type }}.", "T\u00e1to hodnota by mala by\u0165 typu {{ type }}.", "validators", "sk");
t.add("This value should be blank.", "T\u00e1to hodnota by mala by\u0165 pr\u00e1zdna.", "validators", "sk");
t.add("The value you selected is not a valid choice.", "T\u00e1to hodnota by mala by\u0165 jednou z poskytnut\u00fdch mo\u017enost\u00ed.", "validators", "sk");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Mali by ste vybra\u0165 minim\u00e1lne {{ limit }} mo\u017enos\u0165.|Mali by ste vybra\u0165 minim\u00e1lne {{ limit }} mo\u017enosti.|Mali by ste vybra\u0165 minim\u00e1lne {{ limit }} mo\u017enost\u00ed.", "validators", "sk");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Mali by ste vybra\u0165 najviac {{ limit }} mo\u017enos\u0165.|Mali by ste vybra\u0165 najviac {{ limit }} mo\u017enosti.|Mali by ste vybra\u0165 najviac {{ limit }} mo\u017enost\u00ed.", "validators", "sk");
t.add("One or more of the given values is invalid.", "Niektor\u00e9 z uveden\u00fdch hodn\u00f4t s\u00fa neplatn\u00e9.", "validators", "sk");
t.add("This field was not expected.", "Toto pole sa neo\u010dak\u00e1va.", "validators", "sk");
t.add("This field is missing.", "Toto pole ch\u00fdba.", "validators", "sk");
t.add("This value is not a valid date.", "Tato hodnota nem\u00e1 platn\u00fd form\u00e1t d\u00e1tumu.", "validators", "sk");
t.add("This value is not a valid datetime.", "T\u00e1to hodnota nem\u00e1 platn\u00fd form\u00e1t d\u00e1tumu a \u010dasu.", "validators", "sk");
t.add("This value is not a valid email address.", "T\u00e1to hodnota nie je platn\u00e1 emailov\u00e1 adresa.", "validators", "sk");
t.add("The file could not be found.", "S\u00fabor sa nena\u0161iel.", "validators", "sk");
t.add("The file is not readable.", "S\u00fabor nie je \u010ditate\u013en\u00fd.", "validators", "sk");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "S\u00fabor je pr\u00edli\u0161 ve\u013ek\u00fd ({{ size }} {{ suffix }}). Maxim\u00e1lna povolen\u00e1 ve\u013ekos\u0165 je {{ limit }} {{ suffix }}.", "validators", "sk");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "S\u00fabor typu ({{ type }}) nie je podporovan\u00fd. Podporovan\u00e9 typy s\u00fa {{ types }}.", "validators", "sk");
t.add("This value should be {{ limit }} or less.", "T\u00e1to hodnota by mala by\u0165 {{ limit }} alebo menej.", "validators", "sk");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "T\u00e1to hodnota obsahuje viac znakov ako je povolen\u00e9. Mala by obsahova\u0165 najviac {{ limit }} znak.|T\u00e1to hodnota obsahuje viac znakov ako je povolen\u00e9. Mala by obsahova\u0165 najviac {{ limit }} znaky.|T\u00e1to hodnota obsahuje viac znakov ako je povolen\u00e9. Mala by obsahova\u0165 najviac {{ limit }} znakov.", "validators", "sk");
t.add("This value should be {{ limit }} or more.", "T\u00e1to hodnota by mala by\u0165 viac ako {{ limit }}.", "validators", "sk");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "T\u00e1to hodnota je pr\u00edli\u0161 kr\u00e1tka. Mus\u00ed obsahova\u0165 minim\u00e1lne {{ limit }} znak.|T\u00e1to hodnota je pr\u00edli\u0161 kr\u00e1tka. Mus\u00ed obsahova\u0165 minim\u00e1lne {{ limit }} znaky.|T\u00e1to hodnota je pr\u00edli\u0161 kr\u00e1tka. Minim\u00e1lny po\u010det znakov je {{ limit }}.", "validators", "sk");
t.add("This value should not be blank.", "T\u00e1to hodnota by mala by\u0165 vyplnen\u00e1.", "validators", "sk");
t.add("This value should not be null.", "T\u00e1to hodnota by nemala by\u0165 null.", "validators", "sk");
t.add("This value should be null.", "T\u00e1to hodnota by mala by\u0165 null.", "validators", "sk");
t.add("This value is not valid.", "T\u00e1to hodnota nie je platn\u00e1.", "validators", "sk");
t.add("This value is not a valid time.", "Tato hodnota nem\u00e1 spr\u00e1vny form\u00e1t \u010dasu.", "validators", "sk");
t.add("This value is not a valid URL.", "T\u00e1to hodnota nie je platnou URL adresou.", "validators", "sk");
t.add("The two values should be equal.", "Tieto dve hodnoty by mali by\u0165 rovnak\u00e9.", "validators", "sk");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "S\u00fabor je pr\u00edli\u0161 ve\u013ek\u00fd. Maxim\u00e1lna povolen\u00e1 ve\u013ekos\u0165 je {{ limit }} {{ suffix }}.", "validators", "sk");
t.add("The file is too large.", "S\u00fabor je pr\u00edli\u0161 ve\u013ek\u00fd.", "validators", "sk");
t.add("The file could not be uploaded.", "S\u00fabor sa nepodarilo nahra\u0165.", "validators", "sk");
t.add("This value should be a valid number.", "T\u00e1to hodnota by mala by\u0165 \u010d\u00edslo.", "validators", "sk");
t.add("This file is not a valid image.", "Tento s\u00fabor nie je obr\u00e1zok.", "validators", "sk");
t.add("This is not a valid IP address.", "Toto nie je platn\u00e1 IP adresa.", "validators", "sk");
t.add("This value is not a valid language.", "Tento jazyk neexistuje.", "validators", "sk");
t.add("This value is not a valid locale.", "T\u00e1to lokaliz\u00e1cia neexistuje.", "validators", "sk");
t.add("This value is not a valid country.", "T\u00e1to krajina neexistuje.", "validators", "sk");
t.add("This value is already used.", "T\u00e1to hodnota sa u\u017e pou\u017e\u00edva.", "validators", "sk");
t.add("The size of the image could not be detected.", "Nepodarilo sa zisti\u0165 rozmery obr\u00e1zku.", "validators", "sk");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Obr\u00e1zok je pr\u00edli\u0161 \u0161irok\u00fd ({{ width }}px). Maxim\u00e1lna povolen\u00e1 \u0161\u00edrka obr\u00e1zku je {{ max_width }}px.", "validators", "sk");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Obr\u00e1zok je pr\u00edli\u0161 \u00fazky ({{ width }}px). Minim\u00e1lna \u0161\u00edrka obr\u00e1zku by mala by\u0165 {{ min_width }}px.", "validators", "sk");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", ">Obr\u00e1zok je pr\u00edli\u0161 vysok\u00fd ({{ height }}px). Maxim\u00e1lna povolen\u00e1 v\u00fd\u0161ka obr\u00e1zku je {{ max_height }}px.", "validators", "sk");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Obr\u00e1zok je pr\u00edli\u0161 n\u00edzky ({{ height }}px). Minim\u00e1lna v\u00fd\u0161ka obr\u00e1zku by mala by\u0165 {{ min_height }}px.", "validators", "sk");
t.add("This value should be the user's current password.", "T\u00e1to hodnota by mala by\u0165 aktu\u00e1lne heslo pou\u017e\u00edvate\u013ea.", "validators", "sk");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "T\u00e1to hodnota by mala ma\u0165 presne {{ limit }} znak.|T\u00e1to hodnota by mala ma\u0165 presne {{ limit }} znaky.|T\u00e1to hodnota by mala ma\u0165 presne {{ limit }} znakov.", "validators", "sk");
t.add("The file was only partially uploaded.", "Bola nahran\u00e1 len \u010das\u0165 s\u00faboru.", "validators", "sk");
t.add("No file was uploaded.", "\u017diadny s\u00fabor nebol nahran\u00fd.", "validators", "sk");
t.add("No temporary folder was configured in php.ini.", "V php.ini nie je nastaven\u00e1 cesta k address\u00e1ru pre do\u010dasn\u00e9 s\u00fabory.", "validators", "sk");
t.add("Cannot write temporary file to disk.", "Do\u010dasn\u00fd s\u00fabor sa nepodarilo zap\u00edsa\u0165 na disk.", "validators", "sk");
t.add("A PHP extension caused the upload to fail.", "Roz\u0161\u00edrenie PHP zabr\u00e1nilo nahraniu s\u00faboru.", "validators", "sk");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "T\u00e1to kolekcia by mala obsahova\u0165 aspo\u0148 {{ limit }} prvok alebo viac.|T\u00e1to kolekcia by mala obsahova\u0165 aspo\u0148 {{ limit }} prvky alebo viac.|T\u00e1to kolekcia by mala obsahova\u0165 aspo\u0148 {{ limit }} prvkov alebo viac.", "validators", "sk");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "T\u00e1to kolekcia by mala maxim\u00e1lne {{ limit }} prvok.|T\u00e1to kolekcia by mala obsahova\u0165 maxim\u00e1lne {{ limit }} prvky.|T\u00e1to kolekcia by mala obsahova\u0165 maxim\u00e1lne {{ limit }} prvkov.", "validators", "sk");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "T\u00e1to kolekcia by mala obsahova\u0165 presne {{ limit }} prvok.|T\u00e1to kolekcia by mala obsahova\u0165 presne {{ limit }} prvky.|T\u00e1to kolekcia by mala obsahova\u0165 presne {{ limit }} prvkov.", "validators", "sk");
t.add("Invalid card number.", "Neplatn\u00e9 \u010d\u00edslo karty.", "validators", "sk");
t.add("Unsupported card type or invalid card number.", "Nepodporovan\u00fd typ karty alebo neplatn\u00e9 \u010d\u00edslo karty.", "validators", "sk");
t.add("This is not a valid International Bank Account Number (IBAN).", "Toto je neplatn\u00fd IBAN.", "validators", "sk");
t.add("This value is not a valid ISBN-10.", "T\u00e1to hodnota je neplatn\u00e9 ISBN-10.", "validators", "sk");
t.add("This value is not a valid ISBN-13.", "T\u00e1to hodnota je neplatn\u00e9 ISBN-13.", "validators", "sk");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "T\u00e1to hodnota nie je platn\u00e9 ISBN-10 ani ISBN-13.", "validators", "sk");
t.add("This value is not a valid ISSN.", "T\u00e1to hodnota nie je platn\u00e9 ISSN.", "validators", "sk");
t.add("This value is not a valid currency.", "T\u00e1to hodnota nie je platn\u00e1 mena.", "validators", "sk");
t.add("This value should be equal to {{ compared_value }}.", "T\u00e1to hodnota by mala by\u0165 rovn\u00e1 {{ compared_value }}.", "validators", "sk");
t.add("This value should be greater than {{ compared_value }}.", "T\u00e1to hodnota by mala by\u0165 v\u00e4\u010d\u0161ia ako {{ compared_value }}.", "validators", "sk");
t.add("This value should be greater than or equal to {{ compared_value }}.", "T\u00e1to hodnota by mala by\u0165 v\u00e4\u010d\u0161ia alebo rovn\u00e1 {{ compared_value }}.", "validators", "sk");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "T\u00e1to hodnota by mala by\u0165 typu {{ compared_value_type }} a z\u00e1rove\u0148 by mala by\u0165 rovn\u00e1 {{ compared_value }}.", "validators", "sk");
t.add("This value should be less than {{ compared_value }}.", "T\u00e1to hodnota by mala by\u0165 men\u0161ia ako {{ compared_value }}.", "validators", "sk");
t.add("This value should be less than or equal to {{ compared_value }}.", "T\u00e1to hodnota by mala by\u0165 men\u0161ia alebo rovn\u00e1 {{ compared_value }}.", "validators", "sk");
t.add("This value should not be equal to {{ compared_value }}.", "T\u00e1to hodnota by nemala by\u0165 rovn\u00e1 {{ compared_value }}.", "validators", "sk");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "T\u00e1to hodnota by nemala by\u0165 typu {{ compared_value_type }} a z\u00e1rove\u0148 by nemala by\u0165 rovn\u00e1 {{ compared_value }}.", "validators", "sk");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Pomer str\u00e1n obr\u00e1zku je pr\u00edli\u0161 ve\u013ek\u00fd ({{ ratio }}). Maxim\u00e1lny povolen\u00fd pomer str\u00e1n obr\u00e1zku je {{ max_ratio }}.", "validators", "sk");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Pomer str\u00e1n obr\u00e1zku je pr\u00edli\u0161 mal\u00fd ({{ ratio }}). Minim\u00e1lny povolen\u00fd pomer str\u00e1n obr\u00e1zku je {{ min_ratio }}.", "validators", "sk");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Strany obr\u00e1zku s\u00fa \u0161tvorcov\u00e9 ({{ width }}x{{ height }}px). \u0160tvorcov\u00e9 obr\u00e1zky nie s\u00fa povolen\u00e9.", "validators", "sk");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Obr\u00e1zok je orientovan\u00fd na \u0161\u00edrku ({{ width }}x{{ height }}px). Obr\u00e1zky orientovan\u00e9 na \u0161\u00edrku nie s\u00fa povolen\u00e9.", "validators", "sk");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Obr\u00e1zok je orientovan\u00fd na v\u00fd\u0161ku ({{ width }}x{{ height }}px). Obr\u00e1zky orientovan\u00e9 na v\u00fd\u0161ku nie s\u00fa povolen\u00e9.", "validators", "sk");
t.add("An empty file is not allowed.", "S\u00fabor nesmie by\u0165 pr\u00e1zdny.", "validators", "sk");
t.add("The host could not be resolved.", "Hostite\u013ea nebolo mo\u017en\u00e9 rozpozna\u0165.", "validators", "sk");
t.add("This value does not match the expected {{ charset }} charset.", "T\u00e1to hodnota nezodpoved\u00e1 o\u010dak\u00e1vanej znakovej sade {{ charset }}.", "validators", "sk");
t.add("This is not a valid Business Identifier Code (BIC).", "T\u00e1to hodnota nie je platn\u00fd identifika\u010dn\u00fd k\u00f3d podniku (BIC).", "validators", "sk");
t.add("Error", "Chyba", "validators", "sk");
t.add("This is not a valid UUID.", "T\u00e1to hodnota nie je platn\u00fd UUID.", "validators", "sk");
t.add("This value should be a multiple of {{ compared_value }}.", "T\u00e1to hodnota by mala by\u0165 n\u00e1sobkom {{ compared_value }}.", "validators", "sk");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Tento identifika\u010dn\u00fd k\u00f3d podniku (BIC) nie je spojen\u00fd s IBAN {{ iban }}.", "validators", "sk");
t.add("This value should be valid JSON.", "T\u00e1to hodnota by mala by\u0165 platn\u00fd JSON.", "validators", "sk");
t.add("This collection should contain only unique elements.", "T\u00e1to kolekcia by mala obsahova\u0165 len unik\u00e1tne prkvy.", "validators", "sk");
t.add("This value should be positive.", "T\u00e1to hodnota by mala by\u0165 kladn\u00e1.", "validators", "sk");
t.add("This value should be either positive or zero.", "T\u00e1to hodnota by mala by\u0165 kladn\u00e1 alebo nulov\u00e1.", "validators", "sk");
t.add("This value should be negative.", "T\u00e1to hodnota by mala by\u0165 z\u00e1porn\u00e1.", "validators", "sk");
t.add("This value should be either negative or zero.", "T\u00e1to hodnota by mala by\u0165 z\u00e1porn\u00e1 alebo nulov\u00e1.", "validators", "sk");
t.add("This value is not a valid timezone.", "T\u00e1to hodnota nie je platn\u00e9 \u010dasov\u00e9 p\u00e1smo.", "validators", "sk");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Toto heslo uniklo pri naru\u0161en\u00ed ochrany d\u00e1t, nie je mo\u017en\u00e9 ho pou\u017ei\u0165. Pros\u00edm, pou\u017eite in\u00e9 heslo.", "validators", "sk");
t.add("This value should be between {{ min }} and {{ max }}.", "T\u00e1to hodnota by mala by\u0165 medzi {{ min }} a {{ max }}.", "validators", "sk");
t.add("This value is not a valid hostname.", "T\u00e1to hodnota nie je platn\u00fd hostname.", "validators", "sk");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Po\u010det prvkov v tejto kolekcii mus\u00ed by\u0165 n\u00e1sobok {{ compared_value }}.", "validators", "sk");
t.add("This value should satisfy at least one of the following constraints:", "T\u00e1to hodnota mus\u00ed sp\u013a\u0148a\u0165 aspo\u0148 jedno z nasleduj\u00facich obmedzen\u00ed:", "validators", "sk");
t.add("Each element of this collection should satisfy its own set of constraints.", "Ka\u017ed\u00fd prvok v tejto kolekcii mus\u00ed sp\u013a\u0148a\u0165 svoje vlastn\u00e9 obmedzenia.", "validators", "sk");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "T\u00e1to hodnota nie je platn\u00e9 medzin\u00e1rodn\u00e9 ozna\u010denie cenn\u00e9ho papiera (ISIN).", "validators", "sk");
t.add("This value should be a valid expression.", "T\u00e1to hodnota by mala by\u0165 platn\u00fdm v\u00fdrazom.", "validators", "sk");
t.add("This value is not a valid CSS color.", "T\u00e1to hodnota nie je platn\u00e1 CSS farba.", "validators", "sk");
t.add("This value is not a valid CIDR notation.", "T\u00e1to hodnota nie je platnou not\u00e1ciou CIDR.", "validators", "sk");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "Hodnota masky siete by mala by\u0165 medzi {{ min }} a {{ max }}.", "validators", "sk");
t.add("This form should not contain extra fields.", "Polia by nemali obsahova\u0165 \u010fal\u0161ie prvky.", "validators", "sk");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Odoslan\u00fd s\u00fabor je pr\u00edli\u0161 ve\u013ek\u00fd. Pros\u00edm odo\u0161lite s\u00fabor s men\u0161ou ve\u013ekos\u0165ou.", "validators", "sk");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF token je neplatn\u00fd. Pros\u00edm sk\u00faste znovu odosla\u0165 formul\u00e1r.", "validators", "sk");
t.add("This value is not a valid HTML5 color.", "T\u00e1to hodnota nie je platn\u00e1 HTML5 farba.", "validators", "sk");
t.add("Please enter a valid birthdate.", "Pros\u00edm zadajte platn\u00fd d\u00e1tum narodenia.", "validators", "sk");
t.add("The selected choice is invalid.", "Vybran\u00e1 mo\u017enos\u0165 je neplatn\u00e1.", "validators", "sk");
t.add("The collection is invalid.", "Kolekcia je neplatn\u00e1.", "validators", "sk");
t.add("Please select a valid color.", "Pros\u00edm vyberte platn\u00fa farbu.", "validators", "sk");
t.add("Please select a valid country.", "Pros\u00edm vyberte platn\u00fa krajinu.", "validators", "sk");
t.add("Please select a valid currency.", "Pros\u00edm vyberte platn\u00fa menu.", "validators", "sk");
t.add("Please choose a valid date interval.", "Pros\u00edm vyberte platn\u00fd rozsah d\u00e1t.", "validators", "sk");
t.add("Please enter a valid date and time.", "Pros\u00edm zadajte platn\u00fd d\u00e1tum a \u010das.", "validators", "sk");
t.add("Please enter a valid date.", "Pros\u00edm zadajte platn\u00fd d\u00e1tum.", "validators", "sk");
t.add("Please select a valid file.", "Pros\u00edm vyberte platn\u00fd s\u00fabor.", "validators", "sk");
t.add("The hidden field is invalid.", "Skryt\u00e9 pole je neplatn\u00e9.", "validators", "sk");
t.add("Please enter an integer.", "Pros\u00edm zadajte cel\u00e9 \u010d\u00edslo.", "validators", "sk");
t.add("Please select a valid language.", "Pros\u00edm vyberte platn\u00fd jazyk.", "validators", "sk");
t.add("Please select a valid locale.", "Pros\u00edm vyberte platn\u00e9 miestne nastavenia.", "validators", "sk");
t.add("Please enter a valid money amount.", "Pros\u00edm zadajte platn\u00fa \u010diastku.", "validators", "sk");
t.add("Please enter a number.", "Pros\u00edm zadajte \u010d\u00edslo.", "validators", "sk");
t.add("The password is invalid.", "Heslo je nepr\u00e1vne.", "validators", "sk");
t.add("Please enter a percentage value.", "Pros\u00edm zadajte percentu\u00e1lnu hodnotu.", "validators", "sk");
t.add("The values do not match.", "Hodnoty nie s\u00fa zhodn\u00e9.", "validators", "sk");
t.add("Please enter a valid time.", "Pros\u00edm zadajte platn\u00fd \u010das.", "validators", "sk");
t.add("Please select a valid timezone.", "Pros\u00edm vyberte platn\u00e9 \u010dasov\u00e9 p\u00e1smo.", "validators", "sk");
t.add("Please enter a valid URL.", "Pros\u00edm zadajte platn\u00fa URL.", "validators", "sk");
t.add("Please enter a valid search term.", "Pros\u00edm zadajte platn\u00fd vyh\u013ead\u00e1vac\u00ed v\u00fdraz.", "validators", "sk");
t.add("Please provide a valid phone number.", "Pros\u00edm zadajte platn\u00e9 telef\u00f3nne \u010d\u00edslo.", "validators", "sk");
t.add("The checkbox has an invalid value.", "Za\u0161krt\u00e1vacie pol\u00ed\u010dko m\u00e1 neplatn\u00fa hodnotu.", "validators", "sk");
t.add("Please enter a valid email address.", "Pros\u00edm zadajte platn\u00fa emailov\u00fa adresu.", "validators", "sk");
t.add("Please select a valid option.", "Pros\u00edm vyberte platn\u00fa mo\u017enos\u0165.", "validators", "sk");
t.add("Please select a valid range.", "Pros\u00edm vyberte platn\u00fd rozsah.", "validators", "sk");
t.add("Please enter a valid week.", "Pros\u00edm zadajte platn\u00fd t\u00fd\u017ede\u0148.", "validators", "sk");
})(Translator);
