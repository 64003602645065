(function (t) {
// mn
t.add("An authentication exception occurred.", "\u041d\u044d\u0432\u0442\u0440\u044d\u0445 \u0445\u04af\u0441\u044d\u043b\u0442\u0438\u0439\u043d \u0430\u043b\u0434\u0430\u0430 \u0433\u0430\u0440\u0430\u0432.", "security", "mn");
t.add("Authentication credentials could not be found.", "\u041d\u044d\u0432\u0442\u0440\u044d\u0445 \u044d\u0440\u0445\u0438\u0439\u043d \u043c\u044d\u0434\u044d\u044d\u043b\u044d\u043b \u043e\u043b\u0434\u0441\u043e\u043d\u0433\u04af\u0439.", "security", "mn");
t.add("Authentication request could not be processed due to a system problem.", "\u0421\u0438\u0441\u0442\u0435\u043c\u0438\u0439\u043d \u0430\u043b\u0434\u0430\u0430\u043d\u0430\u0430\u0441 \u0431\u043e\u043b\u043e\u043d \u043d\u044d\u0432\u0442\u0440\u044d\u0445 \u0445\u04af\u0441\u044d\u043b\u0442\u0438\u0439\u0433 \u0433\u04af\u0439\u0446\u044d\u0442\u0433\u044d\u0445 \u0431\u043e\u043b\u043e\u043c\u0436\u0433\u04af\u0439 \u0431\u0430\u0439\u043d\u0430.", "security", "mn");
t.add("Invalid credentials.", "\u0411\u0443\u0440\u0443\u0443 \u043d\u044d\u0432\u0442\u0440\u044d\u0445 \u044d\u0440\u0445\u0438\u0439\u043d \u043c\u044d\u0434\u044d\u044d\u043b\u044d\u043b.", "security", "mn");
t.add("Cookie has already been used by someone else.", "\u041a\u04af\u04af\u043a\u0438 \u0444\u0430\u0439\u043b\u044b\u0433 \u0430\u043b\u044c \u0445\u044d\u0434\u0438\u0439\u043d \u04e9\u04e9\u0440 \u0445\u04af\u043d \u0445\u044d\u0440\u044d\u0433\u043b\u044d\u0436 \u0431\u0430\u0439\u043d\u0430.", "security", "mn");
t.add("Not privileged to request the resource.", "\u042d\u043d\u044d\u0445\u04af\u04af \u043c\u044d\u0434\u044d\u044d\u043b\u043b\u0438\u0439\u0433 \u0430\u0432\u0430\u0445 \u044d\u0440\u0445 \u0445\u04af\u0440\u044d\u0445\u0433\u04af\u0439 \u0431\u0430\u0439\u043d\u0430.", "security", "mn");
t.add("Invalid CSRF token.", "\u0422\u043e\u0445\u0438\u0440\u043e\u043c\u0436\u0433\u04af\u0439 CSRF \u0442\u043e\u043a\u0435\u043d.", "security", "mn");
t.add("No authentication provider found to support the authentication token.", "\u041d\u044d\u0432\u0442\u0440\u044d\u0445 \u0442\u043e\u043a\u0435\u043d\u0433 \u0434\u044d\u043c\u0436\u0438\u0445 \u043d\u044d\u0432\u0442\u0440\u044d\u0445 \u044d\u0440\u0445\u0438\u0439\u043d \u0445\u0430\u043d\u0433\u0430\u0433\u0447 \u043e\u043b\u0434\u0441\u043e\u043d\u0433\u04af\u0439.", "security", "mn");
t.add("No  available, it either timed out or cookies are not enabled.", "\u0425\u044d\u0440\u044d\u0433\u043b\u044d\u0433\u0447\u0438\u0439\u043d session \u043e\u043b\u0434\u0441\u043e\u043d\u0433\u04af\u0439, \u0445\u0443\u0433\u0430\u0446\u0430\u0430 \u043d\u044c \u0434\u0443\u0443\u0441\u0441\u0430\u043d \u044d\u0441\u0432\u044d\u043b \u043a\u04af\u04af\u043a\u0438 \u0438\u0434\u044d\u0432\u0445\u0438\u0436\u04af\u04af\u043b\u044d\u044d\u0433\u04af\u0439 \u0431\u0430\u0439\u043d\u0430.", "security", "mn");
t.add("No token could be found.", "\u0422\u043e\u043a\u0435\u043d \u043e\u043b\u0434\u0441\u043e\u043d\u0433\u04af\u0439.", "security", "mn");
t.add("Username could not be found.", "\u041d\u044d\u0432\u0442\u0440\u044d\u0445 \u043d\u044d\u0440 \u043e\u043b\u0441\u043e\u043d\u0433\u04af\u0439.", "security", "mn");
t.add("Account has expired.", "\u0411\u04af\u0440\u0442\u0433\u044d\u043b\u0438\u0439\u043d \u0445\u0443\u0433\u0430\u0446\u0430\u0430 \u0434\u0443\u0443\u0441\u0441\u0430\u043d \u0431\u0430\u0439\u043d\u0430.", "security", "mn");
t.add("Credentials have expired.", "\u041d\u044d\u0432\u0442\u0440\u044d\u0445 \u044d\u0440\u0445\u0438\u0439\u043d \u0445\u0443\u0433\u0430\u0446\u0430\u0430 \u0434\u0443\u0443\u0441\u0441\u0430\u043d \u0431\u0430\u0439\u043d\u0430.", "security", "mn");
t.add("Account is disabled.", "\u0411\u04af\u0440\u0442\u0433\u044d\u043b\u0438\u0439\u0433 \u0445\u0430\u0430\u0441\u0430\u043d \u0431\u0430\u0439\u043d\u0430.", "security", "mn");
t.add("Account is locked.", "\u0411\u04af\u0440\u0442\u0433\u044d\u043b\u0438\u0439\u0433 \u0446\u043e\u043e\u0436\u0438\u043b\u0441\u043e\u043d \u0431\u0430\u0439\u043d\u0430.", "security", "mn");
t.add("Too many failed login attempts, please try again later.", "\u0425\u044d\u0442\u044d\u0440\u0445\u0438\u0439 \u043e\u043b\u043e\u043d \u0430\u043c\u0436\u0438\u043b\u0442\u0433\u04af\u0439 \u043e\u0440\u043e\u043b\u0434\u043b\u043e\u0433\u043e, \u0442\u04af\u0440 \u0445\u04af\u043b\u044d\u044d\u0433\u044d\u044d\u0434 \u0434\u0430\u0445\u0438\u043d \u043e\u0440\u043e\u043b\u0434\u043e\u043d\u043e \u0443\u0443.", "security", "mn");
t.add("Invalid or expired login link.", "\u0411\u0443\u0440\u0443\u0443 \u044d\u0441\u0432\u044d\u043b \u0445\u0443\u0433\u0430\u0446\u0430\u0430 \u043d\u044c \u0434\u0443\u0443\u0441\u0441\u0430\u043d \u043d\u044d\u0432\u0442\u0440\u044d\u0445 \u0437\u0430\u043c.", "security", "mn");
})(Translator);
