(function (t) {
// tr
t.add("This value should be false.", "Bu de\u011fer olumsuz olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be true.", "Bu de\u011fer olumlu olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be of type {{ type }}.", "Bu de\u011ferin tipi {{ type }} olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be blank.", "Bu de\u011fer bo\u015f olmal\u0131d\u0131r.", "validators", "tr");
t.add("The value you selected is not a valid choice.", "Se\u00e7ti\u011finiz de\u011fer ge\u00e7erli bir se\u00e7enek de\u011fil.", "validators", "tr");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "En az {{ limit }} se\u00e7enek belirtmelisiniz.", "validators", "tr");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "En \u00e7ok {{ limit }} se\u00e7enek belirtmelisiniz.", "validators", "tr");
t.add("One or more of the given values is invalid.", "Verilen de\u011ferlerden bir veya daha fazlas\u0131 ge\u00e7ersiz.", "validators", "tr");
t.add("This field was not expected.", "Bu alan beklenen olmad\u0131.", "validators", "tr");
t.add("This field is missing.", "Bu alan, eksik", "validators", "tr");
t.add("This value is not a valid date.", "Bu de\u011fer do\u011fru bir tarih bi\u00e7imi de\u011fildir.", "validators", "tr");
t.add("This value is not a valid datetime.", "Bu de\u011fer do\u011fru bir tarihsaat bi\u00e7imi de\u011fildir.", "validators", "tr");
t.add("This value is not a valid email address.", "Bu de\u011fer do\u011fru bir e-mail adresi de\u011fildir.", "validators", "tr");
t.add("The file could not be found.", "Dosya bulunamad\u0131.", "validators", "tr");
t.add("The file is not readable.", "Dosya okunabilir de\u011fil.", "validators", "tr");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Dosya \u00e7ok b\u00fcy\u00fck ({{ size }} {{ suffix }}). \u0130zin verilen en b\u00fcy\u00fck dosya boyutu {{ limit }} {{ suffix }}.", "validators", "tr");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Dosyan\u0131n mime tipi ge\u00e7ersiz ({{ type }}). \u0130zin verilen mime tipleri {{ types }}.", "validators", "tr");
t.add("This value should be {{ limit }} or less.", "Bu de\u011fer {{ limit }} ve alt\u0131nda olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Bu de\u011fer \u00e7ok uzun. {{ limit }} karakter veya daha az olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be {{ limit }} or more.", "Bu de\u011fer {{ limit }} veya daha fazla olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Bu de\u011fer \u00e7ok k\u0131sa. {{ limit }} karakter veya daha fazla olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should not be blank.", "Bu de\u011fer bo\u015f b\u0131rak\u0131lmamal\u0131d\u0131r.", "validators", "tr");
t.add("This value should not be null.", "Bu de\u011fer bo\u015f b\u0131rak\u0131lmamal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be null.", "Bu de\u011fer bo\u015f b\u0131rak\u0131lmal\u0131d\u0131r.", "validators", "tr");
t.add("This value is not valid.", "Bu de\u011fer ge\u00e7erli de\u011fil.", "validators", "tr");
t.add("This value is not a valid time.", "Bu de\u011fer do\u011fru bir saat de\u011fil.", "validators", "tr");
t.add("This value is not a valid URL.", "Bu de\u011fer do\u011fru bir URL de\u011fil.", "validators", "tr");
t.add("The two values should be equal.", "\u0130ki de\u011fer e\u015fit olmal\u0131d\u0131r.", "validators", "tr");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Dosya \u00e7ok b\u00fcy\u00fck. \u0130zin verilen en b\u00fcy\u00fck dosya boyutu {{ limit }} {{ suffix }}.", "validators", "tr");
t.add("The file is too large.", "Dosya \u00e7ok b\u00fcy\u00fck.", "validators", "tr");
t.add("The file could not be uploaded.", "Dosya y\u00fcklenemiyor.", "validators", "tr");
t.add("This value should be a valid number.", "Bu de\u011fer ge\u00e7erli bir rakam olmal\u0131d\u0131r.", "validators", "tr");
t.add("This file is not a valid image.", "Bu dosya ge\u00e7erli bir resim de\u011fildir.", "validators", "tr");
t.add("This is not a valid IP address.", "Bu ge\u00e7erli bir IP adresi de\u011fildir.", "validators", "tr");
t.add("This value is not a valid language.", "Bu de\u011fer ge\u00e7erli bir lisan de\u011fil.", "validators", "tr");
t.add("This value is not a valid locale.", "Bu de\u011fer ge\u00e7erli bir yer de\u011fildir.", "validators", "tr");
t.add("This value is not a valid country.", "Bu de\u011fer ge\u00e7erli bir \u00fclke de\u011fildir.", "validators", "tr");
t.add("This value is already used.", "Bu de\u011fer \u015fu anda kullan\u0131mda.", "validators", "tr");
t.add("The size of the image could not be detected.", "Resmin boyutu saptanam\u0131yor.", "validators", "tr");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Resmin geni\u015fli\u011fi \u00e7ok b\u00fcy\u00fck ({{ width }}px). \u0130zin verilen en b\u00fcy\u00fck geni\u015flik {{ max_width }}px.", "validators", "tr");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Resmin geni\u015fli\u011fi \u00e7ok k\u00fc\u00e7\u00fck ({{ width }}px). En az {{ min_width }}px olmal\u0131d\u0131r.", "validators", "tr");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Resmin y\u00fcksekli\u011fi \u00e7ok b\u00fcy\u00fck ({{ height }}px). \u0130zin verilen en b\u00fcy\u00fck y\u00fckseklik {{ max_height }}px.", "validators", "tr");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Resmin y\u00fcksekli\u011fi \u00e7ok k\u00fc\u00e7\u00fck ({{ height }}px). En az {{ min_height }}px olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be the user's current password.", "Bu de\u011fer kullan\u0131c\u0131n\u0131n \u015fu anki \u015fifresi olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Bu de\u011fer tam olarak {{ limit }} karakter olmald\u0131r.", "validators", "tr");
t.add("The file was only partially uploaded.", "Dosya sadece k\u0131smen y\u00fcklendi.", "validators", "tr");
t.add("No file was uploaded.", "Hi\u00e7bir dosya y\u00fcklenmedi.", "validators", "tr");
t.add("No temporary folder was configured in php.ini.", "php.ini i\u00e7erisinde ge\u00e7ici dizin tan\u0131mlanmad\u0131.", "validators", "tr");
t.add("Cannot write temporary file to disk.", "Ge\u00e7ici dosya diske yaz\u0131lam\u0131yor.", "validators", "tr");
t.add("A PHP extension caused the upload to fail.", "Bir PHP eklentisi dosyan\u0131n y\u00fcklemesini ba\u015far\u0131s\u0131z k\u0131ld\u0131.", "validators", "tr");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Bu derlem {{ limit }} veya daha \u00e7ok eleman i\u00e7ermelidir.", "validators", "tr");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Bu derlem {{ limit }} veya daha az eleman i\u00e7ermelidir.", "validators", "tr");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Bu derlem {{ limit }} eleman i\u00e7ermelidir.", "validators", "tr");
t.add("Invalid card number.", "Ge\u00e7ersiz kart numaras\u0131.", "validators", "tr");
t.add("Unsupported card type or invalid card number.", "Desteklenmeyen kart tipi veya ge\u00e7ersiz kart numaras\u0131.", "validators", "tr");
t.add("This is not a valid International Bank Account Number (IBAN).", "Bu ge\u00e7erli bir Uluslararas\u0131 Banka Hesap Numaras\u0131 (IBAN) de\u011fildir.", "validators", "tr");
t.add("This value is not a valid ISBN-10.", "Bu de\u011fer ge\u00e7erli bir ISBN-10 de\u011fildir.", "validators", "tr");
t.add("This value is not a valid ISBN-13.", "Bu de\u011fer ge\u00e7erli bir ISBN-13 de\u011fildir.", "validators", "tr");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Bu de\u011fer ge\u00e7erli bir ISBN-10 veya ISBN-13 de\u011fildir.", "validators", "tr");
t.add("This value is not a valid ISSN.", "Bu de\u011fer ge\u00e7erli bir ISSN de\u011fildir.", "validators", "tr");
t.add("This value is not a valid currency.", "Bu de\u011fer ge\u00e7erli bir para birimi de\u011fil.", "validators", "tr");
t.add("This value should be equal to {{ compared_value }}.", "Bu de\u011fer {{ compared_value }} ile e\u015fit olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be greater than {{ compared_value }}.", "Bu de\u011fer {{ compared_value }} de\u011ferinden b\u00fcy\u00fck olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Bu de\u011fer {{ compared_value }} ile e\u015fit veya b\u00fcy\u00fck olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Bu de\u011fer {{ compared_value_type }} {{ compared_value }} ile ayn\u0131 olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be less than {{ compared_value }}.", "Bu de\u011fer {{ compared_value }} de\u011ferinden d\u00fc\u015f\u00fck olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be less than or equal to {{ compared_value }}.", ".Bu de\u011fer {{ compared_value }} ile e\u015fit veya d\u00fc\u015f\u00fck olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should not be equal to {{ compared_value }}.", "Bu de\u011fer {{ compared_value }} ile e\u015fit olmamal\u0131d\u0131r.", "validators", "tr");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Bu de\u011fer {{ compared_value_type }} {{ compared_value }} ile ayn\u0131 olmamal\u0131d\u0131r.", "validators", "tr");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Resim oran\u0131 \u00e7ok b\u00fcy\u00fck ({{ ratio }}). \u0130zin verilen maksimum oran: {{ max_ratio }}.", "validators", "tr");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Resim oran\u0131 \u00e7ok ufak ({{ ratio }}). Beklenen minimum oran {{ min_ratio }}.", "validators", "tr");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Resim karesi ({{ width }}x{{ height }}px). Kare resimlerine izin verilmiyor.", "validators", "tr");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Resim manzara odakl\u0131 ({{ width }}x{{ height }}px). Manzara odakl\u0131 resimlere izin verilmiyor.", "validators", "tr");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Resim portre odakl\u0131 ({{ width }}x{{ height }}px). Portre odakl\u0131 resimlere izin verilmiyor.", "validators", "tr");
t.add("An empty file is not allowed.", "Bo\u015f bir dosyaya izin verilmiyor.", "validators", "tr");
t.add("The host could not be resolved.", "Sunucu \u00e7\u00f6z\u00fclemedi.", "validators", "tr");
t.add("This value does not match the expected {{ charset }} charset.", "Bu de\u011fer beklenen {{ charset }} karakter k\u00fcmesiyle e\u015fle\u015fmiyor.", "validators", "tr");
t.add("This is not a valid Business Identifier Code (BIC).", "Bu ge\u00e7erli bir \u0130\u015fletme Tan\u0131mlay\u0131c\u0131 Kodu (BIC) de\u011fildir.", "validators", "tr");
t.add("Error", "Hata", "validators", "tr");
t.add("This is not a valid UUID.", "Bu ge\u00e7erli bir UUID de\u011fildir.", "validators", "tr");
t.add("This value should be a multiple of {{ compared_value }}.", "Bu de\u011fer {{ compare_value }} de\u011ferinin katlar\u0131ndan biri olmal\u0131d\u0131r.", "validators", "tr");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Bu \u0130\u015fletme Tan\u0131mlay\u0131c\u0131 Kodu (BIC), IBAN {{ iban }} ile ili\u015fkili de\u011fildir.", "validators", "tr");
t.add("This value should be valid JSON.", "Bu de\u011fer i\u00e7in ge\u00e7erli olmal\u0131d\u0131r JSON.", "validators", "tr");
t.add("This collection should contain only unique elements.", "Bu grup yaln\u0131zca benzersiz \u00f6\u011feler i\u00e7ermelidir.", "validators", "tr");
t.add("This value should be positive.", "Bu de\u011fer pozitif olmal\u0131.", "validators", "tr");
t.add("This value should be either positive or zero.", "Bu de\u011fer pozitif veya s\u0131f\u0131r olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be negative.", "Bu de\u011fer negatif olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value should be either negative or zero.", "Bu de\u011fer, negatif veya s\u0131f\u0131r olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value is not a valid timezone.", "Bu de\u011fer, ge\u00e7erli bir saat dilimi de\u011fil.", "validators", "tr");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Bu parola, bir veri ihlali nedeniyle s\u0131zd\u0131r\u0131lm\u0131\u015ft\u0131r ve kullan\u0131lmamal\u0131d\u0131r. L\u00fctfen ba\u015fka bir \u015fifre kullan\u0131n.", "validators", "tr");
t.add("This value should be between {{ min }} and {{ max }}.", "Bu de\u011fer aras\u0131nda olmal\u0131d\u0131r {{ min }} ve {{ max }}.", "validators", "tr");
t.add("This value is not a valid hostname.", "Bu de\u011fer, ge\u00e7erli bir ana bilgisayar ad\u0131 de\u011fil.", "validators", "tr");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Bu gruptaki \u00f6\u011fe say\u0131s\u0131 birden fazla olmal\u0131d\u0131r {{ compared_value }}.", "validators", "tr");
t.add("This value should satisfy at least one of the following constraints:", "Bu de\u011fer a\u015fa\u011f\u0131daki k\u0131s\u0131tlamalardan birini kar\u015f\u0131lamal\u0131d\u0131r:", "validators", "tr");
t.add("Each element of this collection should satisfy its own set of constraints.", "Bu gruptaki her \u00f6\u011fe kendi k\u0131s\u0131tlamalar\u0131n\u0131 kar\u015f\u0131lamal\u0131d\u0131r.", "validators", "tr");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Bu de\u011fer ge\u00e7erli bir Uluslararas\u0131 Menkul K\u0131ymetler Kimlik Numaras\u0131 de\u011fil (ISIN).", "validators", "tr");
t.add("This value should be a valid expression.", "Bu de\u011fer ge\u00e7erli bir ifade olmal\u0131d\u0131r.", "validators", "tr");
t.add("This value is not a valid CSS color.", "Bu de\u011fer ge\u00e7erli bir CSS rengi de\u011fil.", "validators", "tr");
t.add("This value is not a valid CIDR notation.", "Bu de\u011fer ge\u00e7erli bir CIDR yaz\u0131m\u0131 de\u011fil.", "validators", "tr");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "Netmask'in de\u011feri {{ min }} ve {{ max }} aras\u0131nda olmald\u0131r.", "validators", "tr");
t.add("This form should not contain extra fields.", "Form ekstra alanlar i\u00e7eremez.", "validators", "tr");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Y\u00fcklenen dosya boyutu \u00e7ok y\u00fcksek. L\u00fctfen daha k\u00fc\u00e7\u00fck bir dosya y\u00fcklemeyi deneyin.", "validators", "tr");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF fi\u015fi ge\u00e7ersiz. Formu tekrar g\u00f6ndermeyi deneyin.", "validators", "tr");
t.add("This value is not a valid HTML5 color.", "Bu de\u011fer, ge\u00e7erli bir HTML5 rengi de\u011fil.", "validators", "tr");
t.add("Please enter a valid birthdate.", "L\u00fctfen ge\u00e7erli bir do\u011fum tarihi girin.", "validators", "tr");
t.add("The selected choice is invalid.", "Se\u00e7ilen se\u00e7im ge\u00e7ersiz.", "validators", "tr");
t.add("The collection is invalid.", "Koleksiyon ge\u00e7ersiz.", "validators", "tr");
t.add("Please select a valid color.", "L\u00fctfen ge\u00e7erli bir renk se\u00e7in.", "validators", "tr");
t.add("Please select a valid country.", "L\u00fctfen ge\u00e7erli bir \u00fclke se\u00e7in.", "validators", "tr");
t.add("Please select a valid currency.", "L\u00fctfen ge\u00e7erli bir para birimi se\u00e7in.", "validators", "tr");
t.add("Please choose a valid date interval.", "L\u00fctfen ge\u00e7erli bir tarih aral\u0131\u011f\u0131 se\u00e7in.", "validators", "tr");
t.add("Please enter a valid date and time.", "L\u00fctfen ge\u00e7erli bir tarih ve saat girin.", "validators", "tr");
t.add("Please enter a valid date.", "L\u00fctfen ge\u00e7erli bir tarih giriniz.", "validators", "tr");
t.add("Please select a valid file.", "L\u00fctfen ge\u00e7erli bir dosya se\u00e7in.", "validators", "tr");
t.add("The hidden field is invalid.", "Gizli alan ge\u00e7ersiz.", "validators", "tr");
t.add("Please enter an integer.", "L\u00fctfen bir tam say\u0131 girin.", "validators", "tr");
t.add("Please select a valid language.", "L\u00fctfen ge\u00e7erli bir dil se\u00e7in.", "validators", "tr");
t.add("Please select a valid locale.", "L\u00fctfen ge\u00e7erli bir yerel ayar se\u00e7in.", "validators", "tr");
t.add("Please enter a valid money amount.", "L\u00fctfen ge\u00e7erli bir para tutar\u0131 girin.", "validators", "tr");
t.add("Please enter a number.", "L\u00fctfen bir numara giriniz.", "validators", "tr");
t.add("The password is invalid.", "\u015eifre ge\u00e7ersiz.", "validators", "tr");
t.add("Please enter a percentage value.", "L\u00fctfen bir y\u00fczde de\u011feri girin.", "validators", "tr");
t.add("The values do not match.", "De\u011ferler e\u015fle\u015fmiyor.", "validators", "tr");
t.add("Please enter a valid time.", "L\u00fctfen ge\u00e7erli bir zaman girin.", "validators", "tr");
t.add("Please select a valid timezone.", "L\u00fctfen ge\u00e7erli bir saat dilimi se\u00e7in.", "validators", "tr");
t.add("Please enter a valid URL.", "L\u00fctfen ge\u00e7erli bir giriniz URL.", "validators", "tr");
t.add("Please enter a valid search term.", "L\u00fctfen ge\u00e7erli bir arama terimi girin.", "validators", "tr");
t.add("Please provide a valid phone number.", "l\u00fctfen ge\u00e7erli bir telefon numaras\u0131 sa\u011flay\u0131n.", "validators", "tr");
t.add("The checkbox has an invalid value.", "Onay kutusunda ge\u00e7ersiz bir de\u011fer var.", "validators", "tr");
t.add("Please enter a valid email address.", "L\u00fctfen ge\u00e7erli bir e-posta adresi girin.", "validators", "tr");
t.add("Please select a valid option.", "L\u00fctfen ge\u00e7erli bir se\u00e7enek se\u00e7in.", "validators", "tr");
t.add("Please select a valid range.", "L\u00fctfen ge\u00e7erli bir aral\u0131k se\u00e7in.", "validators", "tr");
t.add("Please enter a valid week.", "L\u00fctfen ge\u00e7erli bir hafta girin.", "validators", "tr");
})(Translator);
