(function (t) {
// en
t.add("customer.lastName", "Lastname", "form_labels", "en");
t.add("customer.firstName", "Firstname", "form_labels", "en");
t.add("customer.personalId", "ID", "form_labels", "en");
t.add("customer.birthDate", "Date of birth", "form_labels", "en");
t.add("customer.email", "Email", "form_labels", "en");
t.add("customer.phone", "Phone number", "form_labels", "en");
t.add("customer.postal_code", "Post code", "form_labels", "en");
t.add("customer.surgeonEmail", "Surgeon email", "form_labels", "en");
t.add("customer.sportDoctorEmail", "Sport doctor email", "form_labels", "en");
t.add("customer.secretariatEmail", "Secretary email", "form_labels", "en");
t.add("customer.practiceLevel", "Main sport level of practice", "form_labels", "en");
t.add("customer.gender", "Gender", "form_labels", "en");
t.add("customer.gender.female", "Female", "form_labels", "en");
t.add("customer.height", "Height", "form_labels", "en");
t.add("customer.weight", "Weight", "form_labels", "en");
t.add("customer.handedness", "Inferior Dominant member", "form_labels", "en");
t.add("customer.upper_handedness", "Superior dominant member", "form_labels", "en");
t.add("customer.handedness.right", "Right", "form_labels", "en");
t.add("customer.handedness.left", "Left", "form_labels", "en");
t.add("customer.prestation", "Service", "form_labels", "en");
t.add("customer.reathletisation", "Rehabilitation", "form_labels", "en");
t.add("choice.boolean.no", "No", "form_labels", "en");
t.add("customer.reathletisationAthletic", "Athletic's rehabilitation", "form_labels", "en");
t.add("choice.boolean.yes", "Yes", "form_labels", "en");
t.add("customer.reathletisationPrecision", "Rehabilitation precisions", "form_labels", "en");
t.add("customer.practicedSports", "Practiced sports", "form_labels", "en");
t.add("customer.activityFrequency", "Activity frequency", "form_labels", "en");
t.add("customer.activity_frequency.intensive", "Intensive", "form_labels", "en");
t.add("customer.additionalInformation", "Additional information", "form_labels", "en");
t.add("customer.injuredSide", "Injured side", "form_labels", "en");
t.add("customer.side.right", "Right", "form_labels", "en");
t.add("customer.operationDate", "Date of surgery", "form_labels", "en");
t.add("customer.surgeon", "Surgeon", "form_labels", "en");
t.add("customer.graft", "Graft", "form_labels", "en");
t.add("customer.graft.fascia_lata", "Fascia lata", "form_labels", "en");
t.add("customer.meniscus", "Meniscus", "form_labels", "en");
t.add("costomer.meniscus.mi", "MI", "form_labels", "en");
t.add("customer.lateralLigament", "Lateral ligament", "form_labels", "en");
t.add("customer.lateral_ligament.both", "LLE+LLI", "form_labels", "en");
t.add("customer.injuryDate", "Date of injury", "form_labels", "en");
t.add("customer.operation", "Surgery", "form_labels", "en");
t.add("customer.tenodesis", "Tenodesis", "form_labels", "en");
t.add("customer.requestsNumber", "Requests number", "form_labels", "en");
t.add("customer.injury", "Injury", "form_labels", "en");
t.add("customer.surgicalAntecedentsContralateralSide", "Surgical antecedent controlateral side", "form_labels", "en");
t.add("customer.surgicalAntecedentsIpsilateralSide", "Surgical antecedent ipsilateral side", "form_labels", "en");
t.add("customer.tegnerScaleBeforeInjury", "Tegner scale before injury", "form_labels", "en");
t.add("customer.tegnerScaleNow", "Tegner scale after injury", "form_labels", "en");
t.add("customer.other", "Other", "form_labels", "en");
t.add("customer.shoulder.injury_type", "Type of injury", "form_labels", "en");
t.add("customer.shoulder.shoulder_instability_anterior", "Anterior instability", "form_labels", "en");
t.add("customer.shoulder.shoulder_instability_posterior", "Posterior instability", "form_labels", "en");
t.add("customer.shoulder.shoulder_instability_multidirectionnel", "Multidirectional instability", "form_labels", "en");
t.add("customer.shoulder.clavicular_artropathy", "Clavicular artropathy", "form_labels", "en");
t.add("customer.shoulder.thendinopathy_calcific", "Calcific tendinopathy", "form_labels", "en");
t.add("customer.shoulder.clavicul_broken", "Broken clavicul", "form_labels", "en");
t.add("customer.shoulder.slap_lesion", "SLAP lesion", "form_labels", "en");
t.add("customer.shoulder.disjonction_acromio_clavicular", "Disjonction acromio-claviculaire", "form_labels", "en");
t.add("customer.shoulder.act", "Act", "form_labels", "en");
t.add("customer.shoulder.act_reintegration_anterior", "Reintegration of anterior bead (Bankart anterior)", "form_labels", "en");
t.add("customer.shoulder.act_reintegration_posterior", "Reintegration of posterior bead (Bankart posterior)", "form_labels", "en");
t.add("customer.shoulder.act_cuff_repair", "Rotator cuff repair", "form_labels", "en");
t.add("customer.shoulder.act_calcification_resection", "Tendon calcification resection", "form_labels", "en");
t.add("customer.shoulder.act_acromioclavicular_arthroplasty", "Acromioclavicular arthroplasty (resection)", "form_labels", "en");
t.add("customer.shoulder.act_acromioclavicular_ligamentoplasty", "Acromioclaviculaire ligamentoplasty", "form_labels", "en");
t.add("customer.shoulder.act_tenodesis_long_biceps", "Long biceps tenodesis", "form_labels", "en");
t.add("customer.shoulder.act_clavicul_osteosynthese", "Clavicular osteosynthesis", "form_labels", "en");
t.add("customer.shoulder.graft_type", "Type of graft", "form_labels", "en");
t.add("customer.shoulder.injury_date", "Date of injury", "form_labels", "en");
t.add("customer.shoulder.operation", "Surgery", "form_labels", "en");
t.add("customer.shoulder.operation_type", "Operation type", "form_labels", "en");
t.add("customer.shoulder.right_c7_major", "C7-Maj Right", "form_labels", "en");
t.add("customer.shoulder.left_c7_major", "C7-Maj Left", "form_labels", "en");
t.add("customer.shoulder.training_hours", "Training hours", "form_labels", "en");
t.add("customer.shoulder.num_instability", "Number of episodes of instability on the injured limb", "form_labels", "en");
t.add("customer.shoulder.age_first_injure", "Age of first injury", "form_labels", "en");
t.add("customer.shoulder.lesion_type", "Lesion type", "form_labels", "en");
t.add("customer.shoulder.work_before_3_months_surgery", "Work carried out after surgery from 0 to 3 months", "form_labels", "en");
t.add("customer.shoulder.work_after_3_months_surgery", "Work carried out 3 months after surgery", "form_labels", "en");
t.add("customer.shoulder.sport_doctor", "Sport doctor", "form_labels", "en");
t.add("customer.shoulder.operation_date", "Date of surgery", "form_labels", "en");
t.add("customer.shoulder.surgeon", "Surgeon", "form_labels", "en");
t.add("customer.shoulder.injury_side", "Surgery side", "form_labels", "en");
t.add("customer.shoulder.contralateral_antecedent", "Antecedents of contralateral", "form_labels", "en");
t.add("customer.shoulder.type_of_antecedents", "Type of antecedents", "form_labels", "en");
t.add("customer.shoulder.other", "Other", "form_labels", "en");
t.add("customer.hip.injury_type", "Type of injury", "form_labels", "en");
t.add("customer.hip.act", "Act", "form_labels", "en");
t.add("customer.hip.injury_date", "Date of injury", "form_labels", "en");
t.add("customer.hip.operated", "Operated", "form_labels", "en");
t.add("customer.hip.operation_date", "Date of surgery", "form_labels", "en");
t.add("customer.hip.surgeon", "Surgeon", "form_labels", "en");
t.add("customer.hip.injury_side", "Surgery side", "form_labels", "en");
t.add("customer.hip.contralateral_antecedent", "Antecedents of contralateral", "form_labels", "en");
t.add("customer.hip.type_of_antecedents", "Type of antecedents", "form_labels", "en");
t.add("customer.ankle.injury_type", "Type of injury", "form_labels", "en");
t.add("customer.ankle.act", "Act", "form_labels", "en");
t.add("customer.ankle.injury_date", "Date of injury", "form_labels", "en");
t.add("customer.ankle.operated", "Operated", "form_labels", "en");
t.add("customer.ankle.operation_date", "Date of surgery", "form_labels", "en");
t.add("customer.ankle.surgeon", "Surgeon", "form_labels", "en");
t.add("customer.ankle.injury_side", "Side of injury", "form_labels", "en");
t.add("customer.ankle.contralateral_antecedent", "Antecedents of contralateral", "form_labels", "en");
t.add("customer.ankle.type_of_antecedents", "Type of antecedents", "form_labels", "en");
t.add("customer.ankle.other", "Other", "form_labels", "en");
t.add("customer.other_lower_limbs.injury_type", "Type of injury", "form_labels", "en");
t.add("customer.other_lower_limbs.injury_details", "Injury details", "form_labels", "en");
t.add("customer.other_lower_limbs.injury_date", "Date of injury", "form_labels", "en");
t.add("customer.other_lower_limbs.operated", "Operated", "form_labels", "en");
t.add("customer.other_lower_limbs.operation_date", "Date of surgery", "form_labels", "en");
t.add("customer.other_lower_limbs.surgeon", "Surgeon", "form_labels", "en");
t.add("customer.other_lower_limbs.injury_side", "Surgery side", "form_labels", "en");
t.add("customer.other_lower_limbs.contralateral_antecedent", "Antecedents of contralateral", "form_labels", "en");
t.add("customer.other_lower_limbs.type_of_antecedents", "Type of antecedents", "form_labels", "en");
t.add("customer.back.injury_type", "Type of injury", "form_labels", "en");
t.add("customer.back.location", "Localisation", "form_labels", "en");
t.add("customer.back.injury_date", "Date of injury", "form_labels", "en");
t.add("customer.back.operated", "Operated", "form_labels", "en");
t.add("customer.back.operation_date", "Date of surgery", "form_labels", "en");
t.add("customer.back.surgeon", "Surgeon", "form_labels", "en");
t.add("customer.back.type_of_antecedents", "Type of antecedents", "form_labels", "en");
t.add("customer.tests", "Tests", "form_labels", "en");
t.add("customer.planning_list", "Planning list", "form_labels", "en");
t.add("customer.fullName", "Client name", "form_labels", "en");
t.add("customer.practice.leisure", "Leisure", "form_labels", "en");
t.add("customer.practice.department", "Department", "form_labels", "en");
t.add("customer.practice.region", "Region", "form_labels", "en");
t.add("customer.practice.national", "National", "form_labels", "en");
t.add("customer.practice.international", "International", "form_labels", "en");
t.add("customer.practice.professional", "Professional", "form_labels", "en");
t.add("customer.shoulder.lesion_type.fall_shoulder", "Shoulder drop", "form_labels", "en");
t.add("customer.shoulder.lesion_type.fall_hands", "Chute coude\/main", "form_labels", "en");
t.add("customer.shoulder.lesion_type.armed", "Armed\/contered", "form_labels", "en");
t.add("customer.shoulder.lesion_type.launched", "Launched", "form_labels", "en");
t.add("customer.shoulder.lesion_type.shock", "Shock", "form_labels", "en");
t.add("datatable.actions", "Actions", "form_labels", "en");
t.add("customer.athletic_customer", "Athletic customer", "form_labels", "en");
t.add("customer.button.details", "View details", "form_labels", "en");
t.add("customer.title.group_delete", "Delete from group", "form_labels", "en");
t.add("customer.title.edit", "Edit", "form_labels", "en");
t.add("customer.title.delete", "Delete", "form_labels", "en");
t.add("course.title", "Name", "form_labels", "en");
t.add("test.type", "Choose a test", "form_labels", "en");
t.add("test.customer", "Choose a customer", "form_labels", "en");
t.add("user.offices", "Cabinets", "form_labels", "en");
t.add("office_therapist.modal.add", "Add therapist", "form_labels", "en");
t.add("office_therapist.btn.add", "Add therapist", "form_labels", "en");
t.add("customer.offices", "Cabinets", "form_labels", "en");
t.add("customer.modal_title.share", "Share", "form_labels", "en");
t.add("post.summary", "Article's summary", "form_labels", "en");
t.add("post.comments", "Athletic's comments", "form_labels", "en");
t.add("course.description", "Descriptive", "form_labels", "en");
t.add("exercise.description", "Description", "form_labels", "en");
t.add("exercise.tools", "Tools", "form_labels", "en");
t.add("exercise.execution_speed", "Execution speed", "form_labels", "en");
t.add("exercise.number_of_series", "Number of series", "form_labels", "en");
t.add("exercise.accuracy_speed", "Pr\u00e9cision vitesse", "form_labels", "en");
t.add("exercise.intensity", "Intensity", "form_labels", "en");
t.add("exercise.repetitions", "Repetitions", "form_labels", "en");
t.add("exercise.intermediate_rest", "Intermediate rest", "form_labels", "en");
t.add("exercise.comments", "Comments", "form_labels", "en");
t.add("exercise.framing", "Framing", "form_labels", "en");
t.add("exercise.movement", "Movement", "form_labels", "en");
t.add("exercise.video", "Watch video", "form_labels", "en");
t.add("exercise.chain", "Chain", "form_labels", "en");
t.add("exercise.number_of_circuits", "Number of circuit", "form_labels", "en");
t.add("customer.testDate", "Test date", "form_labels", "en");
t.add("customer.operatedSide", "Surgery side", "form_labels", "en");
t.add("customer.graftType", "Graft type", "form_labels", "en");
t.add("customer.comment", "Comments", "form_labels", "en");
t.add("customer.beforeInjury", "Before injury", "form_labels", "en");
t.add("customer.afterInjury", "Post injury", "form_labels", "en");
t.add("customer.pdf.phone", "Phone number", "form_labels", "en");
t.add("customer_group.title", "Group name", "form_labels", "en");
t.add("add_to_session.select_customer", "Add session 1\/2", "form_labels", "en");
t.add("planning.customer", "Customers", "form_labels", "en");
t.add("planning_session.startDate", "Scheduled for", "form_labels", "en");
t.add("exercise.pdf.name", "Name", "form_labels", "en");
t.add("exercise.pdf.execution_speed", "Speed", "form_labels", "en");
t.add("course.therapists_list", "physiotherapists list", "form_labels", "en");
t.add("course.modal.add_therapist", "Add physiotherapist", "form_labels", "en");
t.add("course.table_header.therapists", "Physiotherapist", "form_labels", "en");
t.add("course.table_header.actions", "Actions", "form_labels", "en");
t.add("course.modal.remove_therapist", "Delete physiotherapist", "form_labels", "en");
t.add("course.documents_list", "File", "form_labels", "en");
t.add("customer.graft.di_suture", "DI SUTURE", "form_labels", "en");
t.add("customer.graft.suture", "SUTURE", "form_labels", "en");
t.add("customer.graft.dt_controlateral", "DT contralateral", "form_labels", "en");
t.add("customer.graft.suture_lca", "SUTURE LCA", "form_labels", "en");
t.add("course.course_category", "Formation's cat\u00e9gorie", "form_labels", "en");
t.add("course_category.name", "Formation's cat\u00e9gorie name", "form_labels", "en");
t.add("course.course_categories", "Formation categories", "form_labels", "en");
t.add("customer.dominant_side", "__customer.dominant_side", "form_labels", "en");
})(Translator);
