(function (t) {
// fr
t.add("app.newfeature", "NOUVELLE FONCTIONNALITE DISPONIBLE : g\u00e9n\u00e9rez automatiquement le protocole de r\u00e9athl\u00e9tisation post K-STARTS. <a target=\"_blank\" style=\"color:white\" href=\"%url%\"> Voir un cas concret <\/a>", "messages", "fr");
t.add("app.note_informative.redis_session", "Pensez \u00e0 vous d\u00e9connecter tous les soirs pour la sauvegarde de nuit afin de ne pas perdre de donn\u00e9es", "messages", "fr");
t.add("app.notice.planification", "<a target=\"_blank\" href=\"%url%\"> T\u00e9l\u00e9charger le guide d'utilisation d'un protocole post K-STARTS <\/a>", "messages", "fr");
t.add("user.role.doctor", "M\u00e9decin", "messages", "fr");
t.add("user.role.surgeon", "Chirurgien", "messages", "fr");
t.add("user.role.therapist", "Kin\u00e9", "messages", "fr");
t.add("user.role.admin-buisness", "Admin", "messages", "fr");
t.add("user.role.admin", "Admin", "messages", "fr");
t.add("user.role.coach", "Pr\u00e9parateur Physique", "messages", "fr");
t.add("menu.users", "Utilisateurs", "messages", "fr");
t.add("menu.globalStats", "Statistiques Globales", "messages", "fr");
t.add("menu.exercise", "Catalogue", "messages", "fr");
t.add("menu.category", "Liste des categories", "messages", "fr");
t.add("menu.customers", "Clients", "messages", "fr");
t.add("exercise.type.heart", "Cardio", "messages", "fr");
t.add("exercise.type.strength", "Musculation", "messages", "fr");
t.add("exercise.type.functional", "Fonctionnel", "messages", "fr");
t.add("exercise.type.armouring", "Gainage", "messages", "fr");
t.add("exercise.type.recovery", "R\u00e9cup\u00e9ration", "messages", "fr");
t.add("exercise.chain.isolated", "Isol\u00e9", "messages", "fr");
t.add("exercise.chain.in_circuit", "En circuit", "messages", "fr");
t.add("exercise.frame_type.follow_up", "Suivi", "messages", "fr");
t.add("exercise.frame_type.autonomy", "En autonomie", "messages", "fr");
t.add("exercise.training_type.unilateral", "Unilat\u00e9ral", "messages", "fr");
t.add("exercise.training_type.bilateral", "Bilat\u00e9ral", "messages", "fr");
t.add("exercise.training_type.alternate", "Altern\u00e9", "messages", "fr");
t.add("exercise.training_side.right", "Droite", "messages", "fr");
t.add("exercise.training_side.left", "Gauche", "messages", "fr");
t.add("menu.logout", "Logout", "messages", "fr");
t.add("planning.pdf.title", "Planification", "messages", "fr");
t.add("of", "du", "messages", "fr");
t.add("menu.planning", "Planifications", "messages", "fr");
t.add("customer_group.customers", "Clients dans le groupe", "messages", "fr");
t.add("menu.customerGroups", "Groupes de clients", "messages", "fr");
t.add("changes.saved", "Changes saved", "messages", "fr");
t.add("menu.posts", "Publications", "messages", "fr");
t.add("post.view_original", "Voir l\u2019article original", "messages", "fr");
t.add("menu.exercise_list", "Liste des exercices", "messages", "fr");
t.add("menu.courses", "Formations", "messages", "fr");
t.add("menu.courses.course_categories", "Cat\u00e9gories de formation", "messages", "fr");
t.add("planning.show_details", "Details", "messages", "fr");
t.add("planning.duplicate_modal_title", "Dupliquer cette planification ?", "messages", "fr");
t.add("planning.duplicate", "Dupliquer", "messages", "fr");
t.add("planning.edit_modal_title", "Edit planning", "messages", "fr");
t.add("planning.edit", "Modifier", "messages", "fr");
t.add("planning.remove_modal_title", "Remove planning", "messages", "fr");
t.add("planning.remove", "Supprimer", "messages", "fr");
t.add("planning.title", "Nom", "messages", "fr");
t.add("planning.session_count", "Nombre de s\u00e9ances", "messages", "fr");
t.add("planning.category", "Cat\u00e9gorie", "messages", "fr");
t.add("planning.customer_name", "Nom du client", "messages", "fr");
t.add("common.datatable_actions", "Actions", "messages", "fr");
t.add("category.title", "Title", "messages", "fr");
t.add("category.details", "Details", "messages", "fr");
t.add("category.edit", "Modifier", "messages", "fr");
t.add("category.delete", "Supprimer", "messages", "fr");
t.add("course_category.name", "Nom", "messages", "fr");
t.add("course_category.details", "Details", "messages", "fr");
t.add("course_category.edit", "Modifier", "messages", "fr");
t.add("course_category.delete", "Supprimer", "messages", "fr");
t.add("courses.title", "Nom", "messages", "fr");
t.add("courses.therapists_count", "Th\u00e9rapeutes", "messages", "fr");
t.add("courses.details", "Details", "messages", "fr");
t.add("courses.remove_modal_title", "Supprimer la formation", "messages", "fr");
t.add("courses.remove", "Supprimer", "messages", "fr");
t.add("exercise.name", "Exercice", "messages", "fr");
t.add("exercise.category_name", "Cat\u00e9gorie", "messages", "fr");
t.add("exercise.type", "Type", "messages", "fr");
t.add("exercise.status", "Statut", "messages", "fr");
t.add("exercise.status_valid", "Valid\u00e9", "messages", "fr");
t.add("exercise.status_draft", "Brouillon", "messages", "fr");
t.add("exercise.select_session_step1_modal_title", "Ajouter \u00e0 une s\u00e9ance 1\/2", "messages", "fr");
t.add("exercise.details", "Details", "messages", "fr");
t.add("exercise.edit", "Modifier", "messages", "fr");
t.add("exercise.remove", "Supprimer", "messages", "fr");
t.add("exercise.view_details", "Voir le d\u00e9tail", "messages", "fr");
t.add("planning_exercise.name", "Exercice", "messages", "fr");
t.add("planning_exercise.category_name", "Cat\u00e9gorie", "messages", "fr");
t.add("planning_exercise.type", "Type", "messages", "fr");
t.add("planning_exercise.details", "Details", "messages", "fr");
t.add("planning_exercise.edit", "Modifier", "messages", "fr");
t.add("planning_exercise.delete", "Supprimer", "messages", "fr");
t.add("planning_exercise.delete_modal_title", "Supprimer l'exercice de cette session?", "messages", "fr");
t.add("therapist_courses.title", "Nom", "messages", "fr");
t.add("therapist_courses.details", "Details", "messages", "fr");
t.add("users.credit.admin", "Gestion des Cr\u00e9dits", "messages", "fr");
t.add("users.first_name", "Pr\u00e9nom", "messages", "fr");
t.add("users.last_name", "Nom", "messages", "fr");
t.add("users.type", "Type", "messages", "fr");
t.add("users.email", "Email", "messages", "fr");
t.add("users.details", "Details", "messages", "fr");
t.add("users.edit", "Modifier", "messages", "fr");
t.add("users.remove", "Supprimer", "messages", "fr");
t.add("users.customers_count", "Clients", "messages", "fr");
t.add("users.k_start_count", "K-STARTS", "messages", "fr");
t.add("users.dpr_count", "DPR", "messages", "fr");
t.add("users.tar_count", "TAR", "messages", "fr");
t.add("users.s_start_count", "S-STARTS", "messages", "fr");
t.add("users.knee_planning", "PLANIF.GENOU", "messages", "fr");
t.add("users.shoulder_planning", "PLANIF.EPAULE", "messages", "fr");
t.add("contact_form.email_title", "Formulaire de contact", "messages", "fr");
t.add("contact_form.sender", "Exp\u00e9diteur", "messages", "fr");
t.add("contact_form.message", "Message", "messages", "fr");
t.add("menu.contact", "Contact", "messages", "fr");
t.add("error_page.title", "An Error Occurred", "messages", "fr");
t.add("error_page.message", "The server returned a", "messages", "fr");
t.add("exercise.details_button", "Voir le d\u00e9tail", "messages", "fr");
t.add("exercise.edit_button", "Modifier", "messages", "fr");
t.add("exercise.validate_button", "Valider", "messages", "fr");
t.add("exercise.chain.circuit_nr", "Circuit N\u00b0", "messages", "fr");
t.add("parameters.missing", "Param\u00e8tres manquants", "messages", "fr");
t.add("planning.mail.subject", "Planification Athletic", "messages", "fr");
t.add("test.mail.subject", "R\u00e9sultats de votre test chez Athletic", "messages", "fr");
t.add("menu.profile", "Mon profil", "messages", "fr");
t.add("menu.invoices", "Mes Factures", "messages", "fr");
t.add("menu.clients", "Mes Clients", "messages", "fr");
t.add("customers.export", "Exporter", "messages", "fr");
t.add("customer.pdf.cause", "Causes", "messages", "fr");
t.add("menu.exportActiveUsers", "Export utilisateur", "messages", "fr");
t.add("courses.edit", "Modifier", "messages", "fr");
t.add("menu.locale", "Langue", "messages", "fr");
t.add("menu.french", "Fran\u00e7ais", "messages", "fr");
t.add("menu.english", "Anglais", "messages", "fr");
t.add("menu.office", "Cabinet", "messages", "fr");
t.add("office.remove", "Supprimer", "messages", "fr");
t.add("office.edit", "Modifier", "messages", "fr");
t.add("stripe.payment.error", "Une erreur est survenu lors du paiement. Merci de bien v\u00e9rifier vos informations bancaires.", "messages", "fr");
t.add("protocole.error.process", "Une erreur est survenue lors de la cr\u00e9ation du protocole. Vous avez \u00e9t\u00e9 cr\u00e9dit\u00e9 de %amount% cr\u00e9dit sur votre compte", "messages", "fr");
t.add("protocole.credit.deficiency", "Vous ne poss\u00e9dez pas assez de cr\u00e9dits.", "messages", "fr");
t.add("protocole.credit.error.process", "Une erreur est survenue lors de la cr\u00e9ation du protocole. Vous ne serez pas d\u00e9bit\u00e9 de vos cr\u00e9dits.", "messages", "fr");
t.add("invoice.name", "Nom", "messages", "fr");
t.add("invoice.created_at", "Date de cr\u00e9ation", "messages", "fr");
t.add("invoice.total", "Total", "messages", "fr");
t.add("invoicing.pay", "Payer", "messages", "fr");
t.add("menu.facturation", "Vente de cr\u00e9dits", "messages", "fr");
t.add("menu.credit.admin", "G\u00e9rer les cr\u00e9dits", "messages", "fr");
t.add("menu.credit.add", "Ajouter des cr\u00e9dits \u00e0 %fullName%", "messages", "fr");
t.add("menu.credit.substract", "Supprimer des cr\u00e9dits \u00e0 %fullName%", "messages", "fr");
t.add("menu.buyCredits", "Acheter des cr\u00e9dits", "messages", "fr");
t.add("invoicing.validate", "Valider", "messages", "fr");
t.add("invoicing.add", "Ajouter", "messages", "fr");
t.add("invoicing.add.credit", "Ajouter des cr\u00e9dits", "messages", "fr");
t.add("invoicing.substract", "Supprimer", "messages", "fr");
t.add("invoicing.substract.credit", "Supprimer des cr\u00e9dits", "messages", "fr");
t.add("protocole.paiement.buy", "Acheter", "messages", "fr");
t.add("protocole.paiement.buy_credits", "Acheter des cr\u00e9dits", "messages", "fr");
t.add("protocole.paiement.use_credit", "Utiliser mon cr\u00e9dit", "messages", "fr");
t.add("protocole.paiement.use_credits", "Utiliser mes cr\u00e9dits", "messages", "fr");
t.add("users.credit", "Cr\u00e9dits", "messages", "fr");
t.add("users.nbProtocoleAuto", "Protocole auto", "messages", "fr");
})(Translator);
