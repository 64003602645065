(function (t) {
// be
t.add("This value should be false.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u041d\u0435.", "validators", "be");
t.add("This value should be true.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0422\u0430\u043a.", "validators", "be");
t.add("This value should be of type {{ type }}.", "\u0422\u044b\u043f \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u044f \u043f\u0430\u0432\u0456\u043d\u0435\u043d \u0431\u044b\u0446\u044c {{ type }}.", "validators", "be");
t.add("This value should be blank.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u043f\u0443\u0441\u0442\u044b\u043c.", "validators", "be");
t.add("The value you selected is not a valid choice.", "\u0410\u0431\u0440\u0430\u043d\u0430\u0435 \u0432\u0430\u043c\u0456 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u0430\u0435.", "validators", "be");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u0412\u044b \u043f\u0430\u0432\u0456\u043d\u043d\u044b \u0432\u044b\u0431\u0440\u0430\u0446\u044c \u0445\u0430\u0446\u044f \u0431 {{ limit }} \u0432\u0430\u0440\u044b\u044f\u043d\u0442.|\u0412\u044b \u043f\u0430\u0432\u0456\u043d\u043d\u044b \u0432\u044b\u0431\u0440\u0430\u0446\u044c \u0445\u0430\u0446\u044f \u0431 {{ limit }} \u0432\u0430\u0440\u044b\u044f\u043d\u0442\u0430\u045e.", "validators", "be");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u0412\u044b \u043f\u0430\u0432\u0456\u043d\u043d\u044b \u0432\u044b\u0431\u0440\u0430\u0446\u044c \u043d\u0435 \u0431\u043e\u043b\u044c\u0448 \u0437\u0430 {{ limit }} \u0432\u0430\u0440\u044b\u044f\u043d\u0442.|\u0412\u044b \u043f\u0430\u0432\u0456\u043d\u043d\u044b \u0432\u044b\u0431\u0440\u0430\u0446\u044c \u043d\u0435 \u0431\u043e\u043b\u044c\u0448 \u0437\u0430 {{ limit }} \u0432\u0430\u0440\u044b\u044f\u043d\u0442\u0430\u045e.", "validators", "be");
t.add("One or more of the given values is invalid.", "\u0410\u0434\u0437\u0456\u043d \u0430\u0431\u043e \u043d\u0435\u043a\u0430\u043b\u044c\u043a\u0456 \u043f\u0430\u0437\u043d\u0430\u0447\u0430\u043d\u044b\u0445 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u044f\u045e \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u043d\u0435\u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c.", "validators", "be");
t.add("This field was not expected.", "\u0413\u044d\u0442\u0430 \u043f\u043e\u043b\u0435 \u043d\u0435 \u0447\u0430\u043a\u0430\u0435\u0446\u0446\u0430.", "validators", "be");
t.add("This field is missing.", "\u0413\u044d\u0442\u0430 \u043f\u043e\u043b\u0435 \u0430\u0434\u0441\u0443\u0442\u043d\u0456\u0447\u0430\u0435.", "validators", "be");
t.add("This value is not a valid date.", "\u0413\u044d\u0442\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0430\u0439 \u0434\u0430\u0442\u0430\u0439.", "validators", "be");
t.add("This value is not a valid datetime.", "\u0413\u044d\u0442\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0430\u0439 \u0434\u0430\u0442\u0430\u0439 i \u0447\u0430\u0441\u043e\u043c.", "validators", "be");
t.add("This value is not a valid email address.", "\u0413\u044d\u0442\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b\u043c \u0430\u0434\u0440\u0430\u0441\u0430\u043c \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u0439 \u043f\u043e\u0448\u0442\u044b.", "validators", "be");
t.add("The file could not be found.", "\u0424\u0430\u0439\u043b \u043d\u0435 \u0437\u043d\u043e\u0439\u0434\u0437\u0435\u043d.", "validators", "be");
t.add("The file is not readable.", "\u0424\u0430\u0439\u043b \u043d\u0435 \u0447\u044b\u0442\u0430\u0435\u0446\u0446\u0430.", "validators", "be");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0424\u0430\u0439\u043b \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u0432\u044f\u043b\u0456\u043a\u0456 ({{ size }} {{ suffix }}). \u041c\u0430\u043a\u0441\u0456\u043c\u0430\u043b\u044c\u043d\u0430 \u0434\u0430\u0437\u0432\u043e\u043b\u0435\u043d\u044b \u043f\u0430\u043c\u0435\u0440 {{ limit }} {{ suffix }}.", "validators", "be");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "MIME-\u0442\u044b\u043f \u0444\u0430\u0439\u043b\u0443 \u043d\u0435\u043a\u0430\u0440\u044d\u043a\u0442\u044b ({{ type }}). \u0414\u0430\u0437\u0432\u043e\u043b\u0435\u043d\u044b\u044f MIME-\u0442\u044b\u043f\u044b \u0444\u0430\u0439\u043b\u0443 {{ types }}.", "validators", "be");
t.add("This value should be {{ limit }} or less.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c {{ limit }} \u0430\u0431\u043e \u043c\u0435\u043d\u0448.", "validators", "be");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u0434\u043e\u045e\u0433\u0430\u0435. \u042f\u043d\u043e \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u043c\u0435\u0446\u044c {{ limit }} \u0441\u0456\u043c\u0432\u0430\u043b \u0430\u0431\u043e \u043c\u0435\u043d\u0448.|\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u0434\u043e\u045e\u0433\u0430\u0435. \u042f\u043d\u043e \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u043c\u0435\u0446\u044c {{ limit }} \u0441\u0456\u043c\u0432\u0430\u043b\u0430\u045e \u0430\u0431\u043e \u043c\u0435\u043d\u0448.", "validators", "be");
t.add("This value should be {{ limit }} or more.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c {{ limit }} \u0430\u0431\u043e \u0431\u043e\u043b\u044c\u0448.", "validators", "be");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u043a\u0430\u0440\u043e\u0442\u043a\u0430\u0435. \u042f\u043d\u043e \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u043c\u0435\u0446\u044c \u043f\u0440\u044b\u043d\u0430\u043c\u0441\u0456 {{ limit }} \u0441\u0456\u043c\u0432\u0430\u043b.|\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u043a\u0430\u0440\u043e\u0442\u043a\u0430\u0435. \u042f\u043d\u043e \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u043c\u0435\u0446\u044c \u043f\u0440\u044b\u043d\u0430\u043c\u0441\u0456 {{ limit }} \u0441\u0456\u043c\u0432\u0430\u043b\u0430\u045e.", "validators", "be");
t.add("This value should not be blank.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u043f\u0443\u0441\u0442\u044b\u043c.", "validators", "be");
t.add("This value should not be null.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c null.", "validators", "be");
t.add("This value should be null.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c null.", "validators", "be");
t.add("This value is not valid.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u043d\u0435 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c.", "validators", "be");
t.add("This value is not a valid time.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c \u0447\u0430\u0441\u0430\u043c.", "validators", "be");
t.add("This value is not a valid URL.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c URL-\u0430\u0434\u0440\u0430\u0441\u0430\u043c.", "validators", "be");
t.add("The two values should be equal.", "\u0410\u0431\u043e\u0434\u0432\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0456 \u043f\u0430\u0432\u0456\u043d\u043d\u044b \u0431\u044b\u0446\u044c \u0430\u0434\u043d\u043e\u043b\u044c\u043a\u0430\u0432\u044b\u043c\u0456.", "validators", "be");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0424\u0430\u0439\u043b \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u0432\u044f\u043b\u0456\u043a\u0456. \u041c\u0430\u043a\u0441\u0456\u043c\u0430\u043b\u044c\u043d\u0430 \u0434\u0430\u0437\u0432\u043e\u043b\u0435\u043d\u044b \u043f\u0430\u043c\u0435\u0440 {{ limit }} {{ suffix }}.", "validators", "be");
t.add("The file is too large.", "\u0424\u0430\u0439\u043b \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u0432\u044f\u043b\u0456\u043a\u0456.", "validators", "be");
t.add("The file could not be uploaded.", "\u041d\u0435\u043c\u0430\u0433\u0447\u044b\u043c\u0430 \u0437\u0430\u043f\u0430\u043c\u043f\u0430\u0432\u0430\u0446\u044c \u0444\u0430\u0439\u043b.", "validators", "be");
t.add("This value should be a valid number.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u043b\u0456\u043a\u0430\u043c.", "validators", "be");
t.add("This file is not a valid image.", "\u0413\u044d\u0442\u044b \u0444\u0430\u0439\u043b \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u0430\u0439 \u0432\u044b\u044f\u0432\u0430\u0439.", "validators", "be");
t.add("This is not a valid IP address.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c IP-\u0430\u0434\u0440\u0430\u0441\u0430\u043c.", "validators", "be");
t.add("This value is not a valid language.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c \u043c\u043e\u0432\u0430\u0439.", "validators", "be");
t.add("This value is not a valid locale.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u0430\u0439 \u043b\u0430\u043a\u0430\u043b\u043b\u044e.", "validators", "be");
t.add("This value is not a valid country.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u0430\u0439 \u043a\u0440\u0430\u0456\u043d\u0430\u0439.", "validators", "be");
t.add("This value is already used.", "\u0413\u044d\u0442\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u045e\u0436\u043e \u0432\u044b\u043a\u0430\u0440\u044b\u0441\u0442\u043e\u045e\u0432\u0430\u0435\u0446\u0446\u0430.", "validators", "be");
t.add("The size of the image could not be detected.", "\u041d\u0435\u043c\u0430\u0433\u0447\u044b\u043c\u0430 \u0432\u044b\u0437\u043d\u0430\u0447\u044b\u0446\u044c \u043f\u0430\u043c\u0435\u0440 \u0432\u044b\u044f\u0432\u044b.", "validators", "be");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u0413\u044d\u0442\u0430 \u0432\u044b\u044f\u0432\u0430 \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u0432\u044f\u043b\u0456\u043a\u0430\u044f ({{ width }}px). \u0414\u0430\u0437\u0432\u0430\u043b\u044f\u0435\u0446\u0446\u0430 \u043c\u0430\u043a\u0441\u0456\u043c\u0430\u043b\u044c\u043d\u0430\u044f \u0448\u044b\u0440\u044b\u043d\u044f {{ max_width }}px.", "validators", "be");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u0413\u044d\u0442\u0430 \u0432\u044b\u044f\u0432\u0430 \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u043c\u0430\u043b\u0435\u043d\u044c\u043a\u0430\u044f ({{ width }}px). \u0414\u0430\u0437\u0432\u0430\u043b\u044f\u0435\u0446\u0446\u0430 \u043c\u0456\u043d\u0456\u043c\u0430\u043b\u044c\u043d\u0430\u044f \u0448\u044b\u0440\u044b\u043d\u044f {{ min_width }}px.", "validators", "be");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u0413\u044d\u0442\u044b \u0432\u044b\u044f\u0432\u0430 \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u0432\u044f\u043b\u0456\u043a\u0430\u044f ({{ width }}px). \u0414\u0430\u0437\u0432\u0430\u043b\u044f\u0435\u0446\u0446\u0430 \u043c\u0430\u043a\u0441\u0456\u043c\u0430\u043b\u044c\u043d\u0430\u044f \u0432\u044b\u0448\u044b\u043d\u044f {{ max_width }}px.", "validators", "be");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u0413\u044d\u0442\u0430 \u0432\u044b\u044f\u0432\u0430 \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u043c\u0430\u043b\u0435\u043d\u044c\u043a\u0430\u044f ({{ width }}px). \u0414\u0430\u0437\u0432\u0430\u043b\u044f\u0435\u0446\u0446\u0430 \u043c\u0456\u043d\u0456\u043c\u0430\u043b\u044c\u043d\u0430\u044f \u0432\u044b\u0448\u044b\u043d\u044f {{ min_width }}px.", "validators", "be");
t.add("This value should be the user's current password.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0446\u044f\u043f\u0435\u0440\u0430\u0448\u043d\u0456\u043c \u043f\u0430\u0440\u043e\u043b\u0435\u043c \u043a\u0430\u0440\u044b\u0441\u0442\u0430\u043b\u044c\u043d\u0456\u043a\u0430.", "validators", "be");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u043c\u0435\u0446\u044c {{ limit }} \u0441\u0456\u043c\u0432\u0430\u043b.|\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u043c\u0435\u0446\u044c {{ limit }} \u0441\u0456\u043c\u0432\u0430\u043b\u0430\u045e.", "validators", "be");
t.add("The file was only partially uploaded.", "\u0424\u0430\u0439\u043b \u0431\u044b\u045e \u0437\u0430\u043f\u0430\u043c\u043f\u0430\u0432\u0430\u043d\u044b \u0442\u043e\u043b\u044c\u043a\u0456 \u0447\u0430\u0441\u0442\u043a\u043e\u0432\u0430.", "validators", "be");
t.add("No file was uploaded.", "\u0424\u0430\u0439\u043b \u043d\u0435 \u0431\u044b\u045e \u0437\u0430\u043f\u0430\u043c\u043f\u0430\u0432\u0430\u043d\u044b.", "validators", "be");
t.add("No temporary folder was configured in php.ini.", "\u0423 php.ini \u043d\u0435 \u0431\u044b\u043b\u0430 \u043d\u0430\u043b\u0430\u0436\u0430\u043d\u0430 \u0447\u0430\u0441\u043e\u0432\u0430\u044f \u043f\u0430\u043f\u043a\u0430, \u0430\u0431\u043e \u0447\u0430\u0441\u043e\u0432\u0430\u044f \u043f\u0430\u043f\u043a\u0430 \u043d\u0435 \u0456\u0441\u043d\u0443\u0435.", "validators", "be");
t.add("Cannot write temporary file to disk.", "\u041d\u0435\u043c\u0430\u0433\u0447\u044b\u043c\u0430 \u0437\u0430\u043f\u0456\u0441\u0430\u0446\u044c \u0447\u0430\u0441\u043e\u0432\u044b \u0444\u0430\u0439\u043b \u043d\u0430 \u0434\u044b\u0441\u043a.", "validators", "be");
t.add("A PHP extension caused the upload to fail.", "\u041f\u0430\u0448\u044b\u0440\u044d\u043d\u043d\u0435 PHP \u0432\u044b\u043a\u043b\u0456\u043a\u0430\u043b\u0430 \u043f\u0430\u043c\u044b\u043b\u043a\u0443 \u0437\u0430\u0433\u0440\u0443\u0437\u043a\u0456.", "validators", "be");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u041a\u0430\u043b\u0435\u043a\u0446\u044b\u044f \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0437\u043c\u044f\u0448\u0447\u0430\u0446\u044c \u043f\u0440\u044b\u043d\u0430\u043c\u0441\u0456 {{ limit }} \u044d\u043b\u0435\u043c\u0435\u043d\u0442.|\u041a\u0430\u043b\u0435\u043a\u0446\u044b\u044f \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0437\u043c\u044f\u0448\u0447\u0430\u0446\u044c \u043f\u0440\u044b\u043d\u0430\u043c\u0441\u0456 {{ limit }} \u044d\u043b\u0435\u043c\u0435\u043d\u0442\u0430\u045e.", "validators", "be");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u041a\u0430\u043b\u0435\u043a\u0446\u044b\u044f \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0437\u043c\u044f\u0448\u0447\u0430\u0446\u044c {{ limit }} \u0430\u0431\u043e \u043c\u0435\u043d\u0448 \u044d\u043b\u0435\u043c\u0435\u043d\u0442.|\u041a\u0430\u043b\u0435\u043a\u0446\u044b\u044f \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0437\u043c\u044f\u0448\u0447\u0430\u0446\u044c {{ limit }} \u0430\u0431\u043e \u043c\u0435\u043d\u0448 \u044d\u043b\u0435\u043c\u0435\u043d\u0442\u0430\u045e.", "validators", "be");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u041a\u0430\u043b\u0435\u043a\u0446\u044b\u044f \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0437\u043c\u044f\u0448\u0447\u0430\u0446\u044c \u0440\u043e\u045e\u043d\u0430 {{ limit }} \u044d\u043b\u0435\u043c\u0435\u043d\u0442.|\u041a\u0430\u043b\u0435\u043a\u0446\u044b\u044f \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0437\u043c\u044f\u0448\u0447\u0430\u0446\u044c \u0440\u043e\u045e\u043d\u0430 {{ limit }} \u044d\u043b\u0435\u043c\u0435\u043d\u0442\u0430\u045e.", "validators", "be");
t.add("Invalid card number.", "\u041d\u0435\u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b \u043d\u0443\u043c\u0430\u0440 \u043a\u0430\u0440\u0442\u044b.", "validators", "be");
t.add("Unsupported card type or invalid card number.", "\u0422\u044b\u043f \u043a\u0430\u0440\u0442\u044b \u043d\u0435 \u043f\u0430\u0434\u0442\u0440\u044b\u043c\u043b\u0456\u0432\u0430\u0435\u0446\u0446\u0430 \u0430\u0431\u043e \u043d\u0435\u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b \u043d\u0443\u043c\u0430\u0440 \u043a\u0430\u0440\u0442\u044b.", "validators", "be");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u041d\u0435\u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b \u043c\u0456\u0436\u043d\u0430\u0440\u043e\u0434\u043d\u044b \u043d\u0443\u043c\u0430\u0440 \u0431\u0430\u043d\u043a\u0430\u045e\u0441\u043a\u0430\u0433\u0430 \u0440\u0430\u0445\u0443\u043d\u043a\u0443 (IBAN).", "validators", "be");
t.add("This value is not a valid ISBN-10.", "\u0413\u044d\u0442\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c ISBN-10.", "validators", "be");
t.add("This value is not a valid ISBN-13.", "\u0413\u044d\u0442\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c ISBN-13.", "validators", "be");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u0413\u044d\u0442\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c ISBN-10 \u0430\u0431\u043e ISBN-13.", "validators", "be");
t.add("This value is not a valid ISSN.", "\u0413\u044d\u0442\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c ISSN.", "validators", "be");
t.add("This value is not a valid currency.", "\u0413\u044d\u0442\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u0430\u0439 \u0432\u0430\u043b\u044e\u0442\u0430\u0439.", "validators", "be");
t.add("This value should be equal to {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0440\u0430\u045e\u043d\u044f\u0446\u0446\u0430 {{ compared_value }}.", "validators", "be");
t.add("This value should be greater than {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0431\u043e\u043b\u044c\u0448 \u0447\u044b\u043c {{ compared_value }}.", "validators", "be");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0431\u043e\u043b\u044c\u0448 \u0447\u044b\u043c \u0430\u0431\u043e \u0440\u0430\u045e\u043d\u044f\u0446\u0446\u0430 {{ compared_value }}.", "validators", "be");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0456\u0434\u044d\u043d\u0442\u044b\u0447\u043d\u044b\u043c {{ compared_value_type }} {{ compared_value }}.", "validators", "be");
t.add("This value should be less than {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u043c\u0435\u043d\u0448 \u0447\u044b\u043c {{ compared_value }}.", "validators", "be");
t.add("This value should be less than or equal to {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u043c\u0435\u043d\u0448 \u0447\u044b\u043c \u0430\u0431\u043e \u0440\u0430\u045e\u043d\u044f\u0446\u0446\u0430 {{ compared_value }}.", "validators", "be");
t.add("This value should not be equal to {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0440\u0430\u045e\u043d\u044f\u0446\u0446\u0430 {{ compared_value }}.", "validators", "be");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0456\u0434\u044d\u043d\u0442\u044b\u0447\u043d\u044b\u043c {{ compared_value_type }} {{ compared_value }}.", "validators", "be");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u0421\u0443\u0430\u0434\u043d\u043e\u0441\u0456\u043d\u044b \u0431\u0430\u043a\u043e\u045e \u0432\u044b\u044f\u0432\u044b \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u0432\u044f\u043b\u0456\u043a\u0456\u043c ({{ ratio }}). \u0414\u0430\u0437\u0432\u0430\u043b\u044f\u0435\u0446\u0446\u0430 \u043c\u0430\u043a\u0441\u0456\u043c\u0430\u043b\u044c\u043d\u044b\u044f \u0441\u0443\u0430\u0434\u043d\u043e\u0441\u0456\u043d\u044b {{max_ratio}} .", "validators", "be");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u0421\u0443\u0430\u0434\u043d\u043e\u0441\u0456\u043d\u044b \u0431\u0430\u043a\u043e\u045e \u0432\u044b\u044f\u0432\u044b \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u043c\u0430\u043b\u0435\u043d\u044c\u043a\u0456\u043c\u0456 ({{ ratio }}). \u0414\u0430\u0437\u0432\u0430\u043b\u044f\u0435\u0446\u0446\u0430 \u043c\u0456\u043d\u0456\u043c\u0430\u043b\u044c\u043d\u044b\u044f \u0441\u0443\u0430\u0434\u043d\u043e\u0441\u0456\u043d\u044b {{ min_ratio }}.", "validators", "be");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u0412\u044b\u044f\u0432\u0430 \u043a\u0432\u0430\u0434\u0440\u0430\u0442\u043d\u0430\u044f ({{width}}x{{height}}px). \u041a\u0432\u0430\u0434\u0440\u0430\u0442\u043d\u044b\u044f \u0432\u044b\u044f\u0432\u044b \u043d\u0435 \u0434\u0430\u0437\u0432\u043e\u043b\u0435\u043d\u044b.", "validators", "be");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u0412\u044b\u044f\u0432\u0430 \u045e \u0430\u043b\u044c\u0431\u043e\u043c\u043d\u0430\u0439 \u0430\u0440\u044b\u0435\u043d\u0442\u0430\u0446\u044b\u0456 ({{ width }}x{{ height }}px). \u0412\u044b\u044f\u0432\u044b \u045e \u0430\u043b\u044c\u0431\u043e\u043c\u043d\u0430\u0439 \u0430\u0440\u044b\u0435\u043d\u0442\u0430\u0446\u044b\u0456 \u043d\u0435 \u0434\u0430\u0437\u0432\u043e\u043b\u0435\u043d\u044b.", "validators", "be");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u0412\u044b\u044f\u0432\u0430 \u045e \u043f\u0430\u0440\u0442\u0440\u044d\u0442\u043d\u0430\u0439 \u0430\u0440\u044b\u0435\u043d\u0442\u0430\u0446\u044b\u0456 ({{ width }}x{{ height }}px). \u0412\u044b\u044f\u0432\u044b \u045e \u043f\u0430\u0440\u0442\u0440\u044d\u0442\u043d\u0430\u0439 \u0430\u0440\u044b\u0435\u043d\u0442\u0430\u0446\u044b\u0456 \u043d\u0435 \u0434\u0430\u0437\u0432\u043e\u043b\u0435\u043d\u044b.", "validators", "be");
t.add("An empty file is not allowed.", "\u041f\u0443\u0441\u0442\u044b \u0444\u0430\u0439\u043b \u043d\u0435 \u0434\u0430\u0437\u0432\u043e\u043b\u0435\u043d\u044b.", "validators", "be");
t.add("The host could not be resolved.", "\u041d\u0435 \u043c\u0430\u0433\u0447\u044b\u043c\u0430 \u0437\u043d\u0430\u0439\u0441\u0446\u0456 \u0456\u043c\u044f \u0445\u043e\u0441\u0442\u0430.", "validators", "be");
t.add("This value does not match the expected {{ charset }} charset.", "\u0413\u044d\u0442\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0441\u0443\u043f\u0430\u0434\u0430\u0435 \u0437 \u0447\u0430\u043a\u0430\u043d\u0430\u0439 {{ charset }} \u043a\u0430\u0434\u044b\u0440\u043e\u045e\u043a\u0430\u0439.", "validators", "be");
t.add("This is not a valid Business Identifier Code (BIC).", "\u041d\u0435\u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b \u0431\u0430\u043d\u043a\u0430\u045e\u0441\u043a\u0456 \u0456\u0434\u044d\u043d\u0442\u044b\u0444\u0456\u043a\u0430\u0446\u044b\u0439\u043d\u044b \u043a\u043e\u0434 (BIC).", "validators", "be");
t.add("Error", "\u041f\u0430\u043c\u044b\u043b\u043a\u0430", "validators", "be");
t.add("This is not a valid UUID.", "\u0413\u044d\u0442\u0430 \u043d\u0435\u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b UUID.", "validators", "be");
t.add("This value should be a multiple of {{ compared_value }}.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u043a\u0440\u0430\u0442\u043d\u044b\u043c {{ compared_value }}.", "validators", "be");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "\u0411\u0430\u043d\u043a\u0430\u045e\u0441\u043a\u0456 \u0456\u0434\u044d\u043d\u0442\u044b\u0444\u0456\u043a\u0430\u0446\u044b\u0439\u043d\u044b \u043a\u043e\u0434 (BIC) \u043d\u0435 \u0437\u0432\u044f\u0437\u0430\u043d \u0437 IBAN {{ iban }}.", "validators", "be");
t.add("This value should be valid JSON.", "\u0413\u044d\u0442\u0430 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0443 \u0444\u0430\u0440\u043c\u0430\u0446\u0435 JSON.", "validators", "be");
t.add("This collection should contain only unique elements.", "\u041a\u0430\u043b\u0435\u043a\u0446\u044b\u044f \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0437\u043c\u044f\u0448\u0447\u0430\u0446\u044c \u0442\u043e\u043b\u044c\u043a\u0456 \u045e\u043d\u0456\u043a\u0430\u043b\u044c\u043d\u044b\u044f \u044d\u043b\u0435\u043c\u0435\u043d\u0442\u044b.", "validators", "be");
t.add("This value should be positive.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0434\u0430\u0434\u0430\u0442\u043d\u044b\u043c.", "validators", "be");
t.add("This value should be either positive or zero.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0434\u0430\u0434\u0430\u0442\u043d\u044b\u043c \u0446\u0456 \u043d\u0443\u043b\u044c.", "validators", "be");
t.add("This value should be negative.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0430\u0434\u043c\u043e\u045e\u043d\u044b\u043c.", "validators", "be");
t.add("This value should be either negative or zero.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0430\u0434\u043c\u043e\u045e\u043d\u044b\u043c \u0446\u0456 \u043d\u0443\u043b\u044c.", "validators", "be");
t.add("This value is not a valid timezone.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c \u0433\u0430\u0434\u0437\u0456\u043d\u043d\u044b\u043c \u043f\u043e\u044f\u0441\u0430\u043c.", "validators", "be");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "\u0413\u044d\u0442\u044b \u043f\u0430\u0440\u043e\u043b\u044c \u0431\u044b\u045e \u0432\u044b\u043a\u0440\u0430\u0434\u0437\u0435\u043d\u044b \u045e \u0432\u044b\u043d\u0456\u043a\u0443 \u045e\u0437\u043b\u043e\u043c\u0443 \u0434\u0430\u0434\u0437\u0435\u043d\u044b\u0445, \u0442\u0430\u043c\u0443 \u044f\u0433\u043e \u043d\u0435\u043b\u044c\u0433\u0430 \u0432\u044b\u043a\u0430\u0440\u044b\u0441\u0442\u043e\u045e\u0432\u0430\u0446\u044c. \u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0432\u044b\u043a\u0430\u0440\u044b\u0441\u0442\u043e\u045e\u0432\u0430\u0439\u0446\u0435 \u0456\u043d\u0448\u044b \u043f\u0430\u0440\u043e\u043b\u044c.", "validators", "be");
t.add("This value should be between {{ min }} and {{ max }}.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u043f\u0430\u043c\u0456\u0436 {{min}} \u0456 {{max}}.", "validators", "be");
t.add("This value is not a valid hostname.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b\u043c \u0456\u043c\u0435\u043c \u0445\u0430\u0441\u0442\u0430.", "validators", "be");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "\u041a\u043e\u043b\u044c\u043a\u0430\u0441\u0446\u044c \u044d\u043b\u0435\u043c\u0435\u043d\u0442\u0430\u045e \u0443 \u0433\u044d\u0442\u0430\u0439 \u043a\u0430\u043b\u0435\u043a\u0446\u044b\u0456 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u043a\u0440\u0430\u0442\u043d\u044b\u043c {{compared_value}}.", "validators", "be");
t.add("This value should satisfy at least one of the following constraints:", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0437\u0430\u0434\u0430\u0432\u0430\u043b\u044c\u043d\u044f\u0446\u044c \u044f\u043a \u043c\u0456\u043d\u0456\u043c\u0443\u043c \u0430\u0434\u043d\u0430\u043c\u0443 \u0437 \u043d\u0430\u0441\u0442\u0443\u043f\u043d\u044b\u0445 \u0430\u0431\u043c\u0435\u0436\u0430\u0432\u0430\u043d\u043d\u044f\u045e:", "validators", "be");
t.add("Each element of this collection should satisfy its own set of constraints.", "\u041a\u043e\u0436\u043d\u044b \u044d\u043b\u0435\u043c\u0435\u043d\u0442 \u0433\u044d\u0442\u0430\u0439 \u043a\u0430\u043b\u0435\u043a\u0446\u044b\u0456 \u043f\u0430\u0432\u0456\u043d\u0435\u043d \u0437\u0430\u0434\u0430\u0432\u0430\u043b\u044c\u043d\u044f\u0446\u044c \u0441\u0432\u0430\u0439\u043c\u0443 \u045e\u043b\u0430\u0441\u043d\u0430\u043c\u0443 \u043d\u0430\u0431\u043e\u0440\u0443 \u0430\u0431\u043c\u0435\u0436\u0430\u0432\u0430\u043d\u043d\u044f\u045e.", "validators", "be");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b\u043c \u043c\u0456\u0436\u043d\u0430\u0440\u043e\u0434\u043d\u044b\u043c \u0456\u0434\u044d\u043d\u0442\u044b\u0444\u0456\u043a\u0430\u0446\u044b\u0439\u043d\u044b\u043c \u043d\u0443\u043c\u0430\u0440\u0430\u043c \u043a\u0430\u0448\u0442\u043e\u045e\u043d\u044b\u0445 \u043f\u0430\u043f\u0435\u0440 (ISIN).", "validators", "be");
t.add("This value should be a valid expression.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u043c \u0432\u044b\u0440\u0430\u0437\u0430\u043c.", "validators", "be");
t.add("This value is not a valid CSS color.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0434\u0430\u043f\u0443\u0448\u0447\u0430\u043b\u044c\u043d\u044b\u043c \u043a\u043e\u043b\u0435\u0440\u0430\u043c CSS.", "validators", "be");
t.add("This value is not a valid CIDR notation.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u0430\u0439 \u043d\u0430\u0442\u0430\u0446\u044b\u044f\u0439 CIDR.", "validators", "be");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u0441\u0435\u0442\u043a\u0430\u0432\u0430\u0439 \u043c\u0430\u0441\u043a\u0456 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u0431\u044b\u0446\u044c \u0430\u0434 {{min}} \u0434\u0430 {{max}}.", "validators", "be");
t.add("This form should not contain extra fields.", "\u0413\u044d\u0442\u0430 \u0444\u043e\u0440\u043c\u0430 \u043d\u0435 \u043f\u0430\u0432\u0456\u043d\u043d\u0430 \u043c\u0435\u0446\u044c \u0434\u0430\u0434\u0430\u0442\u043a\u043e\u0432\u044b\u0445 \u043f\u0430\u043b\u0435\u0439.", "validators", "be");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "\u0417\u0430\u043f\u0430\u043c\u043f\u0430\u0432\u0430\u043d\u044b \u0444\u0430\u0439\u043b \u0431\u044b\u045e \u0437\u0430\u043d\u0430\u0434\u0442\u0430 \u0432\u044f\u043b\u0456\u043a\u0456\u043c. \u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u043f\u0430\u0441\u043f\u0440\u0430\u0431\u0443\u0439\u0446\u0435 \u0437\u0430\u043f\u0430\u043c\u043f\u0430\u0432\u0430\u0446\u044c \u0444\u0430\u0439\u043b \u043c\u0435\u043d\u0448\u0430\u0433\u0430 \u043f\u0430\u043c\u0435\u0440\u0443.", "validators", "be");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF-\u0442\u043e\u043a\u0435\u043d \u043d\u0435 \u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b. \u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u043f\u0430\u0441\u043f\u0440\u0430\u0431\u0443\u0439\u0446\u0435 \u044f\u0448\u0447\u044d \u0440\u0430\u0437 \u0430\u0434\u043f\u0440\u0430\u0432\u0456\u0446\u044c \u0444\u043e\u0440\u043c\u0443.", "validators", "be");
t.add("This value is not a valid HTML5 color.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u043d\u0435 \u0437'\u044f\u045e\u043b\u044f\u0435\u0446\u0446\u0430 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b\u043c HTML5 \u043a\u043e\u043b\u0435\u0440\u0430\u043c.", "validators", "be");
t.add("Please enter a valid birthdate.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0443\u044e \u0434\u0430\u0442\u0443 \u043d\u0430\u0440\u0430\u0434\u0436\u044d\u043d\u043d\u044f.", "validators", "be");
t.add("The selected choice is invalid.", "\u0412\u044b\u0431\u0440\u0430\u043d\u044b \u0432\u0430\u0440\u044b\u044f\u043d\u0442 \u043d\u0435\u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b.", "validators", "be");
t.add("The collection is invalid.", "\u041a\u0430\u043b\u0435\u043a\u0446\u044b\u044f \u043d\u0435\u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0430.", "validators", "be");
t.add("Please select a valid color.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0432\u044b\u0431\u0435\u0440\u044b\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b \u043a\u043e\u043b\u0435\u0440.", "validators", "be");
t.add("Please select a valid country.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0432\u044b\u0431\u0435\u0440\u044b\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0443\u044e \u043a\u0440\u0430\u0456\u043d\u0443.", "validators", "be");
t.add("Please select a valid currency.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0432\u044b\u0431\u0435\u0440\u044b\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0443\u044e \u0432\u0430\u043b\u044e\u0442\u0443.", "validators", "be");
t.add("Please choose a valid date interval.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0432\u044b\u0431\u0435\u0440\u044b\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b \u0456\u043d\u0442\u044d\u0440\u0432\u0430\u043b \u0434\u0430\u0442.", "validators", "be");
t.add("Please enter a valid date and time.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b\u044f \u0434\u0430\u0442\u0443 \u0456 \u0447\u0430\u0441.", "validators", "be");
t.add("Please enter a valid date.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0443\u044e \u0434\u0430\u0442\u0443.", "validators", "be");
t.add("Please select a valid file.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0432\u044b\u0431\u0435\u0440\u044b\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b \u0444\u0430\u0439\u043b.", "validators", "be");
t.add("The hidden field is invalid.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0435 \u0441\u0445\u0430\u0432\u0430\u043d\u0430\u0433\u0430 \u043f\u043e\u043b\u044f \u043d\u0435\u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0430.", "validators", "be");
t.add("Please enter an integer.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u0446\u044d\u043b\u044b \u043b\u0456\u043a.", "validators", "be");
t.add("Please select a valid language.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0432\u044b\u0431\u0435\u0440\u044b\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0443\u044e \u043c\u043e\u0432\u0443.", "validators", "be");
t.add("Please select a valid locale.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0432\u044b\u0431\u0435\u0440\u044b\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0443\u044e \u043b\u0430\u043a\u0430\u043b\u044c.", "validators", "be");
t.add("Please enter a valid money amount.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0443\u044e \u043a\u043e\u043b\u044c\u043a\u0430\u0441\u0446\u044c \u0433\u0440\u043e\u0448\u0430\u0439.", "validators", "be");
t.add("Please enter a number.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043d\u0443\u043c\u0430\u0440.", "validators", "be");
t.add("The password is invalid.", "\u041d\u044f\u043f\u0440\u0430\u0432\u0456\u043b\u044c\u043d\u044b \u043f\u0430\u0440\u043e\u043b\u044c.", "validators", "be");
t.add("Please enter a percentage value.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043f\u0440\u0430\u0446\u044d\u043d\u0442\u043d\u0430\u0435 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435.", "validators", "be");
t.add("The values do not match.", "\u0417\u043d\u0430\u0447\u044d\u043d\u043d\u0456 \u043d\u0435 \u0441\u0443\u043f\u0430\u0434\u0430\u044e\u0446\u044c.", "validators", "be");
t.add("Please enter a valid time.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b \u0447\u0430\u0441.", "validators", "be");
t.add("Please select a valid timezone.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0432\u044b\u0431\u0435\u0440\u044b\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b \u0433\u0430\u0434\u0437\u0456\u043d\u043d\u044b \u043f\u043e\u044f\u0441.", "validators", "be");
t.add("Please enter a valid URL.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b URL.", "validators", "be");
t.add("Please enter a valid search term.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b \u043f\u043e\u0448\u0443\u043a\u0430\u0432\u044b \u0437\u0430\u043f\u044b\u0442.", "validators", "be");
t.add("Please provide a valid phone number.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b \u043d\u0443\u043c\u0430\u0440 \u0442\u044d\u043b\u0435\u0444\u043e\u043d\u0430.", "validators", "be");
t.add("The checkbox has an invalid value.", "\u0424\u043b\u0430\u0436\u043e\u043a \u043c\u0430\u0435 \u043d\u0435\u043a\u0430\u0440\u044d\u043a\u0442\u043d\u0430\u0435 \u0437\u043d\u0430\u0447\u044d\u043d\u043d\u0435.", "validators", "be");
t.add("Please enter a valid email address.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b \u0430\u0434\u0440\u0430\u0441 \u044d\u043b\u0435\u043a\u0442\u0440\u043e\u043d\u043d\u0430\u0439 \u043f\u043e\u0448\u0442\u044b.", "validators", "be");
t.add("Please select a valid option.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0432\u044b\u0431\u0435\u0440\u044b\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b \u0432\u0430\u0440\u044b\u044f\u043d\u0442.", "validators", "be");
t.add("Please select a valid range.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0432\u044b\u0431\u0435\u0440\u044b\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b \u0434\u044b\u044f\u043f\u0430\u0437\u043e\u043d.", "validators", "be");
t.add("Please enter a valid week.", "\u041a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u0443\u0432\u044f\u0434\u0437\u0456\u0446\u0435 \u043a\u0430\u0440\u044d\u043a\u0442\u043d\u044b \u0442\u044b\u0434\u0437\u0435\u043d\u044c.", "validators", "be");
})(Translator);
