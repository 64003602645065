(function (t) {
// zh_TW
t.add("This value should be false.", "\u8a72\u8b8a\u6578\u7684\u503c\u61c9\u70ba false \u3002", "validators", "zh_TW");
t.add("This value should be true.", "\u8a72\u8b8a\u6578\u7684\u503c\u61c9\u70ba true \u3002", "validators", "zh_TW");
t.add("This value should be of type {{ type }}.", "\u8a72\u8b8a\u6578\u7684\u985e\u578b\u61c9\u70ba {{ type }} \u3002", "validators", "zh_TW");
t.add("This value should be blank.", "\u8a72\u8b8a\u6578\u61c9\u70ba\u7a7a\u3002", "validators", "zh_TW");
t.add("The value you selected is not a valid choice.", "\u9078\u5b9a\u8b8a\u6578\u7684\u503c\u4e0d\u662f\u6709\u6548\u7684\u9078\u9805\u3002", "validators", "zh_TW");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u60a8\u81f3\u5c11\u8981\u9078\u64c7 {{ limit }} \u500b\u9078\u9805\u3002", "validators", "zh_TW");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u60a8\u6700\u591a\u80fd\u9078\u64c7 {{ limit }} \u500b\u9078\u9805\u3002", "validators", "zh_TW");
t.add("One or more of the given values is invalid.", "\u4e00\u500b\u6216\u8005\u591a\u500b\u7d66\u5b9a\u7684\u503c\u7121\u6548\u3002", "validators", "zh_TW");
t.add("This field was not expected.", "\u6b64\u5b57\u6bb5\u662f\u6c92\u6709\u9810\u6599\u5230\u3002", "validators", "zh_TW");
t.add("This field is missing.", "\u6b64\u5b57\u6bb5\u7f3a\u5931\u3002", "validators", "zh_TW");
t.add("This value is not a valid date.", "\u8a72\u503c\u4e0d\u662f\u4e00\u500b\u6709\u6548\u7684\u65e5\u671f\uff08date\uff09\u3002", "validators", "zh_TW");
t.add("This value is not a valid datetime.", "\u8a72\u503c\u4e0d\u662f\u4e00\u500b\u6709\u6548\u7684\u65e5\u671f\u6642\u9593\uff08datetime\uff09\u3002", "validators", "zh_TW");
t.add("This value is not a valid email address.", "\u8a72\u503c\u4e0d\u662f\u4e00\u500b\u6709\u6548\u7684\u90f5\u4ef6\u5730\u5740\u3002", "validators", "zh_TW");
t.add("The file could not be found.", "\u627e\u4e0d\u5230\u6a94\u6848\u3002", "validators", "zh_TW");
t.add("The file is not readable.", "\u7121\u6cd5\u8b80\u53d6\u6a94\u6848\u3002", "validators", "zh_TW");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u6a94\u6848\u592a\u5927 ({{ size }} {{ suffix }})\u3002\u6a94\u6848\u5927\u5c0f\u4e0d\u53ef\u4ee5\u8d85\u904e {{ limit }} {{ suffix }} \u3002", "validators", "zh_TW");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "\u7121\u6548\u7684\u6a94\u6848\u985e\u578b ({{ type }}) \u3002\u5141\u8a31\u7684\u6a94\u6848\u985e\u578b\u6709 {{ types }} \u3002", "validators", "zh_TW");
t.add("This value should be {{ limit }} or less.", "\u9019\u500b\u8b8a\u6578\u7684\u503c\u61c9\u8a72\u5c0f\u65bc\u6216\u7b49\u65bc {{ limit }}\u3002", "validators", "zh_TW");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u5b57\u4e32\u592a\u9577\uff0c\u9577\u5ea6\u4e0d\u53ef\u8d85\u904e {{ limit }} \u500b\u5b57\u5143\u3002", "validators", "zh_TW");
t.add("This value should be {{ limit }} or more.", "\u8a72\u8b8a\u6578\u7684\u503c\u61c9\u8a72\u5927\u65bc\u6216\u7b49\u65bc {{ limit }}\u3002", "validators", "zh_TW");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u5b57\u4e32\u592a\u77ed\uff0c\u9577\u5ea6\u4e0d\u53ef\u5c11\u65bc {{ limit }} \u500b\u5b57\u5143\u3002", "validators", "zh_TW");
t.add("This value should not be blank.", "\u8a72\u8b8a\u6578\u4e0d\u61c9\u70ba\u7a7a\u767d\u3002", "validators", "zh_TW");
t.add("This value should not be null.", "\u8a72\u503c\u4e0d\u61c9\u70ba null \u3002", "validators", "zh_TW");
t.add("This value should be null.", "\u8a72\u503c\u61c9\u70ba null \u3002", "validators", "zh_TW");
t.add("This value is not valid.", "\u7121\u6548\u7684\u6578\u503c \u3002", "validators", "zh_TW");
t.add("This value is not a valid time.", "\u8a72\u503c\u4e0d\u662f\u4e00\u500b\u6709\u6548\u7684\u6642\u9593\u3002", "validators", "zh_TW");
t.add("This value is not a valid URL.", "\u8a72\u503c\u4e0d\u662f\u4e00\u500b\u6709\u6548\u7684 URL \u3002", "validators", "zh_TW");
t.add("The two values should be equal.", "\u9019\u5169\u500b\u8b8a\u6578\u7684\u503c\u61c9\u8a72\u76f8\u7b49\u3002", "validators", "zh_TW");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u6a94\u6848\u592a\u5927\uff0c\u6a94\u6848\u5927\u5c0f\u4e0d\u53ef\u4ee5\u8d85\u904e {{ limit }} {{ suffix }}\u3002 ", "validators", "zh_TW");
t.add("The file is too large.", "\u6a94\u6848\u592a\u5927\u3002", "validators", "zh_TW");
t.add("The file could not be uploaded.", "\u7121\u6cd5\u4e0a\u50b3\u6b64\u6a94\u6848\u3002", "validators", "zh_TW");
t.add("This value should be a valid number.", "\u8a72\u503c\u61c9\u8a72\u70ba\u6709\u6548\u7684\u6578\u5b57\u3002", "validators", "zh_TW");
t.add("This file is not a valid image.", "\u8a72\u6a94\u6848\u4e0d\u662f\u6709\u6548\u7684\u5716\u7247\u3002", "validators", "zh_TW");
t.add("This is not a valid IP address.", "\u8a72\u503c\u4e0d\u662f\u6709\u6548\u7684IP\u5730\u5740\u3002", "validators", "zh_TW");
t.add("This value is not a valid language.", "\u8a72\u503c\u4e0d\u662f\u6709\u6548\u7684\u8a9e\u8a00\u540d\u3002", "validators", "zh_TW");
t.add("This value is not a valid locale.", "\u8a72\u503c\u4e0d\u662f\u6709\u6548\u7684\u5340\u57df\u503c\uff08locale\uff09\u3002", "validators", "zh_TW");
t.add("This value is not a valid country.", "\u8a72\u503c\u4e0d\u662f\u6709\u6548\u7684\u570b\u5bb6\u540d\u3002", "validators", "zh_TW");
t.add("This value is already used.", "\u8a72\u503c\u5df2\u7d93\u88ab\u4f7f\u7528\u3002", "validators", "zh_TW");
t.add("The size of the image could not be detected.", "\u4e0d\u80fd\u89e3\u6790\u5716\u7247\u5927\u5c0f\u3002", "validators", "zh_TW");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u5716\u7247\u592a\u5bec ({{ width }}px)\uff0c\u6700\u5927\u5bec\u5ea6\u70ba {{ max_width }}px \u3002", "validators", "zh_TW");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u5716\u7247\u5bec\u5ea6\u4e0d\u5920 ({{ width }}px)\uff0c\u6700\u5c0f\u5bec\u5ea6\u70ba {{ min_width }}px \u3002", "validators", "zh_TW");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u5716\u7247\u592a\u9ad8 ({{ height }}px)\uff0c\u6700\u5927\u9ad8\u5ea6\u70ba {{ max_height }}px \u3002", "validators", "zh_TW");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u5716\u7247\u9ad8\u5ea6\u4e0d\u5920 ({{ height }}px)\uff0c\u6700\u5c0f\u9ad8\u5ea6\u70ba {{ min_height }}px \u3002", "validators", "zh_TW");
t.add("This value should be the user's current password.", "\u8a72\u8b8a\u6578\u7684\u503c\u61c9\u70ba\u7528\u6236\u76ee\u524d\u7684\u5bc6\u78bc\u3002", "validators", "zh_TW");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u8a72\u8b8a\u6578\u61c9\u70ba {{ limit }} \u500b\u5b57\u5143\u3002", "validators", "zh_TW");
t.add("The file was only partially uploaded.", "\u8a72\u6a94\u6848\u7684\u4e0a\u50b3\u4e0d\u5b8c\u6574\u3002", "validators", "zh_TW");
t.add("No file was uploaded.", "\u6c92\u6709\u4e0a\u50b3\u4efb\u4f55\u6a94\u6848\u3002", "validators", "zh_TW");
t.add("No temporary folder was configured in php.ini.", "php.ini \u88e1\u6c92\u6709\u914d\u7f6e\u81e8\u6642\u76ee\u9304\u3002", "validators", "zh_TW");
t.add("Cannot write temporary file to disk.", "\u66ab\u5b58\u6a94\u5beb\u5165\u78c1\u789f\u5931\u6557\u3002", "validators", "zh_TW");
t.add("A PHP extension caused the upload to fail.", "\u67d0\u500b PHP \u64f4\u5c55\u9020\u6210\u4e0a\u50b3\u5931\u6557\u3002", "validators", "zh_TW");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u8a72\u96c6\u5408\u6700\u5c11\u61c9\u5305\u542b {{ limit }} \u500b\u5143\u7d20\u3002", "validators", "zh_TW");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u8a72\u96c6\u5408\u6700\u591a\u5305\u542b {{ limit }} \u500b\u5143\u7d20\u3002", "validators", "zh_TW");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u8a72\u96c6\u5408\u61c9\u5305\u542b {{ limit }} \u500b\u5143\u7d20 element \u3002", "validators", "zh_TW");
t.add("Invalid card number.", "\u7121\u6548\u7684\u4fe1\u7528\u5361\u865f\u3002", "validators", "zh_TW");
t.add("Unsupported card type or invalid card number.", "\u4e0d\u652f\u63f4\u7684\u4fe1\u7528\u5361\u985e\u578b\u6216\u7121\u6548\u7684\u4fe1\u7528\u5361\u865f\u3002", "validators", "zh_TW");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u8a72\u503c\u4e0d\u662f\u6709\u6548\u7684\u570b\u969b\u9280\u884c\u5e33\u865f\uff08IBAN\uff09\u3002", "validators", "zh_TW");
t.add("This value is not a valid ISBN-10.", "\u8a72\u503c\u4e0d\u662f\u6709\u6548\u768410\u4f4d\u570b\u969b\u6a19\u6e96\u66f8\u865f\uff08ISBN-10\uff09\u3002", "validators", "zh_TW");
t.add("This value is not a valid ISBN-13.", "\u8a72\u503c\u4e0d\u662f\u6709\u6548\u768413\u4f4d\u570b\u969b\u6a19\u6e96\u66f8\u865f\uff08ISBN-13\uff09\u3002", "validators", "zh_TW");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u8a72\u503c\u4e0d\u662f\u6709\u6548\u7684\u570b\u969b\u6a19\u6e96\u66f8\u865f\uff08ISBN-10 \u6216 ISBN-13\uff09\u3002", "validators", "zh_TW");
t.add("This value is not a valid ISSN.", "\u8a72\u503c\u4e0d\u662f\u6709\u6548\u7684\u570b\u969b\u6a19\u6e96\u671f\u520a\u865f\uff08ISSN\uff09\u3002", "validators", "zh_TW");
t.add("This value is not a valid currency.", "\u8a72\u503c\u4e0d\u662f\u6709\u6548\u7684\u8ca8\u5e63\u540d\uff08currency\uff09\u3002", "validators", "zh_TW");
t.add("This value should be equal to {{ compared_value }}.", "\u8a72\u503c\u61c9\u7b49\u65bc {{ compared_value }} \u3002", "validators", "zh_TW");
t.add("This value should be greater than {{ compared_value }}.", "\u8a72\u503c\u61c9\u5927\u65bc {{ compared_value }} \u3002", "validators", "zh_TW");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u8a72\u503c\u61c9\u5927\u65bc\u6216\u7b49\u65bc {{ compared_value }} \u3002", "validators", "zh_TW");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u8a72\u503c\u61c9\u8207 {{ compared_value_type }} {{ compared_value }} \u76f8\u540c\u3002", "validators", "zh_TW");
t.add("This value should be less than {{ compared_value }}.", "\u8a72\u503c\u61c9\u5c0f\u65bc {{ compared_value }} \u3002", "validators", "zh_TW");
t.add("This value should be less than or equal to {{ compared_value }}.", "\u8a72\u503c\u61c9\u5c0f\u65bc\u6216\u7b49\u65bc {{ compared_value }} \u3002", "validators", "zh_TW");
t.add("This value should not be equal to {{ compared_value }}.", "\u8a72\u503c\u61c9\u4e0d\u7b49\u65bc {{ compared_value }} \u3002", "validators", "zh_TW");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u8a72\u503c\u4e0d\u61c9\u8207 {{ compared_value_type }} {{ compared_value }} \u76f8\u540c\u3002", "validators", "zh_TW");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u5716\u50cf\u683c\u5f0f\u904e\u5927 ({{ ratio }})\u3002 \u6700\u5927\u5141\u8a31\u5c3a\u5bf8 {{ max_ratio }}\u3002", "validators", "zh_TW");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u5716\u50cf\u683c\u5f0f\u904e\u5c0f ({{ ratio }})\u3002\u6700\u5c0f\u5c3a\u5bf8 {{ min_ratio }}\u3002", "validators", "zh_TW");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u65b9\u5f62\u5716\u50cf ({{ width }}x{{ height }}px)\u3002\u4e0d\u63a5\u53d7\u65b9\u5f62\u5716\u50cf\u3002", "validators", "zh_TW");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u7d00\u5ff5\u518a\u5e03\u5c40\u5716\u50cf ({{ width }}x{{ height }}px)\u3002 \u4e0d\u63a5\u53d7\u7d00\u5ff5\u518a\u5e03\u5c40\u5716\u50cf\u3002", "validators", "zh_TW");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u66f8\u7c4d\u5e03\u5c40\u5716\u50cf ({{ width }}x{{ height }}px)\u3002\u4e0d\u63a5\u53d7\u5716\u50cf\u66f8\u7c4d\u5e03\u5c40\u3002", "validators", "zh_TW");
t.add("An empty file is not allowed.", "\u4e0d\u63a5\u53d7\u7a7a\u767d\u6587\u4ef6\u3002", "validators", "zh_TW");
t.add("The host could not be resolved.", "\u672a\u627e\u5230\u670d\u52d9\u5668\u3002", "validators", "zh_TW");
t.add("This value does not match the expected {{ charset }} charset.", "\u8a72\u6578\u503c\u4e0d\u7b26\u5408\u9810\u671f {{ charset }} \u7b26\u865f\u7de8\u78bc\u3002", "validators", "zh_TW");
t.add("This is not a valid Business Identifier Code (BIC).", "\u7121\u6548\u4f01\u696d\u8b58\u5225\u78bc (BIC)\u3002", "validators", "zh_TW");
t.add("Error.", "\u932f\u8aa4\u3002", "validators", "zh_TW");
t.add("This is not a valid UUID.", "\u7121\u6548\u7684\u901a\u7528\u552f\u58f9\u6a19\u8b58\u7b26 (UUID)\u3002", "validators", "zh_TW");
t.add("This value should be a multiple of {{ compared_value }}.", "\u8a72\u503c\u5fc5\u9808\u662f\u500d\u6578 {{ compared_value }}\u3002", "validators", "zh_TW");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "\u8a72\u4f01\u696d\u8b58\u5225\u78bc (BIC) \u8207\u9280\u884c\u8cec\u6236\u570b\u969b\u7de8\u865f\u4e0d\u58f9\u81f4 (IBAN) {{ iban }}\u3002", "validators", "zh_TW");
t.add("This value should be valid JSON.", "\u8a72\u6578\u503c\u5fc5\u9808\u5e8f\u5217\u5316\u70baJSON\u683c\u5f0f\u3002", "validators", "zh_TW");
t.add("This collection should contain only unique elements.", "\u8a72\u96c6\u5408\u61c9\u50c5\u5305\u542b\u552f\u58f9\u5143\u7d20\u3002", "validators", "zh_TW");
t.add("This value should be positive.", "\u6578\u503c\u61c9\u70ba\u6b63\u6578\u3002", "validators", "zh_TW");
t.add("This value should be either positive or zero.", "\u6578\u503c\u61c9\u662f\u6b63\u6578\uff0c\u6216\u70ba\u96f6\u3002", "validators", "zh_TW");
t.add("This value should be negative.", "\u6578\u503c\u61c9\u70ba\u8ca0\u6578\u3002", "validators", "zh_TW");
t.add("This value should be either negative or zero.", "\u6578\u503c\u61c9\u662f\u8ca0\u6578\uff0c\u6216\u70ba\u96f6\u3002", "validators", "zh_TW");
t.add("This value is not a valid timezone.", "\u7121\u6548\u6642\u5340\u3002", "validators", "zh_TW");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "\u6b64\u5bc6\u78bc\u5df2\u88ab\u6cc4\u9732\uff0c\u5207\u52ff\u4f7f\u7528\u3002\u8acb\u66f4\u63db\u5bc6\u78bc\u3002", "validators", "zh_TW");
t.add("This value should be between {{ min }} and {{ max }}.", "\u8a72\u6578\u503c\u61c9\u5728 {{ min }} \u548c {{ max }} \u4e4b\u9593\u3002", "validators", "zh_TW");
t.add("This value is not a valid hostname.", "\u8a72\u6578\u503c\u4e0d\u662f\u6709\u6548\u7684\u4e3b\u6a5f\u540d\u7a31\u3002", "validators", "zh_TW");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "\u8a72\u96c6\u5408\u5167\u7684\u5143\u7d20\u6578\u91cf\u5f97\u662f {{ compared_value }} \u7684\u500d\u6578\u3002", "validators", "zh_TW");
t.add("This value should satisfy at least one of the following constraints:", "\u8a72\u6578\u503c\u9700\u7b26\u5408\u4ee5\u4e0b\u5176\u4e2d\u4e00\u500b\u7d04\u675f\uff1a", "validators", "zh_TW");
t.add("Each element of this collection should satisfy its own set of constraints.", "\u8a72\u96c6\u5408\u5167\u7684\u6bcf\u500b\u5143\u7d20\u9700\u7b26\u5408\u5143\u7d20\u672c\u8eab\u898f\u5b9a\u7684\u7d04\u675f\u3002", "validators", "zh_TW");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "\u8a72\u6578\u503c\u4e0d\u662f\u6709\u6548\u7684\u570b\u969b\u8b49\u5238\u8b58\u5225\u78bc \uff08ISIN\uff09\u3002", "validators", "zh_TW");
t.add("This value should be a valid expression.", "\u8a72\u503c\u9700\u70ba\u4e00\u500b\u6709\u6548\u7684\u8868\u9054\u5f0f\u3002", "validators", "zh_TW");
t.add("This value is not a valid CSS color.", "\u8a72\u503c\u4e0d\u662f\u6709\u6548\u7684CSS\u984f\u8272\u3002", "validators", "zh_TW");
t.add("This value is not a valid CIDR notation.", "\u8a72\u503c\u4e0d\u662f\u4e00\u500b\u6709\u6548\u7684CIDR\u8868\u793a\u3002", "validators", "zh_TW");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "\u7db2\u7d61\u63a9\u78bc\u7684\u503c\u61c9\u7576\u5728 {{ min }} \u548c {{ max }} \u4e4b\u9593\u3002", "validators", "zh_TW");
t.add("This form should not contain extra fields.", "\u8a72\u8868\u55ae\u4e2d\u4e0d\u53ef\u6709\u984d\u5916\u5b57\u6bb5\u3002", "validators", "zh_TW");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "\u4e0a\u50b3\u6587\u4ef6\u592a\u5927\uff0c \u8acb\u91cd\u65b0\u5617\u8a66\u4e0a\u50b3\u4e00\u500b\u8f03\u5c0f\u7684\u6587\u4ef6\u3002", "validators", "zh_TW");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF \u9a57\u8b49\u7b26\u7121\u6548\uff0c \u8acb\u91cd\u65b0\u63d0\u4ea4\u3002", "validators", "zh_TW");
t.add("This value is not a valid HTML5 color.", "\u8a72\u6578\u503c\u4e0d\u662f\u500b\u6709\u6548\u7684 HTML5 \u984f\u8272\u3002", "validators", "zh_TW");
t.add("Please enter a valid birthdate.", "\u8acb\u8f38\u5165\u6709\u6548\u7684\u751f\u65e5\u65e5\u671f\u3002", "validators", "zh_TW");
t.add("The selected choice is invalid.", "\u6240\u9078\u7684\u9078\u9805\u7121\u6548\u3002", "validators", "zh_TW");
t.add("The collection is invalid.", "\u96c6\u5408\u7121\u6548\u3002", "validators", "zh_TW");
t.add("Please select a valid color.", "\u8acb\u9078\u64c7\u6709\u6548\u7684\u984f\u8272\u3002", "validators", "zh_TW");
t.add("Please select a valid country.", "\u8acb\u9078\u64c7\u6709\u6548\u7684\u570b\u5bb6\u3002", "validators", "zh_TW");
t.add("Please select a valid currency.", "\u8acb\u9078\u64c7\u6709\u6548\u7684\u8ca8\u5e63\u3002", "validators", "zh_TW");
t.add("Please choose a valid date interval.", "\u8acb\u9078\u64c7\u6709\u6548\u7684\u65e5\u671f\u9593\u9694\u3002", "validators", "zh_TW");
t.add("Please enter a valid date and time.", "\u8acb\u8f38\u5165\u6709\u6548\u7684\u65e5\u671f\u8207\u6642\u9593\u3002", "validators", "zh_TW");
t.add("Please enter a valid date.", "\u8acb\u8f38\u5165\u6709\u6548\u7684\u65e5\u671f\u3002", "validators", "zh_TW");
t.add("Please select a valid file.", "\u8acb\u9078\u64c7\u6709\u6548\u7684\u6587\u4ef6\u3002", "validators", "zh_TW");
t.add("The hidden field is invalid.", "\u96b1\u85cf\u5b57\u6bb5\u7121\u6548\u3002", "validators", "zh_TW");
t.add("Please enter an integer.", "\u8acb\u8f38\u5165\u6574\u6578\u3002", "validators", "zh_TW");
t.add("Please select a valid language.", "\u8acb\u9078\u64c7\u6709\u6548\u7684\u8a9e\u8a00\u3002", "validators", "zh_TW");
t.add("Please select a valid locale.", "\u8acb\u9078\u64c7\u6709\u6548\u7684\u8a9e\u8a00\u74b0\u5883\u3002", "validators", "zh_TW");
t.add("Please enter a valid money amount.", "\u8acb\u8f38\u5165\u6b63\u78ba\u7684\u91d1\u984d\u3002", "validators", "zh_TW");
t.add("Please enter a number.", "\u8acb\u8f38\u5165\u6578\u5b57\u3002", "validators", "zh_TW");
t.add("The password is invalid.", "\u5bc6\u78bc\u7121\u6548\u3002", "validators", "zh_TW");
t.add("Please enter a percentage value.", "\u8acb\u8f38\u5165\u767e\u5206\u6bd4\u503c\u3002", "validators", "zh_TW");
t.add("The values do not match.", "\u6578\u503c\u4e0d\u5339\u914d\u3002", "validators", "zh_TW");
t.add("Please enter a valid time.", "\u8acb\u8f38\u5165\u6709\u6548\u7684\u6642\u9593\u3002", "validators", "zh_TW");
t.add("Please select a valid timezone.", "\u8acb\u9078\u64c7\u6709\u6548\u7684\u6642\u5340\u3002", "validators", "zh_TW");
t.add("Please enter a valid URL.", "\u8acb\u8f38\u5165\u6709\u6548\u7684\u7db2\u5740\u3002", "validators", "zh_TW");
t.add("Please enter a valid search term.", "\u8acb\u8f38\u5165\u6709\u6548\u7684\u641c\u7d22\u8a5e\u3002", "validators", "zh_TW");
t.add("Please provide a valid phone number.", "\u8acb\u63d0\u4f9b\u6709\u6548\u7684\u624b\u6a5f\u865f\u78bc\u3002", "validators", "zh_TW");
t.add("The checkbox has an invalid value.", "\u7121\u6548\u7684\u9078\u6846\u503c\u3002", "validators", "zh_TW");
t.add("Please enter a valid email address.", "\u8acb\u8f38\u5165\u6709\u6548\u7684\u96fb\u5b50\u90f5\u4ef6\u5730\u5740\u3002", "validators", "zh_TW");
t.add("Please select a valid option.", "\u8acb\u9078\u64c7\u6709\u6548\u7684\u9078\u9805\u3002", "validators", "zh_TW");
t.add("Please select a valid range.", "\u8acb\u9078\u64c7\u6709\u6548\u7684\u7bc4\u570d\u3002", "validators", "zh_TW");
t.add("Please enter a valid week.", "\u8acb\u8f38\u5165\u6709\u6548\u7684\u661f\u671f\u3002", "validators", "zh_TW");
})(Translator);
