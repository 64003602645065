(function (t) {
// hy
t.add("This value should be false.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u056f\u0565\u0572\u056e\u0589", "validators", "hy");
t.add("This value should be true.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u056b\u0580\u0561\u056f\u0561\u0576\u0589", "validators", "hy");
t.add("This value should be of type {{ type }}.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b {{ type }} \u057f\u0565\u057d\u0561\u056f\u056b\u0589", "validators", "hy");
t.add("This value should be blank.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0564\u0561\u057f\u0561\u0580\u056f\u0589", "validators", "hy");
t.add("The value you selected is not a valid choice.", "\u0541\u0565\u0580 \u0568\u0576\u057f\u0580\u0561\u056e \u0561\u0580\u056a\u0565\u0584\u0568 \u0561\u0576\u057e\u0561\u057e\u0565\u0580 \u0568\u0576\u057f\u0580\u0578\u0582\u0569\u0575\u0578\u0582\u0576 \u0567\u0589", "validators", "hy");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u0534\u0578\u0582\u0584 \u057a\u0565\u057f\u0584 \u0567 \u0568\u0576\u057f\u0580\u0565\u0584 \u0561\u0574\u0565\u0576\u0561\u0584\u056b\u0579\u0568 {{ limit }} \u057f\u0561\u0580\u0562\u0565\u0580\u0561\u056f\u0576\u0565\u0580\u0589", "validators", "hy");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u0534\u0578\u0582\u0584 \u057a\u0565\u057f\u0584 \u0567 \u0568\u0576\u057f\u0580\u0565\u0584 \u0578\u0579 \u0561\u057e\u0565\u056c\u056b \u0584\u0561\u0576 {{ limit }} \u057f\u0561\u0580\u0562\u0565\u0580\u0561\u056f\u0576\u0565\u0580\u0589", "validators", "hy");
t.add("One or more of the given values is invalid.", "\u0544\u0565\u056f \u056f\u0561\u0574 \u0561\u057e\u0565\u056c\u056b \u057f\u0580\u057e\u0561\u056e \u0561\u0580\u056a\u0565\u0584\u0576\u0565\u0580\u0568 \u0561\u0576\u057e\u0561\u057e\u0565\u0580 \u0565\u0576\u0589", "validators", "hy");
t.add("This field was not expected.", "\u0531\u0575\u057d \u0564\u0561\u0577\u057f\u0568 \u0579\u056b \u057d\u057a\u0561\u057d\u057e\u0578\u0582\u0574\u0589", "validators", "hy");
t.add("This field is missing.", "\u0531\u0575\u057d \u0564\u0561\u0577\u057f\u0568 \u0562\u0561\u0581\u0561\u056f\u0561\u0575\u0578\u0582\u0574 \u0567\u0589", "validators", "hy");
t.add("This value is not a valid date.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057d\u056d\u0561\u056c \u0561\u0574\u057d\u0561\u0569\u056b\u057e \u0567\u0589", "validators", "hy");
t.add("This value is not a valid datetime.", "\u0531\u0574\u057d\u0561\u0569\u057e\u056b \u0587 \u056a\u0561\u0574\u0561\u0576\u0561\u056f\u056b \u0561\u0580\u056a\u0565\u0584\u0568 \u0561\u0576\u057e\u0561\u057e\u0565\u0580 \u0567\u0589", "validators", "hy");
t.add("This value is not a valid email address.", "\u0531\u0576\u057e\u0561\u057e\u0565\u0580 \u0567\u056c\u058a\u0583\u0578\u057d\u057f\u056b \u0561\u0580\u056a\u0565\u0584\u0589", "validators", "hy");
t.add("The file could not be found.", "\u0546\u056b\u0577\u0584\u0568 \u0579\u056b \u0563\u057f\u0576\u057e\u0565\u056c\u0589", "validators", "hy");
t.add("The file is not readable.", "\u0546\u056b\u0577\u0584\u0568 \u0561\u0576\u0568\u0576\u0569\u0565\u057c\u0576\u0565\u056c\u056b \u0567\u0589", "validators", "hy");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0546\u056b\u0577\u0584\u0568 \u0579\u0561\u0583\u0561\u0566\u0561\u0576\u0581 \u0574\u0565\u056e \u0567 ({{ size }} {{ suffix }}): \u0544\u0561\u0584\u057d\u056b\u0574\u0561\u056c \u0569\u0578\u0582\u0575\u056c\u0561\u057f\u0580\u0565\u056c\u056b \u0579\u0561\u0583\u057d\u0568\u055d {{ limit }} {{ suffix }}\u0589", "validators", "hy");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "MIME-\u057f\u0565\u057d\u0561\u056f\u0568 \u0561\u0576\u057e\u0561\u057e\u0565\u0580 \u0567 \u0567({{ type }}): \u0546\u056b\u0577\u0584\u0565\u0580\u056b \u0569\u0578\u0582\u0575\u056c\u0561\u057f\u0580\u0565\u056c\u056b MIME-\u057f\u0565\u057d\u0561\u056f\u0576\u0565\u0580\u0576 \u0565\u0576: {{ types }}\u0589", "validators", "hy");
t.add("This value should be {{ limit }} or less.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b {{ limit }} \u056f\u0561\u0574 \u0583\u0578\u0584\u0580\u0589", "validators", "hy");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u0579\u0561\u0583\u0561\u0566\u0561\u0576\u0581 \u0565\u0580\u056f\u0561\u0580 \u0567: \u054a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b {{ limit }} \u056f\u0561\u0574 \u0561\u057e\u0565\u056c \u057d\u056b\u0574\u057e\u0578\u056c\u0576\u0565\u0580\u0589", "validators", "hy");
t.add("This value should be {{ limit }} or more.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f \u0567 \u056c\u056b\u0576\u056b {{ limit }} \u056f\u0561\u0574 \u0577\u0561\u057f\u0589", "validators", "hy");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u0579\u0561\u0583\u0561\u0566\u0561\u0576\u0581 \u056f\u0561\u0580\u0573 \u0567: \u054a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b {{ limit }} \u056f\u0561\u0574 \u0561\u057e\u0565\u056c\u056b \u057d\u056b\u0574\u057e\u0578\u056c\u0576\u0565\u0580\u0589", "validators", "hy");
t.add("This value should not be blank.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u0579\u057a\u0565\u057f\u0584 \u0567 \u0564\u0561\u057f\u0561\u0580\u056f \u056c\u056b\u0576\u056b\u0589", "validators", "hy");
t.add("This value should not be null.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u0579\u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b null\u0589", "validators", "hy");
t.add("This value should be null.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b null\u0589", "validators", "hy");
t.add("This value is not valid.", "\u0531\u0576\u057e\u0561\u057e\u0565\u0580 \u0561\u0580\u056a\u0565\u0584\u0589", "validators", "hy");
t.add("This value is not a valid time.", "\u053a\u0561\u0574\u0561\u0576\u0561\u056f\u056b \u0561\u0580\u056a\u0565\u0584\u0568 \u0561\u0576\u057e\u0561\u057e\u0565\u0580 \u0567\u0589", "validators", "hy");
t.add("This value is not a valid URL.", "\u0531\u0580\u056a\u0565\u0584\u0568  URL  \u0579\u0567\u0589", "validators", "hy");
t.add("The two values should be equal.", "\u0535\u0580\u056f\u0578\u0582 \u0561\u0580\u056a\u0565\u0584\u0576\u0565\u0580\u0568 \u057a\u0565\u057f\u0584 \u0567 \u0576\u0578\u0582\u0575\u0576\u0568 \u056c\u056b\u0576\u0565\u0576\u0589", "validators", "hy");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u0546\u056b\u0577\u0584\u0568 \u0579\u0561\u0583\u0561\u0566\u0561\u0576\u0581 \u0574\u0565\u056e \u0567: \u0544\u0561\u0584\u057d\u056b\u0574\u0561\u056c \u0569\u0578\u0582\u0575\u056c\u0561\u057f\u0580\u0565\u056c\u056b \u0579\u0561\u0583\u057d\u0568 {{ limit }} {{ suffix }} \u0567\u0589", "validators", "hy");
t.add("The file is too large.", "\u0546\u056b\u0577\u0584\u0568 \u0579\u0561\u0583\u0561\u0566\u0561\u0576\u0581 \u0574\u0565\u056e \u0567\u0589", "validators", "hy");
t.add("The file could not be uploaded.", "\u0546\u056b\u0577\u0584\u0568 \u0579\u056b \u056f\u0561\u0580\u0578\u0572 \u0562\u0565\u057c\u0576\u057e\u0565\u056c\u0589", "validators", "hy");
t.add("This value should be a valid number.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0569\u056b\u057e\u0589", "validators", "hy");
t.add("This file is not a valid image.", "\u0546\u056b\u0577\u0584\u0568 \u0576\u056f\u0561\u0580\u056b \u057e\u0561\u057e\u0565\u0580 \u0586\u0578\u0580\u0574\u0561\u057f \u0579\u0567\u0589", "validators", "hy");
t.add("This is not a valid IP address.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057e\u0561\u057e\u0565\u0580 IP \u0570\u0561\u057d\u0581\u0565 \u0579\u0567\u0589", "validators", "hy");
t.add("This value is not a valid language.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057e\u0561\u057e\u0565\u0580 \u056c\u0565\u0566\u0578\u0582 \u0579\u0567\u0589", "validators", "hy");
t.add("This value is not a valid locale.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u0579\u056b \u0570\u0561\u0576\u0564\u056b\u057d\u0561\u0576\u0578\u0582\u0574 \u057e\u0561\u057e\u0565\u0580 \u057f\u0565\u0572\u0561\u0575\u0576\u0561\u0581\u0578\u0582\u0574\u0589", "validators", "hy");
t.add("This value is not a valid country.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0565\u0580\u056f\u056b\u0580\u0589", "validators", "hy");
t.add("This value is already used.", "\u0531\u0575\u0564 \u0561\u0580\u056a\u0565\u0584\u0576 \u0561\u0580\u0564\u0565\u0576 \u0585\u0563\u057f\u0561\u0563\u0578\u0580\u056e\u057e\u0578\u0582\u0574 \u0567\u0589", "validators", "hy");
t.add("The size of the image could not be detected.", "\u0546\u056f\u0561\u0580\u056b \u0579\u0561\u0583\u057d\u0565\u0580\u0568 \u0579\u057d\u057f\u0561\u0581\u057e\u0565\u0581 \u0578\u0580\u0578\u0577\u0565\u056c\u0589", "validators", "hy");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u0546\u056f\u0561\u0580\u056b \u056c\u0561\u0575\u0576\u0578\u0582\u0569\u0575\u0578\u0582\u0576\u0568 \u0579\u0561\u0583\u0561\u0566\u0561\u0576\u0581 \u0574\u0565\u056e \u0567({{ width }}px). \u0544\u0561\u0584\u057d\u056b\u0574\u0561\u056c \u0579\u0561\u0583\u0576 \u0567 {{ max_width }}px\u0589", "validators", "hy");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u0546\u056f\u0561\u0580\u056b \u056c\u0561\u0575\u0576\u0578\u0582\u0569\u0575\u0578\u0582\u0576\u0568 \u0579\u0561\u0583\u0561\u0566\u0561\u0576\u0581 \u0583\u0578\u0584\u0580 \u0567 ({{ width }}px). \u0544\u056b\u0576\u056b\u0574\u0561\u056c \u0579\u0561\u0583\u0576 \u0567 {{ min_ width }}px\u0589", "validators", "hy");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u0546\u056f\u0561\u0580\u056b \u0562\u0561\u0580\u0571\u0580\u0578\u0582\u0569\u0575\u0578\u0582\u0576\u0568 \u0579\u0561\u0583\u0561\u0566\u0561\u0576\u0581 \u0574\u0565\u056e \u0567 ({{ height }}px). \u0544\u0561\u0584\u057d\u056b\u0574\u0561\u056c \u0579\u0561\u0583\u0576 \u0567 {{ max_height }}px\u0589", "validators", "hy");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u0546\u056f\u0561\u0580\u056b \u0562\u0561\u0580\u0571\u0580\u0578\u0582\u0569\u0575\u0578\u0582\u0576\u0568 \u0579\u0561\u0583\u0561\u0566\u0561\u0576\u0581 \u0583\u0578\u0584\u0580 \u0567 ({{ height }}px). \u0544\u056b\u0576\u056b\u0574\u0561\u056c \u0579\u0561\u0583\u0576 \u0567 {{ min_height }}px\u0589", "validators", "hy");
t.add("This value should be the user's current password.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0585\u0563\u057f\u0561\u0563\u0578\u0580\u056e\u0578\u0572\u056b \u0576\u0565\u0580\u056f\u0561 \u056e\u0561\u056e\u056f\u0561\u0563\u056b\u0580\u0568\u0589", "validators", "hy");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u0578\u0582\u0576\u0565\u0576\u0561 \u0573\u056b\u0577\u057f {{ limit }} \u057d\u056b\u0574\u057e\u0578\u056c\u0576\u0565\u0580\u0589", "validators", "hy");
t.add("The file was only partially uploaded.", "\u0546\u056b\u0577\u0584\u056b \u0574\u0561\u057d\u0576\u0561\u056f\u056b \u0562\u0565\u057c\u0576\u0574\u0561\u0576 \u057d\u056d\u0561\u056c\u0589", "validators", "hy");
t.add("No file was uploaded.", "\u0546\u056b\u0577\u0584\u0568 \u0579\u056b \u0562\u0565\u057c\u0576\u057e\u0565\u056c\u0589", "validators", "hy");
t.add("No temporary folder was configured in php.ini.", "php.ini \u0576\u056b\u0577\u0584\u0578\u0582\u0574 \u056a\u0561\u0574\u0561\u0576\u0561\u056f\u0561\u057e\u0578\u0580 \u057a\u0561\u0576\u0561\u056f \u0576\u0577\u057e\u0561\u056e \u0579\u0567\u0589", "validators", "hy");
t.add("Cannot write temporary file to disk.", "\u053a\u0561\u0574\u0561\u0576\u0561\u056f\u0561\u057e\u0578\u0580 \u0576\u056b\u0577\u0584\u0568 \u0570\u0576\u0561\u0580\u0561\u057e\u0578\u0580 \u0579\u0567 \u0563\u0580\u0565\u056c \u057d\u056f\u0561\u057e\u0561\u057c\u0561\u056f\u056b \u057e\u0580\u0561\u0589", "validators", "hy");
t.add("A PHP extension caused the upload to fail.", "PHP \u0586\u0578\u0580\u0574\u0561\u057f\u0568 \u0564\u0561\u0580\u0571\u0565\u056c \u0567 \u0562\u0565\u057c\u0576\u0574\u0561\u0576 \u0579\u0570\u0561\u057b\u0578\u0572\u0574\u0561\u0576 \u057a\u0561\u057f\u0573\u0561\u057c\u0589", "validators", "hy");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u0531\u0575\u057d \u0570\u0561\u0574\u0561\u056d\u0578\u0582\u0574\u0562\u0568 \u057a\u0565\u057f\u0584 \u0567 \u057a\u0561\u0578\u0582\u0580\u0561\u056f\u056b {{ limit }} \u056f\u0561\u0574 \u0561\u057e\u0565\u056c\u056b \u057f\u0561\u0580\u0580\u0565\u0580\u0589|\u0531\u0575\u057d \u0570\u0561\u057e\u0565\u056c\u057e\u0561\u056e\u0568 \u057a\u0565\u057f\u0584 \u0567 \u057a\u0561\u0580\u0578\u0582\u0576\u0561\u056f\u056b limit }} \u057f\u0561\u0580\u0580 \u056f\u0561\u0574 \u0561\u057e\u0565\u056c\u056b\u0576\u0589|\u0531\u0575\u057d \u0570\u0561\u0574\u0561\u056d\u0578\u0582\u0574\u0562\u0568 \u057a\u0565\u057f\u0584 \u0567 \u057a\u0561\u0580\u0578\u0582\u0576\u0561\u056f\u056b {{ limit }} \u057f\u0561\u0580\u0580\u0565\u0580\u056b\u0576 \u056f\u0561\u0574 \u0561\u057e\u0565\u056c\u056b\u0589", "validators", "hy");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u0531\u0575\u057d \u0570\u0561\u0574\u0561\u056d\u0578\u0582\u0574\u0562\u0568 \u057a\u0565\u057f\u0584 \u0567 \u057a\u0561\u0578\u0582\u0580\u0561\u056f\u056b {{ limit }} \u057f\u0561\u0580\u0580\u0565\u0580 \u056f\u0561\u0574 \u0584\u056b\u0579\u0589|\u0531\u0575\u057d \u0570\u0561\u0574\u0561\u056d\u0578\u0582\u0574\u0562\u0568 \u057a\u0565\u057f\u0584 \u0567 \u057a\u0561\u0578\u0582\u0580\u0561\u056f\u056b {{ limit }} \u057f\u0561\u0580\u0580 \u056f\u0561\u0574 \u0584\u056b\u0579\u0589|\u0531\u0575\u057d \u0570\u0561\u0574\u0561\u056d\u0578\u0582\u0574\u0562\u0568 \u057a\u0565\u057f\u0584 \u0567 \u057a\u0561\u0578\u0582\u0580\u0561\u056f\u056b {{ limit }} \u057f\u0561\u0580\u0580\u0565\u0580 \u056f\u0561\u0574 \u0584\u056b\u0579\u0589", "validators", "hy");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u0531\u0575\u057d \u0570\u0561\u0574\u0561\u056d\u0578\u0582\u0574\u0562\u0568 \u057a\u0565\u057f\u0584 \u0567 \u057a\u0561\u0578\u0582\u0580\u0561\u056f\u056b \u0578\u0582\u0572\u056b\u0572 {{ limit }} \u057f\u0561\u0580\u0580\u0589|\u0531\u0575\u057d \u0570\u0561\u0574\u0561\u056d\u0578\u0582\u0574\u0562\u0568 \u057a\u0565\u057f\u0584 \u0567 \u057a\u0561\u0578\u0582\u0580\u0561\u056f\u056b \u0578\u0582\u0572\u056b\u0572 {{ limit }} \u057f\u0561\u0580\u0580\u0565\u0580\u0589|\u0531\u0575\u057d \u0570\u0561\u0574\u0561\u056d\u0578\u0582\u0574\u0562\u0568 \u057a\u0565\u057f\u0584 \u0567 \u057a\u0561\u0578\u0582\u0580\u0561\u056f\u056b {{ limit }} \u057f\u0561\u0580\u0580\u0565\u0580\u0589", "validators", "hy");
t.add("Invalid card number.", "\u0554\u0561\u0580\u057f\u056b \u057d\u056d\u0561\u056c \u0570\u0561\u0574\u0561\u0580:", "validators", "hy");
t.add("Unsupported card type or invalid card number.", "\u0549\u057d\u057a\u0561\u057d\u0561\u0580\u056f\u057e\u0578\u0572 \u056f\u0561\u0574 \u057d\u056d\u0561\u056c \u0584\u0561\u0580\u057f\u056b \u0570\u0561\u0574\u0561\u0580:", "validators", "hy");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057e\u0561\u057e\u0565\u0580 \u0574\u056b\u057b\u0561\u0566\u0561\u0575\u056b\u0576 \u0562\u0561\u0576\u056f\u0561\u0575\u056b\u0576 \u0570\u0561\u0577\u057e\u056b \u0570\u0561\u0574\u0561\u0580 \u0579\u0567 (IBAN)\u0589", "validators", "hy");
t.add("This value is not a valid ISBN-10.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u0578\u0582\u0576\u056b \u0561\u0576\u057e\u0561\u057e\u0565\u0580 ISBN-10 \u0571\u0587\u0561\u0579\u0561\u0583\u0589", "validators", "hy");
t.add("This value is not a valid ISBN-13.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u0578\u0582\u0576\u056b \u0561\u0576\u057e\u0561\u057e\u0565\u0580 ISBN-13 \u0571\u0587\u0561\u0579\u0561\u0583\u0589", "validators", "hy");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u0579\u056b \u0570\u0561\u0574\u0561\u057a\u0561\u057f\u0561\u057d\u056d\u0561\u0576\u0578\u0582\u0574 ISBN-10 \u0587 ISBN-13 \u0571\u0587\u0561\u0579\u0561\u0583\u0565\u0580\u056b\u0576\u0589", "validators", "hy");
t.add("This value is not a valid ISSN.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u0579\u056b \u0570\u0561\u0574\u0561\u057a\u0561\u057d\u057f\u0561\u057d\u056d\u0561\u0576\u0578\u0582\u0574 ISSN \u0571\u0587\u0561\u0579\u0561\u0583\u056b\u0576\u0589", "validators", "hy");
t.add("This value is not a valid currency.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057e\u0561\u057e\u0565\u0580 \u057f\u0561\u0580\u0561\u0564\u0580\u0561\u0574 \u0579\u0567\u0589", "validators", "hy");
t.add("This value should be equal to {{ compared_value }}.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b {{ compared_value }}\u0589", "validators", "hy");
t.add("This value should be greater than {{ compared_value }}.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u0574\u0565\u056e \u056c\u056b\u0576\u056b, \u0584\u0561\u0576 {{ compared_value }}\u0589", "validators", "hy");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0570\u0561\u057e\u0561\u057d\u0561\u0580 \u056f\u0561\u0574 \u0574\u0565\u056e \u0584\u0561\u0576 {{ compared_value }}\u0589", "validators", "hy");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u056b\u0576\u0579\u057a\u0565\u057d {{ compared_value_type }} {{ compared_value }}\u0589", "validators", "hy");
t.add("This value should be less than {{ compared_value }}.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0583\u0578\u0584\u0580 \u0584\u0561\u0576 {{ compared_value }}\u0589", "validators", "hy");
t.add("This value should be less than or equal to {{ compared_value }}.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0583\u0578\u0584\u0580 \u056f\u0561\u0574 \u0570\u0561\u057e\u0561\u057d\u0561\u0580 {{ compared_value }}\u0589", "validators", "hy");
t.add("This value should not be equal to {{ compared_value }}.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0570\u0561\u057e\u0561\u057d\u0561\u0580 {{ compared_value }}\u0589", "validators", "hy");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0576\u0578\u0582\u0576\u0568 {{ compared_value_type }} {{ compared_value }}:", "validators", "hy");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u054a\u0561\u057f\u056f\u0565\u0580\u056b \u056f\u0578\u0572\u0574\u0565\u0580\u056b \u0570\u0561\u0580\u0561\u0562\u0565\u0580\u0561\u056f\u0581\u0578\u0582\u0569\u0575\u0578\u0582\u0576\u0568 \u056d\u056b\u057d\u057f \u0574\u0565\u056e \u0567 ({{ ratio }}). \u0544\u0561\u0584\u057d\u056b\u0574\u0561\u056c \u0570\u0561\u0580\u0561\u0562\u0565\u0580\u0561\u056f\u0581\u0578\u0582\u0569\u0575\u0578\u0582\u0576\u0568\u055d {{ max_ratio }}\u0589", "validators", "hy");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u054a\u0561\u057f\u056f\u0565\u0580\u056b \u056f\u0578\u0572\u0574\u0565\u0580\u056b \u0570\u0561\u0580\u0561\u0562\u0565\u0580\u0561\u056f\u0581\u0578\u0582\u0569\u0575\u0578\u0582\u0576\u0568 \u056d\u056b\u057d\u057f \u0583\u0578\u0584\u0580 \u0567 ({{ ratio }}). \u0544\u056b\u0576\u056b\u0574\u0561\u056c \u0570\u0561\u0580\u0561\u0562\u0565\u0580\u0561\u056f\u0581\u0578\u0582\u0569\u0575\u0578\u0582\u0576\u0568\u055d {{ min_ratio }}\u0589", "validators", "hy");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u054a\u0561\u057f\u056f\u0565\u0580\u0568 \u0584\u0561\u057c\u0561\u056f\u0578\u0582\u057d\u056b \u0567({{ width }}x{{ height }}px)\u0589 \u0554\u0561\u057c\u0561\u056f\u0578\u0582\u057d\u056b \u0576\u056f\u0561\u0580\u0576\u0565\u0580 \u0579\u0565\u0576 \u0569\u0578\u0582\u0575\u056c\u0561\u057f\u0580\u057e\u0578\u0582\u0574\u0589", "validators", "hy");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u054a\u0561\u057f\u056f\u0565\u0580\u0568 \u0561\u056c\u0562\u0578\u0574\u0561\u0575\u056b\u0576 \u0578\u0582\u0572\u0572\u057e\u0561\u056e\u0578\u0582\u0569\u0575\u0561\u0576 \u0567({{ width }}x{{ height }}px)\u2024 \u0564\u0561 \u0579\u056b \u0569\u0578\u0582\u0575\u056c\u0561\u057f\u0580\u057e\u0578\u0582\u0574\u0589", "validators", "hy");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u054a\u0561\u057f\u056f\u0565\u0580\u0568 \u057a\u0578\u0580\u057f\u0580\u0565\u057f\u0561\u0575\u056b\u0576 \u0578\u0582\u0572\u0572\u057e\u0561\u056e\u0578\u0582\u0569\u0575\u0561\u0576 \u0567 ({{ width }}x{{ height }}px)\u2024 \u0564\u0561 \u0579\u056b \u0569\u0578\u0582\u0575\u056c\u0561\u057f\u0580\u057e\u0578\u0582\u0574\u0589", "validators", "hy");
t.add("An empty file is not allowed.", "\u0534\u0561\u057f\u0561\u0580\u056f \u0576\u056b\u0577\u0584 \u0579\u056b \u0569\u0578\u0582\u0575\u056c\u0561\u057f\u0580\u057e\u0578\u0582\u0574\u0589", "validators", "hy");
t.add("The host could not be resolved.", "\u0540\u0578\u057d\u0569\u056b \u0561\u0576\u0578\u0582\u0576\u0568 \u0570\u0576\u0561\u0580\u0561\u057e\u0578\u0580 \u0579\u056b \u057a\u0561\u0580\u0566\u0565\u056c\u0589", "validators", "hy");
t.add("This value does not match the expected {{ charset }} charset.", "\u0531\u0580\u056a\u0565\u0584\u0568 \u0579\u056b \u0570\u0561\u0574\u0568\u0576\u056f\u0576\u0578\u0582\u0574 {{ charset }} \u056f\u0578\u0564\u0561\u057e\u0578\u0580\u0574\u0561\u0576 \u0570\u0565\u057f\u0589", "validators", "hy");
t.add("This is not a valid Business Identifier Code (BIC).", "\u054d\u0561 \u057e\u0561\u057e\u0565\u0580 Business Identifier Code (BIC) \u0579\u0567\u0589", "validators", "hy");
t.add("Error", "\u054d\u056d\u0561\u056c", "validators", "hy");
t.add("This is not a valid UUID.", "\u054d\u0561 \u057e\u0561\u057e\u0565\u0580 UUID \u0579\u0567\u0589", "validators", "hy");
t.add("This value should be a multiple of {{ compared_value }}.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0562\u0561\u0566\u0574\u0561\u056f\u056b {{ compared_value }}\u0589", "validators", "hy");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "\u0532\u056b\u0566\u0576\u0565\u057d\u056b \u0576\u0578\u0582\u0575\u0576\u0561\u056f\u0561\u0576\u0561\u0581\u0574\u0561\u0576 \u056f\u0578\u0564\u0568 (BIC) \u056f\u0561\u057a\u057e\u0561\u056e \u0579\u0567 IBAN- \u056b \u0570\u0565\u057f {{ iban }}\u0589", "validators", "hy");
t.add("This value should be valid JSON.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u057e\u0561\u057e\u0565\u0580 JSON\u0589", "validators", "hy");
t.add("This collection should contain only unique elements.", "\u0531\u0575\u057d \u0570\u0561\u0574\u0561\u056d\u0578\u0582\u0574\u0562\u0568 \u057a\u0565\u057f\u0584 \u0567 \u057a\u0561\u0580\u0578\u0582\u0576\u0561\u056f\u056b \u0574\u056b\u0561\u0575\u0576 \u0565\u0566\u0561\u056f\u056b \u057f\u0561\u0580\u0580\u0565\u0580\u0589", "validators", "hy");
t.add("This value should be positive.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0564\u0580\u0561\u056f\u0561\u0576\u0589", "validators", "hy");
t.add("This value should be either positive or zero.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0564\u0580\u0561\u056f\u0561\u0576 \u056f\u0561\u0574 \u0566\u0580\u0578\u0575\u0561\u056f\u0561\u0576\u0589", "validators", "hy");
t.add("This value should be negative.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0562\u0561\u0581\u0561\u057d\u0561\u056f\u0561\u0576\u0589", "validators", "hy");
t.add("This value should be either negative or zero.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u0562\u0561\u0581\u0561\u057d\u0561\u056f\u0561\u0576 \u056f\u0561\u0574 \u0566\u0580\u0578\u0575\u0561\u056f\u0561\u0576\u0589", "validators", "hy");
t.add("This value is not a valid timezone.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057e\u0561\u057e\u0565\u0580 \u056a\u0561\u0574\u0561\u0575\u056b\u0576 \u0563\u0578\u057f\u056b \u0579\u0567\u0589", "validators", "hy");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "\u0533\u0561\u0572\u057f\u0576\u0561\u0562\u0561\u057c\u0568 \u0563\u057f\u0576\u057e\u0565\u056c \u0567 \u057f\u057e\u0575\u0561\u056c\u0576\u0565\u0580\u056b \u0561\u0580\u057f\u0561\u0570\u0578\u057d\u0584\u0578\u0582\u0574. \u0561\u0575\u0576 \u0579\u057a\u0565\u057f\u0584 \u0567 \u0585\u0563\u057f\u0561\u0563\u0578\u0580\u056e\u057e\u056b: \u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0585\u0563\u057f\u0561\u0563\u0578\u0580\u056e\u0565\u056c \u0574\u0565\u056f \u0561\u0575\u056c \u0563\u0561\u0572\u057f\u0576\u0561\u0562\u0561\u057c\u0589", "validators", "hy");
t.add("This value should be between {{ min }} and {{ max }}.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b  {{ min }}-\u056b \u0587 {{ max }}-\u056b \u0574\u056b\u057b\u0587\u0589", "validators", "hy");
t.add("This value is not a valid hostname.", "\u0531\u0575\u057d \u0570\u0578\u057d\u057f\u056b \u0561\u0576\u0578\u0582\u0576\u0568 \u057e\u0561\u057e\u0565\u0580 \u0579\u0567\u0589", "validators", "hy");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}\u2024", "\u0531\u0575\u057d \u0570\u0561\u0574\u0561\u056d\u0574\u0562\u056b \u057f\u0561\u0580\u0580\u0565\u0580\u056b \u0584\u0561\u0576\u0561\u056f\u0568 \u057a\u0565\u057f\u0584 \u0567 \u0570\u0561\u057e\u0561\u057d\u0561\u0580 \u056c\u056b\u0576\u056b {{ compared_value }}-\u056b \u0562\u0561\u0566\u0574\u0561\u057a\u0561\u057f\u056b\u056f\u0576\u0565\u0580\u056b\u0576\u0589", "validators", "hy");
t.add("This value should satisfy at least one of the following constraints:", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u0562\u0561\u057e\u0561\u0580\u0561\u0580\u056b \u0570\u0565\u057f\u0587\u0575\u0561\u056c \u057d\u0561\u0570\u0574\u0561\u0576\u0561\u0583\u0561\u056f\u0578\u0582\u0574\u0576\u0565\u0580\u056b\u0581 \u0561\u057c\u0576\u057e\u0561\u0566\u0576 \u0574\u0565\u056f\u0568\u0589", "validators", "hy");
t.add("Each element of this collection should satisfy its own set of constraints.", "\u0531\u0575\u057d \u0570\u0561\u0574\u0561\u056d\u0574\u0562\u056b \u0575\u0578\u0582\u0580\u0561\u0584\u0561\u0576\u0579\u0575\u0578\u0582\u0580 \u057f\u0561\u0580\u0580 \u057a\u0565\u057f\u0584 \u0567 \u0562\u0561\u057e\u0561\u0580\u0561\u0580\u056b \u056b\u0580 \u057d\u0565\u0583\u0561\u056f\u0561\u0576 \u057d\u0561\u0570\u0574\u0561\u0576\u0561\u0583\u0561\u056f\u0578\u0582\u0574\u0576\u0565\u0580\u0568\u0589", "validators", "hy");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u0561\u0580\u056a\u0565\u0569\u0572\u0569\u0565\u0580\u056b \u0576\u0578\u0582\u0575\u0576\u0561\u056f\u0561\u0576\u0561\u0581\u0574\u0561\u0576 \u0574\u056b\u057b\u0561\u0566\u0563\u0561\u0575\u056b\u0576 \u0570\u0561\u0574\u0561\u0580\u0568 \u057e\u0561\u057e\u0565\u0580 \u0579\u0567(ISIN)\u0589", "validators", "hy");
t.add("This value should be a valid expression.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057a\u0565\u057f\u0584 \u0567 \u056c\u056b\u0576\u056b \u057e\u0561\u057e\u0565\u0580 \u0561\u0580\u057f\u0561\u0570\u0561\u0575\u057f\u0578\u0582\u0569\u0575\u0578\u0582\u0576:", "validators", "hy");
t.add("This form should not contain extra fields.", "\u0531\u0575\u057d \u0571\u0587\u0568 \u0579\u057a\u0565\u057f\u0584 \u0567 \u057a\u0561\u0580\u0578\u0582\u0576\u0561\u056f\u056b \u056c\u0580\u0561\u0581\u0578\u0582\u0581\u056b\u0579 \u057f\u0578\u0572\u0565\u0580\u0589", "validators", "hy");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "\u054e\u0565\u0580\u0562\u0565\u057c\u0576\u057e\u0561\u056e \u0586\u0561\u0575\u056c\u0568 \u0579\u0561\u0583\u0561\u0566\u0561\u0576\u0581 \u0574\u0565\u056e \u0567. \u053d\u0576\u0564\u0580\u057e\u0578\u0582\u0574 \u0567 \u057e\u0565\u0580\u0562\u0565\u057c\u0576\u0565\u056c \u0561\u057e\u0565\u056c\u056b \u0583\u0578\u0584\u0580 \u0579\u0561\u0583\u057d\u056b \u0586\u0561\u0575\u056c\u0589", "validators", "hy");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF \u0561\u0580\u056a\u0565\u0584\u0568 \u0561\u0576\u0569\u0578\u0582\u0575\u056c\u0561\u057f\u0580\u0565\u056c\u056b \u0567. \u0553\u0578\u0580\u0571\u0565\u0584 \u0576\u0578\u0580\u056b\u0581 \u0578\u0582\u0572\u0561\u0580\u056f\u0565\u056c \u0571\u0587\u0568\u0589", "validators", "hy");
t.add("This value is not a valid HTML5 color.", "\u0531\u0575\u057d \u0561\u0580\u056a\u0565\u0584\u0568 \u057e\u0561\u057e\u0565\u0580 HTML5 \u0563\u0578\u0582\u0575\u0576 \u0579\u0567\u0589", "validators", "hy");
t.add("Please enter a valid birthdate.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0574\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u056e\u0576\u0576\u0564\u0575\u0561\u0576 \u0561\u0574\u057d\u0561\u0569\u056b\u057e\u0589", "validators", "hy");
t.add("The selected choice is invalid.", "\u0538\u0576\u057f\u0580\u057e\u0561\u056e \u0568\u0576\u057f\u0580\u0578\u0582\u0569\u0575\u0578\u0582\u0576\u0576 \u0561\u0576\u057e\u0561\u057e\u0565\u0580 \u0567\u0589", "validators", "hy");
t.add("The collection is invalid.", "\u0540\u0561\u0574\u0561\u056d\u0578\u0582\u0574\u0562\u0576 \u0561\u0576\u057e\u0561\u057e\u0565\u0580 \u0567\u0589", "validators", "hy");
t.add("Please select a valid color.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0568\u0576\u057f\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u0563\u0578\u0582\u0575\u0576\u0589", "validators", "hy");
t.add("Please select a valid country.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0568\u0576\u057f\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u0565\u0580\u056f\u056b\u0580\u0589", "validators", "hy");
t.add("Please select a valid currency.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0568\u0576\u057f\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u0561\u0580\u056a\u0578\u0582\u0575\u0569\u0589", "validators", "hy");
t.add("Please choose a valid date interval.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0568\u0576\u057f\u0580\u0565\u056c \u0573\u056b\u0577\u057f \u0561\u0574\u057d\u0561\u0569\u057e\u0565\u0580\u056b \u0574\u056b\u057b\u0561\u056f\u0561\u0575\u0584\u0589", "validators", "hy");
t.add("Please enter a valid date and time.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0574\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u0561\u0574\u057d\u0561\u0569\u056b\u057e \u0587 \u056a\u0561\u0574\u0589", "validators", "hy");
t.add("Please enter a valid date.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0574\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u0561\u0574\u057d\u0561\u0569\u056b\u057e\u0589", "validators", "hy");
t.add("Please select a valid file.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0568\u0576\u057f\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u0586\u0561\u0575\u056c\u0589", "validators", "hy");
t.add("The hidden field is invalid.", "\u0539\u0561\u0584\u0576\u057e\u0561\u056e \u0564\u0561\u0577\u057f\u0568 \u0561\u0576\u057e\u0561\u057e\u0565\u0580 \u0567\u0589", "validators", "hy");
t.add("Please enter an integer.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0574\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u056c \u0561\u0574\u0562\u0578\u0572\u057b \u0569\u056b\u057e\u0589", "validators", "hy");
t.add("Please select a valid language.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0568\u0576\u057f\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u056c\u0565\u0566\u0578\u0582\u0589", "validators", "hy");
t.add("Please select a valid locale.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0568\u0576\u057f\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u057f\u0565\u0572\u0561\u0575\u0576\u0561\u0581\u0578\u0582\u0574\u0589", "validators", "hy");
t.add("Please enter a valid money amount.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0574\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u0563\u0578\u0582\u0574\u0561\u0580\u0589", "validators", "hy");
t.add("Please enter a number.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0574\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u056c \u0570\u0561\u0574\u0561\u0580\u0589", "validators", "hy");
t.add("The password is invalid.", "\u0533\u0561\u0572\u057f\u0576\u0561\u0562\u0561\u057c\u0576 \u0561\u0576\u057e\u0561\u057e\u0565\u0580 \u0567\u0589", "validators", "hy");
t.add("Please enter a percentage value.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0574\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u056c \u057f\u0578\u056f\u0578\u057d\u0561\u0575\u056b\u0576 \u0561\u0580\u056a\u0565\u0584\u0589", "validators", "hy");
t.add("The values do not match.", "\u0531\u0580\u056a\u0565\u0584\u0576\u0565\u0580\u0568 \u0579\u0565\u0576 \u0570\u0561\u0574\u0568\u0576\u056f\u0576\u0578\u0582\u0574\u0589", "validators", "hy");
t.add("Please enter a valid time.", "\u0544\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u0584 \u057e\u0561\u057e\u0565\u0580 \u056a\u0561\u0574\u0561\u0576\u0561\u056f\u0589", "validators", "hy");
t.add("Please select a valid timezone.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0568\u0576\u057f\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u056a\u0561\u0574\u0561\u0575\u056b\u0576 \u0563\u0578\u057f\u056b\u0589", "validators", "hy");
t.add("Please enter a valid URL.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0574\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 URL\u0589", "validators", "hy");
t.add("Please enter a valid search term.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0574\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u0578\u0580\u0578\u0576\u0574\u0561\u0576 \u057f\u0565\u0580\u0574\u056b\u0576\u0589", "validators", "hy");
t.add("Please provide a valid phone number.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u057f\u0580\u0561\u0574\u0561\u0564\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u0570\u0565\u057c\u0561\u056d\u0578\u057d\u0561\u0570\u0561\u0574\u0561\u0580\u0589", "validators", "hy");
t.add("The checkbox has an invalid value.", "\u0546\u0577\u0574\u0561\u0576 \u057e\u0561\u0576\u0564\u0561\u056f\u0568 \u0561\u0576\u057e\u0561\u057e\u0565\u0580 \u0561\u0580\u056a\u0565\u0584 \u0578\u0582\u0576\u056b\u0589", "validators", "hy");
t.add("Please enter a valid email address.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0574\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u0567\u056c-\u0570\u0561\u057d\u0581\u0565\u0589", "validators", "hy");
t.add("Please select a valid option.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0568\u0576\u057f\u0580\u0565\u056c \u0573\u056b\u0577\u057f \u057f\u0561\u0580\u0562\u0565\u0580\u0561\u056f\u0589", "validators", "hy");
t.add("Please select a valid range.", "\u053d\u0576\u0564\u0580\u0578\u0582\u0574 \u0565\u0576\u0584 \u0568\u0576\u057f\u0580\u0565\u056c \u057e\u0561\u057e\u0565\u0580 \u057f\u056b\u0580\u0578\u0582\u0575\u0569\u0589", "validators", "hy");
t.add("Please enter a valid week.", "\u0544\u0578\u0582\u057f\u0584\u0561\u0563\u0580\u0565\u0584 \u057e\u0561\u057e\u0565\u0580 \u0577\u0561\u0562\u0561\u0569\u0589", "validators", "hy");
})(Translator);
