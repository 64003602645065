(function (t) {
// fr
t.add("user.firstName", "Pr\u00e9nom", "form_labels", "fr");
t.add("user.lastName", "Nom", "form_labels", "fr");
t.add("user.email", "Email", "form_labels", "fr");
t.add("user.type", "Type", "form_labels", "fr");
t.add("user.password", "Mot de passe", "form_labels", "fr");
t.add("user.repeat_password", "R\u00e9p\u00e9tez mot de passe", "form_labels", "fr");
t.add("category.name", "Titre", "form_labels", "fr");
t.add("exercise.name", "Nom de l'exercice", "form_labels", "fr");
t.add("exercise.type", "Type de l'exercice", "form_labels", "fr");
t.add("exercise.description", "Description", "form_labels", "fr");
t.add("exercise.tools", "Accessoires", "form_labels", "fr");
t.add("exercise.intensity", "Charge ou Intensit\u00e9", "form_labels", "fr");
t.add("exercise.execution_speed", "Vitesse d'execution", "form_labels", "fr");
t.add("exercise.pdf.execution_speed", "Vitesse", "form_labels", "fr");
t.add("exercise.accuracy_speed", "Pr\u00e9cision vitesse", "form_labels", "fr");
t.add("exercise.intermediate_rest", "Repos interm\u00e9diaire", "form_labels", "fr");
t.add("exercise.repetitions", "R\u00e9p\u00e9titions ou temps", "form_labels", "fr");
t.add("exercise.comments", "Commentaires", "form_labels", "fr");
t.add("exercise.number_of_series", "Nombre de s\u00e9ries", "form_labels", "fr");
t.add("exercise.chain", "Enchainement", "form_labels", "fr");
t.add("exercise.framing", "Encadrement", "form_labels", "fr");
t.add("exercise.training_type", "Unilat\u00e9ral \/ Bilat\u00e9ral", "form_labels", "fr");
t.add("exercise.training_side", "Si uni, D ou G", "form_labels", "fr");
t.add("exercise.is_valid", "Valid\u00e9", "form_labels", "fr");
t.add("exercise.category", "Cat\u00e9gorie", "form_labels", "fr");
t.add("form_type.choose_an_option", "Choisir une option", "form_labels", "fr");
t.add("exercise.movement", "Mouvement", "form_labels", "fr");
t.add("exercise.video", "Voir la vid\u00e9o", "form_labels", "fr");
t.add("customer.firstName", "Pr\u00e9nom", "form_labels", "fr");
t.add("customer.lastName", "Nom", "form_labels", "fr");
t.add("customer.gender", "Sexe", "form_labels", "fr");
t.add("customer.gender.male", "Homme", "form_labels", "fr");
t.add("customer.gender.female", "Femme", "form_labels", "fr");
t.add("customer.birthDate", "Date de naissance", "form_labels", "fr");
t.add("customer.injuryDate", "Date de blessure", "form_labels", "fr");
t.add("customer.meniscus", "Menisque", "form_labels", "fr");
t.add("customer.lateralLigament", "Ligament lat\u00e9ral", "form_labels", "fr");
t.add("customer.lateral_ligament.intern", "LLI", "form_labels", "fr");
t.add("customer.lateral_ligament.extern", "LLE", "form_labels", "fr");
t.add("customer.lateral_ligament.both", "LLE+LLI", "form_labels", "fr");
t.add("customer.other", "Autre", "form_labels", "fr");
t.add("customer.comment", "Commentaire", "form_labels", "fr");
t.add("choice.boolean.no", "Non", "form_labels", "fr");
t.add("choice.boolean.yes", "Oui", "form_labels", "fr");
t.add("customer.operationDate", "Date d'op\u00e9ration", "form_labels", "fr");
t.add("customer.surgeon", "Chirurgien", "form_labels", "fr");
t.add("customer.graft", "Greffe", "form_labels", "fr");
t.add("customer.tenodesis", "T\u00e9nod\u00e8se", "form_labels", "fr");
t.add("customer.injuredSide", "C\u00f4t\u00e9 l\u00e9s\u00e9", "form_labels", "fr");
t.add("customer.side.left", "Gauche", "form_labels", "fr");
t.add("customer.side.right", "Droit", "form_labels", "fr");
t.add("customer.reathletisation", "R\u00e9athl\u00e9tisation", "form_labels", "fr");
t.add("customer.reathletisationAthletic", "R\u00e9athl\u00e9tisation Athletic", "form_labels", "fr");
t.add("customer.reathletisationPrecision", "Pr\u00e9cisions R\u00e9athl\u00e9tisation", "form_labels", "fr");
t.add("customer.surgicalAntecedentsContralateralSide", "Ant\u00e9c\u00e9dents chirurgicaux c\u00f4t\u00e9 controlat\u00e9ral", "form_labels", "fr");
t.add("customer.antecedentsType", "Type d'ant\u00e9c\u00e9dents", "form_labels", "fr");
t.add("customer.practicedSports", "Sport pratiqu\u00e9", "form_labels", "fr");
t.add("customer.activityFrequency", "Fr\u00e9quence activit\u00e9", "form_labels", "fr");
t.add("customer.activity_frequency.occasional", "Occasionelle", "form_labels", "fr");
t.add("customer.activity_frequency.regular", "R\u00e9guli\u00e8re", "form_labels", "fr");
t.add("customer.activity_frequency.intensive", "Intensive", "form_labels", "fr");
t.add("customer.activity_frequency.pro", "Pro", "form_labels", "fr");
t.add("customer.additionalInformation", "Informations compl\u00e9mentaires", "form_labels", "fr");
t.add("customer.handedness", "Membre dominant inf\u00e9rieur", "form_labels", "fr");
t.add("customer.dominant_side", "Cot\u00e9 dominant", "form_labels", "fr");
t.add("customer.upper_handedness", "Membre dominant sup\u00e9rieur", "form_labels", "fr");
t.add("customer.handedness.right", "Droit", "form_labels", "fr");
t.add("customer.handedness.left", "Gauche", "form_labels", "fr");
t.add("customer.group", "Groupe", "form_labels", "fr");
t.add("customer.height", "Taille (cm)", "form_labels", "fr");
t.add("customer.weight", "Poids (kg)", "form_labels", "fr");
t.add("customer.operation", "Op\u00e9ration", "form_labels", "fr");
t.add("choice.choose", "Choisir...", "form_labels", "fr");
t.add("customer.requestsNumber", "Nombre de sollicitations", "form_labels", "fr");
t.add("customer.personalId", "ID", "form_labels", "fr");
t.add("customer.createdBy", "Cr\u00e9\u00e9 par", "form_labels", "fr");
t.add("exercise.video_url", "Adresse de la video", "form_labels", "fr");
t.add("titles.exercise.back_to_list", "Retour \u00e0 la liste des exercices", "form_labels", "fr");
t.add("planning.title", "Nom", "form_labels", "fr");
t.add("planning.customer", "Clients", "form_labels", "fr");
t.add("planning.category", "Categorie", "form_labels", "fr");
t.add("planning.customerGroup", "Groupe de clients", "form_labels", "fr");
t.add("planning.groupPlanning", "Pour un groupe de clients ?", "form_labels", "fr");
t.add("planning_session.title", "Nom", "form_labels", "fr");
t.add("customer_group.title", "Nom du groupe", "form_labels", "fr");
t.add("customer_group.add_customers", "Ajouter des clients dans le groupe", "form_labels", "fr");
t.add("customer.fullName", "Nom du client", "form_labels", "fr");
t.add("customer.athletic_customer", "Client Athletic", "form_labels", "fr");
t.add("titles.planning_exercises.back_to_list", "Retour \u00e0 la liste", "form_labels", "fr");
t.add("planning_exercise.exercise", "Exercice", "form_labels", "fr");
t.add("add_to_session.select_customer", "Ajouter \u00e0 une s\u00e9ance 1\/2", "form_labels", "fr");
t.add("planning.select_customer", "Selectionner un client", "form_labels", "fr");
t.add("add_to_session.select_session", "Ajouter \u00e0 une s\u00e9ance 2\/2", "form_labels", "fr");
t.add("post.title", "Titre", "form_labels", "fr");
t.add("post.summary", "R\u00e9sum\u00e9 de l'article", "form_labels", "fr");
t.add("post.comments", "Commentaires Athletic", "form_labels", "fr");
t.add("post.url", "Lien vers l\u2019article complet", "form_labels", "fr");
t.add("test.type", "Choisissez un test", "form_labels", "fr");
t.add("test.customer", "Choisissez un client", "form_labels", "fr");
t.add("exercise.number_of_circuits", "N\u00b0 de circuit", "form_labels", "fr");
t.add("titles.courses.back_to_list", "Retour \u00e0 la liste des formations", "form_labels", "fr");
t.add("course.title", "Nom", "form_labels", "fr");
t.add("course.description", "Descriptif", "form_labels", "fr");
t.add("course.video_url", "Adresse de la video", "form_labels", "fr");
t.add("customer.email", "Mail", "form_labels", "fr");
t.add("customer.surgeonEmail", "Mail du Chirurgien", "form_labels", "fr");
t.add("customer.sportDoctorEmail", "Mail du M\u00e9decin du sport", "form_labels", "fr");
t.add("customer.secretariatEmail", "Mail du Secr\u00e9tariat", "form_labels", "fr");
t.add("customer.practiceLevel", "Niveau de pratique du sport principal", "form_labels", "fr");
t.add("customer.phone", "Num\u00e9ro de t\u00e9l\u00e9phone", "form_labels", "fr");
t.add("customer.prestation", "Prestation", "form_labels", "fr");
t.add("customer.graft.tr", "TR", "form_labels", "fr");
t.add("customer.graft.tq", "TQ", "form_labels", "fr");
t.add("customer.graft.dt", "DT", "form_labels", "fr");
t.add("customer.graft.didt", "DIDT", "form_labels", "fr");
t.add("customer.graft.fascia_lata", "Fascia lata", "form_labels", "fr");
t.add("customer.surgicalAntecedentsIpsilateralSide", "Ant\u00e9c\u00e9dents chirurgicaux c\u00f4t\u00e9 homolat\u00e9ral", "form_labels", "fr");
t.add("customer.tegnerScaleBeforeInjury", "Echelle Tegner avant la blessure", "form_labels", "fr");
t.add("customer.tegnerScaleNow", "Echelle Tegner apr\u00e8s la blessure", "form_labels", "fr");
t.add("customer.injury", "Blessure", "form_labels", "fr");
t.add("customer.injury.lca", "LCA", "form_labels", "fr");
t.add("customer.injury.rlca", "RLCA", "form_labels", "fr");
t.add("customer.injury.rrlca", "RRLCA", "form_labels", "fr");
t.add("customer.practice.leisure", "Loisir", "form_labels", "fr");
t.add("customer.practice.department", "D\u00e9partemental", "form_labels", "fr");
t.add("customer.practice.region", "R\u00e9gional", "form_labels", "fr");
t.add("customer.practice.national", "National", "form_labels", "fr");
t.add("customer.practice.international", "International", "form_labels", "fr");
t.add("customer.practice.professional", "Professionnel", "form_labels", "fr");
t.add("customer.shoulder.lesion_type.fall_shoulder", "Chute \u00e9paule", "form_labels", "fr");
t.add("customer.shoulder.lesion_type.fall_hands", "Chute coude\/main", "form_labels", "fr");
t.add("customer.shoulder.lesion_type.armed", "Arm\u00e9\/contr\u00e9", "form_labels", "fr");
t.add("customer.shoulder.lesion_type.launched", "Lanc\u00e9", "form_labels", "fr");
t.add("customer.shoulder.lesion_type.shock", "Choc", "form_labels", "fr");
t.add("datatable.actions", "Actions", "form_labels", "fr");
t.add("course.documents_list", "Documents", "form_labels", "fr");
t.add("course.therapists_list", "Liste des th\u00e9rapeutes", "form_labels", "fr");
t.add("course.modal.remove_therapist", "Supprimer le th\u00e9rapeute ?", "form_labels", "fr");
t.add("course.table_header.therapists", "Th\u00e9rapeutes", "form_labels", "fr");
t.add("course.table_header.actions", "Actions", "form_labels", "fr");
t.add("course.modal.add_therapist", "Ajouter un th\u00e9rapeute", "form_labels", "fr");
t.add("course.therapist", "Th\u00e9rapeute", "form_labels", "fr");
t.add("customer.tests", "Liste des tests effectu\u00e9s ", "form_labels", "fr");
t.add("customer.planning_list", "Liste des planifications", "form_labels", "fr");
t.add("contact.subject", "Sujet", "form_labels", "fr");
t.add("contact.message", "Message", "form_labels", "fr");
t.add("duplicate_planning_session.planning", "Planification", "form_labels", "fr");
t.add("planning_session.startDate", "Pr\u00e9vue le", "form_labels", "fr");
t.add("customer.operatedSide", "C\u00f4t\u00e9 op\u00e9r\u00e9", "form_labels", "fr");
t.add("customer.graftType", "Type de greffe", "form_labels", "fr");
t.add("customer.testDate", "Date du test", "form_labels", "fr");
t.add("customer.beforeInjury", "Avant blessure", "form_labels", "fr");
t.add("customer.afterInjury", "Apr\u00e8s blessure", "form_labels", "fr");
t.add("course.image_list", "Images", "form_labels", "fr");
t.add("customer.graft.tr_controlateral", "TR controlat\u00e9ral", "form_labels", "fr");
t.add("customer.graft.didt_controlateral", "DIDT controlat\u00e9ral", "form_labels", "fr");
t.add("course.accessible_test_types", "Tests accessibles", "form_labels", "fr");
t.add("course.accessible_exercise_categories", "Cat\u00e9gories d'exercices accessibles", "form_labels", "fr");
t.add("course.accessible_planning_client_categories", "Cat\u00e9gories de planification client accessibles", "form_labels", "fr");
t.add("course.course_category", "Cat\u00e9gorie de formation", "form_labels", "fr");
t.add("course.course_categories", "Cat\u00e9gories de formation", "form_labels", "fr");
t.add("course_category.name", "Nom de la cat\u00e9gorie de formation", "form_labels", "fr");
t.add("costomer.meniscus.mi", "MI", "form_labels", "fr");
t.add("costomer.meniscus.me", "ME", "form_labels", "fr");
t.add("costomer.meniscus.mi_me", "MI+ME", "form_labels", "fr");
t.add("customer.injury.lca_bilateral", "LCA Bilat\u00e9ral", "form_labels", "fr");
t.add("customer.injury.mpfl", "MPFL", "form_labels", "fr");
t.add("customer.injury.meniscus", "M\u00e9nisque", "form_labels", "fr");
t.add("customer.injury.ll", "LL", "form_labels", "fr");
t.add("customer.injury.osteo", "Ost\u00e9o", "form_labels", "fr");
t.add("customer.injury.sdr", "SDR", "form_labels", "fr");
t.add("customer.injury.sdr_bilateral", "SDR Bilat\u00e9ral", "form_labels", "fr");
t.add("customer.injury.cp", "LCP", "form_labels", "fr");
t.add("user.profilePicture", "Image de profil", "form_labels", "fr");
t.add("customer.shoulder.injury_type", "Type de blessure", "form_labels", "fr");
t.add("customer.shoulder.shoulder_instability", "Instabilit\u00e9 d'\u00e9paule", "form_labels", "fr");
t.add("customer.shoulder.total_prosthesis", "Proth\u00e8se totale", "form_labels", "fr");
t.add("customer.shoulder.rotator_cuff", "Rupture coiffe des rotateurs", "form_labels", "fr");
t.add("customer.shoulder.acromio_clavicular_dislocation", "Luxation acromio claviculaire\n        ", "form_labels", "fr");
t.add("customer.shoulder.rupture_of_the_glenoid_bourlet", "Rupture du bourlet gl\u00e9noide", "form_labels", "fr");
t.add("customer.shoulder.shoulder_instability_anterior", "Instabilit\u00e9 ant\u00e9rieure", "form_labels", "fr");
t.add("customer.shoulder.shoulder_instability_posterior", "Instabilit\u00e9 post\u00e9rieure", "form_labels", "fr");
t.add("customer.shoulder.shoulder_instability_multidirectionnel", "Instabilit\u00e9 multidirectionnel", "form_labels", "fr");
t.add("customer.shoulder.clavicular_artropathy", "Arthropathie acromio claviculaire", "form_labels", "fr");
t.add("customer.shoulder.thendinopathy_calcific", "Tendinopathie calcifiante", "form_labels", "fr");
t.add("customer.shoulder.clavicul_broken", "Fracture clavicule", "form_labels", "fr");
t.add("customer.shoulder.slap_lesion", "SLAP lesion", "form_labels", "fr");
t.add("customer.shoulder.disjonction_acromio_clavicular", "Disjonction acromio-claviculaire", "form_labels", "fr");
t.add("customer.shoulder.act", "Acte", "form_labels", "fr");
t.add("customer.shoulder.act_anterior_stop", "But\u00e9e ant\u00e9rieure (Latarjet)", "form_labels", "fr");
t.add("customer.shoulder.act_posterior_stop", "But\u00e9e post\u00e9rieure", "form_labels", "fr");
t.add("customer.shoulder.capsuloplasty", "Capsuloplastie", "form_labels", "fr");
t.add("customer.shoulder.act_ablation", "Ablation", "form_labels", "fr");
t.add("customer.shoulder.act_reintegration", "R\u00e9insertion", "form_labels", "fr");
t.add("customer.shoulder.act_reintegration_anterior", "R\u00e9insertion bourrelet ant\u00e9rieure (Bankart ant\u00e9rieur)", "form_labels", "fr");
t.add("customer.shoulder.act_reintegration_posterior", "R\u00e9insertion bourrelet post\u00e9rieure (Bankart post\u00e9rieur)", "form_labels", "fr");
t.add("customer.shoulder.act_cuff_repair", "R\u00e9paration coiffe des rotateurs ", "form_labels", "fr");
t.add("customer.shoulder.act_calcification_resection", "Ex\u00e9r\u00e8se de calcification tendineuse", "form_labels", "fr");
t.add("customer.shoulder.act_acromioclavicular_arthroplasty", "Arthroplastie (r\u00e9section) acromio-claviculaire", "form_labels", "fr");
t.add("customer.shoulder.act_acromioclavicular_ligamentoplasty", "Ligamentoplastie acromio-claviculaire", "form_labels", "fr");
t.add("customer.shoulder.act_tenodesis_long_biceps", "Tenod\u00e8se du long biceps", "form_labels", "fr");
t.add("customer.shoulder.act_clavicul_osteosynthese", "Ost\u00e9osynth\u00e8se de la clavicule", "form_labels", "fr");
t.add("customer.shoulder.graft_type", "Type de greffe", "form_labels", "fr");
t.add("customer.shoulder.graft_type_bankart", "Bankart", "form_labels", "fr");
t.add("customer.shoulder.graft_type_latarjet", "Latarjet", "form_labels", "fr");
t.add("customer.shoulder.graft_type_kouvalchouk", "Kouvalchouk", "form_labels", "fr");
t.add("customer.shoulder.injury_date", "Date blessure", "form_labels", "fr");
t.add("customer.shoulder.operation", "Op\u00e9r\u00e9", "form_labels", "fr");
t.add("customer.shoulder.operation_type", "Type d'op\u00e9ration", "form_labels", "fr");
t.add("customer.shoulder.operation_date", "Date op\u00e9ration", "form_labels", "fr");
t.add("customer.shoulder.surgeon", "Chirurgien", "form_labels", "fr");
t.add("customer.shoulder.injury_side", "Cot\u00e9 l\u00e9s\u00e9", "form_labels", "fr");
t.add("customer.shoulder.contralateral_antecedent", "Ant\u00e9c\u00e9dent controlateral", "form_labels", "fr");
t.add("customer.shoulder.type_of_antecedents", "Type d'ant\u00e9c\u00e9dents", "form_labels", "fr");
t.add("customer.shoulder.other", "Autres", "form_labels", "fr");
t.add("customer.shoulder.right_c7_major", "C7-Maj Droite", "form_labels", "fr");
t.add("customer.shoulder.left_c7_major", "C7-Maj Gauche", "form_labels", "fr");
t.add("customer.shoulder.training_hours", "Nombre d'heures d'entrainement", "form_labels", "fr");
t.add("customer.shoulder.num_instability", "Nombre d'\u00e9pisodes d'instabilit\u00e9 sur le membre l\u00e9s\u00e9", "form_labels", "fr");
t.add("customer.shoulder.age_first_injure", "Age de la premi\u00e8re l\u00e9sion", "form_labels", "fr");
t.add("customer.shoulder.lesion_type", "M\u00e9canisme l\u00e9sionnel", "form_labels", "fr");
t.add("customer.shoulder.work_before_3_months_surgery", "Travail r\u00e9alis\u00e9 apr\u00e8s intervention chirugicale de 0 \u00e0 3 mois", "form_labels", "fr");
t.add("customer.shoulder.work_after_3_months_surgery", "Travail r\u00e9alis\u00e9 apr\u00e8s intervention chirugicale apr\u00e8s 3 mois", "form_labels", "fr");
t.add("customer.shoulder.sport_doctor", "M\u00e9decin du sport", "form_labels", "fr");
t.add("duplicate_session.destination.customer", "Client", "form_labels", "fr");
t.add("duplicate_session.destination.planning", "Planification", "form_labels", "fr");
t.add("duplicate_planning_session.customer", "Client", "form_labels", "fr");
t.add("duplicate_planning_session.new_planning", "Nouvelle planification", "form_labels", "fr");
t.add("customer.hip.injury_type", "Type de blessure ", "form_labels", "fr");
t.add("customer.hip.conflict", "Conflit de hanche", "form_labels", "fr");
t.add("customer.hip.prosthesis", "Proth\u00e8se de hanche", "form_labels", "fr");
t.add("customer.hip.groin", "Pubalgie", "form_labels", "fr");
t.add("customer.hip.inguinal_hernia", "Hernie inguinale", "form_labels", "fr");
t.add("customer.hip.act", "Acte", "form_labels", "fr");
t.add("customer.hip.act_yes_no", "Oui\/Non ", "form_labels", "fr");
t.add("customer.hip.act_total", "Totale", "form_labels", "fr");
t.add("customer.hip.act_partial", "Partielle", "form_labels", "fr");
t.add("customer.hip.act_parieto", "Pari\u00e9to", "form_labels", "fr");
t.add("customer.hip.act_abdominal", "Abdominal", "form_labels", "fr");
t.add("customer.hip.act_adductors", "Adducteurs", "form_labels", "fr");
t.add("customer.hip.injury_date", "Date blessure", "form_labels", "fr");
t.add("customer.hip.operated", "Op\u00e9r\u00e9", "form_labels", "fr");
t.add("customer.hip.operation_date", "Date op\u00e9ration", "form_labels", "fr");
t.add("customer.hip.surgeon", "Chirurgien", "form_labels", "fr");
t.add("customer.hip.injury_side", "Cot\u00e9 l\u00e9s\u00e9", "form_labels", "fr");
t.add("customer.hip.contralateral_antecedent", "ant\u00e9c\u00e9dent controlateral", "form_labels", "fr");
t.add("customer.hip.type_of_antecedents", "Type d'ant\u00e9c\u00e9dents", "form_labels", "fr");
t.add("customer.ankle.injury_type", "Type de blessure", "form_labels", "fr");
t.add("customer.ankle.prosthesis", "Proth\u00e8se de cheville", "form_labels", "fr");
t.add("customer.ankle.front_beam", "Faisceau ant\u00e9rieur (LLE)", "form_labels", "fr");
t.add("customer.ankle.posterior_beam", "Faisceau post\u00e9rieur (LLE)", "form_labels", "fr");
t.add("customer.ankle.medium_beam", "Faisceau moyen (LLE)           ", "form_labels", "fr");
t.add("customer.ankle.lli", "LLI", "form_labels", "fr");
t.add("customer.ankle.achilles_tendon_rupture", "Rupture tendon d\u2019achille", "form_labels", "fr");
t.add("customer.ankle.arthrodesis", "Arthrod\u00e8se", "form_labels", "fr");
t.add("customer.ankle.osteotomy", "Ost\u00e9otomie", "form_labels", "fr");
t.add("customer.ankle.hallux_valgus", "Hallux valgus", "form_labels", "fr");
t.add("customer.ankle.act", "Acte", "form_labels", "fr");
t.add("customer.ankle.act_total", "Totale", "form_labels", "fr");
t.add("customer.ankle.act_partial", "Partielle", "form_labels", "fr");
t.add("customer.ankle.act_ligamentoplasty", "Ligamentoplastie", "form_labels", "fr");
t.add("customer.ankle.graft", "Type de greffe", "form_labels", "fr");
t.add("customer.ankle.graft_duquestony", "Dusquenoy", "form_labels", "fr");
t.add("customer.ankle.graft_castaing", "Castaing", "form_labels", "fr");
t.add("customer.ankle.injury_date", "Date blessure", "form_labels", "fr");
t.add("customer.ankle.operated", "Op\u00e9r\u00e9", "form_labels", "fr");
t.add("customer.ankle.operation_date", "Date op\u00e9ration", "form_labels", "fr");
t.add("customer.ankle.surgeon", "Chirurgien", "form_labels", "fr");
t.add("customer.ankle.injury_side", "Cot\u00e9 l\u00e9s\u00e9", "form_labels", "fr");
t.add("customer.ankle.contralateral_antecedent", "Ant\u00e9c\u00e9dent controlateral", "form_labels", "fr");
t.add("customer.ankle.type_of_antecedents", "Type d'ant\u00e9c\u00e9dents", "form_labels", "fr");
t.add("customer.ankle.other", "Autres", "form_labels", "fr");
t.add("customer.other_lower_limbs.injury_type", "Type de blessure", "form_labels", "fr");
t.add("customer.other_lower_limbs.tearing_quadriceps", "D\u00e9chirure quadriceps", "form_labels", "fr");
t.add("customer.other_lower_limbs.tear_hamstrings", "D\u00e9chirure ischio-jambiers", "form_labels", "fr");
t.add("customer.other_lower_limbs.triceps_sural_tear", "D\u00e9chirure triceps sural", "form_labels", "fr");
t.add("customer.other_lower_limbs.injury_details", "D\u00e9tails sur la blessure", "form_labels", "fr");
t.add("customer.other_lower_limbs.vast_medial", "Vaste m\u00e9dial", "form_labels", "fr");
t.add("customer.other_lower_limbs.vast_lateral", "Vaste lat\u00e9ral", "form_labels", "fr");
t.add("customer.other_lower_limbs.femoral_right", "Droit f\u00e9moral", "form_labels", "fr");
t.add("customer.other_lower_limbs.vast_intermediary", "Vaste int\u00e9rm\u00e9diaire", "form_labels", "fr");
t.add("customer.other_lower_limbs.temi_tendinals", "Temi tendinaux", "form_labels", "fr");
t.add("customer.other_lower_limbs.semi_membranous", "Semi membraneux", "form_labels", "fr");
t.add("customer.other_lower_limbs.femoral_biceps", "Biceps f\u00e9moral", "form_labels", "fr");
t.add("customer.other_lower_limbs.soleus", "Sol\u00e9aire", "form_labels", "fr");
t.add("customer.other_lower_limbs.twins", "Les jumeaux", "form_labels", "fr");
t.add("customer.other_lower_limbs.the_long_lateral_peroneal", "Le long p\u00e9ronier lat\u00e9ral", "form_labels", "fr");
t.add("customer.other_lower_limbs.injury_date", "Date blessure", "form_labels", "fr");
t.add("customer.other_lower_limbs.operated", "Op\u00e9r\u00e9", "form_labels", "fr");
t.add("customer.other_lower_limbs.operation_date", "Date op\u00e9ration", "form_labels", "fr");
t.add("customer.other_lower_limbs.surgeon", "Chirurgien", "form_labels", "fr");
t.add("customer.other_lower_limbs.injury_side", "Cot\u00e9 l\u00e9s\u00e9", "form_labels", "fr");
t.add("customer.other_lower_limbs.contralateral_antecedent", "Ant\u00e9c\u00e9dent controlateral", "form_labels", "fr");
t.add("customer.other_lower_limbs.type_of_antecedents", "Type d'ant\u00e9c\u00e9dents", "form_labels", "fr");
t.add("customer.back.injury_type", "Type de blessure", "form_labels", "fr");
t.add("customer.back.location", "Localisation", "form_labels", "fr");
t.add("customer.back.l4_l5", "L4-L5", "form_labels", "fr");
t.add("customer.back.l5_s1", "L5-S1", "form_labels", "fr");
t.add("customer.back.injury_date", "Date blessure", "form_labels", "fr");
t.add("customer.back.operated", "Op\u00e9r\u00e9", "form_labels", "fr");
t.add("customer.back.operation_date", "Date op\u00e9ration", "form_labels", "fr");
t.add("customer.back.surgeon", "Chirurgien", "form_labels", "fr");
t.add("customer.back.type_of_antecedents", "Type d'ant\u00e9c\u00e9dents", "form_labels", "fr");
t.add("customer.back.previous_lumbar_arthrodesis", "Arthrod\u00e8se lombaire ant\u00e9rieure", "form_labels", "fr");
t.add("customer.back.posterior_arthrodesis", "Arthrod\u00e8se post\u00e9rieure", "form_labels", "fr");
t.add("customer.back.herniated_disc", "Hernie discale", "form_labels", "fr");
t.add("customer.back.anterior_chervical_arthrodesis", "Arthrod\u00e8se cervicale ant\u00e9rieure", "form_labels", "fr");
t.add("customer.postal_code", "Code postal", "form_labels", "fr");
t.add("planning.session_name", "Nom du s\u00e9ance", "form_labels", "fr");
t.add("planning.session_date", "Date du s\u00e9ance", "form_labels", "fr");
t.add("customer.activity_frequency.sedentary", "S\u00e9dentaire", "form_labels", "fr");
t.add("customer.pdf.phone", "T\u00e9l\u00e9phone", "form_labels", "fr");
t.add("customer.pdf.doctor", "M\u00e9decin suiveur", "form_labels", "fr");
t.add("customer.injury.other", "Autres", "form_labels", "fr");
t.add("media.uploaded_file", "Choisir un fichier", "form_labels", "fr");
t.add("course.accessible_planning_type_categories", "Cat\u00e9gories de planification type accessibles", "form_labels", "fr");
t.add("course.video_list", "Vid\u00e9os", "form_labels", "fr");
t.add("link.placeholder", "URL YouTube", "form_labels", "fr");
t.add("customer.button.details", "Voir le d\u00e9tail", "form_labels", "fr");
t.add("customer.title.group_delete", "Supprimer du groupe", "form_labels", "fr");
t.add("customer.title.edit", "Modifier", "form_labels", "fr");
t.add("customer.title.delete", "Supprimer", "form_labels", "fr");
t.add("user.offices", "Cabinets", "form_labels", "fr");
t.add("office_therapist.modal.add", "Ajouter un th\u00e9rapeute", "form_labels", "fr");
t.add("office_therapist.btn.add", "Ajouter un th\u00e9rapeute", "form_labels", "fr");
t.add("customer.offices", "Cabinets", "form_labels", "fr");
t.add("customer.modal_title.share", "Partager", "form_labels", "fr");
t.add("customer.graft.di_suture", "DI SUTURE", "form_labels", "fr");
t.add("customer.graft.suture", "SUTURE", "form_labels", "fr");
t.add("customer.graft.dt_controlateral", "DT controlat\u00e9ral", "form_labels", "fr");
t.add("customer.graft.suture_lca", "SUTURE LCA", "form_labels", "fr");
t.add("form.surgeon", "Chirurgien", "form_labels", "fr");
t.add("form.sportDoctor", "M\u00e9decin du sport", "form_labels", "fr");
t.add("form.secretaria", "Secretariat", "form_labels", "fr");
t.add("form.kneeSurgeon", "Chirurgien (Genou)", "form_labels", "fr");
t.add("form.kneeSportDoctor", "M\u00e9decin du sport (Genou)", "form_labels", "fr");
t.add("form.kneeSecretaria", "Secretariat (Genou)", "form_labels", "fr");
t.add("form.shoulderSurgeon", "Chirurgien (\u00c9paule)", "form_labels", "fr");
t.add("form.shoulderSportDoctor", "M\u00e9decin du sport (\u00c9paule)", "form_labels", "fr");
t.add("form.shoulderSecretaria", "Secretariat (\u00c9paule)", "form_labels", "fr");
t.add("form.customer", "Client", "form_labels", "fr");
t.add("mail.recievers", "Envoyer \u00e0 : ", "form_labels", "fr");
t.add("form.email", "Ajouter un email : ", "form_labels", "fr");
})(Translator);
