(function (t) {
// fr
t.add("user.change_password", "Changement de mot de passe", "user", "fr");
t.add("user.account_created", "Votre compte a \u00e9t\u00e9 cr\u00e9\u00e9 avec succ\u00e8s", "user", "fr");
t.add("user.credentials", "Vos informations d'identification", "user", "fr");
t.add("user.password_changing_required_after_first_login", "Apr\u00e8s la premi\u00e8re connexion \u00e0 l'application, il vous sera demand\u00e9 de changer votre mot de passe.", "user", "fr");
t.add("user.email", "Email", "user", "fr");
t.add("user.startAt", "Date de d\u00e9but", "user", "fr");
t.add("user.endAt", "Date de fin", "user", "fr");
t.add("user.password", "Mot de passe", "user", "fr");
t.add("user.link_to_your_account", "Vous pouvez acc\u00e9der \u00e0 votre compte en cliquant sur le lien suivant", "user", "fr");
t.add("user.password_change_required", "Veuillez cr\u00e9er un nouveau mot de passe pour votre compte", "user", "fr");
})(Translator);
