(function (t) {
// sr_Latn
t.add("This value should be false.", "Vrednost bi trebalo da bude neta\u010dna.", "validators", "sr_Latn");
t.add("This value should be true.", "Vrednost bi trebalo da bude ta\u010dna.", "validators", "sr_Latn");
t.add("This value should be of type {{ type }}.", "Vrednost bi trebalo da bude tipa {{ type }}.", "validators", "sr_Latn");
t.add("This value should be blank.", "Vrednost bi trebalo da bude prazna.", "validators", "sr_Latn");
t.add("The value you selected is not a valid choice.", "Odabrana vrednost nije validan izbor.", "validators", "sr_Latn");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Morate odabrati bar {{ limit }} mogu\u0107nost.|Morate odabrati bar {{ limit }} mogu\u0107nosti.|Morate odabrati bar {{ limit }} mogu\u0107nosti.", "validators", "sr_Latn");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Morate odabrati najvi\u0161e {{ limit }} mogu\u0107nost.|Morate odabrati najvi\u0161e {{ limit }} mogu\u0107nosti.|Morate odabrati najvi\u0161e {{ limit }} mogu\u0107nosti.", "validators", "sr_Latn");
t.add("One or more of the given values is invalid.", "Jedna ili vi\u0161e vrednosti nisu validne.", "validators", "sr_Latn");
t.add("This field was not expected.", "Ovo polje nije bilo o\u010dekivano.", "validators", "sr_Latn");
t.add("This field is missing.", "Ovo polje nedostaje.", "validators", "sr_Latn");
t.add("This value is not a valid date.", "Vrednost nije validan datum.", "validators", "sr_Latn");
t.add("This value is not a valid datetime.", "Vrednost nije validno vreme.", "validators", "sr_Latn");
t.add("This value is not a valid email address.", "Vrednost nije validna adresa elektronske po\u0161te.", "validators", "sr_Latn");
t.add("The file could not be found.", "Datoteka ne mo\u017ee biti prona\u0111ena.", "validators", "sr_Latn");
t.add("The file is not readable.", "Datoteka nije \u010ditljiva.", "validators", "sr_Latn");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Datoteka je prevelika ({{ size }} {{ suffix }}). Najve\u0107a dozvoljena veli\u010dina je {{ limit }} {{ suffix }}.", "validators", "sr_Latn");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "MIME tip datoteke nije validan ({{ type }}). Dozvoljeni MIME tipovi su {{ types }}.", "validators", "sr_Latn");
t.add("This value should be {{ limit }} or less.", "Vrednost bi trebalo da bude {{ limit }} ili manje.", "validators", "sr_Latn");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Vrednost je preduga\u010dka. Trebalo bi da ima {{ limit }} karakter ili manje.|Vrednost je preduga\u010dka. Trebalo bi da ima {{ limit }} karaktera ili manje.|Vrednost je preduga\u010dka. Trebalo bi da ima {{ limit }} karaktera ili manje.", "validators", "sr_Latn");
t.add("This value should be {{ limit }} or more.", "Vrednost bi trebalo da bude {{ limit }} ili vi\u0161e.", "validators", "sr_Latn");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Vrednost je prekratka. Trebalo bi da ima {{ limit }} karakter ili vi\u0161e.|Vrednost je prekratka. Trebalo bi da ima {{ limit }} karaktera ili vi\u0161e.|Vrednost je prekratka. Trebalo bi da ima {{ limit }} karaktera ili vi\u0161e.", "validators", "sr_Latn");
t.add("This value should not be blank.", "Vrednost ne bi trebalo da bude prazna.", "validators", "sr_Latn");
t.add("This value should not be null.", "Vrednost ne bi trebalo da bude prazna.", "validators", "sr_Latn");
t.add("This value should be null.", "Vrednost bi trebalo da bude prazna.", "validators", "sr_Latn");
t.add("This value is not valid.", "Vrednost nije validna.", "validators", "sr_Latn");
t.add("This value is not a valid time.", "Vrednost nije validno vreme.", "validators", "sr_Latn");
t.add("This value is not a valid URL.", "Vrednost nije validan URL.", "validators", "sr_Latn");
t.add("The two values should be equal.", "Obe vrednosti bi trebalo da budu jednake.", "validators", "sr_Latn");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Datoteka je prevelika. Najve\u0107a dozvoljena veli\u010dina je {{ limit }} {{ suffix }}.", "validators", "sr_Latn");
t.add("The file is too large.", "Datoteka je prevelika.", "validators", "sr_Latn");
t.add("The file could not be uploaded.", "Datoteka ne mo\u017ee biti otpremljena.", "validators", "sr_Latn");
t.add("This value should be a valid number.", "Vrednost bi trebalo da bude validan broj.", "validators", "sr_Latn");
t.add("This file is not a valid image.", "Ova datoteka nije validna slika.", "validators", "sr_Latn");
t.add("This is not a valid IP address.", "Ovo nije validna IP adresa.", "validators", "sr_Latn");
t.add("This value is not a valid language.", "Vrednost nije validan jezik.", "validators", "sr_Latn");
t.add("This value is not a valid locale.", "Vrednost nije validna me\u0111unarodna oznaka jezika.", "validators", "sr_Latn");
t.add("This value is not a valid country.", "Vrednost nije validna dr\u017eava.", "validators", "sr_Latn");
t.add("This value is already used.", "Vrednost je ve\u0107 iskori\u0161\u0107ena.", "validators", "sr_Latn");
t.add("The size of the image could not be detected.", "Veli\u010dina slike ne mo\u017ee biti odre\u0111ena.", "validators", "sr_Latn");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u0160irina slike je prevelika ({{ width }} piksela). Najve\u0107a dozvoljena \u0161irina je {{ max_width }} piksela.", "validators", "sr_Latn");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u0160irina slike je premala ({{ width }} piksela). Najmanja dozvoljena \u0161irina je {{ min_width }} piksela.", "validators", "sr_Latn");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Visina slike je prevelika ({{ height }} piksela). Najve\u0107a dozvoljena visina je {{ max_height }} piksela.", "validators", "sr_Latn");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Visina slike je premala ({{ height }} piksela). Najmanja dozvoljena visina je {{ min_height }} piksela.", "validators", "sr_Latn");
t.add("This value should be the user's current password.", "Vrednost bi trebalo da bude trenutna korisni\u010dka lozinka.", "validators", "sr_Latn");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Vrednost bi trebalo da ima ta\u010dno {{ limit }} karakter.|Vrednost bi trebalo da ima ta\u010dno {{ limit }} karaktera.|Vrednost bi trebalo da ima ta\u010dno {{ limit }} karaktera.", "validators", "sr_Latn");
t.add("The file was only partially uploaded.", "Datoteka je samo parcijalno otpremljena.", "validators", "sr_Latn");
t.add("No file was uploaded.", "Datoteka nije otpremljena.", "validators", "sr_Latn");
t.add("No temporary folder was configured in php.ini.", "Privremeni direktorijum nije konfigurisan u php.ini.", "validators", "sr_Latn");
t.add("Cannot write temporary file to disk.", "Nemogu\u0107e pisanje privremene datoteke na disk.", "validators", "sr_Latn");
t.add("A PHP extension caused the upload to fail.", "PHP ekstenzija je prouzrokovala neuspeh otpremanja datoteke.", "validators", "sr_Latn");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Ova kolekcija bi trebalo da sadr\u017ei {{ limit }} ili vi\u0161e elemenata.|Ova kolekcija bi trebalo da sadr\u017ei {{ limit }} ili vi\u0161e elemenata.|Ova kolekcija bi trebalo da sadr\u017ei {{ limit }} ili vi\u0161e elemenata.", "validators", "sr_Latn");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Ova kolekcija bi trebalo da sadr\u017ei {{ limit }} ili manje elemenata.|Ova kolekcija bi trebalo da sadr\u017ei {{ limit }} ili manje elemenata.|Ova kolekcija bi trebalo da sadr\u017ei {{ limit }} ili manje elemenata.", "validators", "sr_Latn");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Ova kolekcija bi trebalo da sadr\u017ei ta\u010dno {{ limit }} element.|Ova kolekcija bi trebalo da sadr\u017ei ta\u010dno {{ limit }} elementa.|Ova kolekcija bi trebalo da sadr\u017ei ta\u010dno {{ limit }} elemenata.", "validators", "sr_Latn");
t.add("Invalid card number.", "Broj kartice nije validan.", "validators", "sr_Latn");
t.add("Unsupported card type or invalid card number.", "Tip kartice nije podr\u017ean ili broj kartice nije validan.", "validators", "sr_Latn");
t.add("This is not a valid International Bank Account Number (IBAN).", "Ovo nije validan me\u0111unarodni broj bankovnog ra\u010duna (IBAN).", "validators", "sr_Latn");
t.add("This value is not a valid ISBN-10.", "Ovo nije validan ISBN-10.", "validators", "sr_Latn");
t.add("This value is not a valid ISBN-13.", "Ovo nije validan ISBN-13.", "validators", "sr_Latn");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Ovo nije validan ISBN-10 ili ISBN-13.", "validators", "sr_Latn");
t.add("This value is not a valid ISSN.", "Ovo nije validan ISSN.", "validators", "sr_Latn");
t.add("This value is not a valid currency.", "Ovo nije validna valuta.", "validators", "sr_Latn");
t.add("This value should be equal to {{ compared_value }}.", "Ova vrednost bi trebalo da bude jednaka {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should be greater than {{ compared_value }}.", "Ova vrednost bi trebalo da bude ve\u0107a od {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Ova vrednost bi trebalo da bude ve\u0107a ili jednaka {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Ova vrednost bi trebalo da bude identi\u010dna sa {{ compared_value_type }} {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should be less than {{ compared_value }}.", "Ova vrednost bi trebalo da bude manja od {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should be less than or equal to {{ compared_value }}.", "Ova vrednost bi trebalo da bude manja ili jednaka {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should not be equal to {{ compared_value }}.", "Ova vrednost ne bi trebalo da bude jednaka {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Ova vrednost ne bi trebalo da bude identi\u010dna sa {{ compared_value_type }} {{ compared_value }}.", "validators", "sr_Latn");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Razmera ove slike je prevelika ({{ ratio }}). Maksimalna dozvoljena razmera je {{ max_ratio }}.", "validators", "sr_Latn");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Razmera ove slike je premala ({{ ratio }}). Minimalna o\u010dekivana razmera je {{ min_ratio }}.", "validators", "sr_Latn");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Slika je kvadratna ({{ width }}x{{ height }} piksela). Kvadratne slike nisu dozvoljene.", "validators", "sr_Latn");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Slika je pejza\u017eno orijentisana ({{ width }}x{{ height }} piksela). Pejza\u017ena orijentisane slike nisu dozvoljene.", "validators", "sr_Latn");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Slika je portretno orijentisana ({{ width }}x{{ height }} piksela). Portretno orijentisane slike nisu dozvoljene.", "validators", "sr_Latn");
t.add("An empty file is not allowed.", "Prazna datoteka nije dozvoljena.", "validators", "sr_Latn");
t.add("The host could not be resolved.", "Ime hosta ne mo\u017ee biti razre\u0161eno.", "validators", "sr_Latn");
t.add("This value does not match the expected {{ charset }} charset.", "Vrednost se ne poklapa sa o\u010dekivanim {{ charset }} setom karaktera.", "validators", "sr_Latn");
t.add("This is not a valid Business Identifier Code (BIC).", "Ovo nije validan BIC.", "validators", "sr_Latn");
t.add("Error", "Gre\u0161ka", "validators", "sr_Latn");
t.add("This is not a valid UUID.", "Ovo nije validan univerzalni unikatni identifikator (UUID).", "validators", "sr_Latn");
t.add("This value should be a multiple of {{ compared_value }}.", "Ova vrednost bi trebalo da bude deljiva sa {{ compared_value }}.", "validators", "sr_Latn");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "BIC kod nije povezan sa IBAN {{ iban }}.", "validators", "sr_Latn");
t.add("This value should be valid JSON.", "Ova vrednost bi trebalo da bude validan JSON.", "validators", "sr_Latn");
t.add("This collection should contain only unique elements.", "Ova kolekcija bi trebala da sadr\u017ei samo jedinstvene elemente.", "validators", "sr_Latn");
t.add("This value should be positive.", "Ova vrednost bi trebala biti pozitivna.", "validators", "sr_Latn");
t.add("This value should be either positive or zero.", "Ova vrednost bi trebala biti pozitivna ili nula.", "validators", "sr_Latn");
t.add("This value should be negative.", "Ova vrednost bi trebala biti negativna.", "validators", "sr_Latn");
t.add("This value should be either negative or zero.", "Ova vrednost bi trebala biti negativna ili nula.", "validators", "sr_Latn");
t.add("This value is not a valid timezone.", "Ova vrednost nije validna vremenska zona.", "validators", "sr_Latn");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Ova lozinka je kompromitovana prilikom prethodnih napada, nemojte je koristiti. Koristite drugu lozinku.", "validators", "sr_Latn");
t.add("This value should be between {{ min }} and {{ max }}.", "Ova vrednost treba da bude izme\u0111u {{ min }} i {{ max }}.", "validators", "sr_Latn");
t.add("This value is not a valid hostname.", "Ova vrednost nije ispravno ime poslu\u017eitelja (hostname).", "validators", "sr_Latn");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Broj elemenata u ovoj kolekciji bi trebalo da bude deljiv sa {{ compared_value }}.", "validators", "sr_Latn");
t.add("This value should satisfy at least one of the following constraints:", "Ova vrednost bi trebalo da zadovoljava namjanje jedno od narednih ograni\u010denja:", "validators", "sr_Latn");
t.add("Each element of this collection should satisfy its own set of constraints.", "Svaki element ove kolekcije bi trebalo da zadovolji sopstveni skup ograni\u010denja.", "validators", "sr_Latn");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Ova vrednost nije ispravna me\u0111unarodna identifikaciona oznaka hartija od vrednosti (ISIN).", "validators", "sr_Latn");
t.add("This value should be a valid expression.", "Ova vrednost treba da bude validan izraz.", "validators", "sr_Latn");
t.add("This value is not a valid CSS color.", "Ova vrednost nije ispravna CSS boja.", "validators", "sr_Latn");
t.add("This value is not a valid CIDR notation.", "Ova vrednost nije ispravna CIDR notacija.", "validators", "sr_Latn");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "Vrednost mre\u017ene maske treba biti izme\u0111u {{ min }} i {{ max }}.", "validators", "sr_Latn");
t.add("This form should not contain extra fields.", "Ovaj formular ne treba da sadr\u017ei dodatna polja.", "validators", "sr_Latn");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Otpremljena datoteka je bila prevelika. Molim poku\u0161ajte otpremanje manje datoteke.", "validators", "sr_Latn");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF vrednost nije ispravna. Poku\u0161ajte ponovo.", "validators", "sr_Latn");
t.add("This value is not a valid HTML5 color.", "Ova vrednost nije ispravna HTML5 boja.", "validators", "sr_Latn");
t.add("Please enter a valid birthdate.", "Molim upi\u0161ite ispravan datum ro\u0111enja.", "validators", "sr_Latn");
t.add("The selected choice is invalid.", "Odabrani izbor nije ispravan.", "validators", "sr_Latn");
t.add("The collection is invalid.", "Ova kolekcija nije ispravna.", "validators", "sr_Latn");
t.add("Please select a valid color.", "Molim izaberite ispravnu boju.", "validators", "sr_Latn");
t.add("Please select a valid country.", "Molim izaberite ispravnu dr\u017eavu.", "validators", "sr_Latn");
t.add("Please select a valid currency.", "Molim izaberite ispravnu valutu.", "validators", "sr_Latn");
t.add("Please choose a valid date interval.", "Molim izaberite ispravan datumski interval.", "validators", "sr_Latn");
t.add("Please enter a valid date and time.", "Molim upi\u0161ite ispravan datum i vreme.", "validators", "sr_Latn");
t.add("Please enter a valid date.", "Molim upi\u0161ite ispravan datum.", "validators", "sr_Latn");
t.add("Please select a valid file.", "Molim izaberite ispravnu datoteku.", "validators", "sr_Latn");
t.add("The hidden field is invalid.", "Skriveno polje nije ispravno.", "validators", "sr_Latn");
t.add("Please enter an integer.", "Molim upi\u0161ite ceo broj (integer).", "validators", "sr_Latn");
t.add("Please select a valid language.", "Molim izaberite ispravan jezik.", "validators", "sr_Latn");
t.add("Please select a valid locale.", "Molim izaberite ispravnu lokalizaciju.", "validators", "sr_Latn");
t.add("Please enter a valid money amount.", "Molim upi\u0161ite ispravnu koli\u010dinu novca.", "validators", "sr_Latn");
t.add("Please enter a number.", "Molim upi\u0161ite broj.", "validators", "sr_Latn");
t.add("The password is invalid.", "Ova lozinka nije ispravna.", "validators", "sr_Latn");
t.add("Please enter a percentage value.", "Molim upi\u0161ite procentualnu vrednost.", "validators", "sr_Latn");
t.add("The values do not match.", "Date vrednosti se ne poklapaju.", "validators", "sr_Latn");
t.add("Please enter a valid time.", "Molim upi\u0161ite ispravno vreme.", "validators", "sr_Latn");
t.add("Please select a valid timezone.", "Molim izaberite ispravnu vremensku zonu.", "validators", "sr_Latn");
t.add("Please enter a valid URL.", "Molim upi\u0161ite ispravan URL.", "validators", "sr_Latn");
t.add("Please enter a valid search term.", "Molim upi\u0161ite ispravan termin za pretragu.", "validators", "sr_Latn");
t.add("Please provide a valid phone number.", "Molim navedite ispravan broj telefona.", "validators", "sr_Latn");
t.add("The checkbox has an invalid value.", "Polje za potvrdu sadr\u017ei neispravnu vrednost.", "validators", "sr_Latn");
t.add("Please enter a valid email address.", "Molim upi\u0161ite ispravnu email adresu.", "validators", "sr_Latn");
t.add("Please select a valid option.", "Molim izaberite ispravnu opciju.", "validators", "sr_Latn");
t.add("Please select a valid range.", "Molim izaberite ispravan opseg.", "validators", "sr_Latn");
t.add("Please enter a valid week.", "Molim upi\u0161ite ispravnu sedmicu.", "validators", "sr_Latn");
})(Translator);
