(function (t) {
// es
t.add("This value should be false.", "Este valor deber\u00eda ser falso.", "validators", "es");
t.add("This value should be true.", "Este valor deber\u00eda ser verdadero.", "validators", "es");
t.add("This value should be of type {{ type }}.", "Este valor deber\u00eda ser de tipo {{ type }}.", "validators", "es");
t.add("This value should be blank.", "Este valor deber\u00eda estar vac\u00edo.", "validators", "es");
t.add("The value you selected is not a valid choice.", "El valor seleccionado no es una opci\u00f3n v\u00e1lida.", "validators", "es");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Debe seleccionar al menos {{ limit }} opci\u00f3n.|Debe seleccionar al menos {{ limit }} opciones.", "validators", "es");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Debe seleccionar como m\u00e1ximo {{ limit }} opci\u00f3n.|Debe seleccionar como m\u00e1ximo {{ limit }} opciones.", "validators", "es");
t.add("One or more of the given values is invalid.", "Uno o m\u00e1s de los valores indicados no son v\u00e1lidos.", "validators", "es");
t.add("This field was not expected.", "Este campo no se esperaba.", "validators", "es");
t.add("This field is missing.", "Este campo est\u00e1 desaparecido.", "validators", "es");
t.add("This value is not a valid date.", "Este valor no es una fecha v\u00e1lida.", "validators", "es");
t.add("This value is not a valid datetime.", "Este valor no es una fecha y hora v\u00e1lidas.", "validators", "es");
t.add("This value is not a valid email address.", "Este valor no es una direcci\u00f3n de email v\u00e1lida.", "validators", "es");
t.add("The file could not be found.", "No se pudo encontrar el archivo.", "validators", "es");
t.add("The file is not readable.", "No se puede leer el archivo.", "validators", "es");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "El archivo es demasiado grande ({{ size }} {{ suffix }}). El tama\u00f1o m\u00e1ximo permitido es {{ limit }} {{ suffix }}.", "validators", "es");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "El tipo mime del archivo no es v\u00e1lido ({{ type }}). Los tipos mime v\u00e1lidos son {{ types }}.", "validators", "es");
t.add("This value should be {{ limit }} or less.", "Este valor deber\u00eda ser {{ limit }} o menos.", "validators", "es");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Este valor es demasiado largo. Deber\u00eda tener {{ limit }} car\u00e1cter o menos.|Este valor es demasiado largo. Deber\u00eda tener {{ limit }} caracteres o menos.", "validators", "es");
t.add("This value should be {{ limit }} or more.", "Este valor deber\u00eda ser {{ limit }} o m\u00e1s.", "validators", "es");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Este valor es demasiado corto. Deber\u00eda tener {{ limit }} car\u00e1cter o m\u00e1s.|Este valor es demasiado corto. Deber\u00eda tener {{ limit }} caracteres o m\u00e1s.", "validators", "es");
t.add("This value should not be blank.", "Este valor no deber\u00eda estar vac\u00edo.", "validators", "es");
t.add("This value should not be null.", "Este valor no deber\u00eda ser nulo.", "validators", "es");
t.add("This value should be null.", "Este valor deber\u00eda ser nulo.", "validators", "es");
t.add("This value is not valid.", "Este valor no es v\u00e1lido.", "validators", "es");
t.add("This value is not a valid time.", "Este valor no es una hora v\u00e1lida.", "validators", "es");
t.add("This value is not a valid URL.", "Este valor no es una URL v\u00e1lida.", "validators", "es");
t.add("The two values should be equal.", "Los dos valores deber\u00edan ser iguales.", "validators", "es");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "El archivo es demasiado grande. El tama\u00f1o m\u00e1ximo permitido es {{ limit }} {{ suffix }}.", "validators", "es");
t.add("The file is too large.", "El archivo es demasiado grande.", "validators", "es");
t.add("The file could not be uploaded.", "No se pudo subir el archivo.", "validators", "es");
t.add("This value should be a valid number.", "Este valor deber\u00eda ser un n\u00famero v\u00e1lido.", "validators", "es");
t.add("This file is not a valid image.", "El archivo no es una imagen v\u00e1lida.", "validators", "es");
t.add("This is not a valid IP address.", "Esto no es una direcci\u00f3n IP v\u00e1lida.", "validators", "es");
t.add("This value is not a valid language.", "Este valor no es un idioma v\u00e1lido.", "validators", "es");
t.add("This value is not a valid locale.", "Este valor no es una localizaci\u00f3n v\u00e1lida.", "validators", "es");
t.add("This value is not a valid country.", "Este valor no es un pa\u00eds v\u00e1lido.", "validators", "es");
t.add("This value is already used.", "Este valor ya se ha utilizado.", "validators", "es");
t.add("The size of the image could not be detected.", "No se pudo determinar el tama\u00f1o de la imagen.", "validators", "es");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "El ancho de la imagen es demasiado grande ({{ width }}px). El ancho m\u00e1ximo permitido es de {{ max_width }}px.", "validators", "es");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "El ancho de la imagen es demasiado peque\u00f1o ({{ width }}px). El ancho m\u00ednimo requerido es {{ min_width }}px.", "validators", "es");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "La altura de la imagen es demasiado grande ({{ height }}px). La altura m\u00e1xima permitida es de {{ max_height }}px.", "validators", "es");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "La altura de la imagen es demasiado peque\u00f1a ({{ height }}px). La altura m\u00ednima requerida es de {{ min_height }}px.", "validators", "es");
t.add("This value should be the user's current password.", "Este valor deber\u00eda ser la contrase\u00f1a actual del usuario.", "validators", "es");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Este valor deber\u00eda tener exactamente {{ limit }} car\u00e1cter.|Este valor deber\u00eda tener exactamente {{ limit }} caracteres.", "validators", "es");
t.add("The file was only partially uploaded.", "El archivo fue s\u00f3lo subido parcialmente.", "validators", "es");
t.add("No file was uploaded.", "Ning\u00fan archivo fue subido.", "validators", "es");
t.add("No temporary folder was configured in php.ini.", "Ninguna carpeta temporal fue configurada en php.ini o la carpeta configurada no existe.", "validators", "es");
t.add("Cannot write temporary file to disk.", "No se pudo escribir el archivo temporal en el disco.", "validators", "es");
t.add("A PHP extension caused the upload to fail.", "Una extensi\u00f3n de PHP hizo que la subida fallara.", "validators", "es");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Esta colecci\u00f3n debe contener {{ limit }} elemento o m\u00e1s.|Esta colecci\u00f3n debe contener {{ limit }} elementos o m\u00e1s.", "validators", "es");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Esta colecci\u00f3n debe contener {{ limit }} elemento o menos.|Esta colecci\u00f3n debe contener {{ limit }} elementos o menos.", "validators", "es");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Esta colecci\u00f3n debe contener exactamente {{ limit }} elemento.|Esta colecci\u00f3n debe contener exactamente {{ limit }} elementos.", "validators", "es");
t.add("Invalid card number.", "N\u00famero de tarjeta inv\u00e1lido.", "validators", "es");
t.add("Unsupported card type or invalid card number.", "Tipo de tarjeta no soportado o n\u00famero de tarjeta inv\u00e1lido.", "validators", "es");
t.add("This is not a valid International Bank Account Number (IBAN).", "Esto no es un International Bank Account Number (IBAN) v\u00e1lido.", "validators", "es");
t.add("This value is not a valid ISBN-10.", "Este valor no es un ISBN-10 v\u00e1lido.", "validators", "es");
t.add("This value is not a valid ISBN-13.", "Este valor no es un ISBN-13 v\u00e1lido.", "validators", "es");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Este valor no es ni un ISBN-10 v\u00e1lido ni un ISBN-13 v\u00e1lido.", "validators", "es");
t.add("This value is not a valid ISSN.", "Este valor no es un ISSN v\u00e1lido.", "validators", "es");
t.add("This value is not a valid currency.", "Este valor no es una divisa v\u00e1lida.", "validators", "es");
t.add("This value should be equal to {{ compared_value }}.", "Este valor deber\u00eda ser igual que {{ compared_value }}.", "validators", "es");
t.add("This value should be greater than {{ compared_value }}.", "Este valor deber\u00eda ser mayor que {{ compared_value }}.", "validators", "es");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Este valor deber\u00eda ser mayor o igual que {{ compared_value }}.", "validators", "es");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Este valor deber\u00eda ser id\u00e9ntico a {{ compared_value_type }} {{ compared_value }}.", "validators", "es");
t.add("This value should be less than {{ compared_value }}.", "Este valor deber\u00eda ser menor que {{ compared_value }}.", "validators", "es");
t.add("This value should be less than or equal to {{ compared_value }}.", "Este valor deber\u00eda ser menor o igual que {{ compared_value }}.", "validators", "es");
t.add("This value should not be equal to {{ compared_value }}.", "Este valor deber\u00eda ser distinto de {{ compared_value }}.", "validators", "es");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Este valor no deber\u00eda ser id\u00e9ntico a {{ compared_value_type }} {{ compared_value }}.", "validators", "es");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "La proporci\u00f3n de la imagen es demasiado grande ({{ ratio }}). La m\u00e1xima proporci\u00f3n permitida es {{ max_ratio }}.", "validators", "es");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "La proporci\u00f3n de la imagen es demasiado peque\u00f1a ({{ ratio }}). La m\u00ednima proporci\u00f3n permitida es {{ min_ratio }}.", "validators", "es");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "La imagen es cuadrada ({{ width }}x{{ height }}px). Las im\u00e1genes cuadradas no est\u00e1n permitidas.", "validators", "es");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "La imagen est\u00e1 orientada horizontalmente ({{ width }}x{{ height }}px). Las im\u00e1genes orientadas horizontalmente no est\u00e1n permitidas.", "validators", "es");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "La imagen est\u00e1 orientada verticalmente ({{ width }}x{{ height }}px). Las im\u00e1genes orientadas verticalmente no est\u00e1n permitidas.", "validators", "es");
t.add("An empty file is not allowed.", "No est\u00e1 permitido un archivo vac\u00edo.", "validators", "es");
t.add("The host could not be resolved.", "No se puede resolver el host.", "validators", "es");
t.add("This value does not match the expected {{ charset }} charset.", "La codificaci\u00f3n de caracteres para este valor deber\u00eda ser {{ charset }}.", "validators", "es");
t.add("This is not a valid Business Identifier Code (BIC).", "No es un C\u00f3digo de Identificaci\u00f3n Bancaria (BIC) v\u00e1lido.", "validators", "es");
t.add("Error", "Error", "validators", "es");
t.add("This is not a valid UUID.", "Este valor no es un UUID v\u00e1lido.", "validators", "es");
t.add("This value should be a multiple of {{ compared_value }}.", "Este valor deber\u00eda ser m\u00faltiplo de {{ compared_value }}.", "validators", "es");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Este C\u00f3digo de Identificaci\u00f3n Bancaria (BIC) no est\u00e1 asociado con el IBAN {{ iban }}.", "validators", "es");
t.add("This value should be valid JSON.", "Este valor deber\u00eda ser un JSON v\u00e1lido.", "validators", "es");
t.add("This collection should contain only unique elements.", "Esta colecci\u00f3n deber\u00eda tener exclusivamente elementos \u00fanicos.", "validators", "es");
t.add("This value should be positive.", "Este valor deber\u00eda ser positivo.", "validators", "es");
t.add("This value should be either positive or zero.", "Este valor deber\u00eda ser positivo o igual a cero.", "validators", "es");
t.add("This value should be negative.", "Este valor deber\u00eda ser negativo.", "validators", "es");
t.add("This value should be either negative or zero.", "Este valor deber\u00eda ser negativo o igual a cero.", "validators", "es");
t.add("This value is not a valid timezone.", "Este valor no es una zona horaria v\u00e1lida.", "validators", "es");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Esta contrase\u00f1a no se puede utilizar porque est\u00e1 incluida en un listado de contrase\u00f1as p\u00fablicas obtenido gracias a fallos de seguridad de otros sitios y aplicaciones. Por favor utilice otra contrase\u00f1a.", "validators", "es");
t.add("This value should be between {{ min }} and {{ max }}.", "Este valor deber\u00eda estar entre {{ min }} y {{ max }}.", "validators", "es");
t.add("This value is not a valid hostname.", "Este valor no es un nombre de host v\u00e1lido.", "validators", "es");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "El n\u00famero de elementos en esta colecci\u00f3n deber\u00eda ser m\u00faltiplo de {{ compared_value }}.", "validators", "es");
t.add("This value should satisfy at least one of the following constraints:", "Este valor deber\u00eda satisfacer al menos una de las siguientes restricciones:", "validators", "es");
t.add("Each element of this collection should satisfy its own set of constraints.", "Cada elemento de esta colecci\u00f3n deber\u00eda satisfacer su propio conjunto de restricciones.", "validators", "es");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Este valor no es un n\u00famero de identificaci\u00f3n internacional de valores (ISIN) v\u00e1lido.", "validators", "es");
t.add("This value should be a valid expression.", "Este valor deber\u00eda ser una expresi\u00f3n v\u00e1lida.", "validators", "es");
t.add("This value is not a valid CSS color.", "Este valor no es un color CSS v\u00e1lido.", "validators", "es");
t.add("This value is not a valid CIDR notation.", "Este valor no es una notaci\u00f3n CIDR v\u00e1lida.", "validators", "es");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "El valor de la m\u00e1scara de red deber\u00eda estar entre {{ min }} y {{ max }}.", "validators", "es");
t.add("This form should not contain extra fields.", "Este formulario no deber\u00eda contener campos adicionales.", "validators", "es");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "El archivo subido es demasiado grande. Por favor, suba un archivo m\u00e1s peque\u00f1o.", "validators", "es");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "El token CSRF no es v\u00e1lido. Por favor, pruebe a enviar nuevamente el formulario.", "validators", "es");
t.add("This value is not a valid HTML5 color.", "Este valor no es un color HTML5 v\u00e1lido.", "validators", "es");
t.add("Please enter a valid birthdate.", "Por favor, ingrese una fecha de cumplea\u00f1os v\u00e1lida.", "validators", "es");
t.add("The selected choice is invalid.", "La opci\u00f3n seleccionada no es v\u00e1lida.", "validators", "es");
t.add("The collection is invalid.", "La colecci\u00f3n no es v\u00e1lida.", "validators", "es");
t.add("Please select a valid color.", "Por favor, seleccione un color v\u00e1lido.", "validators", "es");
t.add("Please select a valid country.", "Por favor, seleccione un pa\u00eds v\u00e1lido.", "validators", "es");
t.add("Please select a valid currency.", "Por favor, seleccione una moneda v\u00e1lida.", "validators", "es");
t.add("Please choose a valid date interval.", "Por favor, elija un intervalo de fechas v\u00e1lido.", "validators", "es");
t.add("Please enter a valid date and time.", "Por favor, ingrese una fecha y hora v\u00e1lidas.", "validators", "es");
t.add("Please enter a valid date.", "Por favor, ingrese una fecha valida.", "validators", "es");
t.add("Please select a valid file.", "Por favor, seleccione un archivo v\u00e1lido.", "validators", "es");
t.add("The hidden field is invalid.", "El campo oculto no es v\u00e1lido.", "validators", "es");
t.add("Please enter an integer.", "Por favor, ingrese un n\u00famero entero.", "validators", "es");
t.add("Please select a valid language.", "Por favor, seleccione un idioma v\u00e1lido.", "validators", "es");
t.add("Please select a valid locale.", "Por favor, seleccione una configuraci\u00f3n regional v\u00e1lida.", "validators", "es");
t.add("Please enter a valid money amount.", "Por favor, ingrese una cantidad de dinero v\u00e1lida.", "validators", "es");
t.add("Please enter a number.", "Por favor, ingrese un n\u00famero.", "validators", "es");
t.add("The password is invalid.", "La contrase\u00f1a no es v\u00e1lida.", "validators", "es");
t.add("Please enter a percentage value.", "Por favor, ingrese un valor porcentual.", "validators", "es");
t.add("The values do not match.", "Los valores no coinciden.", "validators", "es");
t.add("Please enter a valid time.", "Por favor, ingrese una hora v\u00e1lida.", "validators", "es");
t.add("Please select a valid timezone.", "Por favor, seleccione una zona horaria v\u00e1lida.", "validators", "es");
t.add("Please enter a valid URL.", "Por favor, ingrese una URL v\u00e1lida.", "validators", "es");
t.add("Please enter a valid search term.", "Por favor, ingrese un t\u00e9rmino de b\u00fasqueda v\u00e1lido.", "validators", "es");
t.add("Please provide a valid phone number.", "Por favor, proporcione un n\u00famero de tel\u00e9fono v\u00e1lido.", "validators", "es");
t.add("The checkbox has an invalid value.", "La casilla de verificaci\u00f3n tiene un valor inv\u00e1lido.", "validators", "es");
t.add("Please enter a valid email address.", "Por favor, ingrese una direcci\u00f3n de correo electr\u00f3nico v\u00e1lida.", "validators", "es");
t.add("Please select a valid option.", "Por favor, seleccione una opci\u00f3n v\u00e1lida.", "validators", "es");
t.add("Please select a valid range.", "Por favor, seleccione un rango v\u00e1lido.", "validators", "es");
t.add("Please enter a valid week.", "Por favor, ingrese una semana v\u00e1lida.", "validators", "es");
})(Translator);
