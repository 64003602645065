(function (t) {
// uk
t.add("An authentication exception occurred.", "\u041f\u043e\u043c\u0438\u043b\u043a\u0430 \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u0444\u0456\u043a\u0430\u0446\u0456\u0457.", "security", "uk");
t.add("Authentication credentials could not be found.", "\u0410\u0432\u0442\u0435\u043d\u0442\u0438\u0444\u0456\u043a\u0430\u0446\u0456\u0439\u043d\u0456 \u0434\u0430\u043d\u0456 \u043d\u0435 \u0437\u043d\u0430\u0439\u0434\u0435\u043d\u043e.", "security", "uk");
t.add("Authentication request could not be processed due to a system problem.", "\u0417\u0430\u043f\u0438\u0442 \u043d\u0430 \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u0444\u0456\u043a\u0430\u0446\u0456\u044e \u043d\u0435 \u043c\u043e\u0436\u0435 \u0431\u0443\u0442\u0438 \u043e\u043f\u0440\u0430\u0446\u044c\u043e\u0432\u0430\u043d\u043e \u0443 \u0437\u0432\u2019\u044f\u0437\u043a\u0443 \u0437 \u043f\u0440\u043e\u0431\u043b\u0435\u043c\u043e\u044e \u0432 \u0441\u0438\u0441\u0442\u0435\u043c\u0456.", "security", "uk");
t.add("Invalid credentials.", "\u041d\u0435\u0432\u0456\u0440\u043d\u0456 \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u0444\u0456\u043a\u0430\u0446\u0456\u0439\u043d\u0456 \u0434\u0430\u043d\u0456.", "security", "uk");
t.add("Cookie has already been used by someone else.", "\u0425\u0442\u043e\u0441\u044c \u0456\u043d\u0448\u0438\u0439 \u0432\u0436\u0435 \u0432\u0438\u043a\u043e\u0440\u0438\u0441\u0442\u0430\u0432 \u0446\u0435\u0439 \u0441ookie.", "security", "uk");
t.add("Not privileged to request the resource.", "\u0412\u0456\u0434\u0441\u0443\u0442\u043d\u0456 \u043f\u0440\u0430\u0432\u0430 \u043d\u0430 \u0437\u0430\u043f\u0438\u0442 \u0446\u044c\u043e\u0433\u043e \u0440\u0435\u0441\u0443\u0440\u0441\u0443.", "security", "uk");
t.add("Invalid CSRF token.", "\u041d\u0435\u0432\u0456\u0440\u043d\u0438\u0439 \u0442\u043e\u043a\u0435\u043d CSRF.", "security", "uk");
t.add("No authentication provider found to support the authentication token.", "\u041d\u0435 \u0437\u043d\u0430\u0439\u0434\u0435\u043d\u043e \u043f\u0440\u043e\u0432\u0430\u0439\u0434\u0435\u0440\u0430 \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u0444\u0456\u043a\u0430\u0446\u0456\u0457, \u0449\u043e \u043f\u0456\u0434\u0442\u0440\u0438\u043c\u0443\u0454 \u0442\u043e\u043a\u0435\u043d \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u0444\u0456\u043a\u0430\u0446\u0456\u0456\u0457.", "security", "uk");
t.add("No session available, it either timed out or cookies are not enabled.", "\u0421\u0435\u0441\u0456\u044f \u043d\u0435\u0434\u043e\u0441\u0442\u0443\u043f\u043d\u0430, \u0457\u0457 \u0447\u0430\u0441 \u0432\u0438\u0439\u0448\u043e\u0432, \u0430\u0431\u043e cookies \u0432\u0438\u043c\u043a\u043d\u0435\u043d\u043e.", "security", "uk");
t.add("No token could be found.", "\u0422\u043e\u043a\u0435\u043d \u043d\u0435 \u0437\u043d\u0430\u0439\u0434\u0435\u043d\u043e.", "security", "uk");
t.add("Username could not be found.", "\u0406\u043c\u2019\u044f \u043a\u043e\u0440\u0438\u0441\u0442\u0443\u0432\u0430\u0447\u0430 \u043d\u0435 \u0437\u043d\u0430\u0439\u0434\u0435\u043d\u043e.", "security", "uk");
t.add("Account has expired.", "\u0422\u0435\u0440\u043c\u0456\u043d \u0434\u0456\u0457 \u043e\u0431\u043b\u0456\u043a\u043e\u0432\u043e\u0433\u043e \u0437\u0430\u043f\u0438\u0441\u0443 \u0432\u0438\u0447\u0435\u0440\u043f\u0430\u043d\u043e.", "security", "uk");
t.add("Credentials have expired.", "\u0422\u0435\u0440\u043c\u0456\u043d \u0434\u0456\u0457 \u0430\u0432\u0442\u0435\u043d\u0442\u0438\u0444\u0456\u043a\u0430\u0446\u0456\u0439\u043d\u0438\u0445 \u0434\u0430\u043d\u0438\u0445 \u0432\u0438\u0447\u0435\u0440\u043f\u0430\u043d\u043e.", "security", "uk");
t.add("Account is disabled.", "\u041e\u0431\u043b\u0456\u043a\u043e\u0432\u0438\u0439 \u0437\u0430\u043f\u0438\u0441 \u0432\u0456\u0434\u043a\u043b\u044e\u0447\u0435\u043d\u043e.", "security", "uk");
t.add("Account is locked.", "\u041e\u0431\u043b\u0456\u043a\u043e\u0432\u0438\u0439 \u0437\u0430\u043f\u0438\u0441 \u0437\u0430\u0431\u043b\u043e\u043a\u043e\u0432\u0430\u043d\u043e.", "security", "uk");
t.add("Too many failed login attempts, please try again later.", "\u0417\u0430\u0431\u0430\u0433\u0430\u0442\u043e \u043d\u0435\u0432\u0434\u0430\u043b\u0438\u0445 \u0441\u043f\u0440\u043e\u0431 \u0432\u0445\u043e\u0434\u0443. \u0411\u0443\u0434\u044c \u043b\u0430\u0441\u043a\u0430, \u0441\u043f\u0440\u043e\u0431\u0443\u0439\u0442\u0435 \u043f\u0456\u0437\u043d\u0456\u0448\u0435.", "security", "uk");
t.add("Invalid or expired login link.", "\u041f\u043e\u0441\u0438\u043b\u0430\u043d\u043d\u044f \u0434\u043b\u044f \u0432\u0445\u043e\u0434\u0443 \u043d\u0435\u0434\u0456\u0439\u0441\u043d\u0435, \u0430\u0431\u043e \u0442\u0435\u0440\u043c\u0456\u043d \u0439\u043e\u0433\u043e \u0434\u0456\u0457 \u0437\u0430\u043a\u0456\u043d\u0447\u0438\u0432\u0441\u044f.", "security", "uk");
t.add("Too many failed login attempts, please try again in %minutes% minute.", "\u0417\u0430\u0431\u0430\u0433\u0430\u0442\u043e \u043d\u0435\u0432\u0434\u0430\u043b\u0438\u0445 \u0441\u043f\u0440\u043e\u0431 \u0432\u0445\u043e\u0434\u0443. \u0411\u0443\u0434\u044c \u043b\u0430\u0441\u043a\u0430, \u0441\u043f\u0440\u043e\u0431\u0443\u0439\u0442\u0435 \u0437\u043d\u043e\u0432\u0443 \u0447\u0435\u0440\u0435\u0437 %minutes% \u0445\u0432\u0438\u043b\u0438\u043d\u0443.", "security", "uk");
t.add("Too many failed login attempts, please try again in %minutes% minutes.", "\u0417\u0430\u0431\u0430\u0433\u0430\u0442\u043e \u043d\u0435\u0432\u0434\u0430\u043b\u0438\u0445 \u0441\u043f\u0440\u043e\u0431 \u0432\u0445\u043e\u0434\u0443. \u0411\u0443\u0434\u044c \u043b\u0430\u0441\u043a\u0430, \u0441\u043f\u0440\u043e\u0431\u0443\u0439\u0442\u0435 \u0437\u043d\u043e\u0432\u0443 \u0447\u0435\u0440\u0435\u0437 %minutes% \u0445\u0432.", "security", "uk");
})(Translator);
