(function (t) {
// fr
t.add("user.password_requested", "La r\u00e9initialisation du mot de passe a \u00e9t\u00e9 demand\u00e9", "users", "fr");
t.add("user.password_requested_message", "Il semble qu'une demande de r\u00e9initialisation de mot de passe ait \u00e9t\u00e9 demand\u00e9 pour ce compte.", "users", "fr");
t.add("user.password_reset_link", "Pour changer votre mot de passe, veuillez cliquer sur le lien suivant", "users", "fr");
t.add("user.reset_password", "R\u00e9initialiser", "users", "fr");
t.add("user.password_reset_title", "R\u00e9initialisation du mot de passe", "users", "fr");
t.add("user.enter_a_new_password", "Veuillez entrer le nouveau mot de passe :", "users", "fr");
t.add("user.password_reset_email_sent", "Veuillez v\u00e9rifier votre email et suivez les instructions pour r\u00e9initialiser votre mot de passe.", "users", "fr");
})(Translator);
