(function (t) {
// fi
t.add("This value should be false.", "Arvon tulee olla ep\u00e4tosi.", "validators", "fi");
t.add("This value should be true.", "Arvon tulee olla tosi.", "validators", "fi");
t.add("This value should be of type {{ type }}.", "Arvon tulee olla tyyppi\u00e4 {{ type }}.", "validators", "fi");
t.add("This value should be blank.", "Arvon tulee olla tyhj\u00e4.", "validators", "fi");
t.add("The value you selected is not a valid choice.", "Arvon tulee olla yksi annetuista vaihtoehdoista.", "validators", "fi");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Sinun tulee valita v\u00e4hint\u00e4\u00e4n {{ limit }} vaihtoehtoa.", "validators", "fi");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Sinun tulee valitan enint\u00e4\u00e4n {{ limit }} vaihtoehtoa.", "validators", "fi");
t.add("One or more of the given values is invalid.", "Yksi tai useampi annetuista arvoista on virheellinen.", "validators", "fi");
t.add("This field was not expected.", "T\u00e4ss\u00e4 kent\u00e4ss\u00e4 ei odotettu.", "validators", "fi");
t.add("This field is missing.", "T\u00e4m\u00e4 kentt\u00e4 puuttuu.", "validators", "fi");
t.add("This value is not a valid date.", "Annettu arvo ei ole kelvollinen p\u00e4iv\u00e4m\u00e4\u00e4r\u00e4.", "validators", "fi");
t.add("This value is not a valid datetime.", "Annettu arvo ei ole kelvollinen p\u00e4iv\u00e4m\u00e4\u00e4r\u00e4 ja kellonaika.", "validators", "fi");
t.add("This value is not a valid email address.", "Annettu arvo ei ole kelvollinen s\u00e4hk\u00f6postiosoite.", "validators", "fi");
t.add("The file could not be found.", "Tiedostoa ei l\u00f6ydy.", "validators", "fi");
t.add("The file is not readable.", "Tiedostoa ei voida lukea.", "validators", "fi");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Tiedostonkoko ({{ size }} {{ suffix }}) on liian iso. Suurin sallittu tiedostonkoko on {{ limit }} {{ suffix }}.", "validators", "fi");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Tiedostotyyppi ({{ type }}) on virheellinen. Sallittuja tiedostotyyppej\u00e4 ovat {{ types }}.", "validators", "fi");
t.add("This value should be {{ limit }} or less.", "Arvon tulee olla {{ limit }} tai v\u00e4hemm\u00e4n.", "validators", "fi");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Liian pitk\u00e4 sy\u00f6te. Sy\u00f6te saa olla enint\u00e4\u00e4n {{ limit }} merkki\u00e4.", "validators", "fi");
t.add("This value should be {{ limit }} or more.", "Arvon tulee olla {{ limit }} tai enemm\u00e4n.", "validators", "fi");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Liian lyhyt sy\u00f6te. Sy\u00f6tteen tulee olla v\u00e4hint\u00e4\u00e4n {{ limit }} merkki\u00e4.", "validators", "fi");
t.add("This value should not be blank.", "Kentt\u00e4 ei voi olla tyhj\u00e4.", "validators", "fi");
t.add("This value should not be null.", "Sy\u00f6te ei voi olla null.", "validators", "fi");
t.add("This value should be null.", "Sy\u00f6tteen tulee olla null.", "validators", "fi");
t.add("This value is not valid.", "Virheellinen arvo.", "validators", "fi");
t.add("This value is not a valid time.", "Annettu arvo ei ole kelvollinen kellonaika.", "validators", "fi");
t.add("This value is not a valid URL.", "Annettu arvo ei ole kelvollinen URL-osoite.", "validators", "fi");
t.add("The two values should be equal.", "Kahden annetun arvon tulee olla samat.", "validators", "fi");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Annettu tiedosto on liian iso. Suurin sallittu tiedostokoko on {{ limit }} {{ suffix }}.", "validators", "fi");
t.add("The file is too large.", "Tiedosto on liian iso.", "validators", "fi");
t.add("The file could not be uploaded.", "Tiedoston siirto ep\u00e4onnistui.", "validators", "fi");
t.add("This value should be a valid number.", "T\u00e4m\u00e4n arvon tulee olla numero.", "validators", "fi");
t.add("This file is not a valid image.", "T\u00e4m\u00e4 tiedosto ei ole kelvollinen kuva.", "validators", "fi");
t.add("This is not a valid IP address.", "T\u00e4m\u00e4 ei ole kelvollinen IP-osoite.", "validators", "fi");
t.add("This value is not a valid language.", "T\u00e4m\u00e4 arvo ei ole kelvollinen kieli.", "validators", "fi");
t.add("This value is not a valid locale.", "T\u00e4m\u00e4 arvo ei ole kelvollinen kieli- ja alueasetus (locale).", "validators", "fi");
t.add("This value is not a valid country.", "T\u00e4m\u00e4 arvo ei ole kelvollinen maa.", "validators", "fi");
t.add("This value is already used.", "T\u00e4m\u00e4 arvo on jo k\u00e4ytetty.", "validators", "fi");
t.add("The size of the image could not be detected.", "Kuvan kokoa ei voitu tunnistaa.", "validators", "fi");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Kuva on liian leve\u00e4 ({{ width }}px). Sallittu maksimileveys on {{ max_width }}px.", "validators", "fi");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Kuva on liian kapea ({{ width }}px). Leveyden tulisi olla v\u00e4hint\u00e4\u00e4n {{ min_width }}px.", "validators", "fi");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Kuva on liian korkea ({{ width }}px). Sallittu maksimikorkeus on {{ max_width }}px.", "validators", "fi");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Kuva on liian matala ({{ height }}px). Korkeuden tulisi olla v\u00e4hint\u00e4\u00e4n {{ min_height }}px.", "validators", "fi");
t.add("This value should be the user's current password.", "T\u00e4m\u00e4n arvon tulisi olla k\u00e4ytt\u00e4j\u00e4n t\u00e4m\u00e4nhetkinen salasana.", "validators", "fi");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "T\u00e4m\u00e4n arvon tulisi olla tasan yhden merkin pituinen.|T\u00e4m\u00e4n arvon tulisi olla tasan {{ limit }} merkki\u00e4 pitk\u00e4.", "validators", "fi");
t.add("The file was only partially uploaded.", "Tiedosto ladattiin vain osittain.", "validators", "fi");
t.add("No file was uploaded.", "Tiedostoa ei ladattu.", "validators", "fi");
t.add("No temporary folder was configured in php.ini.", "V\u00e4liaikaishakemistoa ei ole asetettu php.ini -tiedostoon.", "validators", "fi");
t.add("Cannot write temporary file to disk.", "V\u00e4liaikaistiedostoa ei voitu kirjoittaa levylle.", "validators", "fi");
t.add("A PHP extension caused the upload to fail.", "PHP-laajennoksen vuoksi tiedoston lataus ep\u00e4onnistui.", "validators", "fi");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "T\u00e4ss\u00e4 ryhm\u00e4ss\u00e4 tulisi olla yksi tai useampi elementti.|T\u00e4ss\u00e4 ryhm\u00e4ss\u00e4 tulisi olla v\u00e4hint\u00e4\u00e4n {{ limit }} elementti\u00e4.", "validators", "fi");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "T\u00e4ss\u00e4 ryhm\u00e4ss\u00e4 tulisi olla enint\u00e4\u00e4n yksi elementti.|T\u00e4ss\u00e4 ryhm\u00e4ss\u00e4 tulisi olla enint\u00e4\u00e4n {{ limit }} elementti\u00e4.", "validators", "fi");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "T\u00e4ss\u00e4 ryhm\u00e4ss\u00e4 tulisi olla tasan yksi elementti.|T\u00e4ss\u00e4 ryhm\u00e4ss\u00e4 tulisi olla enint\u00e4\u00e4n {{ limit }} elementti\u00e4.", "validators", "fi");
t.add("Invalid card number.", "Virheellinen korttinumero.", "validators", "fi");
t.add("Unsupported card type or invalid card number.", "T\u00e4t\u00e4 korttityyppi\u00e4 ei tueta tai korttinumero on virheellinen.", "validators", "fi");
t.add("This is not a valid International Bank Account Number (IBAN).", "Arvo ei ole kelvollinen kansainv\u00e4linen pankkitilinumero (IBAN).", "validators", "fi");
t.add("This value is not a valid ISBN-10.", "Arvo ei ole kelvollinen ISBN-10.", "validators", "fi");
t.add("This value is not a valid ISBN-13.", "Arvo ei ole kelvollinen ISBN-13.", "validators", "fi");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Arvo ei ole kelvollinen ISBN-10 tai kelvollinen ISBN-13.", "validators", "fi");
t.add("This value is not a valid ISSN.", "Arvo ei ole kelvollinen ISSN.", "validators", "fi");
t.add("This value is not a valid currency.", "Arvo ei ole kelvollinen valuutta.", "validators", "fi");
t.add("This value should be equal to {{ compared_value }}.", "Arvo ei ole sama kuin {{ compared_value }}.", "validators", "fi");
t.add("This value should be greater than {{ compared_value }}.", "Arvon tulee olla suurempi kuin {{ compared_value }}.", "validators", "fi");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Arvon tulee olla suurempi tai yht\u00e4 suuri kuin {{ compared_value }}.", "validators", "fi");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "T\u00e4m\u00e4 arvo tulee olla sama kuin {{ compared_value_type }} {{ compared_value }}.", "validators", "fi");
t.add("This value should be less than {{ compared_value }}.", "Arvon tulee olla pienempi kuin {{ compared_value }}.", "validators", "fi");
t.add("This value should be less than or equal to {{ compared_value }}.", "Arvon tulee olla pienempi tai yht\u00e4 suuri {{ compared_value }}.", "validators", "fi");
t.add("This value should not be equal to {{ compared_value }}.", "Arvon ei tule olla sama kuin {{ compared_value }}.", "validators", "fi");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "T\u00e4m\u00e4 arvo ei tule olla sama kuin {{ compared_value_type }} {{ compared_value }}.", "validators", "fi");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Kuvasuhde on liian suuri ({{ ratio }}). Suurin sallittu suhde on {{ max_ratio }}.", "validators", "fi");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Kuvasuhde on liian pieni ({{ ratio }}). Pienin sallittu arvo on {{ min_ratio }}.", "validators", "fi");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Kuva on neli\u00e4 ({{ width }}x{{ height }}px). Neli\u00f6t kuvat eiv\u00e4t ole sallittuja.", "validators", "fi");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Kuva on vaakasuuntainen ({{ width }}x{{ height }}px). Vaakasuuntaiset kuvat eiv\u00e4t ole sallittuja.", "validators", "fi");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Kuva on pystysuuntainen ({{ width }}x{{ height }}px). Pystysuuntaiset kuvat eiv\u00e4t ole sallittuja.", "validators", "fi");
t.add("An empty file is not allowed.", "Tyhj\u00e4 tiedosto ei ole sallittu.", "validators", "fi");
t.add("The host could not be resolved.", "Palvelimeen ei saatu yhteytt\u00e4.", "validators", "fi");
t.add("This value does not match the expected {{ charset }} charset.", "Arvo ei vastaa odotettua merkist\u00f6\u00e4 {{ charset }}.", "validators", "fi");
t.add("This is not a valid Business Identifier Code (BIC).", "Arvo ei ole kelvollinen yritystunnus (BIC).", "validators", "fi");
t.add("Error", "Virhe", "validators", "fi");
t.add("This is not a valid UUID.", "Arvo ei ole kelvollinen UUID.", "validators", "fi");
t.add("This value should be a multiple of {{ compared_value }}.", "T\u00e4m\u00e4n arvon tulisi olla kerrannainen {{ compared_value }}.", "validators", "fi");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "T\u00e4m\u00e4 yritystunnus (BIC) ei ole liitetty IBAN {{ iban }}.", "validators", "fi");
t.add("This value should be valid JSON.", "Arvon tulee olla kelvollinen JSON.", "validators", "fi");
t.add("This collection should contain only unique elements.", "T\u00e4m\u00e4n ryhm\u00e4n tulisi sis\u00e4lt\u00e4\u00e4 vain yksil\u00f6llisi\u00e4 arvoja.", "validators", "fi");
t.add("This value should be positive.", "Arvon tulisi olla positiivinen.", "validators", "fi");
t.add("This value should be either positive or zero.", "Arvon tulisi olla joko positiivinen tai nolla.", "validators", "fi");
t.add("This value should be negative.", "Arvon tulisi olla negatiivinen.", "validators", "fi");
t.add("This value should be either negative or zero.", "Arvon tulisi olla joko negatiivinen tai nolla.", "validators", "fi");
t.add("This value is not a valid timezone.", "Arvo ei ole kelvollinen aikavy\u00f6hyke.", "validators", "fi");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "T\u00e4m\u00e4 salasana on vuotanut tietomurrossa, sit\u00e4 ei saa k\u00e4ytt\u00e4\u00e4. K\u00e4yt\u00e4 toista salasanaa.", "validators", "fi");
t.add("This value should be between {{ min }} and {{ max }}.", "Arvon tulisi olla v\u00e4lill\u00e4 {{ min }} - {{ max }}.", "validators", "fi");
t.add("This value is not a valid hostname.", "Arvo ei ole kelvollinen laitenimi (hostname).", "validators", "fi");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Ryhm\u00e4ss\u00e4 olevien elementtien m\u00e4\u00e4r\u00e4n pit\u00e4\u00e4 olla monikerta luvulle {{ compared_value }}.", "validators", "fi");
t.add("This value should satisfy at least one of the following constraints:", "T\u00e4m\u00e4n arvon tulee l\u00e4p\u00e4ist\u00e4 v\u00e4hint\u00e4\u00e4n yksi seuraavista tarkistuksista:", "validators", "fi");
t.add("Each element of this collection should satisfy its own set of constraints.", "Ryhm\u00e4n jokaisen elementin tulee l\u00e4p\u00e4ist\u00e4 omat tarkistuksensa.", "validators", "fi");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "T\u00e4m\u00e4 arvo ei ole kelvollinen ISIN-koodi (International Securities Identification Number).", "validators", "fi");
t.add("This value should be a valid expression.", "T\u00e4m\u00e4n arvon on oltava kelvollinen lauseke.", "validators", "fi");
t.add("This value is not a valid CSS color.", "T\u00e4m\u00e4 arvo ei ole kelvollinen CSS-v\u00e4rim\u00e4\u00e4ritys.", "validators", "fi");
t.add("This value is not a valid CIDR notation.", "T\u00e4m\u00e4 arvo ei ole kelvollinen CIDR-merkint\u00e4.", "validators", "fi");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "Verkkomaskille annetun arvon tulisi olla {{ min }} ja {{ max }} v\u00e4lill\u00e4.", "validators", "fi");
t.add("This form should not contain extra fields.", "T\u00e4m\u00e4 lomake ei voi sis\u00e4lt\u00e4\u00e4 ylim\u00e4\u00e4r\u00e4isi\u00e4 kentti\u00e4.", "validators", "fi");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Ladattu tiedosto on liian iso. Ole hyv\u00e4 ja lataa pienempi tiedosto.", "validators", "fi");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF-tarkiste on virheellinen. Ole hyv\u00e4 ja yrit\u00e4 l\u00e4hett\u00e4\u00e4 lomake uudestaan.", "validators", "fi");
t.add("This value is not a valid HTML5 color.", "T\u00e4m\u00e4 arvo ei ole kelvollinen HTML5-v\u00e4ri.", "validators", "fi");
t.add("Please enter a valid birthdate.", "Sy\u00f6t\u00e4 kelvollinen syntym\u00e4aika.", "validators", "fi");
t.add("The selected choice is invalid.", "Valittu vaihtoehto ei kelpaa.", "validators", "fi");
t.add("The collection is invalid.", "Ryhm\u00e4 ei kelpaa.", "validators", "fi");
t.add("Please select a valid color.", "Valitse kelvollinen v\u00e4ri.", "validators", "fi");
t.add("Please select a valid country.", "Valitse kelvollinen maa.", "validators", "fi");
t.add("Please select a valid currency.", "Valitse kelvollinen valuutta.", "validators", "fi");
t.add("Please choose a valid date interval.", "Valitse kelvollinen aikav\u00e4li.", "validators", "fi");
t.add("Please enter a valid date and time.", "Sy\u00f6t\u00e4 kelvolliset p\u00e4iv\u00e4 ja aika.", "validators", "fi");
t.add("Please enter a valid date.", "Sy\u00f6t\u00e4 kelvollinen p\u00e4iv\u00e4.", "validators", "fi");
t.add("Please select a valid file.", "Valitse kelvollinen tiedosto.", "validators", "fi");
t.add("The hidden field is invalid.", "Piilotettu kentt\u00e4 ei ole kelvollinen.", "validators", "fi");
t.add("Please enter an integer.", "Sy\u00f6t\u00e4 kokonaisluku.", "validators", "fi");
t.add("Please select a valid language.", "Valitse kelvollinen kieli.", "validators", "fi");
t.add("Please select a valid locale.", "Valitse kelvollinen kielikoodi.", "validators", "fi");
t.add("Please enter a valid money amount.", "Sy\u00f6t\u00e4 kelvollinen rahasumma.", "validators", "fi");
t.add("Please enter a number.", "Sy\u00f6t\u00e4 numero.", "validators", "fi");
t.add("The password is invalid.", "Salasana ei kelpaa.", "validators", "fi");
t.add("Please enter a percentage value.", "Sy\u00f6t\u00e4 prosenttiluku.", "validators", "fi");
t.add("The values do not match.", "Arvot eiv\u00e4t vastaa toisiaan.", "validators", "fi");
t.add("Please enter a valid time.", "Sy\u00f6t\u00e4 kelvollinen kellonaika.", "validators", "fi");
t.add("Please select a valid timezone.", "Valitse kelvollinen aikavy\u00f6hyke.", "validators", "fi");
t.add("Please enter a valid URL.", "Sy\u00f6t\u00e4 kelvollinen URL.", "validators", "fi");
t.add("Please enter a valid search term.", "Sy\u00f6t\u00e4 kelvollinen hakusana.", "validators", "fi");
t.add("Please provide a valid phone number.", "Anna kelvollinen puhelinnumero.", "validators", "fi");
t.add("The checkbox has an invalid value.", "Valintaruudun arvo ei  kelpaa.", "validators", "fi");
t.add("Please enter a valid email address.", "Sy\u00f6t\u00e4 kelvollinen s\u00e4hk\u00f6postiosoite.", "validators", "fi");
t.add("Please select a valid option.", "Valitse kelvollinen vaihtoehto.", "validators", "fi");
t.add("Please select a valid range.", "Valitse kelvollinen v\u00e4li.", "validators", "fi");
t.add("Please enter a valid week.", "Sy\u00f6t\u00e4 kelvollinen viikko.", "validators", "fi");
})(Translator);
