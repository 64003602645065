(function (t) {
// my
t.add("This value should be false.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a false \u1016\u103c\u1005\u103a\u101b\u1019\u100a\u103a\u104b", "validators", "my");
t.add("This value should be true.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a true \u1016\u103c\u1005\u103a\u101b\u1019\u100a\u103a\u104b", "validators", "my");
t.add("This value should be of type {{ type }}.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ type }} \u1021\u1019\u103b\u102d\u102f\u1038\u1021\u1005\u102c\u1038\u1016\u103c\u1005\u103a\u101b\u1019\u100a\u103a\u104b ", "validators", "my");
t.add("This value should be blank.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1000\u103d\u1015\u103a\u101c\u1015\u103a\u1019\u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("The value you selected is not a valid choice.", "\u101e\u1004\u103a\u101b\u103d\u1031\u1038\u1001\u103b\u101a\u103a\u101e\u1031\u102c\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a \u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u101e\u1004\u103a\u101e\u100a\u103a \u1021\u1014\u100a\u103a\u1038\u1006\u102f\u1036\u1038\u101b\u103d\u1031\u1038\u1001\u103b\u101a\u103a\u1019\u103e\u102f {{ limit }} \u1001\u102f\u101b\u103d\u1031\u1038\u1001\u103b\u101a\u103a\u101b\u1019\u100a\u103a\u104b", "validators", "my");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u101e\u1004\u103a\u101e\u100a\u103a\u1021\u1019\u103b\u102c\u1038\u1006\u102f\u1036\u1038 {{ limit }} \u1001\u102f\u101e\u102c\u101b\u103d\u1031\u1038\u1001\u103b\u101a\u103a\u1001\u103d\u1004\u103a\u1037\u101b\u103e\u102d\u101e\u100a\u103a\u104b", "validators", "my");
t.add("One or more of the given values is invalid.", "\u1015\u1031\u1038\u1011\u102c\u1038\u101e\u1031\u102c\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1010\u1005\u103a\u1001\u102f (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u1010\u1005\u103a\u1001\u102f\u1011\u1000\u103a\u1015\u102d\u102f\u104d\u1019\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This field was not expected.", "\u1024\u1000\u103d\u1000\u103a\u101c\u1015\u103a\u1000\u102d\u102f\u1019\u1019\u103b\u103e\u1031\u102c\u103a\u101c\u1004\u1037\u103a\u1011\u102c\u1038\u1015\u102b\u104b", "validators", "my");
t.add("This field is missing.", "\u1024\u1000\u103d\u1000\u103a\u101c\u1015\u103a\u1000\u102d\u102f\u1019\u1019\u103b\u103e\u1031\u102c\u103a\u101c\u1004\u1037\u103a\u1011\u102c\u1038\u1015\u102b\u104b", "validators", "my");
t.add("This value is not a valid date.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u103a\u101b\u1000\u103a\u1005\u103d\u1032\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value is not a valid datetime.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u103a \u101b\u1000\u103a\u1005\u103d\u1032\/\u1021\u1001\u103b\u102d\u1014\u103a \u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value is not a valid email address.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u103a \u1021\u102e\u1038\u1019\u1031\u1038\u101c\u102d\u1015\u103a\u1005\u102c \u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("The file could not be found.", "\u1016\u102d\u102f\u1004\u103a\u101b\u103e\u102c\u1019\u1010\u103d\u1031\u1037\u1015\u102b\u104b", "validators", "my");
t.add("The file is not readable.", "\u1024\u1016\u102d\u102f\u1004\u103a\u1000\u102d\u102f \u1016\u1010\u103a\u104d\u1019\u101b\u1015\u102b\u104b", "validators", "my");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u1016\u102d\u102f\u1004\u103a\u1021\u101b\u103d\u101a\u103a\u1021\u1005\u102c\u1038 \u1021\u101c\u103d\u1014\u103a\u1000\u103c\u102e\u1038\u1014\u1031\u101e\u100a\u103a ({{ size }} {{ suffix }}). \u1001\u103d\u1004\u103a\u1037\u1015\u103c\u102f\u1011\u102c\u1038\u101e\u1031\u102c \u1021\u1019\u103b\u102c\u1038\u1006\u102f\u1036\u1038 \u1016\u102d\u102f\u1004\u103a\u1006\u102d\u102f\u1012\u103a\u101e\u100a\u103a {{ limit }} {{ suffix }} \u1016\u103c\u1005\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "\u1016\u102d\u102f\u1004\u103a\u104f mime \u1021\u1019\u103b\u102d\u102f\u1038\u1021\u1005\u102c\u1038\u1019\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u1015\u102b ({{ type }})\u104b \u1001\u103d\u1004\u1037\u103a\u1015\u103c\u102f\u1011\u102c\u1038\u101e\u1031\u102c mime \u1021\u1019\u103b\u102d\u102f\u1038\u1021\u1005\u102c\u1038\u1019\u103b\u102c\u1038\u1019\u103e\u102c {{ types }}.", "validators", "my");
t.add("This value should be {{ limit }} or less.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ limit }} (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) {{ limit }} \u1011\u1000\u103a\u1014\u100a\u103a\u1038\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1021\u101c\u103d\u1014\u103a\u101b\u103e\u100a\u103a\u101c\u103d\u1014\u103a\u1038\u101e\u100a\u103a\u104b \u104e\u1004\u103a\u1038\u1010\u103d\u1004\u103a\u1021\u1000\u1039\u1001\u101b\u102c {{ limit }} (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u104e\u1004\u103a\u1038\u1011\u1000\u103a\u1014\u100a\u103a\u1038\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b | \u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1021\u101c\u103d\u1014\u103a\u101b\u103e\u100a\u103a\u101c\u103d\u1014\u103a\u1038\u101e\u100a\u103a\u104b \u104e\u1004\u103a\u1038\u1010\u103d\u1004\u103a\u1021\u1000\u1039\u1001\u101b\u102c {{limit}} \u1001\u102f\u1014\u103e\u1004\u1037\u103a\u1021\u1011\u1000\u103a\u101b\u103e\u102d\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should be {{ limit }} or more.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{limit}} (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u1011\u102d\u102f\u1037\u1011\u1000\u103a\u1015\u102d\u102f\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1021\u101c\u103d\u1014\u103a\u1010\u102d\u102f\u101c\u103d\u1014\u103a\u1038\u101e\u100a\u103a\u104b \u104e\u1004\u103a\u1038\u1010\u103d\u1004\u103a\u1021\u1000\u1039\u1001\u101b\u102c {{limit}} (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u1011\u102d\u102f\u1037\u1011\u1000\u103a\u1015\u102d\u102f\u101b\u103e\u102d\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b | \u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1010\u102d\u102f\u101c\u103d\u1014\u103a\u1038\u101e\u100a\u103a\u104b \u104e\u1004\u103a\u1038\u1010\u103d\u1004\u103a\u1021\u1000\u1039\u1001\u101b\u102c {{limit}} \u101c\u102f\u1036\u1038\u1014\u103e\u1004\u1037\u103a\u1021\u1011\u1000\u103a\u101b\u103e\u102d\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should not be blank.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1000\u103d\u1000\u103a\u101c\u1015\u103a\u1019\u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("This value should not be null.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a null \u1019\u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("This value should be null.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a null \u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value is not valid.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value is not a valid time.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c \u1021\u1001\u103b\u102d\u1014\u103a\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value is not a valid URL.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c URL \u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("The two values should be equal.", "\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1014\u103e\u1005\u103a\u1001\u102f\u101e\u100a\u103a \u1010\u1030\u100a\u102e\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u1024\u1016\u102d\u102f\u1004\u103a\u101e\u100a\u103a \u1021\u101c\u103d\u1014\u103a\u1000\u103c\u102e\u1038\u101e\u100a\u103a\u104b \u1001\u103d\u1004\u1037\u103a\u1015\u103c\u102f\u1011\u102c\u1038\u101e\u100a\u1037\u103a\u1021\u1019\u103b\u102c\u1038\u1006\u102f\u1036\u1038\u1016\u102d\u102f\u1004\u103a\u1021\u101b\u103d\u101a\u103a\u1021\u1005\u102c\u1038\u101e\u100a\u103a {{ limit }} {{ suffix }} \u1016\u103c\u1005\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The file is too large.", "\u1024\u1016\u102d\u102f\u1004\u103a\u101e\u100a\u103a \u1021\u101c\u103d\u1014\u103a\u1000\u103c\u102e\u1038\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The file could not be uploaded.", "\u1024\u1016\u102d\u102f\u1004\u103a\u1000\u102d\u102f\u1010\u1004\u103a\u104d\u1019\u101b\u1015\u102b\u104b", "validators", "my");
t.add("This value should be a valid number.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1014\u1036\u1015\u102b\u1010\u103a\u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This file is not a valid image.", "\u1024\u1016\u102d\u102f\u1004\u103a\u101e\u100a\u103a\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c \u1013\u102b\u1010\u103a\u1015\u102f\u1036\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This is not a valid IP address.", "\u104e\u1004\u103a\u1038\u101e\u100a\u103a\u1010\u101b\u102c\u1038\u101d\u1004\u103a IP \u101c\u102d\u1015\u103a\u1005\u102c\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value is not a valid language.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1018\u102c\u101e\u102c\u1005\u1000\u102c\u1038\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value is not a valid locale.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1018\u102c\u101e\u102c\u1015\u103c\u1014\u103a\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value is not a valid country.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1014\u102d\u102f\u1004\u103a\u1004\u1036\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value is already used.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1021\u101e\u102f\u1036\u1038\u1015\u103c\u102f\u1015\u103c\u102e\u1038\u101e\u102c\u1038\u1016\u103c\u1005\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The size of the image could not be detected.", "\u1013\u102b\u1010\u103a\u1015\u102f\u1036\u1021\u101b\u103d\u101a\u103a\u1021\u1005\u102c\u1038\u1000\u102d\u102f\u101b\u103e\u102c\u1019\u1010\u103d\u1031\u1037\u1015\u102b\u104b", "validators", "my");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u1015\u102f\u1036\u104f\u1021\u101c\u103b\u102c\u1038\u101e\u100a\u103a \u1000\u103c\u102e\u1038\u101c\u103d\u1014\u103a\u1038\u101e\u100a\u103a ({{ width }}px)\u104b \u1001\u103d\u1004\u1037\u103a\u1015\u103c\u102f\u1011\u102c\u1038\u101e\u100a\u1037\u103a\u1021\u1019\u103b\u102c\u1038\u1006\u102f\u1036\u1038\u1021\u101c\u103b\u102c\u1038\u101e\u100a\u103a {{max_width}}px \u1016\u103c\u1005\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u1015\u102f\u1036\u104f\u1021\u101c\u103b\u102c\u1038\u101e\u100a\u103a \u101e\u1031\u1038\u101c\u103d\u1014\u103a\u1038\u101e\u100a\u103a ({{ width }}px)\u104b \u1001\u103d\u1004\u1037\u103a\u1015\u103c\u102f\u1011\u102c\u1038\u101e\u100a\u1037\u103a\u1021\u1014\u100a\u103a\u1038\u1006\u102f\u1036\u1038\u1021\u101c\u103b\u102c\u1038\u101e\u100a\u103a {{max_width}}px \u1016\u103c\u1005\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u1015\u102f\u1036\u104f\u1021\u1014\u1036\u101e\u100a\u103a \u1000\u103c\u102e\u1038\u101c\u103d\u1014\u103a\u1038\u101e\u100a\u103a ({{ height }}px)\u104b \u1001\u103d\u1004\u1037\u103a\u1015\u103c\u102f\u1011\u102c\u1038\u101e\u100a\u1037\u103a\u1021\u1019\u103b\u102c\u1038\u1006\u102f\u1036\u1038\u1021\u1014\u1036\u101e\u100a\u103a {{max_height}}px \u1016\u103c\u1005\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u1015\u102f\u1036\u104f\u1021\u1014\u1036\u101e\u100a\u103a \u101e\u1031\u1038\u101c\u103d\u1014\u103a\u1038\u101e\u100a\u103a ({{ height }}px)\u104b \u1001\u103d\u1004\u1037\u103a\u1015\u103c\u102f\u1011\u102c\u1038\u101e\u100a\u1037\u103a\u1021\u1014\u100a\u103a\u1038\u1006\u102f\u1036\u1038\u1021\u1014\u1036\u101e\u100a\u103a {{min_height}}px \u1016\u103c\u1005\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should be the user's current password.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1021\u101e\u102f\u1036\u1038\u1015\u103c\u102f\u101e\u1030\u104f \u101c\u1000\u103a\u101b\u103e\u102d\u1005\u1000\u102c\u1038\u101d\u103e\u1000\u103a\u1016\u103c\u1005\u103a\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1005\u102c\u101c\u102f\u1036\u1038 {{limit}} \u1021\u1010\u102d\u1021\u1000\u103b\u101b\u103e\u102d\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The file was only partially uploaded.", "\u1024\u1016\u102d\u102f\u1004\u103a\u101e\u100a\u103a\u1010\u1005\u103a\u1005\u102d\u1010\u103a\u1010\u1005\u103a\u1015\u102d\u102f\u1004\u103a\u1038\u101e\u102c upload \u1010\u1004\u103a\u1001\u1032\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("No file was uploaded.", "\u1019\u100a\u103a\u101e\u100a\u103a\u1037 \u1016\u102d\u102f\u1004\u103a\u1019\u103b\u103e upload \u1019\u101c\u102f\u1015\u103a\u1001\u1032\u1037\u1015\u102b\u104b", "validators", "my");
t.add("No temporary folder was configured in php.ini.", "php.ini \u1010\u103d\u1004\u103a\u101a\u102c\u101a\u102e\u1016\u102d\u102f\u1004\u103a\u1010\u103d\u1032\u1000\u102d\u102f\u1015\u103c\u1004\u103a\u1006\u1004\u103a\u1011\u102c\u1038\u1001\u103c\u1004\u103a\u1038\u1019\u101b\u103e\u102d\u1015\u102b\u104a", "validators", "my");
t.add("Cannot write temporary file to disk.", "\u101a\u102c\u101b\u102e\u1016\u102d\u102f\u1004\u103a\u1000\u102d\u102f disk \u1019\u101b\u1031\u1038\u1014\u102d\u102f\u1004\u103a\u1015\u102b\u104b", "validators", "my");
t.add("A PHP extension caused the upload to fail.", "PHP extension \u1010\u1005\u103a\u1001\u102f\u1000\u103c\u1031\u102c\u1004\u103a\u1037 upload \u1010\u1004\u103a\u104d\u1019\u101b\u1014\u102d\u102f\u1004\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u1024 collection \u1010\u103d\u1004\u103a {{limit}} element (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u1011\u102d\u102f\u1037\u1011\u1000\u103a\u1019\u1015\u102d\u102f\u101e\u1004\u1037\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u1024 collection \u1010\u103d\u1004\u103a {{limit}} element (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u104e\u1004\u103a\u1038\u1011\u1000\u103a\u1014\u100a\u103a\u1038\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u1024\u1005\u102f\u1005\u100a\u103a\u1038\u1019\u103e\u102f\u1010\u103d\u1004\u103a {{limit}} element \u1021\u1010\u102d\u1021\u1000\u103b\u1015\u102b\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("Invalid card number.", "\u1000\u1012\u103a\u1014\u1036\u1015\u102b\u1010\u103a\u1019\u1019\u103e\u1014\u103a\u1015\u102b\u104b", "validators", "my");
t.add("Unsupported card type or invalid card number.", "\u1000\u1012\u103a\u1021\u1019\u103b\u102d\u102f\u1038\u1021\u1005\u102c\u1038\u1019\u1019\u103e\u1014\u103a\u1015\u102b (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u1000\u1012\u103a\u1014\u1036\u1015\u102b\u1010\u103a\u1019\u1019\u103e\u1014\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1010\u101b\u102c\u1038\u1040\u1004\u103a\u1014\u102d\u102f\u1004\u103a\u1004\u1036\u1010\u1000\u102c\u1018\u100f\u103a\u1021\u1000\u1031\u102c\u1004\u103a\u1037\u1014\u1036\u1015\u102b\u1010\u103a (International Bank Account Number, IBAN) \u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value is not a valid ISBN-10.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c ISBN-10 \u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104a", "validators", "my");
t.add("This value is not a valid ISBN-13.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c ISBN-13 \u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104a", "validators", "my");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a ISBN-10 (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) ISBN-13 \u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104a", "validators", "my");
t.add("This value is not a valid ISSN.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a ISSN \u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value is not a valid currency.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a \u1004\u103d\u1031\u1000\u103c\u1031\u1038\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value should be equal to {{ compared_value }}.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ compared_value }} \u1014\u103e\u1004\u103a\u1037\u100a\u102e\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should be greater than {{ compared_value }}.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ compared_value }} \u1011\u1000\u103a\u1000\u103c\u102e\u1038\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ compared_value }} \u1011\u1000\u103a\u1000\u103c\u102e\u1038\u101e\u1004\u103a\u1037\u101e\u100a\u103a (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ compared_value }} \u100a\u102e\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b ", "validators", "my");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ compared_value_type }} {{ compared_value }} \u1014\u103e\u1004\u103a\u1037\u1011\u1015\u103a\u1010\u1030\u100a\u102e\u1019\u103b\u103e\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should be less than {{ compared_value }}.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ compared_value }} \u1011\u1000\u103a\u1019\u1014\u1032\u101e\u1031\u102c\u1010\u1032\u1037 \u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should be less than or equal to {{ compared_value }}.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ compared_value }} \u1011\u1000\u103a \u1019\u1014\u100a\u103a\u1038\u101e\u1031\u102c\u1010\u1014\u103a\u1016\u102d\u102f\u1038 (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u100a\u102e\u1019\u103b\u103e\u101e\u1031\u102c\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should not be equal to {{ compared_value }}.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ compared_value }} \u1014\u103e\u1004\u103a\u1037\u1019\u100a\u102e\u101e\u1004\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ compared_value_type }} {{ compared_value }} \u1014\u103e\u1004\u103a\u1037\u1011\u1015\u103a\u1010\u1030\u1019\u100a\u102e\u1019\u103b\u103e\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u1024\u1013\u102c\u1010\u103a\u1015\u102f\u1036\u1021\u1001\u103b\u102d\u102f\u1038\u1021\u1005\u102c\u1038\u101e\u100a\u103a\u1021\u101c\u103d\u1014\u103a\u1000\u103c\u102e\u1038\u101c\u103d\u1014\u103a\u1038\u101e\u100a\u103a\u104b ({{ ratio }})\u104b \u1001\u103d\u1004\u103a\u1037\u1015\u103c\u102f\u1011\u102c\u1038\u101e\u1031\u102c\u1013\u102c\u1010\u103a\u1015\u102f\u1036\u1021\u1001\u103b\u102d\u102f\u1038\u1021\u101e\u102c\u1038\u101e\u100a\u103a {{ max_ratio }} \u1016\u103c\u1005\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u1024\u1013\u102c\u1010\u103a\u1015\u102f\u1036\u1021\u1001\u103b\u102d\u102f\u1038\u1021\u1005\u102c\u1038\u101e\u100a\u103a\u1021\u101c\u103d\u1014\u103a\u101e\u1031\u1038\u101c\u103d\u1014\u103a\u1038\u101e\u100a\u103a\u104b ({{ ratio }})\u104b \u1001\u103d\u1004\u103a\u1037\u1015\u103c\u102f\u1011\u102c\u1038\u101e\u1031\u102c\u1013\u102c\u1010\u103a\u1015\u102f\u1036\u1021\u1001\u103b\u102d\u102f\u1038\u1021\u101e\u102c\u1038\u101e\u100a\u103a {{ min_ratio }} \u1016\u103c\u1005\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u1024\u1013\u102c\u1010\u103a\u1015\u102f\u1036\u101e\u100a\u103a \u1005\u1010\u102f\u101b\u1014\u103a\u1038\u1016\u103c\u1005\u103a\u1014\u1031\u101e\u100a\u103a ({{ width }}x{{ height }}px)\u104b \u1005\u1010\u102f\u101b\u1014\u103a\u1038\u1013\u102c\u1010\u103a\u1015\u102f\u1036\u1019\u103b\u102c\u1038\u1000\u102d\u102f \u1001\u103d\u1004\u103a\u1037\u1019\u1015\u103c\u102f\u1015\u102b\u104b", "validators", "my");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u1024\u1013\u102c\u1010\u103a\u1015\u102f\u1036\u101e\u100a\u103a \u1021\u101c\u103b\u103e\u102c\u1038\u101c\u102d\u102f\u1000\u103a\u1016\u103c\u1005\u103a\u1014\u1031\u101e\u100a\u103a ({{ width }}x{{ height }}px). \u1021\u101c\u103b\u103e\u102c\u1038\u101c\u102d\u102f\u1000\u103a\u1013\u102c\u1010\u103a\u1015\u102f\u1036\u1019\u103b\u102c\u1038\u1001\u103d\u1004\u103a\u1037\u1019\u1015\u103c\u102f\u1015\u102b\u104b", "validators", "my");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u1024\u1013\u102c\u1010\u103a\u1015\u102f\u1036\u101e\u100a\u103a \u1012\u1031\u102b\u1004\u103a\u101c\u102d\u102f\u1000\u103a\u1016\u103c\u1005\u103a\u1014\u1031\u101e\u100a\u103a ({{ width }}x{{ height }}px). \u1012\u1031\u102b\u1004\u103a\u101c\u102d\u102f\u1000\u103a\u1013\u102c\u1010\u103a\u1015\u102f\u1036\u1019\u103b\u102c\u1038\u1001\u103d\u1004\u103a\u1037\u1019\u1015\u103c\u102f\u1015\u102b\u104b", "validators", "my");
t.add("An empty file is not allowed.", "\u1016\u102d\u102f\u1004\u103a\u1021\u101c\u103d\u1010\u103a\u1000\u102d\u102f\u1010\u1004\u103a\u1001\u103d\u1004\u103a\u1037\u1019\u1015\u103c\u102f\u1015\u102b\u104b", "validators", "my");
t.add("The host could not be resolved.", "host \u1016\u103c\u1031\u101b\u103e\u1004\u103a\u1038\u104d\u1019\u1014\u102d\u102f\u1004\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value does not match the expected {{ charset }} charset.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1019\u103b\u103e\u1031\u102c\u103a\u1019\u103e\u1014\u103a\u1038\u1011\u102c\u1038\u101e\u1031\u102c {{ charset }} \u1005\u102c\u1038\u101c\u102f\u1036\u1038\u1014\u103e\u1004\u103a\u1037 \u1000\u102d\u102f\u1000\u103a\u100a\u102e\u1019\u103e\u102f\u1019\u101b\u103e\u102d\u1015\u102b\u104b", "validators", "my");
t.add("This is not a valid Business Identifier Code (BIC).", "\u104e\u1004\u103a\u1038\u101e\u100a\u103a\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c Business Identifier Code (BIC) \u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("Error", "\u1021\u1019\u103e\u102c\u1038", "validators", "my");
t.add("This is not a valid UUID.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a UUID \u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value should be a multiple of {{ compared_value }}.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{compared_value}} \u104f \u1005\u1010\u1030\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "\u1024 Business Identifier Code (BIC) \u101e\u100a\u103a IBAN {{ iban }} \u1014\u103e\u1004\u103a\u1037\u1006\u1000\u103a\u1005\u1015\u103a\u1019\u103e\u102f\u1019\u101b\u103e\u102d\u1015\u102b\u104b", "validators", "my");
t.add("This value should be valid JSON.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a JSON \u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This collection should contain only unique elements.", "\u1024 collection \u1000\u102d\u102f\u101a\u103a\u1015\u102d\u102f\u1004\u103a elements \u1019\u103b\u102c\u1038 \u1015\u102b\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should be positive.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1021\u1015\u1031\u102b\u1004\u103a\u1038\u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should be either positive or zero.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1021\u1015\u1031\u102b\u1004\u103a\u1038 (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u101e\u102f\u100a \u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should be negative.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1021\u1014\u102f\u1010\u103a \u1016\u103c\u1005\u103a\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value should be either negative or zero.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1021\u1014\u102f\u1010\u103a (\u101e\u102d\u102f\u1037\u1019\u101f\u102f\u1010\u103a) \u101e\u102f\u100a \u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value is not a valid timezone.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c \u1021\u1001\u103b\u102d\u1014\u103a\u1007\u102f\u1014\u103a\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "\u1024\u1005\u1000\u102c\u1038\u101d\u103e\u1000\u103a \u101e\u100a\u103a \u1012\u1031\u1010\u102c\u1015\u1031\u102b\u1000\u103a\u1000\u103c\u102c\u1038\u1019\u103e\u102f\u1010\u1005\u103a\u1001\u102f\u1016\u103c\u1005\u103a\u1001\u1032\u1037\u101e\u100a\u103a\u104b \u1024\u1005\u1000\u102c\u1038\u1040\u103e\u1000\u103a\u1000\u102d\u102f\u1021\u101e\u102f\u1036\u1038\u1019\u1015\u103c\u102f\u101b\u1015\u102b\u104b  \u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1021\u1001\u103c\u102c\u1038\u1005\u1000\u102c\u1038\u101d\u103e\u1000\u103a\u1000\u102d\u102f\u101e\u102f\u1036\u1038\u1015\u102b\u104b", "validators", "my");
t.add("This value should be between {{ min }} and {{ max }}.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a {{ min }} \u1014\u103e\u1004\u103a\u1037 {{ max }} \u1000\u103c\u102c\u1038\u101b\u103e\u102d\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value is not a valid hostname.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a hostname \u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "\u1024 collection \u1010\u103d\u1004\u103a\u1015\u102b\u1015\u102b\u1040\u1004\u103a\u101e\u1031\u102c elements \u1021\u101b\u1031\u1021\u1010\u103d\u1000\u103a\u101e\u100a\u103a {{ compared_value }} \u104f \u1005\u1010\u1030\u1016\u103c\u1005\u103a\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b ", "validators", "my");
t.add("This value should satisfy at least one of the following constraints:", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1021\u1031\u102c\u1000\u103a\u1015\u102b\u1000\u1014\u1037\u103a\u101e\u1010\u103a\u1001\u103b\u1000\u103a\u1019\u103b\u102c\u1038\u1021\u1014\u1000\u103a\u1019\u103e\u1021\u1014\u100a\u103a\u1038\u1006\u102f\u1036\u1038\u1010\u1005\u103a\u1001\u102f \u1016\u103c\u100a\u103a\u1037\u1006\u100a\u103a\u1038\u1015\u1031\u1038\u101e\u1004\u103a\u1037\u101e\u100a\u103a\u104b", "validators", "my");
t.add("Each element of this collection should satisfy its own set of constraints.", "\u1024 collection \u104f element \u1010\u1005\u103a\u1001\u102f\u1005\u102e\u101e\u100a\u103a\u104e\u1004\u103a\u1038\u104f\u1000\u102d\u102f\u101a\u103a\u1015\u102d\u102f\u1004\u103a\u1000\u1014\u1037\u103a\u101e\u1010\u103a\u1001\u103b\u1000\u103a\u1019\u103b\u102c\u1038\u1000\u102d\u102f\u1016\u103c\u100a\u1037\u103a\u1006\u100a\u103a\u1038\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a \u1021\u1015\u103c\u100a\u103a\u1015\u103c\u100a\u103a\u1006\u102d\u102f\u1004\u103a\u101b\u102c\u1004\u103d\u1031\u1001\u103b\u1031\u1038\u101e\u1000\u103a\u101e\u1031\u1001\u1036\u1014\u1036\u1015\u102b\u1010\u103a ,International Securities Identification Number (ISIN) \u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value should be a valid expression.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1005\u1000\u102c\u1038\u101b\u1015\u103a\u1016\u103c\u1005\u103a\u101e\u1004\u1037\u103a\u101e\u100a\u103a\u104b", "validators", "my");
t.add("This form should not contain extra fields.", "\u1024 \u1016\u1031\u102c\u1004\u103a\u101e\u100a\u103a field \u1021\u1015\u102d\u102f\u1019\u103b\u102c\u1038 \u1019\u1015\u102b\u1040\u1004\u103a\u101e\u1004\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Upload \u1010\u1004\u103a\u101e\u1031\u102c\u1016\u102d\u102f\u1004\u103a\u101e\u100a\u103a\u1021\u101c\u103d\u1014\u103a\u1000\u103c\u102e\u1038\u101c\u103d\u1014\u103a\u1038\u101e\u100a\u103a\u104b \u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u101e\u1031\u1038\u1004\u101a\u103a\u101e\u100a\u1037\u103a\u1016\u102d\u102f\u1004\u103a\u1000\u102d\u102f\u1010\u1004\u103a\u101b\u1014\u103a\u1000\u103c\u102d\u102f\u1038\u1005\u102c\u1038\u1015\u102b\u104b", "validators", "my");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "\u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a CSRF \u1010\u102d\u102f\u1000\u1004\u103a \u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b \u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d\u1016\u1031\u102c\u1004\u103a\u1000\u102d\u102f\u1015\u103c\u1014\u103a\u1010\u1004\u103a\u1015\u102b\u104b", "validators", "my");
t.add("This value is not a valid HTML5 color.", "\u1024\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a HTML5 \u1021\u101b\u1031\u102c\u1004\u103a\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("Please enter a valid birthdate.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1019\u103d\u1031\u1038\u1014\u1031\u1037\u1000\u102d\u102f\u1011\u100a\u1037\u103a\u1015\u102b\u104b", "validators", "my");
t.add("The selected choice is invalid.", "\u101e\u1004\u103a\u1037 \u101b\u103d\u1031\u1038\u1001\u103b\u101a\u103a\u1019\u103e\u102f\u101e\u100a\u103a\u1019\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u1015\u102b\u104b", "validators", "my");
t.add("The collection is invalid.", "\u1024 collection \u101e\u100a\u103a\u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a collection \u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("Please select a valid color.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1021\u101b\u1031\u102c\u1004\u103a\u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1015\u102b\u104b", "validators", "my");
t.add("Please select a valid country.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1014\u102d\u102f\u1004\u103a\u1004\u1036\u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1015\u102b\u104b", "validators", "my");
t.add("Please select a valid currency.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1004\u103d\u1031\u1000\u103c\u1031\u1038\u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1015\u102b\u104b", "validators", "my");
t.add("Please choose a valid date interval.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1014\u1031 \u101b\u1000\u103a\u1005\u103d\u1032\u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1015\u102b\u104b", "validators", "my");
t.add("Please enter a valid date and time.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1014\u1031 \u101b\u1000\u103a\u1005\u103d\u1032\u1014\u103e\u1004\u103a\u1037\u1021\u1001\u103b\u102d\u1014\u103a \u1000\u102d\u102f\u1011\u100a\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("Please enter a valid date.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1014\u1031 \u101b\u1000\u103a\u1005\u103d\u1032\u1000\u102d\u102f\u1011\u100a\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("Please select a valid file.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1014\u1031 \u1016\u102d\u102f\u1004\u103a\u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1001\u103b\u101a\u103a\u1015\u102b\u104b", "validators", "my");
t.add("The hidden field is invalid.", "\u1019\u101e\u1004\u103a\u1037 \u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a hidden field \u1016\u103c\u1005\u103a\u1014\u1031\u101e\u100a\u103a\u104b", "validators", "my");
t.add("Please enter an integer.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d Integer \u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u102c\u1011\u100a\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("Please select a valid language.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c \u1018\u102c\u101e\u102c\u1005\u1000\u102c\u1038\u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1001\u103b\u101a\u103a\u1015\u102b\u104b", "validators", "my");
t.add("Please select a valid locale.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c locale \u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1001\u103b\u101a\u103a\u1015\u102b\u104b", "validators", "my");
t.add("Please enter a valid money amount.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c \u1015\u102d\u102f\u1000\u103a\u1006\u1036\u1015\u1019\u102c\u100f \u1000\u102d\u102f\u1011\u100a\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("Please enter a number.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c \u1014\u1036\u1015\u102b\u1010\u103a \u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1001\u103b\u101a\u103a\u1015\u102b\u104b", "validators", "my");
t.add("The password is invalid.", "\u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1005\u1000\u102c\u1038\u1040\u103e\u1000\u103a\u1019\u101f\u102f\u1010\u103a\u1015\u102b\u104b", "validators", "my");
t.add("Please enter a percentage value.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u101b\u102c\u1001\u102d\u102f\u1004\u103a\u1014\u103e\u102f\u1014\u103a\u1038\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1011\u100a\u1037\u103a\u1015\u102b\u104b", "validators", "my");
t.add("The values do not match.", "\u1010\u1014\u103a\u1016\u102d\u102f\u1038\u1019\u103b\u102c\u1038\u1000\u102d\u102f\u1000\u103a\u100a\u102e\u1019\u103e\u102f\u1019\u101b\u103e\u102d\u1015\u102b\u104b", "validators", "my");
t.add("Please enter a valid time.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1021\u1001\u103b\u102d\u1014\u103a\u1000\u102d\u102f\u1011\u100a\u1037\u103a\u1015\u102b\u104b", "validators", "my");
t.add("Please select a valid timezone.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u1021\u1001\u103b\u102d\u1014\u103a\u1007\u102f\u1014\u103a\u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1015\u102b\u104b", "validators", "my");
t.add("Please enter a valid URL.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u101e\u1004\u103a\u1037\u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a URL \u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1015\u102b\u104b", "validators", "my");
t.add("Please enter a valid search term.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u101e\u1004\u103a\u1037 \u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a\u101b\u103e\u102c\u1016\u103d\u1031\u1019\u103e\u102f term \u1019\u103b\u102c\u1038\u1011\u100a\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("Please provide a valid phone number.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u101e\u1004\u103a\u1037 \u101c\u103b\u103e\u1031\u102c\u103a\u101e\u1031\u102c\u103a\u101b\u103e\u102c\u1016\u103d\u1031\u1019\u103e\u102f \u1016\u102f\u1014\u103a\u1038\u1014\u1036\u1015\u102b\u1010\u103a\u1011\u100a\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("The checkbox has an invalid value.", "Checkbox \u1010\u1014\u103a\u1016\u102d\u102f\u1038\u101e\u100a\u103a \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u1019\u103e\u102f\u1019\u101b\u103e\u102d\u1015\u102b\u104b", "validators", "my");
t.add("Please enter a valid email address.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u103a email \u101c\u102d\u1015\u103a\u1005\u102c\u1011\u100a\u103a\u1037\u1015\u102b\u104b", "validators", "my");
t.add("Please select a valid option.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u103a \u101b\u103d\u1031\u1038\u1001\u103b\u101a\u103a\u1019\u103e\u102f \u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1015\u102b\u104b", "validators", "my");
t.add("Please select a valid range.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c \u1021\u1015\u102d\u102f\u1004\u103a\u1038\u1021\u1001\u103c\u102c\u1038 \u1000\u102d\u102f\u101b\u103d\u1031\u1038\u1015\u102b\u104b", "validators", "my");
t.add("Please enter a valid week.", "\u1000\u103b\u1031\u1038\u1007\u1030\u1038\u1015\u103c\u102f\u104d \u1019\u103e\u1014\u103a\u1000\u1014\u103a\u101e\u1031\u102c\u101b\u1000\u103a\u101e\u1010\u1039\u1010\u1015\u1010\u103a\u1000\u102d\u102f\u1011\u100a\u1037\u103a\u1015\u102b\u104b", "validators", "my");
})(Translator);
