(function (t) {
// af
t.add("This value should be false.", "Hierdie waarde moet vals wees.", "validators", "af");
t.add("This value should be true.", "Hierdie waarde moet waar wees.", "validators", "af");
t.add("This value should be of type {{ type }}.", "Hierdie waarde moet van die soort {{type}} wees.", "validators", "af");
t.add("This value should be blank.", "Hierdie waarde moet leeg wees.", "validators", "af");
t.add("The value you selected is not a valid choice.", "Die waarde wat jy gekies het is nie 'n geldige keuse nie.", "validators", "af");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Jy moet ten minste {{ limit }} kies.|Jy moet ten minste {{ limit }} keuses kies.", "validators", "af");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Jy moet by die meeste {{ limit }} keuse kies.|Jy moet by die meeste {{ limit }} keuses kies.", "validators", "af");
t.add("One or more of the given values is invalid.", "Een of meer van die gegewe waardes is ongeldig.", "validators", "af");
t.add("This field was not expected.", "Die veld is nie verwag nie.", "validators", "af");
t.add("This field is missing.", "Hierdie veld ontbreek.", "validators", "af");
t.add("This value is not a valid date.", "Hierdie waarde is nie 'n geldige datum nie.", "validators", "af");
t.add("This value is not a valid datetime.", "Hierdie waarde is nie 'n geldige datum en tyd nie.", "validators", "af");
t.add("This value is not a valid email address.", "Hierdie waarde is nie 'n geldige e-pos adres nie.", "validators", "af");
t.add("The file could not be found.", "Die l\u00eaer kon nie gevind word nie.", "validators", "af");
t.add("The file is not readable.", "Die l\u00eaer kan nie gelees word nie.", "validators", "af");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Die l\u00eaer is te groot ({{ size }} {{ suffix }}). Toegelaat maksimum grootte is {{ limit }} {{ suffix }}.", "validators", "af");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Die MIME-tipe van die l\u00eaer is ongeldig ({{ type }}). Toegelaat MIME-tipes is {{ types }}.", "validators", "af");
t.add("This value should be {{ limit }} or less.", "Hierdie waarde moet {{ limit }} of minder wees.", "validators", "af");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Hierdie waarde is te lank. Dit moet {{ limit }} karakter of minder wees.|Hierdie waarde is te lank. Dit moet {{ limit }} karakters of minder wees.", "validators", "af");
t.add("This value should be {{ limit }} or more.", "Hierdie waarde moet {{ limit }} of meer wees.", "validators", "af");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Hierdie waarde is te kort. Dit moet {{ limit }} karakter of meer wees.|Hierdie waarde is te kort. Dit moet {{ limit }} karakters of meer wees.", "validators", "af");
t.add("This value should not be blank.", "Hierdie waarde moet nie leeg wees nie.", "validators", "af");
t.add("This value should not be null.", "Hierdie waarde moet nie nul wees nie.", "validators", "af");
t.add("This value should be null.", "Hierdie waarde moet nul wees.", "validators", "af");
t.add("This value is not valid.", "Hierdie waarde is nie geldig nie.", "validators", "af");
t.add("This value is not a valid time.", "Hierdie waarde is nie 'n geldige tyd nie.", "validators", "af");
t.add("This value is not a valid URL.", "Hierdie waarde is nie 'n geldige URL nie.", "validators", "af");
t.add("The two values should be equal.", "Die twee waardes moet gelyk wees.", "validators", "af");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Die l\u00eaer is te groot. Toegelaat maksimum grootte is {{ limit }} {{ suffix }}.", "validators", "af");
t.add("The file is too large.", "Die l\u00eaer is te groot.", "validators", "af");
t.add("The file could not be uploaded.", "Die l\u00eaer kan nie opgelaai word nie.", "validators", "af");
t.add("This value should be a valid number.", "Hierdie waarde moet 'n geldige nommer wees.", "validators", "af");
t.add("This file is not a valid image.", "Hierdie l\u00eaer is nie 'n geldige beeld nie.", "validators", "af");
t.add("This is not a valid IP address.", "Hierdie is nie 'n geldige IP-adres nie.", "validators", "af");
t.add("This value is not a valid language.", "Hierdie waarde is nie 'n geldige taal nie.", "validators", "af");
t.add("This value is not a valid locale.", "Hierdie waarde is nie 'n geldige land instelling nie.", "validators", "af");
t.add("This value is not a valid country.", "Hierdie waarde is nie 'n geldige land nie.", "validators", "af");
t.add("This value is already used.", "Hierdie waarde word reeds gebruik.", "validators", "af");
t.add("The size of the image could not be detected.", "Die grootte van die beeld kon nie opgespoor word nie.", "validators", "af");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "Die beeld breedte is te groot ({{ width }}px). Toegelaat maksimum breedte is {{ max_width }}px.", "validators", "af");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "Die beeld breedte is te klein ({{ width }}px). Minimum breedte verwag is {{ min_width }}px.", "validators", "af");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "Die beeld hoogte is te groot ({{ height }}px). Toegelaat maksimum hoogte is {{ max_height }}px.", "validators", "af");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "Die beeld hoogte is te klein ({{ height }}px). Minimum hoogte verwag is {{ min_height }}px.", "validators", "af");
t.add("This value should be the user's current password.", "Hierdie waarde moet die huidige wagwoord van die gebruiker wees.", "validators", "af");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Hierdie waarde moet presies {{ limit }} karakter wees.|Hierdie waarde moet presies {{ limit }} karakters wees.", "validators", "af");
t.add("The file was only partially uploaded.", "Die l\u00eaer is slegs gedeeltelik opgelaai.", "validators", "af");
t.add("No file was uploaded.", "Geen l\u00eaer is opgelaai nie.", "validators", "af");
t.add("No temporary folder was configured in php.ini.", "Geen tydelike l\u00eaer is ingestel in php.ini nie.", "validators", "af");
t.add("Cannot write temporary file to disk.", "Kan nie tydelike l\u00eaer skryf op skyf nie.", "validators", "af");
t.add("A PHP extension caused the upload to fail.", "'n PHP-uitbreiding veroorsaak die oplaai van die l\u00eaer om te misluk.", "validators", "af");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Hierdie versameling moet {{ limit }} element of meer bevat.|Hierdie versameling moet {{ limit }} elemente of meer bevat.", "validators", "af");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Hierdie versameling moet {{ limit }} element of minder bevat.|Hierdie versameling moet {{ limit }} elemente of meer bevat.", "validators", "af");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Hierdie versameling moet presies {{ limit }} element bevat.|Hierdie versameling moet presies {{ limit }} elemente bevat.", "validators", "af");
t.add("Invalid card number.", "Ongeldige kredietkaart nommer.", "validators", "af");
t.add("Unsupported card type or invalid card number.", "Nie-ondersteunde tipe kaart of ongeldige kredietkaart nommer.", "validators", "af");
t.add("This is not a valid International Bank Account Number (IBAN).", "Hierdie is nie 'n geldige Internationale Bank Rekening Nommer (IBAN) nie.", "validators", "af");
t.add("This value is not a valid ISBN-10.", "Hierdie waarde is nie 'n geldige ISBN-10 nie.", "validators", "af");
t.add("This value is not a valid ISBN-13.", "Hierdie waarde is nie 'n geldige ISBN-13 nie.", "validators", "af");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Hierdie waarde is nie 'n geldige ISBN-10 of ISBN-13 nie.", "validators", "af");
t.add("This value is not a valid ISSN.", "Hierdie waarde is nie 'n geldige ISSN nie.", "validators", "af");
t.add("This value is not a valid currency.", "Hierdie waarde is nie 'n geldige geldeenheid nie.", "validators", "af");
t.add("This value should be equal to {{ compared_value }}.", "Hierdie waarde moet gelyk aan {{ compared_value }} wees.", "validators", "af");
t.add("This value should be greater than {{ compared_value }}.", "Hierdie waarde moet meer as {{ compared_value }} wees.", "validators", "af");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Hierdie waarde moet meer of gelyk aan {{ compared_value }} wees.", "validators", "af");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Hierdie waarde moet identies aan {{ compared_value_type }} {{ compared_value }} wees.", "validators", "af");
t.add("This value should be less than {{ compared_value }}.", "Hierdie waarde moet minder as {{ compared_value }} wees.", "validators", "af");
t.add("This value should be less than or equal to {{ compared_value }}.", "Hierdie waarde moet minder of gelyk aan {{ compared_value }} wees.", "validators", "af");
t.add("This value should not be equal to {{ compared_value }}.", "Hierdie waarde moet nie dieselfde as {{ compared_value }} wees nie.", "validators", "af");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Hierdie waarde moet nie identies as {{ compared_value_type }} {{ compared_value }} wees nie.", "validators", "af");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "Die beeld aspek is te groot ({{ ratio }}). Die maksimum toegelate aspek is {{ max_ratio }}.", "validators", "af");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "Die beeld aspek is te klein ({{ ratio }}). Die minimum toegelate aspek is {{ min_ratio }}.", "validators", "af");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "Die beeld is vierkantig ({{ width }}x{{ height }}px). Vierkantige beelde word nie toegelaat nie.", "validators", "af");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "Die beeld is landskap geori\u00ebnteerd ({{ width }}x{{ height }}px). Landskap geori\u00ebnteerde beelde word nie toegelaat nie.", "validators", "af");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "Die beeld dis portret geori\u00ebnteerd ({{ width }}x{{ height }}px). Portret geori\u00ebnteerde beelde word nie toegelaat nie.", "validators", "af");
t.add("An empty file is not allowed.", "'n Le\u00eb l\u00eaer word nie toegelaat nie.", "validators", "af");
t.add("The host could not be resolved.", "Die gasheer kon nie opgelos word nie.", "validators", "af");
t.add("This value does not match the expected {{ charset }} charset.", "Die waarde stem nie ooreen met die verwagte {{ charset }} karakterstel nie.", "validators", "af");
t.add("This is not a valid Business Identifier Code (BIC).", "Hierdie is nie 'n geldige Besigheids Identifikasie Kode (BIC) nie.", "validators", "af");
t.add("Error", "Fout", "validators", "af");
t.add("This is not a valid UUID.", "Hierdie is nie 'n geldige UUID nie.", "validators", "af");
t.add("This value should be a multiple of {{ compared_value }}.", "Hierdie waarde moet 'n veelvoud van {{ compared_value }} wees.", "validators", "af");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Hierdie Besigheids Identifikasie Kode (BIK) is nie geassosieer met IBAN {{ iban }} nie.", "validators", "af");
t.add("This value should be valid JSON.", "Hierdie waarde moet geldige JSON wees.", "validators", "af");
t.add("This collection should contain only unique elements.", "Hierdie versameling moet net unieke elemente bevat.", "validators", "af");
t.add("This value should be positive.", "Hierdie waarde moet positief wees.", "validators", "af");
t.add("This value should be either positive or zero.", "Hierdie waarde moet positief of nul wees.", "validators", "af");
t.add("This value should be negative.", "Hierdie waarde moet negatief wees.", "validators", "af");
t.add("This value should be either negative or zero.", "Hierdie waarde moet negatief of nul wees.", "validators", "af");
t.add("This value is not a valid timezone.", "Hierdie waarde is nie 'n geldige tydsone nie.", "validators", "af");
t.add("This value should be between {{ min }} and {{ max }}.", "Hierdie waarde moet tussen {{ min }} en {{ max }} wees.", "validators", "af");
t.add("This value is not a valid hostname.", "Hierdie waarde is nie 'n geldige gasheernaam nie.", "validators", "af");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Die hoeveelheid elemente in hierdie versameling moet 'n meelvoud van {{ compared_value }} wees.", "validators", "af");
t.add("This value should satisfy at least one of the following constraints:", "Hierdie waarde moet voldoen aan ten minste een van hierdie beperkings:", "validators", "af");
t.add("Each element of this collection should satisfy its own set of constraints.", "Elke element van hierdie versameling moet voldoen aan hulle eie beperkings.", "validators", "af");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Hierdie waarde is nie 'n geldige Internasionale veiligheidsidentifikasienommer (ISIN) nie.", "validators", "af");
t.add("This form should not contain extra fields.", "Hierdie vorm moet nie ekstra velde bevat nie.", "validators", "af");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Die opgelaaide l\u00eaer was te groot. Probeer asseblief 'n kleiner l\u00eaer.", "validators", "af");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "Die CSRF-teken is ongeldig. Probeer asseblief om die vorm weer in te dien.", "validators", "af");
t.add("This value is not a valid HTML5 color.", "Hierdie waarde is nie 'n geldige HTML5 kleur nie.", "validators", "af");
t.add("Please enter a valid birthdate.", "Voer asseblief 'n geldige geboortedatum in.", "validators", "af");
t.add("The selected choice is invalid.", "Die gekiesde opsie is nie geldig nie.", "validators", "af");
t.add("The collection is invalid.", "Die versameling is nie geldig nie.", "validators", "af");
t.add("Please select a valid color.", "Kies asseblief 'n geldige kleur.", "validators", "af");
t.add("Please select a valid country.", "Kies asseblief 'n geldige land.", "validators", "af");
t.add("Please select a valid currency.", "Kies asseblief 'n geldige geldeenheid.", "validators", "af");
t.add("Please choose a valid date interval.", "Kies asseblief 'n geldige datum interval.", "validators", "af");
t.add("Please enter a valid date and time.", "Voer asseblilef 'n geldige datum en tyd in.", "validators", "af");
t.add("Please enter a valid date.", "Voer asseblief 'n geldige datum in.", "validators", "af");
t.add("Please select a valid file.", "Kies asseblief 'n geldige l\u00eaer.", "validators", "af");
t.add("The hidden field is invalid.", "Die versteekte veld is nie geldig nie.", "validators", "af");
t.add("Please enter an integer.", "Voer asseblief 'n geldige heeltal in.", "validators", "af");
t.add("Please select a valid language.", "Kies assblief 'n geldige taal.", "validators", "af");
t.add("Please select a valid locale.", "Voer assebliefn 'n geldige locale in.", "validators", "af");
t.add("Please enter a valid money amount.", "Voer asseblief 'n geldige bedrag in.", "validators", "af");
t.add("Please enter a number.", "Voer asseblief 'n nommer in.", "validators", "af");
t.add("The password is invalid.", "Die wagwoord is ongeldig.", "validators", "af");
t.add("Please enter a percentage value.", "Voer asseblief 'n geldige persentasie waarde in.", "validators", "af");
t.add("The values do not match.", "Die waardes is nie dieselfde nie.", "validators", "af");
t.add("Please enter a valid time.", "Voer asseblief 'n geldige tyd in time.", "validators", "af");
t.add("Please select a valid timezone.", "Kies asseblief 'n geldige tydsone.", "validators", "af");
t.add("Please enter a valid URL.", "Voer asseblief 'n geldige URL in.", "validators", "af");
t.add("Please enter a valid search term.", "Voer asseblief 'n geldige soek term in.", "validators", "af");
t.add("Please provide a valid phone number.", "Verskaf asseblief 'n geldige telefoonnommer.", "validators", "af");
t.add("The checkbox has an invalid value.", "Die blokkie het 'n ongeldige waarde.", "validators", "af");
t.add("Please enter a valid email address.", "Voer asseblief 'n geldige e-pos adres in.", "validators", "af");
t.add("Please select a valid option.", "Kies asseblief 'n geldige opsie.", "validators", "af");
t.add("Please select a valid range.", "Kies asseblief 'n geldige reeks.", "validators", "af");
t.add("Please enter a valid week.", "Voer assblief 'n geldige week in.", "validators", "af");
})(Translator);
