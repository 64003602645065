(function (t) {
// zh_CN
t.add("This value should be false.", "\u8be5\u53d8\u91cf\u7684\u503c\u5e94\u4e3a false \u3002", "validators", "zh_CN");
t.add("This value should be true.", "\u8be5\u53d8\u91cf\u7684\u503c\u5e94\u4e3a true \u3002", "validators", "zh_CN");
t.add("This value should be of type {{ type }}.", "\u8be5\u53d8\u91cf\u7684\u7c7b\u578b\u5e94\u4e3a {{ type }} \u3002", "validators", "zh_CN");
t.add("This value should be blank.", "\u8be5\u53d8\u91cf\u503c\u5e94\u4e3a\u7a7a\u3002", "validators", "zh_CN");
t.add("The value you selected is not a valid choice.", "\u9009\u5b9a\u53d8\u91cf\u7684\u503c\u4e0d\u662f\u6709\u6548\u7684\u9009\u9879\u3002", "validators", "zh_CN");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u60a8\u81f3\u5c11\u8981\u9009\u62e9 {{ limit }} \u4e2a\u9009\u9879\u3002", "validators", "zh_CN");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u60a8\u6700\u591a\u80fd\u9009\u62e9 {{ limit }} \u4e2a\u9009\u9879\u3002", "validators", "zh_CN");
t.add("One or more of the given values is invalid.", "\u4e00\u4e2a\u6216\u8005\u591a\u4e2a\u7ed9\u5b9a\u7684\u503c\u65e0\u6548\u3002", "validators", "zh_CN");
t.add("This field was not expected.", "\u6b64\u5b57\u6bb5\u662f\u591a\u4f59\u7684\u3002", "validators", "zh_CN");
t.add("This field is missing.", "\u6b64\u5b57\u6bb5\u7f3a\u5931\u3002", "validators", "zh_CN");
t.add("This value is not a valid date.", "\u8be5\u503c\u4e0d\u662f\u4e00\u4e2a\u6709\u6548\u7684\u65e5\u671f\uff08date\uff09\u3002", "validators", "zh_CN");
t.add("This value is not a valid datetime.", "\u8be5\u503c\u4e0d\u662f\u4e00\u4e2a\u6709\u6548\u7684\u65e5\u671f\u65f6\u95f4\uff08datetime\uff09\u3002", "validators", "zh_CN");
t.add("This value is not a valid email address.", "\u8be5\u503c\u4e0d\u662f\u4e00\u4e2a\u6709\u6548\u7684\u90ae\u4ef6\u5730\u5740\u3002", "validators", "zh_CN");
t.add("The file could not be found.", "\u6587\u4ef6\u672a\u627e\u5230\u3002", "validators", "zh_CN");
t.add("The file is not readable.", "\u6587\u4ef6\u4e0d\u53ef\u8bfb\u3002", "validators", "zh_CN");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u6587\u4ef6\u592a\u5927 ({{ size }} {{ suffix }})\u3002\u6587\u4ef6\u5927\u5c0f\u4e0d\u53ef\u4ee5\u8d85\u8fc7 {{ limit }} {{ suffix }} \u3002", "validators", "zh_CN");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "\u65e0\u6548\u7684\u6587\u4ef6\u7c7b\u578b ({{ type }}) \u3002\u5141\u8bb8\u7684\u6587\u4ef6\u7c7b\u578b\u6709 {{ types }} \u3002", "validators", "zh_CN");
t.add("This value should be {{ limit }} or less.", "\u8fd9\u4e2a\u53d8\u91cf\u7684\u503c\u5e94\u8be5\u5c0f\u4e8e\u6216\u7b49\u4e8e {{ limit }}\u3002", "validators", "zh_CN");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u5b57\u7b26\u4e32\u592a\u957f\uff0c\u957f\u5ea6\u4e0d\u53ef\u8d85\u8fc7 {{ limit }} \u4e2a\u5b57\u7b26\u3002", "validators", "zh_CN");
t.add("This value should be {{ limit }} or more.", "\u8be5\u53d8\u91cf\u7684\u503c\u5e94\u8be5\u5927\u4e8e\u6216\u7b49\u4e8e {{ limit }}\u3002", "validators", "zh_CN");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u5b57\u7b26\u4e32\u592a\u77ed\uff0c\u957f\u5ea6\u4e0d\u53ef\u5c11\u4e8e {{ limit }} \u4e2a\u5b57\u7b26\u3002", "validators", "zh_CN");
t.add("This value should not be blank.", "\u8be5\u53d8\u91cf\u4e0d\u5e94\u4e3a\u7a7a\u3002", "validators", "zh_CN");
t.add("This value should not be null.", "\u8be5\u53d8\u91cf\u4e0d\u5e94\u4e3a null \u3002", "validators", "zh_CN");
t.add("This value should be null.", "\u8be5\u53d8\u91cf\u5e94\u4e3a\u7a7a null \u3002", "validators", "zh_CN");
t.add("This value is not valid.", "\u8be5\u53d8\u91cf\u503c\u65e0\u6548 \u3002", "validators", "zh_CN");
t.add("This value is not a valid time.", "\u8be5\u503c\u4e0d\u662f\u4e00\u4e2a\u6709\u6548\u7684\u65f6\u95f4\u3002", "validators", "zh_CN");
t.add("This value is not a valid URL.", "\u8be5\u503c\u4e0d\u662f\u4e00\u4e2a\u6709\u6548\u7684 URL \u3002", "validators", "zh_CN");
t.add("The two values should be equal.", "\u8fd9\u4e24\u4e2a\u53d8\u91cf\u7684\u503c\u5e94\u8be5\u76f8\u7b49\u3002", "validators", "zh_CN");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u6587\u4ef6\u592a\u5927\uff0c\u6587\u4ef6\u5927\u5c0f\u4e0d\u53ef\u4ee5\u8d85\u8fc7 {{ limit }} {{ suffix }}\u3002 ", "validators", "zh_CN");
t.add("The file is too large.", "\u6587\u4ef6\u592a\u5927\u3002", "validators", "zh_CN");
t.add("The file could not be uploaded.", "\u65e0\u6cd5\u4e0a\u4f20\u6b64\u6587\u4ef6\u3002", "validators", "zh_CN");
t.add("This value should be a valid number.", "\u8be5\u503c\u5e94\u8be5\u4e3a\u6709\u6548\u7684\u6570\u5b57\u3002", "validators", "zh_CN");
t.add("This file is not a valid image.", "\u8be5\u6587\u4ef6\u4e0d\u662f\u6709\u6548\u7684\u56fe\u7247\u3002", "validators", "zh_CN");
t.add("This is not a valid IP address.", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u7684IP\u5730\u5740\u3002", "validators", "zh_CN");
t.add("This value is not a valid language.", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u7684\u8bed\u8a00\u540d\u3002", "validators", "zh_CN");
t.add("This value is not a valid locale.", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u7684\u533a\u57df\u503c\uff08locale\uff09\u3002", "validators", "zh_CN");
t.add("This value is not a valid country.", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u7684\u56fd\u5bb6\u540d\u3002", "validators", "zh_CN");
t.add("This value is already used.", "\u8be5\u503c\u5df2\u7ecf\u88ab\u4f7f\u7528\u3002", "validators", "zh_CN");
t.add("The size of the image could not be detected.", "\u4e0d\u80fd\u89e3\u6790\u56fe\u7247\u5927\u5c0f\u3002", "validators", "zh_CN");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u56fe\u7247\u592a\u5bbd ({{ width }}px)\uff0c\u6700\u5927\u5bbd\u5ea6\u4e3a {{ max_width }}px \u3002", "validators", "zh_CN");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u56fe\u7247\u5bbd\u5ea6\u4e0d\u591f ({{ width }}px)\uff0c\u6700\u5c0f\u5bbd\u5ea6\u4e3a {{ min_width }}px \u3002", "validators", "zh_CN");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u56fe\u7247\u592a\u9ad8 ({{ height }}px)\uff0c\u6700\u5927\u9ad8\u5ea6\u4e3a {{ max_height }}px \u3002", "validators", "zh_CN");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u56fe\u7247\u9ad8\u5ea6\u4e0d\u591f ({{ height }}px)\uff0c\u6700\u5c0f\u9ad8\u5ea6\u4e3a {{ min_height }}px \u3002", "validators", "zh_CN");
t.add("This value should be the user's current password.", "\u8be5\u53d8\u91cf\u7684\u503c\u5e94\u4e3a\u7528\u6237\u5f53\u524d\u7684\u5bc6\u7801\u3002", "validators", "zh_CN");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u8be5\u53d8\u91cf\u5e94\u4e3a {{ limit }} \u4e2a\u5b57\u7b26\u3002", "validators", "zh_CN");
t.add("The file was only partially uploaded.", "\u8be5\u6587\u4ef6\u7684\u4e0a\u4f20\u4e0d\u5b8c\u6574\u3002", "validators", "zh_CN");
t.add("No file was uploaded.", "\u6ca1\u6709\u4e0a\u4f20\u4efb\u4f55\u6587\u4ef6\u3002", "validators", "zh_CN");
t.add("No temporary folder was configured in php.ini.", "php.ini \u91cc\u6ca1\u6709\u914d\u7f6e\u4e34\u65f6\u6587\u4ef6\u76ee\u5f55\u3002", "validators", "zh_CN");
t.add("Cannot write temporary file to disk.", "\u4e34\u65f6\u6587\u4ef6\u5199\u5165\u78c1\u76d8\u5931\u8d25\u3002", "validators", "zh_CN");
t.add("A PHP extension caused the upload to fail.", "\u67d0\u4e2a PHP \u6269\u5c55\u9020\u6210\u4e0a\u4f20\u5931\u8d25\u3002", "validators", "zh_CN");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u8be5\u96c6\u5408\u6700\u5c11\u5e94\u5305\u542b {{ limit }} \u4e2a\u5143\u7d20\u3002", "validators", "zh_CN");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u8be5\u96c6\u5408\u6700\u591a\u5305\u542b {{ limit }} \u4e2a\u5143\u7d20\u3002", "validators", "zh_CN");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u8be5\u96c6\u5408\u5e94\u5305\u542b {{ limit }} \u4e2a\u5143\u7d20 element \u3002", "validators", "zh_CN");
t.add("Invalid card number.", "\u65e0\u6548\u7684\u4fe1\u7528\u5361\u53f7\u3002", "validators", "zh_CN");
t.add("Unsupported card type or invalid card number.", "\u4e0d\u652f\u6301\u7684\u4fe1\u7528\u5361\u7c7b\u578b\u6216\u65e0\u6548\u7684\u4fe1\u7528\u5361\u53f7\u3002", "validators", "zh_CN");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u7684\u56fd\u9645\u94f6\u884c\u5e10\u53f7\uff08IBAN\uff09\u3002", "validators", "zh_CN");
t.add("This value is not a valid ISBN-10.", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u768410\u4f4d\u56fd\u9645\u6807\u51c6\u4e66\u53f7\uff08ISBN-10\uff09\u3002", "validators", "zh_CN");
t.add("This value is not a valid ISBN-13.", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u768413\u4f4d\u56fd\u9645\u6807\u51c6\u4e66\u53f7\uff08ISBN-13\uff09\u3002", "validators", "zh_CN");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u7684\u56fd\u9645\u6807\u51c6\u4e66\u53f7\uff08ISBN-10 \u6216 ISBN-13\uff09\u3002", "validators", "zh_CN");
t.add("This value is not a valid ISSN.", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u7684\u56fd\u9645\u6807\u51c6\u671f\u520a\u53f7\uff08ISSN\uff09\u3002", "validators", "zh_CN");
t.add("This value is not a valid currency.", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u7684\u8d27\u5e01\u540d\uff08currency\uff09\u3002", "validators", "zh_CN");
t.add("This value should be equal to {{ compared_value }}.", "\u8be5\u503c\u5e94\u7b49\u4e8e {{ compared_value }} \u3002", "validators", "zh_CN");
t.add("This value should be greater than {{ compared_value }}.", "\u8be5\u503c\u5e94\u5927\u4e8e {{ compared_value }} \u3002", "validators", "zh_CN");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u8be5\u503c\u5e94\u5927\u4e8e\u6216\u7b49\u4e8e {{ compared_value }} \u3002", "validators", "zh_CN");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u8be5\u503c\u5e94\u4e0e {{ compared_value_type }} {{ compared_value }} \u76f8\u540c\u3002", "validators", "zh_CN");
t.add("This value should be less than {{ compared_value }}.", "\u8be5\u503c\u5e94\u5c0f\u4e8e {{ compared_value }} \u3002", "validators", "zh_CN");
t.add("This value should be less than or equal to {{ compared_value }}.", "\u8be5\u503c\u5e94\u5c0f\u4e8e\u6216\u7b49\u4e8e {{ compared_value }} \u3002", "validators", "zh_CN");
t.add("This value should not be equal to {{ compared_value }}.", "\u8be5\u503c\u4e0d\u5e94\u5148\u7b49\u4e8e {{ compared_value }} \u3002", "validators", "zh_CN");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u8be5\u503c\u4e0d\u5e94\u4e0e {{ compared_value_type }} {{ compared_value }} \u76f8\u540c\u3002", "validators", "zh_CN");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u56fe\u7247\u5bbd\u9ad8\u6bd4\u592a\u5927 ({{ ratio }})\u3002\u5141\u8bb8\u7684\u6700\u5927\u5bbd\u9ad8\u6bd4\u4e3a {{ max_ratio }}\u3002", "validators", "zh_CN");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u56fe\u7247\u5bbd\u9ad8\u6bd4\u592a\u5c0f ({{ ratio }})\u3002\u5141\u8bb8\u7684\u6700\u5927\u5bbd\u9ad8\u6bd4\u4e3a {{ min_ratio }}\u3002", "validators", "zh_CN");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u56fe\u7247\u662f\u65b9\u5f62\u7684 ({{ width }}x{{ height }}px)\u3002\u4e0d\u5141\u8bb8\u4f7f\u7528\u65b9\u5f62\u7684\u56fe\u7247\u3002", "validators", "zh_CN");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u56fe\u7247\u662f\u6a2a\u5411\u7684 ({{ width }}x{{ height }}px)\u3002\u4e0d\u5141\u8bb8\u4f7f\u7528\u6a2a\u5411\u7684\u56fe\u7247\u3002", "validators", "zh_CN");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u56fe\u7247\u662f\u7eb5\u5411\u7684 ({{ width }}x{{ height }}px)\u3002\u4e0d\u5141\u8bb8\u4f7f\u7528\u7eb5\u5411\u7684\u56fe\u7247\u3002", "validators", "zh_CN");
t.add("An empty file is not allowed.", "\u4e0d\u5141\u8bb8\u4f7f\u7528\u7a7a\u6587\u4ef6\u3002", "validators", "zh_CN");
t.add("The host could not be resolved.", "\u4e3b\u673a\u540d\u65e0\u6cd5\u89e3\u6790\u3002", "validators", "zh_CN");
t.add("This value does not match the expected {{ charset }} charset.", "\u8be5\u503c\u4e0d\u7b26\u5408 {{ charset }} \u7f16\u7801\u3002", "validators", "zh_CN");
t.add("This is not a valid Business Identifier Code (BIC).", "\u8fd9\u4e0d\u662f\u6709\u6548\u7684\u4e1a\u52a1\u6807\u8bc6\u7b26\u4ee3\u7801\uff08BIC)\u3002", "validators", "zh_CN");
t.add("Error", "\u9519\u8bef", "validators", "zh_CN");
t.add("This is not a valid UUID.", "\u8fd9\u4e0d\u662f\u6709\u6548\u7684UUID\u3002", "validators", "zh_CN");
t.add("This value should be a multiple of {{ compared_value }}.", "\u6b64\u503c\u5e94\u4e3a {{ compared_value }} \u7684\u500d\u6570\u3002", "validators", "zh_CN");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "\u6b64\u4e1a\u52a1\u6807\u8bc6\u7b26\u4ee3\u7801\uff08BIC\uff09\u4e0eIBAN {{ iban }} \u65e0\u5173\u3002", "validators", "zh_CN");
t.add("This value should be valid JSON.", "\u8be5\u503c\u5e94\u8be5\u662f\u6709\u6548\u7684JSON\u3002", "validators", "zh_CN");
t.add("This collection should contain only unique elements.", "\u8be5\u96c6\u5408\u5e94\u4ec5\u5305\u542b\u72ec\u4e00\u65e0\u4e8c\u7684\u5143\u7d20\u3002", "validators", "zh_CN");
t.add("This value should be positive.", "\u6570\u503c\u5e94\u4e3a\u6b63\u6570\u3002", "validators", "zh_CN");
t.add("This value should be either positive or zero.", "\u6570\u503c\u5e94\u662f\u6b63\u6570\uff0c\u6216\u4e3a\u96f6\u3002", "validators", "zh_CN");
t.add("This value should be negative.", "\u6570\u503c\u5e94\u4e3a\u8d1f\u6570\u3002", "validators", "zh_CN");
t.add("This value should be either negative or zero.", "\u6570\u503c\u5e94\u662f\u8d1f\u6570\uff0c\u6216\u4e3a\u96f6\u3002", "validators", "zh_CN");
t.add("This value is not a valid timezone.", "\u65e0\u6548\u65f6\u533a\u3002", "validators", "zh_CN");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "\u6b64\u5bc6\u7801\u5df2\u88ab\u6cc4\u9732\uff0c\u5207\u52ff\u4f7f\u7528\u3002\u8bf7\u66f4\u6362\u5bc6\u7801\u3002", "validators", "zh_CN");
t.add("This value should be between {{ min }} and {{ max }}.", "\u8be5\u6570\u503c\u5e94\u5728 {{ min }} \u548c {{ max }} \u4e4b\u95f4\u3002", "validators", "zh_CN");
t.add("This value is not a valid hostname.", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u7684\u4e3b\u673a\u540d\u79f0\u3002", "validators", "zh_CN");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "\u8be5\u96c6\u5408\u5185\u7684\u5143\u7d20\u6570\u91cf\u5f97\u662f {{ compared_value }} \u7684\u500d\u6570\u3002", "validators", "zh_CN");
t.add("This value should satisfy at least one of the following constraints:", "\u8be5\u503c\u9700\u7b26\u5408\u4ee5\u4e0b\u5176\u4e2d\u4e00\u4e2a\u7ea6\u675f\uff1a", "validators", "zh_CN");
t.add("Each element of this collection should satisfy its own set of constraints.", "\u8be5\u96c6\u5408\u5185\u7684\u6bcf\u4e2a\u5143\u7d20\u9700\u7b26\u5408\u5143\u7d20\u672c\u8eab\u89c4\u5b9a\u7684\u7ea6\u675f\u3002", "validators", "zh_CN");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u7684\u56fd\u9645\u8bc1\u5238\u8bc6\u522b\u7801 \uff08ISIN\uff09\u3002", "validators", "zh_CN");
t.add("This value should be a valid expression.", "\u8be5\u503c\u9700\u4e3a\u4e00\u4e2a\u6709\u6548\u7684\u8868\u8fbe\u5f0f\u3002", "validators", "zh_CN");
t.add("This value is not a valid CSS color.", "\u8be5\u503c\u4e0d\u662f\u6709\u6548\u7684CSS\u989c\u8272\u3002", "validators", "zh_CN");
t.add("This value is not a valid CIDR notation.", "\u8be5\u503c\u4e0d\u662f\u4e00\u4e2a\u6709\u6548\u7684CIDR\u8868\u793a\u3002", "validators", "zh_CN");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "\u7f51\u7edc\u63a9\u7801\u7684\u503c\u5e94\u5f53\u5728 {{ min }} \u548c {{ max }} \u4e4b\u95f4\u3002", "validators", "zh_CN");
t.add("This form should not contain extra fields.", "\u8be5\u8868\u5355\u4e2d\u4e0d\u53ef\u6709\u989d\u5916\u5b57\u6bb5.", "validators", "zh_CN");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "\u4e0a\u4f20\u6587\u4ef6\u592a\u5927\uff0c \u8bf7\u91cd\u65b0\u5c1d\u8bd5\u4e0a\u4f20\u4e00\u4e2a\u8f83\u5c0f\u7684\u6587\u4ef6.", "validators", "zh_CN");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "CSRF \u9a8c\u8bc1\u7b26\u65e0\u6548\uff0c \u8bf7\u91cd\u65b0\u63d0\u4ea4.", "validators", "zh_CN");
t.add("This value is not a valid HTML5 color.", "\u8be5\u6570\u503c\u4e0d\u662f\u4e2a\u6709\u6548\u7684 HTML5 \u989c\u8272\u3002", "validators", "zh_CN");
t.add("Please enter a valid birthdate.", "\u8bf7\u8f93\u5165\u6709\u6548\u7684\u751f\u65e5\u65e5\u671f\u3002", "validators", "zh_CN");
t.add("The selected choice is invalid.", "\u6240\u9009\u7684\u9009\u9879\u65e0\u6548\u3002", "validators", "zh_CN");
t.add("The collection is invalid.", "\u96c6\u5408\u65e0\u6548\u3002", "validators", "zh_CN");
t.add("Please select a valid color.", "\u8bf7\u9009\u62e9\u6709\u6548\u7684\u989c\u8272\u3002", "validators", "zh_CN");
t.add("Please select a valid country.", "\u8bf7\u9009\u62e9\u6709\u6548\u7684\u56fd\u5bb6\u3002", "validators", "zh_CN");
t.add("Please select a valid currency.", "\u8bf7\u9009\u62e9\u6709\u6548\u7684\u8d27\u5e01\u3002", "validators", "zh_CN");
t.add("Please choose a valid date interval.", "\u8bf7\u9009\u62e9\u6709\u6548\u7684\u65e5\u671f\u95f4\u9694\u3002", "validators", "zh_CN");
t.add("Please enter a valid date and time.", "\u8bf7\u8f93\u5165\u6709\u6548\u7684\u65e5\u671f\u4e0e\u65f6\u95f4\u3002", "validators", "zh_CN");
t.add("Please enter a valid date.", "\u8bf7\u8f93\u5165\u6709\u6548\u7684\u65e5\u671f\u3002", "validators", "zh_CN");
t.add("Please select a valid file.", "\u8bf7\u9009\u62e9\u6709\u6548\u7684\u6587\u4ef6\u3002", "validators", "zh_CN");
t.add("The hidden field is invalid.", "\u9690\u85cf\u5b57\u6bb5\u65e0\u6548\u3002", "validators", "zh_CN");
t.add("Please enter an integer.", "\u8bf7\u8f93\u5165\u6574\u6570\u3002", "validators", "zh_CN");
t.add("Please select a valid language.", "\u8bf7\u9009\u62e9\u6709\u6548\u7684\u8bed\u8a00\u3002", "validators", "zh_CN");
t.add("Please select a valid locale.", "\u8bf7\u9009\u62e9\u6709\u6548\u7684\u8bed\u8a00\u73af\u5883\u3002", "validators", "zh_CN");
t.add("Please enter a valid money amount.", "\u8bf7\u8f93\u5165\u6b63\u786e\u7684\u91d1\u989d\u3002", "validators", "zh_CN");
t.add("Please enter a number.", "\u8bf7\u8f93\u5165\u6570\u5b57\u3002", "validators", "zh_CN");
t.add("The password is invalid.", "\u5bc6\u7801\u65e0\u6548\u3002", "validators", "zh_CN");
t.add("Please enter a percentage value.", "\u8bf7\u8f93\u5165\u767e\u5206\u6bd4\u503c\u3002", "validators", "zh_CN");
t.add("The values do not match.", "\u6570\u503c\u4e0d\u5339\u914d\u3002", "validators", "zh_CN");
t.add("Please enter a valid time.", "\u8bf7\u8f93\u5165\u6709\u6548\u7684\u65f6\u95f4\u3002", "validators", "zh_CN");
t.add("Please select a valid timezone.", "\u8bf7\u9009\u62e9\u6709\u6548\u7684\u65f6\u533a\u3002", "validators", "zh_CN");
t.add("Please enter a valid URL.", "\u8bf7\u8f93\u5165\u6709\u6548\u7684\u7f51\u5740\u3002", "validators", "zh_CN");
t.add("Please enter a valid search term.", "\u8bf7\u8f93\u5165\u6709\u6548\u7684\u641c\u7d22\u8bcd\u3002", "validators", "zh_CN");
t.add("Please provide a valid phone number.", "\u8bf7\u63d0\u4f9b\u6709\u6548\u7684\u624b\u673a\u53f7\u7801\u3002", "validators", "zh_CN");
t.add("The checkbox has an invalid value.", "\u65e0\u6548\u7684\u9009\u6846\u503c\u3002", "validators", "zh_CN");
t.add("Please enter a valid email address.", "\u8bf7\u8f93\u5165\u6709\u6548\u7684\u7535\u5b50\u90ae\u4ef6\u5730\u5740\u3002", "validators", "zh_CN");
t.add("Please select a valid option.", "\u8bf7\u9009\u62e9\u6709\u6548\u7684\u9009\u9879\u3002", "validators", "zh_CN");
t.add("Please select a valid range.", "\u8bf7\u9009\u62e9\u6709\u6548\u7684\u8303\u56f4\u3002", "validators", "zh_CN");
t.add("Please enter a valid week.", "\u8bf7\u8f93\u5165\u6709\u6548\u7684\u661f\u671f\u3002", "validators", "zh_CN");
})(Translator);
