(function (t) {
// nl
t.add("This value should be false.", "Deze waarde moet onwaar zijn.", "validators", "nl");
t.add("This value should be true.", "Deze waarde moet waar zijn.", "validators", "nl");
t.add("This value should be of type {{ type }}.", "Deze waarde moet van het type {{ type }} zijn.", "validators", "nl");
t.add("This value should be blank.", "Deze waarde moet leeg zijn.", "validators", "nl");
t.add("The value you selected is not a valid choice.", "De geselecteerde waarde is geen geldige optie.", "validators", "nl");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "Selecteer ten minste {{ limit }} optie.|Selecteer ten minste {{ limit }} opties.", "validators", "nl");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "Selecteer maximaal {{ limit }} optie.|Selecteer maximaal {{ limit }} opties.", "validators", "nl");
t.add("One or more of the given values is invalid.", "E\u00e9n of meer van de ingegeven waarden zijn ongeldig.", "validators", "nl");
t.add("This field was not expected.", "Dit veld werd niet verwacht.", "validators", "nl");
t.add("This field is missing.", "Dit veld ontbreekt.", "validators", "nl");
t.add("This value is not a valid date.", "Deze waarde is geen geldige datum.", "validators", "nl");
t.add("This value is not a valid datetime.", "Deze waarde is geen geldige datum en tijd.", "validators", "nl");
t.add("This value is not a valid email address.", "Deze waarde is geen geldig e-mailadres.", "validators", "nl");
t.add("The file could not be found.", "Het bestand kon niet gevonden worden.", "validators", "nl");
t.add("The file is not readable.", "Het bestand is niet leesbaar.", "validators", "nl");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "Het bestand is te groot ({{ size }} {{ suffix }}). Toegestane maximum grootte is {{ limit }} {{ suffix }}.", "validators", "nl");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "Het mime type van het bestand is ongeldig ({{ type }}). Toegestane mime types zijn {{ types }}.", "validators", "nl");
t.add("This value should be {{ limit }} or less.", "Deze waarde moet {{ limit }} of minder zijn.", "validators", "nl");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "Deze waarde is te lang. Hij mag maximaal {{ limit }} teken bevatten.|Deze waarde is te lang. Hij mag maximaal {{ limit }} tekens bevatten.", "validators", "nl");
t.add("This value should be {{ limit }} or more.", "Deze waarde moet {{ limit }} of meer zijn.", "validators", "nl");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "Deze waarde is te kort. Hij moet tenminste {{ limit }} teken bevatten.|Deze waarde is te kort. Hij moet tenminste {{ limit }} tekens bevatten.", "validators", "nl");
t.add("This value should not be blank.", "Deze waarde mag niet leeg zijn.", "validators", "nl");
t.add("This value should not be null.", "Deze waarde mag niet null zijn.", "validators", "nl");
t.add("This value should be null.", "Deze waarde moet null zijn.", "validators", "nl");
t.add("This value is not valid.", "Deze waarde is niet geldig.", "validators", "nl");
t.add("This value is not a valid time.", "Deze waarde is geen geldige tijd.", "validators", "nl");
t.add("This value is not a valid URL.", "Deze waarde is geen geldige URL.", "validators", "nl");
t.add("The two values should be equal.", "De twee waarden moeten gelijk zijn.", "validators", "nl");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "Het bestand is te groot. Toegestane maximum grootte is {{ limit }} {{ suffix }}.", "validators", "nl");
t.add("The file is too large.", "Het bestand is te groot.", "validators", "nl");
t.add("The file could not be uploaded.", "Het bestand kon niet worden ge\u00fcpload.", "validators", "nl");
t.add("This value should be a valid number.", "Deze waarde moet een geldig getal zijn.", "validators", "nl");
t.add("This file is not a valid image.", "Dit bestand is geen geldige afbeelding.", "validators", "nl");
t.add("This is not a valid IP address.", "Dit is geen geldig IP-adres.", "validators", "nl");
t.add("This value is not a valid language.", "Deze waarde is geen geldige taal.", "validators", "nl");
t.add("This value is not a valid locale.", "Deze waarde is geen geldige locale.", "validators", "nl");
t.add("This value is not a valid country.", "Deze waarde is geen geldig land.", "validators", "nl");
t.add("This value is already used.", "Deze waarde wordt al gebruikt.", "validators", "nl");
t.add("The size of the image could not be detected.", "De grootte van de afbeelding kon niet bepaald worden.", "validators", "nl");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "De afbeelding is te breed ({{ width }}px). De maximaal toegestane breedte is {{ max_width }}px.", "validators", "nl");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "De afbeelding is niet breed genoeg ({{ width }}px). De minimaal verwachte breedte is {{ min_width }}px.", "validators", "nl");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "De afbeelding is te hoog ({{ height }}px). De maximaal toegestane hoogte is {{ max_height }}px.", "validators", "nl");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "De afbeelding is niet hoog genoeg ({{ height }}px). De minimaal verwachte hoogte is {{ min_height }}px.", "validators", "nl");
t.add("This value should be the user's current password.", "Deze waarde moet het huidige wachtwoord van de gebruiker zijn.", "validators", "nl");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "Deze waarde moet exact {{ limit }} teken lang zijn.|Deze waarde moet exact {{ limit }} tekens lang zijn.", "validators", "nl");
t.add("The file was only partially uploaded.", "Het bestand is slechts gedeeltelijk ge\u00fcpload.", "validators", "nl");
t.add("No file was uploaded.", "Er is geen bestand ge\u00fcpload.", "validators", "nl");
t.add("No temporary folder was configured in php.ini.", "Er is geen tijdelijke map geconfigureerd in php.ini, of de gespecificeerde map bestaat niet.", "validators", "nl");
t.add("Cannot write temporary file to disk.", "Kan het tijdelijke bestand niet wegschrijven op disk.", "validators", "nl");
t.add("A PHP extension caused the upload to fail.", "De upload is mislukt vanwege een PHP-extensie.", "validators", "nl");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "Deze collectie moet {{ limit }} element of meer bevatten.|Deze collectie moet {{ limit }} elementen of meer bevatten.", "validators", "nl");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "Deze collectie moet {{ limit }} element of minder bevatten.|Deze collectie moet {{ limit }} elementen of minder bevatten.", "validators", "nl");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "Deze collectie moet exact {{ limit }} element bevatten.|Deze collectie moet exact {{ limit }} elementen bevatten.", "validators", "nl");
t.add("Invalid card number.", "Ongeldig creditcardnummer.", "validators", "nl");
t.add("Unsupported card type or invalid card number.", "Niet-ondersteund type creditcard of ongeldig nummer.", "validators", "nl");
t.add("This is not a valid International Bank Account Number (IBAN).", "Dit is geen geldig internationaal bankrekeningnummer (IBAN).", "validators", "nl");
t.add("This value is not a valid ISBN-10.", "Deze waarde is geen geldige ISBN-10.", "validators", "nl");
t.add("This value is not a valid ISBN-13.", "Deze waarde is geen geldige ISBN-13.", "validators", "nl");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "Deze waarde is geen geldige ISBN-10 of ISBN-13 waarde.", "validators", "nl");
t.add("This value is not a valid ISSN.", "Deze waarde is geen geldige ISSN waarde.", "validators", "nl");
t.add("This value is not a valid currency.", "Deze waarde is geen geldige valuta.", "validators", "nl");
t.add("This value should be equal to {{ compared_value }}.", "Deze waarde moet gelijk zijn aan {{ compared_value }}.", "validators", "nl");
t.add("This value should be greater than {{ compared_value }}.", "Deze waarde moet groter zijn dan {{ compared_value }}.", "validators", "nl");
t.add("This value should be greater than or equal to {{ compared_value }}.", "Deze waarde moet groter dan of gelijk aan {{ compared_value }} zijn.", "validators", "nl");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "Deze waarde moet identiek zijn aan {{ compared_value_type }} {{ compared_value }}.", "validators", "nl");
t.add("This value should be less than {{ compared_value }}.", "Deze waarde moet minder zijn dan {{ compared_value }}.", "validators", "nl");
t.add("This value should be less than or equal to {{ compared_value }}.", "Deze waarde moet minder dan of gelijk aan {{ compared_value }} zijn.", "validators", "nl");
t.add("This value should not be equal to {{ compared_value }}.", "Deze waarde mag niet gelijk zijn aan {{ compared_value }}.", "validators", "nl");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "Deze waarde mag niet identiek zijn aan {{ compared_value_type }} {{ compared_value }}.", "validators", "nl");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "De afbeeldingsverhouding is te groot ({{ ratio }}). Maximale verhouding is {{ max_ratio }}.", "validators", "nl");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "De afbeeldingsverhouding is te klein ({{ ratio }}). Minimale verhouding is {{ min_ratio }}.", "validators", "nl");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "De afbeelding is vierkant ({{ width }}x{{ height }}px). Vierkante afbeeldingen zijn niet toegestaan.", "validators", "nl");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "De afbeelding is liggend ({{ width }}x{{ height }}px). Liggende afbeeldingen zijn niet toegestaan.", "validators", "nl");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "De afbeelding is staand ({{ width }}x{{ height }}px). Staande afbeeldingen zijn niet toegestaan.", "validators", "nl");
t.add("An empty file is not allowed.", "Lege bestanden zijn niet toegestaan.", "validators", "nl");
t.add("The host could not be resolved.", "De hostnaam kon niet worden bepaald.", "validators", "nl");
t.add("This value does not match the expected {{ charset }} charset.", "Deze waarde is niet in de verwachte tekencodering {{ charset }}.", "validators", "nl");
t.add("This is not a valid Business Identifier Code (BIC).", "Dit is geen geldige bedrijfsidentificatiecode (BIC\/SWIFT).", "validators", "nl");
t.add("Error", "Fout", "validators", "nl");
t.add("This is not a valid UUID.", "Dit is geen geldige UUID.", "validators", "nl");
t.add("This value should be a multiple of {{ compared_value }}.", "Deze waarde zou een meervoud van {{ compared_value }} moeten zijn.", "validators", "nl");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "Deze bedrijfsidentificatiecode (BIC) is niet gekoppeld aan IBAN {{ iban }}.", "validators", "nl");
t.add("This value should be valid JSON.", "Deze waarde moet geldige JSON zijn.", "validators", "nl");
t.add("This collection should contain only unique elements.", "Deze collectie moet alleen unieke elementen bevatten.", "validators", "nl");
t.add("This value should be positive.", "Deze waarde moet positief zijn.", "validators", "nl");
t.add("This value should be either positive or zero.", "Deze waarde moet positief of gelijk aan nul zijn.", "validators", "nl");
t.add("This value should be negative.", "Deze waarde moet negatief zijn.", "validators", "nl");
t.add("This value should be either negative or zero.", "Deze waarde moet negatief of gelijk aan nul zijn.", "validators", "nl");
t.add("This value is not a valid timezone.", "Deze waarde is geen geldige tijdzone.", "validators", "nl");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "Dit wachtwoord is gelekt vanwege een data-inbreuk, het moet niet worden gebruikt. Kies een ander wachtwoord.", "validators", "nl");
t.add("This value should be between {{ min }} and {{ max }}.", "Deze waarde moet zich tussen {{ min }} en {{ max }} bevinden.", "validators", "nl");
t.add("This value is not a valid hostname.", "Deze waarde is geen geldige hostnaam.", "validators", "nl");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "Het aantal elementen van deze collectie moet een veelvoud zijn van {{ compared_value }}.", "validators", "nl");
t.add("This value should satisfy at least one of the following constraints:", "Deze waarde moet voldoen aan tenminste een van de volgende voorwaarden:", "validators", "nl");
t.add("Each element of this collection should satisfy its own set of constraints.", "Elk element van deze collectie moet voldoen aan zijn eigen set voorwaarden.", "validators", "nl");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "Deze waarde is geen geldig International Securities Identification Number (ISIN).", "validators", "nl");
t.add("This value should be a valid expression.", "Deze waarde moet een geldige expressie zijn.", "validators", "nl");
t.add("This value is not a valid CSS color.", "Deze waarde is geen geldige CSS kleur.", "validators", "nl");
t.add("This value is not a valid CIDR notation.", "Deze waarde is geen geldige CIDR notatie.", "validators", "nl");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "De waarde van de netmask moet zich tussen {{ min }} en {{ max }} bevinden.", "validators", "nl");
t.add("This form should not contain extra fields.", "Dit formulier mag geen extra velden bevatten.", "validators", "nl");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "Het ge\u00fcploade bestand is te groot. Probeer een kleiner bestand te uploaden.", "validators", "nl");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "De CSRF-token is ongeldig. Probeer het formulier opnieuw te versturen.", "validators", "nl");
t.add("This value is not a valid HTML5 color.", "Dit is geen geldige HTML5 kleur.", "validators", "nl");
t.add("Please enter a valid birthdate.", "Vul een geldige geboortedatum in.", "validators", "nl");
t.add("The selected choice is invalid.", "Deze keuze is ongeldig.", "validators", "nl");
t.add("The collection is invalid.", "Deze collectie is ongeldig.", "validators", "nl");
t.add("Please select a valid color.", "Kies een geldige kleur.", "validators", "nl");
t.add("Please select a valid country.", "Kies een geldige landnaam.", "validators", "nl");
t.add("Please select a valid currency.", "Kies een geldige valuta.", "validators", "nl");
t.add("Please choose a valid date interval.", "Kies een geldig tijdinterval.", "validators", "nl");
t.add("Please enter a valid date and time.", "Vul een geldige datum en tijd in.", "validators", "nl");
t.add("Please enter a valid date.", "Vul een geldige datum in.", "validators", "nl");
t.add("Please select a valid file.", "Kies een geldig bestand.", "validators", "nl");
t.add("The hidden field is invalid.", "Het verborgen veld is incorrect.", "validators", "nl");
t.add("Please enter an integer.", "Vul een geldig getal in.", "validators", "nl");
t.add("Please select a valid language.", "Kies een geldige taal.", "validators", "nl");
t.add("Please select a valid locale.", "Kies een geldige locale.", "validators", "nl");
t.add("Please enter a valid money amount.", "Vul een geldig bedrag in.", "validators", "nl");
t.add("Please enter a number.", "Vul een geldig getal in.", "validators", "nl");
t.add("The password is invalid.", "Het wachtwoord is incorrect.", "validators", "nl");
t.add("Please enter a percentage value.", "Vul een geldig percentage in.", "validators", "nl");
t.add("The values do not match.", "De waardes komen niet overeen.", "validators", "nl");
t.add("Please enter a valid time.", "Vul een geldige tijd in.", "validators", "nl");
t.add("Please select a valid timezone.", "Vul een geldige tijdzone in.", "validators", "nl");
t.add("Please enter a valid URL.", "Vul een geldige URL in.", "validators", "nl");
t.add("Please enter a valid search term.", "Vul een geldige zoekterm in.", "validators", "nl");
t.add("Please provide a valid phone number.", "Vul een geldig telefoonnummer in.", "validators", "nl");
t.add("The checkbox has an invalid value.", "De checkbox heeft een incorrecte waarde.", "validators", "nl");
t.add("Please enter a valid email address.", "Vul een geldig e-mailadres in.", "validators", "nl");
t.add("Please select a valid option.", "Kies een geldige optie.", "validators", "nl");
t.add("Please select a valid range.", "Kies een geldig bereik.", "validators", "nl");
t.add("Please enter a valid week.", "Vul een geldige week in.", "validators", "nl");
})(Translator);
