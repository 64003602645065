(function (t) {
// fr
t.add("test.scaleACLRSI", "Echelle ACL - RSI", "tests", "fr");
t.add("test.singleLegLanding", "Single leg landing", "tests", "fr");
t.add("test.genuValgum", "Genu Valgum", "tests", "fr");
t.add("test.healthyKnee", "Genou sain", "tests", "fr");
t.add("test.k_starts_launch_warning", "L'application a \u00e9volu\u00e9, il faut maintenant renseigner l'email du chirurgien, m\u00e9decin du sport et\/ou secr\u00e9tariat dans l'onglet \"genou\" et non dans l'onglet \"informations g\u00e9n\u00e9rales\" pour qu'ils puissent \u00eatre automatiquement pr\u00e9-s\u00e9lectionn\u00e9s lors de l'envoi par mail du bilan.", "tests", "fr");
t.add("test.launch", "Lancement d\u2019un test", "tests", "fr");
t.add("test.title", "Tests", "tests", "fr");
t.add("test.name", "Nom du test", "tests", "fr");
t.add("test.customerName", "Nom du client", "tests", "fr");
t.add("test.date", "Date du test", "tests", "fr");
t.add("test.validate", "Valider", "tests", "fr");
t.add("test.side.healthy", "Saine", "tests", "fr");
t.add("test.side.healthy_newton", "Saine (en Newton)", "tests", "fr");
t.add("test.side.healthy_cm", "Saine (en cm)", "tests", "fr");
t.add("test.side.injured", "L\u00e9s\u00e9e", "tests", "fr");
t.add("test.side.injured_newton", "L\u00e9s\u00e9e (en Newton)", "tests", "fr");
t.add("test.side.injured_cm", "L\u00e9s\u00e9e  (en cm)", "tests", "fr");
t.add("test.side.left", "Gauche", "tests", "fr");
t.add("test.side.left_cm", "Gauche (en cm)", "tests", "fr");
t.add("test.side.left_newton", "Gauche (en Newton)", "tests", "fr");
t.add("test.side.right", "Droite", "tests", "fr");
t.add("test.side.right_cm", "Droite (en cm)", "tests", "fr");
t.add("test.side.right_newton", "Droite (en Newton)", "tests", "fr");
t.add("test.change_customer", "Modifier", "tests", "fr");
t.add("test.list", "Liste des tests", "tests", "fr");
t.add("test.totals", "R\u00e9sultats", "tests", "fr");
t.add("test.psychologicalComponent", "Composante psychologique", "tests", "fr");
t.add("test.physicalComponent", "Composantes physiques", "tests", "fr");
t.add("test.exercise.name", "Nom de l\u2019exercice", "tests", "fr");
t.add("test.difference", "Diff\u00e9rence", "tests", "fr");
t.add("test.illinois_modifie", "Illinois modifi\u00e9", "tests", "fr");
t.add("test.average", "Moyenne", "tests", "fr");
t.add("test.global_result", "R\u00e9sultat Global", "tests", "fr");
t.add("test.globalScore.insufficient", "\u00c0 travailler", "tests", "fr");
t.add("test.globalScore.correct", "\u00c0 perfectionner", "tests", "fr");
t.add("test.globalScore.good", "\u00c0 entretenir", "tests", "fr");
t.add("test.globalResult.100", "R\u00e9sultat global (100)", "tests", "fr");
t.add("test.globalResult.message", "Les r\u00e9sultats au test sont", "tests", "fr");
t.add("test.edit", "Modifier", "tests", "fr");
t.add("test.show_details", "Voir le d\u00e9tail", "tests", "fr");
t.add("test.delete", "Supprimer", "tests", "fr");
t.add("test.results", "R\u00e9sultat test", "tests", "fr");
t.add("test.result.externalRotationForce", "Force de rotation externe (RE)", "tests", "fr");
t.add("test.result.internalRotationForce", "Force de rotation interne (RI)", "tests", "fr");
t.add("test.result.equilibriumRotationForce", "Equilibre ratio RE\/RI", "tests", "fr");
t.add("test.result.stabilisationWork", "Travail de stabilisation", "tests", "fr");
t.add("test.result.explosivityWork", "Travail d'explosivit\u00e9", "tests", "fr");
t.add("test.result.powerWork", "Travail de puissance", "tests", "fr");
t.add("test.result.musclarWork", "Travail de gainage abdominal\/Travail d'endurance musculaire", "tests", "fr");
t.add("test.specific_analysis_to_be_done", "Analyse sp\u00e9cifique du travail \u00e0 r\u00e9aliser", "tests", "fr");
t.add("test.result.overallApprehensionOfTheTest", "Appr\u00e9hension globale", "tests", "fr");
t.add("test.result.genuValgumDynamic", "Genu valgum dynamique", "tests", "fr");
t.add("test.result.dynamicProprioception", "Proprioception dynamique", "tests", "fr");
t.add("test.result.axialPlyometricWork", "Travail pliom\u00e9trique axe", "tests", "fr");
t.add("test.result.speedWork", "Travail de vitesse", "tests", "fr");
t.add("test.result.eccentricControlOfTheQuadriceps", "Contr\u00f4le excentrique du quadriceps", "tests", "fr");
t.add("test.result.concentricStrengtheningTheQuadriceps", "Renforcement concentrique du quadriceps", "tests", "fr");
t.add("test.result.plyometricWorkOffAxis", "Travail pliom\u00e9trique hors axe", "tests", "fr");
t.add("test.result.muscularEnduranceWork", "Travail d\u2019endurance musculaire", "tests", "fr");
t.add("test.result.strengtheningHipStabilizers", "Renforcement stabilisateurs de hanche", "tests", "fr");
t.add("test.result.explosiveWork", "Travail d\u2019explosivit\u00e9", "tests", "fr");
t.add("test.result.numberOfMinimumRehalationSessionsRecommended", "Nombre de s\u00e9ances minimum de r\u00e9athl\u00e9tisation pr\u00e9conis\u00e9", "tests", "fr");
t.add("test.result.comment", "Commentaire", "tests", "fr");
t.add("test.result.generalComment", "Commentaire", "tests", "fr");
t.add("test.tegnerScale", "Echelle de Tegner", "tests", "fr");
t.add("%", "%", "tests", "fr");
t.add("test.speed_work.improvement_needed", "\u00c0 perfectionner", "tests", "fr");
t.add("test.speed_work.needed", "\u00c0 travailler", "tests", "fr");
t.add("test.speed_work.ok", "Acquis", "tests", "fr");
t.add("test.apprehension.no", "Pas d'appr\u00e9hension", "tests", "fr");
t.add("test.apprehension.at_the_resumption_of_competition", "\u00c0 la reprise de comp\u00e9tition", "tests", "fr");
t.add("test.apprehension.at_the_resumption_of_activity", "\u00c0 la reprise de l'activit\u00e9", "tests", "fr");
t.add("test.work_needed.left", "Gauche", "tests", "fr");
t.add("test.work_needed.right", "Droite", "tests", "fr");
t.add("test.work_needed.both", "Bilat\u00e9ral", "tests", "fr");
t.add("test.work_needed.none", "Acquis", "tests", "fr");
t.add("choice.choose", "Choisir...", "tests", "fr");
t.add("test.result.apprehensionDuringTheTest", "Appr\u00e9hension lors du test", "tests", "fr");
t.add("test.qasls_test_results", "QASLS Test", "tests", "fr");
t.add("test.aclrsi_test_results", "Echelle ACL - RSI", "tests", "fr");
t.add("test.aclrsi_questionaire_description", "Merci de r\u00e9pondre aux questions suivantes concernant le sport principal que vous pratiquiez avant l\u2019accident. Pour chaque question, cochez la case entre les deux extr\u00eames selon ce qui vous parait correspondre le mieux \u00e0 la situation actuelle de votre genou.", "tests", "fr");
t.add("test.aclrsi_edge.not_at_all_sure", "Pas du tout s\u00fbr ", "tests", "fr");
t.add("test.aclrsi_edge.totally_sure", "Totalement s\u00fbr", "tests", "fr");
t.add("test.aclrsi_edge.extremely_likely", "Extr\u00eamement probable", "tests", "fr");
t.add("test.aclrsi_edge.not_at_all_likely", "Pas du tout probable", "tests", "fr");
t.add("test.aclrsi_edge.extremely_worried", "Extr\u00eamement inquiet", "tests", "fr");
t.add("test.aclrsi_edge.not_at_all_worried", "Pas du tout inquiet", "tests", "fr");
t.add("test.aclrsi_edge.extremely_frustrated", "Extr\u00eamement frustr\u00e9", "tests", "fr");
t.add("test.aclrsi_edge.not_at_all_frustrated", "Pas du tout frustr\u00e9", "tests", "fr");
t.add("test.aclrsi_edge.extreme_fear", "Crainte extr\u00eame", "tests", "fr");
t.add("test.aclrsi_edge.no_fear", "Aucune crainte", "tests", "fr");
t.add("test.aclrsi_edge.very_scared", "Tr\u00e8s peur", "tests", "fr");
t.add("test.aclrsi_edge.not_at_all_scared", "Pas du tout peur", "tests", "fr");
t.add("test.aclrsi_edge.all_the_time", "Tout le temps", "tests", "fr");
t.add("test.aclrsi_edge.at_no_time", "\u00c0 aucun moment", "tests", "fr");
t.add("test.aclrsi_edge.not_at_all_confident", "Pas du tout confiant", "tests", "fr");
t.add("test.aclrsi_edge.totally_confident", "Totalement confiant", "tests", "fr");
t.add("test.aclrsi_edge.not_at_all_relaxed", "Pas du tout d\u00e9tendu", "tests", "fr");
t.add("test.aclrsi_edge.totally_relaxed", "Totalement d\u00e9tendu", "tests", "fr");
t.add("test.aclrsi_test_link", "R\u00e9pondre", "tests", "fr");
t.add("test.respond_to_the_questionnaire", "R\u00e9pondre au questionnaire", "tests", "fr");
t.add("test.tar.description", "Fiche d'observations du Test d'Aptitude \u00e0 la R\u00e9athl\u00e9tisation", "tests", "fr");
t.add("result.tar.not_validated", "Non valid\u00e9", "tests", "fr");
t.add("result.tar.validated", "Valid\u00e9", "tests", "fr");
t.add("test.result.placeholder", "Commentaires...", "tests", "fr");
t.add("test.result.validated", "Valid\u00e9", "tests", "fr");
t.add("test.result.not_validated", "Non valid\u00e9", "tests", "fr");
t.add("test.tar.globalComment", "Commentaire global", "tests", "fr");
t.add("test.result.side_left", "Gauche", "tests", "fr");
t.add("test.result.side_right", "Droite", "tests", "fr");
t.add("test.dpr.results", "R\u00e9sultats du test DPR", "tests", "fr");
t.add("test.dpr.description", "Test DPR", "tests", "fr");
t.add("test.email.customerNoEmails", "L'email ne peut pas \u00eatre envoy\u00e9 \u00e0 tous les destinataires car les adresses n\u00e9cessaires ne sont pas renseign\u00e9es sur le profil de ce client.", "tests", "fr");
t.add("test.graph_title.quadriceps_force", "Quadriceps", "tests", "fr");
t.add("test.graph_title.ij_force", "Ischio-jambiers", "tests", "fr");
t.add("test.graph_title.adductors_force", "Adducteurs", "tests", "fr");
t.add("test.graph_title.abductors", "Abducteurs", "tests", "fr");
t.add("test.graph_title.dc", "DC", "tests", "fr");
t.add("test.graph_title.tp", "TP", "tests", "fr");
t.add("test.graph_title.ri", "Rotateurs internes", "tests", "fr");
t.add("test.graph_title.re", "Rotateurs externes", "tests", "fr");
t.add("test.graph_title.ratio_ij_quadriceps", "Ratios IJ \/ Quad", "tests", "fr");
t.add("test.graph_title.ratio_adductors_abductors", "Ratios Adduc \/ Abducteurs", "tests", "fr");
t.add("test.graph_title.ratio_re_ri", "Ratio RE \/ RI", "tests", "fr");
t.add("test.graph_title.upper_body_strength", "Force du haut du corps", "tests", "fr");
t.add("test.graph_title.trunk_endurance", "Endurance du tronc", "tests", "fr");
t.add("test.results.tar", "Fiche d'observations du Test d'Aptitude \u00e0 la R\u00e9athl\u00e9tisation", "tests", "fr");
t.add("test.dpr.long_name", "Test de D\u00e9tection de Profils \u00e0 Risque", "tests", "fr");
t.add("test.force", "Tests de force", "tests", "fr");
t.add("test.force.inferior_members", "Membre inf\u00e9rieur", "tests", "fr");
t.add("test.force.tronk", "Tronc", "tests", "fr");
t.add("test.force.epaule", "Epaule", "tests", "fr");
t.add("test.dpr.comment.dpr_closed_eyes_unipodal_stability.0", "Travail bilat\u00e9ral de proprioception des membres inf\u00e9rieurs.", "tests", "fr");
t.add("test.dpr.comment.dpr_closed_eyes_unipodal_stability.1", "Travail de proprioception du membre inf\u00e9rieur gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_closed_eyes_unipodal_stability.2", "Travail de proprioception du membre inf\u00e9rieur droit.", "tests", "fr");
t.add("test.dpr.comment.dpr_unipodal_knee_flexion.0", "Travail bilat\u00e9ral du contr\u00f4le excentrique des quadriceps.~|~Travail bilat\u00e9ral de renforcement des stabilisateurs de hanche.", "tests", "fr");
t.add("test.dpr.comment.dpr_unipodal_knee_flexion.1", "Travail du contr\u00f4le excentrique du quadriceps gauche.~|~Travail de renforcement des stabilisateurs de hanche gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_unipodal_knee_flexion.2", "Travail du contr\u00f4le excentrique du quadriceps droit.~|~Travail de renforcement des stabilisateurs de hanche droit.", "tests", "fr");
t.add("test.dpr.comment.dpr_squat.0", "Travail global de mobilit\u00e9 et de souplesse des membres inf\u00e9rieurs.", "tests", "fr");
t.add("test.dpr.comment.dpr_proprioception_basin.0", "Travail proprioceptif du bassin.", "tests", "fr");
t.add("test.dpr.comment.dpr_posterior_muscle_chain.0", "Travail de renforcement et\/ou de souplesse de la cha\u00eene post\u00e9rieure. ", "tests", "fr");
t.add("test.dpr.comment.dpr_dip_shoulders.0", "Travail bilat\u00e9ral de renforcement des fixateurs de l'omoplate et\/ou de souplesse des ceintures scapulaires.", "tests", "fr");
t.add("test.dpr.comment.dpr_quadriceps_flexibility.0", "Travail bilat\u00e9ral de souplesse des quadriceps. ", "tests", "fr");
t.add("test.dpr.comment.dpr_quadriceps_flexibility.1", "Travail de souplesse du quadriceps gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_quadriceps_flexibility.2", "Travail de souplesse du quadriceps droit.", "tests", "fr");
t.add("test.dpr.comment.dpr_ij_flexibility.0", "Travail bilat\u00e9ral de souplesse des ischio-jambiers.", "tests", "fr");
t.add("test.dpr.comment.dpr_ij_flexibility.1", "Travail de souplesse des ischio-jambiers gauche. ", "tests", "fr");
t.add("test.dpr.comment.dpr_ij_flexibility.2", "Travail de souplesse des ischio-jambiers droit.", "tests", "fr");
t.add("test.dpr.comment.dpr_adductors_flexibility.0", "Travail bilat\u00e9ral de souplesse des adducteurs. ", "tests", "fr");
t.add("test.dpr.comment.dpr_adductors_flexibility.1", "Travail de souplesse des adducteurs gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_adductors_flexibility.2", "Travail de souplesse des adducteurs droit. ", "tests", "fr");
t.add("test.dpr.comment.dpr_calves.0", "Travail bilat\u00e9ral de souplesse des triceps suraux. ", "tests", "fr");
t.add("test.dpr.comment.dpr_calves.1", "Travail de souplesse du triceps sural gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_calves.2", "Travail de souplesse du triceps sural droit.", "tests", "fr");
t.add("test.dpr.comment.dpr_fingers_ground.0", "Travail de souplesse de la cha\u00eene post\u00e9rieure au niveau lombaire et fessiers.", "tests", "fr");
t.add("test.dpr.comment.dpr_psoas.0", "Travail bilat\u00e9ral de souplesse des psoas. ", "tests", "fr");
t.add("test.dpr.comment.dpr_psoas.1", "Travail de souplesse du psoas gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_psoas.2", "Travail de souplesse du psoas droit.", "tests", "fr");
t.add("test.dpr.comment.dpr_internal_rotation.0", "Travail bilat\u00e9ral de souplesse en rotation interne des \u00e9paules. ", "tests", "fr");
t.add("test.dpr.comment.dpr_internal_rotation.1", "Travail de souplesse en rotation interne de l'\u00e9paule gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_internal_rotation.2", "Travail de souplesse en rotation interne de l'\u00e9paule droite.", "tests", "fr");
t.add("test.dpr.comment.dpr_candlestick.0", "Travail bilat\u00e9ral de souplesse des \u00e9paules en rotation externe.", "tests", "fr");
t.add("test.dpr.comment.dpr_candlestick.1", "Travail de souplesse en rotation externe de l'\u00e9paule gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_candlestick.2", "Travail de souplesse en rotation externe de l'\u00e9paule droite.", "tests", "fr");
t.add("test.dpr.comment.dpr_retropulsion.0", "Travail bilat\u00e9ral de souplesse des \u00e9paules en r\u00e9tropulsion.", "tests", "fr");
t.add("test.dpr.comment.dpr_antepulsion.0", "Travail bilat\u00e9ral de souplesse des \u00e9paules en ant\u00e9pulsion.", "tests", "fr");
t.add("test.dpr.comment.dpr_antepulsion.1", "Travail de souplesse de l'\u00e9paule gauche en ant\u00e9pulsion.", "tests", "fr");
t.add("test.dpr.comment.dpr_antepulsion.2", "Travail de souplesse de l'\u00e9paule droite en ant\u00e9pulsion.", "tests", "fr");
t.add("test.dpr.comment.dpr_quadriceps_force.0", "Travail bilat\u00e9ral de renforcement des quadriceps.", "tests", "fr");
t.add("test.dpr.comment.dpr_quadriceps_force.1", "Travail de renforcement du quadriceps gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_quadriceps_force.2", "Travail de renforcement du quadriceps droit.", "tests", "fr");
t.add("test.dpr.comment.dpr_quadriceps_force.3", "Travail bilat\u00e9ral de renforcement des quadriceps (avec pr\u00e9dominance \u00e0 gauche).", "tests", "fr");
t.add("test.dpr.comment.dpr_quadriceps_force.4", "Travail bilat\u00e9ral de renforcement des quadriceps (avec pr\u00e9dominance \u00e0 droite).", "tests", "fr");
t.add("test.dpr.comment.dpr_ij_force.0", "Travail bilat\u00e9ral de renforcement des ischio-jambiers.", "tests", "fr");
t.add("test.dpr.comment.dpr_ij_force.1", "Travail de renforcement des ischio-jambiers gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_ij_force.2", "Travail de renforcement des ischio-jambiers droit.", "tests", "fr");
t.add("test.dpr.comment.dpr_ij_force.3", "Travail bilat\u00e9ral de renforcement des ischio-jambiers (avec pr\u00e9dominance \u00e0 gauche).", "tests", "fr");
t.add("test.dpr.comment.dpr_ij_force.4", "Travail bilat\u00e9ral de renforcement des ischio-jambiers (avec pr\u00e9dominance \u00e0 droite).", "tests", "fr");
t.add("test.dpr.comment.dpr_adductors_force.0", "Travail bilat\u00e9ral de renforcement des adducteurs.", "tests", "fr");
t.add("test.dpr.comment.dpr_adductors_force.1", "Travail de renforcement des adducteurs gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_adductors_force.2", "Travail de renforcement des adducteurs droit.", "tests", "fr");
t.add("test.dpr.comment.dpr_adductors_force.3", "Travail bilat\u00e9ral de renforcement des adducteurs (avec pr\u00e9dominance \u00e0 gauche).", "tests", "fr");
t.add("test.dpr.comment.dpr_adductors_force.4", "Travail bilat\u00e9ral de renforcement des adducteurs (avec pr\u00e9dominance \u00e0 droite).", "tests", "fr");
t.add("test.dpr.comment.dpr_abductors.0", "Travail bilat\u00e9ral de renforcement des abducteurs.", "tests", "fr");
t.add("test.dpr.comment.dpr_abductors.1", "Travail de renforcement des abducteurs gauche.", "tests", "fr");
t.add("test.dpr.comment.dpr_abductors.2", "Travail de renforcement des abducteurs droit.", "tests", "fr");
t.add("test.dpr.comment.dpr_abductors.3", "Travail bilat\u00e9ral de renforcement des abducteurs (avec pr\u00e9dominance \u00e0 gauche).", "tests", "fr");
t.add("test.dpr.comment.dpr_abductors.4", "Travail bilat\u00e9ral de renforcement des abducteurs (avec pr\u00e9dominance \u00e0 droite).", "tests", "fr");
t.add("test.dpr.comment.dpr_shirado.0", "Travail de gainage abdominal statique puis dynamique.", "tests", "fr");
t.add("test.dpr.comment.dpr_sorensen.0", "Travail de gainage lombaire statique puis dynamique.", "tests", "fr");
t.add("test.dpr.comment.dpr_dc.0", "Travail de renforcement des muscles ant\u00e9rieurs du corps.", "tests", "fr");
t.add("test.dpr.comment.dpr_tirage.0", "Travail de renforcement des muscles post\u00e9rieurs du corps.", "tests", "fr");
t.add("test.dpr.comment.dpr_ri1.0", "Travail bilat\u00e9ral de renforcement des rotateurs internes des \u00e9paules. ", "tests", "fr");
t.add("test.dpr.comment.dpr_ri1.1", "Travail de renforcement des rotateurs internes de l'\u00e9paule gauche. ", "tests", "fr");
t.add("test.dpr.comment.dpr_ri1.2", "Travail de renforcement des rotateurs internes de l'\u00e9paule droite.", "tests", "fr");
t.add("test.dpr.comment.dpr_ri1.3", "Travail bilat\u00e9ral de renforcement des rotateurs internes des \u00e9paules (avec pr\u00e9dominance \u00e0 gauche). ", "tests", "fr");
t.add("test.dpr.comment.dpr_ri1.4", "Travail bilat\u00e9ral de renforcement des rotateurs internes des \u00e9paules (avec pr\u00e9dominance \u00e0 droite). ", "tests", "fr");
t.add("test.dpr.comment.dpr_re1.0", "Travail bilat\u00e9ral de renforcement des rotateurs externes des \u00e9paules. ", "tests", "fr");
t.add("test.dpr.comment.dpr_re1.1", "Travail de renforcement des rotateurs externes de l'\u00e9paule gauche. ", "tests", "fr");
t.add("test.dpr.comment.dpr_re1.2", "Travail de renforcement des rotateurs externes de l'\u00e9paule droite.", "tests", "fr");
t.add("test.dpr.comment.dpr_re1.3", "Travail bilat\u00e9ral de renforcement des rotateurs externes des \u00e9paules (avec pr\u00e9dominance \u00e0 gauche). ", "tests", "fr");
t.add("test.dpr.comment.dpr_re1.4", "Travail bilat\u00e9ral de renforcement des rotateurs externes des \u00e9paules (avec pr\u00e9dominance \u00e0 droite). ", "tests", "fr");
t.add("test.dpr.comment.muscular_maintenance_work", "Travail d'entretien musculaire.", "tests", "fr");
t.add("test.result.ratio_tirage_dc", "Tirage\/DC", "tests", "fr");
t.add("test.result.ratio_abdo_spinaux", "Abdo\/spinaux", "tests", "fr");
t.add("test.graph_title.ratio_tirage_dc", "Ratio Tirage\/DC", "tests", "fr");
t.add("test.dpr.comment.ratio_ij_quadriceps.both_between", "Travail \u00e9quilibr\u00e9 de renforcement des quadriceps et des ischio-jambiers.", "tests", "fr");
t.add("test.dpr.comment.ratio_ij_quadriceps.both_below", "Privil\u00e9gier le travail bilat\u00e9ral de renforcement des ischio-jambiers par rapport \u00e0 celui des quadriceps.", "tests", "fr");
t.add("test.dpr.comment.ratio_ij_quadriceps.both_above", "Privil\u00e9gier le travail bilat\u00e9ral de renforcement des quadriceps par rapport \u00e0 celui des ischio-jambiers.", "tests", "fr");
t.add("test.dpr.comment.ratio_ij_quadriceps.left_below_right_between", "Travail \u00e9quilibr\u00e9 de renforcement du quadriceps et des ischio-jambiers \u00e0 droite et privil\u00e9gier le travail de renforcement des ischio-jambiers par rapport \u00e0 celui du quadriceps \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_ij_quadriceps.left_between_right_below", "Privil\u00e9gier le travail de renforcement des ischio-jambiers par rapport \u00e0 celui du quadriceps \u00e0 droite et travail \u00e9quilibr\u00e9 de renforcement du quadriceps et des ischio-jambiers \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_ij_quadriceps.left_above_right_between", "Travail \u00e9quilibr\u00e9 de renforcement du quadriceps et des ischio-jambiers \u00e0 droite et privil\u00e9gier le travail de renforcement du quadriceps par rapport \u00e0 celui des ischio-jambiers \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_ij_quadriceps.left_between_right_above", "Privil\u00e9gier le travail de renforcement des ischio-jambiers par rapport \u00e0 celui du quadriceps \u00e0 droite et travail \u00e9quilibr\u00e9 de renforcement du quadriceps et des ischio-jambiers \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_ij_quadriceps.left_above_right_below", "Privil\u00e9gier le travail de renforcement des ischio-jambiers par rapport \u00e0 celui du quadriceps \u00e0 droite et privil\u00e9gier le travail du quadriceps par rapport \u00e0 celui des ischio-jambiers \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_ij_quadriceps.left_below_right_above", "Privil\u00e9gier le travail de renforcement du quadriceps par rapport \u00e0 celui des ischio-jambiers \u00e0 droite et privil\u00e9gier le travail de renforcement des ischio-jambiers par rapport \u00e0 celui du quadriceps \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_adductors_abductors.both_between", "Travail \u00e9quilibr\u00e9 de renforcement des adducteurs et des abducteurs.", "tests", "fr");
t.add("test.dpr.comment.ratio_adductors_abductors.both_below", "Privil\u00e9gier le travail bilat\u00e9ral de renforcement des adducteurs par rapport \u00e0 celui des abducteurs.", "tests", "fr");
t.add("test.dpr.comment.ratio_adductors_abductors.both_above", "Privil\u00e9gier le travail bilat\u00e9ral de renforcement des abducteurs par rapport \u00e0 celui des adducteurs.", "tests", "fr");
t.add("test.dpr.comment.ratio_adductors_abductors.left_below_right_between", "Travail \u00e9quilibr\u00e9 de renforcement des adducteurs et des abducteurs \u00e0 droite et privil\u00e9gier le travail de renforcement des adducteurs par rapport \u00e0 celui des abducteurs \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_adductors_abductors.left_between_right_below", "Privil\u00e9gier le travail de renforcement des adducteurs par rapport \u00e0 celui des abducteurs \u00e0 droite et travail \u00e9quilibr\u00e9 de renforcement des adducteurs et des abducteurs \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_adductors_abductors.left_above_right_between", "Travail \u00e9quilibr\u00e9 de renforcement des adducteurs et des abducteurs \u00e0 droite et privil\u00e9gier le travail de renforcement des abducteurs par rapport \u00e0 celui des adducteurs \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_adductors_abductors.left_between_right_above", "Privil\u00e9gier le travail de renforcement des abducteurs par rapport \u00e0 celui des adducteurs \u00e0 droite et travail \u00e9quilibr\u00e9 de renforcement des adducteurs et des abducteur \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_adductors_abductors.left_above_right_below", "Privil\u00e9gier le travail de renforcement des adducteurs par rapport \u00e0 celui des abducteurs \u00e0 droite et privil\u00e9gier le travail de renforcement des abducteurs par rapport \u00e0 celui des adducteurs \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_adductors_abductors.left_below_right_above", "Privil\u00e9gier le travail de renforcement des abducteurs par rapport \u00e0 celui des adducteurs \u00e0 droite et privil\u00e9gier le travail de renforcement des adducteurs par rapport \u00e0 celui des abducteurs \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_re_ri.both_between", "Travail \u00e9quilibr\u00e9 de renforcement des rotateurs internes et externes.", "tests", "fr");
t.add("test.dpr.comment.ratio_re_ri.both_below", "Privil\u00e9gier le travail bilat\u00e9ral de renforcement des rotateurs externes par rapport \u00e0 celui des rotateurs internes.", "tests", "fr");
t.add("test.dpr.comment.ratio_re_ri.both_above", "Privil\u00e9gier le travail bilat\u00e9ral de renforcement des rotateurs internes par rapport \u00e0 celui des rotateurs externes.", "tests", "fr");
t.add("test.dpr.comment.ratio_re_ri.left_below_right_between", "Travail \u00e9quilibr\u00e9 de renforcement des rotateurs internes et externes \u00e0 droite et privil\u00e9gier le travail de renforcement des rotateurs externes par rapport \u00e0 celui des rotateurs internes \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_re_ri.left_between_right_below", "Privil\u00e9gier le travail de renforcement des rotateurs externes par rapport \u00e0 celui des rotateurs internes \u00e0 droite et travail \u00e9quilibr\u00e9 de renforcement des rotateurs internes et externes \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_re_ri.left_above_right_between", "Travail \u00e9quilibr\u00e9 de renforcement des rotateurs internes et externes \u00e0 droite et privil\u00e9gier le travail de renforcement des rotateurs internes par rapport \u00e0 celui des rotateurs externes \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_re_ri.left_between_right_above", "Privil\u00e9gier le travail de renforcement des rotateurs internes par rapport \u00e0 celui des rotateurs externes \u00e0 droite et travail \u00e9quilibr\u00e9 des rotateurs internes et externes \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_re_ri.left_below_right_above", "Privil\u00e9gier le travail de renforcement des rotateurs internes par rapport \u00e0 celui des rotateurs externes \u00e0 droite et privil\u00e9gier le travail de renforcement des rotateurs externes par rapport \u00e0 celui des rotateurs internes \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_re_ri.left_above_right_below", "Privil\u00e9gier le travail de renforcement des rotateurs externe par rapport \u00e0 celui des rotateurs internes \u00e0 droite et privil\u00e9gier le travail de renforcement des rotateurs internes par rapport \u00e0 celui des rotateurs externes \u00e0 gauche.", "tests", "fr");
t.add("test.dpr.comment.ratio_tirage_dc.below", "Privil\u00e9gier le travail de renforcement des muscles post\u00e9rieurs par rapport \u00e0 celui des muscles ant\u00e9rieurs.", "tests", "fr");
t.add("test.dpr.comment.ratio_tirage_dc.between", "Travail \u00e9quilibr\u00e9 de renforcement des muscles ant\u00e9rieurs et post\u00e9rieurs.", "tests", "fr");
t.add("test.dpr.comment.ratio_tirage_dc.above", "Privil\u00e9gier le travail de renforcement des muscles ant\u00e9rieurs par rapport \u00e0 celui des muscles post\u00e9rieurs.", "tests", "fr");
t.add("test.dpr.comment.ratio_abdo_spinaux.between", "Travail \u00e9quilibr\u00e9 de renforcement des abdominaux et des lombaires.", "tests", "fr");
t.add("test.dpr.comment.ratio_abdo_spinaux.below", "Privil\u00e9gier le travail de renforcement des abdominaux par rapport \u00e0 celui des lombaires.", "tests", "fr");
t.add("test.dpr.comment.ratio_abdo_spinaux.above", "Privil\u00e9gier le travail de renforcement des lombaires par rapport \u00e0 celui des abdominaux.", "tests", "fr");
t.add("test.comments", "Commentaires", "tests", "fr");
t.add("test.exercise.name.illinois_modifie_average", "Temps Moyen", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi", "SI-RSI", "tests", "fr");
t.add("test.exercise.exercise_name.uqybt", "UQ YBT", "tests", "fr");
t.add("test.exercise.exercise_name.ckuest", "CKCUEST", "tests", "fr");
t.add("test.exercise.exercise_name.endurance_indice", "Indice d'endurance", "tests", "fr");
t.add("test.exercise.exercise_name.re_ri", "Ratio RE\/RI", "tests", "fr");
t.add("test.score_total", "Score Total", "tests", "fr");
t.add("test.score", "Score", "tests", "fr");
t.add("test.score_title", "Scores des tests", "tests", "fr");
t.add("test.total_score", "Score total (\/100 points)", "tests", "fr");
t.add("test.dpr.comment.dpr_abductors.muscular_maintenance_work", "Travail d'entretien musculaire des abducteurs.", "tests", "fr");
t.add("test.dpr.comment.dpr_quadriceps_force.muscular_maintenance_work", "Travail d'entretien musculaire des quadriceps.", "tests", "fr");
t.add("test.dpr.comment.dpr_ij_force.muscular_maintenance_work", "Travail d'entretien musculaire des ischio-jambiers.", "tests", "fr");
t.add("test.dpr.comment.dpr_adductors_force.muscular_maintenance_work", "Travail d'entretien musculaire des adducteurs.", "tests", "fr");
t.add("test.dpr.comment.dpr_shirado.muscular_maintenance_work", "Travail d'entretien musculaire des abdominaux.", "tests", "fr");
t.add("test.dpr.comment.dpr_sorensen.muscular_maintenance_work", "Travail d'entretien musculaire des lombaires.", "tests", "fr");
t.add("test.dpr.comment.dpr_dc.muscular_maintenance_work", "Travail d'entretien musculaires des muscles ant\u00e9rieurs.", "tests", "fr");
t.add("test.dpr.comment.dpr_tirage.muscular_maintenance_work", "Travail d\u2019entretien musculaire des muscles post\u00e9rieurs du tronc.", "tests", "fr");
t.add("test.dpr.comment.dpr_ri1.muscular_maintenance_work", "Travail d'entretien musculaire des rotateurs internes.", "tests", "fr");
t.add("test.dpr.comment.dpr_re1.muscular_maintenance_work", "Travail d'entretien musculaire des rotateurs externes.", "tests", "fr");
t.add("exercise.custom_name.dpr_shirado", "Abdo", "tests", "fr");
t.add("exercise.custom_name.dpr_sorensen", "Spinaux", "tests", "fr");
t.add("test.exercise.exercise_name.single_leg_landing", "Single leg landing", "tests", "fr");
t.add("test.exercise.exercise_name.single_hop", "Single hop", "tests", "fr");
t.add("test.exercise.exercise_name.triple_hop", "Triple hop", "tests", "fr");
t.add("test.exercise.exercise_name.side_hop_15_secondes", "Side hop 15 secondes", "tests", "fr");
t.add("test.exercise.exercise_name.side_hop_30_secondes", "Side hop 30 secondes", "tests", "fr");
t.add("test.exercise.exercise_name.crossover_hop", "Crossover hop", "tests", "fr");
t.add("test.exercise.exercise_name.illinois_modifie_1s", "Temps 1 (s)", "tests", "fr");
t.add("test.exercise.exercise_name.illinois_modifie_2s", "Temps 2 (s)", "tests", "fr");
t.add("test.exercise.exercise_name.excessive_movement_of_bars_to_balance", "Mouvement excessif des bras pour s'\u00e9quilibrer", "tests", "fr");
t.add("test.exercise.exercise_name.inclined_in_different_positions", "Inclin\u00e9 dans diff\u00e9rents positions", "tests", "fr");
t.add("test.exercise.exercise_name.loss_of_the_horizontal_plane", "Perte du plan horizontal", "tests", "fr");
t.add("test.exercise.exercise_name.flip_or_excessive_rotation", "Bascule ou rotation excessive", "tests", "fr");
t.add("test.exercise.exercise_name.hip_adduction", "Adduction de hanche", "tests", "fr");
t.add("test.exercise.exercise_name.not_maintaining_the_neutral_position_of_the_contralateral_thigh", "Non maintien de la position neutre de la cuisse controlat\u00e9rale", "tests", "fr");
t.add("test.exercise.exercise_name.ball_head_oriented_towards_the_2nd_toe", "Rotule orient\u00e9e vers le 2<sup>\u00e8me<\/sup> orteil", "tests", "fr");
t.add("test.exercise.exercise_name.ball_joint_oriented_towards_the_inside_of_the_foot", "Rotule orient\u00e9e vers l'int\u00e9rieur du pied", "tests", "fr");
t.add("test.exercise.exercise_name.rebalance_with_the_other", "R\u00e9\u00e9quilibrer avec l'autre", "tests", "fr");
t.add("test.exercise.exercise_name.the_receiving_leg_wobbles_noticeably", "La jambe de r\u00e9ception vacille sensiblement", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_1", "Pensez-vous pouvoir pratiquer votre sport au m\u00eame niveau qu\u2019auparavant ?", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_2", "Pensez-vous que vous pourriez vous blesser de nouveau le genou si vous repreniez le sport ?", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_3", "\u00cates-vous inquiet \u00e0 l\u2019id\u00e9e de reprendre votre sport ?", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_4", "Pensez-vous que votre genou sera stable lors de votre pratique sportive ?", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_5", "Pensez-vous pouvoir pratiquer votre sport sans vous soucier de votre genou ?", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_6", "Etes-vous frustr\u00e9 de devoir tenir compte de votre genou lors de votre pratique sportive ?", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_7", "Craignez-vous de vous blesser de nouveau le genou lors de votre pratique sportive ?", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_8", "Pensez-vous que votre genou peut r\u00e9sister aux contraintes ?", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_9", "Avez-vous peur de vous reblesser accidentellement le genou lors de votre pratique sportive ?", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_10", "Est-ce que l\u2019id\u00e9e de devoir \u00e9ventuellement vous faire r\u00e9op\u00e9rer ou r\u00e9\u00e9duquer vous emp\u00eache de pratiquer votre sport ?", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_11", "Etes-vous confiant en votre capacit\u00e9 \u00e0 bien pratiquer votre sport ?", "tests", "fr");
t.add("test.exercise.exercise_name.aclrsi_question_12", "Vous sentez-vous d\u00e9tendu \u00e0 l\u2019id\u00e9e de pratiquer votre sport ?", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_1", "Capacit\u00e9 de stabilisation unipodal en statique avec dissociation lat\u00e9rale", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_2", "Capacit\u00e9 de travail du psoas", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_3", "Capacit\u00e9 des stabilisateurs de hanche en dynamique", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_4", "Capacit\u00e9 de travail des ischio-jambiers (course interne)", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_5", "Capacit\u00e9 de travail des abducteurs de hanche", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_6", "Capacit\u00e9 de stabilisation unipodal en statique avec r\u00e9partition des charges", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_7", "Capacit\u00e9 du triceps sural \u00e0 maintenir la position", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_8", "Capacit\u00e9 du contr\u00f4le excentrique du quadriceps", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_9", "Capacit\u00e9 des stabilisateurs de hanche en dynamique et en charge", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_10", "Capacit\u00e9 de stimulation des fibres rapides", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_11", "Technique d'appui en dynamique dans l'axe", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_12", "Capacit\u00e9 de stabilisation unipodal en dynamique", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_13", "Capacit\u00e9 des stabilisateurs de hanche en dynamique et en charge", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_14", "Capacit\u00e9 concentrique et excentrique des ischio-jambiers", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_15", "Capacit\u00e9 de stimulation des fibres rapides", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_16", "Technique d'appui en dynamique en avant\/arri\u00e8re", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_17", "Aper\u00e7u de l'appr\u00e9hension", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_18", "Capacit\u00e9 de force du quadriceps en concentrique sur un angle fonctionnel (90\u00b0)", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_19", "Capacit\u00e9 de force du quadriceps en concentrique sur un angle sportif (120\u00b0)", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_20", "Capacit\u00e9 excentrique du quadriceps", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_21", "Capacit\u00e9 de stimulation des fibres rapides", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_22", "Technique d\u2019appuis en dynamique en lat\u00e9ral", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_closed_eyes_unipodal_stability", "Stabilit\u00e9 unipodale yeux ferm\u00e9s", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_unipodal_knee_flexion", "Flexion de genou unipodale", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_squat", "Accroupissement", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_proprioception_basin", "Proprioception bassin", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_posterior_muscle_chain", "Cha\u00eene musculaire post\u00e9rieure", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_dip_shoulders", "Epaules dips", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_quadriceps_force", "Quadriceps", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_ij_force", "IJ", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_adductors_force", "Adducteurs", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_abductors", "Abducteurs", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_shirado", "Shirado", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_sorensen", "Sorensen", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_dc", "DC", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_tirage", "Tirage", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_ri1", "RI1", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_re1", "RE1", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_quadriceps_flexibility", "Quadriceps", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_ij_flexibility", "IJ", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_adductors_flexibility", "Adducteurs", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_calves", "Mollets", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_fingers_ground", "Doigts \/ Sol", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_psoas", "PSOAS", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_internal_rotation", "Rotation interne", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_candlestick", "Chandelier", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_retropulsion", "R\u00e9tropulsion", "tests", "fr");
t.add("test.exercise.exercise_name.dpr_antepulsion", "Ant\u00e9pulsion", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_1_name_1", "D\u00e9faut", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_2_name_1", "Utilisation des bras", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_2_name_2", "Alignement du buste", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_2_name_3", "Niveau du bassin", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_2_name_4", "Mouvement de la cuisse", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_2_name_5", "Position du genou", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_2_name_6", "Stabilit\u00e9 de la posture", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_3_name_1", "D\u00e9faut", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_4_name_1", "Circuits de mise en route", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_4_name_2", "", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_4_name_3", "Circuits de renforcement", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_4_name_4", "Quadriceps 1", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_4_name_5", "Ischio-jambiers", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_4_name_6", "Quadriceps 2", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_1", "Tests fonctionnels", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_2", "Membres inf\u00e9rieurs", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_3", "Tronc", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_4", "Membres sup\u00e9rieurs", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_5", "Tests de force", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_6", "Membres inf\u00e9rieurs", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_7", "Tronc", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_8", "Membres sup\u00e9rieurs", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_9", "Tests de souplesse", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_10", "Membres inf\u00e9rieurs", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_11", "Tronc", "tests", "fr");
t.add("test.exercise_group.exercise_group_name.test_5_name_12", "Membres sup\u00e9rieurs", "tests", "fr");
t.add("test.exercise.exercise_name.tar_question_23", "Capacit\u00e9 de verrouillage du quadriceps (avec activation du vaste medial)", "tests", "fr");
t.add("test.graph_title.ratio_abdo_spinaux", "test.graph_title.ratio_abdo_spinaux", "tests", "fr");
t.add("test.normal", "Normaux", "tests", "fr");
t.add("test.insufficient", "Insuffisant", "tests", "fr");
t.add("test.risky", "Risque de blessure", "tests", "fr");
t.add("test.formula", "formule= score\/52*100", "tests", "fr");
t.add("test.exercise.maintenance", "Travail d'entretien", "tests", "fr");
t.add("test.exercise.correction", "Travail de corrections", "tests", "fr");
t.add("test.exercise.specific", "R\u00e9athl\u00e9tisation sp\u00e9cifique", "tests", "fr");
t.add("test.dpr.comment.ratio_ij_quadriceps.empty", "", "tests", "fr");
t.add("test.dpr.comment.ratio_adductors_abductors.empty", "", "tests", "fr");
t.add("test.dpr.comment.ratio_re_ri.empty", "", "tests", "fr");
t.add("test.dpr.comment.ratio_tirage_dc.empty", "", "tests", "fr");
t.add("test.dpr.comment.dpr_quadriceps_force.empty", "", "tests", "fr");
t.add("test.dpr.comment.dpr_ij_force.empty", "", "tests", "fr");
t.add("test.dpr.comment.dpr_adductors_force.empty", "", "tests", "fr");
t.add("test.dpr.comment.dpr_abductors.empty", "", "tests", "fr");
t.add("test.dpr.comment.dpr_ri1.empty", "", "tests", "fr");
t.add("test.dpr.comment.dpr_re1.empty", "", "tests", "fr");
t.add("test.dpr.comment.ratio_abdo_spinaux.empty", "", "tests", "fr");
t.add("test.exercise.exercise_name.rotateur_externe", "Rotateurs Externes", "tests", "fr");
t.add("test.exercise.exercise_name.rotateur_interne", "Rotateurs Internes", "tests", "fr");
t.add("test.exercise.exercise_name.y_balance_test_medial", "YBT M", "tests", "fr");
t.add("test.exercise.exercise_name.y_balance_test_infero_lateral", "YBT IL", "tests", "fr");
t.add("test.exercise.exercise_name.y_balance_test_supero_lateral", "YBT SL", "tests", "fr");
t.add("test.exercise.exercise_name.usspt", "USSPT", "tests", "fr");
t.add("test.exercise.exercise_name.ckcuest", "CKCUEST", "tests", "fr");
t.add("test.scaleSIRSI", "\u00c9chelle SI - RSI", "tests", "fr");
t.add("test.sirsi_test_results", "\u00c9chelle SI - RSI", "tests", "fr");
t.add("test.sstarts.globalComment", "Commentaire global", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_1", "Pensez-vous pouvoir pratiquer votre sport au m\u00eame niveau qu\u2019auparavant ?", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_2", "Pensez-vous que vous pourriez vous blesser de nouveau l\u2019\u00e9paule si vous repreniez le sport ?", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_3", "\u00cates-vous inquiet \u00e0 l\u2019id\u00e9e de reprendre votre sport ?", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_4", "Pensez-vous que votre \u00e9paule sera stable lors de votre pratique sportive ?", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_5", "Pensez-vous pouvoir pratiquer votre sport sans vous soucier de votre \u00e9paule ?", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_6", "Etes-vous frustr\u00e9 de devoir tenir compte de votre \u00e9paule lors de votre pratique sportive ?", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_7", "Craignez-vous de vous blesser de nouveau l\u2019\u00e9paule lors de votre pratique sportive ?", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_8", "Pensez-vous que votre \u00e9paule peut r\u00e9sister aux contraintes ?", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_9", "Avez-vous peur de vous reblesser accidentellement l\u2019\u00e9paule lors de votre pratique sportive ?", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_10", "Est-ce que l\u2019id\u00e9e de devoir \u00e9ventuellement vous faire r\u00e9op\u00e9rer ou r\u00e9\u00e9duquer vous emp\u00eache de pratiquer votre sport ?", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_11", "\u00cates-vous confiant en votre capacit\u00e9 \u00e0 bien pratiquer votre sport ?", "tests", "fr");
t.add("test.exercise.exercise_name.sirsi_question_12", "Vous sentez-vous d\u00e9tendu \u00e0 l'id\u00e9e de pratiquer votre sport ?", "tests", "fr");
t.add("test.sirsi_questionaire_description", "Merci de r\u00e9pondre aux questions suivantes concernant le sport principal que vous pratiquiez avant l\u2019accident. Pour chaque question, cochez la case entre les deux extr\u00eames selon ce qui vous parait correspondre le mieux \u00e0 la situation actuelle de votre \u00e9paule.", "tests", "fr");
t.add("test.sirsi_edge.not_at_all_sure", "Pas du tout s\u00fbr ", "tests", "fr");
t.add("test.sirsi_edge.totally_sure", "Totalement s\u00fbr", "tests", "fr");
t.add("test.sirsi_edge.extremely_likely", "Extr\u00eamement probable", "tests", "fr");
t.add("test.sirsi_edge.not_at_all_likely", "Pas du tout probable", "tests", "fr");
t.add("test.sirsi_edge.extremely_worried", "Extr\u00eamement inquiet", "tests", "fr");
t.add("test.sirsi_edge.not_at_all_worried", "Pas du tout inquiet", "tests", "fr");
t.add("test.sirsi_edge.extremely_frustrated", "Extr\u00eamement frustr\u00e9", "tests", "fr");
t.add("test.sirsi_edge.not_at_all_frustrated", "Pas du tout frustr\u00e9", "tests", "fr");
t.add("test.sirsi_edge.extreme_fear", "Crainte extr\u00eame", "tests", "fr");
t.add("test.sirsi_edge.no_fear", "Aucune crainte", "tests", "fr");
t.add("test.sirsi_edge.very_scared", "Tr\u00e8s peur", "tests", "fr");
t.add("test.sirsi_edge.not_at_all_scared", "Pas du tout peur", "tests", "fr");
t.add("test.sirsi_edge.all_the_time", "Tout le temps", "tests", "fr");
t.add("test.sirsi_edge.at_no_time", "\u00c0 aucun moment", "tests", "fr");
t.add("test.sirsi_edge.not_at_all_confident", "Pas du tout confiant", "tests", "fr");
t.add("test.sirsi_edge.totally_confident", "Totalement confiant", "tests", "fr");
t.add("test.sirsi_edge.not_at_all_relaxed", "Pas du tout d\u00e9tendu", "tests", "fr");
t.add("test.sirsi_edge.totally_relaxed", "Totalement d\u00e9tendu", "tests", "fr");
})(Translator);
