(function (t) {
// en
t.add("user.change_password", "Password change", "user", "en");
t.add("user.account_created", "Your new account has been successfully created", "user", "en");
t.add("user.credentials", "Your credentials", "user", "en");
t.add("user.password_changing_required_after_first_login", "A new password will be created after the first login.", "user", "en");
t.add("user.email", "email adress", "user", "en");
t.add("user.password", "Password", "user", "en");
t.add("user.link_to_your_account", "You can acess your accounts", "user", "en");
t.add("user.password_change_required", "Please create a new password", "user", "en");
})(Translator);
