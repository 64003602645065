(function (t) {
// be
t.add("An authentication exception occurred.", "\u041f\u0430\u043c\u044b\u043b\u043a\u0430 \u0430\u045e\u0442\u044d\u043d\u0442\u044b\u0444\u0456\u043a\u0430\u0446\u044b\u0456.", "security", "be");
t.add("Authentication credentials could not be found.", "\u0414\u0430\u0434\u0437\u0435\u043d\u044b\u044f \u0430\u045e\u0442\u044d\u043d\u0442\u044b\u0444\u0456\u043a\u0430\u0446\u044b\u0456 \u043d\u0435 \u0437\u043d\u043e\u0439\u0434\u0437\u0435\u043d\u044b.", "security", "be");
t.add("Authentication request could not be processed due to a system problem.", "\u0417\u0430\u043f\u044b\u0442 \u0430\u045e\u0442\u044d\u043d\u0442\u044b\u0444\u0456\u043a\u0430\u0446\u044b\u0456 \u043d\u0435 \u043c\u043e\u0436\u0430 \u0431\u044b\u0446\u044c \u0430\u043f\u0440\u0430\u0446\u0430\u0432\u0430\u043d\u044b \u045e \u0441\u0443\u0432\u044f\u0437\u0456 \u0437 \u043f\u0440\u0430\u0431\u043b\u0435\u043c\u0430\u0439 \u0443 \u0441\u0456\u0441\u0442\u044d\u043c\u0435.", "security", "be");
t.add("Invalid credentials.", "\u041d\u0435\u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b\u044f \u0434\u0430\u0434\u0437\u0435\u043d\u044b\u044f \u0430\u045e\u0442\u044d\u043d\u0442\u044b\u0444\u0456\u043a\u0430\u0446\u044b\u0456.", "security", "be");
t.add("Cookie has already been used by someone else.", "\u041d\u0435\u0445\u0442\u0430 \u0456\u043d\u0448\u044b \u045e\u0436\u043e \u0432\u044b\u043a\u0430\u0440\u044b\u0441\u0442\u0430\u045e \u0433\u044d\u0442\u044b\u044f \u043a\u0443\u043a\u0456 (cookie).", "security", "be");
t.add("Not privileged to request the resource.", "\u0410\u0434\u0441\u0443\u0442\u043d\u0456\u0447\u0430\u044e\u0446\u044c \u043f\u0440\u0430\u0432\u044b \u043d\u0430 \u0437\u0430\u043f\u044b\u0442 \u0433\u044d\u0442\u0430\u0433\u0430 \u0440\u044d\u0441\u0443\u0440\u0441\u0443.", "security", "be");
t.add("Invalid CSRF token.", "\u041d\u0435\u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u044b CSRF-\u0442\u043e\u043a\u0435\u043d.", "security", "be");
t.add("No authentication provider found to support the authentication token.", "\u041d\u0435 \u0437\u043d\u043e\u0439\u0434\u0437\u0435\u043d \u043f\u0440\u0430\u0432\u0430\u0439\u0434\u0430\u0440 \u0430\u045e\u0442\u044d\u043d\u0442\u044b\u0444\u0456\u043a\u0430\u0446\u044b\u0456, \u044f\u043a\u0456 \u043c\u043e\u0436\u0430 \u043f\u0430\u0434\u0442\u0440\u044b\u043c\u043b\u0456\u0432\u0430\u0446\u044c \u0433\u044d\u0442\u044b \u0442\u043e\u043a\u0435\u043d \u0430\u045e\u0442\u044d\u043d\u0442\u044b\u0444\u0456\u043a\u0430\u0446\u044b\u0456.", "security", "be");
t.add("No session available, it either timed out or cookies are not enabled.", "\u0421\u0435\u0441\u0456\u044f \u043d\u0435 \u0434\u0430\u0441\u0442\u0443\u043f\u043d\u0430, \u044f\u0435 \u0447\u0430\u0441 \u0441\u043a\u043e\u043d\u0447\u044b\u045e\u0441\u044f, \u0430\u0431\u043e \u043a\u0443\u043a\u0456 (cookies) \u0432\u044b\u043a\u043b\u044e\u0447\u0430\u043d\u044b.", "security", "be");
t.add("No token could be found.", "\u0422\u043e\u043a\u0435\u043d \u043d\u0435 \u0437\u043d\u043e\u0439\u0434\u0437\u0435\u043d.", "security", "be");
t.add("Username could not be found.", "\u0406\u043c\u044f \u043a\u0430\u0440\u044b\u0441\u0442\u0430\u043b\u044c\u043d\u0456\u043a\u0430 \u043d\u0435 \u0437\u043d\u043e\u0439\u0434\u0437\u0435\u043d\u0430.", "security", "be");
t.add("Account has expired.", "\u0421\u043a\u043e\u043d\u0447\u044b\u045e\u0441\u044f \u0442\u044d\u0440\u043c\u0456\u043d \u0434\u0437\u0435\u044f\u043d\u043d\u044f \u0430\u043a\u0430\u045e\u043d\u0442\u0430.", "security", "be");
t.add("Credentials have expired.", "\u0421\u043a\u043e\u043d\u0447\u044b\u045e\u0441\u044f \u0442\u044d\u0440\u043c\u0456\u043d \u0434\u0437\u0435\u044f\u043d\u043d\u044f \u0434\u0430\u0434\u0437\u0435\u043d\u044b\u0445 \u0430\u045e\u0442\u044d\u043d\u0442\u044b\u0444\u0456\u043a\u0430\u0446\u044b\u0456.", "security", "be");
t.add("Account is disabled.", "\u0410\u043a\u0430\u045e\u043d\u0442 \u0430\u0434\u043a\u043b\u044e\u0447\u0430\u043d.", "security", "be");
t.add("Account is locked.", "\u0410\u043a\u0430\u045e\u043d\u0442 \u0437\u0430\u0431\u043b\u0430\u043a\u0456\u0440\u0430\u0432\u0430\u043d.", "security", "be");
t.add("Too many failed login attempts, please try again later.", "\u0417\u0430\u0448\u043c\u0430\u0442 \u043d\u044f\u045e\u0434\u0430\u043b\u044b\u0445 \u0441\u043f\u0440\u043e\u0431 \u0443\u0432\u0430\u0445\u043e\u0434\u0443, \u043a\u0430\u043b\u0456 \u043b\u0430\u0441\u043a\u0430, \u043f\u0430\u0441\u043f\u0440\u0430\u0431\u0443\u0439\u0446\u0435 \u043f\u0430\u0437\u043d\u0435\u0439.", "security", "be");
t.add("Invalid or expired login link.", "\u0421\u043f\u0430\u0441\u044b\u043b\u043a\u0430 \u0434\u043b\u044f \u045e\u0432\u0430\u0445\u043e\u0434\u0443 \u043d\u0435\u0441\u0430\u043f\u0440\u0430\u045e\u0434\u043d\u0430\u044f \u0430\u0431\u043e \u043f\u0440\u0430\u0442\u044d\u0440\u043c\u0456\u043d\u0430\u0432\u0430\u043d\u0430\u044f.", "security", "be");
t.add("Too many failed login attempts, please try again in %minutes% minute.", "\u0417\u0430\u043d\u0430\u0434\u0442\u0430 \u0448\u043c\u0430\u0442 \u043d\u044f\u045e\u0434\u0430\u043b\u044b\u0445 \u0441\u043f\u0440\u043e\u0431 \u0443\u0432\u0430\u0445\u043e\u0434\u0443 \u045e \u0441\u0456\u0441\u0442\u044d\u043c\u0443, \u043f\u0430\u0441\u043f\u0440\u0430\u0431\u0443\u0439\u0446\u0435 \u0441\u043f\u0440\u043e\u0431\u0443 \u043f\u0440\u0430\u0437 %minutes% \u0445\u0432\u0456\u043b\u0456\u043d\u0443.", "security", "be");
t.add("Too many failed login attempts, please try again in %minutes% minutes.", "\u0417\u0430\u043d\u0430\u0434\u0442\u0430 \u0448\u043c\u0430\u0442 \u043d\u044f\u045e\u0434\u0430\u043b\u044b\u0445 \u0441\u043f\u0440\u043e\u0431 \u0443\u0432\u0430\u0445\u043e\u0434\u0443 \u045e \u0441\u0456\u0441\u0442\u044d\u043c\u0443, \u043f\u0430\u0441\u043f\u0440\u0430\u0431\u0443\u0439\u0446\u0435 \u0441\u043f\u0440\u043e\u0431\u0443 \u043f\u0440\u0430\u0437 %minutes% \u0445\u0432\u0456\u043b\u0456\u043d.", "security", "be");
})(Translator);
