(function (t) {
// ca
t.add("An authentication exception occurred.", "Ha succe\u00eft un error d'autenticaci\u00f3.", "security", "ca");
t.add("Authentication credentials could not be found.", "No s'han trobat les credencials d'autenticaci\u00f3.", "security", "ca");
t.add("Authentication request could not be processed due to a system problem.", "La solicitud d'autenticaci\u00f3 no s'ha pogut processar per un problema del sistema.", "security", "ca");
t.add("Invalid credentials.", "Credencials no v\u00e0lides.", "security", "ca");
t.add("Cookie has already been used by someone else.", "La cookie ja ha estat utilitzada per una altra persona.", "security", "ca");
t.add("Not privileged to request the resource.", "No t\u00e9 privilegis per solicitar el recurs.", "security", "ca");
t.add("Invalid CSRF token.", "Token CSRF no v\u00e0lid.", "security", "ca");
t.add("No authentication provider found to support the authentication token.", "No s'ha trobat un prove\u00efdor d'autenticaci\u00f3 que suporti el token d'autenticaci\u00f3.", "security", "ca");
t.add("No session available, it either timed out or cookies are not enabled.", "No hi ha sessi\u00f3 disponible, ha expirat o les cookies no estan habilitades.", "security", "ca");
t.add("No token could be found.", "No s'ha trobat cap token.", "security", "ca");
t.add("Username could not be found.", "No s'ha trobat el nom d'usuari.", "security", "ca");
t.add("Account has expired.", "El compte ha expirat.", "security", "ca");
t.add("Credentials have expired.", "Les credencials han expirat.", "security", "ca");
t.add("Account is disabled.", "El compte est\u00e0 deshabilitat.", "security", "ca");
t.add("Account is locked.", "El compte est\u00e0 bloquejat.", "security", "ca");
t.add("Too many failed login attempts, please try again later.", "Massa intents d'inici de sessi\u00f3 fallits, torneu-ho a provar m\u00e9s tard.", "security", "ca");
t.add("Invalid or expired login link.", "Enlla\u00e7 d'inici de sessi\u00f3 no v\u00e0lid o caducat.", "security", "ca");
t.add("Too many failed login attempts, please try again in %minutes% minute.", "Massa intents d'inici de sessi\u00f3 fallits, torneu-ho a provar en %minutes% minut.", "security", "ca");
t.add("Too many failed login attempts, please try again in %minutes% minutes.", "Massa intents d'inici de sessi\u00f3 fallits, torneu-ho a provar en %minutes% minuts.", "security", "ca");
})(Translator);
