(function (t) {
// el
t.add("This value should be false.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c8\u03b5\u03c5\u03b4\u03ae\u03c2.", "validators", "el");
t.add("This value should be true.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03b1\u03bb\u03b7\u03b8\u03ae\u03c2.", "validators", "el");
t.add("This value should be of type {{ type }}.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c4\u03cd\u03c0\u03bf\u03c5 {{ type }}.", "validators", "el");
t.add("This value should be blank.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03ba\u03b5\u03bd\u03ae.", "validators", "el");
t.add("The value you selected is not a valid choice.", "\u0397 \u03c4\u03b9\u03bc\u03ae \u03c0\u03bf\u03c5 \u03b5\u03c0\u03b9\u03bb\u03ad\u03c7\u03b8\u03b7\u03ba\u03b5 \u03b4\u03b5\u03bd \u03b1\u03bd\u03c4\u03b9\u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af \u03c3\u03b5 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b5\u03c0\u03b9\u03bb\u03bf\u03b3\u03ae.", "validators", "el");
t.add("You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.", "\u03a0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03c4\u03bf\u03c5\u03bb\u03ac\u03c7\u03b9\u03c3\u03c4\u03bf\u03bd {{ limit }} \u03b5\u03c0\u03b9\u03bb\u03bf\u03b3\u03ae.|\u03a0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03c4\u03bf\u03c5\u03bb\u03ac\u03c7\u03b9\u03c3\u03c4\u03bf\u03bd {{ limit }} \u03b5\u03c0\u03b9\u03bb\u03bf\u03b3\u03ad\u03c2.", "validators", "el");
t.add("You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.", "\u03a0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03c4\u03bf \u03c0\u03bf\u03bb\u03cd {{ limit }} \u03b5\u03c0\u03b9\u03bb\u03bf\u03b3\u03ae.|\u03a0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03c4\u03bf \u03c0\u03bf\u03bb\u03cd {{ limit }} \u03b5\u03c0\u03b9\u03bb\u03bf\u03b3\u03ad\u03c2.", "validators", "el");
t.add("One or more of the given values is invalid.", "\u039c\u03b9\u03b1 \u03ae \u03c0\u03b5\u03c1\u03b9\u03c3\u03c3\u03cc\u03c4\u03b5\u03c1\u03b5\u03c2 \u03c4\u03b9\u03bc\u03ad\u03c2 \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b5\u03c2.", "validators", "el");
t.add("This field was not expected.", "\u0391\u03c5\u03c4\u03cc \u03c4\u03bf \u03c0\u03b5\u03b4\u03af\u03bf \u03b4\u03b5\u03bd \u03ae\u03c4\u03b1\u03bd \u03b1\u03bd\u03b1\u03bc\u03b5\u03bd\u03cc\u03bc\u03b5\u03bd\u03bf.", "validators", "el");
t.add("This field is missing.", "\u039b\u03b5\u03af\u03c0\u03b5\u03b9 \u03b1\u03c5\u03c4\u03cc \u03c4\u03bf \u03c0\u03b5\u03b4\u03af\u03bf.", "validators", "el");
t.add("This value is not a valid date.", "\u0397 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b1\u03bd\u03c4\u03b9\u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af \u03c3\u03b5 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b7\u03bc\u03b5\u03c1\u03bf\u03bc\u03b7\u03bd\u03af\u03b1.", "validators", "el");
t.add("This value is not a valid datetime.", "\u0397 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b1\u03bd\u03c4\u03b9\u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af \u03c3\u03b5 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b7\u03bc\u03b5\u03c1\u03bf\u03bc\u03b7\u03bd\u03af\u03b1 \u03ba\u03b1\u03b9 \u03ce\u03c1\u03b1.", "validators", "el");
t.add("This value is not a valid email address.", "\u0397 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b1\u03bd\u03c4\u03b9\u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af \u03c3\u03b5 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf email.", "validators", "el");
t.add("The file could not be found.", "\u03a4\u03bf \u03b1\u03c1\u03c7\u03b5\u03af\u03bf \u03b4\u03b5 \u03bc\u03c0\u03bf\u03c1\u03b5\u03af \u03bd\u03b1 \u03b2\u03c1\u03b5\u03b8\u03b5\u03af.", "validators", "el");
t.add("The file is not readable.", "\u03a4\u03bf \u03b1\u03c1\u03c7\u03b5\u03af\u03bf \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03b1\u03bd\u03b1\u03b3\u03bd\u03ce\u03c3\u03b9\u03bc\u03bf.", "validators", "el");
t.add("The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.", "\u03a4\u03bf \u03b1\u03c1\u03c7\u03b5\u03af\u03bf \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b5\u03b3\u03ac\u03bb\u03bf ({{ size }} {{ suffix }}). \u03a4\u03bf \u03bc\u03ad\u03b3\u03b9\u03c3\u03c4\u03bf \u03b5\u03c0\u03b9\u03c4\u03c1\u03b5\u03c0\u03c4\u03cc \u03bc\u03ad\u03b3\u03b5\u03b8\u03bf\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 {{ limit }} {{ suffix }}.", "validators", "el");
t.add("The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.", "\u039f \u03c4\u03cd\u03c0\u03bf\u03c2 mime \u03c4\u03bf\u03c5 \u03b1\u03c1\u03c7\u03b5\u03af\u03bf\u03c5 \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2 ({{ type }}). \u039f\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03b9 \u03c4\u03cd\u03c0\u03bf\u03b9 mime \u03b5\u03af\u03bd\u03b1\u03b9 {{ types }}.", "validators", "el");
t.add("This value should be {{ limit }} or less.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03ad\u03c0\u03c1\u03b5\u03c0\u03b5 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 {{ limit }} \u03ae \u03bb\u03b9\u03b3\u03cc\u03c4\u03b5\u03c1\u03bf.", "validators", "el");
t.add("This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b5\u03b3\u03ac\u03bb\u03b7. \u0398\u03b1 \u03ad\u03c0\u03c1\u03b5\u03c0\u03b5 \u03bd\u03b1 \u03ad\u03c7\u03b5\u03b9 {{ limit }} \u03c7\u03b1\u03c1\u03b1\u03ba\u03c4\u03ae\u03c1\u03b1 \u03ae \u03bb\u03b9\u03b3\u03cc\u03c4\u03b5\u03c1\u03bf.|\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b5\u03b3\u03ac\u03bb\u03b7. \u0398\u03b1 \u03ad\u03c0\u03c1\u03b5\u03c0\u03b5 \u03bd\u03b1 \u03ad\u03c7\u03b5\u03b9 {{ limit }} \u03c7\u03b1\u03c1\u03b1\u03ba\u03c4\u03ae\u03c1\u03b5\u03c2 \u03ae \u03bb\u03b9\u03b3\u03cc\u03c4\u03b5\u03c1\u03bf.", "validators", "el");
t.add("This value should be {{ limit }} or more.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03ad\u03c0\u03c1\u03b5\u03c0\u03b5 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 {{ limit }} \u03ae \u03c0\u03b5\u03c1\u03b9\u03c3\u03c3\u03cc\u03c4\u03b5\u03c1\u03bf.", "validators", "el");
t.add("This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b9\u03ba\u03c1\u03ae. \u0398\u03b1 \u03ad\u03c0\u03c1\u03b5\u03c0\u03b5 \u03bd\u03b1 \u03ad\u03c7\u03b5\u03b9 {{ limit }} \u03c7\u03b1\u03c1\u03b1\u03ba\u03c4\u03ae\u03c1\u03b1 \u03ae \u03c0\u03b5\u03c1\u03b9\u03c3\u03c3\u03cc\u03c4\u03b5\u03c1\u03bf.|\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b9\u03ba\u03c1\u03ae. \u0398\u03b1 \u03ad\u03c0\u03c1\u03b5\u03c0\u03b5 \u03bd\u03b1 \u03ad\u03c7\u03b5\u03b9 {{ limit }} \u03c7\u03b1\u03c1\u03b1\u03ba\u03c4\u03ae\u03c1\u03b5\u03c2 \u03ae \u03c0\u03b5\u03c1\u03b9\u03c3\u03c3\u03cc\u03c4\u03b5\u03c1\u03bf.", "validators", "el");
t.add("This value should not be blank.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03ba\u03b5\u03bd\u03ae.", "validators", "el");
t.add("This value should not be null.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03bc\u03b7\u03b4\u03b5\u03bd\u03b9\u03ba\u03ae.", "validators", "el");
t.add("This value should be null.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03bc\u03b7\u03b4\u03b5\u03bd\u03b9\u03ba\u03ae.", "validators", "el");
t.add("This value is not valid.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7.", "validators", "el");
t.add("This value is not a valid time.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b1\u03bd\u03c4\u03b9\u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af \u03c3\u03b5 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03ce\u03c1\u03b1.", "validators", "el");
t.add("This value is not a valid URL.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b1\u03bd\u03c4\u03b9\u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af \u03c3\u03b5 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf URL.", "validators", "el");
t.add("The two values should be equal.", "\u039f\u03b9 \u03b4\u03cd\u03bf \u03c4\u03b9\u03bc\u03ad\u03c2 \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03af\u03b4\u03b9\u03b5\u03c2.", "validators", "el");
t.add("The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.", "\u03a4\u03bf \u03b1\u03c1\u03c7\u03b5\u03af\u03bf \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b5\u03b3\u03ac\u03bb\u03bf. \u03a4\u03bf \u03bc\u03ad\u03b3\u03b9\u03c3\u03c4\u03bf \u03b5\u03c0\u03b9\u03c4\u03c1\u03b5\u03c0\u03c4\u03cc \u03bc\u03ad\u03b3\u03b5\u03b8\u03bf\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 {{ limit }} {{ suffix }}.", "validators", "el");
t.add("The file is too large.", "\u03a4\u03bf \u03b1\u03c1\u03c7\u03b5\u03af\u03bf \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b5\u03b3\u03ac\u03bb\u03bf.", "validators", "el");
t.add("The file could not be uploaded.", "\u03a4\u03bf \u03b1\u03c1\u03c7\u03b5\u03af\u03bf \u03b4\u03b5 \u03bc\u03c0\u03bf\u03c1\u03b5\u03af \u03bd\u03b1 \u03b1\u03bd\u03ad\u03b2\u03b5\u03b9.", "validators", "el");
t.add("This value should be a valid number.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03bd\u03b1\u03c2 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2 \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc\u03c2.", "validators", "el");
t.add("This file is not a valid image.", "\u03a4\u03bf \u03b1\u03c1\u03c7\u03b5\u03af\u03bf \u03b4\u03b5\u03bd \u03b1\u03c0\u03bf\u03c4\u03b5\u03bb\u03b5\u03af \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b1.", "validators", "el");
t.add("This is not a valid IP address.", "\u0391\u03c5\u03c4\u03cc \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03bc\u03b9\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b4\u03b9\u03b5\u03cd\u03b8\u03c5\u03bd\u03c3\u03b7 IP.", "validators", "el");
t.add("This value is not a valid language.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b1\u03bd\u03c4\u03b9\u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af \u03c3\u03b5 \u03bc\u03b9\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b3\u03bb\u03ce\u03c3\u03c3\u03b1.", "validators", "el");
t.add("This value is not a valid locale.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b1\u03bd\u03c4\u03b9\u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af \u03c3\u03b5 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc \u03c4\u03bf\u03c0\u03bf\u03b8\u03b5\u03c3\u03af\u03b1\u03c2.", "validators", "el");
t.add("This value is not a valid country.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b1\u03bd\u03c4\u03b9\u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af \u03c3\u03b5 \u03bc\u03b9\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03c7\u03ce\u03c1\u03b1.", "validators", "el");
t.add("This value is already used.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03b5\u03af\u03c4\u03b1\u03b9 \u03ae\u03b4\u03b7.", "validators", "el");
t.add("The size of the image could not be detected.", "\u03a4\u03bf \u03bc\u03ad\u03b3\u03b5\u03b8\u03bf\u03c2 \u03c4\u03b7\u03c2 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b1\u03c2 \u03b4\u03b5\u03bd \u03ae\u03c4\u03b1\u03bd \u03b4\u03c5\u03bd\u03b1\u03c4\u03cc \u03bd\u03b1 \u03b1\u03bd\u03b9\u03c7\u03bd\u03b5\u03c5\u03b8\u03b5\u03af.", "validators", "el");
t.add("The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.", "\u03a4\u03bf \u03c0\u03bb\u03ac\u03c4\u03bf\u03c2 \u03c4\u03b7\u03c2 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b1\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b5\u03b3\u03ac\u03bb\u03bf ({{ width }}px). \u03a4\u03bf \u03bc\u03ad\u03b3\u03b9\u03c3\u03c4\u03bf \u03b5\u03c0\u03b9\u03c4\u03c1\u03b5\u03c0\u03c4\u03cc \u03c0\u03bb\u03ac\u03c4\u03bf\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 {{ max_width }}px.", "validators", "el");
t.add("The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.", "\u03a4\u03bf \u03c0\u03bb\u03ac\u03c4\u03bf\u03c2 \u03c4\u03b7\u03c2 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b1\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b9\u03ba\u03c1\u03cc ({{ width }}px). \u03a4\u03bf \u03b5\u03bb\u03ac\u03c7\u03b9\u03c3\u03c4\u03bf \u03b5\u03c0\u03b9\u03c4\u03c1\u03b5\u03c0\u03c4\u03cc \u03c0\u03bb\u03ac\u03c4\u03bf\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 {{ min_width }}px.", "validators", "el");
t.add("The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.", "\u03a4\u03bf \u03cd\u03c8\u03bf\u03c2 \u03c4\u03b7\u03c2 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b1\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b5\u03b3\u03ac\u03bb\u03bf ({{ height }}px). \u03a4\u03bf \u03bc\u03ad\u03b3\u03b9\u03c3\u03c4\u03bf \u03b5\u03c0\u03b9\u03c4\u03c1\u03b5\u03c0\u03c4\u03cc \u03cd\u03c8\u03bf\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 {{ max_height }}px.", "validators", "el");
t.add("The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.", "\u03a4\u03bf \u03cd\u03c8\u03bf\u03c2 \u03c4\u03b7\u03c2 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b1\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b9\u03ba\u03c1\u03cc ({{ height }}px). \u03a4\u03bf \u03b5\u03bb\u03ac\u03c7\u03b9\u03c3\u03c4\u03bf \u03b5\u03c0\u03b9\u03c4\u03c1\u03b5\u03c0\u03c4\u03cc \u03cd\u03c8\u03bf\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 {{ min_height }}px.", "validators", "el");
t.add("This value should be the user's current password.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03ad\u03c0\u03c1\u03b5\u03c0\u03b5 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03bf \u03c4\u03c1\u03ad\u03c7\u03c9\u03bd \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2.", "validators", "el");
t.add("This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03ad\u03c0\u03c1\u03b5\u03c0\u03b5 \u03bd\u03b1 \u03ad\u03c7\u03b5\u03b9 \u03b1\u03ba\u03c1\u03b9\u03b2\u03ce\u03c2 {{ limit }} \u03c7\u03b1\u03c1\u03b1\u03ba\u03c4\u03ae\u03c1\u03b1.|\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03ad\u03c0\u03c1\u03b5\u03c0\u03b5 \u03bd\u03b1 \u03ad\u03c7\u03b5\u03b9 \u03b1\u03ba\u03c1\u03b9\u03b2\u03ce\u03c2 {{ limit }} \u03c7\u03b1\u03c1\u03b1\u03ba\u03c4\u03ae\u03c1\u03b5\u03c2.", "validators", "el");
t.add("The file was only partially uploaded.", "\u03a4\u03bf \u03b1\u03c1\u03c7\u03b5\u03af\u03bf \u03b4\u03b5\u03bd \u03b1\u03bd\u03ad\u03b2\u03b7\u03ba\u03b5 \u03bf\u03bb\u03cc\u03ba\u03bb\u03b7\u03c1\u03bf.", "validators", "el");
t.add("No file was uploaded.", "\u0394\u03b5\u03bd \u03b1\u03bd\u03ad\u03b2\u03b7\u03ba\u03b5 \u03ba\u03b1\u03bd\u03ad\u03bd\u03b1 \u03b1\u03c1\u03c7\u03b5\u03af\u03bf.", "validators", "el");
t.add("No temporary folder was configured in php.ini.", "\u039a\u03b1\u03bd\u03ad\u03bd\u03b1\u03c2 \u03c0\u03c1\u03bf\u03c3\u03c9\u03c1\u03b9\u03bd\u03cc\u03c2 \u03c6\u03ac\u03ba\u03b5\u03bb\u03bf\u03c2 \u03b4\u03b5\u03bd \u03ad\u03c7\u03b5\u03b9 \u03c1\u03c5\u03b8\u03bc\u03b9\u03c3\u03c4\u03b5\u03af \u03c3\u03c4\u03bf php.ini.", "validators", "el");
t.add("Cannot write temporary file to disk.", "\u0391\u03b4\u03c5\u03bd\u03b1\u03bc\u03af\u03b1 \u03b5\u03b3\u03b3\u03c1\u03b1\u03c6\u03ae\u03c2 \u03c0\u03c1\u03bf\u03c3\u03c9\u03c1\u03b9\u03bd\u03bf\u03cd \u03b1\u03c1\u03c7\u03b5\u03af\u03bf\u03c5 \u03c3\u03c4\u03bf \u03b4\u03af\u03c3\u03ba\u03bf.", "validators", "el");
t.add("A PHP extension caused the upload to fail.", "\u039c\u03b9\u03b1 \u03b5\u03c0\u03ad\u03ba\u03c4\u03b1\u03c3\u03b7 PHP \u03c0\u03c1\u03bf\u03ba\u03ac\u03bb\u03b5\u03c3\u03b5 \u03b1\u03b4\u03c5\u03bd\u03b1\u03bc\u03af\u03b1 \u03b1\u03bd\u03b5\u03b2\u03ac\u03c3\u03bc\u03b1\u03c4\u03bf\u03c2.", "validators", "el");
t.add("This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c3\u03c5\u03bb\u03bb\u03bf\u03b3\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03c0\u03b5\u03c1\u03b9\u03ad\u03c7\u03b5\u03b9 {{ limit }} \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af\u03bf \u03ae \u03c0\u03b5\u03c1\u03b9\u03c3\u03c3\u03cc\u03c4\u03b5\u03c1\u03b1.|\u0391\u03c5\u03c4\u03ae \u03b7 \u03c3\u03c5\u03bb\u03bb\u03bf\u03b3\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03c0\u03b5\u03c1\u03b9\u03ad\u03c7\u03b5\u03b9 {{ limit }} \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af\u03b1 \u03ae \u03c0\u03b5\u03c1\u03b9\u03c3\u03c3\u03cc\u03c4\u03b5\u03c1\u03b1.", "validators", "el");
t.add("This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c3\u03c5\u03bb\u03bb\u03bf\u03b3\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03c0\u03b5\u03c1\u03b9\u03ad\u03c7\u03b5\u03b9 {{ limit }} \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03afo \u03ae \u03bb\u03b9\u03b3\u03cc\u03c4\u03b5\u03c1\u03b1.|\u0391\u03c5\u03c4\u03ae \u03b7 \u03c3\u03c5\u03bb\u03bb\u03bf\u03b3\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03c0\u03b5\u03c1\u03b9\u03ad\u03c7\u03b5\u03b9 {{ limit }} \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af\u03b1 \u03ae \u03bb\u03b9\u03b3\u03cc\u03c4\u03b5\u03c1\u03b1.", "validators", "el");
t.add("This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c3\u03c5\u03bb\u03bb\u03bf\u03b3\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03c0\u03b5\u03c1\u03b9\u03ad\u03c7\u03b5\u03b9 \u03b1\u03ba\u03c1\u03b9\u03b2\u03ce\u03c2 {{ limit }} \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03afo.|\u0391\u03c5\u03c4\u03ae \u03b7 \u03c3\u03c5\u03bb\u03bb\u03bf\u03b3\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03c0\u03b5\u03c1\u03b9\u03ad\u03c7\u03b5\u03b9 \u03b1\u03ba\u03c1\u03b9\u03b2\u03ce\u03c2 {{ limit }} \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af\u03b1.", "validators", "el");
t.add("Invalid card number.", "\u039c\u03b7 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2 \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc\u03c2 \u03ba\u03ac\u03c1\u03c4\u03b1\u03c2.", "validators", "el");
t.add("Unsupported card type or invalid card number.", "\u039c\u03b7 \u03c5\u03c0\u03bf\u03c3\u03c4\u03b7\u03c1\u03b9\u03b6\u03cc\u03bc\u03b5\u03bd\u03bf\u03c2 \u03c4\u03cd\u03c0\u03bf\u03c2 \u03ba\u03ac\u03c1\u03c4\u03b1\u03c2 \u03ae \u03bc\u03b7 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2 \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc\u03c2 \u03ba\u03ac\u03c1\u03c4\u03b1\u03c2.", "validators", "el");
t.add("This is not a valid International Bank Account Number (IBAN).", "\u0391\u03c5\u03c4\u03cc \u03b4\u03b5\u03bd \u03b1\u03bd\u03c4\u03b9\u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af \u03c3\u03b5 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03b4\u03b9\u03b5\u03b8\u03bd\u03ae \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc \u03c4\u03c1\u03b1\u03c0\u03b5\u03b6\u03b9\u03ba\u03bf\u03cd \u03bb\u03bf\u03b3\u03b1\u03c1\u03b9\u03b1\u03c3\u03bc\u03bf\u03cd (IBAN).", "validators", "el");
t.add("This value is not a valid ISBN-10.", "\u0391\u03c5\u03c4\u03cc \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2 \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 ISBN-10.", "validators", "el");
t.add("This value is not a valid ISBN-13.", "\u0391\u03c5\u03c4\u03cc \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2 \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 ISBN-13.", "validators", "el");
t.add("This value is neither a valid ISBN-10 nor a valid ISBN-13.", "\u0391\u03c5\u03c4\u03cc \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03bf\u03cd\u03c4\u03b5 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2 \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 ISBN-10 \u03bf\u03cd\u03c4\u03b5 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2 \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 ISBN-13.", "validators", "el");
t.add("This value is not a valid ISSN.", "\u0391\u03c5\u03c4\u03cc \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2 \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 ISSN.", "validators", "el");
t.add("This value is not a valid currency.", "\u0391\u03c5\u03c4\u03cc \u03b4\u03b5\u03bd \u03b1\u03bd\u03c4\u03b9\u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af \u03c3\u03b5 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03bd\u03cc\u03bc\u03b9\u03c3\u03bc\u03b1.", "validators", "el");
t.add("This value should be equal to {{ compared_value }}.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03af\u03c3\u03b7 \u03bc\u03b5 {{ compared_value }}.", "validators", "el");
t.add("This value should be greater than {{ compared_value }}.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03bc\u03b5\u03b3\u03b1\u03bb\u03cd\u03c4\u03b5\u03c1\u03b7 \u03b1\u03c0\u03cc {{ compared_value }}.", "validators", "el");
t.add("This value should be greater than or equal to {{ compared_value }}.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03bc\u03b5\u03b3\u03b1\u03bb\u03cd\u03c4\u03b5\u03c1\u03b7 \u03ae \u03af\u03c3\u03b7 \u03bc\u03b5 {{ compared_value }}.", "validators", "el");
t.add("This value should be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03b1\u03bd\u03bf\u03bc\u03bf\u03b9\u03cc\u03c4\u03c5\u03c0\u03b7 \u03bc\u03b5 {{ compared_value_type }} {{ compared_value }}.", "validators", "el");
t.add("This value should be less than {{ compared_value }}.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03bc\u03b9\u03ba\u03c1\u03cc\u03c4\u03b5\u03c1\u03b7 \u03b1\u03c0\u03cc {{ compared_value }}.", "validators", "el");
t.add("This value should be less than or equal to {{ compared_value }}.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03bc\u03b9\u03ba\u03c1\u03cc\u03c4\u03b5\u03c1\u03b7 \u03ae \u03af\u03c3\u03b7 \u03bc\u03b5 {{ compared_value }}.", "validators", "el");
t.add("This value should not be equal to {{ compared_value }}.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03af\u03c3\u03b7 \u03bc\u03b5 {{ compared_value }}.", "validators", "el");
t.add("This value should not be identical to {{ compared_value_type }} {{ compared_value }}.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03b1\u03bd\u03bf\u03bc\u03bf\u03b9\u03cc\u03c4\u03c5\u03c0\u03b7 \u03bc\u03b5 {{ compared_value_type }} {{ compared_value }}.", "validators", "el");
t.add("The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.", "\u0397 \u03b1\u03bd\u03b1\u03bb\u03bf\u03b3\u03af\u03b1 \u03c0\u03bb\u03ac\u03c4\u03bf\u03c5\u03c2-\u03cd\u03c8\u03bf\u03c5\u03c2 \u03c4\u03b7\u03c2 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b1\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b5\u03b3\u03ac\u03bb\u03b7 ({{ ratio }}). \u039c\u03ad\u03b3\u03b9\u03c3\u03c4\u03b7 \u03b5\u03c0\u03b9\u03c4\u03c1\u03b5\u03c0\u03c4\u03ae \u03b1\u03bd\u03b1\u03bb\u03bf\u03b3\u03af\u03b1 {{ max_ratio }}.", "validators", "el");
t.add("The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.", "\u0397 \u03b1\u03bd\u03b1\u03bb\u03bf\u03b3\u03af\u03b1 \u03c0\u03bb\u03ac\u03c4\u03bf\u03c5\u03c2-\u03cd\u03c8\u03bf\u03c5\u03c2 \u03c4\u03b7\u03c2 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b1\u03c2 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b9\u03ba\u03c1\u03ae ({{ ratio }}). \u0395\u03bb\u03ac\u03c7\u03b9\u03c3\u03c4\u03b7 \u03b5\u03c0\u03b9\u03c4\u03c1\u03b5\u03c0\u03c4\u03ae \u03b1\u03bd\u03b1\u03bb\u03bf\u03b3\u03af\u03b1 {{ min_ratio }}.", "validators", "el");
t.add("The image is square ({{ width }}x{{ height }}px). Square images are not allowed.", "\u0397 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c4\u03b5\u03c4\u03c1\u03ac\u03b3\u03c9\u03bd\u03b7 ({{ width }}x{{ height }}px). \u0394\u03b5\u03bd \u03b5\u03c0\u03b9\u03c4\u03c1\u03ad\u03c0\u03bf\u03bd\u03c4\u03b1\u03b9 \u03c4\u03b5\u03c4\u03c1\u03ac\u03b3\u03c9\u03bd\u03b5\u03c2 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b5\u03c2.", "validators", "el");
t.add("The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.", "\u0397 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b1 \u03ad\u03c7\u03b5\u03b9 \u03bf\u03c1\u03b9\u03b6\u03cc\u03bd\u03c4\u03b9\u03bf \u03c0\u03c1\u03bf\u03c3\u03b1\u03bd\u03b1\u03c4\u03bf\u03bb\u03b9\u03c3\u03bc\u03cc ({{ width }}x{{ height }}px). \u0394\u03b5\u03bd \u03b5\u03c0\u03b9\u03c4\u03c1\u03ad\u03c0\u03bf\u03bd\u03c4\u03b1\u03b9 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b5\u03c2 \u03bc\u03b5 \u03bf\u03c1\u03b9\u03b6\u03cc\u03bd\u03c4\u03b9\u03bf \u03c0\u03c1\u03bf\u03c3\u03b1\u03bd\u03b1\u03c4\u03bf\u03bb\u03b9\u03c3\u03bc\u03cc.", "validators", "el");
t.add("The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.", "\u0397 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b1 \u03ad\u03c7\u03b5\u03b9 \u03ba\u03ac\u03b8\u03b5\u03c4\u03bf \u03c0\u03c1\u03bf\u03c3\u03b1\u03bd\u03b1\u03c4\u03bf\u03bb\u03b9\u03c3\u03bc\u03cc ({{ width }}x{{ height }}px). \u0394\u03b5\u03bd \u03b5\u03c0\u03b9\u03c4\u03c1\u03ad\u03c0\u03bf\u03bd\u03c4\u03b1\u03b9 \u03b5\u03b9\u03ba\u03cc\u03bd\u03b5\u03c2 \u03bc\u03b5 \u03ba\u03ac\u03b8\u03b5\u03c4\u03bf \u03c0\u03c1\u03bf\u03c3\u03b1\u03bd\u03b1\u03c4\u03bf\u03bb\u03b9\u03c3\u03bc\u03cc.", "validators", "el");
t.add("An empty file is not allowed.", "\u0394\u03b5\u03bd \u03b5\u03c0\u03b9\u03c4\u03c1\u03ad\u03c0\u03b5\u03c4\u03b1\u03b9 \u03ba\u03b5\u03bd\u03cc \u03b1\u03c1\u03c7\u03b5\u03af\u03bf.", "validators", "el");
t.add("The host could not be resolved.", "\u0397 \u03b4\u03b9\u03b5\u03cd\u03b8\u03c5\u03bd\u03c3\u03b7 \u03b4\u03b5\u03bd \u03bc\u03c0\u03cc\u03c1\u03b5\u03c3\u03b5 \u03bd\u03b1 \u03b5\u03c0\u03b9\u03bb\u03c5\u03b8\u03b5\u03af.", "validators", "el");
t.add("This value does not match the expected {{ charset }} charset.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03c4\u03b1\u03b9\u03c1\u03b9\u03ac\u03b6\u03b5\u03b9 \u03c3\u03c4\u03bf \u03b1\u03bd\u03b1\u03bc\u03b5\u03bd\u03cc\u03bc\u03b5\u03bd\u03bf {{ charset }} \u03c3\u03cd\u03bd\u03bf\u03bb\u03bf \u03c7\u03b1\u03c1\u03b1\u03ba\u03c4\u03ae\u03c1\u03c9\u03bd.", "validators", "el");
t.add("This is not a valid Business Identifier Code (BIC).", "\u0391\u03c5\u03c4\u03cc\u03c2 \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03bd\u03b1\u03c2 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2 \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 BIC.", "validators", "el");
t.add("Error", "\u03a3\u03c6\u03ac\u03bb\u03bc\u03b1", "validators", "el");
t.add("This is not a valid UUID.", "\u0391\u03c5\u03c4\u03cc \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03bd\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf UUID.", "validators", "el");
t.add("This value should be a multiple of {{ compared_value }}.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03ad\u03c0\u03c1\u03b5\u03c0\u03b5 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03bb\u03b1\u03c0\u03bb\u03ac\u03c3\u03b9\u03bf \u03c4\u03bf\u03c5 {{ compared_value }}.", "validators", "el");
t.add("This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.", "\u0391\u03c5\u03c4\u03cc\u03c2 \u03bf \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 BIC \u03b4\u03b5\u03bd \u03c3\u03c7\u03b5\u03c4\u03af\u03b6\u03b5\u03c4\u03b1\u03b9 \u03bc\u03b5 \u03c4\u03bf IBAN {{ iban }}.", "validators", "el");
t.add("This value should be valid JSON.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf JSON.", "validators", "el");
t.add("This collection should contain only unique elements.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c3\u03c5\u03bb\u03bb\u03bf\u03b3\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03c0\u03b5\u03c1\u03b9\u03ad\u03c7\u03b5\u03b9 \u03bc\u03cc\u03bd\u03bf \u03bc\u03bf\u03bd\u03b1\u03b4\u03b9\u03ba\u03ac \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af\u03b1.", "validators", "el");
t.add("This value should be positive.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03b8\u03b5\u03c4\u03b9\u03ba\u03ae.", "validators", "el");
t.add("This value should be either positive or zero.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03b8\u03b5\u03c4\u03b9\u03ba\u03ae \u03ae \u03bc\u03b7\u03b4\u03b5\u03bd\u03b9\u03ba\u03ae.", "validators", "el");
t.add("This value should be negative.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03b1\u03c1\u03bd\u03b7\u03c4\u03b9\u03ba\u03ae.", "validators", "el");
t.add("This value should be either negative or zero.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03b1\u03c1\u03bd\u03b7\u03c4\u03b9\u03ba\u03ae \u03ae \u03bc\u03b7\u03b4\u03b5\u03bd\u03b9\u03ba\u03ae.", "validators", "el");
t.add("This value is not a valid timezone.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b6\u03ce\u03bd\u03b7 \u03ce\u03c1\u03b1\u03c2.", "validators", "el");
t.add("This password has been leaked in a data breach, it must not be used. Please use another password.", "\u0391\u03c5\u03c4\u03cc\u03c2 \u03bf \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 \u03c0\u03c1\u03cc\u03c3\u03b2\u03b1\u03c3\u03b7\u03c2 \u03ad\u03c7\u03b5\u03b9 \u03b4\u03b9\u03b1\u03c1\u03c1\u03b5\u03cd\u03c3\u03b5\u03b9 \u03c3\u03b5 \u03c0\u03b1\u03c1\u03b1\u03b2\u03af\u03b1\u03c3\u03b7 \u03b4\u03b5\u03b4\u03bf\u03bc\u03ad\u03bd\u03c9\u03bd. \u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03bd\u03b1 \u03c7\u03c1\u03b7\u03c3\u03b9\u03bc\u03bf\u03c0\u03bf\u03b9\u03ae\u03c3\u03b5\u03c4\u03b5 \u03ad\u03bd\u03b1\u03bd \u03ac\u03bb\u03bb\u03bf \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc.", "validators", "el");
t.add("This value should be between {{ min }} and {{ max }}.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03bc\u03b5\u03c4\u03b1\u03be\u03cd {{ min }} \u03ba\u03b1\u03b9 {{ max }}.", "validators", "el");
t.add("This value is not a valid hostname.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03cc\u03bd\u03bf\u03bc\u03b1 \u03c5\u03c0\u03bf\u03b4\u03bf\u03c7\u03ae\u03c2.", "validators", "el");
t.add("The number of elements in this collection should be a multiple of {{ compared_value }}.", "\u03a4\u03bf \u03bd\u03bf\u03cd\u03bc\u03b5\u03c1\u03bf \u03c4\u03c9\u03bd \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af\u03c9\u03bd \u03c3\u03b5 \u03b1\u03c5\u03c4\u03ae \u03c4\u03b7 \u03c3\u03c5\u03bb\u03bb\u03bf\u03b3\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03bb\u03b1\u03c0\u03bb\u03ac\u03c3\u03b9\u03bf \u03c4\u03bf\u03c5 {{ compared_value }}.", "validators", "el");
t.add("This value should satisfy at least one of the following constraints:", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b9\u03ba\u03b1\u03bd\u03bf\u03c0\u03bf\u03b9\u03b5\u03af \u03c4\u03bf\u03c5\u03bb\u03ac\u03c7\u03b9\u03c3\u03c4\u03bf\u03bd \u03ad\u03bd\u03b1\u03bd \u03b1\u03c0\u03cc \u03c4\u03bf\u03c5\u03c2 \u03c0\u03b1\u03c1\u03b1\u03ba\u03ac\u03c4\u03c9 \u03c0\u03b5\u03c1\u03b9\u03bf\u03c1\u03b9\u03c3\u03bc\u03bf\u03cd\u03c2: ", "validators", "el");
t.add("Each element of this collection should satisfy its own set of constraints.", "\u039a\u03ac\u03b8\u03b5 \u03c3\u03c4\u03bf\u03b9\u03c7\u03b5\u03af\u03bf \u03c3\u03b5 \u03b1\u03c5\u03c4\u03ae \u03c4\u03b7 \u03c3\u03c5\u03bb\u03bb\u03bf\u03b3\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b9\u03ba\u03b1\u03bd\u03bf\u03c0\u03bf\u03b9\u03b5\u03af \u03c4\u03bf \u03b4\u03b9\u03ba\u03cc \u03c4\u03bf\u03c5 \u03c3\u03cd\u03bd\u03bf\u03bb\u03bf \u03c0\u03b5\u03c1\u03b9\u03bf\u03c1\u03b9\u03c3\u03bc\u03ce\u03bd.", "validators", "el");
t.add("This value is not a valid International Securities Identification Number (ISIN).", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf International Securities Identification Number (ISIN).", "validators", "el");
t.add("This value should be a valid expression.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b8\u03b1 \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03bc\u03af\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03ad\u03ba\u03c6\u03c1\u03b1\u03c3\u03b7.", "validators", "el");
t.add("This value is not a valid CSS color.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03c7\u03c1\u03ce\u03bc\u03b1 CSS.", "validators", "el");
t.add("This value is not a valid CIDR notation.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 CIDR \u03c3\u03b7\u03bc\u03b5\u03b9\u03bf\u03b3\u03c1\u03b1\u03c6\u03af\u03b1.", "validators", "el");
t.add("The value of the netmask should be between {{ min }} and {{ max }}.", "\u0397 \u03c4\u03b9\u03bc\u03ae \u03c4\u03bf\u03c5 netmask \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03b5\u03af\u03bd\u03b1\u03b9 \u03b1\u03bd\u03ac\u03bc\u03b5\u03c3\u03b1 \u03c3\u03b5 {{ min }} \u03ba\u03b1\u03b9 {{ max }}.", "validators", "el");
t.add("This form should not contain extra fields.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c6\u03cc\u03c1\u03bc\u03b1 \u03b4\u03b5\u03bd \u03c0\u03c1\u03ad\u03c0\u03b5\u03b9 \u03bd\u03b1 \u03c0\u03b5\u03c1\u03b9\u03ad\u03c7\u03b5\u03b9 \u03b5\u03c0\u03b9\u03c0\u03bb\u03ad\u03bf\u03bd \u03c0\u03b5\u03b4\u03af\u03b1.", "validators", "el");
t.add("The uploaded file was too large. Please try to upload a smaller file.", "\u03a4\u03bf \u03b1\u03c1\u03c7\u03b5\u03af\u03bf \u03b5\u03af\u03bd\u03b1\u03b9 \u03c0\u03bf\u03bb\u03cd \u03bc\u03b5\u03b3\u03ac\u03bb\u03bf. \u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03c0\u03c1\u03bf\u03c3\u03c0\u03b1\u03b8\u03ae\u03c3\u03c4\u03b5 \u03bd\u03b1 \u03b1\u03bd\u03b5\u03b2\u03ac\u03c3\u03b5\u03c4\u03b5 \u03ad\u03bd\u03b1 \u03bc\u03b9\u03ba\u03c1\u03cc\u03c4\u03b5\u03c1\u03bf \u03b1\u03c1\u03c7\u03b5\u03af\u03bf.", "validators", "el");
t.add("The CSRF token is invalid. Please try to resubmit the form.", "\u03a4\u03bf CSRF token \u03b4\u03b5\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf. \u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b4\u03bf\u03ba\u03b9\u03bc\u03ac\u03c3\u03c4\u03b5 \u03bd\u03b1 \u03c5\u03c0\u03bf\u03b2\u03ac\u03bb\u03b5\u03c4\u03b5 \u03c4\u03b7 \u03c6\u03cc\u03c1\u03bc\u03b1 \u03be\u03b1\u03bd\u03ac.", "validators", "el");
t.add("This value is not a valid HTML5 color.", "\u0391\u03c5\u03c4\u03ae \u03b7 \u03c4\u03b9\u03bc\u03ae \u03b4\u03ad\u03bd \u03ad\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03c7\u03c1\u03ce\u03bc\u03b1 HTML5.", "validators", "el");
t.add("Please enter a valid birthdate.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03cc\u03c5\u03bc\u03b5 \u03b5\u03b9\u03c7\u03ac\u03b3\u03b5\u03c4\u03b1\u03b9 \u03bc\u03af\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b7\u03bc\u03b5\u03c1\u03bf\u03bc\u03b7\u03bd\u03af\u03b1 \u03b3\u03ad\u03bd\u03bd\u03b7\u03c3\u03b7\u03c2.", "validators", "el");
t.add("The selected choice is invalid.", "\u0397 \u03b5\u03c0\u03b9\u03bb\u03b5\u03b3\u03bc\u03ad\u03bd\u03b7 \u03b5\u03c0\u03b9\u03bb\u03bf\u03b3\u03ae \u03b4\u03ad\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7.", "validators", "el");
t.add("The collection is invalid.", "\u0397 \u03c3\u03c5\u03bb\u03bb\u03bf\u03b3\u03ae \u03b4\u03ad\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7.", "validators", "el");
t.add("Please select a valid color.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03ad\u03bd\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03c7\u03c1\u03ce\u03bc\u03b1.", "validators", "el");
t.add("Please select a valid country.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03bc\u03af\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03c7\u03ce\u03c1\u03b1.", "validators", "el");
t.add("Please select a valid currency.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03ad\u03bd\u03b1 \u03ad\u03b3\u03c5\u03c1\u03bf \u03bd\u03cc\u03bc\u03b9\u03c3\u03bc\u03b1.", "validators", "el");
t.add("Please choose a valid date interval.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03ad\u03bd\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03b4\u03b9\u03ac\u03c3\u03c4\u03b7\u03bc\u03b1 \u03b7\u03bc\u03b5\u03c1\u03bf\u03bc\u03b7\u03bd\u03af\u03b1\u03c2.", "validators", "el");
t.add("Please enter a valid date and time.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03b9\u03c3\u03b1\u03b3\u03ac\u03b3\u03b5\u03c4\u03b5 \u03bc\u03b9\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b7\u03bc\u03b5\u03c1\u03bf\u03bc\u03b7\u03bd\u03af\u03b1 \u03ba\u03b1\u03b9 \u03ce\u03c1\u03b1.", "validators", "el");
t.add("Please enter a valid date.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03b9\u03c3\u03ac\u03b3\u03b5\u03c4\u03b5 \u03bc\u03af\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b7\u03bc\u03b5\u03c1\u03bf\u03bc\u03b7\u03bd\u03af\u03b1.", "validators", "el");
t.add("Please select a valid file.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03ad\u03bd\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03b1\u03c1\u03c7\u03b5\u03af\u03bf.", "validators", "el");
t.add("The hidden field is invalid.", "\u03a4\u03bf \u03ba\u03c1\u03c5\u03c6\u03cc \u03c0\u03b5\u03b4\u03af\u03bf \u03b4\u03ad\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf.", "validators", "el");
t.add("Please enter an integer.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03b9\u03c3\u03ac\u03b3\u03b5\u03c4\u03b5 \u03ad\u03bd\u03b1\u03bd \u03b1\u03ba\u03ad\u03c1\u03b1\u03b9\u03bf \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc.", "validators", "el");
t.add("Please select a valid language.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03bc\u03af\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b3\u03bb\u03ce\u03c3\u03c3\u03b1.", "validators", "el");
t.add("Please select a valid locale.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03bc\u03af\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03c4\u03bf\u03c0\u03b9\u03ba\u03bf\u03c0\u03bf\u03af\u03b7\u03c3\u03b7.", "validators", "el");
t.add("Please enter a valid money amount.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03b9\u03c3\u03ac\u03b3\u03b5\u03c4\u03b5 \u03ad\u03bd\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03c7\u03c1\u03b7\u03bc\u03b1\u03c4\u03b9\u03ba\u03cc \u03c0\u03bf\u03c3\u03cc.", "validators", "el");
t.add("Please enter a number.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03b9\u03c3\u03ac\u03b3\u03b5\u03c4\u03b5 \u03ad\u03bd\u03b1\u03bd \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc.", "validators", "el");
t.add("The password is invalid.", "\u039f \u03ba\u03c9\u03b4\u03b9\u03ba\u03cc\u03c2 \u03b4\u03ad\u03bd \u03b5\u03af\u03bd\u03b1\u03b9 \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf\u03c2.", "validators", "el");
t.add("Please enter a percentage value.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03b9\u03c3\u03ac\u03b3\u03b5\u03c4\u03b5 \u03bc\u03af\u03b1 \u03c0\u03bf\u03c3\u03bf\u03c3\u03c4\u03b9\u03b1\u03af\u03b1 \u03c4\u03b9\u03bc\u03ae.", "validators", "el");
t.add("The values do not match.", "\u039f\u03b9 \u03c4\u03b9\u03bc\u03ad\u03c2 \u03b4\u03ad\u03bd \u03c4\u03b1\u03b9\u03c1\u03b9\u03ac\u03b6\u03bf\u03c5\u03bd.", "validators", "el");
t.add("Please enter a valid time.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03b9\u03c3\u03ac\u03b3\u03b5\u03c4\u03b5 \u03bc\u03af\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03ce\u03c1\u03b1.", "validators", "el");
t.add("Please select a valid timezone.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03bc\u03af\u03b1 \u03ad\u03b3\u03c5\u03c1\u03b7 \u03b6\u03ce\u03bd\u03b7 \u03ce\u03c1\u03b1\u03c2.", "validators", "el");
t.add("Please enter a valid URL.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03b9\u03c3\u03ac\u03b3\u03b5\u03c4\u03b5 \u03bc\u03b9\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b4\u03b9\u03b5\u03cd\u03b8\u03c5\u03bd\u03c3\u03b7 URL.", "validators", "el");
t.add("Please enter a valid search term.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03b9\u03c3\u03ac\u03b3\u03b5\u03c4\u03b5 \u03ad\u03bd\u03b1\u03bd \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03cc\u03c1\u03bf \u03b1\u03bd\u03b1\u03b6\u03ae\u03c4\u03b7\u03c3\u03b7\u03c2.", "validators", "el");
t.add("Please provide a valid phone number.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03ba\u03b1\u03c4\u03b1\u03c7\u03c9\u03c1\u03af\u03c3\u03c4\u03b5 \u03ad\u03bd\u03b1\u03bd \u03ad\u03b3\u03ba\u03c5\u03c1\u03bf \u03b1\u03c1\u03b9\u03b8\u03bc\u03cc \u03c4\u03b7\u03bb\u03b5\u03c6\u03ce\u03bd\u03bf\u03c5.", "validators", "el");
t.add("The checkbox has an invalid value.", "\u03a4\u03bf \u03c0\u03bb\u03b1\u03af\u03c3\u03b9\u03bf \u03b5\u03bb\u03ad\u03b3\u03c7\u03bf\u03c5 \u03ad\u03c7\u03b5\u03b9 \u03bc\u03ae \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03c4\u03b9\u03bc\u03ae.", "validators", "el");
t.add("Please enter a valid email address.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03b9\u03c3\u03ac\u03b3\u03b5\u03c4\u03b5 \u03bc\u03af\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b7\u03bb\u03b5\u03ba\u03c4\u03c1\u03bf\u03bd\u03b9\u03ba\u03ae \u03b4\u03b9\u03b5\u03cd\u03b8\u03c5\u03bd\u03c3\u03b7.", "validators", "el");
t.add("Please select a valid option.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03bc\u03af\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b5\u03c0\u03b9\u03bb\u03bf\u03b3\u03ae.", "validators", "el");
t.add("Please select a valid range.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03c0\u03b9\u03bb\u03ad\u03be\u03c4\u03b5 \u03ad\u03bd\u03b1 \u03ad\u03b3\u03c5\u03c1\u03bf \u03b5\u03cd\u03c1\u03bf\u03c2.", "validators", "el");
t.add("Please enter a valid week.", "\u03a0\u03b1\u03c1\u03b1\u03ba\u03b1\u03bb\u03bf\u03cd\u03bc\u03b5 \u03b5\u03b9\u03c3\u03ac\u03b3\u03b5\u03c4\u03b5 \u03bc\u03af\u03b1 \u03ad\u03b3\u03ba\u03c5\u03c1\u03b7 \u03b5\u03b2\u03b4\u03bf\u03bc\u03ac\u03b4\u03b1.", "validators", "el");
})(Translator);
